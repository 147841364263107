import React, { Fragment } from 'react';
import { SURVEY_CREATION_CONSTANT } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
class SurveyContentPageHeader extends React.Component {
    render() {
        let onClickFunction = this.props.onUpdate;
        let fieldName = this.props.fieldName;
        let surveyContent;
        if (fieldName) {
            surveyContent = this.props.data.surveyContent;
        } else {
            surveyContent = this.props.AllData.surveyData.surveyContent;
        }
        let index = this.props.index;
        let optionId;
        let optionText;
        if (surveyContent) {
            if (surveyContent[index]) {
                optionId = surveyContent[index].id;
                optionText = surveyContent[index].content_text.replace(/<br\s*\/?>/mg, "");
            }
        }
        if (optionText) {
            optionText = optionText.replace(/<br\s*\/?>/mg, "");
        }
        return (
            <Fragment>
                <div id={"removeText-" + index} className={"mt-4 form-group survey-form-detail d-flex removeText-" + index}>
                    <label htmlFor="survey_welcome_header" className="sr-only">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_content_page_header_placeholder']}</label>
                    <input type="text" id={optionId} name="survey_welcome_header" data-value={index} value={optionText} onChange={onClickFunction} className="input-header-width get-header form-control" placeholder={SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_content_page_header_placeholder']} />
                    <button className="btn p-0 icon-del w-auto" onClick={onClickFunction} type="button" name="removeText" id={optionId} value={index}><i className="fas fa-trash"></i></button>
                </div>
            </Fragment>
        );
    }
}
export default SurveyContentPageHeader;