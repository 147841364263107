import React from 'react';
import axios from 'axios';
import { Redirect, Link } from "react-router-dom";
import { SURVEY_DETAIL_CONSTANT, SURVEY_LIST_CONSTANT, META_TAGS_CONSTANT, URL_CONSTANT,  BUBBLE_LOADING, META_FACEBOOK } from '../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, IMAGE_BASEURL, META_TITLE_APPEND } from '../../../constants/DotEnv';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
class SurveyDetail extends React.Component {
    constructor() {
        super();
        this.state = {
            surveydetail: [],
            length: '',
            loading: 'false',
            demographic: '',
            redirect: 'false',
            relatedsurvey: [],
            survey_slug: '',
            sessionRedirect: false,
            redirectExternal: false,
            cmscontentdata: {},
            cmscontentlength: '',
        }
        setTimeout(() => {
            window.setNoIndex();
        }, 100)
    }
    componentWillUnmount() {
        window.removeNoIndex();
        setTimeout(() => {
            window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
        }, 100)
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.getSurveyDetail();
    }
    /** This function is for getting cookie to show message for the user who is not logged in and already participate the survey */
    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }
    /**this function is for scrolling up page */
    scrollUpFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (document.querySelector('.navbar-toggler')) {
            if (document.querySelector('.navbar-toggler').className) {
                let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
                if (count === -1) {
                    document.querySelector('.navbar-collapse').classList.remove('show');
                    document.querySelector('header').classList.remove("header_collapse");
                    document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
                }
            }
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params['title'] !== prevProps.match.params['title']) {
            this.getSurveyDetail();
        }
    }
    /**API call for getting survey details */
    getSurveyDetail = async () => {
        this.setState({ loading: 'true' })
        let title = this.props.match.params.title;
        let self = this;
        let surveydetail = '';
        let demographic = '';
        let length = '';
        let category_id = '';
        let survey_id = '';
        let survey_slug = '';
        let surveydata = '';
        let meta_title = '';
        let meta_description = '';
        let meta_keywords = '';
        let og_title = '';
        let og_description = META_FACEBOOK['og_description'];
        let og_image = '';
        await axios.get(API_URL + '/commonApi/getSurveyBySlug/' + LANGUAGE_CODE + '/' + title)
            .then((response) => {
                surveydetail = response.data.success.surveydata;
                demographic = response.data.success.surveydata.demographic;
                length = Object.keys(surveydetail).length;
                category_id = response.data.success.surveydata.category_id;
                survey_id = response.data.success.surveydata.id;
                survey_slug = response.data.success.surveydata.survey_title_slug;
                meta_title = surveydetail.survey_title + META_TITLE_APPEND;
                meta_description = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_description'];
                meta_keywords = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords']
                og_title = surveydetail.survey_title + META_TITLE_APPEND;
                og_image = (surveydetail.ogImageUrl) ? surveydetail.ogImageUrl : META_FACEBOOK['og_image'];
                window.setMeta({ "description": meta_description, "keywords": meta_keywords });
                window.setOgTag(og_title, og_description, og_image, window.location.href)
                window.setDocumentTitle(meta_title)
                if (surveydetail !== '') {
                    surveydata = response.data.success.surveydata;
                    if (survey_id) {
                        localStorage.setItem("surveydetail_" + survey_id, JSON.stringify(surveydata));
                    }
                }
                self.setState({ loading: 'false', surveydetail, length, demographic, survey_slug })
                if (length > 0) {
                    this.relatedSurvey(category_id, survey_id);
                }
            })
            .catch((error) => {
                self.setState({ loading: 'false' })
            })
    }
    /**API call for getting same category related survey */
    relatedSurvey = async (category_id, survey_id) => {
        let self = this;
        let relatedsurvey = '';
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let userid = '';
        if (storeitem !== null) {
            userid = storeitem.id;
        }
        await axios.get(API_URL + '/commonApi/relatedsurvey/' + LANGUAGE_CODE + '/' + category_id + '/' + survey_id + '?userid=' + userid)
            .then((response) => {
                relatedsurvey = response.data.success.surveydata;
                self.setState({ relatedsurvey })
            })
            .catch((error) => {
            })
    }
    /** Function for checking if user is logged in or not? also check if quick-click is on and not filled up demographic attribute then restrict for participating in survey */
    checkIsLogIn = async (event, id) => {
        event.preventDefault();
        var s_id = id;
        this.setState({ redirect: 'false', loading: "true" })
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let userid = '';
        let userinfo = '';
        let autoprofile = '';
        let surveyId = "";
        if (storeitem !== null) {
            apitoken = storeitem.token;
            userinfo = storeitem;
            autoprofile = storeitem.autoprofile;
            userid = storeitem.id;
        }
        /**this condition is for checking if user is logged in or not,if not then show popup message to do login */
        if (apitoken === '') {
            this.setState({ loading: 'false' })
            /** It push history in state to redirect to detail page after click on browser back button from the survey participation cover page */
            if (s_id) {
                let check = false;
                check = this.getCookie(s_id)
                // if (check) {
                //     confirmAlert({
                //         customUI: ({ onClose }) => {
                //             return (
                //                 <div className='custom-ui'>
                //                     <div className="close-btn text-right">
                //                         <button className="d-inline-block bg-white border-0 w-auto" onClick={onClose}><i className="ti-close pointer"></i></button>
                //                     </div>
                //                     <p className="mt-3 mb-4">{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_participated_title']}</p>
                //                     <button onClick={onClose}>{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_participated_button']}</button>
                //                 </div>
                //             )
                //         }
                //     })
                // }
                //  else {
                    window.history.pushState(null, document.title, window.location.href);
                    this.setState({ redirectExternal: true })
                // }
            }
        }
        else 
        if (userid !== '') {
            this.setState({ loading: 'true' })
            let title = this.props.match.params.title;
            let isparticipate = '';
            let user_id = "";
            await axios.get(API_URL + '/commonApi/getSurveyBySlug/' + LANGUAGE_CODE + '/' + title + '/' + userid)
                .then((response) => {
                    this.setState({ loading: 'false' })
                    isparticipate = response.data.success.surveydata.isparticipate;
                    surveyId = response.data.success.surveydata.id;
                    user_id = response.data.success.surveydata.user_id;
                    /**this condition is for checking if survey is created by logged in user then can not participate it in his own survey */
                    if (user_id === userid) {
                        confirmAlert({
                            customUI: ({ onClose }) => {
                                return (
                                    <div className='custom-ui'>
                                        <div className="close-btn text-right">
                                            <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                                        </div>
                                        <p className="mt-3 mb-4">{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_his_own_survey_popup_text']}</p>
                                        <button onClick={onClose}>{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_his_own_survey_popup_ok_button']}</button>
                                    </div>
                                )
                            }
                        })
                    }
                    /**this condition is for checking user has participated survey or not if already participated then can not participate again and show popup message */
                    else if (parseInt(isparticipate) === 1) {
                        confirmAlert({
                            customUI: ({ onClose }) => {
                                return (
                                    <div className='custom-ui'>
                                        <div className="close-btn text-right">
                                            <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                                        </div>
                                        <p className="mt-3 mb-4">{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_participated_title']}</p>
                                        <button onClick={onClose}>{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_participated_button']}</button>
                                    </div>
                                )
                            }
                        })
                    }
                    /**this condition is for checking quick-click ,if quick-click on and not filed up require demographic attribute then show popup message */
                    else if (autoprofile === 1 || autoprofile === true) {
                        this.setState({ redirect: 'false' })
                        let demographic = '';
                        let notfilledattribute = [];
                        if (this.state.demographic) {
                            demographic = this.state.demographic;
                            // eslint-disable-next-line
                            Object.keys(demographic).map((attribute, index) => {
                                var attributetitle = attribute;
                                var value = demographic[attribute];
                                if (attributetitle === 'age') {
                                    attributetitle = 'birthday'
                                }
                                if (userinfo.hasOwnProperty(attributetitle)) {
                                    const keytitle = attributetitle;
                                    const attributevalue = userinfo[keytitle]
                                    if (attributevalue === null || attributevalue === '' || attributevalue === undefined || attributevalue === 0) {
                                        notfilledattribute.push(value);
                                    }
                                }
                            })
                            if (notfilledattribute.length > 0) {
                                var attribute = SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_attribute_message']
                                notfilledattribute.map((msg, index) => {
                                    return attribute += "<li>" + msg + "</li>";
                                })
                                confirmAlert({
                                    customUI: ({ onClose }) => {
                                        return (
                                            <div className='custom-ui'>
                                                <div className="close-btn text-right">
                                                    <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                                                </div>
                                                <h2 className="mt-3 mb-4">{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_attribute_title']}</h2>
                                                <p dangerouslySetInnerHTML={{ __html: attribute }}></p>
                                                <button onClick={onClose} >{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_attribute_button']}</button>
                                            </div>
                                        )
                                    }
                                })
                            }
                            else {
                                this.isVisisted(userid, surveyId);
                            }
                        }
                        else {
                            this.isVisisted(userid, surveyId);
                        }
                    }
                    else {
                        this.isVisisted(userid, surveyId);
                    }
                })
                .catch((error) => {
                    this.setState({ loading: 'false' })
                })
        }
        else {
            this.isVisisted(userid, surveyId);
        }
    }
    isVisisted = async (userid, surveyId) => {
        let sessionRedirect = false;
        this.setState({ sessionRedirect, redirect: 'false', loading: 'true' })
        let user_id = userid;
        let survey_id = surveyId;
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
        }
        var data = {
            "survey_id": survey_id,
            "user_id": user_id,
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        let self = this;
        if (user_id && survey_id) {
            // eslint-disable-next-line
            await axios.post(API_URL + "/survey/surveyparticipant"+ "?uId=" + user_id, data, { headers: headers })
                .then((response) => {
                    /** It push history in state to redirect to detail page after click on browser back button from the survey participation cover page */
                    window.history.pushState(null, document.title, window.location.href);
                    self.setState({ redirect: 'true', sessionRedirect, loading: 'false' })
                })
                .catch((error) => {
                    if (error) {
                        if (error.response) {
                            if (error.response.data) {
                                let msg = error.response.data['error']
                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                    self.setState({ loading: 'false', redirect: 'false' })
                                    confirmAlert({
                                        customUI: ({ onClose }) => {
                                            return (
                                                <div className='custom-ui'>
                                                    <div className="close-btn text-right">
                                                        <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                                                    </div>
                                                    <h2 className="mt-3 mb-4">{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_login_title']}</h2>
                                                    <p className="mt-3 mb-4">{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_login_message']}</p>
                                                    <button onClick={(event) => {
                                                        this.redirectToLogin()
                                                        onClose()
                                                    }}>
                                                        {SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_login_button']}</button>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            }
                        }
                    }
                })
        }
    }
    redirectToLogin = () => {
        localStorage.clear();
        this.setState({ sessionRedirect: true })
    }
    render() {
        /**for identifying chrome and safari browser */
        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        var issafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        // eslint-disable-next-line
        let userid = '';
        let autoprofile = "";
        if (storeitem !== null) {
            userid = storeitem.id;
            autoprofile = storeitem.autoprofile;
        }
        let surveydetaildata = '';
        let enddate = '';
        let currentdate = '';
        let demographic = '';
        let demographicattribute = '';
        let relatedsurvey = '';
        let relatedsurveydata = '';
        let title = '';
        // eslint-disable-next-line
        let external_title = "";
        // eslint-disable-next-line
        let external_id = "";
        let length = this.state.relatedsurvey.length;
        /**using map store related survey data in one variable */
        if (this.state.length) {
            if (length) {
                relatedsurvey = this.state.relatedsurvey;
                /*related survey start*/
                relatedsurveydata = relatedsurvey.map((relateddata, index) => {
                    let startdate = relateddata['start_date'].replace(/-/g, '/');
                    let enddate = relateddata['end_date'].replace(/-/g, '/');
                    let currentdate = new Date();
                    enddate = new Date(enddate);
                    startdate = new Date(startdate);
                    var timeDiff = enddate.getTime() - currentdate.getTime();
                    var daysleft = Math.ceil(timeDiff / (1000 * 3600 * 24));
                    daysleft = daysleft + 1;
                    let newDate = new Date();
                    var changeCurrentDate = new Date(newDate.setHours(0, 0, 0, 0));
                    if (Date.parse(startdate) <= Date.parse(changeCurrentDate) && Date.parse(changeCurrentDate) <= Date.parse(enddate)) {
                        let timediff = Math.abs(enddate.getTime() - startdate.getTime());
                        let diffday2 = Math.ceil(timediff / (1000 * 3600 * 24));
                        let totaldate = Math.abs(changeCurrentDate.getTime() - startdate.getTime());
                        let diffday1 = Math.ceil(totaldate / (1000 * 3600 * 24));
                        let cal = diffday1 / (diffday2 + 1) * 100;
                        var currentwidth = cal.toFixed('2');
                    }
                    else if (startdate > currentdate) {
                        currentwidth = '0';
                    }
                    else {
                        currentwidth = '100';
                    }
                    title = relateddata['survey_title_slug'];
                    let isparticipated = relateddata['isparticipate'];
                    let participatemessage = ''
                    if (isparticipated === '1') {
                        participatemessage = SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['alredy_participate'];
                    }
                    let titleNew;
                    if (relateddata['survey_title']) {
                        if (window.innerWidth >= 1200 && window.innerWidth < 1500) {
                            if (relateddata['survey_title'].length > 48) {
                                titleNew = relateddata['survey_title'].substring(0, 48).trim() + "...";
                            } else {
                                titleNew = relateddata['survey_title']
                            }
                        }
                        else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
                            if (relateddata['survey_title'].length > 59) {
                                titleNew = relateddata['survey_title'].substring(0, 59).trim() + "...";
                            } else {
                                titleNew = relateddata['survey_title']
                            }
                        }
                        else if (window.innerWidth >= 768 && window.innerWidth < 992) {
                            if (relateddata['survey_title'].length > 80) {
                                titleNew = relateddata['survey_title'].substring(0, 80).trim() + "...";
                            } else {
                                titleNew = relateddata['survey_title']
                            }
                        }
                        else if (window.innerWidth >= 576 && window.innerWidth < 768) {
                            if (relateddata['survey_title'].length > 152) {
                                titleNew = relateddata['survey_title'].substring(0, 152).trim() + "...";
                            } else {
                                titleNew = relateddata['survey_title']
                            }
                        }
                        else if (window.innerWidth >= 414 && window.innerWidth < 576) {
                            if (relateddata['survey_title'].length > 105) {
                                titleNew = relateddata['survey_title'].substring(0, 105).trim() + "...";
                            } else {
                                titleNew = relateddata['survey_title']
                            }
                        }
                        else if (window.innerWidth >= 320 && window.innerWidth < 414) {
                            if (relateddata['survey_title'].length > 82) {
                                titleNew = relateddata['survey_title'].substring(0, 82).trim() + "...";
                            } else {
                                titleNew = relateddata['survey_title']
                            }
                        }
                        else if (window.innerWidth < 320) {
                            if (relateddata['survey_title'].length > 75) {
                                titleNew = relateddata['survey_title'].substring(0, 75).trim() + "...";
                            } else {
                                titleNew = relateddata['survey_title']
                            }
                        }
                        else {
                            if (relateddata['survey_title'].length > 77) {
                                titleNew = relateddata['survey_title'].substring(0, 77).trim() + "...";
                            } else {
                                titleNew = relateddata['survey_title'];
                            }
                        }
                    }
                    let backImage = IMAGE_BASEURL + "img/100x100.jpg"
                    if (relateddata['thumb_image']) {
                        backImage = relateddata['thumb_image'];
                    }
                    return (<div className="col-md-6 col-lg-4 col-xl-3 blog-lising" key={index}>
                        <div className="blog-item">
                            <Link to={"/umfragen/" + title} onClick={this.scrollUpFunction}>
                                <div className="blog-img position-relative h-220 background-image-css" style={{ "backgroundImage": "url(" + backImage + ")" }}>
                                </div>
                                <div className="blog-content">
                                    <h6 className={(issafari || isChrome) ? "text-dark custom-test-text font-16" : "text-dark text-hyphens font-16"}>{(issafari || isChrome) ? relateddata['survey_title'] : titleNew}</h6>
                                </div>
                                {(relateddata['userimage']) ? <div class="user_profile_photo"><div class="login-profile profile_pic">
                                    <img src={relateddata['userimage']} className="rounded-circle center" alt="person-img" width="70" height="70" />
                                </div></div>
                                    : null}

                                {(isparticipated === '1') ?
                                    <div className="progress custom-main-progress">
                                        <span className="progress-value custom-progress">{participatemessage}</span>
                                        <div className="progress-bar progress-bar-striped" role="progressbar" style={{ "ariaValuenow": "10", "ariaValuemin": "0", "ariaValuemax": "100" }}></div>
                                    </div>
                                    : <div className="progress">
                                        <span className="progress-value">{participatemessage}</span>
                                        <div className="progress-bar progress-bar-striped" role="progressbar" style={{ "width": currentwidth + "%", "ariaValuenow": "10", "ariaValuemin": "0", "ariaValuemax": "100" }}></div>
                                    </div>
                                }
                                <div className="blog-content d-flex">
                                    <ul className="list-unstyled mb-2 mr-4">
                                        <li className="text-gray mb-2 d-flex"><i className="ti-star mr-0 mr-3 font-18"></i><div className="blog__card-title d-block"><span className="d-block">{relateddata['karmapoint']}</span> {SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['karmapoint']}</div></li>
                                        <li className="text-gray d-flex"><i className="ti-user mr-3 font-18"></i><div className="blog__card-title d-block"><span className="d-block">{relateddata['totaluser']}</span> {SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['totaluser']}</div></li>
                                    </ul>
                                    <ul className="list-unstyled mb-2">
                                        <li className="text-gray mb-2 d-flex"><i className="ti-time mr-3 font-18"></i><div className="blog__card-title d-block"><span className="d-block">{relateddata['duration']}</span> {SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['duration']}</div></li>
                                        <li className="text-gray d-flex"><i className="ti-tag mr-3 font-18"></i><div className="blog__card-title d-block"><span className="d-block">{daysleft}</span>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['daysleft']}</div></li>
                                    </ul>
                                </div>{/* /blog-content */}
                            </Link>
                        </div>
                    </div>)
                })
            }
        }
        /*related survey end*/
        if (this.state.length) {
            surveydetaildata = this.state.surveydetail;
            title = this.state.survey_slug;
            external_title = window.btoa(surveydetaildata.survey_title_slug);
            external_id = window.btoa(surveydetaildata.id);
            demographic = this.state.demographic;
            /**displaying demographic attributes */
            if (demographic !== undefined) {
                demographicattribute = Object.keys(demographic).map((attribute, index) => {
                    return ((attribute !== 'data') ?
                        <li className="d-inline-block tag-cloud-link text-dark font-14" key={index}>
                            <i className="ti-check mr-1 font-weight-bold"></i> {demographic[attribute]}
                        </li> : null
                    )
                })
            }
            enddate = surveydetaildata.end_date.replace(/-/g, '/');
            enddate = new Date(enddate);
            currentdate = new Date();
            var timeDiff = enddate.getTime() - currentdate.getTime();
            var daysleft = Math.ceil(timeDiff / (1000 * 3600 * 24));
            daysleft = daysleft + 1;
            var startdate = ""
            if (surveydetaildata) {
                if (surveydetaildata.start_date) {
                    var datePart = surveydetaildata.start_date.match(/\d+/g),
                        year = datePart[0].substring(0), // get only two digits
                        month = datePart[1], day = datePart[2];
                    startdate = day + '.' + month + '.' + year;
                }
            }
        }
        let headerImage = IMAGE_BASEURL + "img/100x100.jpg";
        if (surveydetaildata) {
            if (surveydetaildata.survey_header_image && surveydetaildata.survey_header_image !== '') {
                headerImage = surveydetaildata.survey_header_image;
            }
        }
        return (
            // {/* Main */}
            <main>
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {(this.state.length === 0) ?
                    <Redirect to='/umfragen'></Redirect>
                    : null
                }
                {(this.state.sessionRedirect === true) ?
                    <Redirect to="/anmelden"></Redirect>
                    : null
                }
                {/* - title start - */}
                {(this.state.length > 0 && this.state.loading === 'false') ?
                    <div>
                        {// eslint-disable-next-line
                        }<section className="banner_text d-flex align-items-center survey-detal-bg-img background-image-css" style= {{"backgroundImage": "url(" + headerImage + ")" }}>
                            <div className="container-fluid px-0">
                                <div className="page-title">
                                    <h1 className="text-white font-weight-bold mb-0 without-word-break survey-title-sm">{surveydetaildata.survey_title}</h1>
                                </div>
                            </div>
                        </section>
                        {/*- title end -*/}
                        {/* tags section start */}
                        <section className="tags-section">
                            <div className="container container-large">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="d-inline-block text-center">
                                        <div className="tags text-center d-inline-block m-2 m-lg-2">
                                            <span className="text-dark text-uppercase bg-gray d-sm-flex align-items-center justify-content-center"><i className="ti-tag"></i> {surveydetaildata.survey_category_name}</span>
                                        </div>

                                        <div className="tags text-center d-inline-block m-2 m-lg-2">
                                            <span className="text-dark text-uppercase bg-gray d-sm-flex align-items-center justify-content-center"><i className="ti-calendar"></i> {startdate}</span>
                                        </div>

                                        <div className="tags text-center d-inline-block m-2 m-lg-2">
                                            <span className="text-dark text-uppercase bg-gray d-sm-flex align-items-center justify-content-center"><i className="ti-time"></i> {surveydetaildata.duration} {SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['minute']}</span>
                                        </div>
                                        <div className="tags text-center d-inline-block m-2 m-lg-2">
                                            <span className="text-dark text-uppercase bg-gray d-sm-flex align-items-center justify-content-center"><i className="ti-user"></i> {surveydetaildata.totaluser} {SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['totalparticipants']}</span>
                                        </div>
                                        <div className="tags text-center d-inline-block m-2 m-lg-2">
                                            <span className="text-dark text-uppercase bg-gray d-sm-flex align-items-center justify-content-center"><i className="ti-alarm-clock"></i> {daysleft} {SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['daysleft']}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* tags section end */}
                        {/* survey-detials */}
                        <section className="survey-detials">
                            <div className="container container-large">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-8">
                                        <div className="beschreibung">
                                            <h2 className="text-dark font-weight-bold word-break-text">{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['description']}</h2>
                                            <p className="text-dark font-18 mt-3 mb-0 word-break-text" dangerouslySetInnerHTML={{ __html: surveydetaildata.about_survey_text.replace(/<br\s*\/?>/mg, "").replace( /(<([^>]+)>)/ig, '').replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                                        </div>
                                        <div className="zielgruppe">
                                            <h2 className="text-dark font-weight-bold word-break-text">{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['targetgroup_requirements']}</h2>
                                            <p className="text-dark font-18 mt-3 mb-0 word-break-text" dangerouslySetInnerHTML={{ __html: surveydetaildata.about_target_group_text.replace(/<br\s*\/?>/mg, "").replace( /(<([^>]+)>)/ig, '').replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-4 text-center">
                                        <div className="author-details">
                                            <div className="author-content">
                                                <img src={(surveydetaildata.userimage) ? surveydetaildata.userimage : IMAGE_BASEURL + "img/user_profile.png"} className="rounded-circle custom-survey-detail" alt="person-img" width="135" height="135" />
                                                <h6 className="text-dark font-weight-bold mb-0 mt-3">{surveydetaildata.user_name}</h6>
                                                <p className="font-18 mb-0 text-dark mt-0">{surveydetaildata.university_name}</p>
                                                <div className="strat-survey mt-0 mt-lg-4">
                                                    {(this.state.redirect === 'true') ?
                                                        <Redirect to={"/umfragen/" + surveydetaildata.survey_title_slug + "/" + URL_CONSTANT['start']} />
                                                        :
                                                        (this.state.redirectExternal) ? <Redirect to={"/s/" + surveydetaildata.survey_identifier}></Redirect> : null
                                                    }
                                                    <button onClick={(e) => this.checkIsLogIn(e, surveydetaildata.id)} className="text-uppercase btn btn-clr text-white font-18 no-shadow" title={SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['survey_start_button']}>{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['survey_start_button']}</button>
                                                </div>
                                            </div>
                                            {(this.state.demographic && autoprofile) ?
                                                <div className="features">
                                                    <h3 className="text-dark font-weight-bold">{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['queried_features']}</h3>
                                                    <div className="features-tags text-center">
                                                        <ul className="wp-tag-cloud list-unstyled d-inline-block mt-3 small-screen-demographic">
                                                            {demographicattribute}
                                                        </ul>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* survey-detials end */}
                        <section className="main-blog">
                            {(relatedsurveydata.length > 0) ?
                                <div className="container container-large">
                                    <div className="row">
                                        <div className="col-12">
                                            <h2 className="text-dark font-weight-bold">{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['similar_surveys']}</h2>
                                        </div>
                                    </div>
                                    <div className="row my-4 my-xl-5">
                                        {relatedsurveydata}
                                    </div>
                                </div>
                                : null
                            }
                        </section>
                    </div>
                    : null
                }
            </main>
            // {/* Main end */}
        );
    }
}
export default SurveyDetail;