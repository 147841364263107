import axios from "axios";
import {API_URL} from '../../constants/DotEnv'
import React, { Component } from "react";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import { Button } from "react-bootstrap";
import {MONTHLY_AMOUNT, ANNUAL_AMOUNT, BASEURL} from '../../constants/DotEnv'
import { BUBBLE_LOADING } from "../../constants/FrontEndConstant";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaymentLoading: false,
      client_secret:"",
      payment_id: '',
      loading: false,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    const storeitem = JSON.parse(localStorage.getItem('userdata'));
    var billing_info = JSON.parse(localStorage.getItem('billing_info'));
    var amount =  this.props.total
    var final_amount = amount.replace(",",'.')
    const userId = storeitem.id;
    let apitoken = storeitem.token;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + apitoken
    };
      try {
        const planType = this.props.total === MONTHLY_AMOUNT ? 0 : this.props.total === ANNUAL_AMOUNT ? 1 : 0;
        localStorage.setItem("plan_type", planType )
        const res = await axios.post(API_URL + '/payment/giropay/' + userId+ '?uId=' + userId, {first_name:this.props.first_name, last_name:this.props.last_name, price:final_amount, postalcode_id:this.props.postalcode_id, plan_type: planType, email: storeitem.email, name : this.props.first_name + " " + this.props.last_name},
          { headers: headers }).then((res) => {
            this.setState({client_secret: res.data.success.intent.client_secret})
          });
      } catch (err) {
        console.log(err);
      }
      const { stripe, elements } = this.props;
      if (!stripe || !elements) {
        return;
      }
          this.setState({loading: true})
          let client_secret = this.state.client_secret;
          const paymentIntent = stripe.confirmGiropayPayment(client_secret,
            {
              payment_method: {
                billing_details: {
                  name: billing_info.first_name + " " + billing_info.last_name,
                  address: {
                    line1:  billing_info.street + " " + billing_info.house_number + "," ,
                    // city: 'San Francisco',
                    postal_code: billing_info.postalcode,
                    // state: 'CA',
                    // country: 'US',
                  },
                  email : storeitem.email,
                },
              },
              return_url: BASEURL + "checkout"
            }
          )
          localStorage.setItem('price',final_amount)
          if (paymentIntent.error) {
            this.setState({error_msg: paymentIntent.error.message })
          }
  }
  render() {
    return (
      <>
      {this.state.loading == true? BUBBLE_LOADING : null}
      <form
        className="w-100 m-auto p-3 "
        onSubmit={this.handleSubmit}
      >
        <Button
          className="btn btn-dark btn-block text-center p-2 mt-2"
          style={{
            cursor: "pointer",
          }}
          onClick={this.handleSubmit}
        >
          {this.state.isPaymentLoading ? "Loading..." : "Bezahlen"}
        </Button>
      </form>
      </>
    );
  }
}
function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} {...props} />
      )}
    </ElementsConsumer>
  );
}
export default InjectedCheckoutForm;