import { CHART_HEIGHT, CHART_WIDTH, SURVEY_PARTICIPATION } from '../../constants/FrontEndConstant'
import { LANGUAGE_CODE, CHART_API } from '../../constants/DotEnv'
import * as htmlToImage from 'html-to-image'
import axios from 'axios'
import { API_URL } from '../../constants/DotEnv'
const empirioCanvasLogo = require('./empirioLogo-195X50.png').default
const empirioCanvasSmallLogo = require('./canvasLogo.png').default
const empirioCanvasMediumLogo = require('./canvasLogo-180x65.png').default
// eslint-disable-next-line
export default function (elementId, userNumber = 10, isFreeTextQuestion = false, chartData, NewChartData) {
	if (!isFreeTextQuestion) {
		// const createLink = document.createElement("button");
		// createLink.setAttribute("class","border-0 bg-transparent font-weight-bold pointer");
		// createLink.textContent = SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel'];
		// document.getElementById(elementId).innerHTML="";
		// document.getElementById(elementId).appendChild(createLink);
		// createLink.insertAdjacentHTML('afterbegin', '<i class="ti-download text-dark pr-2"></i>');
		const buttonElement = document.getElementById(elementId)
		if (buttonElement) {
			buttonElement.removeChild(buttonElement.firstElementChild)
			buttonElement.insertAdjacentHTML('afterbegin', '<i class="ti-download text-dark pr-2"></i>')
			document.getElementById(elementId).disabled = false
			document.getElementById(elementId).addEventListener('click', () => {
				if (!isFreeTextQuestion) {
					// downloadEditedImage(imageString, userNumber,isFreeTextQuestion,chartData,NewChartData )
					createAndDownloadCanvas(chartData, NewChartData)
				} else {
					htmlToImage
						.toCanvas(document.getElementById(elementId.replace('download_', '')), {
							filter: filterHtmlImage,
							style: { 'font-family': 'sans-serif' },
						})
						.then(function (dataUrl) {
							// downloadEditedImage(dataUrl.toDataURL(), userNumber, isFreeTextQuestion,chartData,NewChartData)
							createAndDownloadCanvas(chartData, NewChartData)
						})
				}
			})
			document.getElementById(elementId).addEventListener('load', () =>
				htmlToImage
					.toCanvas(document.getElementById(elementId.replace('download_', '')), { filter: filterHtmlImage })
					.then(function (dataUrl) {
						// downloadEditedImage(dataUrl.toDataURL(), userNumber, isFreeTextQuestion,chartData,NewChartData)
						createAndDownloadCanvas(chartData, NewChartData)
					}),
			)
		}
	}
}

function removeElementById(elementId) {
	setTimeout(() => {
		document.getElementById(elementId).remove()
	}, 1000)
}

function filterHtmlImage(node) {
	if (node.tagName === 'DIV') {
		if (node.getAttribute('name') !== 'hidden-tag-chart') {
			return node
		}
	} else {
		return node
	}
}

async function createAndDownloadCanvas(chartData, NewChartData) {
	let survey_color = localStorage.getItem('survey_color')
	var chartOriginalData = NewChartData
	var new_chart_data = ''
	var newChartOptionArray = []
	if (chartOriginalData['data'].length) {
		chartOriginalData['data'].forEach((chart, index) => {
			if (chart.length > 3) {
				new_chart_data = chartOriginalData['data'][index].slice(0, -1)
				new_chart_data = chartOriginalData['data'][index].slice(0, -2)
				newChartOptionArray.push(new_chart_data)
			} else {
				new_chart_data = chartOriginalData['data'][index].slice(0, -1)
				newChartOptionArray.push(new_chart_data)
			}
		})
	}
	newChartOptionArray.shift()
	var data = ''
	var Api_request_header = {
		'Content-Type': 'application/json',
	}
	// NewChartData['options']['vAxis']['interval']=20
	NewChartData['options']['hAxis']['textStyle']['fontName'] = 'Arial'
	NewChartData['options']['vAxis']['textStyle']['fontName'] = 'Arial'
	if (NewChartData['options']['titleTextStyle']) {
		NewChartData['options']['titleTextStyle']['fontName'] = 'Arial'
	}
	var chartArea = encodeURIComponent(JSON.stringify({ top: '150', left: '90', right: '50' }))
	var titleText = NewChartData['options']['titleTextStyle']
		? ',"titleTextStyle":' + encodeURIComponent(JSON.stringify(NewChartData['options']['titleTextStyle']))
		: ',"titleTextStyle":' +
		encodeURIComponent(JSON.stringify({ fontSize: '24', fontName: 'Arial', bold: true, color: '#4a4a4a' }))
	if (newChartOptionArray && NewChartData['options']) {
		const Uri =
			CHART_API +
			'columnChart?path=/tmp/columnChart.png&width=' +
			CHART_WIDTH +
			'&height=' +
			CHART_HEIGHT +
			'&participated=' +
			NewChartData['user_participated'] +
			'&header=[["string", ""],["number",""]]&hAxis=' +
			encodeURIComponent(JSON.stringify(NewChartData['options']['hAxis'])) +
			'&yAxis=' +
			encodeURIComponent(JSON.stringify(NewChartData['options']['vAxis'])) +
			'&data=' +
			encodeURIComponent(JSON.stringify(newChartOptionArray)) +
			'&options={"chartArea":' +
			chartArea +
			',"title":' +
			encodeURIComponent(JSON.stringify(NewChartData['options']['title'] + '\n\n\n')) +
			',"legend" : "none"' +
			titleText +
			'}' +
			'&colors=' +
			encodeURIComponent(survey_color)

		// const googleChartApi = `localhost:4000/pieChart?width=600&height=400&path=/tmp/pie.png&data=[["Yellow", 130], ["Red", 90], ["Orange", 40], ["Brown", 39], ["Blue", 36], ["Magenta", 35]]&options={"title": "Colors Used", "pieHole": true}`
		await axios
			.post(API_URL + '/chartvisualizer', { url: Uri }, { headers: Api_request_header })
			.then((response) => {
				data = response.data
			})
			.catch((error) => { })
	}
	var date = new Date()
	if (navigator.msSaveBlob) {
		// IE 10+
		// var file_path =  canvas.toDataURL("image/png", 1)
		var file_path = 'data:image/png;base64,' + data.success
		let filepath = file_path.split('/')[1]
		const b64Data = atob(file_path.split(',')[1])
		var arraybuffer = new ArrayBuffer(b64Data.length)
		var getunitArray = new Uint8Array(arraybuffer)
		for (var i = 0; i < b64Data.length; i++) {
			getunitArray[i] = b64Data.charCodeAt(i)
		}
		navigator.msSaveBlob(
			new Blob([arraybuffer], { type: 'text/' + filepath.split('.')[1] }),
			'empirio_' + date.getTime() + '.png',
		)
	} else {
		if (data) {
			const newImageDataString = 'data:image/png;base64,' + data.success
			const anchor = document.createElement('a')
			anchor.setAttribute('href', newImageDataString)
			anchor.setAttribute('download', 'empirio_' + date.getTime() + '.png')
			anchor.setAttribute('style', 'display:none')
			const canvasId = Math.ceil(Math.random() * 100)
			anchor.setAttribute('id', canvasId)
			anchor.text = 'Download'
			document.body.append(anchor)
			document.getElementById(canvasId).click()
			removeElementById(canvasId)
		}
	}
}

async function createSaveCanvas(
	name,
	saveFunction,
	isLast,
	chartData,
	isFreeTextQuestion = false,
	NewChartData,
	newImageDataString,
) {
	saveFunction(newImageDataString, name, isLast, chartData)
}

function drawImage(ctx, image, coordinateX, coordinateY, callback) {
	const img = new Image()
	img.addEventListener(
		'load',
		() => {
			ctx.drawImage(img, coordinateX, coordinateY)
			callback()
		},
		false,
	)
	img.src = image
}

export const downloadEditedImage = (imageString, userNumber = 0, isFreeTextQuestion, chartData, NewChartData) => {
	// const can = document.createElement("canvas");
	// const ctx = can.getContext("2d");
	// const img = new Image();
	// img.addEventListener(
	// 	"load",
	// 	() => {
	// 		can.height = isFreeTextQuestion ? img.height : img.height + 50;
	// 		can.width = img.width;
	// 		ctx.font = isFreeTextQuestion ? "17px sans-serif" : "17px Poppins";
	// 		ctx.fillStyle = "#ffffff";
	// 		ctx.fillRect(0, 0, img.width, isFreeTextQuestion ? img.height : img.height + 50);
	// 		drawImage(ctx, imageString, 0, 0, () =>{
	// 			ctx.fillStyle = "#000000";
	// 			ctx.fillText("n = " + userNumber, can.width * 0.10, isFreeTextQuestion ? img.height - 30 : img.height + 15);
	// 			drawImage(
	// 				ctx,
	// 				!isFreeTextQuestion && window.innerWidth <= 575 ? empirioCanvasSmallLogo : empirioCanvasLogo,
	// 				window.innerWidth <= 575 ? can.width - (can.width * 0.50) : can.width - (can.width * 0.29),
	// 				isFreeTextQuestion ? img.height - 70 : (window.innerWidth <= 575 ? img.height : img.height - 20),
	// 				() => createAndDownloadCanvas(can,chartData,NewChartData)
	// 			)
	// 		});
	// 	},
	// 	false
	// );
	// img.src = imageString;
}

export const saveEditedImage = (
	imageString,
	chartData,
	name,
	saveFunction,
	isLast,
	isFreeTextQuestion = false,
	NewquestionData,
	newImageDataString,
) => {
	const can = document.createElement('canvas')
	const ctx = can.getContext('2d')
	const img = new Image()
	let userNumber = 0
	if (chartData.user_participated) {
		userNumber = chartData.user_participated
	}
	img.addEventListener(
		'load',
		() => {
			can.height = isFreeTextQuestion ? img.height : img.height + 50
			can.width = img.width
			ctx.font =
				isFreeTextQuestion && window.innerWidth <= 575
					? '38px sans-serif'
					: isFreeTextQuestion
						? '17px sans-serif'
						: '17px Poppins'
			ctx.fillStyle = '#ffffff'
			ctx.fillRect(0, 0, img.width, isFreeTextQuestion ? img.height : img.height + 50)
			drawImage(ctx, imageString, 0, 0, () => {
				ctx.fillStyle = '#000000'
				ctx.fillText('n = ' + userNumber, can.width * 0.1, isFreeTextQuestion ? img.height - 30 : img.height + 15)
				drawImage(
					ctx,
					window.innerWidth <= 575
						? isFreeTextQuestion
							? empirioCanvasMediumLogo
							: empirioCanvasSmallLogo
						: empirioCanvasLogo,
					window.innerWidth <= 575 ? can.width - can.width * 0.5 : can.width - can.width * 0.29,
					isFreeTextQuestion ? img.height - 70 : window.innerWidth <= 575 ? img.height : img.height - 20,
					() =>
						createSaveCanvas(
							can,
							name,
							saveFunction,
							isLast,
							chartData,
							(isFreeTextQuestion = false),
							NewquestionData,
							newImageDataString,
						),
				)
			})
		},
		false,
	)
	img.src = imageString
}
export const getActualImageString = async (smallScreenQuestionList, last_char) => {
	let survey_color = localStorage.getItem('survey_color')

	let newImageDataString = ''
	var chartOriginalData = smallScreenQuestionList
	var new_chart_data = ''
	var updatedChartOptionArray = []
	if (chartOriginalData['data' + last_char]) {
		if (chartOriginalData['data' + last_char].length) {
			chartOriginalData['data' + last_char].forEach((chart, index) => {
				if (chart.length > 3) {
					new_chart_data = chartOriginalData['data' + last_char][index].slice(0, -1)
					new_chart_data = chartOriginalData['data' + last_char][index].slice(0, -2)
					updatedChartOptionArray.push(new_chart_data)
				} else {
					new_chart_data = chartOriginalData['data' + last_char][index].slice(0, -1)
					updatedChartOptionArray.push(new_chart_data)
				}
			})
		}
		updatedChartOptionArray.shift()
		var data = ''
		var Api_request_header = {
			'Content-Type': 'application/json',
		}
		var option = last_char ? 'option' + last_char : 'options'
		var chartArea = encodeURIComponent(JSON.stringify({ top: '150', left: '90', right: '50' }))
		smallScreenQuestionList[option]['hAxis']['textStyle']['fontName'] = 'Arial'
		smallScreenQuestionList[option]['vAxis']['textStyle']['fontName'] = 'Arial'
		if (smallScreenQuestionList[option]['titleTextStyle']) {
			smallScreenQuestionList[option]['titleTextStyle']['fontName'] = 'Arial'
		}
		var titleText = smallScreenQuestionList[option]['titleTextStyle']
			? ',"titleTextStyle":' + encodeURIComponent(JSON.stringify(smallScreenQuestionList[option]['titleTextStyle']))
			: ',"titleTextStyle":' +
			encodeURIComponent(JSON.stringify({ fontSize: '24', fontName: 'Arial', bold: true, color: '#4a4a4a' }))
		if (updatedChartOptionArray && smallScreenQuestionList[option]) {
			var generatedImageString = ''
			try {
				const uri =
					CHART_API +
					'columnChart?path=/tmp/columnChart.png&width=' +
					CHART_WIDTH +
					'&height=' +
					CHART_HEIGHT +
					'&participated=' +
					smallScreenQuestionList['user_participated'] +
					'&header=[["string", ""],["number",""]]&hAxis=' +
					encodeURIComponent(JSON.stringify(smallScreenQuestionList[option]['hAxis'])) +
					'&yAxis=' +
					encodeURIComponent(JSON.stringify(smallScreenQuestionList[option]['vAxis'])) +
					'&data=' +
					encodeURIComponent(JSON.stringify(updatedChartOptionArray)) +
					'&options={"chartArea":' +
					chartArea +
					',"title":' +
					encodeURIComponent(JSON.stringify(smallScreenQuestionList[option]['title'] + '\n\n\n')) +
					',"legend" : "none"' +
					titleText +
					'}' +
					'&colors=' +
					encodeURIComponent(survey_color)
				// const googleChartApi2 = `localhost:4000/pieChart?width=600&height=400&path=/tmp/pie.png&data=[["Yellow", 130], ["Red", 90], ["Orange", 40], ["Brown", 39], ["Blue", 36], ["Magenta", 35]]&options={"title": "Colors Used", "pieHole": true}`
				const response = await axios.post( API_URL + '/chartvisualizer', { url: uri }, { headers: Api_request_header })
				data = response.data
				newImageDataString = 'data:image/png;base64,' + data.success
				generatedImageString = newImageDataString
				if (generatedImageString) {
					return generatedImageString
				}
			} catch (error) { }
		}
	}
}