import React, { Fragment } from 'react';
import axios from "axios";
import { SURVEY_CREATION_CONSTANT, BUBBLE_LOADING } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL } from '../../../../constants/DotEnv';
import Redirect from 'react-router-dom/Redirect';
import SurveyUploadLogo from './SurveyUploadLogo.js';
import SurveyColorpicker from './SurveyColorpicker'
class SurveyCustomLogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 'false',
            img_error: '',
            survey_logo: '',
            survey_optional_logo: '',
            show_coverImage: false,
            show_upperImage: false,
            background: '' 
        }
    }

    /** this function is for scrolling up page */
    scrollUpFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    handleUpload = async (event) => {
        const fileInputId = document.getElementById(event.target.id);
        if (fileInputId.files.length) {
            this.setState({ loading: 'true' })
            let formData = new FormData();
            if (event.target.id == "survey_logo") {
                formData.append("survey_logo", event.target.files[0]);
            } else {
                formData.append("survey_optional_logo", event.target.files[0]);
            }
            let survey_id = this.props.data.id
            let storeitem = JSON.parse(localStorage.getItem('userdata'));
            let apitoken = '';
            let userId = '';
            if (storeitem !== null) {
                apitoken = storeitem.token;
                userId = storeitem.id;
            }
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + apitoken
            }
            await axios.post(API_URL + '/survey/nsulogo/' + LANGUAGE_CODE + '/' + survey_id + '/' + userId + '?uId=' + userId, formData, { headers: headers })
                .then(async (response) => {
                    let surveyData = JSON.parse(localStorage.getItem('surveycreationdata_' + survey_id));
                    surveyData['survey_logo'] = response.data.surveydata.survey_logo
                    surveyData['survey_optional_logo'] = response.data.surveydata.survey_optional_logo
                    JSON.parse(localStorage.setItem('surveycreationdata_' + survey_id, JSON.stringify(surveyData)));
                    this.setState({ img_error: null })
                })
                .catch((error) => {
                    if (error) {
                        if (error.response) {
                            if (error.response.data) {
                                let msg = error.response.data['error']
                                this.setState({ img_error: msg })
                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                    localStorage.clear();
                                    this.setState({ redirect: 'true' })
                                }
                            }
                        }
                    }
                    this.setState({ loading: 'false' })
                })
            this.setState({ loading: 'false' })
        }
    }
    show_logo = (value) => {
        var surveyData = this.props.data;
        var survey_data = JSON.parse(localStorage.getItem('surveycreationdata_' + surveyData.id));
        if (survey_data.survey_logo == null) {
            this.setState({ show_coverImage: false })
            this.setState({ survey_logo: null })
        }
        if (survey_data.survey_optional_logo == null) {
            this.setState({ show_upperImage: false })
            this.setState({ survey_optional_logo: null })
        }
    }
    surveyColor = (color) => {
        var surveyData = this.props.data;
        var survey_data = JSON.parse(localStorage.getItem('surveycreationdata_' + surveyData.id));
        this.setState({ background: color.hex });
        survey_data['survey_color'] = color.hex
        localStorage.setItem('surveycreationdata_' + surveyData.id, JSON.stringify(survey_data))
        window.dispatchEvent(new Event("storage"));
    }
    componentDidMount(){
        var surveyData = this.props.data;
        var survey_data = JSON.parse(localStorage.getItem('surveycreationdata_' + surveyData.id));
        if(survey_data.survey_color && survey_data.survey_color !== null){
            this.setState({ background: survey_data.survey_color})
        }else{
            this.setState({ background: "#00b3f4"})
        }
    }

    render() {
        var surveyData = this.props.data;
        var survey_data = JSON.parse(localStorage.getItem('surveycreationdata_' + surveyData.id));
        let survey_logo;
        let survey_optional_logo;
        let show_coverImage = this.state.show_coverImage;
        let show_upperImage = this.state.show_upperImage;
        if (survey_data.survey_logo !== null && survey_data.survey_logo !== undefined) {
            survey_logo = survey_data.survey_logo
            show_coverImage = true
        } else {
            show_coverImage = false
        }
        if (survey_data.survey_optional_logo !== null && survey_data.survey_optional_logo !== undefined) {
            survey_optional_logo = survey_data.survey_optional_logo;
            show_upperImage = true
        } else {
            show_upperImage = false
        }
        return (
            <Fragment>
                    {(this.state.redirect === 'true') ?
                        <Redirect to="/anmelden"></Redirect>
                        : null
                    }
                    {(this.state.loading === 'true') ?
                        BUBBLE_LOADING
                        : null
                    }
                <div className={"hs-form-field custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")}>
                    <h2 className="text-dark h3 font-weight-bold survey-title">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_customLogo_heading']}</h2>
                    <h3 className="font-14 lh-24 fw-400 survey-subtitle">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_customLogo_subheading']}</h3>
                    <div id="errorSurveyMessage" className="error-text mt-2">{(this.props.validationMessage) ? <p>{this.props.validationMessage}</p> : null}</div>
                    <div id="errorSurveyMessage" className="error-text mt-2">{(this.state.img_error) ? <p>{this.state.img_error}</p> : null}</div>
                    <div className="row no-gutters survey-form-detail">
                        <div className="col-12">
                            <div className="row survey-picture">
                                <SurveyUploadLogo logo={survey_logo} handleUpload={this.handleUpload} id={"survey_logo"} survey_id={this.props.data.id} show_logo={this.show_logo} data={show_coverImage} logo_number={1} />
                                <SurveyUploadLogo logo={survey_optional_logo} handleUpload={this.handleUpload} id={"survey_optional_logo"} survey_id={this.props.data.id} show_logo={this.show_logo} data={show_upperImage} logo_number={2}/>
                            </div>
                        </div>
                    </div>
                    <h2 className="text-dark h3 font-weight-bold survey-title" style={{ "marginTop": "45px" }}>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['Corporate_Identity_Color_Picker']}</h2>
                    <h3 className="font-14 lh-24 fw-400 survey-subtitle">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['Corporate_Identity_Color_Picker_subheading']}</h3>
                    <SurveyColorpicker functioncolor={this.surveyColor} color={this.state.background}/>
                </div>
            </Fragment>
        );
    }
}
export default SurveyCustomLogo;