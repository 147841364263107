import React, { Fragment } from 'react';
import SurveyContentPageText from '../surveyContentPage/SurveyContentPageText';
import SurveyContentPageImage from '../surveyContentPage/SurveyContentPageImage';
import SurveyContentPageHeader from '../surveyContentPage/SurveyContentPageHeader';
import { SURVEY_CREATION_CONSTANT } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
class SurveyContentPage extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: 'false',
            componentTitle: '',
        }
    }
    surveyContent = (onClickFunction, data, AllData, index, title) => {
        switch (title) {
            case "header":
                return <SurveyContentPageHeader fieldName={this.props.fieldName} index={index} AllData={AllData} data={data} onUpdate={this.props.onUpdate} onClick={onClickFunction} />
            case "text":
                return <SurveyContentPageText fieldName={this.props.fieldName} index={index} AllData={AllData} data={data} onUpdate={this.props.onUpdate} onClick={onClickFunction} />
            case "image":
                return <SurveyContentPageImage fieldName={this.props.fieldName} index={index} AllData={AllData} data={data} onUpdate={this.props.onUpdate} errorMessage={this.props.errorMessage} onClick={onClickFunction} />
            default:
        }
    }
    changeContentData = (event) => {
        let componentTitle = event.target.value;
        this.setState({ componentTitle })
    }
    render() {
        let data = this.props.data;
        let AllData = this.props.stateData;
        let onClickFunction = this.props.onClick;
        let elementData = [];
        let title = '';
        var max;
        // eslint-disable-next-line
        var limit;
        if (this.props.fieldName) {
            onClickFunction = this.props.onUpdate;
            max = AllData.questionContentDataLength;
            limit = AllData.questionContentDataLimit;
        }
        else {
            max = AllData.contentDataLength;
            limit = AllData.contentDataLimit;
        }
        /**Data is question data for fieldName (question part) */
        for (let i = 0; i < max; i++) {
            if (data.surveyContent) {
                if (data.surveyContent[i]) {
                    if (data.surveyContent[i].hasOwnProperty("content_text") && data.surveyContent[i].is_header === 0) {
                        title = "text";
                    }
                    if (data.surveyContent[i].hasOwnProperty("content_image")) {
                        title = "image";
                    }
                    if (data.surveyContent[i].hasOwnProperty("content_text") && data.surveyContent[i].is_header === 1) {
                        title = "header";
                    }
                }
            }
            elementData.push(
                this.surveyContent(onClickFunction, data, AllData, i, title)
            )
        }
        const elementComponent = <div className="col-12">
            {elementData}
        </div >;
        return (
            <Fragment>
                <div className={((this.props.design) ? "survey-form-detail" : "hs-form-field") + ((!this.props.noAnimation) ? (" custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")) : "")}>
                    {(!this.props.fieldName) ?
                        <h2 className="text-dark h3 font-weight-bold survey-title">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_welcome_page_content_page_title']}</h2>
                        : null
                    }
                    {(!this.props.fieldName) ?
                        <h3 className="font-14 lh-24 fw-400 survey-subtitle">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_welcome_page_content_page_subtitle']}</h3>
                        : null
                    }
                    <div className="survey-form-detail row">
                        <div className="col-12 col-md-4 plus-icon">
                            <div className="custom-radio-btn btn w-100">
                                <div className="custom-control custom-radio custom-control-inline pl-0 w-100">
                                    <input type="radio" onClick={this.props.onUpdate} name={"addheader"} className="custom-control-input" id="customRadioInline10" value="text" />
                                    <label className="custom-control-label mb-0" htmlFor="customRadioInline10"><span className="ml-4">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_content_page_header_button']}</span></label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 pt-3 pt-md-0 plus-icon">
                            <div className="custom-radio-btn btn w-100">
                                <div className="custom-control custom-radio custom-control-inline pl-0 w-100">
                                    <input type="radio" onClick={this.props.onUpdate} name={"addtext"} className="custom-control-input" id="customRadioInline12" value="text" />
                                    <label className="custom-control-label mb-0" htmlFor="customRadioInline12"><span className="ml-4">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_content_page_text_button']}</span></label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 pt-3 pt-md-0 plus-icon">
                            <div className="custom-radio-btn btn w-100">
                                <div className="custom-control custom-radio custom-control-inline pl-0 w-100">
                                    <input type="radio" onClick={this.props.onUpdate} name={"addimage"} className="custom-control-input" id="customRadioInline11" value="image" />
                                    <label className="custom-control-label mb-0" htmlFor="customRadioInline11"><span className="ml-4">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_content_page_image_button']}</span></label>
                                </div>
                            </div>
                        </div>
                        {(this.props.validationMessage) ? <div id="errorSurveyMessage" className="error-text mt-3 ml-3"> <p className="mb-0">{this.props.validationMessage}</p> </div> : null}
                        {(this.props.errorMessage && !this.props.validationMessage) ? <div id="errorSurveyMessage" className="error-text mt-3 ml-3"> <p className="mb-0">{this.props.errorMessage}</p> </div> : null}
                        {elementComponent}
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default SurveyContentPage;