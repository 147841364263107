import React, { Component } from 'react';
import { SURVEY_ANALYSIS } from '../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../../constants/DotEnv';
class ParticipantsList extends Component {
    measureScreenWidth = () =>
    {
        let width = window.screen.width;
        if(width > 576)
        {
            return "5";
        }
        else
        {
            return "4";
        }
    }
    createUserListTable = () => {
        let { participated_user_list } = this.props;
        let user_table = [];
        if (participated_user_list && participated_user_list.userparticipated.length) {
            participated_user_list.userparticipated.map((user, index) => {
                return user_table.push(<tr>
                    <td data-head="member">
                        <span className="text-dark">#{index + 1}</span>
                    </td>
                    <td data-head="date">
                        <span className="text-dark">{user.user_survey_date}</span>
                    </td>
                    <td data-head="hours">
                        <span className="text-dark">{user.user_survey_time}</span>
                    </td>
                    <td data-head="minute">
                        <span className="text-dark">{user.user_time_for_survey_completion}</span>
                    </td>
                    <td data-head="clear">
                        <button className="text-dark font-weight-bold border-0 bg-white" onClick={() => this.props.deleteParticipant(user.user_id)} title="Löschen"><i className="fas fa-trash pointer"></i></button>
                    </td>
                </tr>)
            })
        } else if (participated_user_list && !participated_user_list.userparticipated.length) {
            return (<React.Fragment><tr className="text-center"><th className="mt-3 pt-2" colspan="5">{SURVEY_ANALYSIS[LANGUAGE_CODE]['no_participant_message']}</th></tr></React.Fragment>);
        } else {
            return (<tr><td class="text-center border-0" colspan={this.measureScreenWidth()}><i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i></td></tr>)
        }
        return user_table;
    }
    render() {
        let user_tbl = this.createUserListTable();
        return (
            <React.Fragment>
                {user_tbl}
            </React.Fragment>
        );
    }
}
export default ParticipantsList;