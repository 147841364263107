import React from 'react';
import Button from '@material-ui/core/Button';
import {SURVEY_PARTICIPATION} from '../../../../constants/FrontEndConstant'
class SurveyRatingQuestion extends React.Component {
    getDropdownOption = (length, optionid) => {
        var ratingdropdown = [];
        var data  = this.props.questiondata
        var survey_data = JSON.parse(localStorage.getItem('surveydetail_' + data.survey_id))
        let englishbuttonlabels;
        englishbuttonlabels = survey_data.englishbuttonlabels == "1" ? "EN" : "DE"
        for (let i = 1; i <= length; i++) {
            var disableButton = false;
            if (this.props.questionanswer.option === undefined) {
                // eslint-disable-next-line
                Object.keys(this.props.questionanswer).map((key, index) => {
                    if (this.props.questionanswer[key] === i.toString()) {
                        disableButton = true;
                    }
                })
            }
            else {
                // eslint-disable-next-line
                this.props.questionanswer.option.map((optionNew, index) => {
                    if (optionNew['answer'] === i) {
                        disableButton = true;
                        return true;
                    }
                })
            }
            ratingdropdown.push(
                        <button
                            type="button"
                            key={i}
                            value={i}
                            name={optionid}
                            id={optionid}
                            onClick={this.props.onSchoolGrade}
                            className={"MuiCustomRating px-0 " + ((this.props.questionanswer.option === undefined) ?
                                ((this.props.questionanswer[optionid] > 0 && parseInt(this.props.questionanswer[optionid]) === parseInt(i)) ? "btn-grade-clr box-shadow text-capitalize rang-element-grade" : (disableButton) ? "btn-grade box-shadow text-capitalize rating-gray rang-element-grade rang-element-disabled" : "btn-grade box-shadow text-capitalize rating-gray rang-element-grade")
                                : (this.props.questionanswer.option.findIndex(ans => ans.option_id === optionid && (ans.answer > 0) && (ans.answer === parseInt(i))) > -1) ? "btn-grade-clr box-shadow text-capitalize rang-element-grade" : (disableButton) ? "btn-grade box-shadow text-capitalize rating-gray rang-element-grade rang-element-disabled" : "btn-grade box-shadow text-capitalize rating-gray rang-element-grade")
                            }
                            style={{"--school-gradian": survey_data.survey_color? survey_data.survey_color : "#00b3f4" }}
                        >
                            
                    <Button variant="contained" color="#00b3f4" onClick={(e) => this.labelOnClick(e)}>
                            {<i className="fas fa-medal rating-grey"></i>}<br />{SURVEY_PARTICIPATION[englishbuttonlabels]['rank'] + " " + i}
                    </Button>
                        </button>
            )
        }
        return ratingdropdown;
    }

    labelOnClick = (e) => {
        if (e.currentTarget.name) {
            document.getElementById(e.currentTarget.name).click();
            document.getElementById(e.currentTarget.name).checked = !(document.getElementById(e.currentTarget.name).checked);
        }
    }

    render() {
        let data = this.props.questiondata;
        // eslint-disable-next-line
        let ratingdropdown;
        var length = data.option.length;
        var dynamicclass = '';
        if (length === 2) {
            dynamicclass = "ranking-2";
        } else if (length === 3) {
            dynamicclass = "ranking-3";
        } else if (length === 4) {
            dynamicclass = "ranking-4";
        } else if (length === 5) {
            dynamicclass = "ranking-5";
        } else if (length === 6) {
            dynamicclass = "ranking-6";
        } else if (length === 7) {
            dynamicclass = "ranking-7";
        } else if (length === 8) {
            dynamicclass = "ranking-8";
        } else if (length === 9) {
            dynamicclass = "ranking-9";
        } else if (length === 10) {
            dynamicclass = "ranking-10";
        }
        let option = data.option.map((option, index) => {
            return (<div key={index} className={(option.option_image) ? "buttonCounterImgOpt col-12 pb-3 pb-md-5" : "buttonCounterImgOpt col-12 pb-3 pb-md-0"}>
                {(option.option_image) ? <button
                    type="button"
                    id={option.id}
                    className="btn-grade box-shadow custom-rating-button"
                    name={option.id}
                    value={option.option_text}
                >
                    <div className="custom_rating_btn custom-rating-content"><img src={option.option_image} alt=""></img><p className="font-13">{option.option_text}</p>
                    </div>
                </button>
                    : <div className="col-12 text-center mb-4"><span className="font-size font-weight-bold">{option.option_text}</span></div>
                }

                <div className="d-flex align-items-center justify-content-center flex-wrap col-12 rating mb-5">{ratingdropdown = this.getDropdownOption(length, option.id)}</div>
            </div>)
        })
        return (<div key={this.props.questiondata.question_id} className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
            <div className="question-heading">
                <h3 className="text-dark font-weight-bold text-break survey-title">{data.question_text}</h3>
                {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
            </div>
            <div className={"option-button row text-center " + dynamicclass}>
                {option}
            </div>
        </div>)
    }
}
export default SurveyRatingQuestion;