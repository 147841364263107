import React from "react";
import { MYPROFILE_EDITPROFILE_CONSTANT, EDIT_SURVEY_CONSTANT, SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT, VOUCHER_CODE } from "../../constants/FrontEndConstant";
import { LANGUAGE_CODE, API_URL, IMAGE_BASEURL, BASEURL } from '../../constants/DotEnv';
import CountUp from 'react-countup';
import { confirmAlert } from 'react-confirm-alert';
import axios from "axios";
import {Link } from "react-router-dom";
class EditProfile extends React.Component {
    constructor(){
        super()
        this.state= {
            show_plus_membership_button : true,
            active_plus_plan : '',
            Plan_remaing_days : '',
            plan_end_date : ''
        }
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.getPrice()
        const storeitem = JSON.parse(localStorage.getItem('userdata'));
        this.setState({active_plus_plan: storeitem.plan_type })
        const end_date = storeitem.plan_end_date
        if(storeitem.plan_type){
            var plan_end_date = end_date.substring(0, 10).split("-").join(".")
            this.setState({plan_end_date: plan_end_date})
        }
    }
    getPrice(){
        const storeitem = JSON.parse(localStorage.getItem('userdata'));
      if(storeitem){
        const userId = storeitem.id;
        let apitoken = storeitem.token;
        const headers = {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + apitoken
        };
        const res = axios.get(API_URL + '/common/getPricesForPlan/' +userId+ '?uId=' + userId, {header: headers}).then((res) => {
          localStorage.setItem('monthly_plan', res.data.success.priceforplan.monthly_plan)
          localStorage.setItem('quarterly_plan', res.data.success.priceforplan.quarterly_plan)
          localStorage.setItem('actual_price', res.data.success.priceforplan.actual_price)
        })
      }
      }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (document.querySelector('.navbar-toggler')) {
            let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
            if (count === -1) {
                document.querySelector('.navbar-collapse').classList.remove('show');
                document.querySelector('header').classList.remove("header_collapse");
                document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
            }
        }
    }
    // registration referral code display in popup box.
    // referralCode = (e, url, data) => {
    //     confirmAlert({
    //         customUI: ({ onClose }) => {
    //             return (
    //                 <div className='custom-ui text-left position-relative share_popup'>
    //                     <div className="close-btn text-right">
    //                         <button className="d-inline-block bg-white border-0 w-auto sharing-close-button text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
    //                     </div>
    //                     <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['referral_button']}</h3>
    //                     <p className="mb-4">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['referral_popup_subtext_1']}</p>
    //                     <p className="mb-3" dangerouslySetInnerHTML={{__html : MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['referral_popup_subtext_2']}}></p>
    //                     <div className="form-group hs-form-field focus survey-unlock-button mb-3">
    //                         {/* eslint-disable-next-line*/}
    //                         <input type="text" name="enter the link here" className="form-control hs-input" id="copyreferralurl" readOnly value={url} readOnly ref={(text) => this.text = text} />
    //                         <div className="input-group-append">
    //                             <div className="icon-for-copy">
    //                                 <button type="button" className="btn-copy" onClick={this.copyToClipBoard} title={EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['copy_to_clipboard']}><i className="far fa-copy"></i></button>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <p className="my-0"><span className="font-weight-bold">{data['no_of_registered_friend'] ? data['no_of_registered_friend'] : 0}</span> {MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['referral_friends_registered']}</p>
    //                     <p className="mb-3"><span className="font-weight-bold">{data['referral_total_count'] ? data['referral_total_count'] : 0}</span> {MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['referral_karma_multiplier']}</p>
    //                 </div>
    //             )
    //         }
    //     })
    // }

    // Voucher popup show then user click button in profile page.
    voucher = () => {
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let voucherToken = '';
        if (storeitem !== null) {
            voucherToken = storeitem.is_voucher_taken;
        }
        if (voucherToken === 1 || voucherToken === "1") {
            this.voucherRedeem();
        }
        else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui text-left position-relative share_popup'>
                            <div className="close-btn text-right">
                                <button className="d-inline-block bg-white border-0 w-auto sharing-close-button text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                            </div>
                            <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_heading']}</h3>
                            <p>{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_subtext1']}
                                <a href={VOUCHER_CODE['voucher_ExternalURL']} target="_blank" rel="noopener noreferrer">
                                    <span>{" " + MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_link_text'] + " "}</span>
                                </a>
                                <span dangerouslySetInnerHTML={{ __html :MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_subtext2'] }}></span>
                                <div className="text-survey d-flex text-left">
                                    <span className="fa fa-check mt-1"></span>
                                    <div class="mb-0">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_point_1']}</div>
                                </div>
                                <div className="text-survey d-flex text-left">
                                    <span className="fa fa-check mt-1"></span>
                                    <div class="mb-0">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_point_2']}</div>
                                </div>
                                <div className="text-survey d-flex text-left">
                                    <span className="fa fa-check mt-1"></span>
                                    <div class="mb-0">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_point_3']}</div>
                                </div>
                            </p>
                            <div className="pt-3 text-center pb-3">
                                <button type="button" onClick={e => this.unlockVoucherRedeem()} className="btn btn-clr text-white text-uppercase font-18 w-100" title={MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_button1']}> {MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_button1']}</button>
                            </div>
                            <hr></hr>

                            <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['new_patner_voucher_heading']}</h3>
                            <p><span dangerouslySetInnerHTML={{ __html :MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['patner_voucher_subtext'] }}></span></p>
                            <p> {MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['patner_voucher_subtext2']}</p>
                            <div className="pt-3 text-center pb-3">
                                <a href={VOUCHER_CODE['new_patner_voucher_URL']} className="btn btn-clr text-white text-uppercase font-18 w-100" target="_blank" >{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['patner_voucher_button']}
                                </a>
                            </div>
                        </div>
                    )
                }
            })
        }
    }
    unlockVoucherRedeem = async () => {
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let userId = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
            userId = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        var data = {
            id: userId
        }
        await axios.post(API_URL + "/userprofile/MakePaymentToGetVoucher?uId=" + userId, data, { headers: headers })
            .then((response) => {
                if (response && response.data && response.data.success) {
                    storeitem['is_voucher_taken'] = 1;
                    if (localStorage.getItem('userdata')) {
                        localStorage.setItem("userdata",JSON.stringify(storeitem))
                    }
                    this.voucherRedeem();
                }
                if (response && response.data && response.data.error && response.data.message) {
                    this.errorMessageShow();
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    // show voucher code than user unlock voucher.
    voucherRedeem = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui text-left position-relative share_popup'>
                        <div className="close-btn text-right">
                            <button className="d-inline-block bg-white border-0 w-auto sharing-close-button text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                        </div>
                        <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_heading']}</h3>
                        <p dangerouslySetInnerHTML={{ __html : MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_subtext3'] }}>
                            {/* <a href={VOUCHER_CODE['voucher_ExternalURL']} target="_blank" rel="noopener noreferrer">
                                <span>{" " + MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_link_text'] + "."}</span>
                            </a> */}
                        </p>
                        <div className="form-group hs-form-field focus survey-unlock-button mb-3">
                            <input type="text" name="enter the link here" className="form-control hs-input" id="copyvoucherurl" readOnly value={VOUCHER_CODE['voucher_code']} ref={(text) => this.text = text} />
                            <div className="input-group-append">
                                <div className="icon-for-copy">
                                    <button type="button" className="btn-copy" onClick={this.copyToClipBoard} title={EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['copy_to_clipboard']}><i className="far fa-copy"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="pt-3 text-center pb-3">
                            <a href={VOUCHER_CODE['voucher_ExternalURL']} target="_blank" rel="noopener noreferrer" className="btn btn-clr text-white text-uppercase font-18 w-100" title={MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_button2']}> {MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_button2']}</a>
                        </div>
                        <hr></hr>

                            <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['new_patner_voucher_heading']}</h3>
                            <p><span dangerouslySetInnerHTML={{ __html :MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['patner_voucher_subtext'] }}></span></p>
                            <p> {MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['patner_voucher_subtext2']}</p>
                            <div className="pt-3 text-center pb-3">
                                <a href={VOUCHER_CODE['new_patner_voucher_URL']} className="btn btn-clr text-white text-uppercase font-18 w-100" target="_blank" >{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['patner_voucher_button']}
                                </a>
                            </div>
                    </div>
                )
            }
        })
    }

    /*** for copy text which is url for referral code */
    copyToClipBoard = (e) => {
        var input = document.getElementById("copyreferralurl");
        var isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);
        if (isiOSDevice) {
            var editable = input.contentEditable;
            var readOnly = input.readOnly;
            input.contentEditable = true;
            input.readOnly = false;
            var range = document.createRange();
            range.selectNodeContents(input);
            var selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            input.setSelectionRange(0, 999999);
            input.contentEditable = editable;
            input.readOnly = readOnly;
        } else {
            this.text.select();
        }
        document.execCommand('copy');
        e.target.focus();
    }
    // Error message display in popup for karma point Insufficient balance
    errorMessageShow () {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <div className="close-btn text-right">
                            <button className="d-inline-block bg-white border-0 w-auto" onClick={onClose}><i className="ti-close pointer"></i></button>
                        </div>
                        <p>{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['error_unlock_survey']}</p>
                    </div>
                )
            }
        })
    }
    render() {
        let data = this.props.data;
        let karmainfo = this.props.karmainfo;
        var userdata = JSON.parse(localStorage.getItem('userdata'));
        let user_account_id;
        if(this.props.user_account_id){
            user_account_id = this.props.user_account_id
        }else{
            user_account_id = userdata.account_type_category_id
        }
        // let url = '';
        // if (data && data['referral_code']) {
        //     url = BASEURL + 'referral/' + data['referral_code'];
        // }
        return (
            <section className="page-title-section theme-main d-flex align-items-center banner_text editprofile">
                <div className="container">
                    <div className="page-title pt-5 pt-lg-0">
                        <div className="row no-gutters align-items-center">
                            <div className="col-md-12 col-lg-5 text-center text-lg-left">
                                <div className="row no-gutters">
                                    <div className="col-12">
                                        <div className="row no-gutters">
                                            <div className="col-md-5">
                                                <div className="icon-profile position-relative d-inline-block">
                                                    <img src={(data.userimage) ? data.userimage : IMAGE_BASEURL + "img/user_profile.png"} className="rounded-circle" alt="person-img" />
                                                    {/* <i className="fa fa-spinner fa-spin" style={{"fontSize":"25px","color":"#128df5"}}></i> */}
                                                    {(!this.props.showImageUploadLoader) ? <label className="edit_profile" for="inputGroupFile01"> <input type="file" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" className="d-none" name="profile_picture" onChange={this.props.uploadProfilePicture} /></label> : <i className="fa fa-spinner fa-spin" style={{ "fontSize": "25px", "color": "#128df5", "position": "absolute", "top": "37%", "left": "34%" }}></i>}
                                                </div>
                                            </div>
                                            <div className="col-md-7 pt-4 pt-md-0">
                                                <span className="text-white h2 font-36 font-weight-bold lh-50">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['hey']} {data.first_name},</span>
                                                <p className="text-white mb-0 font-14 lh-18">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['welcome_message']}</p>
                                                {
                                                this.state.active_plus_plan? 
                                                user_account_id != 4 && user_account_id != 2 &&
                                                    /* NSU user */
                                                  <><p className="text-white mb-0 font-14 lh-18" style={{marginTop: "1rem "}}>{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['plus_membership_active_msg']} {this.state.plan_end_date} {MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['plus_membership_active_msg2']}</p>
                                                   <a href="/checkout"><p onClick={() => localStorage.setItem('redirectURL',window.location.href)} class="text-white mb-0 font-14 lh-18" style={{textDecoration: "underline",display: 'inline'}}>{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['plus_membership_active_link_text']}</p><span style={{color: 'white'}}>.</span></a></>
                                                :
                                                    <><a href={user_account_id == 4? "/business-checkout" : user_account_id == 2? "/university-checkout" : "/checkout"}><button type="button" name="plus membership" className="mt-2 btn btn-outline-white" onClick={() => localStorage.setItem('redirectURL',window.location.href)} id="voucher_code" title={MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['plus_membership']} ><i class="ti-star " style={{paddingRight: "5px"}}></i>{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['plus_membership']}</button></a></>
                                                }
                                                {
                                                !userdata.IsSubscriptioncancle && !userdata.plan_end_date && userdata.isPlanExpire !== 1?
                                                    user_account_id == 4 || user_account_id == 2 ?
                                                        <p className="text-white mb-0 font-14 lh-18 mt-2">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['for_free_trial']}</p> : null
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {user_account_id != 4 ?
                            <><div className="col-md-12 col-lg-7 sm-p-30px-t pt-3 pt-md-5 pt-lg-0">
                                <div className="count-box counter">
                                    <div className="row no-gutters">
                                        <div className="col-12">
                                            <div className="row no-gutters">
                                            <div className="col-6 col-md-3 pt-3 pt-md-0">
                                                    <div className="counter-col">
                                                        <div className="counter-data">
                                                            <div className="count text-white" data-to="2654" data-speed="2.654">
                                                                {(karmainfo.totalkarmapoints)?  <CountUp end={karmainfo.totalkarmapoints} /> : 0}</div>
                                                            <h6 className="text-white font-12">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['empirio_karma_points_l1']}</h6>
                                                            <i class="ti-gift" />
                                                            <button className="voucher_code font-12" type="button" name="voucher code" id="voucher_code" onClick={(e) => this.voucher(e)} title={MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_profile_button']} >{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['voucher_profile_button']}</button>
                                                        </div>
                                                    </div> {/*<!-- count col -->*/}
                                                {/*<!-- col -->*/}
                                                 </div>
                                                <div className="col-6 col-md-3 pt-3 pt-md-0">
                                                    <div className="counter-col">
                                                        <div className="counter-data">
                                                            <div className="count text-white" data-to="18" data-speed="18">{(karmainfo.userlevel)?  <CountUp end={karmainfo.userlevel} /> : 0}</div>
                                                            <h6 className="text-white font-12">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['level_l1']}<br />{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['level_l2']}</h6>
                                                        </div>
                                                    </div> {/*<!-- count col -->*/}
                                                </div> {/*<!-- col -->*/}
                                                <div className="col-6 col-md-3 pt-3 pt-md-0">
                                                    <div className="counter-col">
                                                        <div className="counter-data">
                                                            <div className="count text-white" data-to="26" data-speed="26">{(karmainfo.nextlevelkarmapoint)?  <CountUp end={karmainfo.nextlevelkarmapoint} /> : 0}</div>
                                                            <h6 className="text-white font-12">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['ponts_to_next_level_l1']}<br />{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['ponts_to_next_level_l2']}</h6>
                                                        </div>
                                                    </div> {/*<!-- count col -->*/}
                                                </div> {/*<!-- col -->*/}
                                                <div className="col-6 col-md-3 pt-3 pt-md-0">
                                                    <div className="counter-col">
                                                        <div className="counter-data">
                                                            <div className="count text-white" data-to="20" data-speed="20">{(karmainfo.bonus)?  <CountUp end={karmainfo.bonus} /> : 0}</div>
                                                            <h6 className="text-white font-12">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['next_level_bonus_l1']}<br />{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['next_level_bonus_l2']}</h6>
                                                        </div>
                                                    </div> {/*<!-- count col -->*/}
                                                </div> {/*<!-- col -->*/}
                                            </div>
                                        </div>
                                    </div> {/*<!-- row -->*/}
                                </div>
                            </div></>
                            :null
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default EditProfile;