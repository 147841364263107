import React from "react";

class StripePayment extends React.Component {
    constructor(props) {
        super(props);
    }
    // handleIBAN = (e) =>{
    //   if(e.target.checked) {
    //     localStorage.setItem('IBAN',1);
    //     document.querySelector("#payment-element").classList.add("hidden");
    //   } else {
    //     localStorage.setItem('IBAN',0);
    //     document.querySelector("#payment-element").classList.remove("hidden");
    //   }
    // }
    render() {
    const button = { 
      background:"#4EB4F4",   
      cursor: "pointer",
      transition: "all 0.2s ease",
      boxShadow: "0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)",
      width: "100%"
    };
        return (
         
       <form id="payment-form">
       {/* <div id="link-authentication-element"> */}
         {/* <!--Stripe.js injects the Link Authentication Element--> */}
       {/* </div> */}
       <div id="payment-element">
         {/* <!--Stripe.js injects the Payment Element--> */}
       </div>
        {/* {this.props.iban === true &&
          <div>
            <label className="checkbox-container d-flex pt-2" >
                <input
                  type="checkbox"
                  name="ibanbutton"
                  className="checkout_ibanbutton"
                  id="ibanbutton"
                  onChange={this.handleIBAN}
                />
              <span className="text-nowrap font-16 mb-0" role='button' style={{padding: "2px 10px 0px 10px"}}>IBAN for Business</span>
            </label>
          </div>
        } */}
       <button id="submit" style={button} className="btn btn-clr text-white font-18">
         <div class="spinner hidden" id="spinner"></div>
         <span id="button-text">{this.props.amount!='' ? this.props.amount+'€':''} {this.props.btn_text}</span>
       </button>
       <div id="payment-message" class="hidden"></div>
     </form>
        )

    }
}

export default StripePayment;
