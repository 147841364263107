import React from 'react';
import { Link } from 'react-router-dom';
import SurveyWelcomePage from './surveyWelcomePage/SurveyWelcomePage';
import SurveyDemographicAttribute from './surveyDemographicAttribute/SurveyDemographicAttribute';
import SurveyQuestionComponents from './surveyQuestionComponents/SurveyQuestionComponents';
import { SURVEY_PARTICIPATION, DEMOGRAPHIC_PARTICIPATION_CONSTANT, BUBBLE_LOADING, AVERAGE_DURATION, KARMA_POINT } from '../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, BASEURL, IMAGE_BASEURL } from '../../../constants/DotEnv';
import axios from "axios";
import Redirect from 'react-router-dom/Redirect';
import ParticipationEndingPage from './participationEndingPage/ParticipationEndingPage';
import SurveyEndingPageNsu from './participationEndingPage/ParticipationEndingPageNsu';
import SurveyPreviewEndPage from './participationEndingPage/SurveyPreviewEndPage';
import CountUp from 'react-countup';
import SurveyDataProtectionParticipantPage from './surveyDataProtection/SurveyDataProtectionParticipantPage';
class SurveyOtherParticipationComponents extends React.Component {
    constructor(props) {
        super(props);
        this.handler = (ev) => ev.preventDefault();
        this.state = {
            errormessage: "",
            redirecttosurvey: "false",
            activeIndex: "",
            demographicchild: 0,
            demographiclength: 0,
            demographicdata: "",
            questionchild: 0,
            name: [],
            indexprevious: '',
            participationAnswer: {},
            allStateData: [],
            lastIndex: 0,
            startQuestionIndex: 1,
            lastQuestionIndex: 0,
            demographicdropdown: '',
            loading: 'false',
            questionData: { "option": [], "demographic": [], "free_text_answer": "", "question_id": "", "question_type_id": "" },
            surveyAnswer: { "survey_id": parseInt(this.props.surveyid), "user_id": parseInt(this.props.userid), "started_at": this.props.start_time, "completed_at": "", "question": [], "demographic": [] },
            currentActiveQuestionData: {},
            currentActiveQuestion: {},
            redirectMyprofile: "false",
            questionPath: [0],
            islastQuestion: false,
            sessionRedirect: false,
            locationData: [],
            postalloading: false,
            ui_id: '',
            assignObject: false,
            redirectPagenotfound: false,
            totalearnkarmapoints: 0,
            annonymousUserId: "",
            backButtonClick: false,
            demographyQuestionDisplay: true,
            dummyFlag: true,
            nsuUser : null,
            planExpired:false,
            showSurveyPreviewEndPage:false,
            dataProtection: false,
            endpageData: null,
            englishbuttonlabels: 'DE'
        };
        this.scrollAnimation = '';
        this.is_FreeTextBoxChecked = false;
        this.isContinueButtonDisplay = true;
        this.disableFinishButton = false;
    }
    scrollUpFunction(smoothScroll = false) {
        if(smoothScroll) {
            var position = document.getElementsByClassName('modal__wizard')[0].scrollTop;
            var positionElement =  document.documentElement.scrollTop 
            var speedScroll = this.state.currentActiveQuestionData && this.state.currentActiveQuestionData.question_type_id === 7 ? true :false;
            if (position) {
                document.getElementsByClassName('modal__wizard')[0].scrollBy(0, -Math.max(speedScroll ? 20 :10 , Math.floor(position / 10)));
                this.scrollAnimation = window.setTimeout(() => {
                    this.scrollUpFunction(true)
                }, 10);
            }
            else if (positionElement) {
                document.documentElement.scrollBy(0, -Math.max(speedScroll ? 20 : 10, Math.floor(position / 10)));
                this.scrollAnimation = window.setTimeout(() => {
                    this.scrollUpFunction(true)
                }, 10);
            }
            else clearTimeout(this.scrollAnimation);
        }
        else {
            window.scrollTo(0, 0)
            document.getElementsByClassName('modal__wizard')[0].scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }
    setCookie(survey_id) {
        var d = new Date();//300000000000 is 10 year
        d.setTime(d.getTime() + 300000000000);
        document.cookie = survey_id + "=1; expires=" + d.toUTCString();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        /* --- Added to enable finish button on reaching to last question in array --- */
        let question_data;
        let autochecker;
        if (this.surveyMainId) {
            question_data = JSON.parse(localStorage.getItem('surveyparticipationquestion_' + this.surveyMainId));
        }
        let userdata = JSON.parse(localStorage.getItem('userdata'));
        if (this.state.currentActiveQuestionData) {
            if (question_data && this.state.currentActiveQuestionData && prevState.currentActiveQuestionData && prevState.currentActiveQuestionData.question_id !== this.state.currentActiveQuestionData.question_id) {
                // eslint-disable-next-line
                /* --- added to set free text checkbox false --- */
                this.is_FreeTextBoxChecked = false;
                // eslint-disable-next-line
                question_data.surveyquestiondata.map((question, index) => {
                    if (userdata) {
                        if (this.props.startSurvey) {
                            if (userdata.autoprofile === 1 || userdata.autoprofile === true) {
                                autochecker = true
                            }
                        }
                    }
                    if (question_data.surveydata.is_welcome_page === 2 && (this.state.currentActiveQuestionData.question_id === question.question_id) && question_data.surveyquestiondata.length === 1 && (autochecker)) {
                        this.setState({
                            lastIndex: 1,
                            islastQuestion: true
                        })
                    }
                    if ((this.state.currentActiveQuestionData.question_id === question.question_id) && (index === question_data.surveyquestiondata.length - 1) && question_data.surveyquestiondata.length !== 1) {
                        this.setState({
                            lastIndex: 1,
                            islastQuestion: true
                        })
                    }
                })
            }
        }
        if (prevState) {
            var allStateData = this.state.allStateData;
            if (this.state.questionchild !== prevState.questionchild) {
                allStateData[prevState.questionchild] = prevState.participationAnswer;
                this.setState({ allStateData })
                if (allStateData[this.state.questionchild]) {
                    this.setState({ participationAnswer: allStateData[this.state.questionchild], assignObject: false })
                }
                else {
                    this.setState({ participationAnswer: {}, assignObject: false })
                }
            }
            if (this.state.activeIndex !== prevState.activeIndex) {
                var assignObject = false;
                if (this.state.activeIndex === 3) {
                    let participationAnswer = {};
                    if (Object.keys(this.state.surveyAnswer.demographic).length !== 0) {
                        participationAnswer = this.state.surveyAnswer.demographic
                        assignObject = true;
                    }
                    this.setState({ participationAnswer })
                }
                this.setState({ assignObject })
            }
        }
        /* --- Added to enable finish button on option select --- */
        if (!this.state.demographyQuestionDisplay && prevState.participationAnswer !== this.state.participationAnswer) {
            let quest_id = this.state.currentActiveQuestionData.question_id
            let selected_opt = this.state.participationAnswer[quest_id];
            let CurrentQuestion = this.state.currentActiveQuestionData;
            let QuestionObjectLength = this.props.questionData['surveyquestiondata'].length;
            let QuestionObject = this.props.questionData['surveyquestiondata'];
            if (this.state.currentActiveQuestionData.hasOwnProperty("option") && this.state.currentActiveQuestionData.option.length && selected_opt) {
                if (CurrentQuestion.question_type_id === 6 && (CurrentQuestion.scale_type === 2 || CurrentQuestion.scale_type === 3 || CurrentQuestion.scale_type === 1 || CurrentQuestion.scale_type === 6)) {
                    // eslint-disable-next-line
                    this.state.currentActiveQuestionData.option.map((question_opt, index) => {
                        if (question_opt.option_text === selected_opt && this.state.islastQuestion === false) {
                            if (!this.state.displayDemographyquestion && (question_opt.option_end_survey || (QuestionObjectLength && QuestionObject[QuestionObjectLength-1] && QuestionObject[QuestionObjectLength-1].question_id === quest_id))) {
                                this.setState({
                                    lastIndex: 1
                                })
                            } else {
                                this.setState({
                                    lastIndex: 0
                                })
                            }
                        }
                    })
                }
                else if (CurrentQuestion.question_type_id === 6 && CurrentQuestion.scale_type === 4) {
                    let check_str = selected_opt.toString();
                    // eslint-disable-next-line
                    this.state.currentActiveQuestionData.option.map((active_opts) => {
                        let startRange = active_opts.option_text.split(',')[0]
                        let endRange = active_opts.option_text.split(',')[1]
                        if (parseInt(check_str) >= parseInt(startRange) && parseInt(check_str) <= parseInt(endRange)) {
                            if (!this.state.displayDemographyquestion && (active_opts.option_end_survey || (QuestionObjectLength && QuestionObject[QuestionObjectLength-1] && QuestionObject[QuestionObjectLength-1].question_id === quest_id))) {
                                this.setState({
                                    lastIndex: 1
                                })
                            } else {
                                this.setState({
                                    lastIndex: 0
                                })
                            }
                        }
                        else if(!this.state.displayDemographyquestion && (QuestionObjectLength && QuestionObject[QuestionObjectLength-1] && QuestionObject[QuestionObjectLength-1].question_id !== quest_id)) {
                            this.setState({
                                lastIndex: 0
                            })
                        }
                    })
                }
                else {
                    // eslint-disable-next-line
                    this.state.currentActiveQuestionData.option.map((question_opt, index) => {
                        if (question_opt.id.toString() === selected_opt && this.state.islastQuestion === false) {
                            if (!this.state.displayDemographyquestion && (question_opt.option_end_survey || (QuestionObjectLength && QuestionObject[QuestionObjectLength-1] && QuestionObject[QuestionObjectLength-1].question_id === quest_id))) {
                                this.setState({
                                    lastIndex: 1,
                                })
                            } else {
                                this.setState({
                                    lastIndex: 0
                                })
                            }
                        }
                    })
                }
            }
            else {
                if (this.state.currentActiveQuestionData.hasOwnProperty("option") && this.state.currentActiveQuestionData.option.length && this.state.participationAnswer.single_selection_free) {
                    // eslint-disable-next-line
                    this.state.currentActiveQuestionData.option.map((question_opt, index) => {
                        if (question_opt.option_text === "free_text_condition" && this.state.islastQuestion === false) {
                            if (!this.state.displayDemographyquestion && (question_opt.option_end_survey || (QuestionObjectLength && QuestionObject[QuestionObjectLength-1] && QuestionObject[QuestionObjectLength-1].question_id === quest_id))) {
                                this.setState({
                                    lastIndex: 1,
                                })
                            } else {
                                this.setState({
                                    lastIndex: 0
                                })
                            }
                        }
                    })
                }
            }
        }
        if (prevState.activeIndex !== this.state.activeIndex) {
            if (question_data) {
                if (this.props.startSurvey) {
                    if (userdata.autoprofile === 1 || userdata.autoprofile === true) {
                        if (question_data.surveyquestiondata.length === 1) {
                            this.setState({
                                lastIndex: 1,
                                islastQuestion: true
                            })
                        }
                    }
                }
            }
        }
        if (prevState.activeIndex !== this.state.activeIndex && this.state.activeIndex === 1) {
            this.setState({
                lastIndex: 0,
                islastQuestion: false
            })
        }
        if (question_data) {
            if (prevState.activeIndex !== this.state.activeIndex && this.state.activeIndex === 2 && question_data.surveyquestiondata.length === 1) {
                this.setState({
                    lastIndex: 1,
                    islastQuestion: true
                })
            }
            if(this.state.activeIndex === 2 || this.state.activeIndex === 3 || this.state.activeIndex === 1) {
                let getValue = this.checkEnableNextFunction()
                if (getValue !== this.isContinueButtonDisplay){
                    this.isContinueButtonDisplay = getValue;
                    this.setState({ dummyFlag: !this.state.dummyFlag })
                }
            }
        }

    }
    componentDidMount = async () => {
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let userId = '';
        if(storeitem){
            apitoken = storeitem.token;
            userId = storeitem.id;
            this.setState({planExpired: storeitem.isPlanExpire})
            this.setState({nsuUser: storeitem.plan_type})
        }
        window.scrollTo(0, 0)
        let question_data;
        if (this.surveyMainId) {
            question_data = JSON.parse(localStorage.getItem('surveyparticipationquestion_' + this.surveyMainId));
        }
        if (question_data) {
            if (this.state.activeIndex === 2 && question_data.surveyquestiondata.length === 1 && this.state.islastQuestion === false) {
                this.setState({
                    lastIndex: 1,
                    islastQuestion: true
                })
            }
        }
        window.history.pushState(null, document.title, window.location.href);
        window.preventBackNav = () => {
            if (this.state.activeIndex !== 4) {
                document.getElementsByClassName("back_button")[0].click();
            }
            if (this.state.activeIndex !== 1) {
                window.history.pushState(null, document.title, window.location.href);
            }
        }
        /**get dropdownoption from the localstorage for demographic question and is it not avilable in localstorage then make API call for the dropdownoption */
        let demographicdropdown = JSON.parse(localStorage.getItem('dropdownListSurvey'));
        let surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + this.surveyMainId));
        let englishbuttonlabels = surveydetail.englishbuttonlabels == "1" ? "EN" : "DE";

        //if (demographicdropdown === undefined || demographicdropdown === null || surveydetail.englishbuttonlabels === "1") {
            this.setState({ loading: 'true' })
           // let storeitem = JSON.parse(localStorage.getItem('userdata'));
            // let apitoken = '';
            // let userId = '';
            // if (storeitem !== null) {
            //     apitoken = storeitem.token;
            //     userId = storeitem.id;
            // }
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + apitoken
            }
            let self = this;
            await axios.get(API_URL + "/common/getAll/" + englishbuttonlabels + '?uId=' + userId, { headers: headers })
                .then((response) => {
                    demographicdropdown = response.data['success'];
                    localStorage.setItem("dropdownListSurvey", JSON.stringify(demographicdropdown));
                    self.setState({ loading: 'false', demographicdropdown });
                })
                .catch((error) => {
                    self.setState({ loading: 'false' });
                })
        // }
        // else {
        //     demographicdropdown = JSON.parse(localStorage.getItem('dropdownListSurvey'))
        //     this.setState({ demographicdropdown })
        // }
        let surveyData;
        if (this.surveyMainId) {
            surveyData = JSON.parse(localStorage.getItem('surveyparticipationquestion_' + this.surveyMainId));
        }
        /*--- Added Below Lines for Setting Questoinpath and currentActiveQuestionData  ---*/
        // eslint-disable-next-line
        this.state.questionPath[this.state.questionchild] = surveyData.surveyquestiondata[this.state.questionchild];
        this.setState({
            currentActiveQuestionData: surveyData.surveyquestiondata[this.state.questionchild]
        })
    }
    /** set active index 1 so first survey welcome component is load by default and then other if user wants to display it */
    componentWillMount = () => {
        let data = this.props.questionData;
        this.surveyMainId = this.props.surveyid;
        let surveydetail;
        let surveycreation;
        let userdata;
        let redirectPagenotfound = false;
        if (this.surveyMainId) {
            surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + this.surveyMainId));
            surveycreation = JSON.parse(localStorage.getItem('surveycreationdata_' + this.surveyMainId));
        }
        userdata = JSON.parse(localStorage.getItem('userdata'));
        var demographiclength = 0;
        var demographicdata = "";
        let checker = true;
        /** if user not set quick-click on then only display demographic question otherwise not */
        if (userdata) {
            if (this.props.userid && this.props.startSurvey) {
                if (userdata.autoprofile === 1 || userdata.autoprofile) {
                    checker = false;
                }
            }
        }
        if (checker) {
            if (surveydetail) {
                if (Object.keys(surveydetail).length > 0) {
                    if (surveydetail.demographic) {
                        demographiclength = surveydetail.demographic['data'].length
                        demographicdata = surveydetail.demographic['data'];
                    }
                    this.setState({ demographiclength, demographicdata })
                } else if (surveydetail.length > 0) {
                    if (surveydetail.demographic) {
                        demographiclength = surveydetail.demographic['data'].length
                        demographicdata = surveydetail.demographic['data'];
                    }
                    this.setState({ demographiclength, demographicdata })
                }
                else if (surveycreation) {
                    this.setState({ loading: 'true' })
                    let title = surveycreation.survey_title_slug
                    let self = this;
                    let surveydetail = '';
                    let demographic = '';
                    let length = '';
                    let category_id = '';
                    let survey_id = '';
                    let survey_slug = '';
                    let surveydata = '';
                    let getUrl;
                    if (title) {
                        getUrl = API_URL + '/commonApi/getSurveyBySlug/' + LANGUAGE_CODE + '/' + title + '/live';
                    }
                    else {
                        getUrl = API_URL + '/commonApi/getSurveyById/' + LANGUAGE_CODE + '/' + this.surveyMainId;
                    }
                    axios.get(getUrl)
                        .then((response) => {
                            surveydetail = response.data.success.surveydata;
                            demographic = response.data.success.surveydata.demographic;
                            length = Object.keys(surveydetail).length;
                            category_id = response.data.success.surveydata.category_id;
                            survey_id = response.data.success.surveydata.id;
                            survey_slug = response.data.success.surveydata.survey_title_slug;
                            if (surveydetail !== '') {
                                surveydata = response.data.success.surveydata;
                                if (!response.data.success.surveydata) {
                                    redirectPagenotfound = true
                                }
                                else {
                                    if (!Object.keys(response.data.success.surveydata).length) {
                                        redirectPagenotfound = true;
                                    }
                                }
                                if (this.surveyMainId) {
                                    localStorage.setItem("surveydetail_" + this.surveyMainId, JSON.stringify(surveydata));
                                }
                                if (surveydetail.demographic) {
                                    demographiclength = surveydetail.demographic['data'].length
                                    demographicdata = surveydetail.demographic['data'];
                                }
                                this.setState({ demographiclength, demographicdata })
                            }
                            self.setState({ loading: 'false', surveydetail, length, demographic, survey_slug, redirectPagenotfound })
                            if (length > 0) {
                                this.relatedSurvey(category_id, survey_id);
                            }
                        })
                        .catch((error) => {
                            self.setState({ loading: 'false' })
                        })
                }
            }
            else {
                if (surveycreation) {
                    this.setState({ loading: 'true' })
                    let title = surveycreation.survey_title_slug
                    let self = this;
                    let surveydetail = '';
                    let demographic = '';
                    let length = '';
                    let category_id = '';
                    let survey_id = '';
                    let survey_slug = '';
                    let surveydata = '';
                    let getUrl;
                    if (title) {
                        getUrl = API_URL + '/commonApi/getSurveyBySlug/' + LANGUAGE_CODE + '/' + title + '/live';
                    }
                    else {
                        getUrl = API_URL + '/commonApi/getSurveyById/' + LANGUAGE_CODE + '/' + this.surveyMainId;
                    }
                    axios.get(getUrl)
                        .then((response) => {
                            surveydetail = response.data.success.surveydata;
                            demographic = response.data.success.surveydata.demographic;
                            length = Object.keys(surveydetail).length;
                            category_id = response.data.success.surveydata.category_id;
                            survey_id = response.data.success.surveydata.id;
                            survey_slug = response.data.success.surveydata.survey_title_slug;
                            if (surveydetail !== '') {
                                surveydata = response.data.success.surveydata;
                                if (!response.data.success.surveydata) {
                                    redirectPagenotfound = true
                                }
                                else {
                                    if (!Object.keys(response.data.success.surveydata).length) {
                                        redirectPagenotfound = true;
                                    }
                                }
                                if (this.surveyMainId) {
                                    localStorage.setItem("surveydetail_" + this.surveyMainId, JSON.stringify(surveydata));
                                }
                                if (surveydetail.demographic) {
                                    demographiclength = surveydetail.demographic['data'].length
                                    demographicdata = surveydetail.demographic['data'];
                                }
                                this.setState({ demographiclength, demographicdata })
                            }
                            self.setState({ loading: 'false', surveydetail, length, demographic, survey_slug, redirectPagenotfound })
                            if (length > 0) {
                                this.relatedSurvey(category_id, survey_id);
                            }
                        })
                        .catch((error) => {
                            self.setState({ loading: 'false' })
                        })
                }
            }
        }
        /** if there is welcome page then dispaly it otherwise check condition for demographic question or survey question component */
        if (surveydetail.declaration_consent_toggle == 1) {
            let autochecker;
            let demographyQuestionDisplay = true;
            if (userdata) {
                if (this.props.startSurvey) {
                    if (userdata.autoprofile === 1 || userdata.autoprofile === true) {
                        autochecker = true
                    }
                }
            }
            /**if autoprofile is set then skip demographic question and direct display survey question */
            if (autochecker) {
                if (data.surveyquestiondata.length > 0) {
                    demographyQuestionDisplay = false;
                }
                /**if there is no question then direct display last surveyEndingPage component */
                else {
                    demographyQuestionDisplay = false;
                }
            }
            else {
                /**if there is no demographic attribute then also redirect to SurveyQuestionComponents by doing activeIndex 2  */
                if (data.surveyquestiondata.length > 0) {
                    if (!demographicdata) {
                        demographyQuestionDisplay = false;
                    }
                }
                else {
                    if (!demographicdata) {
                        demographyQuestionDisplay = false;
                    }
                }
            }
            this.setState({ activeIndex: 1, indexprevious: 1, demographyQuestionDisplay });
        }
        else {
            let autochecker;
            if (userdata) {
                if (this.props.startSurvey) {
                    if (userdata.autoprofile === 1 || userdata.autoprofile === true) {
                        autochecker = true
                    }
                }
            }
            /**if autoprofile is set then skip demographic question and direct display survey question */
            if (autochecker) {
                if (data.surveyquestiondata.length > 0) {
                    this.setState({ activeIndex: 2, indexprevious: 2, demographyQuestionDisplay : false })
                }
                /**if there is no question then direct display last surveyEndingPage component */
                else {
                    this.setState({ activeIndex: 4, indexprevious: 4, startQuestionIndex: 0, demographyQuestionDisplay : false })
                }
            }
            else {
                /**if there is no demographic attribute then also redirect to SurveyQuestionComponents by doing activeIndex 2  */
                if (data.surveyquestiondata.length > 0) {
                    if (demographicdata) {
                        this.setState({ activeIndex: 2, indexprevious: 2 })
                    }
                    else {
                        this.setState({ activeIndex: 2, indexprevious: 2, demographyQuestionDisplay : false })
                    }
                }
                else {
                    if (demographicdata) {
                        this.setState({ activeIndex: 3, indexprevious: 3 })
                    }
                    else {
                        this.setState({ activeIndex: 4, indexprevious: 4, startQuestionIndex: 0, demographyQuestionDisplay : false })
                    }
                }
            }
        }
    }
    /**Check validation For continue button */
    checkEnableNextFunction = () => {
        let questionData = this.state.questionData;
        let checkValidation = true;
        /** If question optional then checkValidation should be false */
        if (this.state.currentActiveQuestionData && this.state.currentActiveQuestionData.is_question_optional && parseInt(this.state.currentActiveQuestionData.is_question_optional) === 1) {
            checkValidation = false;
        }
        /**for the question validation of survey */
        var currentActiveQuestionData = this.state.questionPath[this.state.questionchild];
        if (this.state.activeIndex === 2) {
            if ((questionData.question_id === undefined || questionData.question_id === "" || Object.keys(questionData).length === 0) && currentActiveQuestionData && currentActiveQuestionData.question_id) {
                questionData['question_id'] = currentActiveQuestionData['question_id']
                questionData['question_type_id'] = currentActiveQuestionData['question_type_id']
                if (currentActiveQuestionData['scale_type'] !== undefined) {
                    questionData['scale_type'] = currentActiveQuestionData['scale_type']
                }
            }
            /**for the free text type question validation */
            if (questionData.question_type_id === 1) {
                var freetextbox = document.getElementsByClassName('free-text-textarea')
                if (freetextbox[0].value === "" && checkValidation) {
                    return false;
                }
                if (!questionData['free_text_answer']) {
                    if (!freetextbox[0].value && checkValidation){
                        return false;
                    }
                }
            }
            /**check validation for single selection type question if answer is not given then display error */
            if (questionData.question_type_id === 3) {
                var checkradio = document.getElementsByClassName('singleselection');
                var allcheckedradio = [];
                for (let i = 0; i < checkradio.length; i++) {
                    if (checkradio[i].checked) {
                        allcheckedradio.push(checkradio[i])
                    }
                }
                if (allcheckedradio.length === 0) {
                    if (currentActiveQuestionData && currentActiveQuestionData.is_free_text === 1) {
                        var textans = document.getElementsByClassName('free-text-ans')
                        if (textans[0] && textans[0].value === "" && (checkValidation || (document.getElementsByClassName('custom-control-input') && document.getElementsByClassName('custom-control-input').length && document.getElementsByClassName('custom-control-input')[0].checked))) {
                            return false;
                        }
                    }
                    else if (checkValidation) {
                        return false;
                    }
                }
            }
            /**for the multiple selection type question validation */
            if (questionData.question_type_id === 4) {
                this.is_FreeTextBoxChecked = (document.getElementsByClassName("check-condition").length) ? document.getElementsByClassName("check-condition")[0].checked : false;
                var checkcheckbox = document.getElementsByClassName('selected-checkbox');
                if (checkcheckbox.length === 0) {
                    if (currentActiveQuestionData.is_free_text === 1) {
                        var freemultiplee = document.getElementsByClassName("free_answer_multiple");
                        if (freemultiplee[0].value === "" && (checkValidation || this.is_FreeTextBoxChecked)) {
                            return false;
                        }
                    }
                    else if (checkValidation) {
                        return false;
                    }
                }
            }
            /**for the rating type question validation */
            if (questionData.question_type_id === 5) {
                function getRankingButton(ratingName) {
                    var ratedorderbuttons = document.getElementsByClassName(ratingName);
                    var allratedbuttons = [];
                    if (ratedorderbuttons.length === Object.keys(currentActiveQuestionData['option']).length) {
                        for (var i = 0; i < ratedorderbuttons.length; i++) {
                            var obj = {};
                            if (ratedorderbuttons[i]) {
                                obj['answer'] = parseInt(ratedorderbuttons[i].value);
                                allratedbuttons.push(obj);
                            }
                        }
                    }
                    return allratedbuttons.length > 0 ? allratedbuttons : null;
                }
                if (checkValidation && document.getElementsByClassName("btn-grade-clr").length < Object.keys(currentActiveQuestionData['option']).length) {
                    return false;
                }
                if (!checkValidation && document.getElementsByClassName("btn-grade-clr").length !== 0 && document.getElementsByClassName("btn-grade-clr").length < Object.keys(currentActiveQuestionData['option']).length) {
                    return false;
                }
                var finalretedbuttons = getRankingButton("btn-grade-clr")
                var finderror = ""
                // eslint-disable-next-line
                if (finalretedbuttons !== null) {
                    // eslint-disable-next-line
                    finalretedbuttons.map((ans) => {
                        if (isNaN(ans['answer']) === true || ans['answer'] === "") {
                            finderror = true
                        }
                    })
                }
                if (finderror === true) {
                    return false;
                }
            }
            /**for the rating with one element type question validation */
            if (questionData.question_type_id === 6) {
                /**for the scale type (1 && 3) that is(point 1 to 10 and thumb type question) question validation */
                if (questionData.scale_type === 1 || questionData.scale_type === 3) {
                    var checkpointbutton = document.getElementsByClassName("btn-rank");
                    if (checkpointbutton.length === 0 && checkValidation) {
                        return false;
                    }
                }
                /**for the scale type (2 && 5 && 6) that is (school grade and free-type and survey-approval question) question validation */
                if (questionData.scale_type === 2 || questionData.scale_type === 5 || questionData.scale_type === 6) {
                    var checkschoolgradebutton = document.getElementsByClassName("btn-grade-clr");
                    if (checkschoolgradebutton.length === 0 && checkValidation) {
                        return false;
                    }
                }
                /** for the scale type (4) that is probability type question */
                if (questionData.scale_type === 4) {
                    var singleprobability = document.getElementsByClassName("selected-probability")
                    if (singleprobability.length && singleprobability[0].getElementsByTagName('input').length !== 0) {
                        if (checkValidation && Object.keys(this.state.participationAnswer).length === 0) {
                            return false;
                        }
                    }
                }
            }
            /**for the rating with more elements type question validation */
            if (questionData.question_type_id === 7) {
                /**for the scale type 2(school grade type question) question validation */
                if (questionData.scale_type === 2) {
                    var schoolgradebutton = document.getElementsByClassName("school-grade")
                    if (schoolgradebutton.length !== Object.keys(currentActiveQuestionData['option']).length && (checkValidation || schoolgradebutton.length > 0)) {
                        return false;
                    }
                }
                /**for the scale type 6(survey approval type question) question validation */
                if (questionData.scale_type === 6) {
                    var schoolgradebutton = document.getElementsByClassName("school-grade")
                    if (schoolgradebutton.length !== Object.keys(currentActiveQuestionData['option']).length && (checkValidation || schoolgradebutton.length > 0)) {
                        return false;
                    }
                }
                /** for the scale type 5(free-text type question) question validation */
                if (questionData.scale_type === 5) {
                    var freetextbutton = document.getElementsByClassName("free-text")
                    if (freetextbutton.length !== Object.keys(currentActiveQuestionData['scale_text']).length && (checkValidation || freetextbutton.length > 0)) {
                        return false;
                    }
                }
                /**for the scale type 1(points 1 to 10 type question) question validation  */
                if (questionData.scale_type === 1) {
                    var selectedpointsbutton = document.getElementsByClassName("selected-points")
                    if (selectedpointsbutton.length !== Object.keys(currentActiveQuestionData['option']).length && (checkValidation || selectedpointsbutton.length > 0)) {
                        return false;
                    }
                }
                /**for the scale type 3(thumb type question) question validation */
                if (questionData.scale_type === 3) {
                    var selectedthumb = document.getElementsByClassName("selected-thumbs")
                    if (selectedthumb.length !== Object.keys(currentActiveQuestionData['option']).length && (checkValidation || selectedthumb.length > 0)) {
                        return false;
                    }
                }
                /**for the scale type 4(probability) question validation */
                if (questionData.scale_type === 4) {
                    var selectedprobability = document.getElementsByClassName("selected-probability")
                    let finalselectedprobability = [];
                    if (checkValidation && Object.keys(this.state.participationAnswer).length !== selectedprobability.length) {
                        return false;
                    }
                    if (!checkValidation && Object.keys(this.state.participationAnswer).length !== 0 && Object.keys(this.state.participationAnswer).length < selectedprobability.length) {
                        return false;
                    }
                    for (let i = 0; i < selectedprobability.length; i++) {
                        var selectedobj = {}
                        selectedobj['option_id'] = parseInt(selectedprobability[i].getElementsByTagName('input')[0].name);
                        selectedobj['answer'] = parseInt(selectedprobability[i].getElementsByTagName('input')[0].value);
                        finalselectedprobability.push(selectedobj);
                    }
                    delete questionData['demographic'];
                    delete questionData['free_text_answer'];
                    questionData['option'] = finalselectedprobability;
                    questionData["question_id"] = currentActiveQuestionData['question_id']
                    questionData['question_type_id'] = currentActiveQuestionData['question_type_id']
                    questionData['scale_type'] = currentActiveQuestionData['scale_type']
                }
            }
            return true;
        }
        /** for the dmeographic question validation */
        if (this.state.activeIndex === 3) {
            if (this.state.demographicdata.length !== 0) {
                var currentdemographicquestion = this.state.demographicdata[this.state.demographicchild]
                if (currentdemographicquestion) {
                    /**for the demographic singleselection type question validation */
                    if (currentdemographicquestion.type === 'single_selection') {
                        var singleselection = document.getElementsByClassName('demographicsingle')
                        var allsingleselection = [];
                        for (let i = 0; i < singleselection.length; i++) {
                            if (singleselection[i].checked) {
                                allsingleselection.push(singleselection[i])
                            }
                        }
                        if (allsingleselection.length === 0) {
                            return false;
                        }
                    }
                    /**for the demographic textfield type question */
                    if (currentdemographicquestion.type.toString() === "single_textfield") {
                        if (currentdemographicquestion.id === 3 || currentdemographicquestion.id === 1) {
                            var location = document.getElementsByClassName('textfield-type-demo')
                            if (location[0].value === "") {
                                return false;
                            }
                            else {
                                if (currentdemographicquestion.id === 1) {
                                    if (isNaN(parseInt(location[0].value)) === true) {
                                        return false;
                                    }
                                } else if (currentdemographicquestion.id === 3) {
                                    let locationFound = false;
                                    if (this.state.locationData.length > 0) {
                                        for (let i = 0; i < this.state.locationData.length; i++) {
                                            if (this.state.locationData[i].postalcode_city.toString() === location[0].value.toString()) {
                                                locationFound = true;
                                            }
                                        }
                                        if (!locationFound) {
                                            return false;
                                        }
                                    } else {
                                        return false;
                                    }
                                }
                            }
                        }
                    }
                    /**for the demographic dropdown type question */
                    if (currentdemographicquestion.type === "dropdown") {
                        var dropdown = document.getElementsByClassName('dropdown-type-demo');
                        if (dropdown[0].value === "") {
                            return false;
                        }
                    }
                }
                return true;
            }
        }
        if(this.state.activeIndex === 1){
            let check_status = this.state.dataProtection
            let status = document.getElementById(1).checked
            if(check_status === true || status === true) return true
            else return false
        }
    }
    /**function for managing ans of question which store in final surveyAnswer object and if particular question answer is exist then it update that answer */
    manageAnswerObject = () => {
        let surveyAnswer = this.state.surveyAnswer
        let questionData = this.state.questionData;
        let checkValidation = true;
        let surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + this.surveyMainId));
        let englishbuttonlabels = surveydetail?.englishbuttonlabels == "1" ? "EN" : "DE"
        /** If question optional then checkValidation should be false */
        if (this.state.currentActiveQuestionData && this.state.currentActiveQuestionData.is_question_optional && parseInt(this.state.currentActiveQuestionData.is_question_optional) === 1) {
            checkValidation = false;
        }
        if(this.state.activeIndex == 1){
            var errormessage = "";
            let status = document.getElementById(1).checked
            if(this.state.dataProtection === false && status === false){
                errormessage = SURVEY_PARTICIPATION[LANGUAGE_CODE]['data_protection_error_msg']
                return errormessage;
            }else{
                errormessage = '';
                return errormessage
            }
        }
        /** for the dmeographic question validation */
        if (this.state.activeIndex === 3) {
            var errormessage = "";
            if (Object.keys(questionData.demographic).length === 0) {
                var currentdemographicquestion = this.state.demographicdata[this.state.demographicchild]
                /**for the demographic singleselection type question validation */
                if (currentdemographicquestion.type === 'single_selection' && (currentdemographicquestion.id === 2 || currentdemographicquestion.id === 4 || currentdemographicquestion.id === 5 || currentdemographicquestion.id === 6 || currentdemographicquestion.id === 7)) {
                    var singleselection = document.getElementsByClassName('demographicsingle')
                    var allsingleselection = [];
                    for (let i = 0; i < singleselection.length; i++) {
                        if (singleselection[i].checked) {
                            allsingleselection.push(singleselection[i])
                        }
                    }
                    if (allsingleselection.length === 0) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                    else {
                        errormessage = "";
                        return errormessage;
                    }
                }
                /**for the demographic textfield type question */
                if (currentdemographicquestion.type.toString() === "single_textfield") {
                    if (currentdemographicquestion.id === 3 || currentdemographicquestion.id === 1) {
                        var location = document.getElementsByClassName('textfield-type-demo')
                        if (location[0].value === "") {
                            errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                            return errormessage;
                        }
                        else {
                            if (currentdemographicquestion.id === 1) {
                                if (isNaN(parseInt(location[0].value)) === true) {
                                    errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                                    return errormessage;
                                }
                            } else if (currentdemographicquestion.id === 3) {
                                let locationFound = false;
                                if (this.state.locationData.length > 0) {
                                    for (let i = 0; i < this.state.locationData.length; i++) {
                                        if (this.state.locationData[i].postalcode_city.toString() === location[0].value.toString()) {
                                            locationFound = true;
                                        }
                                    }
                                    if (locationFound) {
                                        errormessage = "";
                                        return errormessage;
                                    } else {
                                        errormessage = SURVEY_PARTICIPATION[LANGUAGE_CODE]['correctlocation'];
                                        return errormessage;
                                    }
                                } else {
                                    errormessage = SURVEY_PARTICIPATION[LANGUAGE_CODE]['correctlocation']
                                    return errormessage;
                                }
                            }
                            else {
                                errormessage = ""
                                return errormessage;
                            }
                        }
                    }
                }
                /**for the demographic dropdown type question */
                if (currentdemographicquestion.type === "dropdown") {
                    var dropdown = document.getElementsByClassName('dropdown-type-demo');
                    if (dropdown[0].value === "") {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                    else {
                        errormessage = "";
                        return errormessage;
                    }
                }
            }
        }
        /**if current activeIndex is for surveyquestion */
        if (this.state.activeIndex === 2) {
            var currentActiveQuestionData = this.state.questionPath[this.state.questionchild];
            if ((questionData.question_id === undefined || questionData.question_id === "" || Object.keys(questionData).length === 0) && currentActiveQuestionData && currentActiveQuestionData.question_id) {
                questionData = currentActiveQuestionData;
                questionData['question_id'] = currentActiveQuestionData['question_id']
                questionData['question_type_id'] = currentActiveQuestionData['question_type_id']
                if (currentActiveQuestionData['scale_type'] !== undefined) {
                    questionData['scale_type'] = currentActiveQuestionData['scale_type']
                }
            }
            /**for the free text type question validation */
            if (questionData.question_type_id === 1) {
                var freetextbox = document.getElementsByClassName('free-text-textarea')
                if (freetextbox[0].value === "" && checkValidation) {
                    errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                    return errormessage;
                }
            }
            /**check validation for single selection type question if answer is not given then display error */
            if (questionData.question_type_id === 3) {
                var checkradio = document.getElementsByClassName('singleselection');
                var allcheckedradio = [];
                for (let i = 0; i < checkradio.length; i++) {
                    if (checkradio[i].checked) {
                        allcheckedradio.push(checkradio[i])
                    }
                }
                if (allcheckedradio.length === 0) {
                    if (currentActiveQuestionData.is_free_text === 1) {
                        var textans = document.getElementsByClassName('free-text-ans')
                        if (textans[0].value === "" && (checkValidation || (document.getElementsByClassName('custom-control-input') && document.getElementsByClassName('custom-control-input').length && document.getElementsByClassName('custom-control-input')[0].checked))) {
                            errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                            return errormessage;
                        }
                    }
                    else if (checkValidation) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                }
            }
            /**for the multiple selection type question validation */
            if (questionData.question_type_id === 4) {
                this.is_FreeTextBoxChecked = (document.getElementsByClassName("check-condition").length) ? document.getElementsByClassName("check-condition")[0].checked : false;
                var checkcheckbox = document.getElementsByClassName('selected-checkbox');
                if (checkcheckbox.length === 0) {
                    if (currentActiveQuestionData.is_free_text === 1) {
                        var freemultiplee = document.getElementsByClassName("free_answer_multiple");
                        if (freemultiplee[0].value === "" && (checkValidation || this.is_FreeTextBoxChecked)) {
                            errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                            return errormessage;
                        }
                    }
                    else if (checkValidation) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                }
            }
            /**for the rating type question validation */
            if (questionData.question_type_id === 5) {
                function getRankingButton(ratingName) {
                    var ratedorderbuttons = document.getElementsByClassName(ratingName);
                    var allratedbuttons = [];
                    if (ratedorderbuttons.length === Object.keys(currentActiveQuestionData['option']).length) {
                        for (var i = 0; i < ratedorderbuttons.length; i++) {
                            var obj = {};
                            if (ratedorderbuttons[i]) {
                                obj['answer'] = parseInt(ratedorderbuttons[i].value);
                                allratedbuttons.push(obj);
                            }
                        }
                    }
                    return allratedbuttons.length > 0 ? allratedbuttons : null;
                }
                if (checkValidation && document.getElementsByClassName("btn-grade-clr").length < Object.keys(currentActiveQuestionData['option']).length) {
                    errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage'];
                    return errormessage;
                }
                if (!checkValidation && document.getElementsByClassName("btn-grade-clr").length !== 0 && document.getElementsByClassName("btn-grade-clr").length < Object.keys(currentActiveQuestionData['option']).length) {
                    errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage'];
                    return errormessage;
                }
                var finalretedbuttons = getRankingButton("btn-grade-clr")
                var finderror = ""
                // eslint-disable-next-line
                if (finalretedbuttons !== null) {
                    // eslint-disable-next-line
                    finalretedbuttons.map((ans) => {
                        if (isNaN(ans['answer']) === true || ans['answer'] === "") {
                            finderror = true
                        }
                    })
                }
                if (finderror === true) {
                    errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                    return errormessage;
                }
            }
            /**for the rating with one element type question validation */
            if (questionData.question_type_id === 6) {
                /**for the scale type (1 && 3) that is(point 1 to 10 and thumb type question) question validation */
                if (questionData.scale_type === 1 || questionData.scale_type === 3) {
                    var checkpointbutton = document.getElementsByClassName("btn-rank");
                    if (checkpointbutton.length === 0 && checkValidation) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                }
                /**for the scale type (2 && 5 && 6) that is (school grade and free-type and survey approval question) question validation */
                if (questionData.scale_type === 2 || questionData.scale_type === 5 || questionData.scale_type === 6) {
                    var checkschoolgradebutton = document.getElementsByClassName("btn-grade-clr");
                    if (checkschoolgradebutton.length === 0 && checkValidation) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                }
                /** for the scale type (4) that is probability type question */
                if (questionData.scale_type === 4) {
                    var singleprobability = document.getElementsByClassName("selected-probability")
                    if (singleprobability.length && singleprobability[0].getElementsByTagName('input').length !== 0) {
                        if (checkValidation && Object.keys(this.state.participationAnswer).length === 0) {
                            errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                            return errormessage;
                        }
                        if (!checkValidation) {
                            if (Object.keys(this.state.participationAnswer).length === 0) {
                                questionData['is_remove'] = true;
                            }
                        }
                        delete questionData['option'];
                        delete questionData['demographic'];
                        questionData["question_id"] = currentActiveQuestionData['question_id']
                        questionData['question_type_id'] = currentActiveQuestionData['question_type_id']
                        questionData['scale_type'] = currentActiveQuestionData['scale_type']
                        questionData['free_text_answer'] = parseInt(singleprobability[0].getElementsByTagName('input')[0].value);
                    }
                }
            }
            /**for the rating with more elements type question validation */
            if (questionData.question_type_id === 7) {
                /**for the scale type 2(school grade type question) question validation */
                if (questionData.scale_type === 2) {
                    var schoolgradebutton = document.getElementsByClassName("school-grade")
                    if (schoolgradebutton.length !== Object.keys(currentActiveQuestionData['option']).length && (checkValidation || schoolgradebutton.length > 0)) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                }
                /**for the scale type 6(survey approval type question) question validation */
                if (questionData.scale_type === 6) {
                    var schoolgradebutton = document.getElementsByClassName("school-grade")
                    if (schoolgradebutton.length !== Object.keys(currentActiveQuestionData['option']).length && (checkValidation || schoolgradebutton.length > 0)) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                }
                /** for the scale type 5(free-text type question) question validation */
                if (questionData.scale_type === 5) {
                    var freetextbutton = document.getElementsByClassName("free-text")
                    if (freetextbutton.length !== Object.keys(currentActiveQuestionData['scale_text']).length && (checkValidation || freetextbutton.length > 0)) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                }
                /**for the scale type 1(points 1 to 10 type question) question validation  */
                if (questionData.scale_type === 1) {
                    var selectedpointsbutton = document.getElementsByClassName("selected-points")
                    if (selectedpointsbutton.length !== Object.keys(currentActiveQuestionData['option']).length && (checkValidation || selectedpointsbutton.length > 0)) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                }
                /**for the scale type 3(thumb type question) question validation */
                if (questionData.scale_type === 3) {
                    var selectedthumb = document.getElementsByClassName("selected-thumbs")
                    if (selectedthumb.length !== Object.keys(currentActiveQuestionData['option']).length && (checkValidation || selectedthumb.length > 0)) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                }
                /**for the scale type 4(probability) question validation */
                if (questionData.scale_type === 4) {
                    var selectedprobability = document.getElementsByClassName("selected-probability")
                    let finalselectedprobability = [];
                    if (checkValidation && Object.keys(this.state.participationAnswer).length !== selectedprobability.length) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                    for (let i = 0; i < selectedprobability.length; i++) {
                        var selectedobj = {}
                        selectedobj['option_id'] = parseInt(selectedprobability[i].getElementsByTagName('input')[0].name);
                        selectedobj['answer'] = parseInt(selectedprobability[i].getElementsByTagName('input')[0].value);
                        finalselectedprobability.push(selectedobj);
                    }
                    if(!checkValidation && Object.keys(this.state.participationAnswer).length !== 0 && Object.keys(this.state.participationAnswer).length !== selectedprobability.length) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                    if (!checkValidation) {
                        if (Object.keys(this.state.participationAnswer).length !== selectedprobability.length) {
                            questionData['is_remove'] = true;
                        }
                    }
                    delete questionData['demographic'];
                    delete questionData['free_text_answer'];
                    questionData['option'] = finalselectedprobability;
                    questionData["question_id"] = currentActiveQuestionData['question_id']
                    questionData['question_type_id'] = currentActiveQuestionData['question_type_id']
                    questionData['scale_type'] = currentActiveQuestionData['scale_type']
                }
            }
            /**for the free-text type question, push data after validation */
            if (questionData.question_type_id === 1) {
                var freetextboxvalue = document.getElementsByClassName('free-text-textarea')
                if (freetextboxvalue[0].value === "" && checkValidation) {
                    errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                    return errormessage;
                }
                if (!questionData['free_text_answer']) {
                    if (freetextboxvalue[0].value) {
                        delete questionData['demographic']
                        delete questionData['option'];
                        questionData['free_text_answer'] = freetextboxvalue[0].value;
                        this.setState({ questionData })
                    } else if (checkValidation){
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                }
            }
            /** single selection with free-text type question, push data after validation */
            if (questionData.question_type_id === 3) {
                var checkradioselected = document.getElementsByClassName('singleselection');
                var allcheckedradioselected = [];
                var selectedRadio = [];
                for (let i = 0; i < checkradioselected.length; i++) {
                    if (checkradioselected[i].checked) {
                        if (!questionData['option'].length) {
                            var radioobj = {};
                            radioobj['option_id'] = parseInt(checkradioselected[i].value)
                            selectedRadio.push(radioobj);
                        }
                        allcheckedradioselected.push(checkradioselected[i])
                    }
                }
                if (questionData.hasOwnProperty('option')) {
                    if (!questionData['option'].length && allcheckedradioselected.length > 0) {
                        delete questionData['demographic'];
                        delete questionData.free_text_answer;
                        questionData['option'] = selectedRadio;
                        this.setState({ questionData })
                    }
                }
                if (allcheckedradioselected.length === 0) {
                    if (currentActiveQuestionData.is_free_text === 1) {
                        var singletextans = document.getElementsByClassName('free-text-ans')
                        if (singletextans[0].value === "") {
                            if (checkValidation) {
                                errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                                return errormessage;
                            }
                        } else {
                            if (!questionData['free_text_answer']) {
                                delete questionData['demographic']
                                delete questionData['option']
                                questionData['free_text_answer'] = singletextans[0].value;
                                this.setState({ questionData });
                            }
                        }
                    }
                    else if (checkValidation) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                }
            }
            /** insert ans object in surveyAnswer for the question type 4 that is multiple selection type question, push data after validation */
            if (questionData.question_type_id === 4) {
                var checkedCheckbox = document.getElementsByClassName("selected-checkbox");
                var checkboxarray = [];
                if (checkedCheckbox.length > 0) {
                    for (var i = 0; i < checkedCheckbox.length; i++) {
                        var obj = {};
                        obj['option_id'] = parseInt(checkedCheckbox[i].name)
                        checkboxarray.push(obj);
                    }
                    var freemultipleans = document.getElementsByClassName("free_answer_multiple");
                    if (freemultipleans[0]) {
                        if (freemultipleans[0] !== "") {
                            questionData['free_text_answer'] = freemultipleans[0].value;
                        }
                        if (freemultipleans[0].value === "") {
                            if (document.getElementsByClassName("check-condition")) {
                                if (document.getElementsByClassName("check-condition")[0].checked) {
                                    errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                                    return errormessage;
                                }
                            }
                        }
                    }
                    delete questionData['demographic']
                    questionData['option'] = checkboxarray;
                    this.setState({ questionData })
                } else {
                    if (currentActiveQuestionData.is_free_text === 1) {
                        var freemultiple = document.getElementsByClassName("free_answer_multiple");
                        if (freemultiple[0]) {
                            if (freemultiple[0].value === "" && checkValidation) {
                                errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                                return errormessage;
                            }
                            if (freemultiple[0].value !== "") {
                                questionData['free_text_answer'] = freemultiple[0].value;
                                this.setState({ questionData })
                            }
                        }
                    }
                    else if (checkValidation) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                }
            }
            /** insert ans object in surveyAnswer for the question type 5 that is rating type question, push data after validation */
            if (questionData.question_type_id === 5) {
                function getRankingButton(ratingName) {
                    var ratingbuttons = document.getElementsByClassName(ratingName);
                    var ratedraningbuttons = [];
                    if (ratingbuttons.length === Object.keys(currentActiveQuestionData['option']).length) {
                        for (var i = 0; i < ratingbuttons.length; i++) {
                            var obj = {};
                            if (ratingbuttons[i]) {
                                obj['option_id'] = parseInt(ratingbuttons[i].id);
                                obj['answer'] = parseInt(ratingbuttons[i].value);
                                ratedraningbuttons.push(obj);
                            }
                        }
                    }
                    return ratedraningbuttons.length > 0 ? ratedraningbuttons : null;
                }
                var ratedbuttons = getRankingButton("btn-grade-clr")
                let allvalue = []
                // eslint-disable-next-line
                let ordererror = "";
                if (ratedbuttons !== null) {
                    // eslint-disable-next-line
                    ratedbuttons.map((ans) => {
                        if (isNaN(ans['answer']) === true || ans['answer'] === "") {
                            ordererror = "true"
                        }
                        else {
                            allvalue.push(ans['answer'])
                        }
                    })
                }
                if (ordererror === "true") {
                    errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                    return errormessage
                }
                let samevaluefound = "";
                for (let i = 0; i < allvalue.length; i++) {                 /**it compare the value with rest of option if it same with any other then returns error */
                    for (let j = i + 1; j < allvalue.length; j++) {
                        if (allvalue[j] === allvalue[i]) {
                            samevaluefound = true
                        }
                    }
                }
                if (samevaluefound === true) {
                    errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage_for_ranking_order']
                    return errormessage
                }
                else {
                    if (ratedbuttons === null) {
                        var ratingbuttons = document.getElementsByClassName("btn-grade-clr");
                        if (checkValidation || ratingbuttons.length) {
                            errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage'];
                            return errormessage
                        }
                    } else {
                        delete questionData['demographic'];
                        questionData['option'] = ratedbuttons
                        this.setState({ questionData })
                    }
                }
            }
            /** pushing ans in surveyanswer object for question type id 6 with all scale type, push data after validation */
            if (questionData.question_type_id === 6) {
                /**for scale type 1*/
                if (questionData.scale_type === 1) {
                    let selectedpoints = document.getElementsByClassName('selected-points')
                    if (!questionData['free_text_answer']) {
                        if (selectedpoints && selectedpoints.length) {
                            delete questionData['demographic']
                            delete questionData['option'];
                            questionData['free_text_answer'] = parseInt(selectedpoints[0].value);
                            this.setState({ questionData })
                        } else {
                            if (checkValidation) {
                                errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                                return errormessage;
                            }
                        }
                    }
                }
                /** for scale type 3 */
                if (questionData.scale_type === 3) {
                    let selectedthumb = document.getElementsByClassName('selected-thumb')
                    if (!questionData['free_text_answer']) {
                        if (selectedthumb && selectedthumb.length) {
                            delete questionData['demographic']
                            delete questionData['option'];
                            questionData['free_text_answer'] = parseInt(selectedthumb[0].value);
                            this.setState({ questionData })
                        } else {
                            if (checkValidation) {
                                errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                                return errormessage;
                            }
                        }
                    }
                }
                /**for scale type 2 */
                if (questionData.scale_type === 2 || questionData.scale_type === 6) {
                    let selectedschool = document.getElementsByClassName('btn-grade-clr');
                    if (!questionData['free_text_answer']) {
                        if (selectedschool && selectedschool.length) {
                            delete questionData['demographic'];
                            delete questionData['option'];
                            questionData['free_text_answer'] = selectedschool[0].value;
                            this.setState({ questionData });
                        } else {
                            if (checkValidation) {
                                errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                                return errormessage;
                            }
                        }
                    }
                }
                /**for scale type 4 */
                if (questionData.scale_type === 4) {
                    let singleprobability = document.getElementsByClassName("selected-probability")
                    if (!questionData['free_text_answer']) {
                        if (singleprobability.length && singleprobability[0].getElementsByTagName('input').length !== 0) {
                            if (checkValidation && Object.keys(this.state.participationAnswer).length === 0) {
                                errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                                return errormessage;
                            }
                            delete questionData['option'];
                            delete questionData['demographic'];
                            questionData["question_id"] = currentActiveQuestionData['question_id']
                            questionData['question_type_id'] = currentActiveQuestionData['question_type_id']
                            questionData['scale_type'] = currentActiveQuestionData['scale_type']
                            questionData['free_text_answer'] = parseInt(singleprobability[0].getElementsByTagName('input')[0].value);
                        }
                    }
                }
                /**for scale type 5 */
                if (questionData.scale_type === 5) {
                    let selectedfreetext = document.getElementsByClassName('btn-grade-clr');
                    if (!questionData['free_text_answer']) {
                        if (selectedfreetext && selectedfreetext.length) {
                            delete questionData['demographic'];
                            let obj = {}
                            obj["option_id"] = parseInt(selectedfreetext[0].value)
                            questionData['option'][0] = obj
                            this.setState({ questionData });
                        } else {
                            if (checkValidation) {
                                errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                                return errormessage;
                            }
                        }
                    }
                }
            }
            /** pushing ans in surveyanswer object for question type id 7 with all scale type, push data after validation */
            if (questionData.question_type_id === 7) {
                /**pushing ans in surveyAnswer object of scale type 2(school grade type question) of survaye ranting with more elements type question */
                if (questionData.scale_type === 2) {
                    let selected = document.getElementsByClassName('school-grade')
                    if (selected.length === Object.keys(currentActiveQuestionData['option']).length) {
                        let finalselected = [];
                        for (let i = 0; i < selected.length; i++) {
                            var objschool = {};
                            if (selected[i]) {
                                objschool['option_id'] = parseInt(selected[i].name);
                                objschool['answer'] = selected[i].value;
                                finalselected.push(objschool);
                            }
                        }
                        delete questionData['demographic'];
                        questionData['option'] = finalselected;
                        this.setState({ questionData })
                    }
                    else {
                        if (checkValidation) {
                            errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                            return errormessage;
                        }
                    }
                }
                /**pushing ans in surveyAnswer object of scale type 6(survey approval type question) of survaye ranting with more elements type question */
                if (questionData.scale_type === 6) {
                    let selected = document.getElementsByClassName('school-grade')
                    if (selected.length === Object.keys(currentActiveQuestionData['option']).length) {
                        let finalselected = [];
                        for (let i = 0; i < selected.length; i++) {
                            var objschool = {};
                            if (selected[i]) {
                                objschool['option_id'] = parseInt(selected[i].name);
                                objschool['answer'] = selected[i].value;
                                finalselected.push(objschool);
                            }
                        }
                        delete questionData['demographic'];
                        questionData['option'] = finalselected;
                        this.setState({ questionData })
                    }
                    else {
                        if (checkValidation) {
                            errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                            return errormessage;
                        }
                    }
                }
                /**pushing ans in surveyAnswer object of scale type 5 (free text type question) of survaye ranting with more elements type question */
                if (questionData.scale_type === 5) {
                    let selectedfreetext = document.getElementsByClassName('free-text')
                    if (selectedfreetext.length === Object.keys(currentActiveQuestionData['scale_text']).length) {
                        let finalselectedfreetext = [];
                        for (let i = 0; i < selectedfreetext.length; i++) {
                            var objfreetext = {}
                            objfreetext['option_id'] = parseInt(selectedfreetext[i].name);
                            objfreetext['answer'] = parseInt(selectedfreetext[i].value);
                            finalselectedfreetext.push(objfreetext);
                        }
                        delete questionData['demographic'];
                        questionData['option'] = finalselectedfreetext;
                        this.setState({ questionData })
                    }
                    else {
                        if (checkValidation) {
                            errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                            return errormessage;
                        }
                    }
                }
                /**pushing ans in surveyAnswer object of scale type 4 (probability) of survey rating with more elements type question */
                if (questionData.scale_type === 4) {
                    let selectedprobability = document.getElementsByClassName('selected-probability')
                    let finalselectedprobability = [];
                    if (checkValidation && Object.keys(this.state.participationAnswer).length !== selectedprobability.length) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                    if(!checkValidation && Object.keys(this.state.participationAnswer).length !== 0 && Object.keys(this.state.participationAnswer).length !== selectedprobability.length) {
                        errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                        return errormessage;
                    }
                    for (let i = 0; i < selectedprobability.length; i++) {
                        var objprobability = {}
                        objprobability['option_id'] = parseInt(selectedprobability[i].getElementsByTagName('input')[0].name);
                        objprobability['answer'] = parseInt(selectedprobability[i].getElementsByTagName('input')[0].value);
                        finalselectedprobability.push(objprobability);
                    }
                    delete questionData['demographic'];
                    questionData['option'] = finalselectedprobability;
                    this.setState({ questionData })
                }
                /**pushing ans in surveyAnswer object of scale type 4 (probability) of survey rating with more elements type question */
                if (questionData.scale_type === 1) {
                    let selectedpoints = document.getElementsByClassName('selected-points')
                    if (selectedpoints.length === Object.keys(currentActiveQuestionData['option']).length) {
                        let finalselectedpoints = [];
                        for (let i = 0; i < selectedpoints.length; i++) {
                            var objpoints = {}
                            objpoints['option_id'] = parseInt(selectedpoints[i].name);
                            objpoints['answer'] = parseInt(selectedpoints[i].value);
                            finalselectedpoints.push(objpoints);
                        }
                        delete questionData['demographic'];
                        questionData['option'] = finalselectedpoints;
                        this.setState({ questionData })
                    } else {
                        if (checkValidation) {
                            errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                            return errormessage;
                        }
                    }
                }
                /**pushing ans in surveyAnswer object of scale type 3 (thumbs type ans) of survey rating with more elements type question */
                if (questionData.scale_type === 3) {
                    let selectedthumbs = document.getElementsByClassName('selected-thumbs')
                    if (selectedthumbs.length === Object.keys(currentActiveQuestionData['option']).length) {
                        let finalselectedthumbs = [];
                        for (let i = 0; i < selectedthumbs.length; i++) {
                            var objthumb = {}
                            objthumb['option_id'] = parseInt(selectedthumbs[i].name);
                            objthumb['answer'] = parseInt(selectedthumbs[i].value);
                            finalselectedthumbs.push(objthumb);
                        }
                        delete questionData['demographic'];
                        questionData['option'] = finalselectedthumbs;
                        this.setState({ questionData })
                    }
                    else {
                        if (checkValidation) {
                            errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                            return errormessage;
                        }
                    }
                }
            }
        }
        /** for the validation of demographic attribute if it type something and erase at that time of validation */
        if (questionData['demographic'] !== undefined) {
            let error = ""
            let locationerror = "";
            // eslint-disable-next-line
            DEMOGRAPHIC_PARTICIPATION_CONSTANT['EN'].map((title, index) => {
                // eslint-disable-next-line
                Object.keys(questionData['demographic']).map((key) => {
                    if (title.title === key) {
                        if (key === "age") {
                            if (isNaN(parseInt(questionData['demographic'][title.title])) === true || questionData['demographic'][title.title] === "") {
                                error = "true"
                            }
                        }
                        else if (key === "postalcode_id") {
                            var location = document.getElementsByClassName('textfield-type-demo')
                            if (this.state.locationData.length > 0) {
                                let locationFound = false;
                                for (let i = 0; i < this.state.locationData.length; i++) {
                                    if (this.state.locationData[i].postalcode_city.toString() === location[0].value.toString()) {
                                        locationFound = true;
                                    }
                                }
                                if (locationFound) {
                                    error = "";
                                    return error;
                                } else {
                                    locationerror = "true";
                                }
                            } else {
                                if (location[0].value) {
                                    locationerror = "true"
                                } else {
                                    error = "true"
                                }
                            }
                        }
                        else {
                            if (questionData['demographic'][title.title] === "") {
                                error = "true"
                            }
                        }
                    }
                })
            })
            if (error === "true") {
                errormessage = SURVEY_PARTICIPATION[englishbuttonlabels]['errormessage']
                return errormessage;
            }
            if (locationerror === "true") {
                errormessage = SURVEY_PARTICIPATION[LANGUAGE_CODE]['correctlocation']
                return errormessage;
            }
        }
        if (questionData) {
            let i = 0;
            var key;
            var id;
            var demographickey;
            var demographicindex;
            if (questionData['demographic'] !== undefined && questionData.scale_type !== 4) {
                if (questionData['demographic'].length !== 0) {
                    for (i = 0; i < surveyAnswer['demographic'].length; i++) {
                        if (surveyAnswer['demographic'][i].hasOwnProperty(Object.keys(questionData['demographic'])[0]) === true) {
                            demographicindex = i;
                            demographickey = "true";
                        }
                    }
                    if (demographickey === 'true') {
                        surveyAnswer['demographic'][demographicindex] = questionData['demographic'];
                    }
                    else {
                        surveyAnswer['demographic'].push(questionData['demographic'])
                    }
                    this.setState({ surveyAnswer })
                }
            }
            else {
                for (i = 0; i < surveyAnswer['question'].length; i++) {
                    if ((questionData.question_id) === surveyAnswer['question'][i]['question_id']) {
                        id = i;
                        key = "true";
                    }
                }
                if (key === "true") {
                    this.setState({ errormessage: "" })
                    surveyAnswer['question'][id] = questionData;
                }
                else {
                    this.setState({ errormessage: "" })
                    surveyAnswer['question'].push(questionData)
                }
                this.setState({ surveyAnswer })
            }
        }
        this.setState({ questionData: { "option": [], "demographic": [], "free_text_answer": "", "question_id": "", "question_type_id": "" }, errormessage: "" })
    }
    /** on click of continue button next question is load...if demographic attribute is exist
     * then sub question of demographic call else questioncomponent's sub question load */
    getUserAnswerbyQuestionId = (quest_id) => {
        let ret = null;
        // eslint-disable-next-line
        this.state.surveyAnswer.question.map(quest => {
            if (quest.question_id === quest_id) {
                ret = quest
            }
        })
        return ret;
    }
    continue = (event) => {
        event.preventDefault();
        /*--- Added keep selected answer to Find Skip to Index of option --- */
        let userSubmittedAns = '';
        if (this.state.activeIndex === 3 || this.state.activeIndex === 2 || this.state.activeIndex === 1) {
            var iserror = this.manageAnswerObject();
            let obj = {};
            if (this.state.questionData.question_type_id === 2) {
                obj['content'] = "content";
                obj['question_type_id'] = this.state.questionData.question_type_id;
                obj['question_id'] = this.state.questionData.question_id;
                if (this.state.surveyAnswer.question[this.state.questionchild] === undefined) {
                    this.state.surveyAnswer['question'].push(obj)
                }
            }
            if (this.state.activeIndex === 2 && this.state.questionData.question_type_id === 6 && this.state.questionData.scale_type < 5) {
                if (this.state.questionData.free_text_answer) {
                    userSubmittedAns = this.state.questionData
                } else {
                    userSubmittedAns = this.getUserAnswerbyQuestionId(this.state.questionData.question_id);
                }
            } else {
                userSubmittedAns = (this.state.activeIndex === 2 && this.state.questionData.hasOwnProperty('option') &&
                    this.state.questionData.option.length) ? this.state.questionData : this.getUserAnswerbyQuestionId(this.state.questionData.question_id);
            }
            if (this.state.activeIndex === 2 && this.state.questionData.question_type_id === 6 && this.state.questionData.scale_type === 6) {
                if (this.state.questionData.free_text_answer) {
                    userSubmittedAns = this.state.questionData
                } else {
                    userSubmittedAns = this.getUserAnswerbyQuestionId(this.state.questionData.question_id);
                }
            } else {
                userSubmittedAns = (this.state.activeIndex === 2 && this.state.questionData.hasOwnProperty('option') &&
                    this.state.questionData.option.length) ? this.state.questionData : this.getUserAnswerbyQuestionId(this.state.questionData.question_id);
            }
            this.setState({ errormessage: iserror })
        }
        if (iserror === undefined || iserror === "") {
            this.scrollUpFunction();
            let index = this.state.activeIndex;
            let lastIndex = 0;
            let data = this.props.questionData;
            let demographiclength = this.state.demographiclength;
            let demographicchild = this.state.demographicchild;
            let questionslength = data.surveyquestiondata.length;
            let surveyPartition;
            let redirectTOLastComponent = false;
            if (this.surveyMainId) {
                surveyPartition = JSON.parse(localStorage.getItem('surveyparticipationquestion_' + this.surveyMainId));
            }
            let surveyQuestData = surveyPartition.surveyquestiondata;
            let questionchild = this.state.questionchild;
            if (index === 1) {
                if (questionslength > 0) {
                    let activeIndex = parseInt(index) + 1;
                    this.setState({ activeIndex });
                }
                else {
                    let activeIndex = parseInt(index) + 2;
                    this.setState({ activeIndex });
                }
            }
            if (index === 3) {
                let activeIndex = parseInt(index);
                if (demographicchild < demographiclength) {
                    demographicchild = parseInt(demographicchild) + 1;
                }
                if (demographicchild === demographiclength) {
                    activeIndex = parseInt(index) + 1;
                }
                this.setState({ activeIndex, demographicchild });
            }
            if (index === 3 && demographicchild > demographiclength) {
                let activeIndex = parseInt(index) + 1;
                this.setState({ activeIndex });
            }
            if (index === 2) {
                // eslint-disable-next-line
                let activeIndex = parseInt(index);
                if (questionchild < questionslength) {
                    questionchild = parseInt(questionchild) + 1;
                    this.setState({ startQuestionIndex: parseInt(questionchild) + 1 }) //set the question startindex for displaying question number out of total question
                }
                if (this.state.currentActiveQuestionData && (parseInt(this.state.currentActiveQuestionData.question_order_index) + 1 === questionslength)) {
                    lastIndex = lastIndex + 1
                }
                if (this.state.currentActiveQuestionData.option && this.state.currentActiveQuestionData.option.length) {
                    /* --- Added for Skip Question for Scale Type 3 or 1 --- */
                    if (this.state.currentActiveQuestionData.question_type_id === 6 && ((this.state.currentActiveQuestionData.scale_type === 3) || (this.state.currentActiveQuestionData.scale_type === 1))) {
                        // eslint-disable-next-line
                        this.state.currentActiveQuestionData.option.map((active_opts) => {
                            if (userSubmittedAns) {
                                let check_str = userSubmittedAns.free_text_answer;
                                if (check_str.toString() === active_opts.option_text.toString()) {
                                    if (active_opts.option_end_survey) {
                                        redirectTOLastComponent = true;
                                    }
                                    else if (active_opts.option_skip_question) {
                                        // eslint-disable-next-line
                                        this.state.questionPath[this.state.questionchild + 1] = surveyQuestData[parseInt(active_opts.option_skip_question) - 1];
                                        this.setState({
                                            currentActiveQuestionData: surveyQuestData[parseInt(active_opts.option_skip_question) - 1]
                                        })
                                    }
                                    else {
                                        this.goToNextQuestion();
                                    }
                                }
                            }
                            else if (this.state.currentActiveQuestionData.is_question_optional && parseInt(this.state.currentActiveQuestionData.is_question_optional) === 1) {
                                // eslint-disable-next-line
                                this.state.questionPath[this.state.questionchild + 1] = surveyQuestData[parseInt(this.state.currentActiveQuestionData.question_order)-1]
                                this.setState({
                                    currentActiveQuestionData: surveyQuestData[parseInt(this.state.currentActiveQuestionData.question_order)-1]
                                })
                                this.goToNextQuestion();
                            }
                        })
                    }
                    /* --- Added for Skip Question for Scale Type 2 --- */
                    else if (this.state.currentActiveQuestionData.question_type_id === 6 && this.state.currentActiveQuestionData.scale_type === 2) {
                        this.state.currentActiveQuestionData.option.map((active_opts) => {
                            if (userSubmittedAns) {
                                let check_str = userSubmittedAns.free_text_answer;
                                if (check_str === active_opts.option_text) {
                                    if (active_opts.option_end_survey) {
                                        redirectTOLastComponent = true;
                                    }
                                    else if (active_opts.option_skip_question) {
                                        // eslint-disable-next-line
                                        this.state.questionPath[this.state.questionchild + 1] = surveyQuestData[parseInt(active_opts.option_skip_question) - 1];
                                        this.setState({
                                            currentActiveQuestionData: surveyQuestData[parseInt(active_opts.option_skip_question) - 1]
                                        })
                                    }
                                    else {
                                        this.goToNextQuestion();
                                    }
                                }
                            }
                            else if (this.state.currentActiveQuestionData.is_question_optional && parseInt(this.state.currentActiveQuestionData.is_question_optional) === 1) {
                                // eslint-disable-next-line
                                this.state.questionPath[this.state.questionchild + 1] = surveyQuestData[parseInt(this.state.currentActiveQuestionData.question_order) - 1]
                                this.setState({
                                    currentActiveQuestionData: surveyQuestData[parseInt(this.state.currentActiveQuestionData.question_order)-1]
                                })
                                this.goToNextQuestion();
                            }
                        })
                    }
                    /* --- Added for Skip Question for Scale Type 6 --- */
                    else if (this.state.currentActiveQuestionData.question_type_id === 6 && this.state.currentActiveQuestionData.scale_type === 6) {
                        // eslint-disable-next-line
                        this.state.currentActiveQuestionData.option.map((active_opts) => {
                            if (userSubmittedAns) {
                                let check_str = userSubmittedAns.free_text_answer;
                                if (check_str === active_opts.option_text) {
                                    if (active_opts.option_end_survey) {
                                        redirectTOLastComponent = true;
                                    }
                                    else if (active_opts.option_skip_question) {
                                        // eslint-disable-next-line
                                        this.state.questionPath[this.state.questionchild + 1] = surveyQuestData[parseInt(active_opts.option_skip_question) - 1];
                                        this.setState({
                                            currentActiveQuestionData: surveyQuestData[parseInt(active_opts.option_skip_question) - 1]
                                        })
                                    }
                                    else {
                                        this.goToNextQuestion();
                                    }
                                }
                            }
                            else if (this.state.currentActiveQuestionData.is_question_optional && parseInt(this.state.currentActiveQuestionData.is_question_optional) === 1) {
                                // eslint-disable-next-line
                                this.state.questionPath[this.state.questionchild + 1] = surveyQuestData[parseInt(this.state.currentActiveQuestionData.question_order) - 1]
                                this.setState({
                                    currentActiveQuestionData: surveyQuestData[parseInt(this.state.currentActiveQuestionData.question_order)-1]
                                })
                                this.goToNextQuestion();
                            }
                        })
                    }
                    /*  Added for Skip Question for Scale Type 4 */
                    else if (this.state.currentActiveQuestionData.question_type_id === 6 && this.state.currentActiveQuestionData.scale_type === 4) {
                        // eslint-disable-next-line
                        this.state.currentActiveQuestionData.option.map((active_opts) => {
                            if (userSubmittedAns) {
                                let check_str = userSubmittedAns.free_text_answer.toString();
                                let startRange = active_opts.option_text.split(',')[0]
                                let endRange = active_opts.option_text.split(',')[1]
                                if (parseInt(check_str) >= parseInt(startRange) && parseInt(check_str) <= parseInt(endRange)) {
                                    if (active_opts.option_end_survey && !userSubmittedAns.hasOwnProperty('is_remove')) {
                                        redirectTOLastComponent = true;
                                    }
                                    else if (active_opts.option_skip_question && !userSubmittedAns.hasOwnProperty('is_remove')) {
                                        // eslint-disable-next-line
                                        this.state.questionPath[this.state.questionchild + 1] = surveyQuestData[parseInt(active_opts.option_skip_question - 1)];
                                        this.setState({
                                            currentActiveQuestionData: surveyQuestData[parseInt(active_opts.option_skip_question) - 1]
                                        })
                                    }
                                    else {
                                        this.goToNextQuestion();
                                    }
                                } else {
                                    this.goToNextQuestion();
                                }
                            }
                            else if (this.state.currentActiveQuestionData.is_question_optional && parseInt(this.state.currentActiveQuestionData.is_question_optional) === 1) {
                                // eslint-disable-next-line
                                this.state.questionPath[this.state.questionchild + 1] = surveyQuestData[parseInt(this.state.currentActiveQuestionData.question_order) - 1]
                                this.setState({
                                    currentActiveQuestionData: surveyQuestData[parseInt(this.state.currentActiveQuestionData.question_order) - 1]
                                })
                                this.goToNextQuestion();
                            }
                        })
                    }
                    else if (this.state.currentActiveQuestionData.question_type_id === 3) {
                        // eslint-disable-next-line
                        this.state.currentActiveQuestionData.option.map((opt) => {
                            if (userSubmittedAns && userSubmittedAns.hasOwnProperty('option')) {
                                let checkIdwith = userSubmittedAns.option[0].option_id;
                                if (opt.id === checkIdwith) {
                                    if (opt.option_end_survey) {
                                        redirectTOLastComponent = true;
                                    }
                                    else if (opt.option_skip_question) {
                                        // eslint-disable-next-line
                                        this.state.questionPath[this.state.questionchild + 1] = surveyQuestData[parseInt(opt.option_skip_question) - 1];
                                        this.setState({
                                            currentActiveQuestionData: surveyQuestData[parseInt(opt.option_skip_question) - 1]
                                        })
                                    } else {
                                        this.goToNextQuestion();
                                    }
                                }
                            } else {
                                if (opt.option_text === "free_text_condition" && userSubmittedAns && userSubmittedAns.free_text_answer) {
                                    if (opt.option_end_survey) {
                                        redirectTOLastComponent = true;
                                    }
                                    else if (opt.option_skip_question) {
                                        // eslint-disable-next-line
                                        this.state.questionPath[this.state.questionchild + 1] = surveyQuestData[parseInt(opt.option_skip_question) - 1]
                                        this.setState({
                                            currentActiveQuestionData: surveyQuestData[parseInt(opt.option_skip_question) - 1]
                                        })
                                    }
                                    else {
                                        this.goToNextQuestion();
                                    }
                                }
                                if (this.state.currentActiveQuestionData.is_question_optional && parseInt(this.state.currentActiveQuestionData.is_question_optional) === 1) {
                                    // eslint-disable-next-line
                                    this.state.questionPath[this.state.questionchild + 1] = surveyQuestData[parseInt(this.state.currentActiveQuestionData.question_order) - 1]
                                    this.setState({
                                        currentActiveQuestionData: surveyQuestData[parseInt(this.state.currentActiveQuestionData.question_order) - 1]
                                    })
                                    this.goToNextQuestion();
                                }
                            }

                        })
                    }
                    else if (userSubmittedAns && userSubmittedAns.hasOwnProperty('option') && this.state.currentActiveQuestionData.hasOwnProperty('option')) {
                        // eslint-disable-next-line
                        this.state.currentActiveQuestionData.option.map((opt) => {
                            /** for question type 7 answer condition */
                            let checkIdwith = (this.state.currentActiveQuestionData.question_type_id === 7 && this.state.currentActiveQuestionData.scale_type === 5) ? userSubmittedAns.option[0].answer : userSubmittedAns.option[0].option_id;
                            if (opt.id === checkIdwith) {
                                if (opt.option_end_survey) {
                                    redirectTOLastComponent = true;
                                }
                                else if (opt.option_skip_question) {
                                    // eslint-disable-next-line
                                    this.state.questionPath[this.state.questionchild + 1] = surveyQuestData[parseInt(opt.option_skip_question) - 1];
                                    this.setState({
                                        currentActiveQuestionData: surveyQuestData[parseInt(opt.option_skip_question) - 1]
                                    })
                                } else {
                                    this.goToNextQuestion();
                                }
                            }
                        })
                        // eslint-disable-next-line
                    }
                    else {
                        this.goToNextQuestion();
                    }
                } else {
                    this.goToNextQuestion();
                }
                // eslint-disable-next-line
                let currentquestionPath = this.state.questionPath;
                let activeIndexNew = parseInt(index);
                if (parseInt(this.state.currentActiveQuestionData.question_order_index) === questionslength || redirectTOLastComponent) {
                    if (this.state.demographyQuestionDisplay) {
                        activeIndexNew = activeIndexNew + 1;
                    }
                    else {
                        activeIndexNew = activeIndexNew + 2;
                    }
                }
                this.setState({ activeIndex: activeIndexNew, lastIndex: lastIndex, questionchild, questionPath: this.state.questionPath });
            }
        }
        else{
            this.scrollUpFunction(true);
        }
        if (this.state.activeIndex === 3) {
            this.setState({ participationAnswer: {} })
        }
        if (iserror === undefined || iserror === "") {
            this.setState({ backButtonClick: false });
        }
        event.preventDefault();
    }
    /* --- Added Function to go to next question if skip question is not there --- */
    goToNextQuestion() {
        let surveyPartition;
        if (this.surveyMainId) {
            surveyPartition = JSON.parse(localStorage.getItem('surveyparticipationquestion_' + this.surveyMainId));
        }
        let surveyQuestData = surveyPartition.surveyquestiondata;
        let lastAddedQuestionObj = this.state.questionPath[this.state.questionPath.length - 1];
        let lastQuestObjQuestId = lastAddedQuestionObj.question_id;
        // eslint-disable-next-line
        surveyQuestData.map((question, index) => {
            if (lastQuestObjQuestId === question.question_id && index !== surveyQuestData.length - 1) {
                let questionPathLocal = this.state.questionPath;
                // eslint-disable-next-line
                questionPathLocal[this.state.questionchild + 1] = surveyQuestData[parseInt(index) + 1]
                this.setState({
                    questionPath: questionPathLocal,
                    currentActiveQuestionData: surveyQuestData[parseInt(index) + 1]
                })
            }
        })
    }
    /** on click of back button previous question is load */
    back = (event) => {
        if (this.state.islastQuestion) {
            this.setState({ islastQuestion: false })
        }
        if (parseInt(this.state.indexprevious) === this.state.activeIndex && this.state.questionchild === 0 && this.state.demographicchild === 0) {
            this.props.changeIndex(0);
        }
        this.scrollUpFunction();
        this.setState({ backButtonClick: true, lastIndex: 0, errormessage: "", questionData: { "option": [], "demographic": [], "free_text_answer": "", "question_id": "", "question_type_id": "" } })
        let index = this.state.activeIndex;
        let data = this.props.questionData;
        let demographiclength = this.state.demographiclength;
        let demographicchild = this.state.demographicchild;
        let questionslength = data.surveyquestiondata.length;
        let questionchild = this.state.questionchild;
        if (index === 3) {
            let activeIndex = parseInt(index);
            if (demographicchild < demographiclength) {
                if (demographicchild === 0 && questionslength) {
                    questionchild = parseInt(questionchild) - 1;
                    activeIndex = parseInt(index) - 1;
                    this.state.questionPath.splice(questionchild + 1, 1);
                    this.setState({ currentActiveQuestionData: this.state.questionPath[questionchild] });
                }
                else {
                    demographicchild = parseInt(demographicchild) - 1;
                }
            }
            this.setState({ activeIndex, demographicchild, questionchild });
        }
        if (index === 3 && demographicchild > demographiclength) {
            let activeIndex = parseInt(index) - 1;
            this.setState({ activeIndex });
        }
        if (index === 1) {
            let activeIndex = parseInt(index) - 1;
            this.setState({ activeIndex })
        }
        if (index === 2) {
            let activeIndex = parseInt(index);
            if (questionchild < questionslength) {
                if (questionchild === 0) {
                    activeIndex = parseInt(index) - 1;
                }
                else {
                    questionchild = parseInt(questionchild) - 1;
                    this.setState({ startQuestionIndex: parseInt(questionchild) + 1 })     //decrease the startindex on click of back button
                }
            }
            // eslint-disable-next-line
            this.state.questionPath.splice(questionchild + 1, 1);
            this.setState({ activeIndex, questionchild, demographicchild, currentActiveQuestionData: this.state.questionPath[questionchild] });
        }
        if (index === 2 && questionchild > questionslength) {
            let activeIndex = parseInt(index) - 1;
            this.setState({ activeIndex });
        }
        if (this.state.activeIndex === 3) {
            /** start - Functionality for saving answer of demography whenever change answer by user and go back */
            if (this.state.participationAnswer && Object.keys(this.state.participationAnswer).length && (this.state.demographicchild === 0 || this.state.demographicchild)) {
                let localSurveyAnswers = this.state.surveyAnswer;
                let demographyAnswer = localSurveyAnswers['demographic'] ? localSurveyAnswers['demographic'] : {};
                demographyAnswer[this.state.demographicchild] = this.state.participationAnswer;
                localSurveyAnswers['demographic'] = demographyAnswer;
                this.setState({ surveyAnswer: localSurveyAnswers })
            }
            /** end - Functionality for saving answer of demography whenever change answer by user and go back */
            this.setState({ participationAnswer: {} })
        }
        event.preventDefault();
    }
    /**to finish the survey participation process */
    finish = async (event) => {
        event.preventDefault();
        this.getSurveyEndPageInfo()
        if (!this.disableFinishButton) {
            var iserror = this.manageAnswerObject();
            let sessionRedirect = this.state.sessionRedirect;
            let surveydetail;
            let autoprofile;
            let demographic_id;
            let totalkarma = 0;
            let annonymousid = this.state.annonymousUserId;
            this.setState({ errormessage: iserror, sessionRedirect })
            if (iserror !== "" || iserror !== undefined) {
                this.setState({ questionData: { "option": [], "demographic": [], "free_text_answer": "", "question_id": "", "question_type_id": "" } })
            }
            if (iserror === "" || iserror === undefined) {
                this.disableFinishButton = true;
                this.setState({ loading: 'true' })
                if (this.surveyMainId) {
                    surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + this.surveyMainId));
                    demographic_id = surveydetail.demographic_id;
                    /**clear surveyParticipationQuestion in localstoarge after finish the survey  */
                    localStorage.removeItem('surveyparticipationquestion_' + this.surveyMainId);
                }
                let storeitem = JSON.parse(localStorage.getItem('userdata'));
                let apitoken = '';
                let userid = '';
                if (storeitem !== null) {
                    apitoken = storeitem.token;
                    autoprofile = storeitem.autoprofile;
                    userid = storeitem.id;
                }
                var data = this.state.surveyAnswer;
                if (data.demographic.length > 0) {
                    // eslint-disable-next-line
                    Object.keys(data.demographic).map((ans, index) => {
                        // eslint-disable-next-line
                        data.demographic.map((key, index) => {
                            // eslint-disable-next-line
                            Object.keys(key).map((finalkey) => {
                                if (finalkey === 'postalcode') {
                                    delete data.demographic[ans][finalkey]
                                }
                                if (finalkey === 'postalcode_id') {
                                    let keyOfMatch;
                                    let matchPostalCode = false;
                                    // eslint-disable-next-line
                                    this.state.locationData.map((locationNew,index)=>{
                                        if (locationNew.id && data.demographic[ans][finalkey] === locationNew.id.toString()) {
                                            matchPostalCode = true;
                                            // eslint-disable-next-line
                                            return;
                                        }
                                        if (locationNew.id && data.demographic[ans][finalkey] !== locationNew.id.toString() && data.demographic[ans][finalkey] === locationNew.postalcode_city) {
                                            keyOfMatch = index;
                                        }
                                    })
                                    if (!matchPostalCode && keyOfMatch && this.state.locationData[keyOfMatch] && this.state.locationData[keyOfMatch]['id']) {
                                        data.demographic[ans][finalkey] = this.state.locationData[keyOfMatch]['id'];
                                    }
                                }
                            })
                        })
                    })
                }
                let newObject = []
                if (data.question.length > 0) {
                    // eslint-disable-next-line
                    Object.keys(data.question).map((d, index) => {
                        // eslint-disable-next-line
                        Object.keys(this.state.questionPath).map((qp, qindex) => {
                            if (data.question[d]['question_id'] === this.state.questionPath[qp]['question_id']) {
                                if (data.question[d]['scale_type'] === 4 && data.question[d]['is_remove'] === true) {
                                }
                                else if (data.question[d]['question_type_id'] === 1) {
                                    if (data.question[d]['free_text_answer'] === "" || (this.state.allStateData[qindex] && this.state.allStateData[qindex][this.state.questionPath[qp]['question_id']] === "")) {
                                    }
                                    else {
                                        newObject.push(data.question[d])
                                    }
                                }
                                else {
                                    newObject.push(data.question[d])
                                }
                            }
                        })
                    })
                }
                data['question'] = newObject;
                var headers = {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + apitoken
                }
                let self = this;
                if (surveydetail.duration) {
                    data.average_duration = surveydetail.duration;
                }
                var d = new Date();
                let day = d.getDate();
                let month = d.getMonth() + 1;
                let year = d.getFullYear();
                if (day < 10) {
                    day = "0" + day;
                }
                if (month < 10) {
                    month = "0" + month;
                }
                let newenddate = year + "-" + month + "-" + day;
                var n = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
                var completed_at = newenddate + " " + n;
                if (this.props.userid && this.props.startSurvey) {
                    data.completed_at = completed_at;
                    if (autoprofile) {
                        data.demographic_id = demographic_id ? demographic_id : "";
                        data.autoprofile = autoprofile;
                    }
                    // eslint-disable-next-line
                    await axios.post(API_URL + "/survey/surveyparticipant"+ "?uId=" + userid, data, { headers: headers })
                        .then((response) => {
                            if (response.data) {
                                if (response.data.success) {
                                    totalkarma = response.data.success.totalearnkarmapoints;
                                    if (surveydetail) {
                                            surveydetail['isparticipate'] = 1;
                                        localStorage.setItem(('surveydetail_' + this.surveyMainId), JSON.stringify(surveydetail))
                                    }
                                }
                            }
                            this.disableFinishButton = false;
                            self.setState({ activeIndex: 4, loading: 'false', lastIndex: 0, totalearnkarmapoints: totalkarma })
                        })
                        .catch((error) => {
                            if (error) {
                                if (error.response) {
                                    if (error.response.data) {
                                        let msg = error.response.data['error']
                                        if (msg === 'Expired token' || msg === 'Invalid token') {
                                            localStorage.clear();
                                            sessionRedirect = true;
                                        }
                                    }
                                }
                            }
                            this.disableFinishButton = false;
                            self.setState({ loading: 'false', sessionRedirect })
                        })
                }
                else {
                    if (this.props.annonymouslug && !this.props.startSurvey && !this.props.previewSurvey) {
                        data.completed_at = completed_at;
                        if (surveydetail) {
                            if (surveydetail.hasOwnProperty('ip')) {
                                data.ip = "";
                            }
                        }
                        delete data.user_id;
                        await axios.post(API_URL + "/anonymoussurveyresult/surveyparticipantByAnonymousUser", data)
                            .then((response) => {
                                if (response.data) {
                                    if (response.data.success) {
                                        totalkarma = response.data.success.totalearnkarmapoints;
                                        annonymousid = response.data.success.user_id;
                                        if (surveydetail) {
                                                surveydetail['isparticipate'] = 1;
                                                this.setCookie(this.surveyMainId);
                                                this.getSurveyEndPageInfo()
                                            localStorage.setItem(('surveydetail_' + this.surveyMainId), JSON.stringify(surveydetail))
                                        }
                                    }
                                }
                                this.disableFinishButton = false;
                                self.setState({ activeIndex: 4, loading: 'false', lastIndex: 0, totalearnkarmapoints: totalkarma, annonymousUserId: annonymousid })
                            })
                            .catch((error) => {
                                if (error) {
                                    if (error.response) {
                                        if (error.response.data) {
                                            let msg = error.response.data['error']
                                            if (msg === 'Expired token' || msg === 'Invalid token') {
                                                localStorage.clear();
                                                sessionRedirect = true;
                                            }
                                        }
                                    }
                                }
                                this.disableFinishButton = false;
                                self.setState({ loading: 'false', sessionRedirect })
                            })
                    } else {
                        this.setState({ redirectMyprofile: "true", loading: "false" })
                    }
                }
            }
            else {
                this.scrollUpFunction(true);
            }
        }
    }  
    getSurveyEndPageInfo = async () =>{
        await axios.get(API_URL + "/commonApi/getSurveyEndPageInfo/" + LANGUAGE_CODE + '/'+ this.surveyMainId +'?uId=' + this.surveyMainId)
        .then((response) => {
            this.setState({endpageData: response.data.default_card})
        })
        .catch((error) => {
            console.log(error);
        })
    }
    finishsurvey = (event) => {
        if (this.surveyMainId) {
            localStorage.removeItem('surveyparticipationquestion_' + this.surveyMainId);
        }
        this.setState({ redirecttosurvey: "true" })
        event.preventDefault();
    }
    /**this function is for handling change for demographic attributes */
    demographicChange = (event) => {
        let participationAnswer = {};
        this.setState({ participationAnswer, errormessage: "" })
        let name = event.target.name;
        let value = event.target.value;
        participationAnswer = Object.assign({}, this.state.participationAnswer);
        let questionData = Object.assign({}, this.state.questiondata);
        participationAnswer[name] = value;
        var obj = {}
        if (this.state.demographicdata !== '') {
            obj[name] = value
            questionData['demographic'] = obj;
        }
        var data = this.state.surveyAnswer;
        var demographickey;
        var demographicindex;
        if (data.demographic.length > 0) {
            for (let i = 0; i < data['demographic'].length; i++) {
                if (data['demographic'][i].hasOwnProperty(name) === true) {
                    demographicindex = i;
                    demographickey = "true";
                }
            }
            if (demographickey === 'true') {
                data['demographic'][demographicindex] = participationAnswer;
            }
        }
        this.setState({ participationAnswer, questionData, surveyAnswer: data, assignObject: false })
    }
    /**this function is for only allow digits to enter otherwise it restrict to type a user for demographic question postalcode*/
    OnlyDigitsAllow = (event) => {
        var keyCode = event.which || event.keycode;
        // eslint-disable-next-line
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || keyCode == 32 || (keyCode >= 97 && keyCode <= 122)) {
        } else {
            event.preventDefault();
        }
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }
    /**this function is for api call for postalcode data after 3 digit type */
    getPostalCode = async (event, ui) => {
        if (this.postalCodeRequest) {
            clearTimeout(this.postalCodeRequest)
        }
        this.ui_id = (ui.id) ? ui.id : '';
        let sessionRedirect = this.state.sessionRedirect;
        this.postalcodedata = this.state.locationData;
        let participationAnswer = {};
        this.setState({ participationAnswer, errormessage: "" })
        participationAnswer = Object.assign({}, this.state.participationAnswer);
        let questionData = Object.assign({}, this.state.questiondata);
        let userLocationId = '';
        let userpostalcode = '';
        const value = (event.target !== undefined) ? event.target.value : event;
        const valLength = value.length;
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let userId = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
            userId = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        var self = this;
        if (valLength > 1 && event.target !== undefined) {
            this.setState({ postalloading: true })
            this.postalCodeRequest = setTimeout(async () => {
                await axios.get(API_URL + "/common/getPostalcodedata/" + LANGUAGE_CODE + "/" + value + '?uId=' + userId, { headers: headers })
                    .then((response) => {
                        this.postalcodedata = response.data.success.postalcodedata;
                        this.postalcodedata = JSON.parse(JSON.stringify(this.postalcodedata))
                        self.setState({ postalloading: false, locationData: this.postalcodedata })
                    })
                    .catch((error) => {
                        if (error) {
                            if (error.response) {
                                if (error.response.data) {
                                    let msg = error.response.data['error']
                                    if (msg === 'Expired token' || msg === 'Invalid token') {
                                        localStorage.clear();
                                        sessionRedirect = true;
                                    }
                                }
                            }
                        }
                        self.setState({ postalloading: false, sessionRedirect })
                    })
            }, 400);
        }
        if (this.postalcodedata.length > 0) {
            this.postalcodedata = this.postalcodedata.map((locationDataNew, index) => {
                this.postalcodedata[index].postalcode_city = locationDataNew.postalcode_city.toString();
                return this.postalcodedata[index];
            })
            if (this.ui_id) {
                for (let i = 0; i < this.postalcodedata.length; i++) {
                    if (this.postalcodedata[i].id === this.ui_id) {
                        userLocationId = this.postalcodedata[i].id;
                        userpostalcode = this.postalcodedata[i].postalcode_city;
                    }
                }
            }
            else {
                for (let i = 0; i < this.postalcodedata.length; i++) {
                    if (this.postalcodedata[i].postalcode_city.toString() === value.toString()) {
                        userLocationId = this.postalcodedata[i].id;
                        userpostalcode = this.postalcodedata[i].postalcode_city;
                    }
                }
            }
        }
        self.setState({ locationData: this.postalcodedata })
        var obj = {}
        if (this.state.demographicdata !== '') {
            obj['postalcode_id'] = userLocationId.toString();
            obj['postalcode'] = userpostalcode.toString();
            questionData['demographic'] = obj;
        }
        participationAnswer = {};
        participationAnswer['postalcode_id'] = value;
        participationAnswer['postalcode'] = value;
        var data = this.state.surveyAnswer;
        var demographickey;
        var demographicindex;
        if (data.demographic.length > 0) {
            for (let i = 0; i < data['demographic'].length; i++) {
                if (data['demographic'][i].hasOwnProperty(Object.keys(participationAnswer)[0]) === true) {
                    demographicindex = i;
                    demographickey = "true";
                }
            }
            if (demographickey === 'true') {
                data['demographic'][demographicindex] = participationAnswer;
            }
        }
        this.setState({ participationAnswer, questionData, surveyAnswer: data, assignObject: false })
    }
    /**Save the answer in object on change of selection of answer of each question */
    handleChange = (event) => {
        let participationAnswer = {};
        this.setState({ participationAnswer, errormessage: "" })
        let currentActiveQuestionData = this.state.questionPath[this.state.questionchild];
        let name = event.target.name;
        let id = event.target.id;
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        participationAnswer = Object.assign({}, this.state.participationAnswer);    //creating copy of object of surveydata
        let questionData = Object.assign({}, this.state.questiondata);
        if (currentActiveQuestionData['question_type_id'] === 1) {
            if(value.replace(/\s/g, '').length || value.length === 0) {
                participationAnswer[name] = value;
            }
        }
        else if (currentActiveQuestionData['question_type_id'] === 4) {
            if (event.target.name === 'free_answer_multiple') {
                if(value.replace(/\s/g, '').length || value.length === 0) {
                    participationAnswer[name] = value;
                }
            }
            else {
                participationAnswer[name] = value;
            }
        }
        else {
            participationAnswer[name] = value;
        }
        if (currentActiveQuestionData) {
            questionData["question_id"] = currentActiveQuestionData['question_id']
            questionData['question_type_id'] = currentActiveQuestionData['question_type_id']
        }
        var obj = {}
        let ans = []
        if (currentActiveQuestionData['question_type_id'] === 3) {
            if (id === value) {
                obj["option_id"] = parseInt(value)
                ans.push(obj)
                questionData['option'] = ans;
            }
            else {
                questionData['free_text_answer'] = value;
            }
        }
        if (currentActiveQuestionData['question_type_id'] === 1 || currentActiveQuestionData['question_type_id'] === 4) {
            if (value !== '' || value !== undefined) {
                if (currentActiveQuestionData['question_type_id'] === 4) {
                    if (event.target.name === 'free_answer_multiple') {
                        if (value) {
                            questionData['free_text_answer'] = value;
                        }
                    }
                }
                if (currentActiveQuestionData['question_type_id'] === 1) {
                    questionData['free_text_answer'] = value;
                }
            }
            if (currentActiveQuestionData['question_type_id'] === 1 && value === '') {
                questionData['free_text_answer'] = value;
            }
        }
        if (currentActiveQuestionData['question_type_id'] === 3) {
            participationAnswer = {}
            if (event.target.type === "text") {
                if(value.replace(/\s/g, '').length || value.length === 0) {
                    participationAnswer[name] = value;
                    if (currentActiveQuestionData && currentActiveQuestionData['question_id']) {
                        participationAnswer['question_id'] = currentActiveQuestionData['question_id'];
                    }
                }
            }
            else {
                participationAnswer[name] = value
            }
        }
        this.setState({ participationAnswer, questionData })
    }
    /** For rating with one element type question to rate answer by clicking button (if click 8 then till 8 all button are selected) */
    buttonRating = (event) => {
        let participationAnswer = {};
        this.setState({ participationAnswer, errormessage: "" })
        let currentActiveQuestionData = this.state.questionPath[this.state.questionchild]
        let questionData = Object.assign({}, this.state.questiondata);
        questionData["question_id"] = currentActiveQuestionData['question_id']
        questionData['question_type_id'] = currentActiveQuestionData['question_type_id']
        questionData['scale_type'] = currentActiveQuestionData['scale_type']
        var name = event.currentTarget.name;
        var value = event.currentTarget.value;
        participationAnswer = Object.assign({}, this.state.participationAnswer);    //creating copy of object of surveydata
        participationAnswer[name] = value;
        if (currentActiveQuestionData['question_type_id'] === 6) {
            questionData['free_text_answer'] = parseInt(value);
        }
        this.setState({ participationAnswer, questionData })
    }
    /**For handling range type question in rating with one element by draging range button range is displayed and set accordingly */
    handleRangle = (event, getValue, name) => {
        let participationAnswer = {};
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || /Mac/i.test(navigator.platform);
        if (!isMobile && event.type !== "mousemove") {
            this.setState({ participationAnswer })
        }
        let currentActiveQuestionData = this.state.questionPath[this.state.questionchild];
        let questionData = Object.assign({}, this.state.questiondata);
        questionData["question_id"] = currentActiveQuestionData['question_id']
        questionData['question_type_id'] = currentActiveQuestionData['question_type_id']
        questionData['scale_type'] = currentActiveQuestionData['scale_type']
        var value = parseInt(getValue);
        participationAnswer = Object.assign({}, this.state.participationAnswer);    //creating copy of object of surveydata
        participationAnswer[name] = parseInt(value);
        if (currentActiveQuestionData['question_type_id'] === 6) {
            questionData['free_text_answer'] = value;
        }
        this.setState({ participationAnswer, questionData })
    }
    /** Dummy state change for radio button valistion for single with free text question */
    dummyStateChange = () => {
        this.setState({ dummyFlag: !this.state.dummyFlag })
    }
    /** for school grade scale rating type question in ranking with one element only selected button is display as selected*/
    schoolGradeRatingHandle = (event) => {
        let participationAnswer = {};
        this.setState({ participationAnswer, errormessage: "" })
        let currentActiveQuestionData = this.state.questionPath[this.state.questionchild];
        let questionData = Object.assign({}, this.state.questiondata);
        questionData["question_id"] = currentActiveQuestionData['question_id']
        questionData['question_type_id'] = currentActiveQuestionData['question_type_id']
        questionData['scale_type'] = currentActiveQuestionData['scale_type']
        var id = event.target.id
        var name = event.currentTarget.name;
        var value = event.currentTarget.value;
        if (currentActiveQuestionData['question_type_id'] === 6 && currentActiveQuestionData['scale_type'] === 5) {
            questionData['option'] = [];
        }
        participationAnswer = Object.assign({}, this.state.participationAnswer);    //creating copy of object of surveydata
        participationAnswer[name] = value;
        if (currentActiveQuestionData['question_type_id'] === 6) {
            if (currentActiveQuestionData['scale_type'] === 5) {
                let obj = {}
                obj["option_id"] = parseInt(id)
                questionData['option'][0] = obj
            }
            else {
                questionData['free_text_answer'] = value;
            }
        }
        this.setState({ participationAnswer, questionData })
    }
    /** for clear surveyParticipationQuestion in localstorage on click of close button */
    clearLocalstorageForQuestion = () => {
        if (this.surveyMainId) {
            localStorage.removeItem('surveyparticipationquestion_' + this.surveyMainId);
            if (!this.props.startSurvey) {
                localStorage.removeItem('surveydetail_' + this.surveyMainId);
                window.close();
            }
        }
    }
    /** For preview check answer is valid or not */
    prviewFinshButton = (event) =>{        
        var iserror = this.manageAnswerObject();
        let sessionRedirect = this.state.sessionRedirect;
        this.setState({ errormessage: iserror, sessionRedirect })
        if (iserror === "" || iserror === undefined) {
            this.setState({showSurveyPreviewEndPage:true,activeIndex:''})
        }
        else {
            this.scrollUpFunction(true);
        }
        event.preventDefault();
        this.getSurveyEndPageInfo();
    }
    labelOnClick_ForDataProtection = () => {
        document.getElementById(1).click();
        this.setState({dataProtection : !this.state.dataProtection})
    }
    /**call all the question component here based on active index */
    section = (currentindex, apidata, demographicdata) => {
        var activeIndex = currentindex;
        var nextattribute = this.state.demographicchild;
        var demographicattribute = demographicdata;
        var questionattribute = this.state.questionchild;
        var questiondata = apidata.surveyquestiondata;
        var demographicdropdown = this.state.demographicdropdown;
        var questionlistdata = '';
        var demographicattributedata = '';
        if (questiondata) {
            questionlistdata = this.state.questionPath[questionattribute];
        }
        if (demographicattribute) {
            demographicattributedata = demographicattribute[nextattribute];
        }
        var finalanswer = this.state.surveyAnswer;
        var questionanswer = this.state.participationAnswer
        var ansobj = "";
        if (this.state.activeIndex === 2) {
            if (questionlistdata) {
                // eslint-disable-next-line
                finalanswer['question'].map((questionid, index) => {
                    if (questionlistdata.question_id === questionid.question_id) {
                        ansobj = questionid
                        if (Object.keys(questionanswer).length === 0) {
                            questionanswer = questionid;
                        }
                    }
                })
            }
        }
        if (this.state.activeIndex === 3) {
            if (Object.keys(questionanswer).length !== 0) {
                var demographic = []
                if (this.state.assignObject) {
                    demographic = questionanswer;
                } else {
                    demographic.push(questionanswer)
                }
                questionanswer = demographic
            }
            else {
                if (Object.keys(finalanswer.demographic).length !== 0) {
                    questionanswer = finalanswer.demographic;
                }
            }
        }
        var errormessage = this.state.errormessage;
        let surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + this.surveyMainId));
        switch (activeIndex) {
            case 1:
                return < SurveyDataProtectionParticipantPage survey_id={this.surveyMainId} declaration_consent={this.props.surveydetail}  errormessage={errormessage} onChange={this.props.onChange} labelOnClick_ForDataProtection={this.labelOnClick_ForDataProtection} dataProtection={this.state.dataProtection}/>
            case 2:
                return < SurveyQuestionComponents errormessage={errormessage} questiondata={questionlistdata} finalanswer={ansobj} onChange={this.handleChange} onSelect={this.onIncrementCount} counter={this.state.participationAnswer} onClick={this.onDecrementCount} onButtonRating={this.buttonRating} onLoad={this.handleRangle} onSchoolGrade={this.schoolGradeRatingHandle} questionanswer={questionanswer} lastIndex={this.state.lastIndex} continue={this.continue} finish={this.finish} is_free_text_checked={this.is_FreeTextBoxChecked} backButtonClick={this.state.backButtonClick} loading={this.state.loading} dummyStateChange={this.dummyStateChange} demographyQuestionDisplay={this.state.demographyQuestionDisplay} surveydetail={surveydetail}/>
            case 3:
                return < SurveyDemographicAttribute errormessage={errormessage} nextattribute={demographicattributedata} demographicdropdown={demographicdropdown} onChange={this.demographicChange} counter={this.state.participationAnswer} questionanswer={questionanswer} onSelect={this.getPostalCode} onKeyPress={this.OnlyDigitsAllow} postalloading={this.state.postalloading} continue={this.continue} finish={this.finish} locationData={this.state.locationData} backButtonClick={this.state.backButtonClick} lastIndex = {(this.state.demographicchild === this.state.demographiclength - 1) ? 1 : 0} data={apidata}/>
            default:
                if(!this.state.showSurveyPreviewEndPage && this.props.is_premium_user_survey !== 1)
                return < ParticipationEndingPage surveyId={this.surveyMainId} />
        }
    }
    render() {
        let questionData = this.state.questionData
        let demographicdata = this.state.demographicdata;
        let currentindex = this.state.activeIndex;
        let apidata = this.props.questionData;
        var length = apidata.surveyquestiondata.length;
        var demographiclength = demographicdata.length;
        var totalquestionlength = length + demographiclength
        let slug = this.props.slug;
        let displayTotalLength;
        let displayDemographyquestion = this.state.demographyQuestionDisplay;
        let displayCurrentQuestion;
        let progresswidth = "";
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let endpageData = this.state.endpageData
        if (this.state.activeIndex === 3 && demographiclength) {
            progresswidth = (((length + this.state.demographicchild) / totalquestionlength) * 100)
        }
        else {
            if (this.state.currentActiveQuestionData) {
                progresswidth = (((this.state.currentActiveQuestionData.question_order_index - 1) / totalquestionlength) * 100);
            }
        }
        progresswidth = Math.round(progresswidth);
        const element = this.section(currentindex, apidata, demographicdata)
        if (!this.surveyMainId) {
            this.surveyMainId = this.props.surveyid;
        }
        this.surveyMainId = this.props.surveyid;
        let surveydetail;
        let survey_logo;
        let survey_color;
        let englishbuttonlabels;
        if (this.surveyMainId) {
            surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + this.surveyMainId));
            survey_logo = surveydetail.survey_logo
            survey_color = surveydetail.survey_color
            englishbuttonlabels = surveydetail?.englishbuttonlabels == "1" ? "EN" : "DE"
        }
        /*- Indexing For display question number in external and internal survey participation Ex. 5 von 10 -*/
        if (displayDemographyquestion) {
            displayTotalLength = totalquestionlength;
            if (this.state.activeIndex === 3) {
                displayCurrentQuestion = length + parseInt(this.state.demographicchild) + 1;
            }
            else {
                displayCurrentQuestion = parseInt(this.state.currentActiveQuestionData.question_order_index);
            }
        }
        else {
            displayTotalLength = length;
            displayCurrentQuestion = parseInt(this.state.currentActiveQuestionData.question_order_index);
        }
        /*- END Indexing For display question number in external and internal survey participation Ex. 5 von 10 -*/
        return (
            <main>
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {(this.state.redirectPagenotfound === true) ?
                    <Redirect to="/404"></Redirect>
                    : null
                }
                {(this.state.redirecttosurvey === 'true') ?
                    <Redirect to={"/" + slug}></Redirect>
                    : null
                }
                {(this.state.redirectMyprofile === 'true') ?
                    <Redirect to="/mein-bereich"></Redirect>
                    : null
                }
                {(this.state.sessionRedirect === true) ?
                    <Redirect to="/anmelden"></Redirect>
                    : null
                }
                <div className="d-block modal-wizard-question modal__wizard" id="example-singal-selection-question">
                    <div className="modal-dialog modal-width" role="document">
                        <div className="modal-content end-survey-top-logo live-modal-content">
                            <div className="container-fluid px-0">
                                {((this.state.activeIndex !== 4 ) && (!this.state.showSurveyPreviewEndPage))
                                    ?
                                    <div className="live-modal-content modal-header border-bottom-0 bg-white pb-0 px-0">
                                        <div className="container-fluid px-0" style={{"height": "69px"}}>
                                            <div className="row justify-content-center">
                                                <div className="col-12">
                                                    {(this.props.previewSurvey) ?
                                                        <div className="question-line question-line row">
                                                            {surveydetail.account_type_category_id == 4 || surveydetail.account_type_category_id == 2?
                                                            surveydetail.isUserHaveActivePlan == 1?
                                                            survey_logo ?
                                                            <a href={BASEURL} className="navbar-brand logo-col logo-padding" >
                                                                <img className="light-logo custom-logo-size" src={survey_logo} alt="empirio-logo" />
                                                            </a>
                                                            : <div className='logo-col'></div>
                                                            : <a href={BASEURL} className="navbar-brand logo-col logo-padding" >
                                                                <img className="light-logo custom-logo-size" src={surveydetail.logo_image} alt="empirio-logo" />
                                                            </a>
                                                            : <a href={BASEURL} className="navbar-brand logo-col logo-padding" >
                                                                <img className="light-logo custom-logo-size" src={surveydetail.logo_image} alt="empirio-logo" />
                                                            </a>
                                                            }
                                                            <span className='logo-col1 text-center'>
                                                            <h2 className="text-dark create-survey-link text-center">{SURVEY_PARTICIPATION[LANGUAGE_CODE]['live_demo']}</h2>
                                                            </span>
                                                            <Link to="/umfragen" className="text-dark close position-relative logo-col2 text-right" onClick={this.clearLocalstorageForQuestion}><i className="ti-close custom-opacity d-block font-weight-bold text-dark"></i></Link>
                                                            <div className="question-blue-line">{(this.state.activeIndex === 1 || this.state.activeIndex === 4) ? <span style={{ "width": "0%", "backgroundColor": survey_color? survey_color : "#00b3f4"}}></span> : <span style={{ "width": progresswidth + "%", "backgroundColor": survey_color? survey_color : "#00b3f4", "top" : "-2px" }}></span>}</div>
                                                        </div>
                                                        : null}
                                                    {(this.state.activeIndex === 1 && !this.props.previewSurvey) ?
                                                        <div className="row" style={{"height": "66px"}}>
                                                        {surveydetail.account_type_category_id == 4 || surveydetail.account_type_category_id == 2?
                                                        surveydetail.isUserHaveActivePlan == 1?
                                                        survey_logo ?
                                                        <a href={BASEURL} className="navbar-brand participation-header-logo col-6" >
                                                            <img className="light-logo custom-logo-size" src={survey_logo} alt="empirio-logo"/>
                                                        </a>
                                                        :  <div className='col-6'></div>
                                                        : <a href={BASEURL} className="navbar-brand participation-header-logo col-6" >
                                                                <img className="light-logo custom-logo-size" src={surveydetail.logo_image} alt="empirio-logo" />
                                                            </a>
                                                        : <a href={BASEURL} className="navbar-brand participation-header-logo col-6" >
                                                                <img className="light-logo custom-logo-size" src={surveydetail.logo_image} alt="empirio-logo" />
                                                          </a>
                                                        }
                                                        <Link to={(!this.props.startSurvey && !this.props.previewSurvey) ? "/" : "/umfragen/"} className="col-6 close position-relative  text-right participation-header-close" onClick={this.clearLocalstorageForQuestion}><i className="ti-close custom-opacity d-block font-weight-bold"></i></Link>
                                                        <div className="question-blue-line"><span style={{ "width": "0%", "backgroundColor": survey_color? survey_color : "#00b3f4" }}></span></div>
                                                        </div>
                                                        : null}
                                                    {((this.state.activeIndex === 3 || this.state.activeIndex === 2) && !this.props.previewSurvey) ?
                                                        <div className="row" style={{"height": "66px"}}>
                                                        {surveydetail.account_type_category_id == 4 || surveydetail.account_type_category_id == 2?
                                                         surveydetail.isUserHaveActivePlan == 1?
                                                         survey_logo ?
                                                         <a href={BASEURL} className="navbar-brand participation-header-logo col-6" >
                                                             <img className="light-logo custom-logo-size" src={survey_logo} alt="empirio-logo" />
                                                         </a>
                                                         :  <div className='col-6'></div>
                                                         : <a href={BASEURL} className="navbar-brand participation-header-logo col-6" >
                                                         <img className="light-logo custom-logo-size" src={surveydetail.logo_image} alt="empirio-logo" />
                                                         </a>
                                                         : <a href={BASEURL} className="navbar-brand participation-header-logo col-6" >
                                                             <img className="light-logo custom-logo-size" src={surveydetail.logo_image} alt="empirio-logo" />
                                                         </a>
                                                         }
                                                         <Link to={(!this.props.startSurvey && !this.props.previewSurvey) ? "/" : "/umfragen/"} className="col-6 close position-relative  text-right participation-header-close" onClick={this.clearLocalstorageForQuestion}><i className="ti-close custom-opacity d-block font-weight-bold"></i></Link>
                                                         <div className="question-blue-line"><span style={{ "width": progresswidth + "%", "backgroundColor": survey_color? survey_color : "#00b3f4"}}></span></div>
                                                     </div>
                                                        : null
                                                    }
                                                    {((this.state.activeIndex === 4) && !this.props.previewSurvey) ?
                                                        <div className="row" style={{"height": "66px"}}>
                                                        {surveydetail.account_type_category_id == 4 || surveydetail.account_type_category_id == 2?
                                                        surveydetail.isUserHaveActivePlan == 1?
                                                        survey_logo ?
                                                        <a href={BASEURL} className="navbar-brand participation-header-logo col-6" >
                                                            <img className="light-logo custom-logo-size" src={survey_logo} alt="empirio-logo" />
                                                        </a>
                                                        : <div className='col-6'></div>
                                                        : <a href={BASEURL} className="navbar-brand participation-header-logo col-6" >
                                                            <img className="light-logo custom-logo-size" src={surveydetail.logo_image} alt="empirio-logo" />
                                                        </a>
                                                        :<a href={BASEURL} className="navbar-brand participation-header-logo col-6" >
                                                            <img className="light-logo custom-logo-size" src={surveydetail.logo_image} alt="empirio-logo" />
                                                        </a>
                                                        }
                                                        <Link to={(!this.props.startSurvey && !this.props.previewSurvey) ? "/" : "/umfragen/"} className="col-6 close position-relative  text-right participation-header-close" onClick={this.clearLocalstorageForQuestion}><i className="ti-close custom-opacity d-block font-weight-bold"></i></Link>
                                                        <div className="question-blue-line"><span style={{ "width": "0%", "backgroundColor": survey_color? survey_color : "#00b3f4"  }}></span></div>
                                                    </div>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        {
                                        !this.state.showSurveyPreviewEndPage && this.props.is_premium_user_survey == 1 ?
                                            <SurveyEndingPageNsu data={surveydetail.survey_header_image} survey_id={surveydetail.id} showSurveyPreviewEndPage={this.state.showSurveyPreviewEndPage} clearLocalstorageForQuestion={this.clearLocalstorageForQuestion} isNormalImage={this.props.isNormalImage} previewSurvey = {this.props.previewSurvey} planExpired={this.state.planExpired} user_id = {surveydetail.user_id} endpageData={endpageData}/>
                                        : !this.state.showSurveyPreviewEndPage &&
                                        <>
                                        <div className="modal_header border-bottom-0">
                                            <div className="container container-large">
                                                <div className="row align-items-center">
                                                    <div className="col-8 col-md-9 text-left px-0 px-lg-2">
                                                    {surveydetail.account_type_category_id == 4 || surveydetail.account_type_category_id == 2?
                                                    surveydetail.isUserHaveActivePlan == 1?
                                                    survey_logo ?
                                                    <div className="modal-logo">
                                                        <a href={BASEURL} className="navbar-brand" style={{"padding" : "0.9rem 0.9rem 0.9rem 2.5rem", "float" : "left"}}>
                                                            <img className="light-logo custom-logo-size" src={survey_logo} alt="empirio-logo"  />
                                                        </a>
                                                    </div>
                                                    : null
                                                    : <a href={BASEURL} className="navbar-brand" style={{"padding" : "0.9rem 0.9rem 0.9rem 2.5rem", "float" : "left"}}>
                                                        <img className="light-logo custom-logo-size" src={surveydetail.logo_image1} alt="empirio-logo" />
                                                    </a>
                                                    : null
                                                    }
                                                    </div>
                                                    <div className="col-4 col-md-3 text-right closetab pb-2">
                                                        <Link className="text-white close font-weight-bold" to={(!this.props.startSurvey && !this.props.previewSurvey) ? "/" : "/umfragen/"}> <i className="ti-close ti-close-participation font-weight-bold"></i> </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <section className={!(this.props.userid && this.props.startSurvey) && this.state.totalearnkarmapoints ? "banner_text d-flex align-items-center survey-detal-bg-img survey-ending-page survey-participate-page" : "banner_text d-flex align-items-center survey-detal-bg-img survey-ending-page"}>
                                            <div className="container-fluid px-0 h-350 background-image-css" style={{ "backgroundImage": "url(" + surveydetail.survey_header_image + ")" }}>
                                                <div className="page-title survey-end-background-content">
                                                    <div className="round-check text-center text-white">
                                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                                            <circle className="path-new circle-new" fill="none" stroke="#FFFFFF" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="60" />
                                                            <polyline className="path-new check-new" fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                                        </svg>
                                                        <h1 className="text-white px-sm-0 px-5 font-weight-bold text-break">{SURVEY_PARTICIPATION[englishbuttonlabels]['endingpage']}</h1>
                                                        <h3 className="text-white px-sm-0 px-5 font-weight-normal text-break">
                                                            +<CountUp delay={2} end={this.state.totalearnkarmapoints} />  {KARMA_POINT[LANGUAGE_CODE]['earnkarmapoint']}
                                                        </h3>
                                                        {!(this.props.userid && this.props.startSurvey) && this.state.totalearnkarmapoints ?
                                                            endpageData.length === undefined?
                                                            <div className="survey-participate-detail">
                                                                <div className="survey-title-box">
                                                                    <h2 className="text-dark font-weight-bold mb-4">
                                                                        {endpageData?.default_card_heading}
                                                                    </h2>
                                                                </div>
                                                                <div dangerouslySetInnerHTML={{ __html: endpageData?.default_card_text }}></div>
                                                                <div className="survey-participate-btn">
                                                                    <a target="_blank" href={endpageData?.default_card_button_link} className='btn btn-clr text-white w-100'>{endpageData?.default_card_button_label}</a>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="survey-participate-detail">
                                                            <div className="survey-title-box">
                                                                <h2 className="text-dark font-weight-bold mb-4">
                                                                  { SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_title']}
                                                                </h2>
                                                            </div>
                                                            <div class="text-gray text-survey d-flex text-left">
                                                                <span class="fa fa-check survey-result-unlock-popup"></span>
                                                                <div class="mb-0">
                                                                    {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_first_subtitle']}
                                                                </div>
                                                            </div>
                                                            <div class="text-gray text-survey d-flex text-left">
                                                                <span class="fa fa-check survey-result-unlock-popup"></span>
                                                                <div class="mb-0">
                                                                    {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_second_subtitle']}
                                                                </div>
                                                            </div>
                                                            <div class="text-gray text-survey d-flex text-left">
                                                                <span class="fa fa-check survey-result-unlock-popup"></span>
                                                                <div class="mb-0">
                                                                    {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_third_subtitle']}
                                                                </div>
                                                            </div>
                                                            <div class="text-gray text-survey d-flex text-left">
                                                                <span class="fa fa-check survey-result-unlock-popup"></span>
                                                                <div class="mb-0">
                                                                    {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_fourth_subtitle']}
                                                                </div>
                                                            </div>
                                                            <div className="survey-participate-btn">
                                                                <Link to={{ pathname: "/registrieren", state: { earnKarmaPoint: this.state.totalearnkarmapoints, uType: this.state.annonymousUserId } }} className="btn btn-clr text-white w-100" >
                                                                    {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_link_text']}
                                                                </Link>
                                                            </div>
                                                            </div>
                                                        : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        </>
                                        }
                                    </div>
                                }
                                <div className={(this.state.activeIndex !== 4) && (!this.state.showSurveyPreviewEndPage) ? "row survey-participation-modal wizard wizard__container justify-content-center align-items-center no-gutters" : (this.props.is_premium_user_survey !== 1) && (!this.state.showSurveyPreviewEndPage) ? "row wizard survey-participation-modal wizard__container justify-content-center align-items-center no-gutters pb-0 participation-end-content custom-height-endpage" : null }>
                                    <form className="tab-content text-left max-width-content" name="mainform">
                                        <div className="content clearfix">
                                            {element}
                                        </div>
                                        {(this.state.activeIndex !== 4) && (!this.state.showSurveyPreviewEndPage) ?
                                            <div className="wizard">
                                                <div className="actions clearfix participation">
                                                    <ul role="menu" aria-label="Pagination" className="align-middle">
                                                        <li className={(this.state.activeIndex === 4) ? "disabled" : "default"} aria-disabled="false" area-hidden="false">
                                                            {// eslint-disable-next-line
                                                            }<a href="#" role="menuitem" onClick={this.back} className="back_button participation-buttons" >{SURVEY_PARTICIPATION[englishbuttonlabels]['back']}</a>
                                                        </li>
                                                        <li aria-hidden="false" aria-disabled="false" className={(((this.state.lastIndex === 1 || (length && this.state.questionPath && this.state.questionPath[this.state.questionchild] && this.state.questionPath[this.state.questionchild].question_order_index === length && this.activeIndex === 2)) && !this.state.demographyQuestionDisplay) || (this.state.demographicchild === demographiclength-1 && this.state.demographyQuestionDisplay && this.state.activeIndex === 3)) ? "disabled" : (this.state.activeIndex === 4) ? "disabled" : "default"}>
                                                            {// eslint-disable-next-line
                                                            } <a href="#" onClick={this.continue} role="menuitem" className={"participation-buttons" + (!this.isContinueButtonDisplay ? " button-opacity-change": "")}  style={{"backgroundColor": survey_color? survey_color : "#00b3f4" }}>{SURVEY_PARTICIPATION[englishbuttonlabels]['continue']}</a>
                                                        </li>
                                                        {((((this.state.lastIndex === 1 || (length && this.state.questionPath && this.state.questionPath[this.state.questionchild] && this.state.questionPath[this.state.questionchild].question_order_index === length && this.activeIndex === 2)) && !this.state.demographyQuestionDisplay && !this.props.previewSurvey) || (this.state.demographicchild === demographiclength - 1 && this.state.demographyQuestionDisplay && this.state.activeIndex === 3)) && !this.props.previewSurvey) ?
                                                            <li aria-hidden="false" aria-disabled="false">
                                                                {// eslint-disable-next-line
                                                                }<a href='#' role="menuitem" onClick={this.finish} className={"btn-finish part-finish participation-buttons" + (!this.isContinueButtonDisplay ? " button-opacity-change" : "")}>{SURVEY_PARTICIPATION[englishbuttonlabels]['finish']}</a>
                                                            </li>
                                                            :
                                                            null}
                                                        {(this.props.previewSurvey && (((this.state.lastIndex === 1 || (length && this.state.questionPath && this.state.questionPath[this.state.questionchild] && this.state.questionPath[this.state.questionchild].question_order_index === length)) && this.state.activeIndex === 2 && demographiclength === 0) || (this.state.demographicchild === demographiclength - 1 && this.state.demographyQuestionDisplay && this.state.activeIndex === 3) || this.state.activeIndex === 4)) ?
                                                            <li aria-hidden="false" aria-disabled="false">
                                                                {// eslint-disable-next-line
                                                                }<a href='#' onClick={(e)=>this.prviewFinshButton(e)} className={"btn-finish part-finish participation-buttons" + (!this.isContinueButtonDisplay ? " button-opacity-change": "")}>{SURVEY_PARTICIPATION[englishbuttonlabels]['finish'] || SURVEY_PARTICIPATION[englishbuttonlabels]['close_survey']}</a>
                                                            </li>
                                                            : null}
                                                        {((this.state.activeIndex === 4) && !this.props.previewSurvey) ?
                                                            <li aria-hidden="false" aria-disabled="false">
                                                                {// eslint-disable-next-line
                                                                }<a href='#' role="menuitem" onClick={this.finishsurvey}>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['close_survey']}</a>
                                                            </li>
                                                            : null}
                                                    </ul>
                                                </div>
                                            </div>
                                        : null}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {((this.state.showSurveyPreviewEndPage)  && (this.props.is_premium_user_survey == 1)) &&
                    <div className="d-block modal-wizard-question modal__wizard" id="example-singal-selection-question">
                        <div className="modal-dialog modal-width" role="document">
                            <div className="modal-content end-survey-top-logo live-modal-content">
                                <div className="container-fluid px-0">
                                    <SurveyEndingPageNsu
                                        data={surveydetail.survey_header_image}
                                        survey_id={surveydetail.id}
                                        showSurveyPreviewEndPage={this.state.showSurveyPreviewEndPage}
                                        clearLocalstorageForQuestion={this.clearLocalstorageForQuestion}
                                        isNormalImage={this.props.isNormalImage}
                                        previewSurvey = {this.props.previewSurvey}
                                        planExpired={this.state.planExpired}
                                        user_id = {surveydetail.user_id} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {((this.state.showSurveyPreviewEndPage)  && !(this.props.is_premium_user_survey == 1)) &&
                    <div className="d-block modal-wizard-question modal__wizard" id="example-singal-selection-question">
                        <div className="modal-dialog modal-width" role="document">
                            <div className="modal-content end-survey-top-logo live-modal-content">
                                <div className="container-fluid px-0">
                                    <SurveyPreviewEndPage
                                        clearLocalstorageForQuestion = {this.clearLocalstorageForQuestion}
                                        backgroundImageUrl = {surveydetail.survey_header_image}
                                        totalEarnKarmaPoints = {this.state.totalearnkarmapoints}
                                        surveyId = {this.surveyMainId}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </main>
        )
    }
}
export default SurveyOtherParticipationComponents;
