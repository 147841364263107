import React from "react";
import {API_URL, LANGUAGE_CODE,MONTHLY_AMOUNT, ANNUAL_AMOUNT,IMAGE_BASEURL,BASEURL} from '../../constants/DotEnv';
import axios from 'axios';
import {PAYMENT_CONSTANTS} from "../../constants/FrontEndConstant";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
class Paypal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paid: false,
      error: null,
      message: ""
    };
    this.paypalRef = React.createRef();
  }

  handlePaypal = () => {
    // this route should only be avaleable from a popup
         let monthly_plan = MONTHLY_AMOUNT;
         let annual_plan = ANNUAL_AMOUNT;
         
         if (monthly_plan == null) {
           monthly_plan = localStorage.getItem('monthly_plan')
         }
         if (annual_plan == null) {
           annual_plan = localStorage.getItem('quarterly_plan')
         }

         const plandays = this.props.total === monthly_plan ? 30 : 90;
         var amount =  this.props.total;
         if(document.getElementById("paypal-render")!=null) {
            document.getElementById("paypal-render").innerHTML = '';
         }
         window.paypal
           .Buttons({
             style:{
               color:  'silver',
               label:  'paypal'
             },
             createOrder: (data, actions) => {
               return actions.order.create({
                 intent: "CAPTURE",
                 purchase_units: [
                   {
                     description: "empirio Plus " + "(" + plandays + " Tage)",
                     amount: {
                       currency_code: "EUR",
                       value: amount.replace(",",'.'),
                     },
                   },
                 ],
               });
             },
             onApprove: async (data, actions) => {
               const order = await actions.order.capture();
               // this.setState({ paid: true });
               this.setState({message: order});
             // we use the `opener` object that lives on a parent window
             // this object only exists if the current window has a child.
             // window.opener.onSuccess(order)
             let storeitem = JSON.parse(localStorage.getItem('userdata'));
             var billing_info = JSON.parse(localStorage.getItem('billing_info'));
             let apitoken = storeitem.token;
             let userId = storeitem.id;
             const headers = {
               "Content-Type": "application/json",
               "Authorization": "Bearer " + apitoken
             }
             const planType = this.props.total === monthly_plan ? 0 : this.props.total === annual_plan ? 1 : 0;
             const tranId = order.id;
             let data1 = order.create_time;
             let finalDate = data1.replace('T',' ').replace('Z','');
             await axios.post(API_URL + '/payment/paypal/' + userId+ '?uId=' + userId ,
             { first_name:this.props.first_name, last_name:this.props.last_name, price:amount.replace(",",'.'), postalcode_id:this.props.postalcode_id, plan_type: planType, start_date : finalDate, transaction_id : order.id, organization: storeitem.organization, telephone: storeitem.telephone, street: billing_info.street, house_number: billing_info.house_number  },
             { headers: headers })
             .then((res)=>{ localStorage.setItem('payment_status',1);
             localStorage.setItem('plan_type', planType);
             localStorage.setItem('transaction_id', order.id);
             let redirectURL = localStorage.getItem('redirectURL')!=null ? localStorage.getItem('redirectURL'):BASEURL + "mein-bereich";
             window.location = redirectURL;
           })
               .catch(err => console.log(err))
             },
             onError: (err) => {
               this.setState({ error: err });
               localStorage.removeItem('plan_type');
             },
           })
           .render(this.paypalRef.current);
  }

  createOrder (data, actions)  {
    let amount = this.props.total;
    let monthly_plan = MONTHLY_AMOUNT;
    
    if (monthly_plan == null) {
      monthly_plan = localStorage.getItem('monthly_plan')
    }

    const plandays = amount === monthly_plan ? 30 : 90;
    localStorage.setItem('method', 'PayPal');
    const billing_info = JSON.parse(localStorage.getItem('billing_info'))
    if (billing_info['payment_method'] !== 'PayPal') {
      billing_info['payment_method'] = 'PayPal'
      localStorage.setItem('billing_info',JSON.stringify(billing_info));
    }
    return actions.order.create({
      intent: "CAPTURE",
      purchase_units: [
        {
          description: "empirio Plus " + "(" + plandays + " Tage)",
          amount: {
            currency_code: "EUR",
            value: amount.replace(",",'.'),
          },
        },
      ],
    });
  }
  async onApprove(data, actions) {
    const order = await actions.order.capture();
    let amount =  this.props.total;
    // this.setState({ paid: true });
    this.setState({message: order});
    // we use the `opener` object that lives on a parent window
    // this object only exists if the current window has a child.
    // window.opener.onSuccess(order)
    let storeitem = JSON.parse(localStorage.getItem('userdata'));
    var billing_info = JSON.parse(localStorage.getItem('billing_info'));
    let apitoken = storeitem.token;
    let userId = storeitem.id;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + apitoken
    }
    let monthly_plan = MONTHLY_AMOUNT;
    let annual_plan = ANNUAL_AMOUNT;
    
    if (monthly_plan == null) {
      monthly_plan = localStorage.getItem('monthly_plan')
    }
    if (annual_plan == null) {
      annual_plan = localStorage.getItem('quarterly_plan')
    }

    const planType = amount === monthly_plan ? 0 : amount === annual_plan ? 1 : 0;
    const tranId = order.id;
    let data1 = order.create_time;
    let finalDate = data1.replace('T',' ').replace('Z','');
    const actualPlan = (amount === monthly_plan && planType === 0 ? 0 : amount === annual_plan && planType === 1 ? 1 : null) 
    if (actualPlan !== null){
        await axios.post(API_URL + '/payment/paypal/' + userId+ '?uId=' + userId ,
        { first_name:this.props.first_name, last_name:this.props.last_name, price:amount.replace(",",'.'), postalcode_id:this.props.postalcode_id, plan_type: planType, start_date : finalDate, transaction_id : order.id, organization: storeitem.organization, telephone: storeitem.telephone, street: billing_info.street, house_number: billing_info.house_number  },
        { headers: headers })
        .then((res)=>{ localStorage.setItem('payment_status',1);
        storeitem['plan_type'] = planType.toString();
        localStorage.setItem("userdata",JSON.stringify(storeitem));
        localStorage.setItem('plan_type', planType);
        localStorage.setItem('transaction_id', order.id);
        let redirectURL = localStorage.getItem('redirectURL')!=null ? localStorage.getItem('redirectURL'):BASEURL + "mein-bereich";
        window.location = redirectURL;
        }).catch(err => console.log(err));
    }
  }
  render() {
    // If the payment has been made
    if (this.state.paid) {
      return <div> {PAYMENT_CONSTANTS[LANGUAGE_CODE]["payment_suceess_msg"]}</div>;
    }
    // If any error occurs
    if (this.state.error) {
      return ( <div className="text-danger"> {PAYMENT_CONSTANTS[LANGUAGE_CODE]["payment_error_msg"]}</div> );
    }
    return (
      <PayPalScriptProvider options={{'client-id': process.env.REACT_APP_PAYPAL_KEY,currency: "EUR", intent: "capture",components: "buttons",vault: "true"}}>
      <PayPalButtons style={{  color:  'silver', label:  'paypal',tagline: false,layout: 'horizontal'}}
      createOrder={(data, actions) => this.createOrder(data,actions)}
      onApprove={(data, actions) => this.onApprove(data,actions)}/>
      </PayPalScriptProvider>   
    );
  }
}

export default Paypal;
