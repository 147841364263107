import React from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import { EMPIRIOWISSEN_CONSTANT, META_TAGS_CONSTANT, URL_CONSTANT, META_TITLE_CONSTANT, BUBBLE_LOADING, META_FACEBOOK, BREADCRUMB_SCHEMA } from '../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, META_TITLE_APPEND, IMAGE_BASEURL } from '../../constants/DotEnv';
import EmpiriowissenHeader from '../subPageHeader/SubPageHeader';
class Empiriowissen extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
            loading: 'false',
            title: '',
            sub_title: '',
            dataLength: '',
            firstchapter_slug: '',
            description: '',
            short_description: ''
        }
    }
    componentWillUnmount() {
        setTimeout(() => {
            window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
            window.removeSchema();
        }, 100)
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.empiriowissenContent();
        setTimeout(() => {
            var schema2 = window.positionCreate(BREADCRUMB_SCHEMA['empiriowissen']['schema2']['position'], BREADCRUMB_SCHEMA['empiriowissen']['schema2']['url'], BREADCRUMB_SCHEMA['empiriowissen']['schema2']['name']);
            var schemaObject = window.schemaObj(BREADCRUMB_SCHEMA['schema1'], schema2, BREADCRUMB_SCHEMA['empiriowissen']['schema3']);
            window.setSchema(schemaObject);
        }, 100)
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
        if (count === -1) {
            document.querySelector('.navbar-collapse').classList.remove('show');
            document.querySelector('header').classList.remove("header_collapse");
            document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
        }
    }
    empiriowissenContent() {
        this.setState({ loading: 'true' })
        var headers = {
            "Content-Type": "application/json"
        }
        let data = '';
        let dataLength = '';
        let title = '';
        let sub_title = '';
        let firstchapter_slug = '';
        let self = this;
        let description = '';
        let short_description = '';
        let meta_title = "";
        let meta_description = '';
        let meta_keywords = "";
        let og_title = META_TITLE_CONSTANT[LANGUAGE_CODE]['empiriowissen'] + META_TITLE_APPEND;
        let og_description = META_FACEBOOK['og_description'];
        let og_image = META_FACEBOOK['og_image'];
        axios.get(API_URL + "/emperiowissen/1/" + LANGUAGE_CODE, { headers: headers })
            .then((response) => {
                data = response.data['success']['empiriowissen'];
                title = response.data['success']['title'];
                sub_title = response.data['success']['sub_title'];
                firstchapter_slug = response.data['success']['firstchapter_slug'];
                short_description = response.data['success']['emperiowissen_shortdescription'];
                description = response.data['success']['empiriowissen_description'];
                dataLength = data.length;
                meta_title = META_TITLE_CONSTANT[LANGUAGE_CODE]['empiriowissen'] + META_TITLE_APPEND;
                meta_keywords = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords'];
                meta_description = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_description'];
                window.setOgTag(og_title, og_description, og_image, window.location.href);
                window.setMeta({ "description": meta_description, "keywords": meta_keywords });
                window.setDocumentTitle(meta_title)
                self.setState({ data, loading: 'false', dataLength, title, sub_title, firstchapter_slug, description, short_description })
            })
            .catch((error) => {
                self.setState({ loading: 'false' })
            })
    }
    wissionDix = (data) => {
        let dataNew = data;
        let rows = [];
        let dataTotalLength = Object.keys(dataNew).length;
        
        for (var i = 0; i < dataTotalLength; i++) {
            rows.push(
                <li key={i}>
                    <div className={(window.innerWidth >= 768 && window.innerWidth <= 990) ? "article-link" : "article-link text-truncate"}>
                        <Link to={"/" + URL_CONSTANT['empiriowissen'] + "/" + dataNew[i].slug} className="text-dark font-2 chapter-style">{(window.innerWidth >= 768 && window.innerWidth <= 990) ? (dataNew[i].title.length > 15) ? dataNew[i]['title'].substring(0, 15).trim() + "..." : dataNew[i]['title'] : dataNew[i]['title']}</Link>
                    </div>
                </li>
            )
        } 
        return <div className="blog-listing"><div className="row no-gutters"><div className="col-md-12"><ul className="blog-listing-number">{rows}</ul></div></div></div>;
    }
    render() {
        let element = '';
        let slug = this.state.firstchapter_slug;
        let description = EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['empiriowisse_description'];
        let short_description = EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['empiriowissen_shortdescription'];
        if (this.state.dataLength > 0) {
            let data = this.state.data;
            
            element = data.map((data, index) => {
                let backImage = IMAGE_BASEURL + "img/100x100.jpg"
            if (data['category_image']) {
                backImage = data['category_image'];
            }
                return (
                <div className="col-md-6 col-lg-4 col-xl-4" key={index}>
                    <div className="blog-item">
                        <div className="blog-img position-relative h-220 background-image-css" style={{ "backgroundImage": "url(" + backImage + ")" }}>
                        </div>
                        <div className="blog-content">
                            <h6 className={(data['category_nme']) ? "text-dark font-16 custom-test-text" : "text-dark font-16 text-hyphens"}>{data['category_name']}</h6>
                        </div>
                        {this.wissionDix(data.empiriowissendata)}
                        <div className="d-flex justify-content-around">
                        </div>
                     </div>
                </div>
                )
            })
        }
        return (
            <main>
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {/* <!-- banner text ---> */}
                <EmpiriowissenHeader data={this.state.data} title={this.state.title} sub_title={this.state.sub_title} dataLength={this.state.dataLength} />
                {/* <!-- banner-text-end --> */}
                {/* <!-- empirical-research start --> */}
                <section className="empirical-research bg-gray">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12">
                                <h2 className="text-dark font-weight-bold">{short_description}</h2>
                                <p className="text-dark font-weight-normal font-18">{description}</p>
                                <div className="start-chapter text-center">
                                    <Link to={"/" + URL_CONSTANT['empiriowissen'] + "/" + slug} className="btn btn-clr text-uppercase text-white font-18" title={EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['start_chapter_button']}>{EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['start_chapter_button']}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- empirical-research end --> */}
                {/* <!-- themen section --> */}
                {(this.state.dataLength > 0 && this.state.loading === 'false') ?
                    <section className="themen-section empiriowissen-wrapper">
                        <div className="container container-large">
                            <div className="row text-center">
                                <div className="col-12">
                                    <h2 className="text-dark font-weight-bold">{EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['overview_title']}</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <div className="blog-lising">
                                         <div className="row">
                                              {element}
                                         </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row basics-zur-link">
                                <div className="col-12">
                                    <Link to="/lexikon" className="btn btn-clr text-uppercase text-white font-18" title={EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['overview_detail_button']}>{EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['overview_detail_button']}</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    : null
                }
                {/* <!-- themen section end --> */}
                {/* <!--  Online survey section end ---> */}
                <section className="start-now analysiere theme-main">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12">
                                <div className="understand-research text-center">
                                    <h2 className="text-white mb-0 font-weight-bold">{EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['research_footer_title']}</h2>
                                    <div className="start-chapter text-center">
                                        <Link to={"/" + URL_CONSTANT['empiriowissen'] + "/" + slug} className="btn btn-clr text-uppercase text-white font-18" title={EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['footer_start_chapter_button']}>{EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['footer_start_chapter_button']}</Link>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- col --> */}
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
export default Empiriowissen;
