import React from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import Recaptcha from "react-google-invisible-recaptcha";
import { REGISTRATION_ERROR_MESSAGE, REGISTRATION_TERMS_CONDITION, REGISTRATION_CONSTANT, META_TITLE_CONSTANT, META_TAGS_CONSTANT, BUBBLE_LOADING, META_FACEBOOK, BREADCRUMB_SCHEMA } from "../../constants/FrontEndConstant";
import { LANGUAGE_CODE, API_URL, MESSAGE_TIME, FRONT_USER, META_TITLE_APPEND, SITEKEY, RECAPTCHA_LANGUAGE } from '../../constants/DotEnv';
import Header from "../header/MainHeader";
const ListItem = ({ value }) => (<span> {value}<br /></span>);
class NsuRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            message: [],
            terms_condition: false,
            newsletter: false,
            count: '',
            loading: 'false',
            fieldErrorMessage: [],
            error: 0,
            usertype: 0,
            accountType: [],
            show: false,
            dark_logo: null,
            white_logo: null,
        }
        let meta_title = META_TITLE_CONSTANT[LANGUAGE_CODE]['registration'] + META_TITLE_APPEND;
        let meta_description = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_description'];
        let meta_keywords = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords'];
        let og_title = META_TITLE_CONSTANT[LANGUAGE_CODE]['registration'] + META_TITLE_APPEND;
        let og_description = META_FACEBOOK['og_description'];
        let og_image = META_FACEBOOK['og_image'];
        this.validPassword = true;
        setTimeout(() => {
            window.setMeta({ "description": meta_description, "keywords": meta_keywords })
            window.setOgTag(og_title, og_description, og_image, window.location.href)
            window.setDocumentTitle(meta_title)
        }, 200)
    }
    componentWillUnmount() {
        setTimeout(() => {
            window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
            window.removeSchema();
        }, 100)
    }
    componentDidMount() {
        var url = new URL(window.location);
        var params = new URLSearchParams(url.search);
        if (params.get('type') && params.get('type') >= 2) {
            this.setState({ usertype: params.get('type') })
        } else {
            this.setState({ usertype: 1 })
        }
        this.scrollUpFunction()
        this.getAccountTypes()
        setTimeout(() => {
            var schema2 = window.positionCreate(BREADCRUMB_SCHEMA['registration']['schema2']['position'], BREADCRUMB_SCHEMA['registration']['schema2']['url'], BREADCRUMB_SCHEMA['registration']['schema2']['name']);
            var schemaObject = window.schemaObj(BREADCRUMB_SCHEMA['schema1'], schema2, BREADCRUMB_SCHEMA['registration']['schema3']);
            window.setSchema(schemaObject);
        }, 100)
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
        if (count === -1) {
            document.querySelector('.navbar-collapse').classList.remove('show');
            document.querySelector('header').classList.remove("header_collapse");
            document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
        }
    }
    handleChange = (event) => {
        const name = event.target.name;
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        var index = this.state.fieldErrorMessage.indexOf(name);
        var indexRE = this.state.fieldErrorMessage.indexOf(name + "validation");
        var indexREPwd = this.state.fieldErrorMessage.indexOf(name + "LengthValidation");
        if (index > -1) {
            this.state.fieldErrorMessage.splice(index, 1);
        }
        if (indexRE > -1) {
            this.state.fieldErrorMessage.splice(indexRE, 1);
        }
        if (indexREPwd > -1) {
            this.state.fieldErrorMessage.splice(indexREPwd, 1);
        }
        if (name === "password") {
            if (this.validPassword) {
                value = value.replace(/\s/g, '');
                this.setState({ [name]: value });
            }
            else {
                this.validPassword = true;
            }
        }
        else if (name === "usertype") {
            var data = this.state.accountType.filter((item) => item.id == value)
            this.setState({ dark_logo: data[0].logo_image, white_logo: data[0].logo_image1, [name]: value })
            var account_type_category_id = data[0].account_type_category_id
            if (account_type_category_id == 3 || account_type_category_id == 4) {
                this.setState({ show: true })
            } else {
                this.setState({ show: false })
            }
        }
        else {
            this.setState({ [name]: value });
        }
    }
    onKeyDownPassword = (event) => {
        var key = event ? event.which : window.event.keyCode;
        if (key === 32) {
            this.validPassword = false;
            return false;
        }
    }
    onResolved = (event) => {
        var response = "";
        response = this.recaptcha.getResponse();
        if (response !== "") {
            this.recaptcha.reset();
            this.handleSubmit();
        }
        event.preventDefault();
    }
    handleSubmit = async (event) => {
        this.setState({ message: [], loading: 'true' })
        let apiresponse = "";
        let regError = [];
        let getEarnKarmaPoint = false;
        // let referralCode = '';
        // let self_referral_code = '';
        if (window.history.state && window.history.state.state && window.history.state.state.earnKarmaPoint && window.history.state.state.uType) {
            getEarnKarmaPoint = true;
        }
        // if (localStorage.getItem("referralCode")) {
        //     referralCode = JSON.parse(localStorage.getItem("referralCode"));
        // }
        let account_type_number;
        var account_type = this.state.accountType.filter((item) => item.id == this.state.usertype)
        if(account_type.length != 0) account_type_number = this.state.usertype;
        else account_type_number = 1

        await axios.post(API_URL + FRONT_USER + "/registration", {
            first_name: this.state.firstname,
            last_name: this.state.lastname,
            email: this.state.email,
            password: this.state.password,
            terms_condition: this.state.terms_condition,
            newsletter: this.state.newsletter,
            account_type: account_type_number,
            languagecode: LANGUAGE_CODE,
            earnKarmPoints: getEarnKarmaPoint ? window.history.state.state.earnKarmaPoint : null,
            user_id: getEarnKarmaPoint ? window.history.state.state.uType : null,
            // referral_by_code: referralCode ? referralCode : null,
            // referral_code: self_referral_code
        })
            .then((response) => {
                this.setState({ loading: 'false' })
                apiresponse = response.data;
                apiresponse = response.data['success'];
                if (apiresponse !== null) {
                    localStorage.setItem("registrationMessage", JSON.stringify(apiresponse));
                    this.setState({ count: 1 })
                }
                // if (localStorage.getItem("referralCode")) {
                //     localStorage.removeItem("referralCode");
                // }
                window.history.state = {};
                this.setState({ message: apiresponse })
                window.setTimeout(() => {
                    this.setState({ message: [], count: null, firstname: '', lastname: '', email: '', password: '', terms_condition: false, newsletter: false, usertype: 0 })
                }, MESSAGE_TIME);
            })
            .catch((error) => {
                this.scrollUpFunction();
                this.setState({ loading: 'false' })
                apiresponse = error.response.data['error'];
                if (apiresponse !== null) {
                    this.setState({ count: 0 })
                }
                Object.keys(apiresponse).map(function (key, index) {
                    regError.push(apiresponse[key]);
                    return 0;
                });
                this.setState({ message: regError })
                window.setTimeout(() => {
                    this.setState({ message: [], count: null })
                }, MESSAGE_TIME);
            });
        if (event) {
            event.preventDefault();
        }
    }
    handleCaptcha = (event) => {
        var registrationfield = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            password: this.state.password,
            terms_condition: this.state.terms_condition,
            usertype: this.state.usertype
        }
        var a = [];
        this.setState({ fieldErrorMessage: a })
        var errorCount = 0;
        var error = ''
        for (var key in registrationfield) {
            if (registrationfield.hasOwnProperty(key)) {
                var errorkey = key;
                const value = registrationfield[errorkey];
                if (value === '' || value === null || value === undefined || value === false || value === 0) {
                    a.push(errorkey);
                    error = errorCount + 1;
                    errorCount = error;
                }
                else {
                    if (errorkey === 'email') {
                        // eslint-disable-next-line 
                        var emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        var checkEmail = emailRE.test(value);
                        if (checkEmail === false) {
                            a.push(errorkey + "validation");
                            error = errorCount + 1;
                            errorCount = error;
                        }
                    }
                    if (errorkey === 'password' && value.length < 8) {
                        a.push("passwordLengthValidation");
                        error = errorCount + 1;
                        errorCount = error;
                    }
                }
            }
        }
        this.setState({ error: errorCount, fieldErrorMessage: a });
        if (errorCount === 0) {
            this.recaptcha.execute();
        }
        event.preventDefault();
    }
    getAccountTypes = async () => {
        await axios.get(API_URL + "/common/getAccountTypes/" + LANGUAGE_CODE)
            .then((response) => {
                var accountType = response.data.success.AccountTypes
                localStorage.setItem("accountType", JSON.stringify(accountType))
                this.setState({ accountType: accountType })
            })
            .catch((error) => {
                // console.log("error", error.response)
            })
        var selected_account = this.state.accountType.filter((item) => item.id == this.state.usertype)
        var category = selected_account[0]?.account_type_category_id;
        if (category == 3 || category == 4 || category == 2) {
            this.setState({ show: true })
        } else {
            this.setState({ show: false })
        }
        this.setState({ dark_logo: selected_account[0]?.logo_image })
        this.setState({ white_logo: selected_account[0]?.logo_image1 })
    }
    render() {
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let show = this.state.show
        let token = '';
        if (storeitem !== null) {
            token = storeitem.token;
        }
        var span = {
            color: 'red'
        }
        let typeData = '';
        let FinalDropdownList = this.state.accountType;
        if (FinalDropdownList !== null) {
            typeData = FinalDropdownList.map((type, index) => {
                return <option value={type.id} key={index}>{type.account_type}</option>
            })
        }
        return (
            // <!-- Main -->
            <main>
                {(token !== '') ?
                    <Redirect to="/mein-bereich" />
                    : null
                }
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                <Header dark_logo={this.state.dark_logo} white_logo={this.state.white_logo} />
                <section className="page-title-section theme-bg">
                    <div className="container-fluid">
                        <div className="row align-items-center justify-content-center no-gutters text-center min-height-form">
                            <div className="registration-form col-12 col-sm-10 col-md-7 col-lg-5">
                                <div className="padding-login-form hs-form-field">
                                    {(this.state.count === 0) ?
                                        <span className="error_message output_message error message-padding">
                                            {this.state.message.map((msg, index) => <ListItem key={index} value={msg} />)}
                                        </span>
                                        : null
                                    }
                                    {(this.state.count === 1) ?
                                        <Redirect to='/anmelden' />
                                        : null
                                    }
                                    <h2 className="text-dark font-weight-bold mb-4">{REGISTRATION_CONSTANT[LANGUAGE_CODE]['registartion_form_title']}</h2>
                                    <div className="text-gray text-survey d-flex text-left">
                                        <span className="fa fa-check mt-1"></span>
                                        <div class="mb-0">{show ? REGISTRATION_CONSTANT[LANGUAGE_CODE]['additional_information_nsu1'] : REGISTRATION_CONSTANT[LANGUAGE_CODE]['additional_information_2']}</div>
                                    </div>
                                    <div className="text-gray text-survey d-flex text-left">
                                        <span className="fa fa-check mt-1"></span>
                                        <div class="mb-0">{show ? REGISTRATION_CONSTANT[LANGUAGE_CODE]['additional_information_nsu2'] : REGISTRATION_CONSTANT[LANGUAGE_CODE]['additional_information_3']}</div>
                                    </div>
                                    <div className="text-gray text-survey d-flex text-left mb-4">
                                        <span className="fa fa-check mt-1"></span>
                                        <div class="mb-0">{show ? REGISTRATION_CONSTANT[LANGUAGE_CODE]['additional_information_nsu3'] : REGISTRATION_CONSTANT[LANGUAGE_CODE]['additional_information_4']}</div>
                                    </div>
                                    <form className="text-left" method="post" onSubmit={this.handleCaptcha} id="registrationform" >
                                        <div className="form-group focus">
                                            <select className="custom-select" onChange={this.handleChange} id="wahle dein" name="usertype" value={this.state.usertype} >
                                                {typeData}
                                            </select>
                                            <div>
                                                {(this.state.fieldErrorMessage.filter(a => a === 'usertype').length > 0) ?
                                                    <span style={span}>{REGISTRATION_ERROR_MESSAGE[LANGUAGE_CODE]['usertype']}</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group hs-form-field">
                                            <input type="text" name="firstname" className={(this.state.fieldErrorMessage.filter(a => a === 'firstname').length > 0) ? " form-control hs-input field-highlight" : "form-control hs-input"} onChange={this.handleChange} value={this.state.firstname} maxLength={20} />
                                            <label htmlFor="Vorname">{REGISTRATION_CONSTANT[LANGUAGE_CODE]['first_name']}</label>
                                        </div>
                                        <div className="form-group hs-form-field">
                                            <input type="text" name="lastname" className={(this.state.fieldErrorMessage.filter(a => a === 'lastname').length > 0) ? " form-control hs-input field-highlight" : "form-control hs-input"} onChange={this.handleChange} value={this.state.lastname} maxLength={20} />
                                            <label htmlFor="Nachname">{REGISTRATION_CONSTANT[LANGUAGE_CODE]['last_name']}</label>
                                            <div>
                                                {(this.state.fieldErrorMessage.filter(a => a === 'lastname').length > 0) ?
                                                    <span style={span}>{REGISTRATION_ERROR_MESSAGE[LANGUAGE_CODE]['lastname']}</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group hs-form-field">
                                            <input type="text" name="email" autoCapitalize="none" className={(this.state.fieldErrorMessage.filter(a => a === 'email' || a === 'emailvalidation').length > 0) ? " form-control hs-input field-highlight" : "form-control hs-input"} onChange={this.handleChange} value={this.state.email} />
                                            <label htmlFor="email adress">{REGISTRATION_CONSTANT[LANGUAGE_CODE]['email_address']}</label>
                                            <div>
                                                {(this.state.fieldErrorMessage.filter(a => a === 'email' || a === 'emailvalidation').length > 0) ?
                                                    <span style={span}>{REGISTRATION_ERROR_MESSAGE[LANGUAGE_CODE][this.state.fieldErrorMessage.filter(a => a === 'email' || a === 'emailvalidation')]}</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group hs-form-field">
                                            <input type="password" name="password" className={(this.state.fieldErrorMessage.filter(a => a === 'password' || a === 'passwordLengthValidation').length > 0) ? " form-control hs-input field-highlight" : "form-control hs-input"} onChange={this.handleChange} value={this.state.password} onKeyDown={(event) => this.onKeyDownPassword(event)} />
                                            <label htmlFor="password">{REGISTRATION_CONSTANT[LANGUAGE_CODE]['password']}</label>
                                            <div>
                                                {(this.state.fieldErrorMessage.filter(a => a === 'password').length > 0) ?
                                                    <span style={span}>{REGISTRATION_ERROR_MESSAGE[LANGUAGE_CODE]['password']}</span>
                                                    : null
                                                }
                                                {(this.state.fieldErrorMessage.filter(a => a === 'passwordLengthValidation' && a !== 'password').length > 0) ?
                                                    <span style={span}>{REGISTRATION_ERROR_MESSAGE[LANGUAGE_CODE]['passwordLength']}</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="custom-control custom-checkbox pl-0 mt-4">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" name="terms_condition" onChange={this.handleChange} checked={this.state.terms_condition} />
                                            <label className="custom-control-label" htmlFor="customCheck1">{REGISTRATION_TERMS_CONDITION[LANGUAGE_CODE]['terms_condition1'] + " "}<Link to="/datenschutz" style={{ color: "#00b3f4" }}>{REGISTRATION_TERMS_CONDITION[LANGUAGE_CODE]['terms_condition2']}</Link>{" " + REGISTRATION_TERMS_CONDITION[LANGUAGE_CODE]['terms_condition3'] + " "}<Link to="/agb" style={{ color: "#00b3f4" }}>{REGISTRATION_TERMS_CONDITION[LANGUAGE_CODE]['terms_condition4']}</Link>{" " + REGISTRATION_TERMS_CONDITION[LANGUAGE_CODE]['terms_condition5']}</label>
                                            <div>
                                                {(this.state.fieldErrorMessage.filter(a => a === 'terms_condition').length > 0) ?
                                                    <span style={span}>{REGISTRATION_ERROR_MESSAGE[LANGUAGE_CODE]['terms_condition']}</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="custom-control custom-checkbox pl-0 mt-2">
                                            <input type="checkbox" className="custom-control-input" id="customCheck2" name="newsletter" onChange={this.handleChange} checked={this.state.newsletter} />
                                            <label className="custom-control-label" htmlFor="customCheck2">{REGISTRATION_CONSTANT[LANGUAGE_CODE]['newsletter_checkbox_content_1']}<br /> {REGISTRATION_CONSTANT[LANGUAGE_CODE]['newsletter_checkbox_content_2']}</label>
                                        </div>
                                        <Recaptcha ref={ref => this.recaptcha = ref} locale={RECAPTCHA_LANGUAGE} sitekey={SITEKEY} onResolved={this.onResolved} />
                                        <div className="form-group font-size-btn">
                                            <input className="btn btn-clr w-100 d-block login-btn text-uppercase text-white mt-4 font-16" type="submit" title={REGISTRATION_CONSTANT[LANGUAGE_CODE]['registartion_button']} value={REGISTRATION_CONSTANT[LANGUAGE_CODE]['registartion_button']} />
                                        </div>
                                    </form>
                                    <div className="btn-link-for-registrer mt-3 mt-sm-4 font-weight-bold">
                                        <Link title={REGISTRATION_CONSTANT[LANGUAGE_CODE]['do_you_have_account']} className="text-dark d-block d-sm-inline-block" to="/anmelden">{REGISTRATION_CONSTANT[LANGUAGE_CODE]['do_you_have_account']}</Link>
                                        <Link title={REGISTRATION_CONSTANT[LANGUAGE_CODE]['login_link']} className="text-blue d-block d-sm-inline-block ml-0 ml-sm-3" to="/anmelden">{REGISTRATION_CONSTANT[LANGUAGE_CODE]['login_link']}</Link>
                                    </div>
                                </div>
                            </div> {/*<!-- col -->*/}
                        </div>
                    </div>
                </section>{/*<!--login section end -->*/}
            </main>
        );
    }
}
export default NsuRegistration;