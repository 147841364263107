import React from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import GoogleChart from '../surveyPublishResultDetail/GoogleResultChart';
import { BUBBLE_LOADING, SURVEY_DETAIL_CONSTANT, SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT, META_FACEBOOK, META_SHARE_FACEBOOK } from '../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, IMAGE_BASEURL, META_TITLE_APPEND, META_TITLE_STATISTICS_PREPEND } from '../../../constants/DotEnv';
class SurveyPublishResultDetail extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            chart_loaded_once: false,
            surveyData : {},
            isImageDeleted : true,
            hide_chart: true,
            chart_loading: true,
            redirectToNotFound : false,
        }
        this.savedImages = 0;
        this.totalGraphLength = 0;
        this.totalQuestionLength = 0;
        this.mobileDevice = 0;
    }
    // componentWillUnmount() {
    //     setTimeout(() => {
    //         window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
    //         window.removeSchema();
    //     }, 100)
    // }
    componentDidMount() {
        this.scrollUpFunction();
        this.getSurveyResultDetail();
    }
    scrollUpFunction = () => {
        /** For scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (document.querySelector('.navbar-toggler')) {
            if (document.querySelector('.navbar-toggler').className) {
                let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
                if (count === -1) {
                    document.querySelector('.navbar-collapse').classList.remove('show');
                    document.querySelector('header').classList.remove("header_collapse");
                    document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
                }
            }
        }
    }
    /** API call for getting survey Result details */
    getSurveyResultDetail = async () => {
        this.setState({ loading: true })
        let title = this.props.match.params.title;
        let userId = ""
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        if (storeitem && storeitem.id) { 
            userId = storeitem.id;
        }
        let surveyData = '';
        let ApiUrl = '';
        let meta_title = '';
        // let meta_description = '';
        // let meta_keywords = '';
        let og_title = '';
        let og_description = META_SHARE_FACEBOOK['og_description'];
        let og_image = '';
        if (window.innerWidth >= 320 && window.innerWidth <= 575) {
            this.mobileDevice = 1;
        }
        if (storeitem && storeitem.id) {
            ApiUrl = API_URL + '/commonApi/getDataOfPublishedResultDetailPage/' + LANGUAGE_CODE + '/' + title + '/' + userId + '?mobileDevice=' + this.mobileDevice;
        }
        else {
            ApiUrl = API_URL + '/commonApi/getDataOfPublishedResultDetailPage/' + LANGUAGE_CODE + '/' + title + '?mobileDevice=' + this.mobileDevice;
        }
        await axios.get(ApiUrl)
        .then((response) => {
            if (response && response.data && response.data.success) {
                if (response.data.success.surveydata) {
                    surveyData = response.data.success.surveydata; 
                    this.surveyEditDataId = surveyData.id;
                    meta_title = META_TITLE_STATISTICS_PREPEND + surveyData.survey_title;
                    // meta_description = META_OG_DESCRIPTION[LANGUAGE_CODE]['surveylist'];
                    // meta_keywords = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords'];
                    // window.setMeta({ "description": meta_description, "keywords": meta_keywords });
                    window.setDocumentTitle(meta_title)
                    og_title = surveyData.survey_title + META_TITLE_APPEND;
                    og_image = (surveyData.ogImageUrl) ? surveyData.ogImageUrl : META_FACEBOOK['og_image'];
                    window.setOgTag(og_title, og_description, og_image, window.location.href)
                    if (surveyData['presentation_of_the_survey'] && surveyData['presentation_of_the_survey'] !== "") {
                        let presentation_of_the_survey = surveyData['presentation_of_the_survey'];
                        surveyData['presentation_of_the_survey'] = presentation_of_the_survey;
                    }
                    if (surveyData['facts_and_odds'] && surveyData['facts_and_odds'] !== "") {
                        let facts_and_odds = surveyData['facts_and_odds'];
                        surveyData['facts_and_odds'] = facts_and_odds;
                    }
                    if (surveyData['publish_date'] && surveyData['publish_date'] !== "") {
                        let publish_date = surveyData['publish_date'].replace(/-/gi, "." );
                        surveyData['publish_date'] = publish_date;
                    }
                    this.setState({loading: false, surveyData})
                }
                if (response.data.success.chartdata) {
                    if (response.data.success.chartdata.demographic) {
                        this.totalGraphLength += Object.keys(response.data.success.chartdata.demographic).length;
                    }
                    if (response.data.success.chartdata.questiondata) {
                        this.totalGraphLength += Object.keys(response.data.success.chartdata.questiondata).length;
                        this.totalQuestionLength += Object.keys(response.data.success.chartdata.questiondata).length;
                    }
                    this.setState({
                        questionList : response.data.success.chartdata
                    })
                }
                if (response.data.success.is_payment) {
                    let payment = response.data.success.is_payment;
                    this.setState({payment})
                }
            }
        })
        .catch((error) => {
            this.setState({ loading: false, redirectToNotFound: true })
        })
    }
    // For save images of all chart
    // sendImages = (data, chartData) => {
    //     let self = this;
    //     let dataObject = {
    //         "file_data": data, "survey_id": this.surveyEditDataId
    //     }
    //     if (chartData.question_title) {
    //         dataObject['question_title'] = chartData.question_title;
    //     }
    //     if(this.state.isImageDeleted){
    //         // eslint-disable-next-line
    //         axios.post(API_URL + "/commonApi/SaveImagesForShare", dataObject).then(res => {
    //             self.savedImages += 1;
    //             if (self.savedImages === self.totalGraphLength) {
    //                 // Do loading false
    //                 self.setState({
    //                     hide_chart: true,
    //                     chart_loading: false
    //                 })
    //                 if (document.getElementsByClassName("remove-chart-div") && document.getElementsByClassName("remove-chart-div").length) {
    //                     document.querySelectorAll('.remove-chart-div').forEach(function(a){
    //                         setTimeout(() => {
    //                             a.remove()
    //                         }, 100);
    //                     })
    //                 }
    //             }
    //         }).catch(err => console.log(err))
    //     }
    // }

    render() {
        let surveyData = this.state.surveyData;
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let headerImage = IMAGE_BASEURL + "img/100x100.jpg";
        if (surveyData) {
            if (surveyData.survey_header_image && surveyData.survey_header_image !== '') {
                headerImage = surveyData.survey_header_image;
            }
        }
        return (
            <main>
                {(this.state.loading === true) ?
                    BUBBLE_LOADING
                    : null
                }
                {(this.state.redirectToNotFound) ?
                    <Redirect to='/404'></Redirect>
                    :null
                }
                <div>
                    {/*- title start -*/}
                    <section className="banner_text d-flex align-items-center survey-detal-bg-img background-image-css" style={{ "backgroundImage": "url(" + headerImage + ")" }}>
                        <div className="container-fluid px-0">
                            <div className="page-title text-center">
                                <h1 className="text-white font-weight-bold mb-0 without-word-break survey-title-sm">{surveyData.survey_title}</h1>
                                <p className="text-white text-center mb-0 font-18">{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['from_subtitle']} {surveyData.user_name}</p>
                            </div>
                        </div>
                    </section>
                    {/*- title end -*/}
                    {/* tags section start */}
                    <section className="tags-section">
                        <div className="container container-large">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="d-inline-block text-center">
                                    <div className="tags text-center d-inline-block m-2 m-lg-2">
                                        <span className="text-dark text-uppercase bg-gray d-sm-flex align-items-center justify-content-center"><i className="ti-tag"></i> {surveyData.survey_category_name}</span>
                                    </div>
                                    <div className="tags text-center d-inline-block m-2 m-lg-2">
                                        <span className="text-dark text-uppercase bg-gray d-sm-flex align-items-center justify-content-center"><i className="ti-user"></i> {surveyData.participate_count} {SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['totalparticipants']}</span>
                                    </div>
                                    <div className="tags text-center d-inline-block m-2 m-lg-2">
                                        <span className="text-dark text-uppercase bg-gray d-sm-flex align-items-center justify-content-center"><i className="ti-calendar"></i> {surveyData.publish_date}</span>
                                    </div>
                                    <div className="tags text-center d-inline-block m-2 m-lg-2">
                                        <span className="text-dark text-uppercase bg-gray d-sm-flex align-items-center justify-content-center"><i className="ti-bar-chart"></i> {surveyData.totalquestion_published} {SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['question']}</span>
                                    </div>
                                    <div className="tags text-center d-inline-block m-2 m-lg-2">
                                        <span className="text-dark text-uppercase bg-gray d-sm-flex align-items-center justify-content-center"><i className="ti-file"></i> {surveyData.type_of_scientific_work}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* tags section end */}
                    {/* survey-publish-result-details */}
                    <section className="survey-detials pb-4 pb-lg-5">
                        <div className="container container-large">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-8">
                                    <div className="Vorstellung">
                                        <h2 className="text-dark font-weight-bold word-break-text">{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['presentation_of_study']}</h2>
                                        <p className="text-dark font-18 mt-3 mb-0 word-break-text" dangerouslySetInnerHTML={{ __html: surveyData.presentation_of_the_survey ? surveyData.presentation_of_the_survey.replace(/<br\s*\/?>/mg, "").replace( /(<([^>]+)>)/ig, '').replace(/(?:\r\n|\r|\n)/g, '<br>') : surveyData.presentation_of_the_survey }}></p>
                                    </div>
                                    <div className="zielgruppe">
                                        <h2 className="text-dark font-weight-bold word-break-text">{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['survey_facts_odds']}</h2>
                                        <p className="text-dark font-18 mt-3 mb-0 word-break-text" dangerouslySetInnerHTML={{ __html: surveyData.facts_and_odds ? surveyData.facts_and_odds.replace(/<br\s*\/?>/mg, "").replace( /(<([^>]+)>)/ig, '').replace(/(?:\r\n|\r|\n)/g, '<br>') : surveyData.facts_and_odds }}></p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-4 text-center">
                                    <div className="author-details">
                                        <div className="author-content">
                                            <img src={(surveyData.userimage) ? surveyData.userimage : IMAGE_BASEURL + "img/user_profile.png"} className="rounded-circle custom-survey-detail" alt="person-img" width="135" height="135" />
                                            <h6 className="text-dark font-weight-bold mb-0 mt-3">{ surveyData.user_name ? surveyData.user_name : null }</h6>
                                            <p className="font-18 mb-0 text-dark mt-0">{ surveyData.university_name ? surveyData.university_name : null }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <div className="zielgruppe mb-5">
                                        <h2 className="text-dark font-weight-bold word-break-text">{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['results']}</h2>
                                    </div>
                                </div>
                                {this.state.questionList && <GoogleChart chart_loading={this.state.chart_loading} chartData={this.state.questionList} questionLength={this.totalQuestionLength} chart_loaded_once={this.state.chart_loaded_once} payment={this.state.payment} surveydata={this.state.surveyData} getSurveyResult={this.getSurveyResultDetail} sendImages={this.sendImages} hideChart={this.state.hide_chart} />}
                            </div>
                        </div>
                    </section>
                    {/* survey-publish-result-details end */}
                </div>
            </main>
        )
    }
}
export default SurveyPublishResultDetail;