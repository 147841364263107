import React, { Fragment } from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import { LANGUAGE_CODE, API_URL } from '../../../../constants/DotEnv';
import { SURVEY_CREATION_CONSTANT, BUBBLE_LOADING } from '../../../../constants/FrontEndConstant';

import Button from '@material-ui/core/Button';
class SurveyUploadLogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: 'false',
            redirect: 'false',
            isPremiumUser: true,
            imgUploaded: false,
            imgpoolLength: 0,
            img_error: '',
            show_upload_logo: false
        }
    }

    /** this function is for scrolling up page */
    scrollUpFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    deleteLogo = async (event) => {
        this.setState({ loading: "true" })
        let survey_id = this.props.survey_id
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let userId = '';
        let logoName;
        if (storeitem !== null) {
            apitoken = storeitem.token;
            userId = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        if (this.props.id == "survey_logo") {
            logoName = "surveylogo"
        }
        if (this.props.id == "survey_optional_logo") {
            logoName = "surveyoptionallogo"
        }
        await axios.get(API_URL + '/survey/deletensulogo/' + LANGUAGE_CODE + '/' + survey_id + '/' + userId + '/' + logoName + '?uId=' + userId, { headers: headers })
            .then(async (response) => {
                if (response.data.success) {
                    this.setState({show_upload_logo: true})
                    var surveyData = this.props.survey_id;
                    var newSurveyData = response.data.surveydata
                    localStorage.setItem('surveycreationdata_' + surveyData, JSON.stringify(newSurveyData))
                    if (newSurveyData.survey_logo == null) {
                        this.props.show_logo("survey_logo")
                    }
                    if (newSurveyData.survey_optional_logo == null) {
                        this.props.show_logo("survey_optional_logo")
                    }
                }
            })
        this.setState({ loading: "false" })
    }
    render() {
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let user_ID = storeitem.account_type_category_id
        return (
            <Fragment>
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {storeitem.plan_type ?
                    <>{this.props.data ?
                        <div className="col-12 col-md-4 col-lg-3 pb-3 pt-lg-0" >
                            <label htmlFor={"customRadioInline"} className="MuiButton-effect w-100 mb-0 survey-pic-shadow">
                                <Button variant="contained" name={"customRadioInline"} style={{"cursor": "initial"}} >
                                    <div className="fill-by-custom_logo" style={{"backgroundColor": "#dee2e6"}}>
                                        <img src={ this.props.logo} className=" custom-logo-size"></img>
                                    </div>
                                    <div className="custom-radio-btn btn w-100">
                                        <div className="custom-control custom-radio custom-control-inline pl-0 w-100">
                                            <div className="custom-control-label mb-0 btn-radio-spacing"  style={{"cursor": "initial"}} >
                                                <i class="fas fa-trash" style={{"cursor": "pointer"}} onClick={(event) => this.deleteLogo(event)} ></i>
                                            </div>
                                        </div>
                                    </div>
                                </Button>
                            </label>
                        </div> : <div className="col-12 col-md-4 col-lg-3 pb-3 pt-lg-0" onClick={() => { document.getElementById(this.props.id).click() }} >
                        <label htmlFor='customRadioInline' className="MuiButton-effect w-100 mb-0 pointer survey-pic-shadow" >
                            <Button variant="contained" className='MuiButtonBase-root MuiButton-root MuiButton-contained' tabIndex='0' type='button'  >
                                <span className='MuiButton-label'>
                                    <div className="fill-by-img icon-center" >
                                        <div className="icon card-img icon-center" style={{ alignItems: "center" }}>
                                            <input type="file" id={this.props.id} accept="image/*" onChange={this.props.handleUpload} />
                                            <i className="ti-plus icon-m border-radius btn-clr create-plus"></i>
                                        </div>
                                    </div>
                                        <div className="custom-radio-btn btn w-100">
                                            <div className="custom-control custom-radio custom-control-inline pl-0 w-100">
                                                <div className="custom-control-label  mb-0 btn-radio-spacing text-dark font-18" style={{"fontWeight": "500"}} >Logo {this.props.logo_number}</div>
                                            </div>
                                        </div>
                                </span>
                            </Button>
                        </label>
                    </div>
                    }</>
                    : <Link className="col-12 col-md-4 col-lg-3 pb-3 pt-lg-0" to={user_ID == 4 ? '/business-checkout': '/university-checkout'}>
                        <label htmlFor='customRadioInline' className="MuiButton-effect w-100 mb-0 pointer survey-pic-shadow" >
                            <Button variant="contained" className='MuiButtonBase-root MuiButton-root MuiButton-contained' tabIndex='0' type='button' color="#00b3f4" name='customRadioInline' onClick={() => localStorage.setItem('redirectURL', window.location.href)}>
                                <span className='MuiButton-label'>
                                    <div className="fill-by-img icon-center" >
                                        <div className="icon card-img icon-center" style={{ alignItems: "center" }}>
                                            <input type="file" id={this.props.id} />
                                            <i className="ti-plus icon-m border-radius btn-clr create-plus" ></i>
                                        </div>
                                    </div>
                                    <div className="custom-radio-btn btn w-100">
                                        <div className="custom-control custom-radio custom-control-inline pl-0 w-100">
                                            <div className="custom-control-label  mb-0 btn-radio-spacing text-dark font-20" ><i className='ti-lock'></i></div>
                                        </div>
                                    </div>
                                </span>
                            </Button>
                        </label>
                    </Link>}
            </Fragment>
        );
    }
}
export default SurveyUploadLogo;