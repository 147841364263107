import React,{useEffect} from 'react';
import DemographicSingleTextField from './DemographicSingleTextField';
import DemographicSingleSelection from './DemographicSingleSelection';
import DemographicDropdownSelection from './DemographicDropdownSelection';
import axios from "axios";
import { API_URL } from '../../../../constants/DotEnv';

class SurveyDemographicAttribute extends React.Component {
    componentDidMount() {
        window.addEventListener('popstate', window.preventBackNav);
    }
    componentWillUnmount() {
        // you need to unbind the same listener that was binded.
        window.removeEventListener('popstate', window.preventBackNav, false);
    }
    section = (demographictype, data, survey_id) => {
        var demographicdropdownoption = this.props.demographicdropdown;
        var demographiccomponent = demographictype;
        var demographicdata = data;
        switch (demographiccomponent) {
            case "single_textfield":
                return < DemographicSingleTextField demographicdata={demographicdata} onChange={this.props.onChange} counter={this.props.counter} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} onSelect={this.props.onSelect} onKeyPress={this.props.onKeyPress} postalloading={this.props.postalloading} locationData={this.props.locationData} continue={this.props.continue} finish={this.props.finish} backButtonClick={this.props.backButtonClick} lastIndex = {this.props.lastIndex} surveydata={this.props.surveydetail}/>
            case "single_selection":
                return < DemographicSingleSelection survey_id={survey_id.surveydata.survey_id} demographicdata={demographicdata} demographicdropdown={demographicdropdownoption} onChange={this.props.onChange} counter={this.props.counter} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} lastIndex = {this.props.lastIndex}/>
            case "dropdown":
                return < DemographicDropdownSelection demographicdata={demographicdata} demographicdropdown={demographicdropdownoption} onChange={this.props.onChange} counter={this.props.counter} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} lastIndex = {this.props.lastIndex}/>
            default:
        }
    }
     render() {
        let data = this.props.nextattribute;
        let survey_id = this.props.data;
        let demographictype = '';
        if (data) {
            demographictype = data.type;
        }
        let surveyDetail = JSON.parse(localStorage.getItem('surveydetail_' + survey_id.surveydata.survey_id));
        if(surveyDetail.englishbuttonlabels == "1") {
            let allDemographic = JSON.parse(localStorage.getItem('allDemographic'));
            if(allDemographic!=null) {
                allDemographic.find((demographic, index) => {
                    if(demographic.id == data.id) {
                        data.question = demographic.question;
                    }
                });
            }
        }

        const element = this.section(demographictype, data, survey_id);
        return (
            <div className={"container survey-form"}>{element}</div>
        )
    }
}
export default SurveyDemographicAttribute;