import React from 'react';
import FindParticipants from './FindParticipants';
import Analyze from './Analyze';
import PublishResult from './PublishResult';
import axios from 'axios';
import { Redirect, Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import { EDIT_SURVEY_CONSTANT, META_TITLE_CONSTANT, META_TAGS_CONSTANT, MYPROFILE_POLLS_CONSTANT, URL_CONSTANT } from '../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, META_TITLE_APPEND } from '../../../constants/DotEnv';
class EditSurvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drawChart: false,
            showCounter:false,
            poll_data: [],
            redirectToEdit: "false"
        }
        this.activeFirst = true;
        this.activeSecond = false;
        this.activeThird = false;
        this.surveyMainId = (this.props.match.params.surveyid) ? window.atob(this.props.match.params.surveyid) : this.props.match.params.surveyid;
        let meta_title = META_TITLE_CONSTANT[LANGUAGE_CODE]['findparticipation'] + META_TITLE_APPEND;
        let meta_description = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_description'];
        let meta_keywords = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords'];
        setTimeout(() => {
            window.setMeta({ "description": meta_description, "keywords": meta_keywords })
            window.setDocumentTitle(meta_title)
        }, 100)
    }
    clearRelatedKey = (e) => {
        localStorage.removeItem("surveycreationeditid")
        localStorage.removeItem("editsurveyactivetab")
        if (this.surveyMainId) {
            localStorage.removeItem("surveycreationdata_" + this.surveyMainId)
            localStorage.removeItem("surveycreationallquestiondata_" + this.surveyMainId)
            localStorage.setItem("myprofileactivetab", 2);
        }
        window.history.push('/mein-bereich');
    }
    componentDidMount() {
        this.scrollUpFunction();
    }
    /**
    * Warning: This lifecycle is currently deprecated, and will be removed in React version 17+
    More details here: https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
    */
    componentWillMount() {
        this.getData();
    }
    getData = async () => {
        let self = this;
        let ActiveTab = localStorage.getItem("editsurveyactivetab");
        if (parseInt(ActiveTab) === 2) {
            this.drawChart();
        }
        if (parseInt(ActiveTab) === 3) {
            this.publishActiveTab();
        }
        if (this.surveyMainId) {
            let poll_data;
            let pollsdata = JSON.parse(localStorage.getItem("surveycreationdata_" + this.surveyMainId))
            if (pollsdata !== null && pollsdata['survey_identifier'] && pollsdata['survey_identifier'] !== null && pollsdata['survey_identifier'] !== '') {
                poll_data = pollsdata;
                self.setState({ poll_data })
            }
            else {
                let self = this;
                var responseSurvey = '';
                let responseQuestion = '';
                let storeitem = JSON.parse(localStorage.getItem('userdata'));
                let apitoken = '';
                let userid = '';
                if (storeitem !== null) {
                    apitoken = storeitem.token;
                    userid = storeitem.id;
                }
                var headers = {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + apitoken
                }
                await axios.get(API_URL + '/survey/getQuestionBySurveyId/' + LANGUAGE_CODE + '/' + this.surveyMainId + '?uId=' + userid, { headers: headers })
                    .then((response) => {
                        responseSurvey = response.data['success'].surveydata;
                        poll_data = responseSurvey;
                        responseQuestion = response.data['success'].questiondata;
                        if (this.surveyMainId) {
                            localStorage.setItem("surveycreationdata_" + this.surveyMainId, JSON.stringify(responseSurvey));
                            localStorage.setItem("surveycreationallquestiondata_" + this.surveyMainId, JSON.stringify(responseQuestion));
                        }
                        self.setState({ loading: 'false', poll_data })
                    })
                    .catch((error) => {
                        if (error) {
                            if (error.response) {
                                if (error.response.data) {
                                    let msg = error.response.data['error']
                                    if (msg === 'Expired token' || msg === 'Invalid token') {
                                        localStorage.clear();
                                        self.setState({ redirect: 'true' })
                                    }
                                    if (msg === 'Invalid User') {
                                        self.setState({ redirectToNotFound: true })
                                    }
                                }
                            }
                        }
                        self.setState({ loading: 'false' })
                    })
            }
        }
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    drawChart = () => {
        this.activeFirst = false;
        this.activeSecond = true;
        this.activeThird = false;
        this.setState({
            drawChart: true,
            showCounter:true
        })
        localStorage.setItem("editsurveyactivetab", 2);
    }
    removeCharts = () => {
        this.setState({
            drawChart: false,
            showCounter:false
        })
    }
    changeData = (status) => {
        let poll_data = this.state.poll_data;
        poll_data.status = status;
        this.setState({ poll_data })
    }
    changeActiveTab = () => {
        this.setState({
            activeFirst: true,
            activeSecond: false,
            activeThird: false,
            showCounter:false
        })
        this.activeFirst = true;
        this.activeSecond = false;
        this.activeThird = false;
        localStorage.setItem("editsurveyactivetab", 1);
    }
    // PublishResult ActiveTab
    publishActiveTab = () => {
        this.setState({
            activeFirst: false,
            activeSecond: false,
            activeThird: true
        })
        this.activeFirst = false;
        this.activeSecond = false;
        this.activeThird = true;
        localStorage.setItem("editsurveyactivetab", 3);
    }
    //To edit survey click to display popup message
    editSurveyMessageShow = (data, event) => {
        let isPublished = '';
        let surveydata = JSON.parse(localStorage.getItem("surveycreationdata_" + data.id));
        if (data.ispublish !== '') {
            isPublished = data.ispublish;
        } else if (surveydata && surveydata.hasOwnProperty('ispublish')) {
            isPublished = surveydata.ispublish;
        }
        // Survey result is publish then not edit survey question
        if (isPublished === 1) {
            confirmAlert({
                customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <div className="close-btn text-right">
                            <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                        </div>
                        <h3 className="mt-3 mb-4">{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['toedit_popup_heading']}</h3>
                        <p className="mt-3 mb-4">{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['toedit_popup_subtext']}</p>
                        <button className="ml-0" onClick={(event) => {
                            onClose()
                        }} >{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['no_question_found_ok_button']}</button>
                    </div>
                )
                }
            })
        }
        // If survey result not published then to edit survey question
        if (isPublished === 0 || isPublished === null || isPublished === '' || isPublished === undefined) {
            this.addEditSurveyId(data, event);
        }
    }
    // for edit Survey
    addEditSurveyId = (data, event) => {
        window.history.pushState(null, document.title, window.location.href);
        this.setState({ loading: "true" })
        // eslint-disable-next-line
        let editid = { "id": data.id }
        let surveyStatus = data.status;
        event.preventDefault();
        localStorage.removeItem("surveycreationeditid")
        if (data) {
            if (data.id) {
                localStorage.removeItem("surveycreationdata_" + data.id)
                localStorage.removeItem("surveycreationallquestiondata_" + data.id)
                this.surveyMainId = data.id;
            }
            if (data.survey_title_slug) {
                this.titleSlug = data.survey_title_slug;
            }
        }
        if (surveyStatus) {
            this.setState({ redirectToEdit: "true", loading: "false" })
        }
    }
    // Status check in publish result to set value in ispublish.
    changePublishedResultStatus = (id, status) => {
        if (id === this.state.poll_data.id) {
            let data = this.state.poll_data;
            data.ispublish = status;
            this.setState({
                poll_data : data
            })
            let surveydata = JSON.parse(localStorage.getItem("surveycreationdata_" + id))
            if (surveydata) {
                surveydata.ispublish = status;
                localStorage.setItem("surveycreationdata_" + id, JSON.stringify(surveydata));
            }
        }
    }

    render() {
        let userdata = JSON.parse(localStorage.getItem("userdata"))
        let survey_title = "";
        let poll_status = "";
        let poll_data = this.state.poll_data;
        let callForData = false;
        if (this.surveyMainId) {
            if (poll_data) {
                survey_title = poll_data.survey_title;
                poll_status = poll_data.status;
                callForData = true;
            }
        }
        let token = '';
        if (userdata !== null) {
            token = userdata.token;
        }
        let class_name = ''
        if(poll_status === 3 || poll_status === 4){
            if(userdata.account_type_category_id > 2){
                class_name = "edit-tabs myprofile-section  myprofile-section-nsu edit_survey_tabs"
            }else{
                class_name = "edit-tabs myprofile-section edit_survey_tabs"
            }
        }
        else{
            if(userdata.account_type_category_id > 2){
                class_name = "edit-tabs myprofile-section myprofile-section-nsu"
            }else{
                class_name = "edit-tabs myprofile-section "
            }
        }
        return (
            <main>
                {(token === '') ?
                    <Redirect to="/anmelden" />
                    : null
                }
                {(this.state.redirectToNotFound) ?
                    <Redirect to="/404" />
                    : null
                }
                {(this.state.redirectToEdit === "true" && this.surveyMainId) ?
                    <Redirect to={"/" + URL_CONSTANT['surveycreation'] + "/" + window.btoa(this.surveyMainId)}></Redirect>
                    : null
                }
                <div className="d-block" id="example-edit-modal" tabIndex="-1">
                    {(poll_data.length === undefined) ?
                        <div className="modal-dialog modal-width" role="document">
                            <div className="modal-content">
                                <div className="modal-header live-content-model bg-white">
                                    <div className="container-fluid">
                                        <div className="row align-items-center">
                                            <div className="col-10 col-xl-10 text-left col-sm-8">
                                                <div className="title-of-page text-truncate">
                                                    {// eslint-disable-next-line
                                                    }<a className="text-dark find-participation-tab-title">{survey_title}</a>
                                                </div>
                                            </div>
                                            <div className="col-2 col-xl-2 text-right closetab col-sm-4">
                                                <Link title={MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['edit']} to="#" className="w-auto d-inline-block text-gray font-18 edit_text_style mobile-hide"  onClick={(event) => this.editSurveyMessageShow(poll_data, event)}  >{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['edit']}</Link>
                                                <Link to="/mein-bereich" className="text-dark close close-editsurvey-tab" onClick={this.clearRelatedKey}><i className="ti-close ti-close-participation"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>{/*<!-- modal header end--->*/}
                                <div className="modal-body modal-edit-height" style={{minHeight: "100vh"}}>
                                    <div className={class_name}>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-12">
                                                    <ul className="nav nav-tabs custom-background-tabs" id="myTab" role="tablist">
                                                        <li className="nav-item text-center">
                                                            <a className={(this.activeFirst) ? "nav-link font-weight-bold active active-tab show" : "nav-link font-weight-bold completed"} title={EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['tab2']} id="teilnehmer-finden-tab" data-toggle="tab" href="#teilnehmer-finden" role="tab" aria-controls="teilnehmer-finden" aria-selected={(this.activeFirst) ? "true" : "false"} onClick={this.changeActiveTab}>{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['tab2']}</a>
                                                        </li>
                                                        <li className="nav-item text-center">
                                                            <a className={(this.activeSecond) ? "nav-link font-weight-bold active show active-tab" : "nav-link font-weight-bold completed"} title={EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['tab3']} id="auswerten-tab" data-toggle="tab" href="#auswerten" role="tab" aria-controls="auswerten" aria-selected={(this.activeSecond) ? "true" : "false"} onClick={this.drawChart}>{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['tab3']}</a>
                                                        </li>
                                                        {userdata.account_type_category_id <= 2 ?
                                                        <li className="nav-item text-center">
                                                            <a className={(this.activeThird) ? "nav-link font-weight-bold active show active-tab" : "nav-link font-weight-bold completed"} title={EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['tab4']} id="Veröffentlichen-tab" data-toggle="tab" href="#Veröffentlichen" role="tab" aria-controls="Veröffentlichen" aria-selected={(this.activeThird) ? "true" : "false"} onClick={this.publishActiveTab}>{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['tab4']}</a>
                                                        </li> : null
                                                        }
                                                    </ul>
                                                    <div className="tab-content md-m-30px-b" id="myTabContent">
                                                        {/** call find participants component for publish survey */}
                                                        {(this.activeFirst) ?
                                                            < FindParticipants poll_status={poll_status} poll_data={poll_data} callForData={callForData} changeData={this.changeData} />
                                                            : null}
                                                        {/** call analyze component for displaying result graph */}
                                                        {(this.activeSecond) ?
                                                            < Analyze showCounter={this.state.showCounter} isDrawChart={this.state.drawChart} {...this.props} />
                                                            : null}
                                                        {/* call Publish Result for displaying All Questions in grater than 100 Participation */}
                                                        {(this.activeThird) ?
                                                            <PublishResult poll_data={poll_data} changeData={this.changeData} changePublishedResultStatus={this.changePublishedResultStatus} />
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </main>
        )
    }
}
export default EditSurvey;