import React from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import { EMPIRIOWISSEN_CONSTANT, URL_CONSTANT, BUBBLE_LOADING, META_FACEBOOK, BREADCRUMB_SCHEMA } from '../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, META_TITLE_APPEND, IMAGE_BASEURL } from '../../constants/DotEnv';
import EmpiriowissenGuideHeader from '../empiriowissen/SubPageHeaderH2';
class EmpiriowissenGuide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: 'false',
            title: '',
            sub_title: '',
            dataLength: '',
            chapter_subtitle: '',
            chapter_title: ''
        }
    }
    componentWillUnmount() {
        setTimeout(() => {
            window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
            window.removeSchema();
        }, 100)
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.empiriowissenGuideContent();
        setTimeout (() => {this.accordianExpand()},2000);
    }
    accordianExpand = () => {
        var acc = document.getElementsByClassName("acco-heading");
        var i;
        for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function(event) {
            event.preventDefault();
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
            }
        });
        }
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
        if (count === -1) {
            document.querySelector('.navbar-collapse').classList.remove('show');
            document.querySelector('header').classList.remove("header_collapse");
            document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params['slug'] !== prevProps.match.params['slug']) {
            this.scrollUpFunction();
            this.empiriowissenGuideContent();
        }
    }
    empiriowissenGuideContent() {
        this.setState({ loading: 'true' })
        var headers = {
            "Content-Type": "application/json"
        }
        let data = '';
        let dataLength = '';
        let title = '';
        let sub_title = '';
        let self = this;
        let chapter_title = '';
        let chapter_subtitle = '';
        let meta_title = '';
        let meta_description = '';
        let meta_keywords = '';
        let og_title = '';
        let og_description = '';
        let og_image = '';
        let google_title = '';
        let guideId = this.props.match.params.slug;
        axios.get(API_URL + "/emperiowissen/" + guideId + "/" + LANGUAGE_CODE, { headers: headers })
            .then((response) => {
                data = response.data['success']['empiriowissen'];
                title = response.data['success']['title'];
                sub_title = response.data['success']['sub_title'];
                chapter_title = response.data['success']['empiriowissen']['title']
                chapter_subtitle = response.data['success']['empiriowissen']['title']
                google_title = response.data['success']['empiriowissen']['google_title']
                dataLength = Object.keys(data).length;
                meta_title = response.data['success']['empiriowissen']['title'] + META_TITLE_APPEND;
                meta_description = response.data['success']['empiriowissen']['metadescription'];
                meta_keywords = response.data['success']['empiriowissen']['metakeyword'];
                og_title = response.data['success']['empiriowissen']['title'] + META_TITLE_APPEND;
                og_description = response.data['success']['empiriowissen']['metadescription'];
                og_image = (response.data['success']['empiriowissen']['image']) ? response.data['success']['empiriowissen']['image'] : META_FACEBOOK['og_image'];
                window.setMeta({ "description": meta_description, "keywords": meta_keywords, "author": response.data['success']['empiriowissen']['author_name']  });
                window.setOgTag(og_title, og_description, og_image, window.location.href);
                window.setDocumentTitle(meta_title)
                var schema2 = window.positionCreate(BREADCRUMB_SCHEMA['empiriowissen']['schema2']['position'], BREADCRUMB_SCHEMA['empiriowissen']['schema2']['url'], BREADCRUMB_SCHEMA['empiriowissen']['schema2']['name']);
                var url = BREADCRUMB_SCHEMA['empiriowissenChapter']['schema3']['url']
                url = url + response.data['success']['empiriowissen']['slug']
                var name = response.data['success']['empiriowissen']['title']
                var schema3 = window.positionCreate(BREADCRUMB_SCHEMA['empiriowissenChapter']['schema3']['position'], url, name);
                var schemaObject = window.schemaObj(BREADCRUMB_SCHEMA['schema1'], schema2, schema3);
                window.setSchema(schemaObject);
                self.setState({ data, loading: 'false', dataLength, title, sub_title, chapter_title, chapter_subtitle, google_title })
            })
            .catch((error) => {
                self.setState({ loading: 'false', data, dataLength, title, sub_title, chapter_title, chapter_subtitle, google_title });
            })
    }
    render() {
        let data = this.state.data;
        let prev = '';
        let next = '';
        let sub_title = '';
        let next_chapter_title = '';
        let prev_chapter_title = '';
        let next_to_next = '';
        let next_to_next_chapter_title = '';
        let backImage_prev = '';
        let backImage_next_to_next = '';
        let backImage_next = '';
        if (this.state.dataLength > 0) {
            if (this.state.data.chapter_next_previous['next_chapter'] === '') {
                next = "/mein-bereich"
            }
            else {
                next = this.state.data.chapter_next_previous['next_chapter']['slug'];
                next_chapter_title = this.state.data.chapter_next_previous['next_chapter']['title'];
                if (this.state.data.chapter_next_previous['next_chapter']['image'] && this.state.data.chapter_next_previous['next_chapter']['image'] !== null) {
                    backImage_next = this.state.data.chapter_next_previous['next_chapter']['image'];
                }
                else {
                    backImage_next = IMAGE_BASEURL + "img/100x100.jpg"
                }
            }
            if (this.state.data.chapter_next_previous['previous_chapter'] !== '') {
                prev = this.state.data.chapter_next_previous['previous_chapter']['slug'];
                prev_chapter_title = this.state.data.chapter_next_previous['previous_chapter']['title'];
                if (this.state.data.chapter_next_previous['previous_chapter']['image'] && this.state.data.chapter_next_previous['previous_chapter']['image'] !== null) {
                    backImage_prev = this.state.data.chapter_next_previous['previous_chapter']['image'];
                }
                else {
                    backImage_prev = IMAGE_BASEURL + "img/100x100.jpg"
                }
            }
            if (this.state.data.chapter_next_previous['next_to_next_chapter'] !== '' && this.state.data.chapter_next_previous['next_to_next_chapter']) {
                next_to_next = this.state.data.chapter_next_previous['next_to_next_chapter']['slug'];
                next_to_next_chapter_title = this.state.data.chapter_next_previous['next_to_next_chapter']['title'];
                if (this.state.data.chapter_next_previous['next_to_next_chapter']['image'] !== null && this.state.data.chapter_next_previous['next_to_next_chapter']['image']) {
                    backImage_next_to_next = this.state.data.chapter_next_previous['next_to_next_chapter']['image'];
                }
                else {
                    backImage_next_to_next = IMAGE_BASEURL + "img/100x100.jpg"
                }
            }
        }
        var created_date = "";
        if (this.state.data.length > 0 || Object.keys(this.state.data).length > 0) {
            created_date = data['created_date'].split("-");
            created_date = created_date[2] + "." + created_date[1] + "." + created_date[0].substring(0, 4);
        }
        if (data.chapter && data.chapter !== "" && data.chapter !== null && data.chapter !== 0) {
            sub_title = EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['chapter'] + " " + data.chapter;
        }
        return (
            <main>
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {/* <!-- banner text ---> */}
                <EmpiriowissenGuideHeader data={this.state.data} title={this.state.chapter_title} sub_title={sub_title} dataLength={this.state.dataLength} />
                {/* <!-- banner-text-end --> */}
                {/* <!-- --> */}
                {(this.state.dataLength > 0 && this.state.loading === 'false') ?
                    <section className="start-new-chapter">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="text-dark font-weight-bold">{(this.state.google_title && this.state.google_title !== '' && this.state.google_title !== null) ? this.state.google_title : this.state.chapter_title}</h2>
                                    <p className="font-18 text-dark font-weight-normal">{EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['published_on']} {(created_date) ? created_date : ""}</p>
                                </div>
                            </div>
                            <div>
                                <div className="fundamental-text" dangerouslySetInnerHTML={{ __html: data.description }}></div>
                            </div>
                            <div className="row previous-next-link-section">
                                <div className="col-6 col-md-6 text-md-left back to-start text-center">
                                    <Link to={"/" + URL_CONSTANT['empiriowissen'] + "/" + prev} className={(prev === '' && window.innerWidth <= 320 ) ? "btn text-uppercase text-white font-10 bg-dark-gray float-left" : (prev === '' && window.innerWidth > 320) ? "btn text-uppercase text-white font-18 bg-dark-gray float-left text-nowrap":"btn text-uppercase text-white font-18 bg-dark-gray float-left custom-wissen-button"} title={(prev === '') ? EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['back_to_the_start'] : EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['previous_chapter']}>{(prev === '') ? EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['back_to_the_start'] : EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['previous_chapter']}</Link>
                                </div>
                                <div className="col-6 col-md-6 text-md-right next-chapter text-center">
                                    <Link to={(this.state.data.chapter_next_previous['next_chapter'] === '') ? next : "/" + URL_CONSTANT['empiriowissen'] + "/" + next} className={((this.state.data.chapter_next_previous['next_chapter'] === '' && window.innerWidth <= 320)) ? "btn btn-clr text-uppercase text-white font-10 float-right text-nowrap" : (this.state.data.chapter_next_previous['next_chapter'] === '' && window.innerWidth > 320)? "btn btn-clr text-uppercase text-white font-18 float-right text-nowrap" :"btn btn-clr text-uppercase text-white font-18 float-right custom-wissen-button"} title={(this.state.data.chapter_next_previous['next_chapter'] === '') ? EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['find_participants'] : EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['next_chapter']}>{(this.state.data.chapter_next_previous['next_chapter'] === '') ? EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['find_participants'] : EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['next_chapter']}</Link>
                                </div>
                            </div>
                            {((this.state.data.chapter_next_previous['next_chapter'] !== "" && this.state.data.chapter_next_previous['previous_chapter'] !== "") || (this.state.data.chapter_next_previous['next_chapter'] !== "" && this.state.data.chapter_next_previous['previous_chapter'] === "") || (this.state.data.chapter_next_previous['next_chapter'] === "" && this.state.data.chapter_next_previous['previous_chapter'] !== "")) ?
                                <React.Fragment>
                                    <div className="row text-center previous-next-link-section">
                                        <div className="col-12">
                                            <h2 className="text-dark font-weight-bold">{EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['empiriowissen_card_title']}</h2>
                                        </div>
                                    </div>
                                    <div className="row previous-next-link-section">
                                        <div className="col-12 col-md-12">
                                            <div className="blog-lising">
                                                <div className="row justify-content-center">
                                                    { (prev_chapter_title && prev && prev_chapter_title !== '' && prev_chapter_title !== null) ?
                                                        <div className="col-md-6 col-lg-4 col-xl-4" >
                                                            <div className="blog-item">
                                                                <Link to={"/" + URL_CONSTANT['empiriowissen'] + "/" + prev}>
                                                                    <div className="blog-img position-relative h-220 background-image-css" style={{ "backgroundImage": "url(" + backImage_prev + ")" }}>
                                                                    </div>
                                                                    <div className="blog-content">
                                                                        <h6 className={(prev_chapter_title) ? "text-dark font-16 custom-test-text" : "text-dark font-16 text-hyphens"}>{prev_chapter_title}</h6>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        : null
                                                    }
                                                    { (next_chapter_title && next && next_chapter_title !== '' && next_chapter_title !== null) ?
                                                        <div className="col-md-6 col-lg-4 col-xl-4" >
                                                            <div className="blog-item">
                                                                <Link to={"/" + URL_CONSTANT['empiriowissen'] + "/" + next}>
                                                                    <div className="blog-img position-relative h-220 background-image-css" style={{ "backgroundImage": "url(" + backImage_next + ")" }}>
                                                                    </div>
                                                                    <div className="blog-content">
                                                                        <h6 className={(next_chapter_title) ? "text-dark font-16 custom-test-text" : "text-dark font-16 text-hyphens"}>{next_chapter_title}</h6>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        : null
                                                    }
                                                    { (next_to_next_chapter_title && next_to_next && next_to_next_chapter_title !== '' && next_to_next_chapter_title !== null) ?
                                                        <div className="col-md-6 col-lg-4 col-xl-4" >
                                                            <div className="blog-item">
                                                                <Link to={"/" + URL_CONSTANT['empiriowissen'] + "/" + next_to_next}>
                                                                    <div className="blog-img position-relative h-220 background-image-css" style={{ "backgroundImage": "url(" + backImage_next_to_next + ")" }}>
                                                                    </div>
                                                                    <div className="blog-content">
                                                                        <h6 className={(next_to_next_chapter_title) ? "text-dark font-16 custom-test-text" : "text-dark font-16 text-hyphens"}>{next_to_next_chapter_title}</h6>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                                : null
                            }
                        </div>
                    </section>
                    : null
                }
                {/* <!-- end--> */}
            </main>
        );
    }
}
export default EmpiriowissenGuide;