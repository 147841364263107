import React from 'react';
import { SURVEY_APPROVAL_QUESTION_ANSWER } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
import Button from '@material-ui/core/Button';
class SurveyRatingWithStaticQueation extends React.Component {
    labelOnClick = (e) => {
        if (e.currentTarget.name) {
            document.getElementById(e.currentTarget.name).click();
            document.getElementById(e.currentTarget.name).checked = !(document.getElementById(e.currentTarget.name).checked);
        }
    }
    render() {
        let data = this.props.questiondata;
        let survey_data = JSON.parse(localStorage.getItem("surveydetail_" + data.survey_id))
        let englishbuttonlabels;
        englishbuttonlabels = survey_data.englishbuttonlabels == "1" ? "EN" : "DE"
        let option = SURVEY_APPROVAL_QUESTION_ANSWER[LANGUAGE_CODE].map((option, index) => {
            return (
            <div className='mb-0 full_width_option'>
                <div className="custom-radio-btn w-100">
                    <div className="custom-control custom-radio custom-control-inline pl-0 survey-approval-min-height">
                        <button
                            type="button"
                            id={option.id}
                            className={"survey-approval-btn MuiCustom-school-grade p-0 " + ((this.props.questionanswer.free_text_answer === undefined) 
                                ? (Object.keys(this.props.questionanswer).length === 0) 
                                    ? "btn-grade box-shadow  school-grade-font rating-custom"
                                    : (this.props.counter[data.question_id] === option.title) 
                                        ? 'btn-grade-clr school-grade-font box-shadow' 
                                        : 'btn-grade school-grade-font rating-custom box-shadow'
                                : (this.props.questionanswer.free_text_answer === undefined) 
                                    ? "btn-grade box-shadow school-grade-font rating-custom"
                                    : (this.props.questionanswer.free_text_answer === option.title) 
                                        ? 'btn-grade-clr box-shadow school-grade-font' 
                                        : 'btn-grade  school-grade-font rating-custom')
                            }
                            name={data.question_id}
                            key={index}
                            value={option.title}
                            onClick={this.props.onSchoolGrade}
                            style={survey_data.survey_color? {"--school-gradian": survey_data.survey_color} : {"--school-gradian": "#00b3f4"}}
                        >
                            <Button variant="contained" color="#00b3f4" className="survey-approval-btn-in survey-approval-font-size" onClick={(e) => this.labelOnClick(e)}>
                                {SURVEY_APPROVAL_QUESTION_ANSWER[englishbuttonlabels][index]["title"]}
                            </Button>
                        </button>
                    </div>
                </div>
            </div>)
        })
        return (<div key={this.props.questiondata.question_id} className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
            <div className="text-center scool-grade">
                <div className="question-heading text-left">
                    <h3 className="text-dark font-weight-bold text-break survey-title">{data.question_text}</h3>
                    {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
                </div>
                {option}
            </div>
        </div>);
    }
}
export default SurveyRatingWithStaticQueation;