import React from "react";
import axios from "axios";
import {BUBBLE_LOADING,META_FACEBOOK,BREADCRUMB_SCHEMA,PAYMENT_CONSTANTS, MYPROFILE_ERROR_MESSAGE, MYPROFILE_MAINPROFILE_CONSTANT, BILLING_ADDRESS_ERROR,} from "../../constants/FrontEndConstant";
import { LANGUAGE_CODE, API_URL, IMAGE_BASEURL, BASEURL} from "../../constants/DotEnv";
import Header from "./Header";
import Paypal from "./paypal";
import Sepa from "./giropay";
import CheckoutForm from "./CheckoutForm";
import Payment from './Payment';
import { confirmAlert } from 'react-confirm-alert';
import Autocomplete from "react-autocomplete";
import { Redirect } from "react-router";
import StripePayment from "./StripePayment";
//import styles from "../../../css/checkout.module.css";

const homeSectionUrl = {
  768 : 'img/empirio_startseiten_grafiken_hintergrund01_content03.svg',
  375 : 'img/empirio_startseiten_grafiken_hintergrund01_content03-375.svg',
  320 : 'img/empirio_startseiten_grafiken_hintergrund01_content03-320.svg',
  'default' : 'img/empirio_startseiten_grafiken_hintergrund01_content02.svg'
}
const url = {
  1440 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-1440.svg',
  1280 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-1280.svg',
  1024 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-1024.svg',
  768 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-768.svg',
  375 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-375.svg',
  'default' : 'img/empirio_startseiten_grafiken_hintergrund01.svg'
}
class PaymentPage extends React.Component {
  constructor() {
    super();
    this.state = {
      subscription: null,
      method: null,
      homecontent: {},
      faqcontent: {},
      loading: "false",
      paypal: false,
      stripe: false,
      elements: null,
      hidden:null,
      giropay: false,
      message: "",
      showPaypal: false,
      showgiropay: false,
      showCard: false,
      nsu_name: "",
      phone: "",
      street: "",
      house_number: "",
      postalcode: "",
      postalcode_id:null,
      a: [],
      postalcodedata: [],
      postalloading: false,
      ui_id: "",
      postalCodeMatchFound: true,
      organisation: "",
      phone: "",
      street: "",
      house: "",
      postal: "",
      first_name: "",
      last_name: "",
      plan_type: '',
      empty_field_error:false,
      price: {},
      mobileDevice:'',
    };
    this.package = React.createRef();
    this.headerRef = React.createRef();
    setTimeout(() => {
      window.setNoIndex();
    }, 100)
    this.setImageVar()
  }

  setImageVar = () => {
      if(window.innerWidth <= 375) {
        this.mainImageBg = "url(" +IMAGE_BASEURL+ url[375]+")"
      }else if(window.innerWidth <= 768) {
        this.mainImageBg = "url(" +IMAGE_BASEURL+ url[768]+")"
      }else if(window.innerWidth <= 1024) {
        this.mainImageBg = "url(" +IMAGE_BASEURL+ url[1024]+")"
      }else if(window.innerWidth <= 1440){
        this.mainImageBg = "url(" +IMAGE_BASEURL+ url[1440]+")"
      }else{
        this.mainImageBg = "url(" + IMAGE_BASEURL+ url['default'] +")"
      }
  }

  componentWillUnmount() {
    window.removeNoIndex();
    setTimeout(() => {
        window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
    }, 100)
  }

  setHomeSectionVar = () => {
  if(window.innerWidth <= 320) {
      this.homeSectionImageBg = "url(" +IMAGE_BASEURL+ homeSectionUrl[320]+")"
    }else if(window.innerWidth <= 375) {
      this.homeSectionImageBg = "url(" +IMAGE_BASEURL+ homeSectionUrl[375]+")"
    }else if(window.innerWidth < 768) {
      this.homeSectionImageBg = "url(" +IMAGE_BASEURL+ homeSectionUrl[768]+")"
    }else{
      this.homeSectionImageBg = "url(" + IMAGE_BASEURL+ homeSectionUrl['default'] +")"
    }
}
  handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value },this.handleClick);
  };

  // formValidate = () => {
  //   let state = this.state;
  //   //&& state.method
  //   if(state.subscription && state.first_name && state.last_name && state.street && state.house_number && state.postalcode && state.postalCodeMatchFound)
  //   {
  //     this.setState({empty_field_error:false});
  //     return true;
  //   }
  //   else
  //   {
  //     this.setState({empty_field_error:true});
  //     // if(state.subscription == null)
  //     //   document.getElementById('package').scrollIntoView();
  //     // else
  //     //   document.getElementById('invoice_address').scrollIntoView();
  //     var formfield_error_list = document.getElementsByClassName('billing_info_error');
  //     for(var i=0; i< formfield_error_list.length; i++){
  //       formfield_error_list[i].classList.remove('d-none');
  //     }
  //     if(state.error)
  //     {
  //       var list = document.getElementsByClassName('form-control hs-input ');
  //       for(var i=0; i< list.length; i++){
  //         list[i].classList.add('field-highlight');
  //       }
  //     }
  //     return false;
  //   }
  // }

  // getPostalCode = async (event, ui) => {
  //   if (this.postalDataRequest) {
  //     clearTimeout(this.postalDataRequest);
  //   }
  //   let i = this.state.a.indexOf("postalcode");
  //   if (i > -1) {
  //     this.state.a.splice(i, 1);
  //   }
  //   this.postalcodedata = "";
  //   this.ui_id = this.state.ui_id;
  //   let value = event.target !== undefined ? event.target.value : event;
  //   const valLength = value.length;
  //   let storeitem = JSON.parse(localStorage.getItem("userdata"));
  //   let apitoken = "";
  //   let userId = "";
  //   if (storeitem !== null) {
  //     apitoken = storeitem.token;
  //     userId = storeitem.id;
  //   }
  //   let headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + apitoken,
  //   };
  //   let self = this;
  //   if (valLength > 1 && event.target !== undefined) {
  //     this.setState({ postalloading: true });
  //     this.postalDataRequest = setTimeout(async () => {
  //       // eslint-disable-next-line
  //       await axios.get(API_URL +"/userprofile/getPostalcodedata/" +value +"/" +LANGUAGE_CODE +"?uId=" +userId,{ headers: headers })
  //         .then((response) => {
  //           this.postalcodedata = response.data.success.postalcodedata;
  //           this.postalcodedata = JSON.parse(JSON.stringify(this.postalcodedata));
  //           // eslint-disable-next-line
  //           self.setState({postalcodedata: this.postalcodedata,postalloading: false,},this.handleClick);
  //         })
  //         .catch((error) => {self.setState({ postalloading: false });});
  //     }, 400);
  //   }
  //   if (self.state.postalcodedata.length) {
  //     let postalCodeFound = false;
  //     // eslint-disable-next-line
  //     let billing_info = {}
  //     self.state.postalcodedata.map((postalData) => {
  //       if (postalData.postalcode_city === value.toString()) {
  //         postalCodeFound = true;
  //         this.setState({postalcode_id:postalData.id},this.handleClick)
  //         billing_info['postalcode_id'] = postalData.id
  //         localStorage.setItem('billing_info',JSON.stringify(billing_info))
  //       }
  //     });
  //     self.setState({
  //       postalCodeMatchFound: postalCodeFound,
  //     });
  //   } else if (valLength) {
  //     self.setState({
  //       postalCodeMatchFound: false,
  //     });
  //   }
  //   this.ui_id = ui.id ? ui.id : "";
  //   // this.setState({postalcode_id:})
  //   this.setState({ postalcode: value, ui_id: this.ui_id },this.handleClick);
  // }; 
  
  //Start 322 Restructuring Payment Popup and Stripe Integration
  // Fetches a payment intent and captures the client secret
  initialize =  async () => {
    let amount = '';
    const MONTHLY_AMOUNT = this.state.price.monthly_plan;
    const ANNUAL_AMOUNT = this.state.price.quarterly_plan;
    if(this.state.subscription === "monthly") {
      amount = MONTHLY_AMOUNT;
    }
    else if(this.state.subscription === "annual"){
      amount = ANNUAL_AMOUNT;
    }
    let elements;
    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_KEY,{locale: 'de'});
    this.setState({stripe:stripe});
    let storeitem = JSON.parse(localStorage.getItem('userdata'));
    const userId = storeitem.id;
    let apitoken = storeitem.token;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + apitoken
    };
    if(amount == '') {
      amount = ANNUAL_AMOUNT;
    }
    amount = amount.replace(",",".");
    amount = amount * 100;
    let intent = localStorage.getItem('intent');
    let clientSecret =  localStorage.getItem('clientSecret')!=null ? localStorage.getItem('clientSecret') : '' ;
  //  if(clientSecret == '') {
          try {
          const res = await axios.post(API_URL + '/payment/paymentIntent/' + userId+ '?uId=' + userId, {name:storeitem.first_name,last_name:storeitem.last_name, email:storeitem.email,amount:amount,type:'checkout',intent:intent},
          { headers: headers }).then((res) => {
            clientSecret = res.data.success.intent.client_secret;
            localStorage.setItem('clientSecret',clientSecret);
            localStorage.setItem('intent', res.data.success.intent.id);
            this.setLoading(false);
          });
          } catch (err) {
            console.log(err);
          }
  //  }
    let stripe_elements = stripe.elements({ clientSecret});
    const paymentElementOptions = {
      layout: "tabs",
      paymentMethodOrder: ["card","giropay"],
      fields: {
        billingDetails: {
            address: {
                country: 'never',
            }
        }
      }
    };

    const paymentElement = stripe_elements.create("payment", paymentElementOptions);
    paymentElement.mount("#payment-element");
    this.setState({elements:stripe_elements});
    paymentElement.on('change', this.paymentClick);
  };

  handleSubmit = async (e) => {
      e.preventDefault();
          this.setLoading(true);
          const stripe = this.state.stripe;
          localStorage.removeItem("clientSecret");
          localStorage.removeItem('intent');
          const billing_info = JSON.parse(localStorage.getItem('billing_info'))
          const payment_method = localStorage.getItem('method')
          if (payment_method && billing_info && payment_method !== billing_info['payment_method']) {
            billing_info['payment_method'] = payment_method
            localStorage.setItem('billing_info',JSON.stringify(billing_info));
          }

          let redirectURL = BASEURL + "checkout";
          let elements = this.state.elements;
          const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: redirectURL,
              payment_method_data: {
                billing_details: {
                    address: {
                        country: 'DE'
                    }
                }
              }
            },
          });

          if (error.type === "card_error" || error.type === "validation_error") {       
            this.showMessage(error.message);
          } else {
            this.showMessage("An unexpected error occurred.");
          }
          this.setLoading(false);
    };
    // Fetches the payment intent status after payment submission
      checkStatus = async () => {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }
      const stripe = this.state.stripe;
      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

      switch (paymentIntent.status) {
        case "succeeded":
          this.showMessage("Payment succeeded!");
          break;
        case "processing":
          this.showMessage("Your payment is processing.");
          console.log("Your payment is processing");
          break;
        case "requires_payment_method":
          this.showMessage("Your payment was not successful, please try again.");
          console.log("Your payment was not successful, please try again.");
          break;
        default:
          this.showMessage("Something went wrong.");
          console.log("failed");
          break;
      }
    };

  // ------- UI helpers -------

  showMessage = (messageText) => {
    const messageContainer = document.querySelector("#payment-message");

    messageContainer.classList.remove("hidden");
    messageContainer.textContent = messageText;

    setTimeout(function () {
      messageContainer.classList.add("hidden");
      messageContainer.textContent = "";
    }, 4000);
  };

  // Show a spinner on payment submission
  setLoading = (isLoading) => {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector("#submit").disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
    } else {
      document.querySelector("#submit").disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
    }
  };
  paymentClick = (event) => {
    let payment_type = '';
    if(event.value.type == 'card') {
      payment_type = 'Credit Card';
    }else if(event.value.type == 'giropay') {
      payment_type = 'Giropay';
    }
    localStorage.setItem('method',payment_type);
  }
  //End 322 Restructuring Payment Popup and Stripe Integration
  handlePlanSelection = (e) => {
    console.log("planselection");
    this.setState({subscription: e.target.id}, function () {
     //localStorage.removeItem("clientSecret");
     this.handlePaymentIntent();
     const MONTHLY_AMOUNT = this.state.price.monthly_plan;
     const ANNUAL_AMOUNT = this.state.price.quarterly_plan;
     let storeitem = JSON.parse(localStorage.getItem('userdata'));
     let billing_info = JSON.parse(localStorage.getItem('billing_info'));
     if(billing_info === null){
       billing_info = {};
       billing_info['postalcode_id'] = storeitem.postalcode_id;
     }
     let planType;
    // billing_info['organization'] = this.state.nsu_name;
     billing_info['organization'] = storeitem.organization;
     billing_info['telephone'] = storeitem.telephone;
     billing_info['street'] =storeitem.street;
     billing_info['house_number'] = storeitem.house_number;
     billing_info['first_name'] = storeitem.first_name;
     billing_info['last_name'] = storeitem.last_name;
     billing_info['payment_method'] = localStorage.getItem('method');
     //  billing_info['payment_method'] = this.state.method;
     billing_info['postalcode'] = storeitem.postalcode;
     if(this.state.subscription === "monthly"){
       planType = 0;
       billing_info['price'] = MONTHLY_AMOUNT;
       billing_info['time_period'] = PAYMENT_CONSTANTS[LANGUAGE_CODE]["package_days_30"];
       document.querySelector('.plan_annual').classList.remove('plan-border');
       document.querySelector('.plan_monthly').classList.add('plan-border');
     }else if(this.state.subscription === "annual"){
       planType = 1;
       billing_info['price'] = ANNUAL_AMOUNT;
       billing_info['time_period'] = PAYMENT_CONSTANTS[LANGUAGE_CODE]["package_days_90"];
       document.querySelector('.plan_annual').classList.add('plan-border');
       document.querySelector('.plan_monthly').classList.remove('plan-border');
     }
     localStorage.setItem('billing_info',JSON.stringify(billing_info));
    //  storeitem['organization'] = this.state.nsu_name;
    //  storeitem['telephone'] = this.state.phone;
    //  storeitem['street'] = this.state.street;
    //  storeitem['house_number'] = this.state.house_number;
    //  localStorage.setItem("userdata",JSON.stringify(storeitem));
     localStorage.setItem("plan_type", planType );
     localStorage.setItem('price', billing_info['price']);
     this.handleClick();
    });  
  }
  handlePaymentIntent = () => {
    this.initialize();
    this.checkStatus();
    document.querySelector("#payment-form");
    document.addEventListener("submit", this.handleSubmit);
  }
  handlePopup = () =>{
    this.handleClick();
    document.querySelector("#annual").click();
  }
  handleClick = () => {
    const MONTHLY_AMOUNT = this.state.price.monthly_plan;
    const ANNUAL_AMOUNT = this.state.price.quarterly_plan;
   // let storeitem = JSON.parse(localStorage.getItem('userdata'));
    let userData = JSON.parse(localStorage.getItem('userdata'));
    if(userData == null){
      return <Redirect to="/anmelden" />
    }
    let billing_info = JSON.parse(localStorage.getItem('billing_info'));
    if(billing_info === null){
      billing_info = {};
      billing_info['postalcode_id'] =userData.postalcode_id;
      billing_info['price'] = ANNUAL_AMOUNT;
    }
    // let locationName = "";
    // let locationData = this.state.postalcodedata;
    // locationData = locationData.map((locationDataNew, index) => {
    //   locationData[index].postalcode_city = locationDataNew.postalcode_city.toString();
    //   return locationData[index];
    // });
    // if (this.state.postalcode !== undefined && this.state.postalcode !== null && this.state.postalcode !== "")
    // {
    //   locationName = this.state.postalcode;
    // }
    const button = { 
      background:"#4EB4F4",
      marginTop:"10px",  
      cursor: "pointer",
      transition: "all 0.2s ease",
      boxShadow: "0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)",
      width: "100%"
    };
    const annualAmount = parseFloat(ANNUAL_AMOUNT.toString().replace(',','.')/3).toFixed(2).replace(".",",");
    let displayMobile = {display:'block'};
    if(this.state.mobileDevice == 0) {
      displayMobile = {display:'flex'};
    } 
    confirmAlert({
      overlayClassName: "payment-popup",
      customUI: ({ onClose }) => {
        return (
          <div className='container custom-ui text-left position-relative share_popup'>
            <div className="close-btn text-right">
              <button className="d-inline-block bg-white border-0 w-auto sharing-close-button" onClick={onClose}><i class="ti-close ti-close-creditcard"></i></button>
            </div>
            <div className="pl-3" style={{'word-wrap': 'break-word',width: '96%'}}>
              <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin">
              Jetzt empirio Plus Mitglied werden
              </h3>
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-7">
                    <div className="row" style={{paddingBottom:'10px'}}>
                      {/**Desktop */}
                        <div className="col-lg-12">
                           <div className="plan_annual" style={displayMobile}>
                              <div className="col-lg-6">
                                <label for="annual" className="radio-container d-flex radio_color annual pt-3" style={{cursor:"pointer"}}>
                                <span className='checkout_radiobutton_label'>
                                    <input
                                      type="radio"
                                      name="radio"
                                      style={{display:"none"}}
                                      id="annual"
                                      className="checkout_radiobutton"
                                      onClick={this.handlePlanSelection}
                                    />
                                    <svg width="15px" height="20px" viewBox="0 0 20 20">
                                        <circle cx="10" cy="10" r="9"></circle>
                                        <path d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                        <path d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                                    </svg>
                                  </span>
                                  <span role='button' className="text-nowrap font-16 mb-0 radio-button">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["package_days_90"]}</span>
                                  <span className="checkmark" />
                                </label>
                              </div>
                              <div className="col-lg-6">
                                <label for="annual" style={{cursor:"pointer",width:'100%'}} className="pt-2">
                                <p className="font-18 mb-0" style={{fontWeight:600}}>{annualAmount}€ / 30 Tage</p>
                                <p className="font-12 mb-0">{ANNUAL_AMOUNT}€ inkl. MwSt. für 90 Tage</p>
                                </label>
                              </div>
                            </div>
                        </div>                    
                    </div>
                      {/* <div className="text-center">
                        <span className={!this.state.subscription ? "billing_info_error d-none text-center" : "billing_info_error d-block text-center" } style={{color:"red"}}>{this.state.subscription ? null : (BILLING_ADDRESS_ERROR[LANGUAGE_CODE]['no_time_period_selected']) }</span>
                      </div> */}
                    <div className="row">
                      {/**desktop */}
                      <div className="col-lg-12"> 
                          <div className="plan_monthly" style={displayMobile}>
                            <div className="col-lg-6">
                              <label className="radio-container d-flex radio_color pt-3" style={{cursor:"pointer"}}>
                                <span className='checkout_radiobutton_label'>
                                  <input
                                    type="radio"
                                    name="radio"
                                    style={{display:"none"}}
                                    className="checkout_radiobutton"
                                    id="monthly"
                                    onClick={this.handlePlanSelection}
                                  />
                                  <svg width="15px" height="20px" viewBox="0 0 20 20">
                                    <circle cx="10" cy="10" r="9"></circle>
                                    <path d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                    <path d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                                  </svg>
                                </span>
                                <span className="text-nowrap font-16 mb-0 radio-button" role='button'>{PAYMENT_CONSTANTS[LANGUAGE_CODE]["package_days_30"]}</span>
                                <span className="checkmark" />
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <label for="monthly" style={{cursor:"pointer",width:'100%'}} className="pt-2">
                              <p className="font-18 mb-0" style={{fontWeight:600}}>{MONTHLY_AMOUNT}€ / 30 Tage</p>
                              <p className="font-12 mb-0">{MONTHLY_AMOUNT}€ inkl. MwSt. für 30 Tage</p>
                              </label>
                            </div>
                          </div>
                      </div>
                    </div>
                   <div className="row">
                      <ul className="col-lg-12 pt-5">
                          <li className="text-survey d-flex text-left pt-3">
                            <span className="fa fa-check mt-1 tick" />
                            <div className="mb-0">
                              <h6 className="font-16">Kein Abo - Mitgliedschaft endet automatisch</h6>
                            </div>
                          </li>
                          <li className="text-survey d-flex text-left">
                            <span className="fa fa-check mt-1 tick" />
                            <div className="mb-0">
                              <h6 className="font-16">Zusätzliche Ergebnis-Exporte (PowerPoint, Word, usw.)</h6>
                            </div>
                          </li>
                          <li className="text-survey d-flex text-left">
                            <span className="fa fa-check mt-1 tick" />
                            <div className="mb-0">
                              <h6 className="font-16">Personalisierung mit Dankeseite & Hintergrundbild</h6>
                            </div>
                          </li>
                          <li className="text-survey d-flex text-left">
                            <span className="fa fa-check mt-1 tick" />
                            <div className="mb-0">
                              <h6 className="font-16">Automatische Einwilligungserklärung</h6>
                            </div>
                          </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-5" style={{paddingLeft:'4%',paddingRight:'8px'}}>
                  {/* {this.state.subscription == "monthly" ?
                  <Paypal total={ MONTHLY_AMOUNT} goBack={this.props.history.goBack} first_name={this.state.first_name} last_name = {this.state.last_name} postalcode_id ={billing_info.postalcode_id} /> : <Paypal total={ANNUAL_AMOUNT} goBack={this.props.history.goBack} first_name={this.state.first_name} last_name = {this.state.last_name} postalcode_id ={billing_info.postalcode_id} /> } */}
                  <Paypal total={billing_info.price} goBack={this.props.history.goBack} first_name={billing_info.first_name} last_name = {billing_info.last_name} postalcode_id ={billing_info.postalcode_id} />
                  <div className="hr-lines">oder</div>
                  <StripePayment amount={billing_info.price} btn_text="BEZAHLEN"></StripePayment>
                </div>
              </div>
            </div>
            {/* <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin">
              {this.state.method === "paypal" ? PAYMENT_CONSTANTS[LANGUAGE_CODE]["paypal_payment"] :
                  this.state.method === "creditcard" ? PAYMENT_CONSTANTS[LANGUAGE_CODE]["creditcard_payment"] :
                    this.state.method === "giropay" ? PAYMENT_CONSTANTS[LANGUAGE_CODE]["giropay_payment"]
              : null }
            </h3> */}
            {/* {this.state.subscription === "monthly" ?
              (
                this.state.method === "creditcard" ?
                (<Payment><CheckoutForm total={MONTHLY_AMOUNT} goBack={this.props.history.goBack} first_name={this.state.first_name} last_name = {this.state.last_name} postalcode_id ={billing_info.postalcode_id}/></Payment>) :
                  (this.state.method === "paypal" ? <Paypal total={ MONTHLY_AMOUNT} goBack={this.props.history.goBack} first_name={this.state.first_name} last_name = {this.state.last_name} postalcode_id ={billing_info.postalcode_id} /> :
                    this.state.method === "giropay" ? <Payment><Sepa goBack={this.props.history.goBack} total={ MONTHLY_AMOUNT} first_name={this.state.first_name} last_name = {this.state.last_name} postalcode_id ={billing_info.postalcode_id} /></Payment>
                  : null)
              ) : this.state.subscription === "annual" ?
              (
                this.state.method === "creditcard" ?
                (<Payment><CheckoutForm total={ ANNUAL_AMOUNT} goBack={this.props.history.goBack} first_name={this.state.first_name} last_name = {this.state.last_name} postalcode_id ={billing_info.postalcode_id}/></Payment>) :
                  (this.state.method === "paypal" ? <Paypal total={ANNUAL_AMOUNT} goBack={this.props.history.goBack} first_name={this.state.first_name} last_name = {this.state.last_name} postalcode_id ={billing_info.postalcode_id} /> :
                    this.state.method === "giropay" ? <Payment><Sepa goBack={this.props.history.goBack} total={ANNUAL_AMOUNT} first_name={this.state.first_name} last_name = {this.state.last_name} postalcode_id ={billing_info.postalcode_id} /></Payment>
                  : null)
              )
            : null} */}
          </div>
        );
      }
    });
  };

  componentWillUnmount() {
    setTimeout(() => {
      window.setOgTag(
        META_FACEBOOK["og_title"],
        META_FACEBOOK["og_description"],
        META_FACEBOOK["og_image"],
        META_FACEBOOK["og_url"]
      );
      window.removeFaqItemtype();
      window.removeSchema();
    }, 100);
  }
  componentDidMount() {
    this.setState({mobileDevice: 0 });
    //this.setState({desktopDevice: 0 });
    if (window.innerWidth >= 320 && window.innerWidth <= 575) {
      //mobileDevice = {display:'none'};
      this.setState({mobileDevice: 1});
    }
    // } else {
    //   //desktopDevice =  {display:'none'};
    //   this.setState({desktopDevice: 1});
    // }
    this.getUserData();
    this.scrollUpFunction();
    this.stripePaymentHandle();
    this.setHomeSectionVar()
    this.getPrice();
    this.setState({subscription:'annual'});
    // Added paypal script tag
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_KEY}&components=buttons&currency=EUR&disable-funding=card,sofort,giropay,sepa&vault=true`;
    document.body.appendChild(script);

    //Add stripe script tag
    const stripeScript = document.createElement("script");
    stripeScript.src = 'https://js.stripe.com/v3/';
    document.body.appendChild(stripeScript);
    
    setTimeout(() => {
      var schemaObject = window.schemaObj(
        BREADCRUMB_SCHEMA["schema1"],
        BREADCRUMB_SCHEMA["main"]["schema2"],
        BREADCRUMB_SCHEMA["main"]["schema3"]
      );
      window.setSchema(schemaObject);
    }, 100);
  }
  getPrice(){
    const storeitem = JSON.parse(localStorage.getItem('userdata'));
    if(storeitem){
      const userId = storeitem.id;
      let apitoken = storeitem.token;
      const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + apitoken
      };
      const res = axios.get(API_URL + '/common/getPricesForPlan/' +userId+ '?uId=' + userId, {header: headers}).then((res) => {
        this.setState({price : res.data.success.priceforplan})
      })
    }
  }
  stripePaymentHandle(){
    const storeitem = JSON.parse(localStorage.getItem('userdata'));
    let url = new URL(window.location);
    let params = new URLSearchParams(url.search);
      if(storeitem){
      const userId = storeitem.id;
      let apitoken = storeitem.token;
      const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + apitoken
      };
      if(params.get('redirect_status') == "succeeded"){
        localStorage.setItem('payment_status',1);
        //const price = localStorage.getItem('price');
        let transaction_id = params.get('payment_intent');
        const method = localStorage.getItem('method');
        let planType = localStorage.getItem("plan_type");
        let billing_info = JSON.parse(localStorage.getItem('billing_info'));
          billing_info['payment_method'] = method;
          const today = Date.now();
          let date = new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
          let finalDate = date.replace(',', '').replace('P', '').replace('M', '');
          date = finalDate.split("/").join("-");
          const res = axios.post(API_URL + '/payment/savePlanInfo/' + userId + '?uId=' + userId, { first_name: billing_info.first_name, last_name: billing_info.last_name, postalcode_id: billing_info.postalcode_id, price: billing_info.price, plan_type: planType, transaction_id: transaction_id, start_date: date, organization: billing_info.organization, telephone: billing_info.telephone, street: billing_info.street, house_number: billing_info.house_number, payment_method: method },
            { headers: headers }).then((res) => {
              localStorage.removeItem('price');
              storeitem['plan_type'] = planType.toString();
              localStorage.setItem("userdata", JSON.stringify(storeitem));
              //if(res.data.success){
              localStorage.setItem('transaction_id', transaction_id);
              let redirectURL = localStorage.getItem('redirectURL') != null ? localStorage.getItem('redirectURL') : BASEURL + "meine-umfrage";
              window.location = redirectURL;
            //}
        });
      }
      if(params.get('redirect_status') == "failed"){
         localStorage.setItem('payment_status',0);
         localStorage.removeItem('price');
         localStorage.removeItem('plan_type');
         window.location="/checkout";
     }
    }
  }
  getUserData = () => {
    const MONTHLY_AMOUNT = this.state.price.monthly_plan;
    const ANNUAL_AMOUNT = this.state.price.quarterly_plan;
    const userdata = JSON.parse(localStorage.getItem('userdata'));
    const billing_info = JSON.parse(localStorage.getItem('billing_info'));
    {
      if(userdata){
        this.setState({
          postalcode: userdata.postalcode,
          postalcode_id: userdata.postalcode_id,
          first_name: userdata.first_name,
          last_name: userdata.last_name,
          plan_type: userdata.plan_type
        });
      }
      if(billing_info){
        if(billing_info.price == MONTHLY_AMOUNT){
          this.setState({subscription : "monthly"})
        }else if(billing_info.price == ANNUAL_AMOUNT){
          this.setState({subscription : "annual"})
        }
        this.setState({
          postalcode: billing_info.postalcode,
          first_name: billing_info.first_name,
          last_name: billing_info.last_name,
          plan_type: billing_info.plan_type,
          street: billing_info.street,
          house_number: billing_info.house_number,
        });
      }
    }
  };

  scrollUpFunction = () => {
    /**for scrolling up page */
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  render = () => {
    const MONTHLY_AMOUNT = this.state.price.monthly_plan;
    const ANNUAL_AMOUNT = this.state.price.quarterly_plan;
    let userData = JSON.parse(localStorage.getItem('userdata'));
    if(userData == null){
      return <Redirect to="/anmelden" />
    }
    // let locationName = "";
    // let locationData = this.state.postalcodedata;
    // locationData = locationData.map((locationDataNew, index) => {
    //   locationData[index].postalcode_city = locationDataNew.postalcode_city.toString();
    //   return locationData[index];
    // });
    // if (this.state.postalcode !== undefined && this.state.postalcode !== null && this.state.postalcode !== "")
    // {
    //   locationName = this.state.postalcode;
    // }
    let span = {
      color: "red",
    };
    return (
      <>
        {/* header  */}
        <Header goBack={this.props.history.goBack} />
        <main >
          {this.state.loading === "true" ? BUBBLE_LOADING : null}
          <div>
            {/*   Home Banner  */}
            <section
              ref={this.headerRef}
              id="home"
              className="home-section home-banner home-banner-changed nsu-home-banner-changed theme-main-changed theme-banner mb-0"
              style={{background: "#2d3e50"}}
            >
              <div className="home-effect-bg home-effect-bg-img d-md-block" style={{"--main-img" : this.mainImageBg}}/>
              <div className="container container-large">
                <div className="row p-100px-tb p-100px-tb-changed sm-p-30px-b pb-0">
                  <div
                    className={
                      window.innerWidth >= 1200
                        ? "col-12 col-md-12 col-lg-3 col-xl-3 md-m-30px-t col-lg-banner-text text-center em_left"
                        : "col-12 col-md-12 col-lg-3 col-xl-3 md-m-30px-t text-right em_left"
                    }
                  >
                    <img
                      src={IMAGE_BASEURL + "img/checkout_header.svg"}
                      title="img"
                      id="payment-img"
                    />
                  </div>
                  <div className="col-12 col-lg-6 col-xl-6 text-center em_center em-center-padding">
                    <h1 className="text-white font-weight-bold mb-0 heading-padding">
                      {PAYMENT_CONSTANTS[LANGUAGE_CODE]["nsu_heading"]}
                    </h1>
                    <h4 className="text-white my-4">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["nsu_subheading"]}</h4>
                    <div className="payment-home-section col-xl-9 mb-5 mx-auto p-3 p-md-5 box-shadow bg-white">
                      <div className="border-0 w-100">
                        <h5 className="card-title text-center font-weight-bold text-dark">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["popup_main_heading"]}</h5>
                        <ul className="paket-list p-2">
                          <li className="text-gray text-survey d-flex text-left">
                            <span className="fa fa-check mt-1 tick" />
                            <div className="mb-0">
                              <h6 className="text-dark Bullet_point_heading">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["popup_text1"]}</h6>
                              <p className="text-dark mb-2 Bullet_point_sub_text">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["popup_description1"]}</p>
                            </div>
                          </li>
                          {/* <li className="text-gray text-survey d-flex text-left">
                            <span className="fa fa-check mt-1 tick" />
                            <div className="mb-0">
                              <h6 className="text-dark Bullet_point_heading">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["popup_text2"]}</h6>
                              <p className="text-dark mb-2 Bullet_point_sub_text">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["popup_description2"]}</p>
                            </div>
                          </li> */}
                          <li className="text-gray text-survey d-flex text-left">
                            <span className="fa fa-check mt-1 tick" />
                            <div className="mb-0">
                              <h6 className="text-dark Bullet_point_heading">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["popup_text3"]}</h6>
                              <p className="text-dark mb-2 Bullet_point_sub_text">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["popup_description3"]}</p>
                            </div>
                          </li>
                          <li className="text-gray text-survey d-flex text-left">
                            <span className="fa fa-check mt-1 tick" />
                            <div className="mb-0">
                              <h6 className="text-dark Bullet_point_heading">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["popup_text4"]}</h6>
                              <p className="text-dark mb-2 Bullet_point_sub_text">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["popup_description4"]}</p>
                            </div>
                          </li>
                          <li className="text-gray text-survey d-flex text-left">
                            <span className="fa fa-check mt-1 tick" />
                            <div className="mb-0">
                              <h6 className="text-dark Bullet_point_heading">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["popup_text5"]}</h6>
                              <p className="text-dark mb-2 Bullet_point_sub_text">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["popup_description5"]}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <button
                        // onClick={() => { this.package.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); }}
                        onClick={this.handlePopup}
                        className="btn btn-clr text-white font-18 p-"
                        style={{ whiteSpace: "normal"}}
                        title={PAYMENT_CONSTANTS[LANGUAGE_CODE]["btn_text"]}
                      >
                        {PAYMENT_CONSTANTS[LANGUAGE_CODE]["btn_text"]}
                      </button>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-md-row justify-content-between align-items-center w-100 mb-5 mt-md-5" style={{ order: '3' }}>
                    <div className="text-white font-20 font-weight-bold my-2">
                      <i className="ti-lock"></i>
                      <p className="px-2">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["secure_payment"]}</p>
                    </div>
                    <div className="text-white font-20 font-weight-bold my-2">
                      <i className="ti-lock"></i>
                      <p className="px-2">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["ssl_encrypted"]}</p>
                    </div>
                    <div className="text-white font-20 font-weight-bold my-2">
                      <i className="ti-lock"></i>
                      <p className="px-2">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["compliant"]}</p>
                    </div>
                  </div>
                </div>
                {/*   row    */}
              </div>
            </section>
            {/* section-info  */}
            <section id="info" className="bg-white info" >
              <div className="container">
                <div className="section-title text-center">
                  <h2 className="font-weight-bold text-dark">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["section1_heading"]}</h2>
                </div>
                <p className="text-dark text-center px-md-5">
                {PAYMENT_CONSTANTS[LANGUAGE_CODE]["section1_description1"]} <i>{PAYMENT_CONSTANTS[LANGUAGE_CODE]["section1_description3"]}</i> {PAYMENT_CONSTANTS[LANGUAGE_CODE]["section1_description2"]}
                </p>
              </div>
            </section>
            {/* section-example */}
            <section id="example" className="content-left content-left-bg-img home-section home-content-left" style={{"--home-section" : this.homeSectionImageBg}}>
              <div className="container">
                <div className="row align-items-center screen-height-checkpouPop  sm-p-30px-b pt-0">
                  <div className="col-12 " >
                    <div className="row">
                      <div className="col-md-4">
                        <img
                          src={IMAGE_BASEURL + "img/Startseite_04.svg"}
                          className="img-fluid example-img "
                          id="example-img"
                        />
                      </div>
                      <div className="col-md-8">
                        <div className="section-title">
                          <h2 className="font-weight-bold text-dark" style={{padding: "0px 15px"}}>{PAYMENT_CONSTANTS[LANGUAGE_CODE]["section2_heading"]}</h2>
                        </div>
                        <p className="text-dark" style={{padding: "0px 15px"}}>
                        {PAYMENT_CONSTANTS[LANGUAGE_CODE]["section2_description"]}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                  <div ref={this.package}></div>
              </div>
            </section>
            {/* packages  */}
            <section  className="bg-white" id='package'>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                     <div className="row">
                      <div className="col-12 d-flex align-items-center justify-content-center">
                        <button  onClick={this.handlePopup} className="btn btn-clr text-white font-18 p-3 " title={PAYMENT_CONSTANTS[LANGUAGE_CODE]["btn_text"] } >{PAYMENT_CONSTANTS[LANGUAGE_CODE]["btn_text"] }
                        </button>
                      </div>
                      <p className="p-2 w-100 text-center "><i className="ti-lock pr-2"></i>{PAYMENT_CONSTANTS[LANGUAGE_CODE]["secure_booking"]}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </>
    );
  };
}

export default PaymentPage;
