import React from "react";
import axios from 'axios';
import GoogleChart from '../../GoogleChart/GoogleChart';
import GoogleChartDemographic from '../../GoogleChart/GoogleChartDemographic';
import ParticipantsList from "./ParticipantsList";
import { MYPROFILE_SETTINGS_CONSTANT, SURVEY_ANALYSIS, BUBBLE_LOADING, SURVEY_PARTICIPATION } from '../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL } from '../../../constants/DotEnv';
import { Redirect,Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import CountUp from 'react-countup';
import download from 'downloadjs';
import FileSaver from 'file-saver';
import createChartDownloadLink,{getActualImageString} from "../../GoogleChart/createChartDownloadLink";
import * as htmlToImage from 'html-to-image';

class Analyze extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            smallScreenDemographicList:'',
            smallScreenQuestionList:'',
            questionList: '',
            participated_user_list: '',
            surveyData: '',
            chart_loaded_once: false,
            send_chart_data: true,
            disablePDFButton: true,
            isExcelBtnDisabled: true,
            isCSVBtnDisabled: true,
            isPremiumExportBtnDisabled: true,
            showPDFloader: true,
            redirectTo404:false,
            isImageDeleted: false,
            count : 0,
            isZeroParticipants:false
        }
        this.userData = JSON.parse(localStorage.getItem("userdata"));
        this.savedImages = 0;
        this.totalGraphLength = 0;
        this.questionCount = 0;
        this.mobileDevice = 0;
        this.ImagetoSendQuestion = []
    }
    async componentDidMount() {
        let self = this;
        let usercheck = true;
        let redirectTo404 = false
        this.userId = '';
        if (this.userData) {
            this.surveyEditDataId = (this.props.match.params.surveyid) ? window.atob(this.props.match.params.surveyid) : '';
            this.userId = this.userData.id;
            this.Api_request_header = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.userData.token
            }
            let dataObject = {
                "survey_id": this.surveyEditDataId
            }
                if (this.surveyEditDataId) {
                    if (this.surveyEditDataId && this.userData.token) {
                        /* --- Get Survey Details --- */
                        await axios.get(API_URL + '/surveyresult/getSurveyBriefData/' + LANGUAGE_CODE + "/" + this.surveyEditDataId + "/" + this.userData.id + '?uId=' + this.userId, { headers: this.Api_request_header }).then((res) => {
                            if(res.data.success.userfound === 0){
                                usercheck = false;
                                redirectTo404 = true
                                // self.setState({
                                //     redirectTo404:true
                                // })
                            }
                            self.setState({
                                surveyData: res.data.success.surveybriefdata
                            })
                            let survey_color = res.data.success.surveybriefdata.survey_color
                            let color = survey_color? survey_color: '#1992E7'
                            if(res.data.success.surveybriefdata) localStorage.setItem('survey_color', color)
                        }).catch((error) => {
                            console.log(error)
                            if (error && error.response && error.response.data) {
                                let msg = error.response.data['error']
                                if (msg === 'Invalid User') {
                                    redirectTo404 = true
                                    // self.setState({ redirectTo404: true })
                                }
                            }
                        })
                        // eslint-disable-next-line
                        await axios.post(API_URL + "/surveyresult/deleteImagefiles" + '?uId=' + this.userId, dataObject, { headers: this.Api_request_header })
                        .then(()=>{
                            self.setState({
                                isImageDeleted: true
                            })
                        })
                        .catch(err => console.log(err))
                }
            }
            if(usercheck){
                // default mobile Device value is 0, if width is less than 575px value is 1.
                if (window.innerWidth >= 320 && window.innerWidth <= 575) {
                    this.mobileDevice = 1;
                }
                /* --- Get Question List for Chart Rendering --- */
                await axios.get(API_URL + "/surveyresult/getQuestiongraphdata/" + LANGUAGE_CODE + "/" + this.surveyEditDataId + '?uId=' + this.userId + '&mobileDevice=' + this.mobileDevice, { headers: this.Api_request_header }).then((res) => {
                    let qList = res.data.success.questiondata;
                    let IsSurveyHaveOnlyFreetextQuestion = res.data.success.IsSurveyHaveOnlyFreetextQuestion
                    if(IsSurveyHaveOnlyFreetextQuestion === 1){
                        self.setState({
                            showPDFloader : false,
                            disablePDFButton : true
                        })
                    }
                    // eslint-disable-next-line
                    qList.map((data, index)=>{
                        if(data.question_type_id === 1) {
                            if(!data.tagcloud.length) {
                                res.data.success.questiondata.splice(index,1);
                            }
                        }
                    })
                    self.setState({
                        questionList: res.data.success.questiondata,
                        demographicList: res.data.success.demographic,
                    })
                }).catch((error) => {
                    console.log(error)
                    if (error && error.response && error.response.data) {
                        let msg = error.response.data['error']
                        if (msg === 'Invalid User') {
                            // self.setState({ redirectTo404: true })
                            redirectTo404 = true
                        }
                    }
                })
                 /*--Get normal question data---*/
                 await axios.get(API_URL + "/surveyresult/getQuestiongraphdata/" + LANGUAGE_CODE + "/" + this.surveyEditDataId + '?uId=' + this.userId + '&mobileDevice=0', { headers: this.Api_request_header }).then((res) => {
                    let qList = res.data.success.questiondata;
                    let IsSurveyHaveOnlyFreetextQuestion = res.data.success.IsSurveyHaveOnlyFreetextQuestion
                    if(IsSurveyHaveOnlyFreetextQuestion === 1){
                      self.setState({
                          disablePDFButton : true,
                          showPDFloader : false
                      })
                  }
                    // eslint-disable-next-line
                    qList.map((data, index)=>{
                        if(data.question_type_id === 1) {
                            if(!data.tagcloud.length) {
                                res.data.success.questiondata.splice(index,1);
                            }
                        }
                    })
                    self.setState({
                      smallScreenQuestionList: res.data.success.questiondata,
                      smallScreenDemographicList: res.data.success.demographic
                    })
                }).catch((error) => {
                    console.log(error)
                })
                /* --- Get User Request Data for All Participants --- */
                await axios.get(API_URL + '/surveyresult/getParticipantuserBySurveyId/' + LANGUAGE_CODE + '/' + this.surveyEditDataId + '?uId=' + this.userId, { headers: this.Api_request_header }).then((res) => {
                    self.setState({
                        participated_user_list: res.data.success,
                        loading: 'false',
                    })
                    if (res.data.success.userparticipated.length) {
                        this.setState({
                            isExcelBtnDisabled: false,
                            isCSVBtnDisabled: false,
                            isPremiumExportBtnDisabled:false
                        })
                    } else {
                        self.setState({ showPDFloader: false })
                        self.setState({ isZeroParticipants: true })
                    }
                }).catch((error) => {
                    console.log(error)
                    if (error && error.response && error.response.data) {
                        let msg = error.response.data['error']
                        if (msg === 'Invalid User') {
                            // self.setState({ redirectTo404: true })
                            redirectTo404 = true
                        }
                    }
                    self.setState({
                        loading: 'false'
                    })
                })
               
                self.savedImages = 0;
                if(this.state.demographicList && this.state.demographicList.length) {
                    this.totalGraphLength = this.state.demographicList.length;
                    this.questionCount = this.totalGraphLength;
                }
                if(this.state.questionList && this.state.questionList.length) {
                    this.generatePngImages()
                    // eslint-disable-next-line
                    this.state.questionList.map((data,index)=>{
                        if(data.question_type_id === 1 && !data.tagcloud.length) {
                            // eslint-disable-next-line
                            return;
                        }
                        if (data.question_type_id !== 1) {
                            this.questionCount = this.questionCount + 1;
                        }
                        this.totalGraphLength = this.totalGraphLength + 1;
                    });
                }
            }
        }
        self.setState({redirectTo404 : redirectTo404})
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isDrawChart !== this.props.isDrawChart) {
            this.setState({
                chart_loaded_once: true
            })
        }
    }
    loadGoogleChart = () => {
        if (this.state.questionList) {
            let questions = this.state.questionList;
            return questions.questiondata.map((question, index) => (
                <GoogleChart chartData={question} key={index} elementNo={index} chart_loaded_once={this.state.chart_loaded_once} smallScreenQuestionList={this.state.smallScreenQuestionList['questiondata'][index]} chartColor={this.state.surveyData.survey_color}/>
            ))
        }
        else {
            return null;
        }
    }
    /* --- Create Anchor Tag with Link remove it once File is downloaded --- */
    createAnchorforDownload(file_path,file_name='') {
        var ua = window.navigator.userAgent;
        if (navigator.msSaveBlob) {
            this.downloadFileIE(file_path,file_name);
        }
        else if (ua.match(/iPad/i) || ua.match(/iPhone/i)) {
            if (!navigator.userAgent.match('CriOS') || ua.match(/iPhone/i)) {
                const myBlobContent = file_path.split(",")[1];
                let myBlob = new Blob([this.safariExportFile(myBlobContent, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
                FileSaver.saveAs(myBlob, file_name);
            }
            else {
                let anchor_tag = document.createElement("a");
                anchor_tag.href = file_path;
                anchor_tag.download = file_name? file_name : file_path.split("/")[1];
                anchor_tag.className = "d-none";
                anchor_tag.id = "excel_link";
                if (file_path.split(".")[1] === "pdf")
                    anchor_tag.target = "_blank"
                document.querySelector("body").insertBefore(anchor_tag, null);
                setTimeout(() => {
                    document.getElementById("excel_link").click();
                    document.getElementById("excel_link").remove();
                }, 500)
            }
        }
        else {
            let anchor_tag = document.createElement("a");
            anchor_tag.href = file_path;
            anchor_tag.download = file_name? file_name : file_path.split("/")[1];
            anchor_tag.className = "d-none";
            anchor_tag.id = "excel_link";
            if (file_path.split(".")[1] === "pdf")
                anchor_tag.target = "_blank"
            document.querySelector("body").insertBefore(anchor_tag, null);
            setTimeout(() => {
                document.getElementById("excel_link").click();
                document.getElementById("excel_link").remove();
            }, 500)
        }
    }
    // Export a Excel and CSV file in safari.
    safariExportFile(base64Data, contentType) {
        contentType = contentType || '';
        let size = 1024;
        let charactersData = atob(base64Data);
        let byteLength = charactersData.length;
        let count = Math.ceil(byteLength / size);
        let base64DataBytesArrays = new Array(count);
        for (let index = 0; index < count; ++index) {
            let start = index * size;
            let end = Math.min(start + size, byteLength);
            let arrayLength = end - start;
            let blobBytes = new Array(arrayLength);
            var i=0;
            for (var offset = start; offset < end; ++offset) {
                blobBytes[i] = charactersData[offset].charCodeAt(0);
                ++i;
            }
            base64DataBytesArrays[index] = new Uint8Array(blobBytes);
        }
        return new Blob(base64DataBytesArrays, { type: contentType });
    }
    // download a Excel, CSV and PNG file in Internet Explorer.
    downloadFileIE(file_path,file_name='') {
        // IE 10+
        let filepath = file_name.split(";")[0];
        const b64Data = atob(file_path.split(",")[1]);
        var arraybuffer = new ArrayBuffer(b64Data.length);
        var getunitArray = new Uint8Array(arraybuffer);
        for (var i = 0; i < b64Data.length; i++) {
            getunitArray[i] = b64Data.charCodeAt(i);
        }
        navigator.msSaveBlob(new Blob([arraybuffer], { type: 'text/'+ filepath.split(".")[1] }), file_name);
    }
    downloadPdf(file_path, file_name='') {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        var url = file_path;
        var fileName = (file_name) ? file_name : file_path.substring(file_path.lastIndexOf('/') + 1);
        // eslint-disable-next-line
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            var _window = window.open(url, '_blank');
            _window.document.close();
            _window.document.execCommand('SaveAs', true, fileName)
            _window.close();
        }
        else {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            xhr.responseType = "blob";
            xhr.onload = function () {
                var urlCreator = window.URL || window.webkitURL;
                var imageUrl = urlCreator.createObjectURL(this.response);
                var tag = document.createElement('a');
                tag.href = imageUrl;
                tag.download = fileName;
                document.body.appendChild(tag);
                tag.click();
                document.body.removeChild(tag);
            }
            xhr.send();
        }
    }
    requestExcel = async () => {
        let self = this;
        this.setState({
            loading: 'true'
        })
        if (this.surveyEditDataId) {
            if (this.surveyEditDataId && this.userData.token) {
                await axios.get(API_URL + '/surveyresult/generateExcel/' + LANGUAGE_CODE + '/' + this.surveyEditDataId + '?uId=' + this.userId, { headers: this.Api_request_header }).then((res) => {
                    this.createAnchorforDownload("data:" + res.data.file_type + ";base64," + res.data.success, res.data.file_name);
                    self.setState({
                        loading: 'false'
                    })
                }).catch((err) => {
                    console.log(err);
                    self.setState({
                        loading: 'false'
                    })
                })
            }
        }
    }
    requestWord = async () => {
        let self = this;
        this.setState({
            loading: 'true'
        })
        if (this.surveyEditDataId) {
            if (this.surveyEditDataId && this.userData.token) {
                await axios.get(API_URL + '/surveyresult/SurveyQuestionsAsWord/' + LANGUAGE_CODE + '/' + this.surveyEditDataId + '/'+this.userId + '?uId=' + this.userId, { headers: this.Api_request_header }).then((res) => {
                    this.createAnchorforDownload("data:" + res.data.file_type + ";base64," + res.data.success, res.data.file_name);
                    self.setState({
                        loading: 'false'
                    })
                }).catch((err) => {
                    console.log(err);
                    self.setState({
                        loading: 'false'
                    })
                })
            }
        }
    }
    requestWordDiagram = async () => {
        let self = this;
        this.setState({
            loading: 'true'
        })
        if (this.surveyEditDataId) {
            if (this.surveyEditDataId && this.userData.token) {
                await axios.get(API_URL + '/surveyresult/SurveyResultChartsAsWord/' + LANGUAGE_CODE + '/' + this.surveyEditDataId + '/'+this.userId + '?uId=' + this.userId, { headers: this.Api_request_header }).then((res) => {
                    this.createAnchorforDownload("data:" + res.data.file_type + ";base64," + res.data.success, res.data.file_name);
                    self.setState({
                        loading: 'false'
                    })
                }).catch((err) => {
                    console.log(err);
                    self.setState({
                        loading: 'false'
                    })
                })
            }
        }
    }
    requestPowerpoint = async () => {
        let self = this;
        this.setState({
            loading: 'true'
        })
        if (this.surveyEditDataId) {
            if (this.surveyEditDataId && this.userData.token) {
                await axios.get(API_URL + '/surveyresult/SurveyResultChartsAsPowerpoint/' + LANGUAGE_CODE + '/' + this.surveyEditDataId + '/'+this.userId + '?uId=' + this.userId, { headers: this.Api_request_header }).then((res) => {
                    this.createAnchorforDownload("data:" + res.data.file_type + ";base64," + res.data.success, res.data.file_name);
                    self.setState({
                        loading: 'false'
                    })
                }).catch((err) => {
                    console.log(err);
                    self.setState({
                        loading: 'false'
                    })
                })
            }
        }
    }
    requestExcelforPlusPackage = async () => {
        let self = this;
        this.setState({
            loading: 'true'
        })
        if (this.surveyEditDataId) {
            if (this.surveyEditDataId && this.userData.token) {
                await axios.get(API_URL + '/surveyresult/SurveyResultAnalysisAsExcel/' + LANGUAGE_CODE + '/' + this.surveyEditDataId + '/'+this.userId + '?uId=' + this.userId, { headers: this.Api_request_header }).then((res) => {
                    this.createAnchorforDownload("data:" + res.data.file_type + ";base64," + res.data.success, res.data.file_name);
                    self.setState({
                        loading: 'false'
                    })
                }).catch((err) => {
                    console.log(err);
                    self.setState({
                        loading: 'false'
                    })
                })
            }
        }
    }
    requestCSV = async () => {
        let self = this;
        this.setState({
            loading: 'true'
        })
        if (this.surveyEditDataId) {
            if (this.surveyEditDataId && this.userData.token) {
                await axios.get(API_URL + '/surveyresult/generateCSV/' + LANGUAGE_CODE + '/' + this.surveyEditDataId + '?uId=' + this.userId, { headers: this.Api_request_header }).then((res) => {
                    this.createAnchorforDownload("data:" + res.data.file_type + ";base64," + res.data.success, res.data.file_name);
                    self.setState({
                        loading: 'false'
                    })
                }).catch((err) => {
                    console.log(err);
                    self.setState({
                        loading: 'false'
                    })
                })
            }
        }
    }
    requestPDF = async () => {
        let self = this;
        this.setState({
            loading: 'true'
        })
        if (this.surveyEditDataId && this.userData.token) {
            let isMobileDevice = window.innerWidth<=575 ? 1 : 0;
            await axios.get(API_URL + '/surveyresult/getPngfiles/' + LANGUAGE_CODE + '/' + this.surveyEditDataId + '/' + isMobileDevice  + '?uId=' + this.userId, { headers: this.Api_request_header }).then((res) => {
                /* --- Uncomment if need to open in same tab for Safari --- */
                var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                if (isSafari) {
                    download("data:application/" + res.data.file_type + ";base64," + res.data.success, res.data.file_name, "application/zip");
                } else {
                    //this.createAnchorforDownload(res.data.success.pdflink);
                    if (navigator.msSaveBlob) { 
                        let file_path = "data:" + res.data.file_type + ";base64," + res.data.success;
                        this.downloadFileIE(file_path, res.data.file_name);
                    } else {
                        this.downloadPdf("data:" + res.data.file_type + ";base64," + res.data.success, res.data.file_name);
                    }
                }
                self.setState({
                    loading: 'false'
                })
            }).catch((err) => {
                console.log(err);
                self.setState({
                    loading: 'false'
                })
            })
        }
    }
    confirmDeleteParticipant = (id) => {
        let user_id = id;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <div className="close-btn text-right">
                            <button className="d-inline-block bg-white border-0 w-auto" onClick={onClose}><i className="ti-close pointer"></i></button>
                        </div>
                        <p>{SURVEY_ANALYSIS[LANGUAGE_CODE]['delete_message']}</p>
                        <button name="proceed_btn" onClick={(event) => {
                            this.deleteParticipant(user_id)
                            onClose()
                        }}>{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['confirm_alert_yes_button_text']}</button>
                        <button onClick={onClose} >{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['confirm_alert_no_button_text']}</button>
                    </div>
                )
            }
        })
    }
    deleteParticipant = async (id) => {
        this.setState({
            questionList: '', loading: 'true'
        }, async () => {
            let self = this;
            if (this.surveyEditDataId) {
                if (this.surveyEditDataId && id) {
                    await axios.delete(API_URL + "/surveyresult/deleteParticipantBySurveyIdAndUserId/" + LANGUAGE_CODE + "/" + this.surveyEditDataId + "/" + id + '?uId=' + this.userId, { headers: this.Api_request_header }).then(res => {
                        let participants = this.state.participated_user_list.userparticipated;
                        // eslint-disable-next-line
                        participants.map((user, index) => {
                            if (user.user_id === id) {
                                participants.splice(index, 1);
                                self.setState({ participants, send_chart_data: true })
                            }
                        })
                        self.componentDidMount();
                    }).catch(err => {
                        console.log(err);
                    })
                }
            }
        })
    }
    saveEditedChartImage = (image_string, image_name, isLast, chartData ,data=[]) => {
        if(chartData.question_type_id === 5 || chartData.question_type_id === 7){
            this.sendImages(data, chartData);
        }else{
        this.image_meta = {};
        this.image_meta['image_string'] = image_string.replace("data:image/png;base64,", "");
        this.image_meta['image_name'] = image_name;
        this.ImagetoSendQuestion.push(this.image_meta)
        if(isLast){
            this.sendImages(this.ImagetoSendQuestion, chartData);
            this.ImagetoSendQuestion = [];
            this.image_meta = {};
        }}
    }
    saveEditedChartImageDemographic = (image_string, image_name, isLast, chartData) => {
        this.ImagetoSend = [];
        this.image_meta = {};
        this.image_meta['image_string'] = image_string.replace("data:image/png;base64,", "");
        this.image_meta['image_name'] = image_name;
        this.ImagetoSend.push(this.image_meta);
        if(isLast){
          this.sendImages(this.ImagetoSend, chartData);
          this.ImagetoSend = [];
          this.image_meta = {};
        }
    }
    //To generate question PNG images
    generateQuestionImages = () => {
        setTimeout(async() => {
            // eslint-disable-next-line
            if(this.state.questionList && this.state.smallScreenQuestionList){
              await this.state.questionList.reduce(async(prev, chart, index) => {
                  const res = await prev
                  this.image_meta = {};
                  var dataOf57 = []
              if(chart.hasOwnProperty("question_type_id") && chart.question_type_id === 1) {
                var smallScreenQuestionList = this.state.smallScreenQuestionList[index]
                let self = this;
                let img_name = chart.question_id + "_" + ((chart && chart.question_title && chart.question_title.title)
                    ? (chart.question_title.title.length > 200)
                      ? chart.question_title.title.substring(0,200).replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
                      : chart.question_title.title.replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
                    : chart.question_id + ".png");
                    img_name =  img_name.replaceAll("ä","ae");
                    img_name =  img_name.replaceAll("ü","ue");
                    img_name =  img_name.replaceAll("ö","oe");
                    img_name =  img_name.replaceAll("Ü","Ue");
                    img_name =  img_name.replaceAll("Ä","Ae");
                    img_name =  img_name.replaceAll("Ö","Oe");
                    img_name =  img_name.replaceAll("ß","ss");
                if(chart.tagcloud.length){
                  htmlToImage.toCanvas(document.getElementById("chart_div_" + index), {filter: this.filterHtmlImage, style: {'font-family':'sans-serif'}})
                  .then(function (dataUrl) {
                    self.saveEditedChartImage(dataUrl.toDataURL(),img_name,true,chart)
                    // saveEditedImage(dataUrl.toDataURL(), chart, img_name, self.saveEditedChartImage, true, true,smallScreenQuestionList,dataUrl.toDataURL());
                    createChartDownloadLink("download_chart_div_" + index, chart.user_participated, true,chart,smallScreenQuestionList);
                  });
                }
              }else if (chart.hasOwnProperty("question_type_id") && (chart.question_type_id === 5 || chart.question_type_id === 7)) {
                let keyArray = Object.keys(chart);
                // eslint-disable-next-line
                  await keyArray.reduce(async(prevVal, value, array_index) => {
                    const newRes = await prevVal
                    value = keyArray[array_index]
                  if (value.substring(0, 4) === "data") {
                    this.image_meta = {};
                    var last_char = value.substring(4);
                    let chart_type_data;
                    let chart_options;
                    let new_chart_options;
                    if (chart.question_type_id === 7 || chart.question_type_id === 5) {
                      chart_type_data = chart["option" + last_char].chart_type;
                      chart_options = chart["option" + last_char]
                      chart_options = {...chart_options,colors:['red']}
                      new_chart_options = this.state.smallScreenQuestionList[index]["option" + last_char]
                    } else {
                      chart_type_data = chart.options.chart_type;
                      chart_options = chart.options;
                      chart_options = {...chart_options,colors:['red']}
                      new_chart_options = this.state.smallScreenQuestionList[index].options;
                    }
                      let isLast = (keyArray.length - 1 === array_index);
                      let img_name = chart.question_id + "_" + chart_options.option_id + "_" + ((chart_options && chart_options.title)
                          ? (chart_options.title.length > 200)
                            ? chart_options.title.substring(0,200).replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
                            : chart_options.title.replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
                          : chart.question_id + "_opt_" + value + ".png");
                          new_chart_options['title'] = chart_options.title
                          let chart_new_data = {'data' : chart[value],'options' : chart_options,"user_participated" :chart['user_participated'] }
                          let chart_new_new_data = {'data' : this.state.smallScreenQuestionList[index][value],'options' : new_chart_options,"user_participated" : chart['user_participated']}
                          img_name =  img_name.replaceAll("ä","ae");
                          img_name =  img_name.replaceAll("ü","ue");
                          img_name =  img_name.replaceAll("ö","oe");
                          img_name =  img_name.replaceAll("Ü","Ue");
                          img_name =  img_name.replaceAll("Ä","Ae");
                          img_name =  img_name.replaceAll("Ö","Oe");
                          img_name =  img_name.replaceAll("ß","ss");
                      createChartDownloadLink("download_chart_div_opt_" + value + "_" + index, chart.user_participated,false,chart_new_data,chart_new_new_data);
                      let newImageDataString = await getActualImageString(this.state.smallScreenQuestionList[index],last_char)
                      this.image_meta['image_string'] = newImageDataString.replace("data:image/png;base64,", "");
                      this.image_meta['image_name'] = img_name;
                      dataOf57.push(this.image_meta)
                      if(isLast){
                          this.saveEditedChartImage(newImageDataString,img_name,isLast,chart,dataOf57)
                      }
                  }
                return newRes
              }, [])
              }
              else {
                this.image_meta = {};
                let chart_type_data = chart.options.chart_type;
                let img_name = chart.question_id + "_" + ((chart && chart.options && chart.options.title)
                    ? (chart.options.title.length > 200)
                      ? chart.options.title.substring(0,200).replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
                      : chart.options.title.replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
                    : chart.question_id + ".png");
                    img_name =  img_name.replaceAll("ä","ae");
                    img_name =  img_name.replaceAll("ü","ue");
                    img_name =  img_name.replaceAll("ö","oe");
                    img_name =  img_name.replaceAll("Ü","Ue");
                    img_name =  img_name.replaceAll("Ä","Ae");
                    img_name =  img_name.replaceAll("Ö","Oe");
                    img_name =  img_name.replaceAll("ß","ss");
                    createChartDownloadLink("download_chart_div_" + index, chart.user_participated,false,chart,this.state.smallScreenQuestionList[index]);
                    let newImageDataString = await getActualImageString(this.state.smallScreenQuestionList[index],'')
                    this.saveEditedChartImage(newImageDataString, img_name, true, chart)
              }
            return res
              }, [])
            }
          }, 3000)
    }
    //To generate demographic question PNG images
    generateDemographicImages = () => {
        setTimeout(async() => {
            // eslint-disable-next-line
            if(this.state.demographicList && this.state.smallScreenDemographicList){
                await this.state.demographicList.reduce(async(prev, chart, index) => {
                    const res = await prev
                // eslint-disable-next-line
                if (chart.hasOwnProperty("question_type_id") && (chart.question_type_id === 5 || chart.question_type_id === 7)) {
                }
                else {
                    let img_name = chart.demographic_id + "_" + (chart.options && chart.options.title
                        ? (chart.options.title.length > 200)
                            ? chart.options.title.substring(0,200).replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
                            : chart.options.title.replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
                        : "demographic_" + chart.demographic_id + ".png");
                    img_name =  img_name.replaceAll("ä","ae");
                    img_name =  img_name.replaceAll("ü","ue");
                    img_name =  img_name.replaceAll("ö","oe");
                    img_name =  img_name.replaceAll("Ü","Ue");
                    img_name =  img_name.replaceAll("Ä","Ae");
                    img_name =  img_name.replaceAll("Ö","Oe");
                    img_name =  img_name.replaceAll("ß","ss");
                    createChartDownloadLink("download_chart_div_demographic_" + index,chart.user_participated,false,chart,this.state.smallScreenDemographicList[index])
                    let newImageDataString = await getActualImageString(this.state.smallScreenDemographicList[index],'')
                        this.saveEditedChartImageDemographic(newImageDataString,img_name,true,chart)
                }
            return res
               }, [])
            }
        }, 3000)
    }
    generatePngImages = () => {
        this.generateQuestionImages()
        this.generateDemographicImages()
    }
    sendImages = (data, chartData) => {
        let counter = this.state.count
        if(chartData.question_type_id !== 1){
            counter += 1
        }
        this.setState({count : counter})
        let self = this;
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        let dataObject = {
            "file_data": data, "survey_id": this.surveyEditDataId
        }
        if(counter === 0) {
            this.setState({showPDFloader: false})
        }
        if (chartData.question_title) {
            dataObject['question_title'] = chartData.question_title;
        }
        if(this.state.isImageDeleted){
            if(chartData.question_type_id && chartData.question_type_id === 1){
                self.savedImages += 1;
                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
                    if (self.savedImages === this.questionCount && counter) {
                        self.setState({
                            disablePDFButton: false,
                            showPDFloader: false
                        })
                    }else if(counter == 0){
                        self.setState({showPDFloader: false})
                    }
                }
                else if (self.savedImages === self.totalGraphLength && counter) {
                    self.setState({
                        disablePDFButton: false,
                        showPDFloader: false
                    })
                }else if(counter == 0){
                    self.setState({showPDFloader: false})
                }
            }else{
            // eslint-disable-next-line
            axios.post(API_URL + "/surveyresult/saveImagefiles" + '?uId=' + this.userId, dataObject, { headers: this.Api_request_header }).then(res => {
                self.savedImages += 1;
                // eslint-disable-next-line
                if(this.questionCount){
                    self.setState({
                        disablePDFButton: false,
                        showPDFloader: false
                    })
                }
                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
                    if (self.savedImages === this.questionCount && counter) {
                        self.setState({
                            disablePDFButton: false,
                            showPDFloader: false
                        })
                    }else if(counter == 0){
                        self.setState({showPDFloader: false})
                    }
                }
                else if (self.savedImages === self.totalGraphLength && counter) {
                    self.setState({
                        disablePDFButton: false,
                        showPDFloader: false
                    })
                }else if(counter == 0){
                    self.setState({showPDFloader: false})
                }
            }).catch(err => console.log(err))
        }
        }
    }
    resetViewers = () => {
        this.setState({loading : 'true'})
        this.Api_request_header = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.userData.token
        }
        var surveyEditDataId = (this.props.match.params.surveyid) ? window.atob(this.props.match.params.surveyid) : '';
        var userid = this.userData ? this.userData.id : "";
        axios.get(API_URL + "/surveyresult/ResetSurveyImpressions/" + LANGUAGE_CODE + "/" + surveyEditDataId + '?uId=' + userid, { headers: this.Api_request_header }).then(res => {
            if(res.data){
                this.state.surveyData.totaluservisited  = res.data.success.ResetSurveyImpressions ? 0  : this.state.surveyData.totaluservisited
            }
            this.componentDidMount();
        }).catch(err => {
            console.log(err);
            this.setState({loading:'false'})
        })
    }
    handleReset = (e) => {
        e.preventDefault()
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui position-relative share_popup'>
                            <div className="close-btn text-right">
                                <button className="d-inline-block bg-white border-0 w-auto sharing-close-button text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                            </div>
                            <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin">{SURVEY_ANALYSIS[LANGUAGE_CODE]['reset_heading']}</h3>
                            <p>{SURVEY_ANALYSIS[LANGUAGE_CODE]['reset_title']}</p>
                            <button name="left_button" type="button" onClick={onClose} title={SURVEY_ANALYSIS[LANGUAGE_CODE]['reset_left_button']}>{SURVEY_ANALYSIS[LANGUAGE_CODE]['reset_left_button']}</button>
                            <button name="right_button" type="button" onClick={e => {this.resetViewers()
                                onClose()}} className="reset-button" title={SURVEY_ANALYSIS[LANGUAGE_CODE]['reset_right_button']}>{SURVEY_ANALYSIS[LANGUAGE_CODE]['reset_right_button']}</button>
                        </div>
                )
            }
        })
    }
    render() {
        var user_ID= null;
        if(this.userData){
            user_ID = this.userData.account_type_category_id
        }
        return (
            <React.Fragment>
                {(!JSON.parse(localStorage.getItem("userdata"))) ? <Redirect to="/anmelden" /> : null}
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {(this.state.redirectTo404 === true) ?
                    <Redirect to="/404" />
                    : null
                }
                <div className={localStorage.getItem("editsurveyactivetab") === "2" ? "tab-pane fade active show" : "tab-pane fade"} id="auswerten" role="tabpanel" aria-labelledby="auswerten-tab">
                    <div className="analyze-tab">
                        <div className="container-md pl-0 pr-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="count-box counter auto-height bg-white box-shadow">
                                        <div className="row no-gutters">
                                            <div className="col-12 col-md pt-3 pt-md-0 counter-border">
                                                <div className="counter-col  counter-font">
                                                    <div className="counter-data custom-static-data">
                                                        <div className="count" data-to="245" data-speed={this.state.surveyData.totaluserparticipated}>{this.state.surveyData.totaluserparticipated && this.props.showCounter 
                                                            ? <CountUp end={this.state.surveyData.totaluserparticipated} /> 
                                                            : 0}
                                                        </div>
                                                        <h6 className="font-18 text-truncate">{SURVEY_ANALYSIS[LANGUAGE_CODE]['member']}</h6>
                                                    </div>
                                                </div> {/*<!-- count col -->*/}
                                            </div>
                                            <div className="col-12 col-md pt-3 pt-md-0 counter-border">
                                                <div className="counter-col counter-font">
                                                    <div className="counter-data custom-static-data">
                                                        <div className="count" data-to="189" data-speed={this.state.surveyData.usercompletedsurvey}>{this.state.surveyData.usercompletedsurvey && this.props.showCounter 
                                                            ? <CountUp end={this.state.surveyData.usercompletedsurvey} />  
                                                            : 0}
                                                        </div>
                                                        <h6 className="font-18 text-truncate">{SURVEY_ANALYSIS[LANGUAGE_CODE]['member_from_empirio']}</h6>
                                                    </div>
                                                </div>{/* <!-- count col -->*/}
                                            </div>
                                            <div className="col-12 col-md pt-3 pt-md-0 counter-border">
                                                <div className="counter-col counter-font">
                                                    <div className="counter-data custom-static-data">
                                                        <div className="count" data-to="445" data-speed={this.state.surveyData.totaluservisited ? this.state.surveyData.totaluservisited : 0}>
                                                            {this.state.surveyData.totaluservisited && this.props.showCounter 
                                                                ? <CountUp end={this.state.surveyData.totaluservisited} /> 
                                                                : 0
                                                            }
                                                        </div>
                                                        <h6 className="font-18 text-truncate">{SURVEY_ANALYSIS[LANGUAGE_CODE]['views']}</h6><a href="#" onClick={(e)=>this.handleReset(e)}>{SURVEY_ANALYSIS[LANGUAGE_CODE]['reset']}</a>
                                                    </div>
                                                </div>{/* <!-- count col -->*/}
                                            </div>
                                            <div className="col-12 col-md pt-3 pt-md-0 counter-border">
                                                <div className="counter-col counter-font">
                                                    <div className="counter-data custom-static-data">
                                                        <span className="count" data-to="6" data-speed={this.state.surveyData.averageduration && this.state.surveyData.averageduration.minute ? this.state.surveyData.averageduration.minute : 0}>
                                                            {(this.state.surveyData.averageduration && this.state.surveyData.averageduration.minute && this.props.showCounter)
                                                                ? <CountUp formattingFn={(n) => (n<10)?"0"+n:n} end={this.state.surveyData.averageduration.minute} /> 
                                                                : "00"
                                                            }</span>
                                                        <span className="count">:</span>
                                                        <span className="count" data-to="6" data-speed={this.state.surveyData.averageduration && this.state.surveyData.averageduration.second ? this.state.surveyData.averageduration.second : 0}>
                                                            {(this.state.surveyData.averageduration && this.state.surveyData.averageduration.second && this.props.showCounter)
                                                                ? <CountUp formattingFn={(n) => (n<10)?"0"+n:n} end={this.state.surveyData.averageduration.second} />
                                                                : "00"
                                                            } min</span>
                                                        <h6 className="font-18 text-truncate">{SURVEY_ANALYSIS[LANGUAGE_CODE]['duration']}</h6>
                                                    </div>
                                                </div>{/* <!-- count col -->*/}
                                            </div>
                                            <div className="col-12 col-md pt-3 pt-md-0">
                                                <div className="counter-col counter-font">
                                                    <div className="counter-data custom-static-data">
                                                        <div className="count" data-to="20" data-speed={this.state.surveyData.visitedwelcomepageUser}>{this.state.surveyData.visitedwelcomepageUser && this.props.showCounter 
                                                            ? <CountUp end={this.state.surveyData.visitedwelcomepageUser} /> 
                                                            : 0}
                                                        %</div>
                                                        <h6 className="font-18 text-truncate">{SURVEY_ANALYSIS[LANGUAGE_CODE]['dropout_rate']}</h6>
                                                    </div>
                                                </div>{/* <!-- count col -->*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white box-shadow mt-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-content bg-white">
                                            <h3 className="mb-0 text-dark font-weight-bold text-break mt-4">{SURVEY_ANALYSIS[LANGUAGE_CODE]['download_result']}</h3>
                                            <div className="bottom-border-blue-auswerten mt-2"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Download_btn_container">
                                    <div className="Download_btn_item">
                                        <div className="download_btn_border-bottom d-flex justify-content-between">
                                            <div className="d-flex">
                                                <p><i className="fas fa-file-excel download_btn_file_icon"></i></p>
                                                <p>{SURVEY_ANALYSIS[LANGUAGE_CODE]['excel']}</p>
                                            </div>
                                            <div className="d-flex cursor-pointer" onClick={this.state.isZeroParticipants ? 'false' : this.requestExcel}>
                                            {
                                                !this.state.isZeroParticipants ? this.state.isExcelBtnDisabled
                                                ? <p><i className="fa fa-spinner fa-spin font-weight-bold text-primary cursor-pointer" onClick='false'></i></p>
                                                : <p><i className="ti-download text-primary"></i></p>
                                                : <p><i className="ti-download Download_btn_text_grey" onClick='false'></i></p>
                                            }
                                            {
                                                !this.state.isZeroParticipants ? this.state.isExcelBtnDisabled
                                                ? <p className ='Download_btn_text_dark opacity-60' onClick='false'>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</p>
                                                : <p className ='Download_btn_text_dark'>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</p>
                                                : <p className ='Download_btn_text_dark opacity-60' onClick='false'>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</p>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Download_btn_item">
                                        <div className="download_btn_border-bottom d-flex justify-content-between">
                                            <div className="d-flex">
                                                <p><i className="fas fa-file-powerpoint download_btn_file_icon"></i></p>
                                                <p>{SURVEY_ANALYSIS[LANGUAGE_CODE]['powerpoint']}</p>
                                            </div>
                                            <div className="d-flex cursor-pointer">
                                            {  this.userData.plan_type ? !this.state.isZeroParticipants
                                                ? (this.state.isPremiumExportBtnDisabled ?
                                                    <p><i className="fa fa-spinner fa-spin font-weight-bold text-primary" onClick='false'></i></p>
                                                    : <p><i class="ti-download text-primary" onClick={this.requestPowerpoint}></i></p>)
                                                : <p><i class="ti-download Download_btn_text_grey" onClick='false'></i></p>
                                                : <p><Link to={user_ID == 4 ? '/business-checkout' : user_ID == 2 ? "/university-checkout" : '/checkout'}><i onClick={() => localStorage.setItem('redirectURL',window.location.href)} class="ti-lock lock_btn_text_grey font-weight-bold"></i></Link></p>
                                            }
                                            {   this.userData.plan_type ? !this.state.isZeroParticipants
                                                ? <p className={this.state.isPremiumExportBtnDisabled ? "border-0 bg-transparent text-dark font-weight-bold opacity-60" :"border-0 bg-transparent text-dark font-weight-bold"}
                                                     onClick={this.state.isPremiumExportBtnDisabled ? 'false' :this.requestPowerpoint}>
                                                     {SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}
                                                  </p>
                                                : <p className="border-0 bg-transparent text-dark font-weight-bold opacity-60" onClick='false'>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</p>
                                                : <p><Link to={user_ID == 4 ? '/business-checkout' : user_ID == 2 ? "/university-checkout" : '/checkout'}><span onClick={() => localStorage.setItem('redirectURL',window.location.href)} className ='Download_btn_text_dark'>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</span></Link></p>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Download_btn_item">
                                        <div className="download_btn_border-bottom d-flex justify-content-between">
                                            <div className="d-flex">
                                                <p><i className="fas fa-file-csv download_btn_file_icon"></i></p>
                                                <p>{SURVEY_ANALYSIS[LANGUAGE_CODE]['csv']}</p>
                                            </div>
                                            <div className="d-flex cursor-pointer" onClick={this.state.isZeroParticipants ? 'false' : this.requestCSV}>
                                            {
                                                !this.state.isZeroParticipants ? this.state.isCSVBtnDisabled
                                                ? <p><i className="fa fa-spinner fa-spin font-weight-bold text-primary cursor-pointer" onClick='false'></i></p>
                                                : <p><i className="ti-download text-primary"></i></p>
                                                : <p><i className="ti-download Download_btn_text_grey" onClick='false'></i></p>
                                            }
                                            {
                                                !this.state.isZeroParticipants ? this.state.isCSVBtnDisabled
                                                ? <p className ='Download_btn_text_dark opacity-60' onClick='false'>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</p>
                                                : <p className ='Download_btn_text_dark'>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</p>
                                                : <p className ='Download_btn_text_dark opacity-60' onClick='false'>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</p>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Download_btn_item">
                                        <div className="download_btn_border-bottom d-flex justify-content-between">
                                            <div className="d-flex">
                                                <p><i className="fas fa-file-word download_btn_file_icon"></i></p>
                                                <p>{SURVEY_ANALYSIS[LANGUAGE_CODE]['word_diagramme']}</p>
                                            </div>
                                            <div className="d-flex cursor-pointer">
                                            {  this.userData.plan_type ? !this.state.isZeroParticipants
                                                ? (this.state.isPremiumExportBtnDisabled ?
                                                    <p><i className="fa fa-spinner fa-spin font-weight-bold text-primary"></i></p>
                                                    : <p><i class="ti-download text-primary" onClick={this.requestWordDiagram}></i></p>)
                                                : <p><i class="ti-download Download_btn_text_grey" onClick='false'></i></p>
                                                : <p><Link to={user_ID == 4 ? '/business-checkout' : user_ID == 2 ? "/university-checkout" : '/checkout'}><i onClick={() => localStorage.setItem('redirectURL',window.location.href)} class="ti-lock lock_btn_text_grey font-weight-bold"></i></Link></p>
                                            }
                                            { this.userData.plan_type ? !this.state.isZeroParticipants
                                                ? <p className={this.state.isPremiumExportBtnDisabled ? "border-0 bg-transparent text-dark font-weight-bold opacity-60" :"border-0 bg-transparent text-dark font-weight-bold"}
                                                     onClick={this.state.isPremiumExportBtnDisabled ? 'false' :this.requestWordDiagram}>
                                                    {SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}
                                                  </p>
                                                : <p className="border-0 bg-transparent text-dark font-weight-bold opacity-60" onClick='false' >{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</p>
                                                : <p><Link to={user_ID == 4 ? '/business-checkout' : user_ID == 2 ? "/university-checkout" : '/checkout'}><span onClick={() => localStorage.setItem('redirectURL',window.location.href)} className ='Download_btn_text_dark'>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</span></Link></p>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="Download_btn_item">
                                        <div className="download_btn_border-bottom d-flex justify-content-between">
                                            <div className="d-flex">
                                                <p><i className="fas fa-file-image download_btn_file_icon"></i></p>
                                                <p>{SURVEY_ANALYSIS[LANGUAGE_CODE]['png']}</p>
                                            </div>
                                            <div className="d-flex cursor-pointer" onClick={this.state.isZeroParticipants ? 'false' : this.requestPDF}>
                                            {
                                                !this.state.isZeroParticipants ?  this.state.showPDFloader
                                                ? <p><i className="fa fa-spinner fa-spin font-weight-bold text-primary" onClick='false'></i></p>
                                                : <p><i className="ti-download text-primary"></i></p>
                                                : <p><i className="ti-download Download_btn_text_grey" onClick='false'></i></p>
                                            }
                                            {
                                                !this.state.isZeroParticipants ? this.state.disablePDFButton
                                                ? <p className ='Download_btn_text_dark opacity-60' onClick='false'>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</p>
                                                : <p className ='Download_btn_text_dark'>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</p>
                                                : <p className ='Download_btn_text_dark opacity-60' onClick='false'>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</p>
                                            }
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="Download_btn_item">
                                        <div className="download_btn_border-bottom d-flex justify-content-between">
                                            <div className="d-flex">
                                                <p><i className="fas fa-file-word download_btn_file_icon"></i></p>
                                                <p>{SURVEY_ANALYSIS[LANGUAGE_CODE]['word_fragbook']}</p>
                                            </div>
                                            <div className="d-flex cursor-pointer">
                                                {  this.userData.plan_type
                                                    ?
                                                    <>
                                                        <p><i class="ti-download text-primary" onClick={this.requestWord}></i></p>
                                                        <p className="border-0 bg-transparent text-dark font-weight-bold" onClick={this.requestWord}>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</p>
                                                    </>
                                                    :
                                                    <>
                                                        <p><Link to={user_ID == 4 ? '/business-checkout' : user_ID == 2 ? "/university-checkout" : '/checkout'}><i class="ti-lock lock_btn_text_grey font-weight-bold" onClick={() => localStorage.setItem('redirectURL',window.location.href)}></i></Link></p>
                                                        <p><Link to={user_ID == 4 ? '/business-checkout' : user_ID == 2 ? "/university-checkout" : '/checkout'}><span onClick={() => localStorage.setItem('redirectURL',window.location.href)} className ='Download_btn_text_dark'>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</span></Link></p>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Download_btn_item">
                                        <div className="download_btn_border-bottom d-flex justify-content-between">
                                            <div className="d-flex">
                                                <p><i className="fas fa-file-excel download_btn_file_icon"></i></p>
                                                <p>{SURVEY_ANALYSIS[LANGUAGE_CODE]['premium_excel']}</p>
                                            </div>
                                            <div className="d-flex cursor-pointer">
                                            { this.userData.plan_type ? !this.state.isZeroParticipants
                                                ? (this.state.isPremiumExportBtnDisabled ?
                                                    <p><i className="fa fa-spinner fa-spin font-weight-bold text-primary"></i></p>
                                                    : <p><i class="ti-download text-primary" onClick={this.requestExcelforPlusPackage}></i></p>)
                                                : <p><i class="ti-download Download_btn_text_grey" onClick='false'></i></p>
                                                : <p><Link to={user_ID == 4 ? '/business-checkout' : user_ID == 2 ? "/university-checkout" : '/checkout'}><i class="ti-lock lock_btn_text_grey font-weight-bold" onClick={() => localStorage.setItem('redirectURL',window.location.href)}></i></Link></p>
                                            }
                                            { this.userData.plan_type ? !this.state.isZeroParticipants
                                                ? <p className={this.state.isPremiumExportBtnDisabled ? "border-0 bg-transparent text-dark font-weight-bold opacity-60" :"border-0 bg-transparent text-dark font-weight-bold"} disabled={this.state.isPremiumExportBtnDisabled}
                                                     onClick={this.state.isPremiumExportBtnDisabled ? 'false' :this.requestExcelforPlusPackage}>
                                                     {SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}
                                                  </p>
                                                : <p className="border-0 bg-transparent text-dark font-weight-bold opacity-60" onClick='false'>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</p>
                                                : <p><Link to={user_ID == 4 ? '/business-checkout' : user_ID == 2 ? "/university-checkout" : '/checkout'}><span onClick={() => localStorage.setItem('redirectURL',window.location.href)} className ='Download_btn_text_dark'>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</span></Link></p>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white box-shadow table-main-block mt-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-content bg-white">
                                            <h3 className="mb-0 text-dark font-weight-bold text-break">{SURVEY_ANALYSIS[LANGUAGE_CODE]['detail_of_participants']}</h3>
                                            <div className='bottom-border-blue-auswerten mt-2'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-responsive table-participants">
                                            <table className={this.state.participated_user_list && this.state.participated_user_list.userparticipated.length > 4 ? "member-table custom-scroll" : "member-table"}>
                                                <thead>
                                                    <tr>
                                                        <td>{SURVEY_ANALYSIS[LANGUAGE_CODE]['table_column_member_name']}</td>
                                                        <td>{SURVEY_ANALYSIS[LANGUAGE_CODE]['table_column_date']}</td>
                                                        <td>{SURVEY_ANALYSIS[LANGUAGE_CODE]['table_column_time']}</td>
                                                        <td>{SURVEY_ANALYSIS[LANGUAGE_CODE]['table_column_duration']}</td>
                                                        <td>{SURVEY_ANALYSIS[LANGUAGE_CODE]['table_column_action']}</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ParticipantsList participated_user_list={this.state.participated_user_list} deleteParticipant={this.confirmDeleteParticipant} />
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row graph">
                                <div className="col-12">
                                    {this.props.isDrawChart && this.state.questionList && <GoogleChart chartData={this.state.questionList} chart_loaded_once={this.state.chart_loaded_once} sendImages={this.sendImages} smallScreenQuestionList={this.state.smallScreenQuestionList} chartColor={this.state.surveyData.survey_color}/>}
                                    {this.props.isDrawChart && this.state.demographicList && <GoogleChartDemographic chartData={this.state.demographicList} chart_loaded_once={this.state.chart_loaded_once} sendImages={this.sendImages} questionLength={this.state.questionList.length} smallScreenDemographicList={this.state.smallScreenDemographicList}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Analyze;