import React from 'react';
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import Recaptcha from "react-google-invisible-recaptcha";
import { LOGIN_ERROR, VERIFY_ERROR, LOGIN_CONSTANT, META_TAGS_CONSTANT, META_TITLE_CONSTANT, URL_CONSTANT, BUBBLE_LOADING, META_FACEBOOK, BREADCRUMB_SCHEMA } from "../../constants/FrontEndConstant";
import { LANGUAGE_CODE, API_URL, MESSAGE_TIME, FRONT_USER, META_TITLE_APPEND, SITEKEY, RECAPTCHA_LANGUAGE, IMAGE_BASEURL } from '../../constants/DotEnv';
import { addUserdata } from '../../actions';
import { connect } from "react-redux";
import Header from "../header/MainHeader";
const ListItem = ({ value }) => (<span dangerouslySetInnerHTML={{ __html: value }}></span>);
function mapDispatchToProps(dispatch) {
	return {
		addUserdata: userdata => dispatch(addUserdata(userdata)),
	};
}
class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user_id: '',
			name: [],
			message: [],
			count: '',
			loading: 'false',
			token: '',
			login_message: [],
			register_count: '',
			fieldErrorMessage: [],
			error: 0,
			emailloader: false,
			logo_change: true
		}
		let meta_title = META_TITLE_CONSTANT[LANGUAGE_CODE]['login'] + META_TITLE_APPEND;
		let meta_description = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_description'];
		let meta_keywords = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords'];
		let og_title = META_TITLE_CONSTANT[LANGUAGE_CODE]['login'] + META_TITLE_APPEND;
		let og_description = META_FACEBOOK['og_description'];
		let og_image = META_FACEBOOK['og_image'];
		setTimeout(() => {
			window.setMeta({ "description": meta_description, "keywords": meta_keywords })
			window.setOgTag(og_title, og_description, og_image, window.location.href);
			window.setDocumentTitle(meta_title)
		}, 200)
	}
	componentWillUnmount() {
		setTimeout(() => {
			window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
			window.removeSchema();
		}, 100)
	}
	registrationMessageDisplay = () => {
		let registrationmsg = '';
		let regError = [];
		registrationmsg = JSON.parse(localStorage.getItem('registrationMessage'));
		if (registrationmsg !== null) {
			if (LANGUAGE_CODE === 'DE') {
				registrationmsg = decodeURIComponent(escape(registrationmsg));
				regError = registrationmsg
			}
			else {
				regError = registrationmsg;
			}
			this.setState({ register_count: 1 })
		}
		this.setState({ login_message: regError })
		window.setTimeout(() => {
			this.setState({ login_message: [], register_count: null })
		}, MESSAGE_TIME);
	}
	loginCheck = async (userId) => {
		this.setState({ loading: 'true' })
		var apiresponse = "";
		let regError = [];
		let self = this;
		await axios.get(API_URL + FRONT_USER + "/verifyuser/" + userId)
			.then((response) => {
				this.scrollUpFunction();
				apiresponse = response.data['success'];
				if (apiresponse !== null) {
					self.setState({ register_count: 1 })
					if (LANGUAGE_CODE === 'DE') {
						apiresponse = response.data['success']
						// VERIFY_ERROR['DE']['success']
					}
				}
				self.setState({ loading: 'false', login_message: apiresponse })
				window.setTimeout(() => {
					self.setState({ login_message: [], register_count: null })
				}, MESSAGE_TIME);
			})
			.catch((error) => {
				this.scrollUpFunction();
				apiresponse = error.response.data['error']
				if (apiresponse !== null) {
					self.setState({ register_count: 0 })
				}
				Object.keys(apiresponse).map(function (key, index) {
					if (LANGUAGE_CODE === 'DE') {
						regError = VERIFY_ERROR['DE']['error']
					}
					else {
						regError = apiresponse[key];
					}
					return 0;
				});
				self.setState({ loading: 'false', login_message: regError })
				window.setTimeout(() => {
					self.setState({ login_message: [], register_count: null })
				}, MESSAGE_TIME);
			})
	}
	changeHandle = (event) => {
		const name = event.target.name;
		const value = (name === "email") ? event.target.value.trim() : event.target.value;
		this.setState({
			[name]: value
		})
		var index = this.state.fieldErrorMessage.indexOf(name);
		var indexRE = this.state.fieldErrorMessage.indexOf(name + "validation");
		if (index > -1) {
			this.state.fieldErrorMessage.splice(index, 1);
		}
		if (indexRE > -1) {
			this.state.fieldErrorMessage.splice(indexRE, 1);
		}
	}
	componentDidMount() {
		this.scrollUpFunction();
		this.registrationMessageDisplay();
		let userId = this.props.match.params.id;
		this.setState({ user_id: userId });
		if (userId !== undefined) {
			this.loginCheck(userId);
		}
		window.setTimeout(() => {
			localStorage.removeItem('unsubscribe');
			localStorage.removeItem('unsubscribenewslatter');
			localStorage.removeItem('unsubscribenewslattererror');
			this.unsubscribenewsletter = "";
			this.unsubscribenewslettererror = "";
			this.unsubscribe = "";
			this.setState({ unsubscribe: "", unsubscribenewsletter: "", unsubscribenewslettererror: "" })
		}, MESSAGE_TIME)
		setTimeout(() => {
			var schema2 = window.positionCreate(BREADCRUMB_SCHEMA['login']['schema2']['position'], BREADCRUMB_SCHEMA['login']['schema2']['url'], BREADCRUMB_SCHEMA['login']['schema2']['name']);
			var schemaObject = window.schemaObj(BREADCRUMB_SCHEMA['schema1'], schema2, BREADCRUMB_SCHEMA['login']['schema3']);
			window.setSchema(schemaObject);
		}, 100)
	}
	scrollUpFunction = () => {
		/**for scrolling up page */
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
		let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
		if (count === -1) {
			document.querySelector('.navbar-collapse').classList.remove('show');
			document.querySelector('header').classList.remove("header_collapse");
			document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
		}
	}
	/**on resolved google invisible recaptcha call submitdata function to submit form data using API call */
	onResolved = (event) => {
		var response = "";
		response = this.recaptcha.getResponse();
		if (response !== "") {
			this.recaptcha.reset();
			this.submitData();
		}
		event.preventDefault();
	}
	/**function for submitting form data using API */
	submitData = async () => {
		let apiresponse = "";
		let apitoken = "";
		let count = '';
		let userid = '';
		let userdetail = '';
		let self = this;
		this.setState({ message: [], loading: 'true' })
		await axios.post(API_URL + FRONT_USER + "/login", {
			email: self.state.email,
			password: self.state.password,
			languagecode: LANGUAGE_CODE
		})
			.then((response) => {
				apitoken = response.data['success'].userdata['token'];
				userid = response.data['success'].userdata['id'];
				if (apitoken !== '' && userid !== '') {
					userdetail = response.data['success'].userdata;
					localStorage.setItem("userdata", JSON.stringify(userdetail));
				}
				apiresponse = response.data['success'];
				self.props.addUserdata(response.data['success'].userdata);
				if (apiresponse !== null) {
					count = 1;
				}
				self.setState({ loading: 'false', count })
				localStorage.removeItem('registrationMessage');
				// localStorage.removeItem("referralCode");
				localStorage.setItem("userdata", JSON.stringify(userdetail));
			})
			.catch((error) => {
				this.scrollUpFunction();
				let regError = [];
				let link = '';
				apiresponse = error.response.data['error'];
				if (apiresponse !== null) {
					self.setState({ count: 0 })
				}
				if (apiresponse) {
					if (apiresponse.hasOwnProperty('flag')) {
						if (LANGUAGE_CODE === 'DE') {
							link = "Bitte verifiziere deine E-Mail Adresse.<br/>Verifizierungsmail nicht bekommen? <br/> Klicke <a href= 'javascript:void(0);' class='email_link_error email-verification-mystyle' id='email_verification'>hier</a> , um sie noch einmal zu senden."
							regError.push(link);
						} else {
							link = "Please verify your e-mail address.<br /> Do not get verification e-mail? <br /><div><a href= 'javascript:void(0);' class='email_link_error email-verification-mystyle' id='email_verification'>Click here</a></div> to send it again."
							regError.push(link);
						}
						setTimeout(() => {
							document.getElementsByClassName('email_link_error')[0].addEventListener('click', () => this.sentVerificationMail(this.state.email))
						}, 1000)
					}
					else {
						Object.keys(apiresponse).map(function (key, index) {
							regError.push(apiresponse[key]);
							return 0;
						});
					}
				}
				self.setState({ message: regError, loading: 'false' })
				window.setTimeout(() => {
					self.setState({ message: [], count: null })
				}, MESSAGE_TIME);
			});
	}
	/** function for checking whether user has filled up all required field or not and then call recaptcha to execute */
	handleSubmit = (event) => {
		var loginfield = {
			email: this.state.email,
			password: this.state.password
		}
		var a = [];
		this.setState({ fieldErrorMessage: a })
		var test = 0;
		var error = ''
		for (var key in loginfield) {
			if (loginfield.hasOwnProperty(key)) {
				var errorkey = key;
				const value = loginfield[errorkey];
				if (value === '' || value === null || value === undefined) {
					a.push(errorkey);
					error = test + 1;
					test = error;
				}
				else {
					if (errorkey === 'email') {
						// eslint-disable-next-line 
						var emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						var checkEmail = emailRE.test(value);
						if (checkEmail === false) {
							a.push(errorkey + "validation");
							error = test + 1;
							test = error;
						}
					}
				}
			}
		}
		this.setState({ error: test, fieldErrorMessage: a });
		if (test === 0) {
			/**if recaptcha stop then comment this.recaptcha.execute(); and uncomment this.submitData();*/
			/**call google invisble recaptcha to execute */
			this.recaptcha.execute();
			//this.submitData();
		}
		event.preventDefault();
	}
	/**this function is for resending email for verification on click of message hyperlink to user */
	async sentVerificationMail(email) {
		let apiresponse = "";
		let regError = []
		this.setState({ emailloader: true })
		let self = this;
		await axios.get(API_URL + '/frontuser/resendvarificationemail/' + this.state.email + '/' + LANGUAGE_CODE)
			.then((response) => {
				this.scrollUpFunction();
				apiresponse = response.data['success'];
				if (apiresponse !== null) {
					self.setState({ register_count: 1 })
				}
				self.setState({ emailloader: false, login_message: apiresponse, message: [] })
				window.setTimeout(() => {
					self.setState({ login_message: [], register_count: null })
				}, MESSAGE_TIME);
			})
			.catch((error) => {
				this.scrollUpFunction();
				apiresponse = error.response.data['error']
				if (apiresponse !== null) {
					self.setState({ register_count: 0 })
				}
				// eslint-disable-next-line
				Object.keys(apiresponse).map(function (key, index) {
					regError.push(apiresponse[key]);
				});
				self.setState({ emailloader: false, login_message: regError })
				window.setTimeout(() => {
					self.setState({ login_message: [], register_count: null })
				}, MESSAGE_TIME);
			})
	}
	render() {
		let storeitem = JSON.parse(localStorage.getItem('userdata'));
		this.unsubscribe = localStorage.getItem('unsubscribe')
		this.unsubscribenewsletter = localStorage.getItem('unsubscribenewslatter')
		this.unsubscribenewslettererror = localStorage.getItem('unsubscribenewslattererror')
		let token = '';
		if (storeitem !== null) {
			token = storeitem.token;
		}
		var span = {
			color: 'red'
		}
		return (
			// {/* Main */}
			<main>
				{(token !== '' && this.state.count === '') ?
					<Redirect to="/mein-bereich" />
					: null
				}
				{(this.state.loading === 'true') ?
					BUBBLE_LOADING
					: null
				}
				{(this.state.count === 1) ?
					<Redirect to="/mein-bereich"></Redirect>
					: null
				}
				{/* <Header  change={this.state.change}/> */}
				<section className="page-title-section theme-bg">
					<div className="container-fluid">
						<div className="row align-items-center justify-content-center no-gutters text-center min-height-form">
							<div className="login-form col-12 col-sm-10 col-md-7 col-lg-5">
								<div className="padding-login-form hs-form-field">
									{(this.unsubscribe) ? <span className="error_message output_message error message-padding">
										{LOGIN_CONSTANT[LANGUAGE_CODE]['unsubscribe_mail']}
									</span>
										: null
									}
									{(this.unsubscribenewsletter) ? <span className="error_messsage_success output_message success message-padding">
										{LOGIN_CONSTANT[LANGUAGE_CODE]['unsubscribe_newsletter']}
									</span>
										: null
									}
									{(this.unsubscribenewslettererror) ? <span className="error_messsage output_message error message-padding">
										{LOGIN_CONSTANT[LANGUAGE_CODE]['unsubscribe_newsletter_error']}
									</span>
										: null
									}
									{(this.state.count === 0) ?
										(this.state.emailloader) ?
											<div><i className="fa fa-spinner fa-spin" style={{ "fontSize": "25px", "color": "#128df5" }}></i></div>
											:
											(this.state.message.length) ? <span className="error_message output_message error message-padding">
												{this.state.message.map((msg, index) => <ListItem key={index} value={msg + '<br />'} />)}
											</span>
												: null
										: null
									}
									{(this.state.register_count === 1 && this.state.login_message.length !== 0) ?
										(this.state.login_message) ? <span className="error_message_success output_message success message-padding">
											{this.state.login_message}
										</span>
											: null
										: null
									}
									{(this.state.register_count === 0 && this.state.login_message.length !== 0) ?
										(this.state.login_message) ? <span className="error_message output_message error message-padding">
											{this.state.login_message}
										</span>
											: null
										: null
									}
									<h2 className="text-dark font-weight-bold mb-4">{LOGIN_CONSTANT[LANGUAGE_CODE]['login_title']}</h2>
									<form method="post" className="text-left" onSubmit={this.handleSubmit}>
										<div className="form-group hs-form-field">
											<input type="text" name="email" autoCapitalize="none" className={(this.state.fieldErrorMessage.filter(a => a === 'email' || a === 'emailvalidation').length > 0) ? " form-control hs-input field-highlight" : "form-control hs-input"} value={this.state.value} onChange={this.changeHandle} />
											<label htmlFor="email adress">{LOGIN_CONSTANT[LANGUAGE_CODE]['email_address_label']}</label>
											<div>
												{(this.state.fieldErrorMessage.filter(a => a === 'email' || a === 'emailvalidation').length > 0) ?
													<span style={span}>{LOGIN_ERROR[LANGUAGE_CODE][this.state.fieldErrorMessage.filter(a => a === 'email' || a === 'emailvalidation')]}</span>
													: null
												}
											</div>
										</div>
										<div className="form-group hs-form-field">
											<input type="password" name="password" className={(this.state.fieldErrorMessage.filter(a => a === 'password').length > 0) ? " form-control hs-input field-highlight" : "form-control hs-input"} onChange={this.changeHandle} value={this.state.value} />
											<label htmlFor="password">{LOGIN_CONSTANT[LANGUAGE_CODE]['password_label']}</label>
											<div className="input-group-append">
												<Link className="input-group-text forgot-password text-blue font-14 custom-padding-login" to={"/" + URL_CONSTANT['forgotpassword']} title={LOGIN_CONSTANT[LANGUAGE_CODE]['to_forget']}>{LOGIN_CONSTANT[LANGUAGE_CODE]['to_forget']}</Link>
											</div>
											<div>
												{(this.state.fieldErrorMessage.filter(a => a === 'password').length > 0) ?
													<span style={span}>{LOGIN_ERROR[LANGUAGE_CODE]['password']}</span>
													: null
												}
											</div>
										</div>
										<Recaptcha ref={ref => this.recaptcha = ref} locale={RECAPTCHA_LANGUAGE} sitekey={SITEKEY} onResolved={this.onResolved} />
										<div className="form-group font-size-btn">
											<input className="btn btn-clr w-100 d-block login-btn text-uppercase text-white mt-3 font-16" type="submit" title={LOGIN_CONSTANT[LANGUAGE_CODE]['button_text']} value={LOGIN_CONSTANT[LANGUAGE_CODE]['button_text']} />
										</div>
									</form>
									<div className="btn-link-for-registrer mt-3 mt-sm-4 font-weight-bold">
										<span title={LOGIN_CONSTANT[LANGUAGE_CODE]['not_a_member_yet']} className="text-dark d-block d-sm-inline-block">{LOGIN_CONSTANT[LANGUAGE_CODE]['not_a_member_yet']}</span>
										<Link className="text-blue d-block d-sm-inline-block ml-0 ml-sm-3" to="/registrieren" title={LOGIN_CONSTANT[LANGUAGE_CODE]['sign_up_here']}> {LOGIN_CONSTANT[LANGUAGE_CODE]['sign_up_here']}</Link>
									</div>
								</div>
							</div> {/* col */}
						</div>
					</div>
				</section>
				{/* login section end */}
			</main>
		);
	}
}
export default connect(null, mapDispatchToProps)(Login);