import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { SURVEY_CREATION_CONSTANT, SCHOOL_GRADE_SCALE_CONSTANT, SURVEY_APPROVAL_QUESTION_ANSWER, SURVEY_CREATION_POSITION_CONSTANT, SURVEY_CREATION_ERROR_MESSAGE, META_TITLE_CONSTANT, META_TAGS_CONSTANT, URL_CONSTANT, BUBBLE_LOADING } from "../../../constants/FrontEndConstant";
import { LANGUAGE_CODE, BASEURL, API_URL, META_TITLE_APPEND } from '../../../constants/DotEnv';
import SurveyTitle from '../surveyCreationComponents/surveyTitle/SurveyTitle';
import SurveyCategory from '../surveyCreationComponents/surveyCategory/SurveyCategory';
import SurveyElement from '../surveyCreationComponents/surveyElement/SurveyElement'
import SurveyPicture from '../surveyCreationComponents/surveyPicture/SurveyPicture';
import SurveyWelcomePage from '../surveyCreationComponents/surveyWelcomePage/SurveyWelcomePage';
import SurveyContentPage from '../surveyCreationComponents/surveyContentPage/SurveyContentPage';
import SurveyDemographic from '../surveyCreationComponents/surveyDemographic/SurveyDemographic';
import SurveyCreatingConditions from '../surveyCreationComponents/surveyCreatingConditions/SurveyCreatingConditions';
import SurveyEnd from '../surveyCreationComponents/surveyEnd/SurveyEnd';
import SurveyDefineClosingPage from '../surveyCreationComponents/surveyDefineClosingPage/SurveyDefineClosingPage';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import SurveyCustomLogo from '../surveyCreationComponents/surveyCustomLogo/SurveyCustomLogo';
import SurveyDataProtectionPage from '../surveyCreationComponents/surveyDataProtection/SurveyDataProtectionPage';
class SurveyCreation extends Component {
        constructor(props) { 
                super(props);
                this.state = {
                        FinalQuestionData: [],
                        ActiveQuestionIndex: 0,
                        activeComponentIndex: 0,
                        childCreatingConditionIndex: 0,
                        designChangesContentPage: false,
                        editQuestionRedirect: false,
                        surveyCreationData: new Map(),
                        surveyData: {},
                        optionArray: [],
                        dropDownData: '',
                        loading: 'false',
                        questionDataOrder: [],
                        redirect: 'false',
                        questionComponentErrorMessage: '',
                        questionType: '',
                        questionFileUpload: true,
                        userId: '',
                        errorCount: 0,
                        validationErrorCount: 0,
                        validationErrorMessage: '',
                        fileUpload: false,
                        fileUploadErrorMessage: '',
                        questionFileUploadCount: 0,
                        errorElementPosition: 0,
                        questionOptionCondition: true,
                        questionScaleOptionCondition: true,
                        questionOptionCount: 0,
                        questionScaleOptionCount: 0,
                        optionCount: 2,
                        optionLimit: 20,
                        optionScaleCount: 2,
                        contentDataLength: 0,
                        contentDataLimit: 10,
                        questionContentDataLength: 0,
                        questionContentDataLimit: 10,
                        optionScaleLimit: 10,
                        questionData: { "option": [], "scale_option_text": [] },//true and flase for go into survey content page or not,it's string,
                        questionIndexUpdated: false, //Added for Deleting Question Flag Check
                        deletedQuestionIndex: '', //Added to check which question is deleted
                        surveyCreatingConditionRedirect: false,
                        nextQuestionIndex: '',
                        prevQuestionIndex: '',
                        changeConditionDropdown: false,
                        addQuestionIndex: '',
                        checkAddQuestion: false, //check condition after added new question
                        addQuestionIndexCheck: '', //check index of added new question for condition
                        changeDemographicButton: false,
                        changeClosingPageButton:false,
                        changeDataProtectionPageButton:false,
                        redirectPagenotfound: false,
                        RedirectToEditsurvey: false,
                        RedirectToProfile: false,
                        RedirectToMypolls: false,
                        editWelcomePage: false,
                        changeEditQuestionType: false,
                        updateSurveyStatusById: false,
                        updateOrderAfterAddedQuestion: false,
                        editSurveyTitleFlag: false,
                        backButtonClick: false,
                        titleLengthAllowed: 100,
                        titleLengthLeft: 100,
                        is_sie_demographic: 0,
                        editSurveyCategory: false,
                        redirectToCheckout: false,
                        editSurveyLogo: false,
                        declaration_consent_description: '',
                        declaration_consent_title: SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_data_protection_heading'],
                }
                this.surveyCreatingConditionRedirect = false;
                this.previewOpenInNewTab = false;
                this.surveyMainId = this.props.match.params.surveyid ? (this.checkBase64(this.props.match.params.surveyid)) ? window.atob(this.props.match.params.surveyid) : "" : this.props.match.params.surveyid;
                this.scrollAnimation = '';
                let meta_title = META_TITLE_CONSTANT[LANGUAGE_CODE]['surveycreation'] + META_TITLE_APPEND;
                let meta_description = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_description'];
                let meta_keywords = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords'];
                setTimeout(() => {
                        window.setMeta({ "description": meta_description, "keywords": meta_keywords })
                        window.setDocumentTitle(meta_title)
                }, 100)
                this.checkUser();
        }
        /* this function is use for check base64 url is valid or not */
        checkBase64 = (string) => {
                try {
                        window.atob(string);
                        // eslint-disable-next-line
                        this.state.redirectPagenotfound = false;
                        return true;
                } catch (e) {
                        if (e.code === 5) {
                                // eslint-disable-next-line
                                this.state.redirectPagenotfound = true
                        }
                        return false;
                }
        }
        componentDidMount() {
                document.getElementsByTagName("body")[0].classList.add("survey-creation-fixed-body");
                window.history.pushState(null, document.title, window.location.href);
                window.addEventListener('popstate', this.preventBackNav);
                if (this.state.surveyData.surveyContent) {
                        if (this.state.surveyData.surveyContent.length) {
                                this.setState({ 'contentDataLength': this.state.surveyData.surveyContent.length })
                        }
                }
                this.scrollUpFunction();
        }
        componentDidUpdate(prevProps, prevState, snapshot) {
                if (this.state.editQuestionRedirect) {
                        let ActiveQuestionIndex = this.state.ActiveQuestionIndex;
                        if (prevState.ActiveQuestionIndex !== ActiveQuestionIndex) {
                                let surveyCreationAllquestionData;
                                if (this.state.FinalQuestionData) {
                                        if (this.state.FinalQuestionData.length) {
                                                surveyCreationAllquestionData = this.state.FinalQuestionData;
                                        }
                                }
                                if (!surveyCreationAllquestionData) {
                                        if (this.surveyMainId) {
                                                surveyCreationAllquestionData = JSON.parse(localStorage.getItem('surveycreationallquestiondata_' + this.surveyMainId));
                                        }
                                }
                                if (surveyCreationAllquestionData[ActiveQuestionIndex]) {
                                        let questionData = surveyCreationAllquestionData[ActiveQuestionIndex];
                                        let optionLength = this.state.optionCount;
                                        let optionScaleLimit;
                                        let optionScaleLength = this.state.optionScaleCount;
                                        let optionLimit;
                                        if (questionData.option) {
                                                optionLength = questionData.option.length;
                                        }
                                        if (questionData.scale_option_text) {
                                                optionScaleLength = questionData.scale_option_text.length;
                                        }
                                        if (parseInt(questionData.question_type_id) === 6 || parseInt(questionData.question_type_id) === 7) {
                                                optionLimit = 10;
                                                optionScaleLimit = 20;
                                        }
                                        else {
                                                optionLimit = 20;
                                                optionScaleLimit = 10;
                                        }
                                        this.setState({ questionData, optionCount: optionLength, optionScaleCount: optionScaleLength, optionScaleLimit, optionLimit })
                                }
                        }
                } else {
                        /**Bug of question after edit question add new question data not to be clear */
                        let ActiveQuestionIndex = this.state.ActiveQuestionIndex;
                        let surveyCreationAllquestionData;
                        if (this.surveyMainId) {
                                surveyCreationAllquestionData = JSON.parse(localStorage.getItem('surveycreationallquestiondata_' + this.surveyMainId));
                        }
                        if (surveyCreationAllquestionData) {
                                if (surveyCreationAllquestionData.length === ActiveQuestionIndex) {
                                        if (this.state.questionData) {
                                                if (Object.keys(this.state.questionData).length) {
                                                        if (Object.keys(this.state.questionData).length > 2 && this.state.questionData.id) {
                                                                this.setState({ questionData: { "option": [], "scale_option_text": [] } })
                                                        }
                                                }
                                        }
                                }
                        }
                }
                if (prevState) {
                        if (prevState.activeComponentIndex !== this.state.activeComponentIndex) {
                                this.setState({ validationErrorCount: 0, validationErrorMessage: '' })
                        }
                        if (this.state.activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_page_position']) {
                                if (this.state.surveyData) {
                                        let data = this.state.surveyData;
                                        if (data.is_welcome_page === false && prevState.surveyData.is_welcome_page) {
                                                let contentDataLength = this.state.contentDataLength;
                                                let contentDataLimit = this.state.contentDataLimit;
                                                if (data.surveyContent) {
                                                        delete data.surveyContent;
                                                        contentDataLength = 0;
                                                        contentDataLimit = 10;
                                                }
                                                this.setState({ surveyData: data, contentDataLength, contentDataLimit })
                                        }
                                        else if (data.is_welcome_page === true && !prevState.surveyData.is_welcome_page) {
                                                let dataNew = JSON.parse(localStorage.getItem('surveycreationdata_' + this.surveyMainId));
                                                let contentDataLength = dataNew.surveyContent ? dataNew.surveyContent.length : 0;
                                                let contentDataLimit = 10;
                                                this.setState({ surveyData: dataNew, contentDataLength, contentDataLimit })
                                        }
                                }
                        }
                }
                if(this.state.activeComponentIndex !== prevState.activeComponentIndex){
                        this.scrollUpFunction();
                }
        }
        componentWillMount() {
                this.checkUser();
        }
        componentWillUnmount() {
                document.getElementsByTagName("body")[0].classList.remove("survey-creation-fixed-body");
                window.removeEventListener('popstate', this.preventBackNav, false);
        }
        scrollUpFunction(smoothScroll = false) {
                if(smoothScroll) {
                        var position = document.getElementsByClassName('survey-creation-modal')[0].scrollTop;
                        var positionElement = document.documentElement.scrollTop;
                        if (position) {
                                document.getElementsByClassName('survey-creation-modal')[0].scrollBy(0, -Math.max(10, Math.floor(position / 10)));
                                this.scrollAnimation = window.setTimeout(() => {
                                        this.scrollUpFunction(true)
                                }, 10);
                        }
                        else if (positionElement) {
                                document.documentElement.scrollBy(0, -Math.max(10, Math.floor(position / 10)));
                                this.scrollAnimation = window.setTimeout(() => {
                                        this.scrollUpFunction(true)
                                }, 10);
                        }
                        else clearTimeout(this.scrollAnimation);
                }
                else {
                        document.getElementsByClassName('survey-creation-modal')[0].scrollTop = 0;
                        document.documentElement.scrollTop = 0;
                }
                if (this.surveyMainId) {
                        if (localStorage.getItem('scrollposition_' + this.surveyMainId) && this.state.activeComponentIndex === 7) {
                                let scroll = localStorage.getItem('scrollposition_' + this.surveyMainId);
                                document.body.scrollTop = scroll;
                                document.documentElement.scrollTop = scroll;
                        }
                        else {
                                document.getElementsByClassName('survey-creation-modal')[0].scrollTop = 0;
                                document.documentElement.scrollTop = 0;
                        }
                }
        }
        preventBackNav = () => {
                if (this.state) {
                        if (this.state.activeComponentIndex === 0) {
                                window.history.go(-2);
                        }
                }
                if (document.getElementsByClassName("back_button")[0]) {
                        document.getElementsByClassName("back_button")[0].click();
                        window.history.pushState(null, document.title, window.location.href);
                }
        }
        checkUser = async () => {
                this.setState({ loading: 'true' })
                let dropDownData = '';
                let surveyCreationData;
                let surveyCreationAllquestionData;
                let storeitem = JSON.parse(localStorage.getItem('userdata'));
                let FinalDropdownList = JSON.parse(localStorage.getItem("surveydropdownlist"));
                let SurveyQuestionTypeDropdownList = JSON.parse(localStorage.getItem("surveyquestiontypedropdownlist"));
                if (this.surveyMainId) {
                        surveyCreationData = JSON.parse(localStorage.getItem('surveycreationdata_' + this.surveyMainId));
                        surveyCreationAllquestionData = JSON.parse(localStorage.getItem('surveycreationallquestiondata_' + this.surveyMainId));
                }
                if (!this.surveyMainId) {
                        if (this.props) {
                                if (this.props.match) {
                                        if (this.props.match.params) {
                                                if (this.props.match.params.surveyid) {
                                                        this.surveyMainId = this.checkBase64(this.props.match.params.surveyid) ? window.atob(this.props.match.params.surveyid) : ""
                                                }
                                        }
                                }
                        }
                }
                // eslint-disable-next-line
                let self = this;
                let questionType = '';
                let allQuestionData = '';
                let userId;
                if (storeitem !== null) {
                        let apitoken = storeitem.token;
                        userId = storeitem.id;
                        // eslint-disable-next-line
                        var headers = {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + apitoken
                        }
                        if (FinalDropdownList === null) {
                                await axios.get(API_URL + '/commonApi/getAllSurveydp/' + LANGUAGE_CODE)
                                        .then((response) => {
                                                dropDownData = response.data.success;
                                                localStorage.setItem("surveydropdownlist", JSON.stringify(dropDownData));
                                                self.setState({ dropDownData })
                                        })
                                        .catch((error) => {
                                                console.log("error", error)
                                        })
                        }
                        else {
                                dropDownData = JSON.parse(localStorage.getItem("surveydropdownlist"));
                                this.setState({ dropDownData });
                        }
                        if (SurveyQuestionTypeDropdownList === null) {
                                await axios.get(API_URL + '/questiontype/getQuestionType/' + LANGUAGE_CODE + '?uId=' + userId, { headers: headers })
                                        .then((response) => {
                                                let responseNew = '';
                                                responseNew = response.data['success'];
                                                localStorage.setItem("surveyquestiontypedropdownlist", JSON.stringify(responseNew));
                                                questionType = responseNew.questiontype;
                                                self.setState({ questionType })
                                        })
                                        .catch((error) => {
                                                if (error) {
                                                        if (error.response) {
                                                                if (error.response.data) {
                                                                        let msg = error.response.data['error']
                                                                        if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                                localStorage.clear();
                                                                                self.setState({ redirect: 'true' })
                                                                        }
                                                                        if (msg === 'Umfrage nicht gefunden' || msg === 'Survey not found' || msg === 'Invalid User') {
                                                                                self.setState({ redirectPagenotfound: true })
                                                                        }
                                                                }
                                                        }
                                                }
                                        })
                        }
                        if (surveyCreationData) {
                                if (this.surveyMainId && userId) {
                                        await axios.get(API_URL + '/survey/getQuestionByUser/' + LANGUAGE_CODE + '/' + this.surveyMainId + '/' + userId+ '?uId=' + userId, { headers: headers })
                                                .then((response) => {
                                                        let responseSurvey = '';
                                                        let responseQuestion = '';
                                                        responseSurvey = response.data['success'].surveydata;
                                                        responseQuestion = response.data['success'].questiondata;
                                                        if (this.surveyMainId) {
                                                                localStorage.setItem("surveycreationdata_" + this.surveyMainId, JSON.stringify(responseSurvey));
                                                                localStorage.setItem("surveycreationallquestiondata_" + this.surveyMainId, JSON.stringify(responseQuestion));
                                                        }
                                                        allQuestionData = responseQuestion;
                                                        surveyCreationData = responseSurvey;
                                                        surveyCreationAllquestionData = allQuestionData;
                                                        self.setState({ allQuestionData, FinalQuestionData: allQuestionData, is_sie_demographic: responseSurvey.is_sie_demographic })
                                                })
                                                .catch((error) => {
                                                        if (error) {
                                                                if (error.response) {
                                                                        if (error.response.data) {
                                                                                let msg = error.response.data['error']
                                                                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                                        localStorage.clear();
                                                                                        self.setState({ redirect: 'true' })
                                                                                }
                                                                                if (msg === 'Umfrage nicht gefunden' || msg === 'Survey not found' || msg === 'Invalid User') {
                                                                                        self.setState({ redirectPagenotfound: true })
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                })
                                }
                                this.setState({ surveyData: surveyCreationData });
                        }
                        else {
                                if (this.surveyMainId && userId) {
                                        await axios.get(API_URL + '/survey/getQuestionByUser/' + LANGUAGE_CODE + '/' + this.surveyMainId + '/' + userId+ '?uId=' + userId, { headers: headers })
                                                .then((response) => {
                                                        let responseSurvey = '';
                                                        let responseQuestion = '';
                                                        responseSurvey = response.data['success'].surveydata;
                                                        responseQuestion = response.data['success'].questiondata;
                                                        if (this.surveyMainId) {
                                                                localStorage.setItem("surveycreationdata_" + this.surveyMainId, JSON.stringify(responseSurvey));
                                                                localStorage.setItem("surveycreationallquestiondata_" + this.surveyMainId, JSON.stringify(responseQuestion));
                                                        }
                                                        allQuestionData = responseQuestion;
                                                        surveyCreationData = responseSurvey;
                                                        surveyCreationAllquestionData = allQuestionData;
                                                        self.setState({ surveyData: responseSurvey, allQuestionData, FinalQuestionData: allQuestionData, is_sie_demographic: responseSurvey.is_sie_demographic })
                                                })
                                                .catch((error) => {
                                                        if (error) {
                                                                if (error.response) {
                                                                        if (error.response.data) {
                                                                                let msg = error.response.data['error']
                                                                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                                        localStorage.clear();
                                                                                        self.setState({ redirect: 'true' })
                                                                                }
                                                                                if (msg === 'Umfrage nicht gefunden' || msg === 'Survey not found' || msg === 'Invalid User') {
                                                                                        self.setState({ redirectPagenotfound: true })
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                })
                                }
                        }
                        let surveyAllDemographic = JSON.parse(localStorage.getItem('surveyalldemographic'));
                        if (surveyAllDemographic) {
                                surveyAllDemographic = surveyAllDemographic.demographicattribute;
                        }
                        if (!surveyAllDemographic) {
                                await axios.get(API_URL + '/survey/getDemographicattribute/' + LANGUAGE_CODE + "?uId=" + userId, { headers: headers })
                                        .then((response) => {
                                                let responseNew = '';
                                                responseNew = response.data['success'];
                                                surveyAllDemographic = responseNew.demographicattribute;
                                                self.setState({ surveyAllDemographic })
                                                localStorage.setItem("surveyalldemographic", JSON.stringify(responseNew));
                                        })
                                        .catch((error) => {
                                                if (error) {
                                                        if (error.response) {
                                                                let msg = error.response.data['error']
                                                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                        localStorage.clear();
                                                                        self.setState({ redirect: 'true' })
                                                                }
                                                        }
                                                }
                                        })
                        }
                        if (surveyCreationData) {
                                let contentDataLength = this.state.contentDataLength;
                                let componentIndex = this.state.activeComponentIndex;
                                let RedirectToMypolls = this.state.RedirectToMypolls;
                                let childCreatingConditionIndex = this.state.childCreatingConditionIndex;
                                if (surveyCreationData.surveyContent) {
                                        if (surveyCreationData.surveyContent.length) {
                                                contentDataLength = surveyCreationData.surveyContent.length
                                        }
                                }
                                if (surveyCreationData.status) {
                                        /** For set default active component index edit activeComponentIndex */
                                        if (parseInt(surveyCreationData.status) === 1 || parseInt(surveyCreationData.status) === 4 || parseInt(surveyCreationData.status) === 5 || parseInt(surveyCreationData.status) === 3) {
                                                componentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                                                childCreatingConditionIndex = 1;
                                        }
                                        else if (parseInt(surveyCreationData.status) === 2 && surveyCreationAllquestionData && surveyCreationAllquestionData.length > 0) {
                                                componentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                                                childCreatingConditionIndex = 1;
                                        }
                                }
                                this.setState({ contentDataLength, activeComponentIndex: componentIndex, childCreatingConditionIndex, RedirectToMypolls });
                        }
                        if (surveyCreationAllquestionData) {
                                let ActiveQuestionIndex = surveyCreationAllquestionData.length;
                                this.setState({ FinalQuestionData: surveyCreationAllquestionData, ActiveQuestionIndex, questionDataOrder: surveyCreationAllquestionData });
                        }
                        this.setState({ userId });
                        setTimeout(() => {
                                this.setState({ loading: 'false' });
                        }, 1000)
                }
                else {
                        this.setState({ redirect: 'true', loading: 'false' });
                }
        }
        surveyTitle = (e) => {
                const title = e.target.name;
                const value = e.target.value;
                this.setState(prevState => ({ surveyCreationData: prevState.surveyCreationData.set(title, value) }));
                let surveyData = Object.assign({}, this.state.surveyData);    //creating copy of object
                surveyData[title] = value;                        //updating value
                this.setState({ surveyData, validationErrorMessage: '' });
                //Title Count
                const charCount = value.length;
                const charsLeft = this.state.titleLengthAllowed - charCount;
                this.setState({ titleLengthLeft: charsLeft });
        }
        surveyCategory = (e) => {
                const category = e.target.name;
                const value = e.target.value;
                this.setState(prevState => ({ surveyCreationData: prevState.surveyCreationData.set(category, value) }));
                let surveyData = Object.assign({}, this.state.surveyData);    //creating copy of object of questionData
                surveyData[category] = parseInt(value);                        //updating value of questionData
                this.setState({ surveyData, validationErrorMessage: '' });
        }
        surveyElement = (e) => {
                const questionName = e.target.name;
                const questionId = e.target.value;
                var changeEditQuestionType = this.state.changeEditQuestionType;
                var stateData = this.state;
                var deleteQuestionData = false;
                let surveyCreationAllquestionData;
                if (this.surveyMainId) {
                        surveyCreationAllquestionData = JSON.parse(localStorage.getItem('surveycreationallquestiondata_' + this.surveyMainId));
                }
                // eslint-disable-next-line
                let questionData = Object.assign({}, this.state.questionData);    //creating copy of object of questionData
                if (questionName === "question_type_id") {
                        Array.from(document.getElementsByClassName("get-image-preview")).forEach((img, index) => {
                                img.removeAttribute("src");
                        });
                        Array.from(document.getElementsByClassName("get-image")).forEach((img, index) => {
                                img.value = "";
                        });
                        /** Delete from backkend question data if change question and after comes old question type of question edit -- key deleteQuestionData */
                        if (stateData) {
                                if (stateData.editQuestionRedirect) {
                                        if (changeEditQuestionType) {
                                                if (stateData.questionDataOrder[stateData.ActiveQuestionIndex]) {
                                                        if (parseInt(questionId) === parseInt(stateData.questionDataOrder[stateData.ActiveQuestionIndex][questionName])) {
                                                                deleteQuestionData = true;
                                                        }
                                                }
                                        }
                                        else {
                                                changeEditQuestionType = true;
                                        }
                                }
                        }
                        /** End Delete from backkend question data if change question and after comes old question type of question edit -- key deleteQuestionData */
                        questionData[questionName] = parseInt(questionId);                        //updating value of questionData
                        if (parseInt(questionId) !== this.state.questionData.questionName) {
                                if (parseInt(questionId) === 3 || parseInt(questionId) === 4 || parseInt(questionId) === 5 || parseInt(questionId) === 7) {
                                        questionData['option'] = [{ id: "", option_text: "", option_image: "" }, { id: "", option_text: "", option_image: "" }];
                                }
                                else {
                                        questionData['option'] = [];
                                }
                                questionData['surveyContent'] = [];
                                questionData['question_text'] = "";
                                questionData['scale_type'] = "";
                                questionData['is_question_optional'] = (questionData['is_question_optional'] && parseInt(questionData['is_question_optional']) === 1) ? 1 : 0;
                                questionData['deleteQuestionData'] = deleteQuestionData;
                                let questionContentDataLength = 0;
                                let questionContentDataLimit = 10;
                                let optionCount = 2;
                                let optionScaleCount = 2;
                                let optionLimit;
                                let optionScaleLimit;
                                if (parseInt(questionId) === 6 || parseInt(questionId) === 7) {
                                        optionLimit = 10;
                                        optionScaleLimit = 20;
                                }
                                else {
                                        optionLimit = 20;
                                        optionScaleLimit = 10;
                                }
                                this.setState({ optionCount, questionContentDataLength, questionContentDataLimit, optionLimit, questionComponentErrorMessage: '', optionScaleCount, optionScaleLimit });
                        }
                        this.setState({ validationErrorMessage: '', validationErrorCount: 0, changeEditQuestionType });
                }
                else if (questionName === "scale_type") {
                        questionData[questionName] = parseInt(questionId);                        //updating value of questionData
                        if (parseInt(questionId) !== this.state.questionData.questionName) {
                                if ((parseInt(questionId) === 5 && parseInt(questionData.question_type_id) === 7)) {
                                        questionData['scale_option_text'] = [{ id: "", scale_text: "" }, { id: "", scale_text: "" }];
                                }
                                else {
                                        questionData['scale_option_text'] = [];
                                }
                                questionData['question_text'] = "";
                                if (parseInt(questionData.question_type_id) === 7) {
                                        questionData['option'] = [{ id: "", option_text: "", option_image: "" }, { id: "", option_text: "", option_image: "" }];
                                }
                                let optionCount = 2;
                                let optionScaleCount = 2;
                                let optionLimit;
                                let optionScaleLimit;
                                if (parseInt(questionId) === 6 || parseInt(questionId) === 7) {
                                        optionLimit = 10;
                                        optionScaleLimit = 20;
                                }
                                else {
                                        optionLimit = 20;
                                        optionScaleLimit = 10;
                                }
                                this.setState({ optionCount, optionLimit, optionScaleCount, optionScaleLimit });
                        }
                }
                else if (questionName === "questionOptionalToggle") {
                        questionData.is_question_optional = questionData.is_question_optional && parseInt(questionData.is_question_optional) === 1 ? 0 : 1;
                }
                else {
                        questionData[questionName] = questionId;                        //updating value of questionData
                }
                if (this.surveyMainId) {
                        if (surveyCreationAllquestionData) {
                                if (surveyCreationAllquestionData[this.state.ActiveQuestionIndex]) {
                                        surveyCreationAllquestionData[this.state.ActiveQuestionIndex] = questionData;
                                        //localStorage.setItem('surveycreationallquestiondata_' + this.surveyMainId, JSON.stringify(surveyCreationAllquestionData));
                                }
                        }
                }
                this.setState({ questionData });
        }        
        surveyElementContent = (e) => {
                let surveyCreationAllquestionData;
                if (this.surveyMainId) {
                        surveyCreationAllquestionData = JSON.parse(localStorage.getItem('surveycreationallquestiondata_' + this.surveyMainId));
                }
                let questionData = Object.assign({}, this.state.questionData);
                let questionTypeId = questionData.question_type_id;
                let optionName;
                let optionValue;
                if (e.target.name) {
                        optionName = e.target.name;
                        optionValue = e.target.value;
                }
                else {
                        optionName = e.currentTarget.getAttribute('name');
                        optionValue = e.currentTarget.getAttribute('value');
                }
                let optionCount = this.state.optionCount;
                let optionLimit = this.state.optionLimit;
                let optionScaleCount = this.state.optionScaleCount;
                let optionScaleLimit = this.state.optionScaleLimit;
                let questionComponentErrorMessage = this.state.questionComponentErrorMessage
                let errorCount = this.state.errorCount
                errorCount = 0;
                questionComponentErrorMessage = '';
                var optionDiv = document.getElementsByClassName("element-content");
                var optionDivSecond = document.getElementsByClassName("element-second");
                let option_url = null;
                if (optionName.substring(0, 6) === 'option') { 
                        var j = 0;
                        if (parseInt(questionTypeId) === 7 || parseInt(questionTypeId) === 6) {
                                for (let i = 0; i < (optionDiv.length / 2); i++) {
                                        // eslint-disable-next-line
                                        var obj = {};
                                        obj[optionDiv[j].name] = optionDiv[j].value === "" || undefined ? null : optionDiv[j].value;
                                        obj[optionDiv[(j + 1)].name] = optionDiv[(j + 1)].value;
                                        if (optionDiv[(j + 1)].value.replace(/\s/g, '').length || optionDiv[(j + 1)].value.length === 0) {
                                                questionData['option'][i] = obj;                        //updating value of questionData for option
                                                j = j + 2;
                                        }
                                }
                        }
                        else {
                                for (let i = 0; i < (optionDiv.length / 4); i++) {
                                        if (surveyCreationAllquestionData) {
                                                if (surveyCreationAllquestionData[this.state.ActiveQuestionIndex]) {
                                                        if (surveyCreationAllquestionData[this.state.ActiveQuestionIndex].option) {
                                                                if (surveyCreationAllquestionData[this.state.ActiveQuestionIndex].option[i]) {
                                                                        if (questionTypeId !== surveyCreationAllquestionData[this.state.ActiveQuestionIndex].question_type_id) {
                                                                                option_url = "";
                                                                        }
                                                                        else if (surveyCreationAllquestionData[this.state.ActiveQuestionIndex].option[i]['option_image'] !== "") {
                                                                                option_url = surveyCreationAllquestionData[this.state.ActiveQuestionIndex].option[i]['option_image'];
                                                                                if (this.state.addQuestionIndex) {
                                                                                        option_url = "";
                                                                                }
                                                                        }
                                                                        else {
                                                                                option_url = "";
                                                                        }
                                                                }
                                                                else {
                                                                        option_url = "";
                                                                }
                                                        }
                                                        else {
                                                                option_url = "";
                                                        }
                                                }
                                                else {
                                                        option_url = "";
                                                }
                                        }
                                        // eslint-disable-next-line                                        
                                        var obj = {};
                                        obj[optionDiv[j].name] = optionDiv[j].value === "" || undefined ? null : optionDiv[(j)].value;
                                        obj[optionDiv[(j + 1)].name] = optionDiv[(j + 1)].value;
                                        obj[optionDiv[(j + 2)].name] = optionDiv[(j + 2)].files[0] === undefined ? option_url : optionDiv[(j + 2)].files[0];
                                        if (optionDiv[(j + 1)].value.replace(/\s/g, '').length || optionDiv[(j + 1)].value.length === 0) {
                                                questionData['option'][i] = obj;                        //updating value of questionData for option
                                                j = j + 4;
                                        }
                                }
                        }
                        this.setState({ questionData, validationErrorCount: 0, validationErrorMessage: '' });
                }
                else if (optionName.substring(0, 10) === 'scale_text') {
                        j = 0; 
                        if (parseInt(questionTypeId) === 7) {
                                for (let i = 0; i < (optionDivSecond.length / 2); i++) {
                                        // eslint-disable-next-line
                                        var objNew = {};
                                        objNew[optionDivSecond[j].name] = optionDivSecond[j].value === "" || undefined ? null : optionDivSecond[j].value;
                                        objNew[optionDivSecond[(j + 1)].name] = optionDivSecond[(j + 1)].value;
                                        if (optionDivSecond[(j + 1)].value.replace(/\s/g, '').length || optionDivSecond[(j + 1)].value.length === 0) {
                                                questionData['scale_option_text'][i] = objNew;                        //updating value of questionData for option
                                                j = j + 2;
                                        }
                                }
                        }
                        else {
                                for (let i = 0; i < (optionDivSecond.length / 3); i++) {
                                        // eslint-disable-next-line
                                        var objNew = {};
                                        objNew[optionDivSecond[j].name] = optionDivSecond[j].value === "" || undefined ? null : optionDivSecond[j].value;
                                        objNew[optionDivSecond[(j + 1)].name] = optionDivSecond[(j + 1)].value;
                                        objNew[optionDivSecond[(j + 2)].name] = optionDivSecond[(j + 2)].files[0] === undefined ? null : optionDivSecond[(j + 2)].files[0];
                                        questionData['scale_option_text'][i] = objNew;                        //updating value of questionData for option
                                        j = j + 3;
                                }
                        }
                        this.setState({ questionData, validationErrorCount: 0, validationErrorMessage: '' });
                }
                else if (optionName === "question_content_image") {
                        questionData[optionName] = e.target.files[0];                        //updating value of questionData
                        this.setState({ questionData });
                }
                else if (optionName === 'addoption') {
                        if (optionCount < optionLimit) {
                                optionCount = optionCount + 1;
                        }
                        let optionObj = { id: "", option_text: "", option_image: "" }
                        if (!questionData['option']) {
                                questionData['option'] = [];

                        }
                        questionData['option'].push(optionObj)
                        e.preventDefault();
                }
                else if (optionName === 'removeoption') {
                        var id = e.currentTarget.getAttribute('data-value');
                        var optionid = e.currentTarget.getAttribute('id');
                        id = parseInt(id);
                        if (parseInt(id) >= 0) {
                                var element = document.getElementById(id);
                                if (element) {
                                        optionLimit = optionLimit + 1;
                                }
                                if (document.getElementById("output_" + id)) {
                                        document.getElementById("output_" + id).removeAttribute("src");
                                }
                                questionData['option'].splice(id, 1);
                                /**removed data but updated filelist that's why update input file value */
                                document.getElementsByClassName("element-content")[id + 2].value = "";
                                if (optionid) {
                                        this.setState({ loading: 'true' })
                                        let storeitem = JSON.parse(localStorage.getItem('userdata'));
                                        let apitoken = '';
                                        let userid = '';
                                        if (storeitem !== null) {
                                                apitoken = storeitem.token;
                                                userid = storeitem.id;
                                        }
                                        var headers = {
                                                "Content-Type": "application/json",
                                                "Authorization": "Bearer " + apitoken
                                        }
                                        // eslint-disable-next-line
                                        let data = '';
                                        let self = this;
                                        optionid = parseInt(optionid);
                                        axios.delete(API_URL + "/survey/deleteoptionbyid/" + LANGUAGE_CODE + '/' + optionid + '?uId=' + userid, { headers: headers })
                                                .then((response) => {
                                                        data = response.data['success'];
                                                        self.setState({ loading: 'false', redirectLogin: 'true' })
                                                })
                                                .catch((error) => {
                                                        if (error) {
                                                                if (error.response) {
                                                                        if (error.response.data) {
                                                                                let msg = error.response.data['error']
                                                                                if (msg) {
                                                                                        if (msg.length) {
                                                                                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                                                        localStorage.clear();
                                                                                                        self.setState({ redirect: 'true' })
                                                                                                }
                                                                                        }
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                        self.setState({ loading: 'false' })
                                                })
                                        if (surveyCreationAllquestionData[this.state.ActiveQuestionIndex]) {
                                                if (surveyCreationAllquestionData[this.state.ActiveQuestionIndex].option) {
                                                        surveyCreationAllquestionData[this.state.ActiveQuestionIndex].option = this.state.questionData.option;
                                                        if (this.surveyMainId) {
                                                                localStorage.setItem("surveycreationallquestiondata_" + this.surveyMainId, JSON.stringify(surveyCreationAllquestionData));
                                                        }
                                                }
                                        }
                                }
                        }
                } else if (optionName === 'sclaeaddoption') {
                        if (optionScaleCount < optionScaleLimit) {
                                optionScaleCount = optionScaleCount + 1;
                        }
                        let optionObj = { id: "", scale_text: "" }
                        if (!questionData['scale_option_text']) {
                                questionData['scale_option_text'] = [];
                        }
                        questionData['scale_option_text'].push(optionObj)
                        e.preventDefault();
                } else if (optionName === 'scaleremoveoption') {
                        // eslint-disable-next-line
                        var id = e.currentTarget.getAttribute('data-value');
                        // eslint-disable-next-line
                        var optionid = e.currentTarget.getAttribute('id');
                        if (parseInt(id) >= 0) {
                                // eslint-disable-next-line
                                var element = document.getElementById("scale" + id);
                                if (element) {
                                        optionScaleLimit = optionScaleLimit + 1;
                                }
                                questionData['scale_option_text'].splice(id, 1);
                                if (optionid) {
                                        this.setState({ loading: 'true' })
                                        let storeitem = JSON.parse(localStorage.getItem('userdata'));
                                        let apitoken = '';
                                        let userId = '';
                                        if (storeitem !== null) {
                                                apitoken = storeitem.token;
                                                userId = storeitem.id;
                                        }
                                        headers = {
                                                "Content-Type": "application/json",
                                                "Authorization": "Bearer " + apitoken
                                        }
                                        // eslint-disable-next-line
                                        let data = '';
                                        let self = this;
                                        optionid = parseInt(optionid);
                                        axios.delete(API_URL + "/survey/deleteScaleById/" + LANGUAGE_CODE + '/' + optionid + '?uId=' + userId, { headers: headers })
                                                .then((response) => {
                                                        data = response.data['success'];
                                                        self.setState({ loading: 'false' })
                                                })
                                                .catch((error) => {
                                                        if (error) {
                                                                if (error.response) {
                                                                        if (error.response.data) {
                                                                                let msg = error.response.data['error']
                                                                                if (msg) {
                                                                                        if (msg.length) {
                                                                                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                                                        localStorage.clear();
                                                                                                        self.setState({ redirect: 'true' })
                                                                                                }
                                                                                        }
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                        self.setState({ loading: 'false' })
                                                })
                                }
                        }
                } else if (optionName === "scale_type") {
                        questionData[optionName] = parseInt(optionValue);                        //updating value of questionData
                        if (parseInt(optionValue) !== this.state.questionData.optionName) {
                                if ((parseInt(optionValue) === 5 && parseInt(questionData.question_type_id) === 6) || (parseInt(questionData.question_type_id) === 7)) {
                                        questionData['option'] = [{ id: "", option_text: "", option_image: "" }, { id: "", option_text: "", option_image: "" }];
                                }
                                else {
                                        questionData['option'] = [];
                                }
                                if (parseInt(questionData.question_type_id) === 7 && parseInt(optionValue) === 5) {
                                        questionData['scale_option_text'] = [{ id: "", scale_text: "" }, { id: "", scale_text: "" }];
                                }
                                else {
                                        questionData['scale_option_text'] = [];
                                }
                                let optionLimit;
                                let optionScaleLimit;
                                if (parseInt(questionData.question_type_id) === 6 || parseInt(questionData.question_type_id) === 7) {
                                        optionLimit = 10;
                                        optionScaleLimit = 20;
                                }
                                else {
                                        optionLimit = 20;
                                        optionScaleLimit = 10;
                                }
                                let optionCount = 2;
                                let optionScaleCount = 2;
                                this.setState({ questionData, optionCount, optionLimit, optionScaleCount, optionScaleLimit, validationErrorCount: 0, validationErrorMessage: '' });
                        }
                }
                else if (optionName === "question_text") {
                        if(optionValue.replace(/\s/g, '').length || optionValue.length === 0) {
                                questionData[optionName] = optionValue;
                                this.setState({ questionData, validationErrorCount: 0, validationErrorMessage: '' });
                        }
                }
                else {
                        questionData[optionName] = optionValue;                        //updating value of questionData
                        this.setState({ questionData, validationErrorCount: 0, validationErrorMessage: '' });
                }
                this.setState({ questionData, optionLimit, optionCount, questionComponentErrorMessage, errorCount, optionScaleCount, optionScaleLimit });
        }
        surveyWelcomePage = (e) => {
                const name = e.target.name;
                let activeComponentIndex;
                const value = (e.target.value === 'true')
                if (value) {
                        activeComponentIndex = this.state.activeComponentIndex;
                        let surveyData = Object.assign({}, this.surveyData);    //creating copy of object of questionData
                        surveyData[name] = value;
                        this.setState({ activeComponentIndex, surveyData });
                } else {
                        activeComponentIndex = this.state.activeComponentIndex; //next survey-element if welcome page constant false
                        let surveyData = Object.assign({}, this.state.surveyData);    //creating copy of object of questionData
                        surveyData[name] = value;
                        this.setState({ activeComponentIndex, surveyData, [name]: value });  //if false then update into surveydata object direct
                }
                this.setState({ validationErrorMessage: '' });
        }
        surveyContentPageData = (e) => {
                let name = e.target.name;
                let fileUpload = this.state.fileUpload;
                let value;
                if (name === 'survey_welcome_image') {
                        value = e.target.files[0];
                        fileUpload = true;
                } else {
                        value = e.target.value;
                }
                let surveyData = Object.assign({}, this.state.surveyData);    //creating copy of object of surveydata
                surveyData['is_welcome_page'] = this.state.surveyData.is_welcome_page;     //update value of is welcome page after select text or image
                surveyData[name] = value;                                     //updating value of surveydata
                this.setState({ surveyData, fileUpload, errorCount: 0 });
        }
        surveyWelcomeImage = (e) => {
                const title = e.target.name;
                const value = e.target.value;
                let surveyData = Object.assign({}, this.state.surveyData);    //creating copy of object of surveydata
                surveyData[title] = parseInt(value);                        //updating value of surveydata
                this.setState({ surveyData, validationErrorMessage: '' });
        }
        // sie-du toggle on SurveyDemographic Page
        handleChangeToggle = (event) => {
                let surveyData = this.state.surveyData;
                surveyData.is_sie_demographic = this.state.surveyData.is_sie_demographic === 0 ? 1 : 0;
                this.setState({ surveyData });
        }
        onChangeDemographic = (e) => {
                const title = e.target.name;
                //const value = e.target.value;
                let surveyData = Object.assign({}, this.state.surveyData);    //creating copy of object of surveydata
                /**For All checked Checkbox saved value as object into array.*/
                // Pass the checkbox name to the function
                function getCheckedBoxes(chkboxName) {
                        var checkboxes = document.getElementsByName(chkboxName);
                        var checkboxesChecked = [];
                        // loop over them all
                        for (var i = 0; i < checkboxes.length; i++) {
                                var obj = {}
                                // And stick the checked ones onto an array...
                                if (checkboxes[i].checked) {
                                        obj['id'] = parseInt(checkboxes[i].value);
                                        checkboxesChecked.push(obj);
                                }
                        }
                        // Return the array if it is non-empty, or null
                        return checkboxesChecked.length > 0 ? checkboxesChecked : null;
                }
                var checkedBoxes = getCheckedBoxes("demographic");
                surveyData[title] = checkedBoxes;                        //updating value of surveydata
                this.setState({ surveyData });
        }
        //Function for accordian close and open in creating condition, only use both switch elements not in same state (like not open both or not close both accordian)
        changeAccordianStatus = (conditionMinus, conditionPlus, id, Dec, Inc) => {
                setTimeout (()=>{
                        var iconName = "acc_" + id;
                        var iconNextName = "acc_" + (id + 1);
                        var iconPrevName = "acc_" + (id - 1);
                        if (conditionMinus && Dec) {
                                document.getElementsByName(iconName)[0].click();
                                document.getElementsByName(iconPrevName)[0].click();
                        }
                        else if (conditionMinus && Inc) {
                                document.getElementsByName(iconName)[0].click();
                                document.getElementsByName(iconNextName)[0].click();
                        }
                        else if (conditionPlus && Inc) {
                                document.getElementsByName(iconName)[0].click();
                                document.getElementsByName(iconNextName)[0].click();
                        }
                        else if (conditionPlus && Dec) {
                                document.getElementsByName(iconName)[0].click();  
                                document.getElementsByName(iconPrevName)[0].click();
                        }
                },600);
        }
        /**Change Question Position/index in survey creating condition*/
        onChangeCreatingCondition = (e) => {
                let name = e.currentTarget.name;
                let id = parseInt(e.currentTarget.id);
                let FinalQuestionData = this.state.FinalQuestionData;
                let conditionMinus = document.getElementById("icon_" + id).classList.contains("fa-minus");
                let conditionNextMinus;
                let conditionPlus = document.getElementById("icon_" + id).classList.contains("fa-map-signs");
                let conditionNextPlus;
                let nextQuestionIndex = this.state.nextQuestionIndex;
                let prevQuestionIndex = this.state.prevQuestionIndex;
                let changeConditionDropdown = this.state.changeConditionDropdown;
                let listFadeInClass = document.getElementsByClassName("accordianFadeIn")
                let panel = document.getElementById("condition_" + id).nextSibling;
                for(let i=0; i < listFadeInClass.length; i++){
                        listFadeInClass[i].classList.remove("accordianFadeIn");
                }
                let listFadeOutClass = document.getElementsByClassName("accordianFadeOut")
                for(let i=0; i < listFadeOutClass.length; i++){
                        listFadeOutClass[i].classList.remove("accordianFadeOut");
                }
                if (name === "Decbutton") {
                        let oldPanel = document.getElementById("condition_" + (id - 1)).nextSibling;
                        document.getElementById("condition_" + (id - 1)).firstElementChild.classList.add("accordianFadeIn")
                        document.getElementById("condition_" + id).firstElementChild.classList.add("accordianFadeOut")
                        conditionNextMinus = document.getElementById("icon_" + (id - 1)).classList.contains("fa-minus");
                        conditionNextPlus = document.getElementById("icon_" + (id - 1)).classList.contains("fa-map-signs");
                        if (!(conditionMinus && conditionNextMinus) && !(conditionPlus && conditionNextPlus)) {
                                this.changeAccordianStatus(conditionMinus, conditionPlus, id, true, false);
                        }
                        else if (panel && oldPanel) {
                                if (!panel.style.maxHeight) {
                                        panel.style.maxHeight = null;
                                } else {
                                        panel.style.display = null;
                                        panel.style.maxHeight = oldPanel.scrollHeight + "px";
                                }
                                if (!oldPanel.style.maxHeight) {
                                        oldPanel.style.maxHeight = null;
                                } else {
                                        oldPanel.style.display = null;
                                        oldPanel.style.maxHeight = panel.scrollHeight + "px";
                                }
                        }
                        nextQuestionIndex = id + 1;
                        prevQuestionIndex = id;
                        changeConditionDropdown = true;
                        var b = FinalQuestionData[id]
                        FinalQuestionData[id] = FinalQuestionData[id - 1];
                        FinalQuestionData[id - 1] = b;
                }
                else if (name === "Increbutton") {
                        let newPanel = document.getElementById("condition_" + (id + 1)).nextSibling;
                        document.getElementById("condition_" + id).firstElementChild.classList.add("accordianFadeIn")
                        document.getElementById("condition_" + (id + 1)).firstElementChild.classList.add("accordianFadeOut")
                        conditionNextMinus = document.getElementById("icon_" + (id + 1)).classList.contains("fa-minus");
                        conditionNextPlus = document.getElementById("icon_" + (id + 1)).classList.contains("fa-map-signs");
                        if (!(conditionMinus && conditionNextMinus) && !(conditionPlus && conditionNextPlus)) {
                                this.changeAccordianStatus(conditionMinus, conditionPlus, id, false, true);
                        }
                        else if (panel && newPanel) {
                                if (!panel.style.maxHeight) {
                                        panel.style.maxHeight = null;
                                } else {
                                        panel.style.display = null;
                                        panel.style.maxHeight = newPanel.scrollHeight + "px";
                                }
                                if (!newPanel.style.maxHeight) {
                                        newPanel.style.maxHeight = null;
                                } else {
                                        newPanel.style.display = null;
                                        newPanel.style.maxHeight = panel.scrollHeight + "px";
                                }
                        }
                        var c = FinalQuestionData[id]
                        nextQuestionIndex = id + 1;
                        prevQuestionIndex = id + 2;
                        changeConditionDropdown = true;
                        FinalQuestionData[id] = FinalQuestionData[id + 1];
                        FinalQuestionData[id + 1] = c;
                        
                }
                let self = this ;
                setTimeout (()=>{
                        self.setState({ FinalQuestionData, changeConditionDropdown, nextQuestionIndex, prevQuestionIndex, questionDataOrder: FinalQuestionData, questionIndexUpdated: false, deletedQuestionIndex: '', checkAddQuestion: false })
                },500);
                setTimeout (()=>{
                        let listFadeInClass = document.getElementsByClassName("accordianFadeIn")
                        for(let i=0; i < listFadeInClass.length; i++){
                                listFadeInClass[i].classList.remove("accordianFadeIn");
                        }
                        let listFadeOutClass = document.getElementsByClassName("accordianFadeOut")
                        for(let i=0; i < listFadeOutClass.length; i++){
                                listFadeOutClass[i].classList.remove("accordianFadeOut");
                        }
                },600);
        }
        /**Delete question in survey creating condition*/
        handleDeleteQuestion = (event) => {
                let e;
                if (event.target.name) {
                        e = event.target;
                }
                else {
                        e = event.currentTarget;
                }
                let totalQuestionLength = 0;
                let data;
                if (this.surveyMainId) {
                        data = JSON.parse(localStorage.getItem('surveycreationallquestiondata_' + this.surveyMainId));
                }
                if (data) {
                        totalQuestionLength = data.length;
                }
                confirmAlert({
                        customUI: ({ onClose }) => {
                                return (
                                        <div className='custom-ui'>
                                                <div className="close-btn text-right">
                                                        <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                                                </div>
                                                {totalQuestionLength > 1 ?
                                                        <p className="mt-3 mb-4 font-weight-bold">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_confirm_alert']}</p>
                                                        : <p className="mt-3 mb-4">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_confirm_alert_not_delete']}</p>
                                                }
                                                {totalQuestionLength > 1 ?
                                                        <button name={e.name} id={e.id} value={e.value} onClick={(event) => {
                                                                this.onEditDeleteConditionQuestion(event, onClose);
                                                        }}>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_confirm_alert_yes_button']}</button>
                                                        : null
                                                }
                                                {totalQuestionLength > 1 ?
                                                        <button onClick={onClose}>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_confirm_alert_no_button']}</button>
                                                        : <button onClick={onClose}>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_confirm_alert_not_delete_close_button']}</button>
                                                }
                                        </div>
                                )
                        }
                })
                this.setState({ changeConditionDropdown: false, checkAddQuestion: false })
        }
        copyQuestionSurvey = (event) => {
                let storeitem = JSON.parse(localStorage.getItem('userdata'));
                let e;
                if (event.target.name) {
                        e = event.target;
                }
                else {
                        e = event.currentTarget;
                }
                let totalQuestionLength = 0;
                let data;
                if (this.surveyMainId) {
                        data = JSON.parse(localStorage.getItem('surveycreationallquestiondata_' + this.surveyMainId));
                }
                if (data) {
                        totalQuestionLength = data.length;
                }
                confirmAlert({
                                customUI: ({ onClose }) => {
                                        return (
                                                <div className='custom-ui'>
                                                        <div className="close-btn text-right">
                                                                <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                                                        </div>
                                                        <p class="font-weight-bold">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["copy_survey_question"]}</p>
                                                        {/* For plus plan user */}
                                                        {storeitem.plan_type?
                                                                <button name={e.name} id={e.id} value={e.value} onClick={(event) => {
                                                                        this.onEditDeleteConditionQuestion(event, onClose);
                                                                }}>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_welcome_page_yes_button']}</button>
                                                                : <button onClick={(event) => {
                                                                        this.setState({ redirectToCheckout: true })
                                                                        onClose()
                                                                        localStorage.setItem('redirectURL',window.location.href)
                                                                }}><i class="fas fa-lock mr-2"></i>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_welcome_page_yes_button']}</button>
                                                        }
                                                        <button onClick={onClose}>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_welcome_page_no_button']}</button>
                                                </div>
                                        )
                                }
                })
                this.setState({ changeConditionDropdown: false, checkAddQuestion: false })
        }
        onAddDeleteContent = (e) => {
                let validationErrorMessage = '';
                let errorElementPosition;
                if (e.target.name) {
                        // eslint-disable-next-line
                        e.target = e.target;
                } else {
                        e.target = e.currentTarget;
                }
                let name = e.target.name;
                // eslint-disable-next-line
                let id = e.target.id;
                let contentDataLength;
                let contentDataLimit;
                let surveyData;
                let welcomeContent = false;
                if (this.state.activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position']) {
                        contentDataLength = this.state.questionContentDataLength;
                        contentDataLimit = this.state.questionContentDataLimit;
                        surveyData = this.state.questionData;
                } else {
                        contentDataLength = this.state.contentDataLength;
                        contentDataLimit = this.state.contentDataLimit;
                        surveyData = this.state.surveyData;
                        welcomeContent = true;
                }
                let surveyCreationAllquestionData;
                if (this.surveyMainId) {
                        surveyCreationAllquestionData = JSON.parse(localStorage.getItem('surveycreationallquestiondata_' + this.surveyMainId));
                }
                if (name === "addtext") {
                        if (!surveyData['surveyContent']) {
                                surveyData['surveyContent'] = [];
                        }
                        if (contentDataLength < contentDataLimit) {
                                let obj = { "id": "", "content_text": "", "is_header": 0 }
                                surveyData['surveyContent'].push(obj);
                                contentDataLength = contentDataLength + 1;
                        }
                        else {
                                if (this.state.activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position']) {
                                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_element_page_content_max_limit'];
                                }
                                else {
                                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_welcome_content_page_max_limit'];
                                }
                                errorElementPosition = 1;
                        }
                }
                if (name === "addheader") {
                        if (!surveyData['surveyContent']) {
                                surveyData['surveyContent'] = [];
                        }
                        if (contentDataLength < contentDataLimit) {
                                let obj = { "id": "", "content_text": "", "is_header": 1 }
                                surveyData['surveyContent'].push(obj);
                                contentDataLength = contentDataLength + 1;
                        }
                        else {
                                if (this.state.activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position']) {
                                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_element_page_content_max_limit'];
                                }
                                else {
                                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_welcome_content_page_max_limit'];
                                }
                                errorElementPosition = 1;
                        }
                }
                if (name === "addimage") {
                        if (!surveyData['surveyContent']) {
                                surveyData['surveyContent'] = [];
                        }
                        if (contentDataLength < contentDataLimit) {
                                let obj = { "id": "", "content_image": "" }
                                surveyData['surveyContent'].push(obj);
                                contentDataLength = contentDataLength + 1;
                                var getId = "fileUploadEvent" + (contentDataLength - 1);
                                // eslint-disable-next-line
                                var removeId = "removeImage-" + (contentDataLength - 1);
                                setTimeout(() => {
                                        document.getElementsByClassName(getId)[0].click();
                                        // eslint-disable-next-line
                                        let value = document.getElementsByClassName(getId)[0].value;
                                }, 200);
                        }
                        else {
                                if (this.state.activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position']) {
                                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_element_page_content_max_limit'];
                                }
                                else {
                                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_welcome_content_page_max_limit'];
                                }
                                errorElementPosition = 1;
                        }
                }
                if (name === "survey_welcome_text") {
                        if (document.getElementsByClassName("get-text").length) {
                                let textData = document.getElementsByClassName("get-text");
                                /**HTMLCollection to an array using js */
                                textData = Array.prototype.slice.call(textData, 0);
                                let surveyContent;
                                if (surveyData) {
                                        if (surveyData.surveyContent) {
                                                surveyContent = surveyData.surveyContent;
                                        }
                                        else {
                                                surveyContent = [];
                                        }
                                }
                                // eslint-disable-next-line
                                textData.map((data, index) => {
                                        let position;
                                        if (data.getAttribute("data-value")) {
                                                position = parseInt(data.getAttribute("data-value"))
                                        }
                                        let textObject = {}
                                        let valueGet = data.value;
                                        if (!valueGet.replace(/\s/g, '').length || valueGet.length === 0) {
                                                valueGet = '';
                                        }
                                        textObject['id'] = (data.id) ? data.id : "";
                                        textObject['content_text'] = (valueGet) ? valueGet : "";
                                        textObject['is_header'] = 0;
                                        surveyContent[position] = textObject;
                                })
                                surveyData['surveyContent'] = surveyContent;                        //updating value of surveydata
                        }
                }
                if (name === "survey_welcome_header") {
                        if (document.getElementsByClassName("get-header").length) {
                                let textData = document.getElementsByClassName("get-header");
                                /**HTMLCollection to an array using js */
                                textData = Array.prototype.slice.call(textData, 0);
                                let surveyContent;
                                if (surveyData) {
                                        if (surveyData.surveyContent) {
                                                surveyContent = surveyData.surveyContent;
                                        }
                                        else {
                                                surveyContent = [];
                                        }
                                }
                                // eslint-disable-next-line
                                textData.map((data, index) => {
                                        let position;
                                        if (data.getAttribute("data-value")) {
                                                position = parseInt(data.getAttribute("data-value"))
                                        }
                                        let textObject = {}
                                        let valueGet = data.value;
                                        if (!valueGet.replace(/\s/g, '').length || valueGet.length === 0) {
                                                valueGet = '';
                                        }
                                        textObject['id'] = (data.id) ? data.id : "";
                                        textObject['content_text'] = (valueGet) ? valueGet : "";
                                        textObject['is_header'] = 1;
                                        surveyContent[position] = textObject;
                                })
                                surveyData['surveyContent'] = surveyContent;                        //updating value of surveydata
                        }
                }
                if (name === "survey_welcome_image") {
                        // eslint-disable-next-line
                        let imageName = "file-upload-" + e.target.title;
                        if (document.getElementsByClassName("get-file").length) {
                                let imageData = document.getElementsByClassName("get-file");
                                /**HTMLCollection to an array using js */
                                imageData = Array.prototype.slice.call(imageData, 0);
                                let surveyContent;
                                if (surveyData) {
                                        if (surveyData.surveyContent) {
                                                surveyContent = surveyData.surveyContent;
                                        } else {
                                                surveyContent = []
                                        }
                                }
                                // eslint-disable-next-line
                                imageData.map((data, index) => {
                                        let imageObject = {};
                                        let imageUrl = "";
                                        let position;
                                        if (data.getAttribute("data-value")) {
                                                position = parseInt(data.getAttribute("data-value"))
                                        }
                                        if (surveyContent[position]) {
                                                if (surveyContent[position].content_image) {
                                                        imageUrl = surveyContent[position].content_image;
                                                }
                                        }
                                        imageObject['id'] = (data.getAttribute("data-id")) ? data.getAttribute("data-id") : "";
                                        imageObject['content_image'] = (data.files[0]) ? data.files[0] : imageUrl;
                                        surveyContent[position] = imageObject;
                                })
                                surveyData['surveyContent'] = surveyContent;                        //updating value of surveydata
                        }
                }
                if (name === "removeImage") {
                        let value = e.target.value;
                        let idName = "removeImage-" + value;
                        if (surveyData) {
                                if (surveyData.surveyContent) {
                                        if (surveyData.surveyContent.length) {
                                                surveyData.surveyContent.splice(parseInt(value), 1);
                                        }
                                }
                        }
                        var element = document.getElementById(idName);
                        if (element) {
                        }
                        let contentId = e.target.getAttribute("id");
                        if (contentId) {
                                this.setState({ loading: 'true' })
                                let storeitem = JSON.parse(localStorage.getItem('userdata'));
                                let apitoken = '';
                                let userId = '';
                                if (storeitem !== null) {
                                        apitoken = storeitem.token;
                                        userId = storeitem.id;
                                }
                                var headers = {
                                        "Content-Type": "application/json",
                                        "Authorization": "Bearer " + apitoken
                                }
                                // eslint-disable-next-line
                                let data = '';
                                let self = this;
                                contentId = parseInt(contentId);
                                axios.delete(API_URL + "/survey/deleteSurveycontentById/" + LANGUAGE_CODE + '/' + contentId + '?uId=' + userId, { headers: headers })
                                        .then((response) => {
                                                data = response.data['success'];
                                                self.setState({ loading: 'false' })
                                        })
                                        .catch((error) => {
                                                if (error) {
                                                        if (error.response) {
                                                                if (error.response.data) {
                                                                        let msg = error.response.data['error']
                                                                        if (msg) {
                                                                                if (msg.length) {
                                                                                        if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                                                localStorage.clear();
                                                                                                self.setState({ redirect: 'true' })
                                                                                        }
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                }
                                                self.setState({ loading: 'false' })
                                        })
                                if (welcomeContent) {
                                        if (surveyData) {
                                                if (this.surveyMainId) {
                                                        localStorage.setItem("surveycreationdata_" + this.surveyMainId, JSON.stringify(surveyData));
                                                }
                                        }
                                }
                                else {
                                        if (surveyCreationAllquestionData[this.state.ActiveQuestionIndex]) {
                                                if (surveyCreationAllquestionData[this.state.ActiveQuestionIndex].surveyContent) {
                                                        surveyCreationAllquestionData[this.state.ActiveQuestionIndex] = surveyData;
                                                        if (this.surveyMainId) {
                                                                localStorage.setItem("surveycreationallquestiondata_" + this.surveyMainId, JSON.stringify(surveyCreationAllquestionData));
                                                        }
                                                }
                                        }
                                }
                        }
                        contentDataLength = contentDataLength - 1;
                }
                if (name === "removeText") {
                        let value = e.target.value;
                        let idName = "removeText-" + value;
                        element = document.getElementById(idName);
                        if (surveyData) {
                                if (surveyData.surveyContent) {
                                        if (surveyData.surveyContent.length) {
                                                surveyData.surveyContent.splice(parseInt(value), 1);
                                        }
                                }
                        }
                        let contentId = e.target.getAttribute("id");
                        if (contentId) {
                                this.setState({ loading: 'true' })
                                let storeitem = JSON.parse(localStorage.getItem('userdata'));
                                let apitoken = '';
                                let userId = '';
                                if (storeitem !== null) {
                                        apitoken = storeitem.token;
                                        userId = storeitem.id;
                                }
                                headers = {
                                        "Content-Type": "application/json",
                                        "Authorization": "Bearer " + apitoken
                                }
                                // eslint-disable-next-line
                                let data = '';
                                let self = this;
                                contentId = parseInt(contentId);
                                axios.delete(API_URL + "/survey/deleteSurveycontentById/" + LANGUAGE_CODE + '/' + contentId + '?uId=' + userId, { headers: headers })
                                        .then((response) => {
                                                data = response.data['success'];
                                                self.setState({ loading: 'false' })
                                        })
                                        .catch((error) => {
                                                if (error) {
                                                        if (error.response) {
                                                                if (error.response.data) {
                                                                        let msg = error.response.data['error']
                                                                        if (msg) {
                                                                                if (msg.length) {
                                                                                        if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                                                localStorage.clear();
                                                                                                self.setState({ redirect: 'true' })
                                                                                        }
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                }
                                                self.setState({ loading: 'false' })
                                        })
                                if (welcomeContent) {
                                        if (surveyData) {
                                                if (this.surveyMainId) {
                                                        localStorage.setItem("surveycreationdata_" + this.surveyMainId, JSON.stringify(surveyData));
                                                }
                                        }
                                }
                                else {
                                        if (surveyCreationAllquestionData[this.state.ActiveQuestionIndex]) {
                                                if (surveyCreationAllquestionData[this.state.ActiveQuestionIndex].surveyContent) {
                                                        surveyCreationAllquestionData[this.state.ActiveQuestionIndex] = surveyData;
                                                        if (this.surveyMainId) {
                                                                localStorage.setItem("surveycreationallquestiondata_" + this.surveyMainId, JSON.stringify(surveyCreationAllquestionData));
                                                        }
                                                }
                                        }
                                }
                        }
                        contentDataLength = contentDataLength - 1;
                }
                if (this.state.activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position']) {
                        this.setState({ 'questionData': surveyData, 'questionContentDataLimit': contentDataLimit, errorElementPosition, 'questionContentDataLength': contentDataLength, validationErrorCount: 0, validationErrorMessage, fileUploadErrorMessage: '' })
                } else {
                        this.setState({ surveyData, contentDataLimit, contentDataLength, validationErrorCount: 0, validationErrorMessage, fileUploadErrorMessage: '' })
                }
                e.preventDefault();
        }
        dataProtectionContent = (e) => {
                const title = e.target.name;
                const value = e.target.value;
                this.setState(prevState => ({ surveyCreationData: prevState.surveyCreationData.set(title, value) }));
                let surveyData = Object.assign({}, this.state.surveyData);    //creating copy of object
                surveyData[title] = value;                        //updating value
                this.setState({ surveyData, validationErrorMessage: '' });

        }
        /* --- Check if Any question has index of question being deleted - do not delete if yes--- */
        errorMessageDeleteMessage = (errorMsg) => {
                confirmAlert({
                        customUI: ({ onClose }) => {
                                return (
                                        <div className='custom-ui'>
                                                <div className="close-btn text-right">
                                                        <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                                                </div>
                                                <p>{errorMsg}</p>
                                                <button onClick={onClose} >{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['popup_button_ok_text']}</button>
                                        </div>
                                )
                        }
                })
        }

        validateDeleteQuestion(deleteIndex, closePopup) {
                let allDropdowns = document.getElementsByClassName("question-condition-select").length;
                let self = this;
                let errorMsg;
                for (let i = 0; i < allDropdowns; i++) {
                        if (parseInt(document.getElementsByClassName("question-condition-select")[i].value) === deleteIndex + 1) {
                                errorMsg = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_question_delete_error_1'] + " " + (parseInt(document.getElementsByClassName("question-condition-select")[i].id) + 1) + SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_question_delete_error_2'];
                                self.errorMessageDeleteMessage(errorMsg);
                                return false;
                        }
                }
                return true;
        }
        changeOpenCloseAccordianAfterDelete = async (startLength, totalLength) => {
                for (let j = startLength; j < totalLength; j++) {
                        let x = document.getElementById("acc_" + (j + 1))
                        var panel;
                        if (document.getElementById("condition_" + (j + 1))) {
                                panel = document.getElementById("condition_" + (j + 1)).nextSibling;
                        }
                        var panelPrev = document.getElementById("condition_" + (j)).nextSibling
                        if (x) {
                                if (x.className.indexOf("visible") !== -1) {
                                        document.getElementById("acc_" + (j)).classList.remove('d-none');
                                        document.getElementById("acc_" + (j)).classList.remove('hidden')
                                        document.getElementById("acc_" + (j)).classList.add('visible')
                                        if (panel) {
                                                panelPrev.style.display = null;
                                                panelPrev.style.maxHeight = panel.scrollHeight + "px";
                                        }
                                        document.getElementById("condition_" + (j)).classList.add('change-bottom-border-accordian');
                                        document.getElementById("icon_" + (j)).classList.remove('fa-map-signs');
                                        document.getElementById("icon_" + (j)).classList.add('fa-minus');
                                }
                                else if (x.className.indexOf("hidden") !== -1) {
                                        document.getElementById("acc_" + (j)).classList.remove('d-none');
                                        document.getElementById("acc_" + (j)).classList.add('hidden')
                                        document.getElementById("acc_" + (j)).classList.remove('visible')
                                        if (panel) {
                                                if (panelPrev.style.maxHeight) {
                                                        panelPrev.style.maxHeight = null;
                                                }
                                        }
                                        document.getElementById("condition_" + (j)).classList.remove('change-bottom-border-accordian');
                                        document.getElementById("icon_" + (j)).classList.remove('fa-minus');
                                        document.getElementById("icon_" + (j)).classList.add('fa-map-signs');
                                }
                        }
                }
        }
        onEditDeleteConditionQuestion = async (e, closePopup) => {
                let name;
                let ActiveQuestionIndex;
                let questionId;
                let deletedQuestionIndex = ''
                let questionIndexUpdated = false
                let checkAddQuestion = false;
                let addQuestionIndexCheck = '';
                let backButtonClick = true;
                let event = e.currentTarget;
                if (e.target.name) {
                        name = e.target.name;
                        ActiveQuestionIndex = parseInt(e.target.value);
                        questionId = e.target.id;
                }
                else {
                        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                        if (!isMobile && e.type === "mousemove") {
                                event = e.target;
                        }
                        name = event.name ? event.name : event.className && event.className.length && event.className.split(" ")[event.className.split(" ").length - 1];
                        if (name === "EditButton") {
                                ActiveQuestionIndex = parseInt(event.value);
                        } else {
                                ActiveQuestionIndex = parseInt(event.value) ? parseInt(event.value) : (Array.isArray(closePopup) ? closePopup[0] : closePopup);
                        }
                        questionId = event.id ? event.id : (Array.isArray(closePopup) ? closePopup[1] : event.id);
                        if (name === "ButtonChange") {
                               event = e.currentTarget;
                        }
                }
                var mainDiv = document.getElementById("errorSurveyMessage");
                if (mainDiv) {
                        if (mainDiv.hasChildNodes()) {
                                mainDiv.innerHTML = '';
                        }
                }
                let questionDataOrder = this.state.questionDataOrder;
                if (questionDataOrder.length === 0) {
                        if (this.surveyMainId) {
                                questionDataOrder = JSON.parse(localStorage.getItem('surveycreationallquestiondata_' + this.surveyMainId));
                        }
                }
                if (name === "AddQuestion") {
                        let scrollPosition = window.scrollY;
                        if (!localStorage.getItem('scrollposition_' + this.surveyMainId) && this.surveyMainId) {
                                localStorage.setItem("scrollposition_" + this.surveyMainId, JSON.stringify(scrollPosition));
                        }
                        addQuestionIndexCheck = questionId;
                        var questionDataNew = { "option": [], "scale_option_text": [] }
                        this.setState({ activeComponentIndex: SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'], questionData: questionDataNew, addQuestionIndex: questionId, checkAddQuestion, addQuestionIndexCheck })
                        e.preventDefault();
                }
                else if (name === "CopyQuestionButton") {
                        backButtonClick = false;
                        if (this.surveyMainId) {
                                localStorage.setItem("surveycreationallquestiondata_" + this.surveyMainId, JSON.stringify(questionDataOrder));
                        }
                        if (questionId) {
                                let value = ActiveQuestionIndex;
                                        this.setState({ loading: 'true' })
                                        let storeitem = JSON.parse(localStorage.getItem('userdata'));
                                        let apitoken = '';
                                        let userId = '';
                                        if (storeitem !== null) {
                                                apitoken = storeitem.token;
                                                userId = storeitem.id;
                                        }
                                        let headers = {
                                                "Content-Type": "application/json",
                                                "Authorization": "Bearer " + apitoken
                                        }
                                        // eslint-disable-next-line
                                        let data = '';
                                        let self = this;
                                        let id = parseInt(questionId);
                                        await axios.get(API_URL + "/survey/copyquestion/" + LANGUAGE_CODE + '/' + id + '/' + userId +'?uId=' + userId, { headers: headers })
                                                .then((response) => {
                                                        const copyQuestionData = response.data['success'];
                                                        var question_order = copyQuestionData.question_order
                                                        questionIndexUpdated = true;
                                                        var data = questionDataOrder
                                                        for(var i= 0; i< data.length; i++)
                                                        {
                                                                if(data[i].question_order >= question_order)
                                                                {
                                                                        data[i].question_order = data[i].question_order + 1;
                                                                }
                                                                if(data[i].option)
                                                                {
                                                                        let option_arr = data[i].option;
                                                                        for(var j=0;j<option_arr.length;j++)
                                                                        {
                                                                                if(data[i].option[j].option_skip_question >= question_order)
                                                                                {
                                                                                        var skip_option =  data[i].option[j].option_skip_question
                                                                                        data[i].option[j].option_skip_question = parseInt(skip_option) + 1;
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                        data.splice(question_order - 1, 0 , copyQuestionData )
                                                        questionDataOrder = data
                                                        let daraorder =  questionDataOrder.sort((a,b) => a.question_order - b.question_order)
                                                        localStorage.setItem("surveycreationallquestiondata_" + this.surveyMainId, JSON.stringify(daraorder))
                                                        self.setState({ loading: 'false' })
                                                        /* --- Close Popup --- */
                                                        closePopup();
                                                })
                                                .catch((error) => {
                                                        if (error) {
                                                                if (error.response) {
                                                                        if (error.response.data) {
                                                                                let msg = error.response.data['error']
                                                                                if (msg) {
                                                                                        if (Object.keys(msg).length) {
                                                                                                /**Map for object of error and append span of message into div having id errorSurveyMessage */
                                                                                                // eslint-disable-next-line
                                                                                                Object.keys(msg).map((msgdata, index) => {
                                                                                                        if (msg[msgdata] === SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_delete_question_backend_error']) {
                                                                                                                var node = document.createElement("p");
                                                                                                                var textnode = document.createTextNode(msg[msgdata]);
                                                                                                                node.appendChild(textnode);
                                                                                                                var mainDiv = document.getElementById("errorSurveyMessage");
                                                                                                                if (mainDiv) mainDiv.appendChild(node);
                                                                                                        }
                                                                                                        if (msg[msgdata] === "Fragennummer nicht gefunden" || msg[msgdata] === "Question id not found") {
                                                                                                                questionDataOrder.splice(value, 1);
                                                                                                                questionIndexUpdated = true;
                                                                                                                deletedQuestionIndex = ActiveQuestionIndex;
                                                                                                                self.setState({ loading: 'false', questionDataOrder, deletedQuestionIndex: ActiveQuestionIndex })
                                                                                                        }
                                                                                                })
                                                                                        }
                                                                                        if (msg.length) {
                                                                                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                                                        localStorage.clear();
                                                                                                        self.setState({ redirect: 'true' })
                                                                                                }
                                                                                        }
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                        self.setState({ loading: 'false' })
                                                })
                                }
                        }
                else if (name === "EditDemographicButton") {
                        let scrollPosition = window.scrollY;
                        if (!localStorage.getItem('scrollposition_' + this.surveyMainId) && this.surveyMainId) {
                                localStorage.setItem("scrollposition_" + this.surveyMainId, JSON.stringify(scrollPosition));
                        }
                        this.setState({ activeComponentIndex: SURVEY_CREATION_POSITION_CONSTANT['survey_demographic_position'], changeDemographicButton: true })
                }
                else if (name === "EditClosingPageButton") {
                        let scrollPosition = window.scrollY;
                        if (!localStorage.getItem('scrollposition_' + this.surveyMainId) && this.surveyMainId) {
                                localStorage.setItem("scrollposition_" + this.surveyMainId, JSON.stringify(scrollPosition));
                        }
                        this.setState({ activeComponentIndex: SURVEY_CREATION_POSITION_CONSTANT['define_closing_page'], changeClosingPageButton: true })
                }
                else if (name === "EditdataProtectionPageButton") {
                        let scrollPosition = window.scrollY;
                        if (!localStorage.getItem('scrollposition_' + this.surveyMainId) && this.surveyMainId) {
                                localStorage.setItem("scrollposition_" + this.surveyMainId, JSON.stringify(scrollPosition));
                        }
                        this.setState({ activeComponentIndex: SURVEY_CREATION_POSITION_CONSTANT['survey_data_protection'], changeDataProtectionPageButton: true })
                }
                else if (name === "EditWelcomPage") {
                        let scrollPosition = window.scrollY;
                        if (!localStorage.getItem('scrollposition_' + this.surveyMainId) && this.surveyMainId) {
                                localStorage.setItem("scrollposition_" + this.surveyMainId, JSON.stringify(scrollPosition));
                        }
                        this.setState({ activeComponentIndex: SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_page_position'], editWelcomePage: true })
                }
                else if (name === "EditButton") {
                        let scrollPosition = window.scrollY;
                        if (!localStorage.getItem('scrollposition_' + this.surveyMainId) && this.surveyMainId) {
                                localStorage.setItem("scrollposition_" + this.surveyMainId, JSON.stringify(scrollPosition));
                        }
                        if (ActiveQuestionIndex >= 0) {
                                let questionContentDataLength = this.state.questionContentDataLength;
                                if (questionDataOrder[ActiveQuestionIndex]) {
                                        let editQuestionData = questionDataOrder[ActiveQuestionIndex];
                                        if (editQuestionData.surveyContent) {
                                                if (editQuestionData.surveyContent.length) {
                                                        questionContentDataLength = editQuestionData.surveyContent.length;
                                                }
                                        }
                                        this.setState({ questionData: editQuestionData })
                                }
                                this.setState({ ActiveQuestionIndex, questionContentDataLength, activeComponentIndex: SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'], editQuestionRedirect: true, validationErrorCount: 0 })
                        }
                }
                else if (name === "DeleteButton") {
                        backButtonClick = false;
                        if (this.surveyMainId) {
                                localStorage.setItem("surveycreationallquestiondata_" + this.surveyMainId, JSON.stringify(questionDataOrder));
                        }
                        if (questionId) {
                                let value = ActiveQuestionIndex;
                                if (this.validateDeleteQuestion(value, closePopup)) {
                                        this.changeOpenCloseAccordianAfterDelete(value, questionDataOrder.length);
                                        let surveyCreationData;
                                        if (this.surveyMainId) {
                                                surveyCreationData = JSON.parse(localStorage.getItem('surveycreationdata_' + this.surveyMainId));
                                        }
                                        let surveyId = parseInt(surveyCreationData.id);
                                        this.setState({ loading: 'true' })
                                        let storeitem = JSON.parse(localStorage.getItem('userdata'));
                                        let apitoken = '';
                                        let userId = '';
                                        if (storeitem !== null) {
                                                apitoken = storeitem.token;
                                                userId = storeitem.id;
                                        }
                                        var headers = {
                                                "Content-Type": "application/json",
                                                "Authorization": "Bearer " + apitoken
                                        }
                                        // eslint-disable-next-line
                                        let data = '';
                                        let self = this;
                                        let id = parseInt(questionId);
                                        await axios.delete(API_URL + "/survey/deleteQuestionById/" + LANGUAGE_CODE + '/' + id + '/' + (value + 1) + '/' + surveyId + '?uId=' + userId, { headers: headers })
                                                .then((response) => {
                                                        data = response.data['success'];
                                                        questionDataOrder.splice(value, 1);
                                                        questionIndexUpdated = true;
                                                        deletedQuestionIndex = ActiveQuestionIndex;
                                                        self.setState({ loading: 'false', questionDataOrder, deletedQuestionIndex: ActiveQuestionIndex })
                                                        /* --- Close Popup --- */
                                                        closePopup();
                                                })
                                                .catch((error) => {
                                                        if (error) {
                                                                if (error.response) {
                                                                        if (error.response.data) {
                                                                                let msg = error.response.data['error']
                                                                                if (msg) {
                                                                                        if (Object.keys(msg).length) {
                                                                                                /**Map for object of error and append span of message into div having id errorSurveyMessage */
                                                                                                // eslint-disable-next-line
                                                                                                Object.keys(msg).map((msgdata, index) => {
                                                                                                        if (msg[msgdata] === SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_delete_question_backend_error']) {
                                                                                                                var node = document.createElement("p");
                                                                                                                var textnode = document.createTextNode(msg[msgdata]);
                                                                                                                node.appendChild(textnode);
                                                                                                                var mainDiv = document.getElementById("errorSurveyMessage");
                                                                                                                if (mainDiv) mainDiv.appendChild(node);
                                                                                                        }
                                                                                                        if (msg[msgdata] === "Fragennummer nicht gefunden" || msg[msgdata] === "Question id not found") {
                                                                                                                questionDataOrder.splice(value, 1);
                                                                                                                questionIndexUpdated = true;
                                                                                                                deletedQuestionIndex = ActiveQuestionIndex;
                                                                                                                self.setState({ loading: 'false', questionDataOrder, deletedQuestionIndex: ActiveQuestionIndex })
                                                                                                        }
                                                                                                })
                                                                                        }
                                                                                        if (msg.length) {
                                                                                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                                                        localStorage.clear();
                                                                                                        self.setState({ redirect: 'true' })
                                                                                                }
                                                                                        }
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                        self.setState({ loading: 'false' })
                                                })
                                }
                                if (this.surveyMainId) {
                                        localStorage.setItem("surveycreationallquestiondata_" + this.surveyMainId, JSON.stringify(questionDataOrder));
                                }
                        }
                }
                /**This function not using now  */
                else if (name === "ButtonChange") {
                        let optionId = "";
                        backButtonClick = false;
                        if (questionDataOrder[questionId]) {
                                if (questionDataOrder[questionId]['option']) {
                                        if (questionDataOrder[questionId]['option'][0]) {
                                                if (questionDataOrder[questionId]['option'][0]['id']) {
                                                        optionId = questionDataOrder[questionId]['option'][0]['id'];
                                                }
                                        }
                                }
                        }
                        if (questionDataOrder[questionId]) {
                                if (!questionDataOrder[questionId]['conditional'] || parseInt(questionDataOrder[questionId]['conditional']) === 2) {
                                        questionDataOrder[questionId]['conditional'] = false;
                                } else if (parseInt(questionDataOrder[questionId]['conditional']) === 1) {
                                        questionDataOrder[questionId]['conditional'] = true;
                                }
                        }
                        if (event.length) {
                                questionDataOrder[questionId]['question_order'] = questionId + 1;
                                var getName = "button_" + event.id;
                                var optionDiv = document.getElementsByClassName(getName);
                                if (questionDataOrder[questionId]) {
                                        if (questionDataOrder[questionId]['option']) {
                                                if (questionDataOrder[questionId]['option'][0]) {
                                                }
                                                else {
                                                        questionDataOrder[questionId]['option'][0] = {}
                                                }
                                        }
                                        else {
                                                questionDataOrder[questionId]['option'] = [{}]
                                        }
                                }
                                let data = questionDataOrder[questionId]['option'][0];
                                data['id'] = optionId;
                                if (parseInt(optionDiv[optionDiv.length - 1].value) === 0) {
                                        data['option_end_survey'] = 1;
                                        data['option_skip_question'] = "";
                                }
                                else {
                                        if (optionDiv[optionDiv.length - 1].value) {
                                                questionDataOrder[questionId]['conditional'] = true;
                                                data['option_skip_question'] = optionDiv[optionDiv.length - 1].value;
                                                data['option_end_survey'] = 0;
                                        }
                                        else {
                                                questionDataOrder[questionId]['conditional'] = false;
                                                data['option_skip_question'] = "";
                                        }
                                }
                        }
                        else {
                                if (questionDataOrder[questionId]) {
                                        if (questionDataOrder[questionId]['option']) {
                                                if (questionDataOrder[questionId]['option'][0]) {
                                                }
                                                else {
                                                        questionDataOrder[questionId]['option'][0] = {}
                                                }
                                        }
                                        else {
                                                questionDataOrder[questionId]['option'] = [{}]
                                        }
                                }
                                let data = questionDataOrder[questionId]['option'][0];
                                questionDataOrder[questionId]['question_order'] = questionId + 1;
                                // eslint-disable-next-line
                                var getName = "button_" + event.id;
                                // eslint-disable-next-line
                                var optionDiv = document.getElementsByClassName(getName);
                                data['id'] = optionId;
                                data['option_text'] = optionDiv[event.value - 1].value;
                                if (parseInt(optionDiv[optionDiv.length - 1].value) === 0) {
                                        data['option_end_survey'] = 1;
                                        data['option_skip_question'] = "";
                                }
                                else {
                                        if (optionDiv[optionDiv.length - 1].value) {
                                                questionDataOrder[questionId]['conditional'] = true;
                                                data['option_skip_question'] = optionDiv[optionDiv.length - 1].value;
                                                data['option_end_survey'] = 0;
                                        }
                                        else {
                                                questionDataOrder[questionId]['conditional'] = false;
                                                data['option_skip_question'] = "";
                                        }
                                }
                        }
                }
                else {
                        // eslint-disable-next-line
                        var orderDiv = document.getElementsByClassName("creating-condition");
                        // eslint-disable-next-line
                        var getName = "option_" + (e.target.id ? e.target.id : (event.id ? event.id : (Array.isArray(closePopup) ? closePopup[1] : event.id)));
                        // eslint-disable-next-line
                        optionDiv = document.getElementsByClassName(getName);
                        var j = 0;
                        backButtonClick = false;
                        if (orderDiv) {
                                for (let i = 0; i < orderDiv.length; i++) {
                                        // eslint-disable-next-line
                                        var objectOrder = {};
                                        questionDataOrder[i]['id'] = parseInt(orderDiv[j].getAttribute("value"));
                                        questionDataOrder[i]['question_order'] = parseInt(orderDiv[j].id);
                                        if (!questionDataOrder[i]['conditional'] || parseInt(questionDataOrder[i]['conditional']) === 2) {
                                                questionDataOrder[i]['conditional'] = false;
                                        } else if (parseInt(questionDataOrder[i]['conditional']) === 1) {
                                                questionDataOrder[i]['conditional'] = true;
                                        }
                                        let objectOptionArray = [];
                                        let p = 0;
                                        let optionId = "";
                                        let length;
                                        let checkConditional = true;
                                        if (optionDiv) {
                                                length = optionDiv.length / 2;
                                                for (let q = 0; q < length; q++) {
                                                        var objectOption = {};
                                                        if (questionDataOrder[questionId] && questionDataOrder[questionId]['option']) {
                                                                if (questionDataOrder[questionId]['option'][q]) {
                                                                        objectOption = questionDataOrder[questionId]['option'][q];
                                                                        if (questionDataOrder[questionId]['option'][q]['id']) {
                                                                                optionId = questionDataOrder[questionId]['option'][q]['id'];
                                                                        }
                                                                        else {
                                                                                optionId = "";
                                                                        }
                                                                }
                                                                else {
                                                                        optionId = "";
                                                                }
                                                        }
                                                        else {
                                                                optionId = "";
                                                        }
                                                        objectOption['id'] = optionId;
                                                        let rangevalue1;
                                                        let rangevalue2;
                                                        let prevData = objectOption['option_text'];
                                                        let indexOption = event.id && event.className && event.className.length && event.className.split(" ")[event.className.split(" ").length - 2] && event.className.split(" ")[event.className.split(" ").length - 2].length ? event.className && event.className.length && parseInt(event.className.split(" ")[event.className.split(" ").length - 2]) : (Array.isArray(closePopup) ? closePopup[2] : null);
                                                        if (!optionDiv[p].value) {
                                                                objectOption['option_text'] = optionDiv[p].getAttribute("value") ? optionDiv[p].getAttribute("value") : (q === indexOption) ? (Array.isArray(closePopup) ? closePopup[0] : closePopup) : optionDiv[q].childNodes[2].value;

                                                                if (parseInt(questionDataOrder[questionId].question_type_id) === 6 && parseInt(questionDataOrder[questionId].scale_type) === 4) {
                                                                        rangevalue1 = optionDiv[0].getAttribute("value") ? optionDiv[0].getAttribute("value") : (0 === indexOption) ? (Array.isArray(closePopup) ? closePopup[0] : closePopup) : optionDiv[0].childNodes[2].value;
                                                                        rangevalue2 = optionDiv[1].getAttribute("value") ? optionDiv[1].getAttribute("value") : (1 === indexOption) ? (Array.isArray(closePopup) ? closePopup[0] : closePopup) : optionDiv[1].childNodes[2].value;
                                                                        if ((rangevalue1 || rangevalue1 === 0) && rangevalue2) {
                                                                                if (rangevalue1 > rangevalue2) {
                                                                                }
                                                                                if (parseInt(rangevalue1) < parseInt(rangevalue2)) {
                                                                                        objectOption['option_text'] = rangevalue1 + "," + rangevalue2;
                                                                                }
                                                                                else {
                                                                                        objectOption['option_text'] = prevData;
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                        else {
                                                                objectOption['option_text'] = optionDiv[p].value ? optionDiv[p].value : (q === indexOption) ? (Array.isArray(closePopup) ? closePopup[0] : closePopup) : optionDiv[q].childNodes[2].value;

                                                                if (parseInt(questionDataOrder[questionId].question_type_id) === 6 && parseInt(questionDataOrder[questionId].scale_type) === 4) {
                                                                        rangevalue1 = optionDiv[0].value ? optionDiv[0].value : (0 === indexOption) ? (Array.isArray(closePopup) ? closePopup[0] : closePopup) : optionDiv[0].childNodes[2].value;
                                                                        rangevalue2 = optionDiv[1].value ? optionDiv[1].value : (1 === indexOption) ? (Array.isArray(closePopup) ? closePopup[0] : closePopup) : optionDiv[1].childNodes[2].value;
                                                                        if ((rangevalue1 || rangevalue1 === 0) && rangevalue2) {
                                                                                if (rangevalue1 > rangevalue2) {
                                                                                }
                                                                                if (parseInt(rangevalue1) < parseInt(rangevalue2)) {
                                                                                        objectOption['option_text'] = rangevalue1 + "," + rangevalue2;
                                                                                }
                                                                                else {
                                                                                        objectOption['option_text'] = prevData;
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                        let v;
                                                        if (parseInt(questionDataOrder[questionId].question_type_id) === 6 && parseInt(questionDataOrder[questionId].scale_type) === 4) {
                                                                v = p + 2;
                                                        }
                                                        else {
                                                                v = p + 1;
                                                        }
                                                        if (optionDiv[v]) {
                                                                if (parseInt(optionDiv[v].value) === 0) {
                                                                        objectOption['option_end_survey'] = 1;
                                                                        objectOption['option_skip_question'] = "";
                                                                }
                                                                else {
                                                                        if (i === parseInt(questionId)) {
                                                                                if (optionDiv[v].value && parseInt(optionDiv[v].value) !== 0) {
                                                                                        if (questionDataOrder[i]['conditional'] === false) {
                                                                                                questionDataOrder[i]['conditional'] = true;
                                                                                        }
                                                                                        checkConditional = true;
                                                                                }
                                                                                else {
                                                                                        checkConditional = false;
                                                                                }
                                                                        }
                                                                        objectOption['option_skip_question'] = optionDiv[v].value;
                                                                        objectOption['option_end_survey'] = 0;
                                                                }
                                                        }
                                                        objectOptionArray.push(objectOption);
                                                        p = p + 2;
                                                }
                                        }
                                        if (i === parseInt(questionId)) {
                                                questionDataOrder[i]['option'] = objectOptionArray;                        //updating value of questionData for option
                                                if (checkConditional === false) {
                                                        questionDataOrder[i]['conditional'] = false;
                                                }
                                        }
                                        j = j + 1;
                                }
                        }
                }
                this.setState({ backButtonClick, questionDataOrder, FinalQuestionData: questionDataOrder, changeConditionDropdown: false, deletedQuestionIndex, questionIndexUpdated, checkAddQuestion, addQuestionIndexCheck })
        }
        section = (data) => {
                var surveyComponent = data;
                switch (surveyComponent) {
                        case 0:
                                return <SurveyTitle oldvalue={this.state.surveyCreationData} value={this.state.surveyData} validationMessage={this.state.validationErrorMessage} onChange={this.surveyTitle} backButtonClick={this.state.backButtonClick} titleLengthAllowed={this.state.titleLengthAllowed} titleLengthLeft={this.state.titleLengthLeft} titleLengthReached={this.state.titleLengthReached} />
                        case 1:
                                return <SurveyCategory oldvalue={this.state.surveyCreationData} value={this.state.surveyData} validationMessage={this.state.validationErrorMessage} onChange={this.surveyCategory} dropDownData={this.state.dropDownData} backButtonClick={this.state.backButtonClick} />
                        case 2:
                                return <SurveyPicture data={this.state.surveyData} onClick={this.surveyWelcomeImage} validationMessage={this.state.validationErrorMessage} backButtonClick={this.state.backButtonClick} />
                        case 3:
                                return <SurveyWelcomePage data={this.state.surveyData} onClick={this.surveyWelcomePage} validationMessage={this.state.validationErrorMessage} backButtonClick={this.state.backButtonClick} />
                        case 4:
                                return <SurveyContentPage stateData={this.state} data={this.state.surveyData} errorMessage={this.state.fileUploadErrorMessage} onClick={this.onChangeImageUpload} onUpdate={this.onAddDeleteContent} validationMessage={this.state.validationErrorMessage} backButtonClick={this.state.backButtonClick} />
                        // case 5:
                        //         return <SurveyWelcomeText backButtonClick={this.state.backButtonClick}/>
                        case 5:
                                return <SurveyElement stateData={this.state} errorElementPosition={this.state.errorElementPosition} data={this.state.questionData} design={true} onChange={this.surveyElement} optionLimit={this.state.optionLimit} optionScaleLimit={this.state.optionScaleLimit} validationMessage={this.state.validationErrorMessage} optionScaleCount={this.state.optionScaleCount} optionCount={this.state.optionCount} message={this.state.questionComponentErrorMessage} onUpdate={this.onAddDeleteContent} onSelect={this.surveyElementContent} backButtonClick={this.state.backButtonClick} />
                        case 6:
                                return <SurveyDemographic value={this.state.surveyData} onChange={this.onChangeDemographic} backButtonClick={this.state.backButtonClick} sieDuToggle={this.handleChangeToggle} />
                        case 7:
                                return <SurveyCreatingConditions changeQuestionOptionData={this.changeQuestionOptionData} changeConditionDropdown={this.state.changeConditionDropdown} nextQuestionIndex={this.state.nextQuestionIndex} prevQuestionIndex={this.state.prevQuestionIndex} saveCondition={this.saveCondition} childIndex={this.state.childCreatingConditionIndex} data={this.state.FinalQuestionData} value={this.state.surveyData} onClick={this.onEditDeleteConditionQuestion} onDelete={this.handleDeleteQuestion} onChange={this.onChangeCreatingCondition} deletedQuestionIndex={this.state.deletedQuestionIndex} checkAddQuestion={this.state.checkAddQuestion} questionIndexUpdated={this.state.questionIndexUpdated} addQuestionIndexCheck={this.state.addQuestionIndexCheck} backButtonClick={this.state.backButtonClick}  onCopyQuestion={this.copyQuestionSurvey} />
                        case 9:
                                return <SurveyDefineClosingPage SurveyId = {this.surveyMainId} validationMessage={this.state.validationErrorMessage} backButtonClick={this.state.backButtonClick} />
                        case 10:
                                return <SurveyCustomLogo data={this.state.surveyData} backButtonClick={this.state.backButtonClick} validationMessage={this.state.validationErrorMessage} />
                        case 11:
                                return <SurveyDataProtectionPage SurveyId = {this.surveyMainId} validationMessage={this.state.validationErrorMessage} backButtonClick={this.state.backButtonClick} data={this.state.surveyData} onChange={this.dataProtectionContent}/>
                        default:
                                return <SurveyEnd value={this.state.updateSurveyStatusById} backButtonClick={this.state.backButtonClick} />
                }
        }
        onChangeImageUpload = (e) => {
                if (e.target.files) {
                        let ext = e.target.files[0].type.split('/').pop().toLowerCase(); //get extension from type of file
                        let size = (e.target.files[0].size / 1024 / 1024).toFixed(2); //image size get into MB
                        let fileUploadErrorMessage = '';
                        this.setState({ fileUploadErrorMessage });
                        if (ext !== 'png' && ext !== 'jpeg' && ext !== 'jpg') {
                                fileUploadErrorMessage = "Please upload image having exentension png jpeg jpg ";
                                this.setState({ fileUploadErrorMessage, errorCount: 1 });
                        }
                        else if (size > 5) {
                                fileUploadErrorMessage = "Please upload file size having less then 5MB";
                                this.setState({ fileUploadErrorMessage, errorCount: 1 });
                        }
                        else {
                                this.surveyContentPageData(e);
                        }
                }
                else {
                        this.surveyContentPageData(e);
                }
        }
        clickOnPreview = (e) => {
                e.preventDefault();
                if (this.surveyMainId) {
                        localStorage.removeItem("surveydetail_" + this.surveyMainId)
                        localStorage.removeItem("surveyparticipationquestion_" + this.surveyMainId);
                }
                let scrollPosition = window.scrollY;
                if (!localStorage.getItem('scrollposition_' + this.surveyMainId) && this.surveyMainId) {
                        localStorage.setItem("scrollposition_" + this.surveyMainId, JSON.stringify(scrollPosition));
                }
                this.saveCondition(e);
        }
        saveCondition = (e) => {
                if (this.surveyCreatingConditionRedirect === false) {
                        this.surveyCreatingConditionRedirect = true;
                        this.previewOpenInNewTab = true;
                        this.titleSlug = this.state.surveyData.survey_title_slug;
                }
                this.continue(e);
                this.setState({ changeConditionDropdown: false })
        }
        /**use async await for waiting axios call response */
        continue = async (e) => {
                e.preventDefault();
                let errorCount = this.state.errorCount;
                let activeComponentIndex = this.state.activeComponentIndex;
                let FinalQuestionData = this.state.FinalQuestionData;
                let ActiveQuestionIndex = this.state.ActiveQuestionIndex;
                let questionComponentErrorMessage = this.state.questionComponentErrorMessage;
                let questionFileUpload = this.state.questionFileUpload;
                let questionFileUploadCount = this.state.questionFileUploadCount;
                let surveyData = this.state.surveyData;
                let questionData = this.state.questionData;
                let is_welcome_page = surveyData.is_welcome_page;
                let userId = this.state.userId;
                let editQuestionRedirect = this.state.editQuestionRedirect;
                let fileUpload = this.state.fileUpload;
                let is_image;
                let redirectToDemographicComponent = false;
                let questionOptionSplice;
                let validationErrorCount = this.state.validationErrorCount;
                let validationErrorMessage = this.state.validationErrorMessage;
                let questionOptionCount = this.state.questionOptionCount;
                let questionOptionCondition = this.state.questionOptionCondition;
                let questionScaleOptionCondition = this.state.questionScaleOptionCondition;
                let questionScaleOptionCount = this.state.questionScaleOptionCount;
                let storeitem = JSON.parse(localStorage.getItem('userdata'));
                let userUniversity = storeitem.university;
                let addQuestionIndex = this.state.addQuestionIndex;
                let checkAddQuestion = this.state.checkAddQuestion;
                let RedirectToMypolls = this.state.RedirectToMypolls;
                let editWelcomePage = this.state.editWelcomePage;
                let notRedirect = false;
                let nextComponentAfterElement = false;
                let surveyCreationData;
                let updateCategoryWithOthers = true;
                let current_school = storeitem.current_school;
                let organization = storeitem.organization;
                if (this.surveyMainId) {
                        surveyCreationData = JSON.parse(localStorage.getItem('surveycreationdata_' + this.surveyMainId));
                }
                let questionDataOrder = this.state.questionDataOrder;
                let childCreatingConditionIndex = this.state.childCreatingConditionIndex;
                let qTypeId;
                let errorImageCount = 0;
                let changeDemographicButton = this.state.changeDemographicButton;
                let changeClosingPageButton = this.state.changeClosingPageButton;
                let changeDataProtectionPageButton = this.state.changeDataProtectionPageButton
                if (questionData) {
                        if (editQuestionRedirect) {
                                if (questionDataOrder[ActiveQuestionIndex]) {
                                        if (parseInt(questionData.question_type_id) === 6 && parseInt(questionData.scale_type) !== 5) {
                                                if (parseInt(questionData.scale_type) === parseInt(questionDataOrder[ActiveQuestionIndex]['scale_type'])) {
                                                        questionData['deleteQuestionData'] = true;
                                                        if (questionData.option) {
                                                                /** Remove double option in question type - 6 and scale type 1 to 4 */
                                                                var removeQuestionOption = false;
                                                                var removeQuestionOptionkey;
                                                                if(questionData.option.length){
                                                                        if(parseInt(questionData.scale_type) === 1 && questionData.option.length > 10){
                                                                                removeQuestionOption = true;
                                                                                removeQuestionOptionkey = 10;
                                                                        }
                                                                        if(parseInt(questionData.scale_type) === 2 && questionData.option.length > 6){
                                                                                removeQuestionOption = true;
                                                                                removeQuestionOptionkey = 6;
                                                                        }
                                                                        if(parseInt(questionData.scale_type) === 3 && questionData.option.length > 5){
                                                                                removeQuestionOption = true;
                                                                                removeQuestionOptionkey = 5;
                                                                        }
                                                                        if(parseInt(questionData.scale_type) === 4 && questionData.option.length > 1){
                                                                                removeQuestionOption = true;
                                                                                removeQuestionOptionkey = 1;
                                                                        }

                                                                        if(removeQuestionOption && removeQuestionOptionkey){
                                                                                questionData.option.splice(removeQuestionOptionkey,questionData.option.length-removeQuestionOptionkey);
                                                                        }
                                                                }
                                                                // eslint-disable-next-line
                                                                questionData.option.map((data, index) => {
                                                                        data['id'] = "";
                                                                })
                                                                questionDataOrder[ActiveQuestionIndex] = questionData;
                                                        }
                                                }
                                        }
                                        if (parseInt(questionData.question_type_id) !== 6 && parseInt(questionData.question_type_id) !== 7) {
                                                if (questionData.hasOwnProperty('scale_type')) {
                                                        if (questionData.scale_type) {
                                                                questionData.scale_type = null;
                                                                questionDataOrder[ActiveQuestionIndex] = questionData;
                                                        }
                                                }
                                        }
                                }
                        }
                }
                /**Defalut validation of all component */
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_title_position']) {
                        let surveyTitleLength = 0;
                        if (surveyData) {
                                if (surveyData['survey_title']) {
                                        let checkValidationData = surveyData['survey_title'].trim();
                                        surveyTitleLength =  surveyData['survey_title'].length;
                                        if(surveyTitleLength > 100){
                                                validationErrorCount = validationErrorCount + 1;
                                                
                                        }
                                        surveyData['survey_title'] = checkValidationData;
                                        if (checkValidationData === "" || checkValidationData === undefined || checkValidationData === null) {
                                                validationErrorCount = validationErrorCount + 1;
                                        }
                                }
                                else {
                                        validationErrorCount = validationErrorCount + 1;
                                }
                        }
                        else {
                                validationErrorCount = validationErrorCount + 1;
                        }
                        if (validationErrorCount > 0 && surveyTitleLength <= 100) {
                                validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_title_page'];
                        }
                        else if (surveyTitleLength > 100 && validationErrorCount > 0 ){
                                validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_title_limit'];
                        }else {
                                validationErrorMessage = '';
                        }
                }
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_category_position']) {
                        if (surveyData) {
                                if (surveyData['category_id']) {
                                        if (surveyData['category_id'] === "" || surveyData['category_id'] === undefined || surveyData['category_id'] === null) {
                                                validationErrorCount = validationErrorCount + 1;
                                        }
                                }
                                else {
                                        validationErrorCount = validationErrorCount + 1;
                                }
                        }
                        else {
                                validationErrorCount = validationErrorCount + 1;
                        }
                        if (validationErrorCount > 0) {
                                validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_category_page'];
                        }
                        else {
                                validationErrorMessage = '';
                        }
                }
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_picture_position']) {
                        if (surveyData) {
                                let surveypic = document.getElementsByName("survey_picture");
                                var surveypicValue;
                                for (var i = 0; i < surveypic.length; i++) {
                                        if (surveypic[i].checked === true) {
                                                surveypicValue = surveypic[i].value;
                                                surveyData['survey_picture'] = parseInt(surveypicValue);
                                        }
                                }
                                let picture = ''
                                if (surveypicValue) {
                                        picture = surveyData['survey_picture'];
                                }
                                if (picture) {
                                        if (picture === "" || picture === undefined || picture === null) {
                                                validationErrorCount = validationErrorCount + 1;
                                        }
                                }
                                else {
                                        validationErrorCount = validationErrorCount + 1;
                                }
                        }
                        else {
                                validationErrorCount = validationErrorCount + 1;
                        }
                        if (validationErrorCount > 0) {
                                validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_picture_page'];
                        }
                        else {
                                validationErrorMessage = '';
                        }
                }
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_page_position']) {
                        if (surveyData) {
                                let welcomeCondition = document.getElementsByName("is_welcome_page");
                                var welcomeValue;
                                for (i = 0; i < welcomeCondition.length; i++) {
                                        if (welcomeCondition[i].checked === true) {
                                                welcomeValue = welcomeCondition[i].value;
                                        }
                                }
                                if (welcomeValue) {
                                        if (surveyData['is_welcome_page'] === "" || surveyData['is_welcome_page'] === undefined || surveyData['is_welcome_page'] === null) {
                                                validationErrorCount = validationErrorCount + 1;
                                        }
                                }
                                else {
                                        validationErrorCount = validationErrorCount + 1;
                                }
                        }
                        else {
                                validationErrorCount = validationErrorCount + 1;
                        }
                        if (validationErrorCount > 0) {
                                validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_welcome_page'];
                        }
                        else {
                                validationErrorMessage = '';
                        }
                }
                /**Check welcome page condition from survey data if 2 then send as false and if 1 then send as true */
                if (surveyData) {
                        if (surveyData.hasOwnProperty("is_welcome_page")) {
                                if (surveyData.is_welcome_page === "2" || surveyData.is_welcome_page === 2) {
                                        surveyData.is_welcome_page = false;
                                }
                                if (surveyData.is_welcome_page === "1" || surveyData.is_welcome_page === 1) {
                                        surveyData.is_welcome_page = true;
                                }
                        }
                }
                /**Add data of welcome page on continue */
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_content_position']) {
                        if (document.getElementsByClassName("get-text").length) {
                                let textData = document.getElementsByClassName("get-text");
                                /**HTMLCollection to an array using js */
                                textData = Array.prototype.slice.call(textData, 0);
                                let surveyContent;
                                if (this.state.surveyData) {
                                        if (this.state.surveyData.surveyContent) {
                                                surveyContent = this.state.surveyData.surveyContent;
                                        }
                                        else {
                                                surveyContent = [];
                                        }
                                }
                                // eslint-disable-next-line
                                textData.map((data, index) => {
                                        let position;
                                        if (data.getAttribute("data-value")) {
                                                position = parseInt(data.getAttribute("data-value"))
                                        }
                                        let textObject = {}
                                        textObject['id'] = (data.id) ? data.id : "";
                                        textObject['content_text'] = (data.value) ? data.value : "";
                                        textObject['is_header'] = (data.is_header && parseInt(data.is_header)) ? data.is_header : 0;
                                        surveyContent[position] = textObject;
                                })
                                let surveyData = Object.assign({}, this.state.surveyData);    //creating copy of object of surveydata
                                surveyData['surveyContent'] = surveyContent;                        //updating value of surveydata
                                this.setState({ surveyData })
                        }
                        if (document.getElementsByClassName("get-file").length) {
                                let imageData = document.getElementsByClassName("get-file");
                                /**HTMLCollection to an array using js */
                                imageData = Array.prototype.slice.call(imageData, 0);
                                let surveyContent = this.state.surveyContent;
                                if (this.state.surveyData) {
                                        if (this.state.surveyData.surveyContent) {
                                                surveyContent = this.state.surveyData.surveyContent;
                                        } else {
                                                surveyContent = []
                                        }
                                }
                                // eslint-disable-next-line
                                imageData.map((data, index) => {
                                        let imageObject = {};
                                        let imageUrl = "";
                                        let position;
                                        if (data.getAttribute("data-value")) {
                                                position = parseInt(data.getAttribute("data-value"))
                                        }
                                        if (surveyContent[position]) {
                                                if (surveyContent[position].content_image) {
                                                        imageUrl = surveyContent[position].content_image;
                                                }
                                        }
                                        imageObject['id'] = (data.getAttribute("data-id")) ? data.getAttribute("data-id") : "";
                                        imageObject['content_image'] = (data.files[0]) ? data.files[0] : imageUrl;
                                        surveyContent[position] = imageObject;
                                })
                                let surveyData = Object.assign({}, this.state.surveyData);    //creating copy of object of surveydata
                                surveyData['surveyContent'] = surveyContent;                        //updating value of surveydata
                                this.setState({ surveyData })
                        }
                }
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_content_position']) {
                        let contentTextValidation = false;
                        let contentImageValidation = false;
                        let contentAllValidation = false;
                        let fileUploadErrorMessage = '';
                        if (surveyData) {
                                if (surveyData.surveyContent) {
                                        if (surveyData.surveyContent.length === 0) {
                                                validationErrorCount = validationErrorCount + 1;
                                                contentAllValidation = true;
                                        } else {
                                                if (surveyData.surveyContent) {
                                                        // eslint-disable-next-line
                                                        surveyData.surveyContent.map((data, index) => {
                                                                if (data.hasOwnProperty("content_text")) {
                                                                        if (data.content_text === "" || data.content_text === null || data.content_text === undefined) {
                                                                                validationErrorCount = validationErrorCount + 1;
                                                                                contentTextValidation = true;
                                                                                if (contentImageValidation) {
                                                                                        contentAllValidation = true;
                                                                                }
                                                                        }
                                                                }
                                                                if (data.hasOwnProperty("content_image")) {
                                                                        if (data.content_image === "" || data.content_image === null || data.content_image === undefined) {
                                                                                validationErrorCount = validationErrorCount + 1;
                                                                                contentImageValidation = true;
                                                                                if (contentTextValidation) {
                                                                                        contentAllValidation = true;
                                                                                }
                                                                        }
                                                                        else {
                                                                                if (data.content_image.length === undefined) {
                                                                                        let ext = data.content_image.type.split('/').pop().toLowerCase(); //get extension from type of file
                                                                                        let size = (data.content_image.size / 1024 / 1024).toFixed(2); //image size get into MB
                                                                                        if (ext !== 'png' && ext !== 'jpeg' && ext !== 'jpg') {
                                                                                                fileUploadErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_welcome_content_page_image_ext'];
                                                                                        }
                                                                                        else if (size > 5) {
                                                                                                fileUploadErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_welcome_content_page_image_size'];
                                                                                        }
                                                                                        if (fileUploadErrorMessage) {
                                                                                                errorImageCount = 1;
                                                                                        }
                                                                                        this.setState({ fileUploadErrorMessage });
                                                                                }
                                                                        }
                                                                }
                                                        })
                                                }
                                        }
                                } else {
                                        validationErrorCount = validationErrorCount + 1;
                                        contentAllValidation = true;
                                }
                        }
                        else {
                                validationErrorCount = validationErrorCount + 1;
                                contentAllValidation = true;
                        }
                        if (validationErrorCount > 0 && contentAllValidation) {
                                validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_welcome_content_page'];
                        } else if (validationErrorCount > 0 && contentTextValidation) {
                                validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_welcome_content_page_text'];
                        } else if (validationErrorCount > 0 && contentImageValidation) {
                                validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_welcome_content_page_image'];
                        }
                        else {
                                validationErrorMessage = '';
                        }
                }
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position']) {
                        var questionValidation = false;
                        var optionValidation = false;
                        var scaleValidation = false;
                        var scaleOptionValidation = false;
                        var errorElementPosition = this.state.errorElementPosition;
                        let contentTextValidation = false;
                        let contentImageValidation = false;
                        let contentAllValidation = false;
                        let questionImageExtValidation = false;
                        let questionImageSizeValidation = false;
                        let questionTypeValidation = false;
                        if (surveyData) {
                                if (FinalQuestionData) {
                                        if (questionData) {
                                                if (!questionData.question_type_id) {
                                                        if (FinalQuestionData.length === 0) {
                                                                validationErrorCount = validationErrorCount + 1;
                                                        }
                                                        else {
                                                                questionTypeValidation = true;
                                                                validationErrorCount = validationErrorCount + 1;
                                                        }
                                                } else {
                                                        var id = parseInt(questionData.question_type_id);
                                                        if (id !== 2) {
                                                                if (!questionData.question_text) {
                                                                        validationErrorCount = validationErrorCount + 1;
                                                                        questionValidation = true;
                                                                        errorElementPosition = 1;
                                                                }
                                                                else {
                                                                        if (questionData.question_text === "" || questionData.question_text === undefined || questionData.question_text === null) {
                                                                                validationErrorCount = validationErrorCount + 1;
                                                                                questionValidation = true;
                                                                                errorElementPosition = 1;
                                                                        }
                                                                }
                                                                if (questionValidation === false) {
                                                                        if (id === 6 || id === 7) {
                                                                                if (questionData.scale_type) {
                                                                                        var scale = parseInt(questionData.scale_type);
                                                                                        if (scale === 5 && id === 7) {
                                                                                                if (questionData.scale_option_text) {
                                                                                                        // eslint-disable-next-line
                                                                                                        questionData.scale_option_text.map((data, index) => {
                                                                                                                if (data.scale_text === "" || data.scale_text === undefined || data.scale_text === null) {
                                                                                                                        validationErrorCount = validationErrorCount + 1;
                                                                                                                        scaleOptionValidation = true;
                                                                                                                        errorElementPosition = 4;
                                                                                                                }
                                                                                                        })
                                                                                                }
                                                                                                else {
                                                                                                        validationErrorCount = validationErrorCount + 1;
                                                                                                        scaleOptionValidation = true;
                                                                                                        errorElementPosition = 4;
                                                                                                }
                                                                                        }
                                                                                } else {
                                                                                        validationErrorCount = validationErrorCount + 1;
                                                                                        scaleValidation = true;
                                                                                        errorElementPosition = 3;
                                                                                }
                                                                        }
                                                                }
                                                                if (questionValidation === false && scaleValidation === false) {
                                                                        if (id !== 1 && (id === 6 && parseInt(questionData.scale_type) === 5)) {
                                                                                if (!questionData.option) {
                                                                                        validationErrorCount = validationErrorCount + 1;
                                                                                        questionValidation = true;
                                                                                        errorElementPosition = 2;
                                                                                }
                                                                                else {
                                                                                        if (questionData.option[0].option_text) {
                                                                                                if (questionData.option[0].option_text === "" || questionData.option[0].option_text === undefined || questionData.option[0].option_text === null) {
                                                                                                        validationErrorCount = validationErrorCount + 1;
                                                                                                        optionValidation = true;
                                                                                                        errorElementPosition = 2;
                                                                                                }

                                                                                        } else {
                                                                                                validationErrorCount = validationErrorCount + 1;
                                                                                                optionValidation = true;
                                                                                                errorElementPosition = 2;
                                                                                        }
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                        else {
                                                                if (questionData) {
                                                                        if (questionData.surveyContent) {
                                                                                if (questionData.surveyContent.length === 0) {
                                                                                        validationErrorCount = validationErrorCount + 1;
                                                                                        contentAllValidation = true;
                                                                                        errorElementPosition = 1;
                                                                                } else {
                                                                                        if (questionData.surveyContent) {
                                                                                                // eslint-disable-next-line
                                                                                                questionData.surveyContent.map((data, index) => {
                                                                                                        if (data.hasOwnProperty("content_text")) {
                                                                                                                if (data.content_text === "" || data.content_text === null || data.content_text === undefined) {
                                                                                                                        validationErrorCount = validationErrorCount + 1;
                                                                                                                        contentTextValidation = true;
                                                                                                                        if (contentImageValidation) {
                                                                                                                                contentAllValidation = true;
                                                                                                                        }
                                                                                                                        errorElementPosition = 1;
                                                                                                                }
                                                                                                        }
                                                                                                        if (data.hasOwnProperty("content_image")) {
                                                                                                                if (data.content_image === "" || data.content_image === null || data.content_image === undefined) {
                                                                                                                        validationErrorCount = validationErrorCount + 1;
                                                                                                                        contentImageValidation = true;
                                                                                                                        if (contentTextValidation) {
                                                                                                                                contentAllValidation = true;
                                                                                                                        }
                                                                                                                        errorElementPosition = 1;
                                                                                                                }
                                                                                                                else {
                                                                                                                        if (data.content_image.length === undefined) {
                                                                                                                                let ext = data.content_image.type.split('/').pop().toLowerCase(); //get extension from type of file
                                                                                                                                let size = (data.content_image.size / 1024 / 1024).toFixed(2); //image size get into MB
                                                                                                                                if (ext !== 'png' && ext !== 'jpeg' && ext !== 'jpg') {
                                                                                                                                        questionImageExtValidation = true;
                                                                                                                                        errorElementPosition = 1;
                                                                                                                                        validationErrorCount = validationErrorCount + 1;
                                                                                                                                }
                                                                                                                                else if (size > 5) {
                                                                                                                                        questionImageSizeValidation = true;
                                                                                                                                        errorElementPosition = 1;
                                                                                                                                        validationErrorCount = validationErrorCount + 1;
                                                                                                                                }
                                                                                                                        }
                                                                                                                }
                                                                                                        }
                                                                                                })
                                                                                        }
                                                                                }
                                                                        } else {
                                                                                validationErrorCount = validationErrorCount + 1;
                                                                                contentAllValidation = true;
                                                                                errorElementPosition = 1;
                                                                        }
                                                                }
                                                                else {
                                                                        validationErrorCount = validationErrorCount + 1;
                                                                        contentAllValidation = true;
                                                                        errorElementPosition = 1;
                                                                }
                                                        }

                                                }
                                        }
                                }
                                else {
                                        validationErrorCount = validationErrorCount + 1;
                                }
                        }
                        else {
                                validationErrorCount = validationErrorCount + 1;
                        }
                        if (validationErrorCount > 0 && id !== 2) {
                                notRedirect = true;
                                if (questionValidation === true) {
                                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_element_page_question'];
                                }
                                else if (scaleValidation === true) {
                                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_element_page_scale'];
                                }
                                else if (scaleOptionValidation === true) {
                                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_element_page_scale_option'];
                                }
                                else if (optionValidation === true) {
                                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_element_page_option'];
                                }
                                else {
                                        if(questionTypeValidation) {
                                                validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_element_page_type'];
                                                errorElementPosition = 0;
                                        }
                                        else {
                                                validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_element_page'];
                                                errorElementPosition = 0;
                                        }
                                }
                        }
                        else if (validationErrorCount > 0 && id === 2) {
                                notRedirect = true;
                                if (validationErrorCount > 0 && contentAllValidation) {
                                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_welcome_content_page'];
                                } else if (validationErrorCount > 0 && contentTextValidation) {
                                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_welcome_content_page_text'];
                                } else if (validationErrorCount > 0 && contentImageValidation) {
                                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_welcome_content_page_image'];
                                }
                                else if (validationErrorCount > 0 && questionImageExtValidation) {
                                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_element_page_content_image_ext'];
                                }
                                else if (validationErrorCount > 0 && questionImageSizeValidation) {
                                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_element_page_content_image_size'];
                                }
                                else {
                                        validationErrorMessage = '';
                                }
                        }
                        else {
                                validationErrorMessage = '';
                        }
                        this.setState({ errorElementPosition })
                }
                if(activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['define_closing_page'])
                {
                       let headingValue = document.getElementById('headingValue').value;
                       let freetextValue = document.getElementById('freetextValue').value;
                       if(!((headingValue && freetextValue) || (headingValue === '' && freetextValue === '' )))
                       {
                               validationErrorCount = validationErrorCount + 1;
                       }
                       if (validationErrorCount > 0) {
                        validationErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_define_closing_page'];
                        }
                        else {
                                validationErrorMessage = '';
                                this.setState({ loading: 'true' })
                                var headers = {
                                        "Content-Type": "application/json",
                                        "Authorization": "Bearer " + storeitem.token
                                }
                                await axios.post(API_URL + '/survey/saveSurveyendPageInfo/' + LANGUAGE_CODE + "/" + this.surveyMainId + "/" + userId + '?uId=' + userId,{survey_end_page_title:headingValue,survey_end_page_description:freetextValue}, { headers: headers })
                                .then((response) => {
                                        if (response.data['success']) {
                                               console.log(response.data['success'])
                                        }
                                        this.setState({ loading: 'false' });
                                })
                                .catch((error) => {
                                        if (error) {
                                                if (error.response) {
                                                        if (error.response.data) {
                                                                let msg = error.response.data['error']
                                                                if (msg) {
                                                                        if (Object.keys(msg).length) {
                                                                                /**Map for object of error and append span of message into div having id errorSurveyMessage */
                                                                                // eslint-disable-next-line
                                                                                Object.keys(msg).map((msgdata, index) => {
                                                                                        errorCount = errorCount + 1;
                                                                                        var node = document.createElement("p");
                                                                                        var textnode = document.createTextNode(msg[msgdata]);
                                                                                        node.appendChild(textnode);
                                                                                        var mainDiv = document.getElementById("errorSurveyMessage");
                                                                                        if (mainDiv) mainDiv.appendChild(node);
                                                                                })
                                                                        }
                                                                        if (msg.length) {
                                                                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                                        localStorage.clear();
                                                                                        this.setState({ redirect: 'true' })
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                }
                                        }
                                        this.setState({ loading: 'false' })
                                })
                        }
                }
                if(validationErrorCount){
                        this.scrollUpFunction(true);
                }
                else {
                        this.scrollUpFunction();
                }
                /**Add step into surveydata for welcome image or text validation */
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_content_position']) {
                        surveyData['step'] = SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_content_position'];
                }
                else {
                        delete surveyData.step;
                }
                /**If get end date and other field from backend then remove and send data after*/
                if (surveyData) {
                        if (surveyData.hasOwnProperty("end_date")) {
                                delete surveyData.end_date;
                        }
                        if (surveyData.hasOwnProperty("about_survey_text")) {
                                delete surveyData.about_survey_text;
                        }
                        if (surveyData.hasOwnProperty("about_target_group_text")) {
                                delete surveyData.about_target_group_text;
                        }
                        if (surveyData.hasOwnProperty("reward_type")) {
                                delete surveyData.reward_type;
                        }
                        if (surveyData.hasOwnProperty("reward_description")) {
                                delete surveyData.reward_description;
                        }
                }
                /**create question order for component no 7 - creating condition*/
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position']) {
                        if (questionDataOrder.length === 0) {
                                if (this.surveyMainId) {
                                        questionDataOrder = JSON.parse(localStorage.getItem('surveycreationallquestiondata_' + this.surveyMainId));
                                }
                        }
                        /**change condition*/
                        var orderDiv = document.getElementsByClassName("creating-condition");
                        var selectDropdown = document.getElementsByClassName("question-condition-select");
                        var j = 0;
                        let englishbuttonlabels;
                        englishbuttonlabels = this.state.surveyData.englishbuttonlabels == "1" ? "EN" : "DE"
                        if (questionDataOrder && orderDiv) {
                                if (questionDataOrder.length > 0) {
                                        for (let i = 0; i < orderDiv.length; i++) {
                                                var allOptionChange = questionDataOrder[i]["option"];
                                                if (orderDiv[i].getAttribute("value")) {
                                                        questionDataOrder[i]['id'] = parseInt(orderDiv[i].getAttribute("value"));
                                                }
                                                questionDataOrder[i]['question_order'] = parseInt(orderDiv[i].id);
                                                var z = 0;
                                                var checkCondition = false;
                                                var optionArray = [];
                                                for (j = 0; j < selectDropdown.length; j++) {
                                                        if (parseInt(selectDropdown[j]['id']) === i) {
                                                                var objectOptionChange = (allOptionChange) ? (allOptionChange[z]) ? allOptionChange[z] : {} : {};
                                                                if (!allOptionChange) {
                                                                        objectOptionChange['id'] = "";
                                                                        objectOptionChange['option_text'] = "";
                                                                        if (questionDataOrder[i]) {
                                                                                if (questionDataOrder[i].question_type_id) {
                                                                                        var qId = parseInt(questionDataOrder[i].question_type_id);
                                                                                        var qScale = parseInt(questionDataOrder[i].scale_type);
                                                                                        if (qId === 6 && qScale === 2) {
                                                                                                objectOptionChange['option_text'] = SCHOOL_GRADE_SCALE_CONSTANT[LANGUAGE_CODE][z]['title'];
                                                                                        }
                                                                                        if (qId === 6 && qScale === 6) {
                                                                                                objectOptionChange['option_text'] = SURVEY_APPROVAL_QUESTION_ANSWER[LANGUAGE_CODE][z]['title'];
                                                                                        }
                                                                                        if (qId === 6 && qScale === 4) {
                                                                                                objectOptionChange['option_text'] = 50;
                                                                                        }
                                                                                        if (qId === 6 && qScale === 3) {
                                                                                                objectOptionChange['option_text'] = (z + 1);
                                                                                        }
                                                                                        if (qId === 6 && qScale === 1) {
                                                                                                objectOptionChange['option_text'] = (z + 1);
                                                                                        }
                                                                                }
                                                                        }
                                                                }
                                                                else {
                                                                        if (!allOptionChange[z]) {
                                                                                if (questionDataOrder[i]) {
                                                                                        // for free text in question type 3 only (first time)
                                                                                        if (parseInt(questionDataOrder[i].question_type_id) === 3 && parseInt(questionDataOrder[i].is_free_text) === 1) {
                                                                                                objectOptionChange['id'] = "";
                                                                                                objectOptionChange['option_text'] = "free_text_condition";
                                                                                                objectOptionChange['option_image'] = "";
                                                                                        }
                                                                                }
                                                                        }
                                                                 }
                                                                if (selectDropdown[j]['value']) {
                                                                        if (parseInt(selectDropdown[j]['value']) === 0) {
                                                                                objectOptionChange['option_skip_question'] = "";
                                                                                objectOptionChange['option_end_survey'] = 1;
                                                                        }
                                                                        else {
                                                                                delete objectOptionChange['option_skip_question'];
                                                                                objectOptionChange['option_skip_question'] = parseInt(selectDropdown[j]['value']);
                                                                                checkCondition = true;
                                                                                objectOptionChange['option_end_survey'] = 0;
                                                                        }
                                                                }
                                                                else {
                                                                        if (!objectOptionChange.hasOwnProperty('id')) {
                                                                                objectOptionChange['id'] = "";
                                                                        }
                                                                        objectOptionChange['option_skip_question'] = "";
                                                                        objectOptionChange['option_end_survey'] = 0;
                                                                }
                                                                optionArray[z] = objectOptionChange;
                                                                z = z + 1;
                                                        }
                                                }
                                                questionDataOrder[i]['conditional'] = checkCondition;
                                                if (optionArray.length) {
                                                        questionDataOrder[i]['option'] = optionArray;                        //updating value of questionData for option
                                                }
                                        }
                                }
                        }
                }
                /**Remove survey welcome text and image if is_welcome_page false */
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_page_position'] && (is_welcome_page === false)) {
                        delete surveyData.survey_welcome_image;
                        delete surveyData.survey_welcome_text;
                }
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position']) {
                        if (questionData.question_type_id) {
                                redirectToDemographicComponent = true;
                                nextComponentAfterElement = true;
                        }
                        questionComponentErrorMessage = '';
                        errorCount = 0;
                }
                /**Validation for question selection component in Survey Element - no 5 */
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && (parseInt(questionData.question_type_id) === 3 || parseInt(questionData.question_type_id) === 4 || parseInt(questionData.question_type_id) === 5 || parseInt(questionData.question_type_id) === 7 || (parseInt(questionData.scale_type) === 5 && parseInt(questionData.question_type_id) === 6))) {
                        if (questionData.question_type_id) {
                                redirectToDemographicComponent = true;
                        }
                        let questionOption = questionData.option;
                        let questionOptionLength;
                        if (questionOption) {
                                questionOptionLength = questionOption.length;
                        }
                        let questionFileUploadLength = document.getElementsByClassName("get-image-preview").length;
                        let count = 0;
                        let isImageCheck = 0;
                        Array.from(document.getElementsByClassName("get-image-preview")).forEach((img, index) => {
                                if (img.src === "" || img.src === null || img.src === undefined) {
                                        questionFileUploadCount = questionFileUploadCount + 1;
                                        count = count + 1;
                                }
                                else {
                                        isImageCheck = isImageCheck + 1;
                                }
                        })
                        if (questionFileUploadCount > 0) {
                                if (questionFileUploadCount !== questionFileUploadLength) {
                                        questionFileUpload = false;
                                } else {
                                        questionFileUploadCount = 0;
                                }
                        }
                        if (isImageCheck > 0) {
                                is_image = true;
                        }
                        let free_text_option_count = 0;
                        if (questionOptionLength) {
                                for (i = 0; i < questionOptionLength; i++) {
                                        if (questionFileUploadCount > 0) {
                                                if (questionOption[i].option_text === null || questionOption[i].option_text === '') {
                                                        questionOptionCount = questionOptionCount + 1;
                                                }
                                        }
                                        else {
                                                if(questionOption[i].option_text === "free_text_condition" || questionOption[i].option_text === "" || questionOption[i].option_text === null){
                                                        free_text_option_count++;
                                                }
                                                if (i !== (questionOptionLength - 1)) {
                                                        if (questionOption[i].option_text === null || questionOption[i].option_text === '') {
                                                                questionOptionCount = questionOptionCount + 1;
                                                        }
                                                }
                                                else if (i === (questionOptionLength - 1)) {
                                                        if ((is_image === false || is_image === undefined) && questionData.question_type_id !== 7 && questionData.question_type_id !== 5 && questionData.question_type_id !== 6) {
                                                                if (questionOption[i].option_text === null || questionOption[i].option_text === '' || questionOption[i].option_text === "free_text_condition") {
                                                                        questionOptionSplice = i;
                                                        }
                                                        } else {
                                                                if (questionOption[i].option_text === null || questionOption[i].option_text === '') {
                                                                        questionOptionCount = questionOptionCount + 1;
                                                                }
                                                        }
                                                }
                                        }
                                }
                                if (questionOptionCount > 0 || free_text_option_count > 1) {
                                        questionOptionCondition = false;
                                        errorCount = 1;
                                }
                                else if (questionOptionCount === 0 && questionFileUploadCount === 0) {
                                        errorCount = 0;
                                }
                        }
                }
                /** Check for scale text error in question type 7*/
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && parseInt(questionData.question_type_id) === 7 && parseInt(questionData.scale_type) === 5) {
                        let questionScaleOption = questionData.scale_option_text;
                        if (questionScaleOption) {
                                let questionScaleOptionLength = questionScaleOption.length;
                                if (questionScaleOptionLength) {
                                        for (i = 0; i < questionScaleOptionLength; i++) {
                                                if (questionScaleOption[i].scale_text === null || questionScaleOption[i].scale_text === '') {
                                                        questionScaleOptionCount = questionScaleOptionCount + 1;
                                                }
                                        }
                                }
                        }
                        if (questionScaleOptionCount > 0) {
                                questionScaleOptionCondition = false;
                                errorCount = 1;
                        }
                }
                /**For create new survey for only survey Title page */
                if (userId && (surveyCreationData === null || surveyCreationData === undefined) && activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_title_position'] && errorCount === 0 && validationErrorCount === 0) {
                        let accountType = storeitem.account_type_category_id
                        this.setState({ loading: 'true' })
                        let apitoken = '';
                        let userId = '';
                        if (storeitem !== null) {
                                apitoken = storeitem.token;
                                userId = storeitem.id;
                        }
                        var headers = {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + apitoken
                        }
                        surveyData['user_id'] = userId;
                        surveyData['language_code'] = LANGUAGE_CODE;
                        if(accountType <= 2){
                                surveyData['university_id'] = userUniversity;
                        }else if(accountType === 3){
                                surveyData['current_school'] = current_school;
                        }else if(accountType === 4){
                                surveyData['organization'] = organization;
                        }
                        surveyData['status'] = 2;
                        let self = this;
                        let surveyCreation;
                        var mainDiv = document.getElementById("errorSurveyMessage");
                        if (mainDiv) {
                                if (mainDiv.hasChildNodes()) {
                                        mainDiv.innerHTML = '';
                                }
                        }
                        // eslint-disable-next-line
                        await axios.post(API_URL + "/survey/createSurvey" + '?uId=' + userId, surveyData, { headers: headers })
                                .then((response) => {
                                        if (response.data['success']) {
                                                surveyCreation = response.data['success'].surveydata;
                                                if (surveyCreation) {
                                                        if (surveyCreation.id) {
                                                                this.surveyMainId = parseInt(surveyCreation.id);
                                                        }
                                                }
                                                if (this.surveyMainId) {
                                                        localStorage.setItem("surveycreationdata_" + this.surveyMainId, JSON.stringify(surveyCreation));
                                                }
                                                let surveyCreationDataNew = Object.assign({}, this.state.surveyData);    //creating copy of object of surveyCreationdata of localStorage
                                                /**Update data of local Storage if exist into respone of axios call */
                                                // eslint-disable-next-line
                                                Object.keys(surveyCreation).map((data1, index) => {
                                                        surveyCreationDataNew[data1] = surveyCreation[data1];
                                                })
                                                this.setState({ 'surveyData': surveyCreationDataNew })
                                        }
                                        self.setState({ loading: 'false' });
                                })
                                .catch((error) => {
                                        if (error) {
                                                if (error.response) {
                                                        if (error.response.data) {
                                                                let msg = error.response.data['error']
                                                                if (msg) {
                                                                        if (Object.keys(msg).length) {
                                                                                /**Map for object of error and append span of message into div having id errorSurveyMessage */
                                                                                // eslint-disable-next-line
                                                                                Object.keys(msg).map((msgdata, index) => {
                                                                                        errorCount = errorCount + 1;
                                                                                        var node = document.createElement("p");
                                                                                        var textnode = document.createTextNode(msg[msgdata]);
                                                                                        node.appendChild(textnode);
                                                                                        var mainDiv = document.getElementById("errorSurveyMessage");
                                                                                        if (mainDiv) mainDiv.appendChild(node);
                                                                                })
                                                                        }
                                                                        if (msg.length) {
                                                                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                                        localStorage.clear();
                                                                                        self.setState({ redirect: 'true' })
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                }
                                        }
                                        self.setState({ loading: 'false' })
                                })
                }
                /**This call for only welcome content page  */
                if (userId && surveyCreationData && activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_content_position'] && errorCount === 0 && errorImageCount === 0 && validationErrorCount === 0) {
                        let formData = new FormData();
                        this.setState({ loading: 'true' })
                        let apitoken = '';
                        let userId = '';
                        if (storeitem !== null) {
                                apitoken = storeitem.token;
                                userId = storeitem.id;
                        }
                        // eslint-disable-next-line
                        Object.keys(surveyData).map((data1, index) => {
                                if (data1 === "surveyContent") {
                                        formData.delete('surveyContentFile[]')
                                        for (i = 0; i < surveyData[data1].length; i++) {
                                                if (surveyData[data1][i]) {
                                                        surveyData[data1][i].content_order = i + 1;
                                                        if(surveyData[data1][i].content_text){
                                                                surveyData[data1][i].content_text = surveyData[data1][i].content_text.replace(/<br\s*\/?>/mg, "");
                                                        }
                                                        if (surveyData[data1][i].content_image) {
                                                                if (surveyData[data1][i].content_image.length === undefined) {
                                                                        if (surveyData[data1][i].content_image) {
                                                                                formData.append("surveyContentFile[]", surveyData[data1][i].content_image);
                                                                                surveyData[data1][i].content_image = null;
                                                                        }
                                                                }
                                                        }
                                                        formData.append("surveyContent[]", JSON.stringify(surveyData[data1][i]));
                                                }
                                        }
                                }
                                else {
                                        formData.append(data1, surveyData[data1]);
                                }
                        })
                        formData.append('user_id', parseInt(userId));
                        formData.append('language_code', LANGUAGE_CODE);
                        formData.append('university_id', userUniversity);
                        formData.append('id', surveyCreationData.id);
                        headers = {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + apitoken
                        }
                        let self = this;
                        let surveyCreation;
                        // eslint-disable-next-line
                        var mainDiv = document.getElementById("errorSurveyMessage");
                        if (mainDiv) {
                                if (mainDiv.hasChildNodes()) {
                                        mainDiv.innerHTML = '';
                                }
                        }
                        // eslint-disable-next-line
                        await axios.post(API_URL + "/survey/updateSurvey" + '?uId=' + userId, formData, { headers: headers })
                                .then((response) => {
                                        if (response.data['success']) {
                                                surveyCreation = response.data['success'].surveydata;
                                                let surveyCreationDataNew = Object.assign({}, surveyCreationData);    //creating copy of object of surveyCreationdata of localStorage
                                                /**Update data of local Storage if exist into respone of axios call */
                                                // eslint-disable-next-line
                                                Object.keys(surveyCreation).map((data1, index) => {
                                                        surveyCreationDataNew[data1] = surveyCreation[data1];
                                                })
                                                if (this.surveyMainId) {
                                                        localStorage.setItem("surveycreationdata_" + this.surveyMainId, JSON.stringify(surveyCreationDataNew));
                                                }
                                                this.setState({ 'surveyData': surveyCreationDataNew })
                                        }
                                        self.setState({ loading: 'false' });
                                })
                                .catch((error) => {
                                        if (error) {
                                                if (error.response) {
                                                        if (error.response.data) {
                                                                let msg = error.response.data['error']
                                                                if (msg) {
                                                                        if (Object.keys(msg).length) {
                                                                                /**Map for object of error and aapend span of message into div having id errorSurveyMessage */
                                                                                // eslint-disable-next-line
                                                                                Object.keys(msg).map((msgdata, index) => {
                                                                                        errorCount = errorCount + 1;
                                                                                        var node = document.createElement("p");
                                                                                        var textnode = document.createTextNode(msg[msgdata]);
                                                                                        node.appendChild(textnode);
                                                                                        // eslint-disable-next-line
                                                                                        var mainDiv = document.getElementById("errorSurveyMessage");
                                                                                        if (mainDiv) mainDiv.appendChild(node);
                                                                                })
                                                                        }
                                                                        if (msg.length) {
                                                                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                                        localStorage.clear();
                                                                                        self.setState({ redirect: 'true' })
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                }
                                        }
                                        self.setState({ loading: 'false' })
                                })
                }
                /**For add and update survey content question component only */
                if (userId && surveyCreationData && errorCount === 0 && redirectToDemographicComponent && activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && validationErrorCount === 0 && parseInt(questionData.question_type_id) === 2) {
                        let formData = new FormData();
                        this.setState({ loading: 'true' })
                        let apitoken = '';
                        let userId = '';
                        if (storeitem !== null) {
                                apitoken = storeitem.token;
                                userId = storeitem.id;
                        }
                        // eslint-disable-next-line
                        Object.keys(questionData).map((data1, index) => {
                                if (data1 === "surveyContent") {
                                        formData.delete('surveyContentFile[]')
                                        for (i = 0; i < questionData[data1].length; i++) {
                                                if (questionData[data1][i]) {
                                                        questionData[data1][i].content_order = i + 1;
                                                        if(questionData[data1][i].content_text){
                                                                questionData[data1][i].content_text = questionData[data1][i].content_text.replace(/<br\s*\/?>/mg, "");
                                                        }
                                                        if (questionData[data1][i].content_image) {
                                                                if (questionData[data1][i].content_image.length === undefined) {
                                                                        if (questionData[data1][i].content_image) {
                                                                                formData.append("surveyContentFile[]", questionData[data1][i].content_image);
                                                                                questionData[data1][i].content_image = null;
                                                                        }
                                                                }
                                                        }
                                                        formData.append("surveyContent[]", JSON.stringify(questionData[data1][i]));
                                                }
                                        }
                                }
                                else if (data1 === "is_question_optional") {
                                        formData.append(data1, 0);
                                }
                                else {
                                        formData.append(data1, questionData[data1]);
                                }
                                formData.delete('option');
                                formData.delete('scale_option_text');
                                formData.delete('scale_type');
                        })
                        formData.append('user_id', userId);
                        formData.append('language_code', LANGUAGE_CODE);
                        formData.append('university_id', userUniversity);
                        formData.append('survey_id', surveyCreationData.id);
                        headers = {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + apitoken
                        }
                        let self = this;
                        let questionCreation;
                        // eslint-disable-next-line
                        var mainDiv = document.getElementById("errorSurveyMessage");
                        if (mainDiv) {
                                if (mainDiv.hasChildNodes()) {
                                        mainDiv.innerHTML = '';
                                }
                        }
                        // eslint-disable-next-line
                        await axios.post(API_URL + "/survey/question" + '?uId=' + userId, formData, { headers: headers })
                                .then((response) => {
                                        if (response.data['success']) {
                                                questionCreation = response.data['success'].questiondata;
                                                if (addQuestionIndex) {
                                                        FinalQuestionData.splice(parseInt(addQuestionIndex), 0, questionCreation);
                                                        checkAddQuestion = true;
                                                        ActiveQuestionIndex = ActiveQuestionIndex + 1;
                                                }
                                                else {
                                                        if (ActiveQuestionIndex >= FinalQuestionData.length) {
                                                                FinalQuestionData.push(questionCreation);
                                                                ActiveQuestionIndex = ActiveQuestionIndex + 1;
                                                        }
                                                        else {
                                                                FinalQuestionData[ActiveQuestionIndex] = questionCreation;
                                                                ActiveQuestionIndex = ActiveQuestionIndex + 1;
                                                        }
                                                }
                                                questionDataOrder = FinalQuestionData;
                                                if (this.surveyMainId) {
                                                        localStorage.setItem("surveycreationallquestiondata_" + this.surveyMainId, JSON.stringify(FinalQuestionData));
                                                }
                                        }
                                        qTypeId = questionData.question_type_id;
                                        questionData.question_type_id = "";
                                        self.setState({ questionData, FinalQuestionData, questionDataOrder, ActiveQuestionIndex, loading: 'false' });
                                })
                                .catch((error) => {
                                        if (error) {
                                                if (error.response) {
                                                        if (error.response.data) {
                                                                let msg = error.response.data['error']
                                                                if (msg) {
                                                                        if (Object.keys(msg).length) {
                                                                                /**Map for object of error and aapend span of message into div having id errorSurveyMessage */
                                                                                // eslint-disable-next-line
                                                                                Object.keys(msg).map((msgdata, index) => {
                                                                                        errorCount = errorCount + 1;
                                                                                        notRedirect = true;
                                                                                        var node = document.createElement("p");
                                                                                        var textnode = document.createTextNode(msg[msgdata]);
                                                                                        node.appendChild(textnode);
                                                                                        // eslint-disable-next-line
                                                                                        var mainDiv = document.getElementById("errorSurveyMessage");
                                                                                        if (mainDiv) mainDiv.appendChild(node);
                                                                                })
                                                                        }
                                                                        if (msg.length) {
                                                                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                                        localStorage.clear();
                                                                                        self.setState({ redirect: 'true' })
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                }
                                        }
                                        self.setState({ loading: 'false', notRedirect })
                                })
                                if(this.state.addQuestionIndex === 0 || this.state.addQuestionIndex){
                                        self.setState({ updateOrderAfterAddedQuestion: true })
                                }
                }
                /**For add and update survey question component */
                if (userId && surveyCreationData && errorCount === 0 && redirectToDemographicComponent && questionFileUpload && questionOptionCondition && activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && validationErrorCount === 0 && parseInt(qTypeId) !== 2) {
                        let formData = new FormData();
                        this.setState({ loading: 'true' })
                        let apitoken = '';
                        let userId = '';
                        let is_free_text = 0;
                        let option_length;
                        if (storeitem !== null) {
                                apitoken = storeitem.token;
                                userId = storeitem.id;
                        }
                        if (typeof questionOptionSplice !== 'undefined') {
                                is_free_text = 1;
                        }
                        if (questionData.option) {
                                option_length = questionData.option.length;
                        }
                        else {
                                option_length = 0;
                        }
                        // eslint-disable-next-line
                        Object.keys(questionData).map((data1, index) => {
                                if (data1 === "option") {
                                        formData.delete('optionimage[]')
                                        for (i = 0; i < questionData[data1].length; i++) {
                                                if (questionData[data1][i].option_image) {
                                                        if (questionData[data1][i].option_image.length === undefined) {
                                                                if (questionData[data1][i].option_image) {
                                                                        formData.append("optionimage[]", questionData[data1][i].option_image);
                                                                        questionData[data1][i].option_image = null;
                                                                }
                                                        }
                                                }
                                                if (questionData[data1][i]) {
                                                        if (!questionData[data1][i].option_text && (parseInt(questionData[data1][i].is_free_text) === 1 || is_free_text === 1)) {
                                                                questionData[data1][i].option_text = "free_text_condition";
                                                                questionData[data1][i].option_image = "";
                                                        }
                                                }
                                                formData.append("option[]", JSON.stringify(questionData[data1][i]));
                                        }
                                }
                                else if (data1 === "scale_option_text") {
                                        for (i = 0; i < questionData[data1].length; i++) {
                                                formData.append("scale_option_text[]", JSON.stringify(questionData[data1][i]));
                                        }
                                }
                                else if (data1 === "is_question_optional") {
                                        formData.append(data1, questionData[data1]);
                                }
                                else {
                                        formData.append(data1, questionData[data1]);
                                }
                        })
                        if (is_image) {
                                formData.set('is_image', 1);
                        }
                        else {
                                formData.set('is_image', 0);
                        }
                        // free text question option type default value set
                        if (!questionData['free_text_ans_type']) {
                                formData.set('free_text_ans_type', 0);
                        }
                        formData.set('user_id', userId);
                        formData.set('language_code', LANGUAGE_CODE);
                        formData.set('university_id', userUniversity);
                        formData.set('survey_id', surveyCreationData.id);
                        formData.set('option_length', option_length);
                        formData.set('is_free_text', is_free_text);
                        headers = {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + apitoken
                        }
                        let self = this;
                        let questionCreation;
                        // eslint-disable-next-line
                        var mainDiv = document.getElementById("errorSurveyMessage");
                        if (mainDiv) {
                                if (mainDiv.hasChildNodes()) {
                                        mainDiv.innerHTML = '';
                                }
                        }
                        // eslint-disable-next-line
                        await axios.post(API_URL + "/survey/question" + '?uId=' + userId, formData, { headers: headers })
                                .then((response) => {
                                        if (response.data['success']) {
                                                questionCreation = response.data['success'].questiondata;
                                                if (addQuestionIndex) {
                                                        FinalQuestionData.splice(parseInt(addQuestionIndex), 0, questionCreation);
                                                        ActiveQuestionIndex = ActiveQuestionIndex + 1;
                                                        checkAddQuestion = true;
                                                }
                                                else {
                                                        if (ActiveQuestionIndex >= FinalQuestionData.length) {
                                                                FinalQuestionData.push(questionCreation);
                                                                ActiveQuestionIndex = ActiveQuestionIndex + 1;
                                                        }
                                                        else {
                                                                FinalQuestionData[ActiveQuestionIndex] = questionCreation;
                                                                ActiveQuestionIndex = ActiveQuestionIndex + 1;
                                                        }
                                                }
                                                questionDataOrder = FinalQuestionData;
                                                if (this.surveyMainId) {
                                                        localStorage.setItem("surveycreationallquestiondata_" + this.surveyMainId, JSON.stringify(FinalQuestionData));
                                                }
                                        }
                                        questionData.question_type_id = "";
                                        self.setState({ questionData, FinalQuestionData, questionDataOrder, ActiveQuestionIndex });
                                        self.setState({ loading: 'false' });
                                })
                                .catch((error) => {
                                        if (error) {
                                                if (error.response) {
                                                        if (error.response.data) {
                                                                let msg = error.response.data['error']
                                                                if (msg) {
                                                                        if (Object.keys(msg).length) {
                                                                                /**Map for object of error and aapend span of message into div having id errorSurveyMessage */
                                                                                // eslint-disable-next-line
                                                                                Object.keys(msg).map((msgdata, index) => {
                                                                                        errorCount = errorCount + 1;
                                                                                        notRedirect = true;
                                                                                        if (questionData.question_type_id) {
                                                                                                if (parseInt(questionData.question_type_id) === 3 || parseInt(questionData.question_type_id) === 4 || parseInt(questionData.question_type_id) === 5) {
                                                                                                        if (questionData.option) {
                                                                                                                // eslint-disable-next-line
                                                                                                                questionData.option.map((data, index) => {
                                                                                                                        let checkString = "delete-change-value-" + index;
                                                                                                                        if (document.getElementsByClassName(checkString)[0]) {
                                                                                                                                if (document.getElementsByClassName(checkString)[0].files[0]) {
                                                                                                                                        questionData.option[index]['option_image'] = document.getElementsByClassName(checkString)[0].files[0];
                                                                                                                                }
                                                                                                                        }
                                                                                                                })
                                                                                                        }
                                                                                                }
                                                                                        }
                                                                                        this.setState({errorElementPosition: 1})
                                                                                        var node = document.createElement("p");
                                                                                        var textnode = document.createTextNode(msg[msgdata]);
                                                                                        node.appendChild(textnode);
                                                                                        // eslint-disable-next-line
                                                                                        var mainDiv = document.getElementById("errorSurveyMessage");
                                                                                        if (mainDiv) mainDiv.appendChild(node);
                                                                                })
                                                                        }
                                                                        if (msg.length) {
                                                                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                                        localStorage.clear();
                                                                                        self.setState({ redirect: 'true' })
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                }
                                        }
                                        self.setState({ loading: 'false', notRedirect })
                                })
                                if(this.state.addQuestionIndex === 0 || this.state.addQuestionIndex || this.state.editQuestionRedirect){
                                        self.setState({ updateOrderAfterAddedQuestion: true })
                                }
                }
                /**For survey update of component no - 6 demographic Attribute*/
                if (userId && surveyCreationData && errorCount === 0 && redirectToDemographicComponent === false && activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_demographic_position'] && validationErrorCount === 0) {
                        this.setState({ loading: 'true' })
                        let apitoken = '';
                        let userId = '';
                        if (storeitem !== null) {
                                apitoken = storeitem.token;
                                userId = storeitem.id;
                        }
                        headers = {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + apitoken
                        }
                        surveyData['user_id'] = userId;
                        surveyData['language_code'] = LANGUAGE_CODE;
                        surveyData['university_id'] = userUniversity;
                        surveyData['is_sie_demographic'] = this.state.surveyData.is_sie_demographic ? this.state.surveyData.is_sie_demographic : 0;
                        let self = this;
                        let surveyCreation;
                        // eslint-disable-next-line
                        var mainDiv = document.getElementById("errorSurveyMessage");
                        if (mainDiv) {
                                if (mainDiv.hasChildNodes()) {
                                        mainDiv.innerHTML = '';
                                }
                        }
                        // eslint-disable-next-line
                        await axios.post(API_URL + "/survey/updateSurveyDemographicattribute" + "?uId=" + userId, surveyData, { headers: headers })
                                .then((response) => {
                                        if (response.data['success']) {
                                                if (response.data['success'].surveydata) {
                                                        surveyCreation = response.data['success'].surveydata;
                                                        let surveyCreationDataNew = Object.assign({}, surveyCreationData);    //creating copy of object of surveyCreationdata of localStorage
                                                        /**Update data of local Storage if exist into respone of axios call */
                                                        // eslint-disable-next-line
                                                        Object.keys(surveyCreation).map((data1, index) => {
                                                                surveyCreationDataNew[data1] = surveyCreation[data1];
                                                        })
                                                        if (this.surveyMainId) {
                                                                localStorage.setItem("surveycreationdata_" + this.surveyMainId, JSON.stringify(surveyCreationDataNew));
                                                        }
                                                }
                                        }
                                        self.setState({ loading: 'false' });
                                })
                                .catch((error) => {
                                        if (error) {
                                                if (error.response) {
                                                        if (error.response.data) {
                                                                let msg = error.response.data['error']
                                                                if (msg) {
                                                                        if (Object.keys(msg).length) {
                                                                                /**Map for object of error and aapend span of message into div having id errorSurveyMessage */
                                                                                // eslint-disable-next-line
                                                                                Object.keys(msg).map((msgdata, index) => {
                                                                                        errorCount = errorCount + 1;
                                                                                        var node = document.createElement("p");
                                                                                        var textnode = document.createTextNode(msg[msgdata]);
                                                                                        if (msg[msgdata] === 'Expired token' || msg[msgdata] === 'Invalid token' || msg[msgdata] === 'Survey not found') {
                                                                                                self.setState({ redirect: 'true' })
                                                                                        }
                                                                                        node.appendChild(textnode);
                                                                                        // eslint-disable-next-line
                                                                                        var mainDiv = document.getElementById("errorSurveyMessage");
                                                                                        if (mainDiv) mainDiv.appendChild(node);
                                                                                })
                                                                        }
                                                                        if (msg.length) {
                                                                                if (msg === 'Expired token' || msg === 'Invalid token' || msg === 'Survey not found') {
                                                                                        localStorage.clear();
                                                                                        self.setState({ redirect: 'true' })
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                }
                                        }
                                        self.setState({ loading: 'false' })
                                })
                }
                /** Already true but false when change category from dropdown stop updating on countinue button when updateCategory call */
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_category_position'] && this.state.editSurveyCategory) {
                        updateCategoryWithOthers = false;
                }
                let welcomePositionSaveData = true;
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_page_position']) {
                        if (is_welcome_page === true || is_welcome_page === "1" || is_welcome_page === 1 || is_welcome_page === "true") {
                                welcomePositionSaveData = false;
                        }
                }
                /**For update survey if existing */
                if (userId && surveyCreationData && fileUpload === false && errorCount === 0 && questionFileUpload && activeComponentIndex !== SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && activeComponentIndex !== SURVEY_CREATION_POSITION_CONSTANT['survey_demographic_position'] && activeComponentIndex !== SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && activeComponentIndex !== SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_content_position'] && validationErrorCount === 0 && updateCategoryWithOthers && welcomePositionSaveData) {
                        this.setState({ loading: 'true' })
                        let apitoken = '';
                        if (storeitem !== null) {
                                apitoken = storeitem.token;
                        }
                        headers = {
                                "Authorization": "Bearer " + apitoken
                        }
                        if (surveyCreationData.survey_welcome_image) {
                                surveyData['survey_welcome_image'] = surveyCreationData.survey_welcome_image;
                        }
                        surveyData['user_id'] = userId;
                        surveyData['language_code'] = LANGUAGE_CODE;
                        surveyData['university_id'] = userUniversity;
                        surveyData['id'] = surveyCreationData.id;
                        surveyData['survey_logo'] = surveyCreationData.survey_logo;
                        surveyData['survey_optional_logo'] = surveyCreationData.survey_optional_logo;
                        surveyData['survey_color'] = surveyCreationData.survey_color;
                        surveyData['declaration_consent_title'] = surveyCreationData.declaration_consent_title;
                        surveyData['declaration_consent_description'] = surveyCreationData.declaration_consent_description;
                        surveyData['declaration_consent_toggle'] = surveyCreationData.declaration_consent_toggle;
                        if (surveyData) {
                                if (surveyData['is_welcome_page'] === "false") {
                                        surveyData['is_welcome_page'] = false;
                                }
                                if (surveyData['is_welcome_page'] === "true") {
                                        surveyData['is_welcome_page'] = true;
                                }
                        }
                        let self = this;
                        let surveyCreation;
                        // eslint-disable-next-line
                        var mainDiv = document.getElementById("errorSurveyMessage");
                        if (mainDiv) {
                                if (mainDiv.hasChildNodes()) {
                                        mainDiv.innerHTML = '';
                                }
                        }
                        // eslint-disable-next-line
                        await axios.post(API_URL + "/survey/updateSurvey" + '?uId=' + userId, surveyData, { headers: headers })
                                .then((response) => {
                                        if (response.data['success']) {
                                                surveyCreation = response.data['success'].surveydata;
                                                let surveyCreationDataNew = Object.assign({}, surveyCreationData);    //creating copy of object of surveyCreationdata of localStorage
                                                /**Update data of local Storage if exist into respone of axios call */
                                                // eslint-disable-next-line
                                                Object.keys(surveyCreation).map((data1, index) => {
                                                        surveyCreationDataNew[data1] = surveyCreation[data1];
                                                })
                                                if (this.surveyMainId) {
                                                        localStorage.setItem("surveycreationdata_" + this.surveyMainId, JSON.stringify(surveyCreationDataNew));
                                                }
                                        }
                                        self.setState({ loading: 'false' });
                                })
                                .catch((error) => {
                                        if (error) {
                                                if (error.response) {
                                                        if (error.response.data) {
                                                                let msg = error.response.data['error']
                                                                if (msg) {
                                                                        if (Object.keys(msg).length) {
                                                                                /**Map for object of error and aapend span of message into div having id errorSurveyMessage */
                                                                                // eslint-disable-next-line
                                                                                Object.keys(msg).map((msgdata, index) => {
                                                                                        errorCount = errorCount + 1;
                                                                                        var node = document.createElement("p");
                                                                                        var textnode = document.createTextNode(msg[msgdata]);
                                                                                        node.appendChild(textnode);
                                                                                        // eslint-disable-next-line
                                                                                        var mainDiv = document.getElementById("errorSurveyMessage");
                                                                                        if (mainDiv) mainDiv.appendChild(node);
                                                                                })
                                                                        }
                                                                        if (msg.length) {
                                                                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                                                                        localStorage.clear();
                                                                                        self.setState({ redirect: 'true' })
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                }
                                        }
                                        self.setState({ loading: 'false' })
                                })
                }
                /**Update order of question in component no - 7 Creating Condition*/
                if (userId && surveyCreationData && errorCount === 0 && activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && childCreatingConditionIndex === 1 && validationErrorCount === 0) {
                        checkAddQuestion = false;
                        this.setState({ loading: 'true', questionIndexUpdated: false, checkAddQuestion: false })
                        let apitoken = '';
                        let userId = '';
                        if (storeitem !== null) {
                                apitoken = storeitem.token;
                                userId = storeitem.id;
                        }
                        headers = {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + apitoken
                        }
                        let surveyCreationCondition = {};
                        surveyCreationCondition['questiondata'] = questionDataOrder;
                        surveyCreationCondition['user_id'] = userId;
                        surveyCreationCondition['id'] = surveyCreationData.id;
                        let self = this;
                        // eslint-disable-next-line
                        var mainDiv = document.getElementById("errorSurveyMessage");
                        if (mainDiv) {
                                if (mainDiv.hasChildNodes()) {
                                        mainDiv.innerHTML = '';
                                }
                        }
                        // eslint-disable-next-line
                        await axios.post(API_URL + "/survey/updateQuestionOrder" + "?uId=" + userId, surveyCreationCondition, { headers: headers })
                                .then((response) => {
                                        if (response.data['success']) {
                                                if (response.data['success']['questiondata']) {
                                                        if(response.data['success']['questiondata']['questiondata']){
                                                                questionDataOrder = response.data['success']['questiondata']['questiondata'];
                                                        }
                                                }
                                        }
                                        self.setState({ loading: 'false' });
                                })
                                .catch((error) => {
                                        if (error) {
                                                if (error.response) {
                                                        if (error.response.data) {
                                                                let msg = error.response.data['error']
                                                                if (msg) {
                                                                        if (Object.keys(msg).length) {
                                                                                /**Map for object of error and aapend span of message into div having id errorSurveyMessage */
                                                                                // eslint-disable-next-line
                                                                                Object.keys(msg).map((msgdata, index) => {
                                                                                        errorCount = errorCount + 1;
                                                                                        var node = document.createElement("p");
                                                                                        var textnode = document.createTextNode(msg[msgdata]);
                                                                                        if (msg[msgdata] === 'Expired token' || msg[msgdata] === 'Invalid token' || msg[msgdata] === 'Survey not found') {
                                                                                                self.setState({ redirect: 'true' })
                                                                                        }
                                                                                        node.appendChild(textnode);
                                                                                        // eslint-disable-next-line
                                                                                        var mainDiv = document.getElementById("errorSurveyMessage");
                                                                                        if (mainDiv) mainDiv.appendChild(node);
                                                                                })
                                                                        }
                                                                        if (msg.length) {
                                                                                if (msg === 'Expired token' || msg === 'Invalid token' || msg === 'Survey not found') {
                                                                                        localStorage.clear();
                                                                                        self.setState({ redirect: 'true' })
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                }
                                        }
                                        self.setState({ loading: 'false' })
                                })
                        this.setState({ FinalQuestionData: questionDataOrder, questionDataOrder }, () => {
                                if (self.surveyMainId) {
                                        localStorage.setItem("surveycreationallquestiondata_" + self.surveyMainId, JSON.stringify(questionDataOrder));
                                }
                        });
                }
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position']) {
                        if (ActiveQuestionIndex > 0 && FinalQuestionData[ActiveQuestionIndex]) {
                        }
                }
                /**For skip content page or not */
                if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_page_position']) {
                        if (is_welcome_page === false || is_welcome_page === 2 || is_welcome_page === "false" || is_welcome_page === "2") {
                                activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                                editWelcomePage = false
                                childCreatingConditionIndex = 1;
                        }
                        if ((is_welcome_page === true || is_welcome_page === 1 || is_welcome_page === "true" || is_welcome_page === "1")) {
                                activeComponentIndex = activeComponentIndex + 1;
                        }
                }
                else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position']) {
                        if (childCreatingConditionIndex === 1) {
                                let ActiveStatus = parseInt(surveyCreationData.status);
                                if (!surveyCreationData.status) {
                                        ActiveStatus = 2;
                                }
                                if (ActiveStatus === 2) {
                                        if (!this.surveyCreatingConditionRedirect) {
                                                this.changeSurveyStatus(this.surveyMainId, 5, ActiveStatus, " ");
                                        }
                                }
                                if (this.surveyCreatingConditionRedirect || this.previewOpenInNewTab) {
                                        this.surveyCreatingConditionRedirect = false;
                                        if (surveyData.survey_title_slug) {
                                                this.titleSlug = surveyData.survey_title_slug;
                                                //window.open(BASEURL + URL_CONSTANT['surveycreation'] + "/" + this.titleSlug + "/" + URL_CONSTANT['preview'], '_blank')
                                        }
                                } else {
                                        activeComponentIndex = activeComponentIndex + 1;
                                        if (surveyCreationData.status) {
                                                if (parseInt(surveyCreationData.status) === 1 || parseInt(surveyCreationData.status) === 4 || parseInt(surveyCreationData.status) === 5 || parseInt(surveyCreationData.status) === 3) {
                                                        RedirectToMypolls = true;
                                                }
                                        }
                                }
                        }
                        else {
                                childCreatingConditionIndex = childCreatingConditionIndex + 1;
                        }
                }
                else {
                        /**Validation for question selection component in Survey Element for not redirect and message- no 5 */
                        /**Element page default error messages */
                        if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && questionFileUpload === false) {
                                // eslint-disable-next-line
                                activeComponentIndex = activeComponentIndex;
                                questionComponentErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_element_page_option_image_default'];
                        }
                        else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && questionOptionCondition === false) {
                                // eslint-disable-next-line
                                activeComponentIndex = activeComponentIndex;
                                if (questionData['question_type_id'] === 3 || questionData['question_type_id'] === 4) {
                                        questionComponentErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_element_page_option_single_multiple_selection'];
                                }
                                else {
                                        questionComponentErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_element_page_option_default'];
                                } 
                        }
                        else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && questionScaleOptionCondition === false) {
                                // eslint-disable-next-line
                                activeComponentIndex = activeComponentIndex;
                                questionComponentErrorMessage = SURVEY_CREATION_ERROR_MESSAGE[LANGUAGE_CODE]['survey_element_page_scale_option'];
                        }
                        else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && editQuestionRedirect) {
                                if (!notRedirect) {
                                        activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                                        if (editQuestionRedirect) {
                                                editQuestionRedirect = false;
                                        }
                                }
                                this.setState({ activeComponentIndex, editQuestionRedirect })
                        }
                        else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && addQuestionIndex) {
                                if (!notRedirect) {
                                        activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                                        addQuestionIndex = '';
                                }
                                this.setState({ activeComponentIndex, addQuestionIndex })
                        }
                        else if (SURVEY_CREATION_POSITION_CONSTANT['survey_demographic_position'] && changeDemographicButton) {
                                childCreatingConditionIndex = 1;
                                changeDemographicButton = false;
                                activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        }
                        else if (SURVEY_CREATION_POSITION_CONSTANT['define_closing_page'] && changeClosingPageButton) {
                                activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        }
                        else if (SURVEY_CREATION_POSITION_CONSTANT['survey_data_protection'] && changeDataProtectionPageButton) {
                                activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        }
                        else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_content_position'] && editWelcomePage) {
                                if (is_welcome_page === true || is_welcome_page === 1 || is_welcome_page === "true" || is_welcome_page === "1") {
                                        activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                                        editWelcomePage = false
                                        childCreatingConditionIndex = 1;
                                }
                        }
                        else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_title_position'] && this.state.editSurveyTitleFlag ) {
                                activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        }
                        else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_custom_logo']) {
                                activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        }
                        else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_picture_position'] && this.state.editSurveyCategory) {
                                activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        }
                        else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_picture_position'] && !this.state.editSurveyCategory) {
                                activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'];
                                let ActiveQuestionIndex = 0;
                                if (ActiveQuestionIndex >= 0) {
                                        let questionContentDataLength = this.state.questionContentDataLength;
                                        let questionDataOrder = this.state.questionDataOrder;
                                        if (questionDataOrder[ActiveQuestionIndex]) {
                                                questionData = questionDataOrder[ActiveQuestionIndex];
                                                if (questionData.surveyContent) {
                                                        if (questionData.surveyContent.length) {
                                                                questionContentDataLength = questionData.surveyContent.length;
                                                        }
                                                }
                                                this.setState({ questionData: questionData, ActiveQuestionIndex, questionContentDataLength, validationErrorCount: 0 })
                                        }
                                }
                        }
                        else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position']) {
                                activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        }
                        else {
                                activeComponentIndex = activeComponentIndex + 1;
                        }
                }
                if (errorCount === 0 && (redirectToDemographicComponent === false || nextComponentAfterElement) && validationErrorCount === 0 && errorImageCount === 0) {
                        if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && questionFileUpload === false) {
                                this.setState({ RedirectToMypolls, activeComponentIndex, questionComponentErrorMessage, fileUpload: false });
                        }
                        else {
                                this.setState({ backButtonClick: false });
                                this.setState({ RedirectToMypolls, activeComponentIndex, questionComponentErrorMessage, fileUpload: false });
                        }
                }
                this.setState({ questionComponentErrorMessage, notRedirect, RedirectToMypolls, validationErrorMessage, questionData, editWelcomePage, checkAddQuestion, childCreatingConditionIndex, changeDemographicButton,changeClosingPageButton, changeEditQuestionType: false, editSurveyTitleFlag: false, editSurveyLogo: false, changeDataProtectionPageButton });
                if(this.state.updateOrderAfterAddedQuestion && this.state.errorElementPosition !== 1){
                        if(document.getElementById('loading')){
                                document.getElementById("loading").style.display = "block";
                        }
                        this.updateQuestionOrder();
                }
                if (this.state.activeComponentIndex === 7 && this.surveyMainId) {
                        if (localStorage.getItem('scrollposition_' + this.surveyMainId)) {
                                setTimeout(() => {
                                        localStorage.removeItem('scrollposition_' + this.surveyMainId);
                                }, 1000);
                        }
                }
        }

        updateQuestionOrder = async () => {
                var questionDataOrder = this.state.questionDataOrder;
                if (questionDataOrder.length === 0) {
                        if (this.surveyMainId) {
                                questionDataOrder = JSON.parse(localStorage.getItem('surveycreationallquestiondata_' + this.surveyMainId));
                        }
                }
                /**change condition*/
                var orderDiv = document.getElementsByClassName("creating-condition");
                var selectDropdown = document.getElementsByClassName("question-condition-select");
                var j = 0;
                let englishbuttonlabels;
                englishbuttonlabels = this.state.surveyData.englishbuttonlabels == "1" ? "EN" : "DE"
                if (questionDataOrder && orderDiv) {
                        if (questionDataOrder.length > 0) {
                                for (let i = 0; i < orderDiv.length; i++) {
                                        var allOptionChange = questionDataOrder[i]["option"];
                                        if (orderDiv[i].getAttribute("value")) {
                                                questionDataOrder[i]['id'] = parseInt(orderDiv[i].getAttribute("value"));
                                        }
                                        questionDataOrder[i]['question_order'] = parseInt(orderDiv[i].id);
                                        var z = 0;
                                        var checkCondition = false;
                                        var optionArray = [];
                                        for (j = 0; j < selectDropdown.length; j++) {
                                                if (parseInt(selectDropdown[j]['id']) === i) {
                                                        var objectOptionChange = (allOptionChange) ? (allOptionChange[z]) ? allOptionChange[z] : {} : {};
                                                        if (!allOptionChange) {
                                                                objectOptionChange['id'] = "";
                                                                objectOptionChange['option_text'] = "";
                                                                if (questionDataOrder[i]) {
                                                                        if (questionDataOrder[i].question_type_id) {
                                                                                var qId = parseInt(questionDataOrder[i].question_type_id);
                                                                                var qScale = parseInt(questionDataOrder[i].scale_type);
                                                                                if (qId === 6 && qScale === 2) {
                                                                                        objectOptionChange['option_text'] = SCHOOL_GRADE_SCALE_CONSTANT[LANGUAGE_CODE][z]['title'];
                                                                                }
                                                                                if (qId === 6 && qScale === 6) {
                                                                                        objectOptionChange['option_text'] = SURVEY_APPROVAL_QUESTION_ANSWER[LANGUAGE_CODE][z]['title'];
                                                                                }
                                                                                if (qId === 6 && qScale === 4) {
                                                                                        objectOptionChange['option_text'] = 50;
                                                                                }
                                                                                if (qId === 6 && qScale === 3) {
                                                                                        objectOptionChange['option_text'] = (z + 1);
                                                                                }
                                                                                if (qId === 6 && qScale === 1) {
                                                                                        objectOptionChange['option_text'] = (z + 1);
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                        else {
                                                                if (!allOptionChange[z]) {
                                                                        if (questionDataOrder[i]) {
                                                                                // for free text in question type 3 only (first time)
                                                                                if (parseInt(questionDataOrder[i].question_type_id) === 3 && parseInt(questionDataOrder[i].is_free_text) === 1) {
                                                                                        objectOptionChange['id'] = "";
                                                                                        objectOptionChange['option_text'] = "free_text_condition";
                                                                                        objectOptionChange['option_image'] = "";
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                        if (selectDropdown[j]['value']) {
                                                                if (parseInt(selectDropdown[j]['value']) === 0) {
                                                                        objectOptionChange['option_skip_question'] = "";
                                                                        objectOptionChange['option_end_survey'] = 1;
                                                                }
                                                                else {
                                                                        delete objectOptionChange['option_skip_question'];
                                                                        objectOptionChange['option_skip_question'] = parseInt(selectDropdown[j]['value']);
                                                                        checkCondition = true;
                                                                        objectOptionChange['option_end_survey'] = 0;
                                                                }
                                                        }
                                                        else {
                                                                if (!objectOptionChange.hasOwnProperty('id')) {
                                                                        objectOptionChange['id'] = "";
                                                                }
                                                                objectOptionChange['option_skip_question'] = "";
                                                                objectOptionChange['option_end_survey'] = 0;
                                                        }
                                                        optionArray[z] = objectOptionChange;
                                                        z = z + 1;
                                                }
                                        }
                                        questionDataOrder[i]['conditional'] = checkCondition;
                                        if (optionArray.length) {
                                                questionDataOrder[i]['option'] = optionArray;                        //updating value of questionData for option
                                        }
                                }
                        }
                }
                let apitoken = '';
                let userId = '';
                let surveyCreationData = JSON.parse(localStorage.getItem('surveycreationdata_' + this.surveyMainId));
                let storeitem = JSON.parse(localStorage.getItem('userdata'));
                if (storeitem !== null) {
                        apitoken = storeitem.token;
                        userId = storeitem.id;
                }
                var headers = {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + apitoken
                }
                let surveyCreationCondition = {};
                surveyCreationCondition['questiondata'] = questionDataOrder;
                surveyCreationCondition['user_id'] = userId;
                surveyCreationCondition['id'] = surveyCreationData.id;
                let self = this;
                // eslint-disable-next-line
                var mainDiv = document.getElementById("errorSurveyMessage");
                if (mainDiv) {
                        if (mainDiv.hasChildNodes()) {
                                mainDiv.innerHTML = '';
                        }
                }
                // eslint-disable-next-line
                await axios.post(API_URL + "/survey/updateQuestionOrder" + "?uId=" + userId, surveyCreationCondition, { headers: headers })
                        .then((response) => {
                                if (response.data['success']) {
                                        if (response.data['success']['questiondata']) {
                                                if(response.data['success']['questiondata']['questiondata']){
                                                        questionDataOrder = response.data['success']['questiondata']['questiondata'];
                                                }
                                        }
                                }
                                if(document.getElementById('loading')){
                                        document.getElementById("loading").style.display = "none";
                                }
                        })
                        .catch((error) => {
                                if (error) {
                                        if (error.response) {
                                                if (error.response.data) {
                                                        let msg = error.response.data['error']
                                                        let errorCount = 0;
                                                        if (msg) {
                                                                if (Object.keys(msg).length) {
                                                                        /**Map for object of error and aapend span of message into div having id errorSurveyMessage */
                                                                        // eslint-disable-next-line
                                                                        Object.keys(msg).map((msgdata, index) => {
                                                                                errorCount = errorCount + 1;
                                                                                var node = document.createElement("p");
                                                                                var textnode = document.createTextNode(msg[msgdata]);
                                                                                if (msg[msgdata] === 'Expired token' || msg[msgdata] === 'Invalid token' || msg[msgdata] === 'Survey not found') {
                                                                                        self.setState({ redirect: 'true' })
                                                                                }
                                                                                node.appendChild(textnode);
                                                                                // eslint-disable-next-line
                                                                                var mainDiv = document.getElementById("errorSurveyMessage");
                                                                                if (mainDiv) mainDiv.appendChild(node);
                                                                        })
                                                                }
                                                                if (msg.length) {
                                                                        if (msg === 'Expired token' || msg === 'Invalid token' || msg === 'Survey not found') {
                                                                                localStorage.clear();
                                                                                self.setState({ redirect: 'true' })
                                                                        }
                                                                }
                                                        }
                                                }
                                        }
                                }
                                if(document.getElementById('loading')){
                                        document.getElementById("loading").style.display = "none";
                                }
                        })
                this.setState({ FinalQuestionData: questionDataOrder,questionIndexUpdated: true, checkAddQuestion: false, questionDataOrder,updateOrderAfterAddedQuestion: false }, () => {
                        if (self.surveyMainId) {
                                localStorage.setItem("surveycreationallquestiondata_" + self.surveyMainId, JSON.stringify(questionDataOrder));
                        }
                });
        }

        back = (e) => {
                e.preventDefault();
                this.scrollUpFunction()
                let activeComponentIndex = this.state.activeComponentIndex;
                let childCreatingConditionIndex = parseInt(this.state.childCreatingConditionIndex);
                let editQuestionRedirect = this.state.editQuestionRedirect;
                let addQuestionIndex = this.state.addQuestionIndex;
                let changeDemographicButton = this.state.changeDemographicButton;
                let changeClosingPageButton = this.state.changeClosingPageButton;
                let changeDataProtectionPageButton = this.state.changeDataProtectionPageButton
                let editWelcomePage = this.state.editWelcomePage;
                let questionData = this.state.questionData;
                let backButtonClick= true;
                let editSurveyCategory = this.state.editSurveyCategory
                /**For skip content page or not */
                if(activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_title_position'] && this.state.editSurveyTitleFlag) {
                        activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        childCreatingConditionIndex = 1;
                        backButtonClick = false;
                        let surveyLocalData;
                        if (this.surveyMainId) {
                                surveyLocalData = JSON.parse(localStorage.getItem('surveycreationdata_' + this.surveyMainId))
                        }
                        if (surveyLocalData) {
                                this.setState({ surveyData: surveyLocalData })
                        }
                }
                else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && editQuestionRedirect) {
                        /* -- Remove new prview (data:: - url) on edit back button */
                        Array.from(document.getElementsByClassName("get-image-preview")).forEach((img, index) => {
                                if (questionData.option[index].option_image === null || !questionData.option[index].option_image.length || questionData.option[index].option_image.length === undefined) {
                                        img.removeAttribute("src");
                                        document.getElementsByClassName("get-image")[index].value = "";
                                        questionData.option[index].option_image = "";
                                }
                        })
                        /* -- End Remove new prview (data:: - url) on edit back button */
                        activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        editQuestionRedirect = false;
                        backButtonClick = false;
                        let localDataOrder = this.state.questionDataOrder;
                        if (this.surveyMainId && this.state.questionDataOrder) {
                                localDataOrder = JSON.parse(localStorage.getItem('surveycreationallquestiondata_' + this.surveyMainId));
                        }
                        this.setState({FinalQuestionData: localDataOrder, questionDataOrder: localDataOrder, activeComponentIndex, editQuestionRedirect, ActiveQuestionIndex: (this.state.FinalQuestionData.length + 1), questionData: { "option": [], "scale_option_text": [] } })
                }
                else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position']) {
                        if (childCreatingConditionIndex === 0) {
                                activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'];
                                let ActiveQuestionIndex = 0;
                                if (ActiveQuestionIndex >= 0) {
                                        let questionContentDataLength = this.state.questionContentDataLength;
                                        let questionDataOrder = this.state.questionDataOrder;
                                        if (questionDataOrder[ActiveQuestionIndex]) {
                                                questionData = questionDataOrder[ActiveQuestionIndex];
                                                if (questionData.surveyContent) {
                                                        if (questionData.surveyContent.length) {
                                                                questionContentDataLength = questionData.surveyContent.length;
                                                        }
                                                }
                                                this.setState({ questionData: questionData, ActiveQuestionIndex, questionContentDataLength, validationErrorCount: 0 })
                                        }
                                }
                        }
                        else {
                                childCreatingConditionIndex = childCreatingConditionIndex - 1;
                        }
                }
                else if (activeComponentIndex > SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position']) {
                        activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        childCreatingConditionIndex = 1;
                }
                else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && addQuestionIndex) {
                        activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        backButtonClick = false;
                        this.setState({ activeComponentIndex, addQuestionIndex: '', ActiveQuestionIndex: (this.state.FinalQuestionData.length + 1), questionData: { "option": [], "scale_option_text": [] } })
                }
                else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position']) {
                        activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_picture_position'];
                }
                else if (SURVEY_CREATION_POSITION_CONSTANT['survey_demographic_position'] && changeDemographicButton) {
                        let surveyLocalData;
                        if (this.surveyMainId) {
                                surveyLocalData = JSON.parse(localStorage.getItem('surveycreationdata_' + this.surveyMainId))
                        }
                        changeDemographicButton = false;
                        childCreatingConditionIndex = 1;
                        backButtonClick = false;
                        activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        if (surveyLocalData) {
                                this.setState({ surveyData: surveyLocalData })
                        }
                }
                else if (SURVEY_CREATION_POSITION_CONSTANT['define_closing_page'] && changeClosingPageButton) {
                        let surveyLocalData;
                        if (this.surveyMainId) {
                                surveyLocalData = JSON.parse(localStorage.getItem('surveycreationdata_' + this.surveyMainId))
                        }
                        changeClosingPageButton = false;
                        childCreatingConditionIndex = 1;
                        backButtonClick = false;
                        activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        if (surveyLocalData) {
                                this.setState({ surveyData: surveyLocalData })
                        }
                }
                else if (SURVEY_CREATION_POSITION_CONSTANT['survey_data_protection'] && changeDataProtectionPageButton) {
                        let surveyLocalData;
                        if (this.surveyMainId) {
                                surveyLocalData = JSON.parse(localStorage.getItem('surveycreationdata_' + this.surveyMainId))
                        }
                        changeDataProtectionPageButton = false;
                        childCreatingConditionIndex = 1;
                        backButtonClick = false;
                        activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        if (surveyLocalData) {
                                this.setState({ surveyData: surveyLocalData })
                        }
                }
                else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_category_position'] && editSurveyCategory) {
                        activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        childCreatingConditionIndex = 1;
                        editSurveyCategory = false;
                        backButtonClick = false;
                        let surveyLocalData;
                        if (this.surveyMainId) {
                                surveyLocalData = JSON.parse(localStorage.getItem('surveycreationdata_' + this.surveyMainId))
                        }
                        if (surveyLocalData) {
                                this.setState({ surveyData: surveyLocalData })
                        }
                }
                else if (activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_page_position'] && editWelcomePage) {
                        activeComponentIndex = SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'];
                        childCreatingConditionIndex = 1;
                        editWelcomePage = false;
                        backButtonClick = false;
                        let surveyLocalData;
                        let contentDataLength = 0;
                        let contentDataLimit = 10;
                        if (this.surveyMainId) {
                                surveyLocalData = JSON.parse(localStorage.getItem('surveycreationdata_' + this.surveyMainId));
                                contentDataLength = surveyLocalData.surveyContent ? surveyLocalData.surveyContent.length : 0;
                        }
                        if (surveyLocalData) {
                                this.setState({ surveyData: surveyLocalData, contentDataLength, contentDataLimit })
                        }
                }
                else {
                        activeComponentIndex = activeComponentIndex > 0 ? activeComponentIndex - 1 : activeComponentIndex;
                }
                if (this.surveyMainId) {
                        if (localStorage.getItem('scrollposition_' + this.surveyMainId)) {
                                setTimeout(() => {
                                        localStorage.removeItem('scrollposition_' + this.surveyMainId);
                                }, 1000);
                        }
                }
                this.setState({ backButtonClick, activeComponentIndex, childCreatingConditionIndex, changeDemographicButton, changeClosingPageButton, editWelcomePage, changeEditQuestionType: false, editSurveyTitleFlag: false, editSurveyCategory, validationErrorCount: 0, validationErrorMessage: '', questionComponentErrorMessage : '', errorCount : 0, changeDataProtectionPageButton});
        }
        clearRelatedKey = () => {
                if (this.surveyMainId) {
                        localStorage.removeItem("surveycreationeditid")
                        localStorage.removeItem("surveycreationdata_" + this.surveyMainId)
                        localStorage.removeItem("surveycreationallquestiondata_" + this.surveyMainId)
                        localStorage.removeItem('scrollposition_' + this.surveyMainId);
                        localStorage.setItem("myprofileactivetab", 2);
                }
                window.history.push('/mein-bereich');
        }
        /** add survey_id to surveycreationeditid in localstorage */
        addIdToLocalstorage = () => {
                let surveycreationdata;
                if (this.surveyMainId) {
                        surveycreationdata = JSON.parse(localStorage.getItem('surveycreationdata_' + this.surveyMainId))
                }
                if (surveycreationdata) {
                        let survey_title = surveycreationdata.survey_title;
                        let id = surveycreationdata.id;
                        let surveycreationeditid = { "id": id, "survey_title": survey_title }
                        localStorage.setItem(('surveycreationeditid'), JSON.stringify(surveycreationeditid))
                }
        }
        /**this function is for not redirecting on click of survey title in main header */
        stopRedirect = (event) => {
                event.preventDefault();
        }
        /**Change status of survey */
        changeSurveyStatus = async (surveyId, status, activeStatus, changeRedirectKey) => {
                if (activeStatus === 2) {
                        let updateSurveyStatusById = false;
                        this.setState({ loading: 'true' })
                        let storeitem = JSON.parse(localStorage.getItem('userdata'));
                        let surveyCreationData = JSON.parse(localStorage.getItem('surveycreationdata_' + surveyId));
                        let apitoken = '';
                        let userId = '';
                        if (storeitem !== null) {
                                apitoken = storeitem.token;
                                userId = storeitem.id;
                        }
                        var headers = {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + apitoken
                        }
                        var data = {
                                id: parseInt(surveyId),
                                status: status,
                                is_mail: true
                        }
                        let self = this;
                        // eslint-disable-next-line
                        var mainDiv = document.getElementById("errorSurveyMessage");
                        if (mainDiv) {
                                if (mainDiv.hasChildNodes()) {
                                        mainDiv.innerHTML = '';
                                }
                        }
                        // eslint-disable-next-line
                        await axios.post(API_URL + "/survey/updateSurveyStatusById" + '?uId=' + userId, data, { headers: headers })
                                .then((response) => {
                                        if (response.data['success']) {
                                                if (surveyCreationData) {
                                                        let surveyCreationDataNew = Object.assign({}, surveyCreationData);    //creating copy of object of surveyCreationdata of localStorage
                                                        /**Update data of local Storage if exist into respone of axios call */
                                                        // eslint-disable-next-line
                                                        Object.keys(surveyCreationData).map((data1, index) => {
                                                                surveyCreationDataNew[data1] = surveyCreationData[data1];
                                                        })
                                                        surveyCreationDataNew['status'] = status;
                                                        if (surveyId) {
                                                                localStorage.setItem("surveycreationdata_" + surveyId, JSON.stringify(surveyCreationDataNew));
                                                        }
                                                        surveyCreationData = surveyCreationDataNew;
                                                        updateSurveyStatusById = true;
                                                }
                                        }
                                        self.setState({ loading: 'false', surveyData: surveyCreationData, updateSurveyStatusById });
                                })
                                .catch((error) => {
                                        if (error) {
                                                if (error.response) {
                                                        if (error.response.data) {
                                                                let msg = error.response.data['error']
                                                                if (msg) {
                                                                        if (msg.length) {
                                                                                if (msg === 'Expired token' || msg === 'Invalid token' || msg === 'Survey not found') {
                                                                                        localStorage.clear();
                                                                                        self.setState({ redirect: 'true' })
                                                                                }
                                                                        }
                                                                }
                                                        }
                                                }
                                        }
                                        self.setState({ loading: 'false' })
                                })
                }
                if (changeRedirectKey === "mein-bereich") {
                        if (this.surveyMainId) {
                                localStorage.removeItem("surveycreationeditid")
                                localStorage.removeItem("surveycreationdata_" + this.surveyMainId)
                                localStorage.removeItem("surveycreationallquestiondata_" + this.surveyMainId)
                                localStorage.setItem("myprofileactivetab", 2);
                        }
                        this.setState({ RedirectToProfile: true })
                } else if (changeRedirectKey === "edit-survey") {
                        this.setState({ RedirectToEditsurvey: true })
                }
                return false;
        }
        /**Change status with clear data */
        changeStatusCleardata = (surveyId, status, activeStatus, changeRedirectKey) => {
                this.changeSurveyStatus(surveyId, status, activeStatus, changeRedirectKey);
        }
        editSurveyTitle = () => {
                let scrollPosition = window.scrollY;
                if (!localStorage.getItem('scrollposition_' + this.surveyMainId) && this.surveyMainId) {
                        localStorage.setItem("scrollposition_" + this.surveyMainId, JSON.stringify(scrollPosition));
                }
                this.setState({activeComponentIndex: 0, editSurveyTitleFlag: true, backButtonClick: true})
        }
        editSurveyCategory = () => {
                let scrollPosition = window.scrollY;
                if (!localStorage.getItem('scrollposition_' + this.surveyMainId) && this.surveyMainId) {
                        localStorage.setItem("scrollposition_" + this.surveyMainId, JSON.stringify(scrollPosition));
                }
                this.setState({activeComponentIndex: 1, editSurveyCategory: true, backButtonClick: true})
        }
        customLogo = () =>{
                let scrollPosition = window.scrollY;
                if (!localStorage.getItem('scrollposition_' + this.surveyMainId) && this.surveyMainId) {
                        localStorage.setItem("scrollposition_" + this.surveyMainId, JSON.stringify(scrollPosition));
                }
                this.setState({activeComponentIndex: 10, editSurveyLogo: true, backButtonClick: true})
        }
        defineClosingPage = () => {
                this.setState({activeComponentIndex: SURVEY_CREATION_POSITION_CONSTANT['define_closing_page']})
        }
        handleChange = (event) => {
                const name = event.target.name;
                let value = event.target.value;
                if (name === "survey_feedback") {
                        if(value.replace(/\s/g,'').length || value.length === 0) {
                                this.setState({ [name] : value });
                        }
                        var id = document.getElementById("error_reason");
                        if (id) {
                                id.setAttribute("class","d-none")
                        }
                        this.ClickComment(event)
                }
        }
        // Feedback comments validation check.
        handleClickValidation = (event, onClose) => {
                var count = 0;
                let value = this.state.survey_feedback
                if (value === '' || value === null || value === undefined) {
                        count = count + 1;
                }
                if (count === undefined || count === 0) {
                        this.feedbackSend(event)
                        onClose()
                }
                else {
                        if (count) {
                                if (!document.getElementById("error_reason")) {
                                        var id = document.getElementById("reasons");
                                        var node = document.createElement("span");
                                        node.innerText = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["survey_creating_condition_comment_error_msg"];
                                        node.setAttribute("style","color: red;")
                                        node.setAttribute("id","error_reason")
                                        id.appendChild(node);
                                }
                                else {
                                        var error_id = document.getElementById("error_reason");
                                        if(error_id) {
                                                error_id.removeAttribute("class")
                                        }
                                }
                        }
                }
                event.preventDefault();
        }
        feedbackSend = async (event) => {
                this.setState({survey_feedback: ''})
                let storeitem = JSON.parse(localStorage.getItem('userdata'));
                let apitoken = '';
                let userid = '';
                if (storeitem !== null) {
                        apitoken = storeitem.token;
                        userid = storeitem.id;
                }
                var headers = {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + apitoken
                }
                var feedbackData = {
                        surveyFeedback : this.state.survey_feedback.replace(/<br\s*\/?>/mg, "").replace( /(<([^>]+)>)/ig, '').replace(/(?:\r\n|\r|\n)/g, '<br>'),
                        email : storeitem.email,
                        first_name : storeitem.first_name,
                        last_name : storeitem.last_name
                }
                await axios.post(API_URL + "/surveyfeedback/surveyFeedback/" + userid + '?uId=' + userid, feedbackData, { headers: headers })
                        .then((response) => {
                                this.setState({survey_feedback: ''})
                        })
                        .catch((error) => {
                                let msg = error.response.data['error']
                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                        localStorage.clear();
                                        this.setState({redirect: 'true'})
                                }
                        })
        }
        // Popup open to user write a comment
        ClickComment = (event) => {
                confirmAlert({
                        customUI: ({ onClose }) => {
                        return (
                                <div className='custom-ui position-relative share_popup'>
                                        <div className="close-btn text-right">
                                                <button className="d-inline-block bg-white border-0 w-auto sharing-close-button text-rgb" onClick={(event) => {
                                                this.setState({survey_feedback : ''})
                                                onClose()
                                                }}><i className="ti-close pointer"></i></button>
                                        </div>
                                        <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_comment_heading']}</h3>
                                        <p className="mb-4 text-left">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_comment_subtext']}</p>
                                        <div className={"form-group hs-form-field focus"} id="reasons">
                                                <textarea className={"form-control hs-input textarea-height mb-0 p-3"} id="survey_feedback" rows="5" name="survey_feedback" onChange={this.handleChange} value={this.state.survey_feedback} ></textarea>
                                        </div>
                                        <button className="btn-bgcolor" name={'survey_comment'} onClick={(event) => {
                                                this.handleClickValidation(event, onClose)
                                        }}>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_comment_send_button']}</button>
                                </div>
                        )
                        }
                })
                event.preventDefault();
        }
        render() {
                let index = this.state.activeComponentIndex;
                let storeitem = JSON.parse(localStorage.getItem('userdata'));
                let childIndex = this.state.childCreatingConditionIndex;
                let editQuestionRedirect = this.state.editQuestionRedirect;
                let redirectUrlId = false;
                let changeDemographicButton = this.state.changeDemographicButton;
                let changeClosingPageButton = this.state.changeClosingPageButton;
                let changeDataProtectionPageButton = this.state.changeDataProtectionPageButton
                let editWelcomePage = this.state.editWelcomePage;
                const logoColor = {
                        padding: "7px 8px",
                        "border-radius": "5px",
                        "font-weight":"600",
                        marginLeft: "15px",
                        "font-size": "13px",
                        border:"2px solid",
                      };
                if (this.previewOpenInNewTab && this.titleSlug) {
                        window.open(BASEURL + URL_CONSTANT['surveycreation'] + "/" + this.titleSlug + "/" + URL_CONSTANT['preview'], '_blank')
                        this.previewOpenInNewTab = false;
                }
                if (!this.props.match.params.surveyid) {
                        if (this.state.surveyData) {
                                if (this.state.surveyData.id) {
                                        redirectUrlId = true;
                                        this.surveyMainId = parseInt(this.state.surveyData.id);
                                        if (this.surveyMainId) {
                                                if (localStorage.getItem('surveycreationdata')) {
                                                        localStorage.setItem("surveycreationdata_" + this.surveyMainId, localStorage.getItem('surveycreationdata'));
                                                        localStorage.removeItem("surveycreationdata");
                                                }
                                                if (localStorage.getItem('surveycreationallquestiondata')) {
                                                        localStorage.setItem("surveycreationallquestiondata_" + this.surveyMainId, localStorage.getItem('surveycreationallquestiondata'));
                                                        localStorage.removeItem("surveycreationallquestiondata");
                                                }
                                        }
                                }
                        }
                }
                let elements;
                if (index !== SURVEY_CREATION_POSITION_CONSTANT['survey_demographic_position']) {
                        elements = <div className={index == 7 ? "survey-form creation-survey col-12 col-sm-10 col-md-7 col-lg-5 container ques-in-mobile" : "survey-form creation-survey col-12 col-sm-10 col-md-7 col-lg-5 container"}> {this.section(index)} </div >;
                }
                else {
                        elements = <div> {this.section(index)} </div >;
                }
                let qestionTId = ''
                if (this.state.questionData) {
                        if (this.state.questionData.question_type_id) {
                                qestionTId = parseInt(this.state.questionData.question_type_id);
                        }
                }
                let status;
                let ActiveStatus;
                let surveyTitle;
                if (this.state.surveyData) {
                        if (this.state.surveyData.status) {
                                ActiveStatus = parseInt(this.state.surveyData.status);
                                if (parseInt(this.state.surveyData.status) === 1 || parseInt(this.state.surveyData.status) === 4 || parseInt(this.state.surveyData.status) === 5 || parseInt(this.state.surveyData.status) === 3) {
                                        status = true;
                                }
                        }
                }
                if (this.surveyMainId) {
                        let localData = JSON.parse(localStorage.getItem('surveycreationdata_' + this.surveyMainId));
                        if (localData) {
                                if (localData.survey_title) {
                                        surveyTitle = localData.survey_title;
                                }
                        }
                }
                let buttonName;
                let buttonDesignChange = false;
                let buttonSizeChange = false;
                let surveyData = this.state.surveyData;
                let lockButton = false;
                let account_type_category_id = false
                if(index === SURVEY_CREATION_POSITION_CONSTANT['survey_title_position'] && this.state.editSurveyTitleFlag) {
                        buttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['continue_button_edit_question'];
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_content_position'] && editWelcomePage) {
                        buttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['continue_button_edit_question'];
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && childIndex === 1) {
                        buttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['continue_button_to_finish_button'];
                        buttonDesignChange = true;
                        buttonSizeChange = true;
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && editQuestionRedirect) {
                        buttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['continue_button_edit_question'];
                }
                
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_picture_position'] && this.state.editSurveyCategory) {
                        buttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['continue_button_edit_question'];
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && qestionTId === '' && !this.state.addQuestionIndexCheck) {
                        buttonSizeChange = true;
                        buttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['continue_button'];
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && this.state.addQuestionIndexCheck) {
                        buttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['continue_button_edit_question'];
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_demographic_position'] && changeDemographicButton) {
                        buttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['continue_button_edit_question'];
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['define_closing_page'] && changeClosingPageButton) {
                        buttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['continue_button_edit_question'];
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_data_protection'] && changeDataProtectionPageButton) {
                        buttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['continue_button_edit_question'];
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_page_position']) {
                        if(surveyData.is_welcome_page === "1" || surveyData.is_welcome_page === 1 || surveyData.is_welcome_page === "true" || surveyData.is_welcome_page === true) {
                                buttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['continue_button'];
                        }
                        if(surveyData.is_welcome_page === "2" || surveyData.is_welcome_page === 2 || surveyData.is_welcome_page === "false" || surveyData.is_welcome_page === false) {
                                buttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['continue_button_edit_question'];
                        }
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_custom_logo']) {
                        buttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['continue_button_edit_question'];
                                let storeitem = JSON.parse(localStorage.getItem('userdata'));
                                let plan_type = localStorage.getItem('plan_type');
                                if(storeitem.plan_type == null && plan_type == undefined){
                                        lockButton = true;
                                        account_type_category_id = storeitem.account_type_category_id
                                }
                }
                else {
                        buttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['continue_button'];
                        
                }
                let backbuttonName;
                let minWidthButton = false;
                if(index === SURVEY_CREATION_POSITION_CONSTANT['survey_title_position'] && this.state.editSurveyTitleFlag) {
                        backbuttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['back_button_edit_question'];
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_page_position'] && editWelcomePage) {
                        backbuttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['back_button_edit_question'];
                        minWidthButton = true;
                }
                else if ((index === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_content_position'] && editWelcomePage) || (index === SURVEY_CREATION_POSITION_CONSTANT['survey_picture_position'] && this.state.editSurveyCategory)) {
                        backbuttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['back_button'];
                        minWidthButton = true;
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && editQuestionRedirect) {
                        backbuttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['back_button_edit_question'];
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_category_position'] && this.state.editSurveyCategory) {
                        backbuttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['back_button_edit_question'];
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_demographic_position'] && changeDemographicButton) {
                        backbuttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['back_button_edit_question'];
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['define_closing_page'] && changeClosingPageButton) {
                        backbuttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['back_button_edit_question'];
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_data_protection'] && changeDataProtectionPageButton) {
                        backbuttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['back_button_edit_question'];
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position'] && this.state.addQuestionIndexCheck) {
                        backbuttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['back_button_edit_question'];
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_custom_logo']) {
                        backbuttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['back_button_edit_question'];
                }
                else {
                        backbuttonName = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['back_button'];
                        buttonSizeChange = true;
                }
                if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_category_position'] && this.state.editSurveyCategory) {
                        minWidthButton = true;
                }
                /*-- Change survey title --*/
                let changeSurveyTitle;
                if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_title_position'] && surveyTitle) {
                        changeSurveyTitle = surveyTitle;
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_category_position'] && surveyTitle) {
                        changeSurveyTitle = surveyTitle;
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_picture_position'] && surveyTitle) {
                        changeSurveyTitle = surveyTitle;
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_page_position'] && surveyTitle) {
                        changeSurveyTitle = surveyTitle;
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_content_position'] && surveyTitle) {
                        changeSurveyTitle = surveyTitle;
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_welcome_text_position'] && surveyTitle) {
                        changeSurveyTitle = surveyTitle;
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_element_position']) {
                        if (this.state.ActiveQuestionIndex) {
                                if (this.state.addQuestionIndex) {
                                        changeSurveyTitle = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['question_survey_title'] + " " + (parseInt(this.state.addQuestionIndex) + 1);
                                }
                                else {
                                        changeSurveyTitle = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['question_survey_title'] + " " + (this.state.ActiveQuestionIndex + 1);
                                }
                        }
                        else if (this.state.ActiveQuestionIndex === 0) {
                                changeSurveyTitle = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['question_survey_title'] + " " + (this.state.ActiveQuestionIndex + 1);
                        }
                        else {
                                changeSurveyTitle = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['common_survey_title'];
                        }
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_demographic_position'] && surveyTitle) {
                        changeSurveyTitle = surveyTitle;
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['define_closing_page'] && surveyTitle) {
                        changeSurveyTitle = surveyTitle;
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_data_protection'] && surveyTitle) {
                        changeSurveyTitle = surveyTitle;
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && surveyTitle) {
                        changeSurveyTitle = surveyTitle;
                }
                else if (index === SURVEY_CREATION_POSITION_CONSTANT['survey_end_position'] && surveyTitle) {
                        changeSurveyTitle = surveyTitle;
                }
                else {
                        changeSurveyTitle = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['common_survey_title']
                }
                let paddingTop = null;
                if( window.innerWidth > 575 && this.state.activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && childIndex === 1 && (storeitem.account_type_category_id == 4 || storeitem.account_type_category_id == 2)) {
                        paddingTop =  {"padding-top":"8px"};
                }
                return (
                        <main>
                                {(this.state.RedirectToProfile)
                                        ? <Redirect to="/mein-bereich"></Redirect>
                                        : null
                                }
                                {(this.state.RedirectToMypolls) ?
                                        <Redirect to="/mein-bereich"></Redirect>
                                        : null
                                }
                                {(this.state.RedirectToEditsurvey)
                                        ? <Redirect to={"/" + URL_CONSTANT['findparticipation'] + "/" + window.btoa(this.surveyMainId)}></Redirect>
                                        : null
                                }
                                {(redirectUrlId) ?
                                        <Redirect to={"/" + URL_CONSTANT['surveycreation'] + "/" + window.btoa(this.surveyMainId)}></Redirect>
                                        : null
                                }
                                {(this.state.redirect === 'true') ?
                                        <Redirect to="/anmelden"></Redirect>
                                        : null
                                }
                                {(this.state.redirectToCheckout === true) ?
                                        storeitem.account_type_category_id == 4?
                                        <Redirect to="/business-checkout"></Redirect>
                                        : storeitem.account_type_category_id == 2? <Redirect to="/university-checkout"></Redirect>:<Redirect to="/checkout"></Redirect>
                                        : null
                                }
                                {(this.state.redirectPagenotfound === true) ?
                                        <Redirect to="/404"></Redirect>
                                        : null
                                }
                                {(this.state.loading === 'true') ?
                                        BUBBLE_LOADING
                                        : null
                                }
                                <div className="modal__wizard d-block" id="exampleModalCenter">
                                        <div className="modal-dialog  modal-width" role="document">
                                                <div className="modal-content">
                                                        <div className="container-fluid px-0">
                                                                <div className="live-modal-content modal-header bg-white">
                                                                        <div className="container-fluid">
                                                                                <div className="row align-items-center no-gutters">
                                                                                        <div className="col-8 col-sm-8 col-md-8 col-xl-9 text-left d-flex">
                                                                                                <div className="survey-create-link text-truncate" style={paddingTop} >
                                                                                                        <a className="text-dark create-survey-link" href={BASEURL} onClick={this.stopRedirect} title={changeSurveyTitle}>{changeSurveyTitle}</a>
                                                                                                </div>
                                                                                                {this.state.activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && childIndex === 1 ?
                                                                                                        <button type="button" className="bg-transparent border-0 pointer ml-1 ml-md-2 lock_btn_text_grey" onClick={this.editSurveyTitle} style={{marginTop: "1px"}}><i className="fas fa-edit"></i></button>
                                                                                                        : null
                                                                                                }
                                                                                                {this.state.activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && childIndex === 1 ?
                                                                                                        <button type="button" className="bg-transparent border-0 pointer ml-1 ml-md-2 align-items-center pt-1 lock_btn_text_grey" onClick={this.editSurveyCategory} style={{marginTop: "1px"}}><i className="fas fa-image font-16"></i></button>
                                                                                                        : null
                                                                                                }
                                                                                                {this.state.activeComponentIndex === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && childIndex === 1 ?
                                                                                                        storeitem.account_type_category_id == 4 || storeitem.account_type_category_id == 2 ?
                                                                                                        window.innerWidth <= 575 ? 
                                                                                                        <button type="button" className="bg-transparent border-0 pointer ml-1 ml-md-2 align-items-center pt-1 lock_btn_text_grey" onClick={this.customLogo}  ><i className="fas fa-light fa-palette font-16" style={{marginTop: "1px"}}></i> </button> : <button type="button" className="bg-transparent border-1 pointer align-items-center lock_btn_text_grey" onClick={this.customLogo}  style={logoColor}><i className="fas fa-light fa-palette font-16" style={{paddingRight:"5px"}}></i> LOGO UND FARBEN </button> 
                                                                                                        : null : null
                                                                                                }
                                                                                        </div>
                                                                                        <div className="col-4 col-sm-4 col-md-4 col-xl-3 text-right closetab">
                                                                                                {/* class --- save_exit  */}
                                                                                                <div className="d-none d-lg-block">
                                                                                                        {(index === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && childIndex === 1) ?
                                                                                                                <div className="close_link_btn">
                                                                                                                        {/* <button type="button" className="btn text-dark w-auto d-inline-block text-gray font-18 float-right" name="save_data" title="Vorschau" onClick={this.clickOnPreview}>Vorschau</button> */}
                                                                                                                        <Link type="button" className="preview-link text-dark w-auto d-inline-block text-gray font-18 float-right" name="save_data" to={"/" + this.state.surveyData.survey_title_slug + "/" + URL_CONSTANT['preview']} title="Vorschau" onClick={this.clickOnPreview}>Vorschau</Link>
                                                                                                                </div>
                                                                                                                : null
                                                                                                        }
                                                                                                        {(index === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && childIndex === 1) ? null
                                                                                                                :
                                                                                                                (status) ?
                                                                                                                        <Link to="/mein-bereich" onClick={this.clearRelatedKey} className="text-dark close custom-opacity d-none d-lg-block font-18"><i className="ti-close custom-opacity d-done d-lg-block font-26 font-weight-bold text-dark"></i></Link>
                                                                                                                        :
                                                                                                                        <Link to="/mein-bereich" onClick={this.clearRelatedKey} className="text-dark close custom-opacity d-none d-lg-block font-18 custom-text-color">{((index === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && this.state.childCreatingConditionIndex === 1)) ? <i className="ti-close custom-opacity d-done d-lg-block closebtn_mobile font-weight-bold text-dark"></i> : (index === SURVEY_CREATION_POSITION_CONSTANT['survey_end_position']) ? <i className="ti-close custom-opacity d-done d-lg-block font-26 font-weight-bold text-dark"></i> : "Speichern und beenden"}</Link>
                                                                                                        }
                                                                                                </div>
                                                                                                <div className="d-block d-lg-none">
                                                                                                        {(index === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && childIndex === 1) ?
                                                                                                                <div className="close_link_btn">
                                                                                                                        {/* <button name="save_data" className="btn text-dark w-auto text-gray d-inline-block float-right" title="sparen" onClick={this.clickOnPreview}>Vorschau</button> */}
                                                                                                                        <Link type="button" className="preview-link text-dark w-auto d-inline-block text-gray font-18 float-right" name="save_data" to={"/" + this.state.surveyData.survey_title_slug + "/" + URL_CONSTANT['preview']} title="Vorschau" onClick={this.clickOnPreview}>Vorschau</Link>
                                                                                                                </div>
                                                                                                                : null
                                                                                                        }
                                                                                                        {(index === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && childIndex === 1) ?
                                                                                                                <div className="d-none">
                                                                                                                        <Link to="/mein-bereich" onClick={this.clearRelatedKey} className="text-dark close d-block d-lg-none">
                                                                                                                                {(index === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && childIndex === 1) ?
                                                                                                                                        <i className="ti-close custom-opacity d-block d-lg-none closebtn_mobile font-weight-bold text-dark no_display_below_500"></i>
                                                                                                                                        : <i className="ti-close custom-opacity d-block d-lg-none font-weight-bold text-dark"></i>
                                                                                                                                }
                                                                                                                        </Link>
                                                                                                                </div>
                                                                                                                : <Link to="/mein-bereich" onClick={this.clearRelatedKey} className="text-dark close d-block d-lg-none">
                                                                                                                        {(index === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && childIndex === 1) ?
                                                                                                                                <i className="ti-close custom-opacity d-block d-lg-none closebtn_mobile font-weight-bold text-dark no_display_below_500"></i>
                                                                                                                                : <i className="ti-close custom-opacity d-block d-lg-none font-weight-bold text-dark"></i>
                                                                                                                        }
                                                                                                                </Link>
                                                                                                        }
                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="row survey-creation-modal wizard wizard__container justify-content-center align-items-center no-gutters">
                                                                        <form className="tab-content text-left max-width-content" name="myForm" onSubmit={this.continue}>
                                                                                <div className="content clearfix">
                                                                                        {elements}
                                                                                </div>
                                                                                {window.innerWidth >= 992 && index === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && childIndex === 1 ?
                                                                                        <div className="comments">
                                                                                                <div className="add-comment text-center">
                                                                                                        {/* eslint-disable-next-line */}
                                                                                                        <a href="#" onClick={this.ClickComment} id="comment" name="comment" title="Feedback"><i className="ti-comment-alt icon-m border-radius create-plus box-shadow"></i></a>
                                                                                                </div>
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                <div className="wizard">
                                                                                        <div className="actions clearfix">
                                                                                                {(index === SURVEY_CREATION_POSITION_CONSTANT['survey_end_position'])
                                                                                                        ?
                                                                                                        <ul className="mb-2 mb-sm-0" role="menu" aria-label="Pagination">
                                                                                                                <li className={index !== SURVEY_CREATION_POSITION_CONSTANT['survey_title_position'] ? "default" : "disabled"} aria-disabled="true">
                                                                                                                        {// eslint-disable-next-line
                                                                                                                        }<Link to="/mein-bereich" onClick={() => this.changeStatusCleardata(this.surveyMainId, 5, ActiveStatus, "mein-bereich")} className="back_button" title={SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['close_button']}>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['close_button']}</Link>
                                                                                                                </li>
                                                                                                                <li aria-hidden="false" aria-disabled="false">
                                                                                                                        {// eslint-disable-next-line
                                                                                                                        }<Link to={"/" + URL_CONSTANT['findparticipation'] + "/" + window.btoa(this.surveyMainId)} onClick={() => this.changeSurveyStatus(this.surveyMainId, 5, ActiveStatus, "edit-survey")} className="pointer" title={SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['find_participants_button']}>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['find_participants_button']}</Link>
                                                                                                                </li>
                                                                                                        </ul>
                                                                                                        :
                                                                                                        <ul className="mb-0" role="menu" aria-label="Pagination">
                                                                                                                <li className={(index !== SURVEY_CREATION_POSITION_CONSTANT['survey_title_position']) ? ((index === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && childIndex === 1) ? "disabled" : "default") : (this.state.editSurveyTitleFlag === true) ? "defalut" : "disabled"} aria-disabled="true">
                                                                                                                        {index === SURVEY_CREATION_POSITION_CONSTANT['survey_creating_conditions_position'] && childIndex === 1 
                                                                                                                                ? null
                                                                                                                                : <a href="test" onClick={this.back} className={((buttonSizeChange) ? "back_button creation-fixed-width-button" : "back_button") + (minWidthButton ? " survey-creation-big-button" : "")}>{backbuttonName}</a>
                                                                                                                        }
                                                                                                                </li>
                                                                                                                {lockButton ?
                                                                                                                <li aria-hidden="false" aria-disabled="false">
                                                                                                                {/* eslint-disable-next-line */}
                                                                                                                <Link to = {account_type_category_id == 4? "/business-checkout" : account_type_category_id == 2? "/university-checkout" : "/university-checkout"}className={((buttonSizeChange) ? (buttonDesignChange) ? "bg-green live-test-submit pointer creation-fixed-width-button " : "live-test-submit pointer creation-fixed-width-button" : (buttonDesignChange) ? "bg-green live-test-submit pointer " : "live-test-submit pointer") + (minWidthButton ? " survey-creation-big-button" : "")} onClick={() => localStorage.setItem('redirectURL',window.location.href)}> <i className='ti-lock'
                                                                                                                 ></i> {buttonName}</Link>
                                                                                                                </li>
                                                                                                                : <li aria-hidden="false" aria-disabled="false">
                                                                                                                {// eslint-disable-next-line
                                                                                                                }<button type="submit" className={((buttonSizeChange) ? (buttonDesignChange) ? "bg-green live-test-submit pointer creation-fixed-width-button " : "live-test-submit pointer creation-fixed-width-button" : (buttonDesignChange) ? "bg-green live-test-submit pointer " : "live-test-submit pointer") + (minWidthButton ? " survey-creation-big-button" : "")}>{buttonName}</button>
                                                                                                        </li>
                                                                                                                }
                                                                                                        </ul>
                                                                                                }
                                                                                        </div>
                                                                                </div>
                                                                        </form>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </main>
                );
        }
}
export default SurveyCreation;