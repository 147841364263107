import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import CheckoutForm from "./CheckoutForm";

import React, { Component } from "react";

class Payment extends Component {
  stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY,{locale:'de'});
  render() {
    return (
      <Elements stripe={this.stripe}>
        {/* <CheckoutForm /> */}
        {this.props.children}
      </Elements>
    );
  }
}

export default Payment;
