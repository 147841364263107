import React, { Component } from "react";
import axios from "axios";
import {
  SURVEY_CREATION_CONSTANT,
  BUBBLE_LOADING,
} from "../../../../../js/constants/FrontEndConstant";
import { LANGUAGE_CODE, API_URL } from "../../../../constants/DotEnv";

export default class SurveyDataProtectionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      declaration_consent_title:
        SURVEY_CREATION_CONSTANT[LANGUAGE_CODE][
          "survey_data_protection_heading"
        ],
      declaration_consent_description: "",
      loading: false,
      declaration_consent_toggle: false,
    };
  }

  handleChange = async (e) => {
    let name = e.target.name;
    let value;
    if (name == "declaration_consent_toggle") value = Number(e.target.checked);
    else value = e.target.value;
    this.setState({ [name]: value });

    let surveyData = this.props.data;
    var survey_data = JSON.parse(
      localStorage.getItem("surveycreationdata_" + surveyData.id)
    );
    survey_data[e.target.name] = value;

    // If value of title and description doesnot change save default value
    let survey_title = surveyData.survey_title;
    let survey_mail = JSON.parse(localStorage.getItem("userdata")).email;
    if (this.state.declaration_consent_title == null) {
      survey_data["declaration_consent_title"] =
        SURVEY_CREATION_CONSTANT[LANGUAGE_CODE][
          "survey_data_protection_heading"
        ];
    }
    if (this.state.declaration_consent_description == null) {
      survey_data[
        "declaration_consent_description"
      ] = `${SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["survey_data_protection_text0"]}\n\n${SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["survey_data_protection_text1"]} "${survey_title}".\n${SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["survey_data_protection_text2"]}\n\n${SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["survey_data_protection_text3"]}\n\n${SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["survey_data_protection_text4"]} ${survey_mail} ${SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["survey_data_protection_text5"]}`;
    }
    localStorage.setItem(
      "surveycreationdata_" + surveyData.id,
      JSON.stringify(survey_data)
    );
    window.dispatchEvent(new Event("storage"));
  };
  getSurveyEndPageInfo = async () => {
    let storeitem = JSON.parse(localStorage.getItem("userdata"));
    this.setState({ loading: "true" });
    await axios
      .get(
        API_URL +
          "/commonApi/getDeclarationConsentPage/" +
          LANGUAGE_CODE +
          "/" +
          this.props.SurveyId +
          "?uId=" +
          storeitem.id
      )
      .then((response) => {
        let responseData = response.data.success;
        let declaration_consent_title = responseData.declaration_consent_title;
        let declaration_consent_description =
          responseData.declaration_consent_description;
        let declaration_consent_toggle = Boolean(
          Number(responseData.declaration_consent_toggle)
        );

        this.setState({
          declaration_consent_title,
          declaration_consent_description,
          declaration_consent_toggle,
          loading: "false",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: "false" });
      });
  };
  componentDidMount() {
    this.getSurveyEndPageInfo();
    let data = this.props.data;
    let survey_title = data.survey_title;
    let survey_mail = JSON.parse(localStorage.getItem("userdata")).email;
    this.setState({
      declaration_consent_description: `${SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["survey_data_protection_text0"]}\n\n${SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["survey_data_protection_text1"]} "${survey_title}".\n${SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["survey_data_protection_text2"]}\n\n${SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["survey_data_protection_text3"]}\n\n${SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["survey_data_protection_text4"]} ${survey_mail} ${SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["survey_data_protection_text5"]}`,
    });
  }
  render() {
    return (
      <div
        className={
          "tab-pane sectionstep body current custom-form-animated animated " +
          (this.props.backButtonClick ? "fadeInLeft" : "fadeInRight")
        }
      >
        {this.state.loading === "true" ? BUBBLE_LOADING : null}
        <div className="row survey-form-detail mt-4">
          <div className="col-12 col-md-12">
            <div className="swich-slider d-flex align-items-center">
              <label className="switch mr-2">
                <input
                  type="checkbox"
                  data-toggle="toggle"
                  name="declaration_consent_toggle"
                  checked={this.state.declaration_consent_toggle}
                  onClick={this.handleChange}
                />
                <span className="slider round"></span>
              </label>
              <span
                className="slider-text-findparticipation"
                style={{ color: "#202124" }}
              >
                {
                  SURVEY_CREATION_CONSTANT[LANGUAGE_CODE][
                    "data_protection_toggle"
                  ]
                }
              </span>
            </div>
          </div>
        </div>
        <div className="mt-3 form-group survey-form-detail">
          <label htmlFor="headingValue" className="sr-only">
            sdf
          </label>
          <input
            type="text"
            name="declaration_consent_title"
            id="headingValue"
            className="get-header form-control"
            value={this.state.declaration_consent_title}
            placeholder={
              SURVEY_CREATION_CONSTANT[LANGUAGE_CODE][
                "survey_data_protection_heading"
              ]
            }
            onChange={this.handleChange}
          />
        </div>
        <div className="align-items-center mt-4">
          <label htmlFor="freetextValue" className="sr-only">
            sdf
          </label>
          <textarea
            rows="15"
            cols="50"
            name="declaration_consent_description"
            id="freetextValue"
            className="w-100 text-msg text-area-design get-text"
            value={this.state.declaration_consent_description}
            onChange={this.handleChange}
          >
          </textarea>
        </div>
      </div>
    );
  }
}
