import React, { Fragment } from 'react';
import { SURVEY_CREATION_CONSTANT, FREE_TEXT_OPTIOPNS } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
import Button from '@material-ui/core/Button';
class SurveyElementFreetext extends React.Component {
    clickInputRadio = (e) => {
        if (e.currentTarget.name) {
            document.getElementById(e.currentTarget.name).click();
        }
    }
    render() {
        let data = this.props.value;
        let onChange = this.props.onChange;
        let textOption = '';
        const freeTextOptions = FREE_TEXT_OPTIOPNS[LANGUAGE_CODE];
        if (freeTextOptions && Object.keys(freeTextOptions).length) {
            textOption = freeTextOptions.map((textoptions, index) => {
                return (
                    <div className="col-md-4 participation-survey pb-3 survey-radio-effect">
                        <label htmlFor={"free_text_ans_type" + index} className="MuiButton-effect w-100 mb-0 pointer btn-radio survey-pic-shadow" key={index}>
                            <Button variant="contained" color="#00b3f4" className="w-100" name={"free_text_ans_type" + index} onClick={(e)=>this.clickInputRadio(e)}>
                                <div className="custom-radio-btn btn w-100">
                                    <div className="custom-control custom-radio custom-control-inline pl-0 px-4 py-3 w-100">
                                        <input type="radio" id={"free_text_ans_type" + index} name="free_text_ans_type" className="custom-control-input" defaultChecked={((parseInt(data.free_text_ans_type) === index) && data.free_text_ans_type) ? (parseInt(data.free_text_ans_type) === index) ? true : false : (!data.free_text_ans_type && index === 0)? true : false} value={textoptions.id} onChange={onChange} />
                                        <svg width="20px" height="20px" viewBox="0 0 20 20">
                                            <circle cx="10" cy="10" r="9"></circle>
                                            <path style={{"--colorpicker": "#00b3f4" }} d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" class="inner"></path><path d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" class="outer" style={{"--colorpicker": "#00b3f4" }}></path>
                                        </svg>
                                        <span class="ml-3 ml-md-3 p-1 word-break-all d-inline-block w-100 text-initial font-16">{textoptions.free_text_name}</span>
                                    </div>
                                </div>
                            </Button>
                        </label>
                    </div>
                )
            })
        }
        return (
            <Fragment>
                <div>
                    <div id="errorSurveyMessage"></div>
                    <div className="form-group survey-form-detail mt-4 mt-md-5">
                        <label htmlFor="question_text" className="sr-only">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_question_IPH']}</label>
                        <input type="text" id="question_text" name="question_text" value={data.question_text} onChange={onChange} className={(this.props.errorElementPosition === 1 && this.props.validationMessage) ? "form-control border-bottom-red" : "form-control"} placeholder={SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_question_IPH']} />
                        {(this.props.errorElementPosition === 1 && this.props.validationMessage)
                            ? <div id="errorSurveyMessage" className="error-text mt-2">{(this.props.validationMessage) ? <p>{this.props.validationMessage}</p> : null}</div>
                            : null}
                    </div>
                    <div className="row">
                        {textOption}
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default SurveyElementFreetext;