import React from 'react';
import { Redirect } from "react-router-dom";
import axios from "axios";
import { BUBBLE_LOADING } from "../../constants/FrontEndConstant";
import { API_URL, FRONT_USER } from '../../constants/DotEnv';
class Unsubscribenewsletter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            redirectPagenotfound: false,
            redirectToMyprofile: false
        }
        this.id = this.props.match.params.id ? this.props.match.params.id : "";
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.unsubscribeNewsletter();
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
        if (count === -1) {
            document.querySelector('.navbar-collapse').classList.remove('show');
            document.querySelector('header').classList.remove("header_collapse");
            document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
        }
    }
    async unsubscribeNewsletter() {
        this.setState({ loading: true })
        let redirectToMyprofile = this.state.redirectToMyprofile;
        await axios.get(API_URL + FRONT_USER + "/unsubscribenewslatter/" + this.id)
            .then((response) => {
                if (response.data['success']) {
                    let data = response.data['success'].unsubscribenewslettter;
                    if (data) {
                        redirectToMyprofile = true;
                        localStorage.setItem("unsubscribenewslatter", this.props.match.params.id);
                    }
                }
                this.setState({ loading: false, redirectToMyprofile })
            })
            .catch((error) => {
                if (error.response.data.error[0]) {
                    redirectToMyprofile = true;
                    localStorage.setItem("unsubscribenewslattererror", this.props.match.params.id);
                }
                this.setState({ loading: false, redirectToMyprofile })
            })
    }
    render() {
        return (
            <main>
                {(this.state.loading) ?
                    BUBBLE_LOADING
                    : null
                }
                {(this.state.redirectPagenotfound === true) ?
                    <Redirect to="/404"></Redirect>
                    : null
                }
                {(this.state.redirectToMyprofile) ?
                    <Redirect to="/anmelden"></Redirect>
                    : null
                }
            </main>
        );
    }
}
export default Unsubscribenewsletter;