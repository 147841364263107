import React from 'react';
import { SURVEY_PARTICIPATION } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
class FreeTextTypeQuestion extends React.Component {
    constructor(props)
    {
        super(props);
        this.state={
            shortAnswerFreetextLengthAllowed : 500,
            longAnswerFreetextLengthAllowed : 2000
        }
    }

    /**this function is for prevent form data submit when press enter key */
    stopSubmitting = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (this.props.lastIndex === 1 && !this.props.demographyQuestionDisplay) {
                this.props.finish(e)
            } else {
                this.props.continue(e)
            }
        }
    }
    render() {
        let data = this.props.questiondata;
        let surveydetail = this.props.surveydetail
        let englishbuttonlabels;
        englishbuttonlabels = surveydetail.englishbuttonlabels == "1" ? "EN" : "DE"

        return (
            <div key={this.props.questiondata.question_id} className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
                <div className="question-heading">
                    <h3 className="text-dark font-weight-bold text-break survey-title">{data.question_text}</h3>
                    {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
                </div>
                <div className="form-row survey-form-question">
                    { (data.free_text_ans_type === 0 && data.hasOwnProperty('free_text_ans_type')) ?
                        <div className="form-group col-12 fill-colour free-text-design">
                            <label htmlFor="yourcolour" className="sr-only">{SURVEY_PARTICIPATION[englishbuttonlabels]['common_textfield_IPH']}</label>
                            <input type="text" className="form-control free-text-textarea border-0" name={data.question_id} onKeyPress={this.stopSubmitting} id="yourcolour" onChange={this.props.onChange} placeholder={SURVEY_PARTICIPATION[englishbuttonlabels]['common_textfield_IPH']}
                                value={(this.props.questionanswer.free_text_answer === undefined)
                                    ? (this.props.questionanswer[data.question_id]) ? this.props.questionanswer[data.question_id] : ""
                                    : (this.props.questionanswer.free_text_answer !== "") ? (this.props.questionanswer.free_text_answer) : ""
                                }
                            maxlength={this.state.shortAnswerFreetextLengthAllowed}
                                />
                            <span className="title-counter float-right mt-1">{(this.props.questionanswer[this.props.questiondata.question_id]) ? 
                            (this.state.shortAnswerFreetextLengthAllowed)  -
                           ( (this.props.questionanswer[this.props.questiondata.question_id])?.length) + `/${this.state.shortAnswerFreetextLengthAllowed}` :
                            `${this.state.shortAnswerFreetextLengthAllowed}/${this.state.shortAnswerFreetextLengthAllowed}`}
                            </span>
                    <div id="errorSurveyMessage" className="error-text mt-2">{(this.props.validationMessage) ? <p>{this.props.validationMessage}</p> : null}</div>
                        </div>
                        :
                        <div className="form-group col-12 fill-colour">
                            <textarea className="form-control free-text-textarea font-16 h-170 p-4 textarea-border-none" id="yourcolour" rows="5" name={data.question_id} onChange={this.props.onChange} placeholder={SURVEY_PARTICIPATION[englishbuttonlabels]['common_textfield_IPH']} maxlength={this.state.longAnswerFreetextLengthAllowed}
                                value={(this.props.questionanswer.free_text_answer === undefined)
                                    ? (this.props.questionanswer[data.question_id]) ? this.props.questionanswer[data.question_id] : ""
                                    : (this.props.questionanswer.free_text_answer !== "") ? (this.props.questionanswer.free_text_answer) : ""}>
                            </textarea>
                            <span className="title-counter float-right mt-1">{(this.props.questionanswer[this.props.questiondata.question_id]) ?
                            (this.state.longAnswerFreetextLengthAllowed)  -
                           ((this.props.questionanswer[this.props.questiondata.question_id])?.length) + `/${this.state.longAnswerFreetextLengthAllowed}` :
                            `${this.state.longAnswerFreetextLengthAllowed}/${this.state.longAnswerFreetextLengthAllowed}`}
                            </span>
                        </div>
                    }
                </div>
            </div>)
    }
}
export default FreeTextTypeQuestion;