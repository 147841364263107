import React from 'react';
import axios from "axios";
import { BUBBLE_LOADING, META_FACEBOOK, BREADCRUMB_SCHEMA } from '../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, META_TITLE_APPEND } from '../../constants/DotEnv';
import LexikonPageHeader from '../subPageHeader/SubPageHeader';
class HilfeCenter extends React.Component {
    constructor() {
        super();
        this.state = {
            lexikonContentData: {},
            loading: 'false',
            dataLength: '',
            title: '',
            sub_title: ''
        }
    }
    componentWillUnmount() {
        setTimeout(() => {
            window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
            window.removeSchema();
        }, 100)
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.hilfeCenterContent();
        setTimeout(() => {
            var schema2 = window.positionCreate(BREADCRUMB_SCHEMA['lexikon']['schema2']['position'], BREADCRUMB_SCHEMA['lexikon']['schema2']['url'], BREADCRUMB_SCHEMA['lexikon']['schema2']['name']);
            var schemaObject = window.schemaObj(BREADCRUMB_SCHEMA['schema1'], schema2, BREADCRUMB_SCHEMA['lexikon']['schema3']);
            window.setSchema(schemaObject);
        }, 100)
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
        if (count === -1) {
            document.querySelector('.navbar-collapse').classList.remove('show');
            document.querySelector('header').classList.remove("header_collapse");
            document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
        }
    }
    hilfeCenterContent = () => {
        this.setState({ loading: 'true' })
        let self = this;
        let lexikonContentData = '';
        let dataLength = '';
        let title = '';
        let sub_title = '';
        let meta_title = "";
        let meta_description = "";
        let meta_keywords = '';
        let og_title = '';
        let og_description = '';
        let og_image = '';
        axios.get(API_URL + '/cms/getCmsByID/93/' + LANGUAGE_CODE)
            .then((response) => {
                lexikonContentData = response.data.success.cms;
                dataLength = Object.keys(lexikonContentData).length;
                title = lexikonContentData['title'];
                sub_title = lexikonContentData['sub_title'];
                meta_title = lexikonContentData['title'] + META_TITLE_APPEND;
                meta_description = lexikonContentData['metadescription'];
                meta_keywords = lexikonContentData['metakeyword'];
                og_title = lexikonContentData['title'] + META_TITLE_APPEND;
                og_description = lexikonContentData['metadescription'];
                og_image = META_FACEBOOK['og_image'];
                window.setMeta({ "description": meta_description, "keywords": meta_keywords });
                window.setOgTag(og_title, og_description, og_image, window.location.href);
                window.setDocumentTitle(meta_title)
                self.setState({ loading: 'false', lexikonContentData, dataLength, title, sub_title })
            })
            .catch((error) => {
                self.setState({ loading: 'false' })
            })
    }
    render() {
        let length = Object.keys(this.state.lexikonContentData).length
        let lexikonContentData = this.state.lexikonContentData;
        return (
            <main>
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                <LexikonPageHeader data={lexikonContentData} title={this.state.title} sub_title={this.state.sub_title} dataLength={this.state.dataLength} />
                {(length > 0 && this.state.loading === 'false') ?
                    <section className="section-blog">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <div className="blog-lising">
                                        <div dangerouslySetInnerHTML={{ __html: lexikonContentData['content'] }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    : null
                }
            </main>
        );
    }
}
export default HilfeCenter;