import React from 'react';
import { Link } from 'react-router-dom';
import { SURVEY_PARTICIPATION, BUBBLE_LOADING } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, BASEURL, IMAGE_BASEURL, API_URL } from '../../../../constants/DotEnv';
import axios from "axios";
class SurveyEndingPageNsu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 'false',
            survey_end_page_title:null,
            survey_end_page_description:null
        }
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.getSurveyEndPageInfo()
    }
    /** this function is for scrolling up page */
    scrollUpFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    getSurveyEndPageInfo = async () =>{
        this.setState({ loading: 'true' })
        await axios.get(API_URL + "/commonApi/getSurveyEndPageInfo/" + LANGUAGE_CODE + '/'+ this.props.survey_id +'?uId=' + this.props.user_id)
        .then((response) => {
            this.setState({survey_end_page_title:response.data.success.survey_end_page_title})
            this.setState({survey_end_page_description:response.data.success.survey_end_page_description})
            this.setState({ loading: 'false' })
        })
        .catch((error) => {
            console.log(error);
            this.setState({ loading: 'false' });
        })
    }
    clearLocalstorageForQuestion = () => {
        if (this.surveyMainId) {
            localStorage.removeItem('surveyparticipationquestion_' + this.surveyMainId);
            if (!this.props.startSurvey) {
                localStorage.removeItem('surveydetail_' + this.surveyMainId);
                window.close();
            }
        }
    }
    render() {
        let survey_data = JSON.parse(localStorage.getItem('surveydetail_' + this.props.survey_id))
        let englishbuttonlabels = survey_data?.englishbuttonlabels == "1" ? "EN" : "DE"
        let survey_optional_logo =  survey_data.survey_optional_logo;
        let endpageData = this.props.endpageData!=null?this.props.endpageData:'';
        return (<main>
            {(this.state.loading === 'true') ? BUBBLE_LOADING : null }
            <div className="" role="document">
                <div className="survey-bg-img position-relative change-header-design_line">
                    <div className="container-fluid px-0">
                        <div className="modal_header border-bottom-0">
                            <div className="container container-large">
                                <div className="row align-items-center">
                                    <div className="col-8 col-md-9 text-left ">
                                    {survey_data.account_type_category_id == 4 || survey_data.account_type_category_id == 2?
                                    survey_data.isUserHaveActivePlan == 1?
                                    survey_optional_logo ?
                                        <div className="modal-logo">
                                        <a href={BASEURL} className="navbar-brand">
                                            <img className="light-logo custom-logo-size" src={survey_optional_logo} alt="empirio-logo"  />
                                        </a>
                                    </div>
                                    : null : <div className="modal-logo">
                                        <a href={BASEURL} className="navbar-brand">
                                            <img className="light-logo custom-logo-size" src={survey_data.logo_image1} alt="empirio-logo"  />
                                        </a>
                                    </div>
                                    : <div className="modal-logo">
                                    <a href={BASEURL} className="navbar-brand">
                                        <img className="light-logo custom-logo-size" src={survey_data.logo_image1} alt="empirio-logo"  />
                                    </a>
                                    </div>
                                    }
                                    </div>
                                    <div className="col-4 col-md-3 text-right closetab pb-2">
                                    {this.props.showSurveyPreviewEndPage
                                    ? <Link className="text-white close font-weight-bold" to='#' onClick={this.props.clearLocalstorageForQuestion}> <i className="ti-close ti-close-participation font-weight-bold"></i> </Link>
                                    : <Link className="text-white close font-weight-bold" to={ "/umfragen/"}> <i className="ti-close ti-close-participation font-weight-bold"></i> </Link>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="survey-main-image" style={this.props.isNormalImage === 'yes' ?
                        { "backgroundImage": "url(" +this.props.data + ")" }
                        : (this.props.planExpired === 1 ? { "backgroundImage": "none" } : { "backgroundImage": "url(" +this.props.data + ")" })
                        }>
                        </div>
                        <div className="text-center survey-cover-page-detail mt-4">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                <circle className="path-new circle-new" fill="none" stroke="#FFFFFF" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="60" />
                                <polyline className="path-new check-new" fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                            </svg>
                            <h1 className="text-white px-sm-0 px-5 font-weight-bold text-break">{SURVEY_PARTICIPATION[englishbuttonlabels]['endingpage']}</h1>
                            {this.state.survey_end_page_title && this.state.survey_end_page_description? 
                            <div className="survey-participate-detail">
                                <div className="survey-title-box">
                                    <h2 className="text-dark font-weight-bold mb-4">{this.state.survey_end_page_title}</h2>
                                    <p className='text-left font-14 mt-3' >{this.state.survey_end_page_description}</p>
                                </div>
                            </div>
                            : survey_data.account_type_category_id == 1 ?
                                endpageData.length === undefined ?
                                    <div className="survey-participate-detail">
                                        <div className="survey-title-box">
                                            <h2 className="text-dark font-weight-bold mb-4">
                                                {endpageData?.default_card_heading}
                                            </h2>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: endpageData?.default_card_text }}></div>
                                        <div className="survey-participate-btn">
                                            <a target="_blank" href={endpageData?.default_card_button_link} className='btn btn-clr text-white w-100'>{endpageData?.default_card_button_label}</a>
                                        </div>
                                    </div>
                                    :   
                                    <div className="survey-participate-detail"> 
                                    <div className="survey-title-box">
                                        <h2 className="text-dark font-weight-bold mb-4">
                                        { SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_title']}
                                        </h2>
                                    </div>
                                    <div class="text-gray text-survey d-flex text-left">
                                        <span class="fa fa-check survey-result-unlock-popup"></span>
                                        <div class="mb-0">
                                            {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_first_subtitle']}
                                        </div>
                                    </div>
                                    <div class="text-gray text-survey d-flex text-left">
                                        <span class="fa fa-check survey-result-unlock-popup"></span>
                                        <div class="mb-0">
                                            {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_second_subtitle']}
                                        </div>
                                    </div>
                                    <div class="text-gray text-survey d-flex text-left">
                                        <span class="fa fa-check survey-result-unlock-popup"></span>
                                        <div class="mb-0">
                                            {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_third_subtitle']}
                                        </div>
                                    </div>
                                    <div class="text-gray text-survey d-flex text-left">
                                        <span class="fa fa-check survey-result-unlock-popup"></span>
                                        <div class="mb-0">
                                            {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_fourth_subtitle']}
                                        </div>
                                    </div>
                                    <div className="survey-participate-btn">
                                        <Link to={{ pathname: "/registrieren", state: { earnKarmaPoint: this.state.totalearnkarmapoints, uType: this.state.annonymousUserId } }} className="btn btn-clr text-white w-100" >
                                            {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_link_text']}
                                        </Link>
                                    </div>
                                    </div>
                                :null
                                }
                        </div>
                    </div>
                </div>
            </div>
        </main>)
    }
}
export default SurveyEndingPageNsu;