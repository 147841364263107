import React from 'react';
class SurveyWelcomePage extends React.Component {
    render() {
        let data = this.props.data;
        let welcome_content_data = ""
        if (data.surveydata.surveyContent) {
            // eslint-disable-next-line
            welcome_content_data = data.surveydata.surveyContent.map((data, index) => {
                // if (data.content_text) {
                //     return (<div className="text-break"><p className="font-18 text-dark font-weight-normal" key={index} dangerouslySetInnerHTML={{ __html: data.content_text.replace(/<br\s*\/?>/mg, "").replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p></div>)
                // }
                if (data.content_text && data.is_header === 1) {
                    return (<div className="question-heading"><h3 className="text-dark font-weight-bold survey-title" key={index} dangerouslySetInnerHTML={{ __html: data.content_text.replace(/<br\s*\/?>/mg, "").replace( /(<([^>]+)>)/ig, '').replace(/(?:\r\n|\r|\n)/g, '<br>') }}></h3></div>)
                }
                else if (data.content_text && data.is_header === 0) {
                    return (<div className="text-break"><p className="font-18 text-dark font-weight-normal" key={index} dangerouslySetInnerHTML={{ __html: data.content_text.replace(/<br\s*\/?>/mg, "").replace( /(<([^>]+)>)/ig, '').replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p></div>)
                }
                else if (data.content_image) {
                    // eslint-disable-next-line
                    return (<div className="d-block pb-4 text-center"><img src={data.content_image} key={index}></img></div>)
                }
            })
        }
        return (
            <div className={"container survey-form custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")}>
                {welcome_content_data}
            </div>);
    }
}
export default SurveyWelcomePage;