import React from 'react';
import SurveyRatingWithMoreSchoolGradeQuestion from './SurveyRatingWithMoreSchoolGradeQuestion';
import SurveyRatingWithMoreStaticQuestion from './SurveyRatingWithMoreStaticQuestion';
import SurveyRatingWithMoreFreeTextQuestion from './SurveyRatingWithMoreFreeTextQuestion';
import SurveyRatingWithMoreProbabiltyQuestion from './SurveyRatingWithMoreProbabiltyQuestion';
import SurveyRatingWithMorePointsQuestion from './SurveyRatingWithMorePointsQuestion';
import SurveyRatingWithMoreThumbQuestion from './SurveyRatingWithMoreThumbQuestion';
class SurveyRatingWithMoreElements extends React.Component {
    section = (questiontype, data) => {
        var questioncomponent = questiontype;
        var questiondata = data
        switch (questioncomponent) {
            case 1:
                return < SurveyRatingWithMorePointsQuestion questiondata={questiondata} onChange={this.props.onChange} counter={this.props.counter} onButtonRating={this.props.onButtonRating} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} />
            case 2:
                return < SurveyRatingWithMoreSchoolGradeQuestion questiondata={questiondata} onChange={this.props.onChange} counter={this.props.counter} onSchoolGrade={this.props.onSchoolGrade} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} />
            case 3:
                return < SurveyRatingWithMoreThumbQuestion questiondata={questiondata} onChange={this.props.onChange} counter={this.props.counter} onButtonRating={this.props.onButtonRating} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} />
            case 4:
                return < SurveyRatingWithMoreProbabiltyQuestion questiondata={questiondata} onChange={this.props.onChange} counter={this.props.counter} onLoad={this.props.onLoad} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} />
            case 5:
                return < SurveyRatingWithMoreFreeTextQuestion questiondata={questiondata} onChange={this.props.onChange} counter={this.props.counter} onSchoolGrade={this.props.onSchoolGrade} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} />
            case 6:
                return < SurveyRatingWithMoreStaticQuestion questiondata={questiondata} onChange={this.props.onChange} counter={this.props.counter} onSchoolGrade={this.props.onSchoolGrade} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} />
            default:
        }
    }
    render() {
        let data = this.props.questiondata;
        let questiontype = '';
        if (data) {
            questiontype = data.scale_type;
        }
        const element = this.section(questiontype, data)
        return (
            <div className="">{element}</div>
        )
    }
}
export default SurveyRatingWithMoreElements;