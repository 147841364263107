import React, { Fragment } from 'react';
import { SURVEY_CREATION_CONSTANT } from "../../../../constants/FrontEndConstant";
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
class SurveyTitle extends React.Component {
    section = (value, method) => {
        return (
            <div className="hs-form-field">
                <h2 className="text-dark h3 font-weight-bold survey-title">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_title_page_question']}</h2>
                <h3 className="font-14 lh-24 fw-400 survey-subtitle">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_title_page_subtitle']}</h3>
                <div className="form-group continue-btn survey-form-detail">
                    <label htmlFor="survey_title" className="sr-only">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_title_page_IPH']}</label>
                    <input type="text" name="survey_title" className={(this.props.validationMessage) ? "form-control border-bottom-red" : "form-control"} value={value.survey_title || undefined || ''} onChange={method} maxlength={this.props.titleLengthAllowed} placeholder={SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_title_page_IPH']} />
                    <span className="title-counter float-right">{ value.survey_title ? ( value.survey_title.length > 100 ? 0 : this.props.titleLengthAllowed - value.survey_title.length) : this.props.titleLengthLeft}/{this.props.titleLengthAllowed}</span>
                    <div id="errorSurveyMessage" className="error-text mt-2">{(this.props.validationMessage) ? <p>{this.props.validationMessage}</p> : null}</div>
                </div>
            </div>
        );
    }
    render() {
        let value = this.props.value;
        let method = this.props.onChange;
        const elements = <div className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1"> {this.section(value, method)} </div>;
        return (
            <Fragment>
                {elements}
            </Fragment>
        );
    }
}
export default SurveyTitle;