import React from 'react';
import { Redirect } from "react-router-dom";
class SubPageHeader extends React.Component {
    componentDidMount() {
        this.scrollUpFunction();
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (document.querySelector('.navbar-toggler')) {
            let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
            if (count === -1) {
                document.querySelector('.navbar-collapse').classList.remove('show');
                document.querySelector('header').classList.remove("header_collapse");
                document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
            }
        }
    }
    render() {
        // eslint-disable-next-line 
        let data = this.props.data;
        return (
            <section className="banner_text theme-main d-flex align-items-center">
                {(this.props.dataLength === 0) ?
                    <Redirect to="/404" />
                    : null
                }
                <div className="container">
                    <div className="page-title">
                        <h1 className="text-white font-weight-bold">{this.props.title}</h1>
                        <p className="text-white text-center mb-0 font-18 line-height">{this.props.sub_title}</p>
                    </div>
                </div>
            </section>
        );
    }
}
export default SubPageHeader; 