import React from 'react';
import axios from "axios";
import { BUBBLE_LOADING, META_FACEBOOK, BREADCRUMB_SCHEMA } from '../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, META_TITLE_APPEND } from '../../constants/DotEnv';
import DatenschutzHeader from '../subPageHeader/SubPageHeader';
class Datenschutz extends React.Component {
    constructor() {
        super();
        this.state = {
            datneschutzdata: {},
            loading: 'false',
            dataLength: '',
            title: ''
        }
    }
    componentWillUnmount() {
        setTimeout(() => {
            window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
            window.removeSchema();
        }, 100)
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.datneschutzcontent();
        setTimeout(() => {
            var schema2 = window.positionCreate(BREADCRUMB_SCHEMA['datenschutz']['schema2']['position'], BREADCRUMB_SCHEMA['datenschutz']['schema2']['url'], BREADCRUMB_SCHEMA['datenschutz']['schema2']['name']);
            var schemaObject = window.schemaObj(BREADCRUMB_SCHEMA['schema1'], schema2, BREADCRUMB_SCHEMA['datenschutz']['schema3']);
            window.setSchema(schemaObject);
        }, 100)
    }
    optoutGOptimizeDisabled() {
        if (document.getElementById("optoutGOptimize") && localStorage.getItem('optout_GOptimize')) {
            document.getElementById("optoutGOptimize").innerHTML = "";
            var node = document.createElement("span");
            var textnode = document.createTextNode("Google Optimize ist deaktiviert. ");
            node.appendChild(textnode);
            node.setAttribute("class", "text-dark font-18 font-weight-noraml");
            document.getElementById("optoutGOptimize").appendChild(node);
            var nodeButton = document.createElement("a");
            nodeButton.setAttribute("class", "font-18");
            // eslint-disable-next-line
            nodeButton.setAttribute("href", 'javascript:void(0);');
            var textnodeButton = document.createTextNode("Jetzt aktivieren");
            var space = document.createElement("br");
            nodeButton.addEventListener('click', () => this.deleteOptOutGOpt());
            nodeButton.append(textnodeButton);
            document.getElementById("optoutGOptimize").appendChild(nodeButton);
            document.getElementById("optoutGOptimize").after(space);
            //document.write('<span> Google Optimize ist deaktiviert. </span><button onclick="setOptOutGOpt()">Jetzt aktivieren</button>');
        }
    }
    optoutGOptimizeEnabled() {
        if (document.getElementById("optoutGOptimize") && !localStorage.getItem('optout_GOptimize')) {
            document.getElementById("optoutGOptimize").innerHTML = "";
            var node = document.createElement("span");
            var textnode = document.createTextNode("Google Optimize ist aktiviert. ");
            node.appendChild(textnode);
            node.setAttribute("class", "text-dark font-18 font-weight-noraml");
            document.getElementById("optoutGOptimize").appendChild(node);
            var nodeButton = document.createElement("a");
            nodeButton.setAttribute("class", "font-18");
            // eslint-disable-next-line
            nodeButton.setAttribute("href", 'javascript:void(0);');
            var textnodeButton = document.createTextNode("Jetzt deaktivieren");
            var space = document.createElement("br");
            nodeButton.addEventListener('click', () => this.setOptOutGOpt());
            nodeButton.append(textnodeButton);
            document.getElementById("optoutGOptimize").appendChild(nodeButton);
            document.getElementById("optoutGOptimize").after(space);
            //document.write('<span> Google Optimize ist aktiviert. </span><button onclick="setOptOutGOpt()">Jetzt deaktivieren</button>');
        }
    }
    setOptOutGOpt() {
        localStorage.setItem('optout_GOptimize', 'true');
        window.location.reload(false);
    }
    deleteOptOutGOpt() {
        localStorage.removeItem('optout_GOptimize');
        window.location.reload(false);
    }
    FacebookPixelTrackingenabled() {
        if (document.getElementById("facebookscript") && !localStorage.getItem('optout_facebook')) {
            document.getElementById("facebookscript").innerHTML = "";
            var node = document.createElement("span");
            var textnode = document.createTextNode("Facebook Pixel Tracking ist aktiviert. ");
            node.appendChild(textnode);
            node.setAttribute("class", "text-dark font-18 font-weight-noraml");
            document.getElementById("facebookscript").appendChild(node);
            var nodeButton = document.createElement("a");
            nodeButton.setAttribute("class", "font-18");
            // eslint-disable-next-line
            nodeButton.setAttribute("href", 'javascript:void(0);');
            var textnodeButton = document.createTextNode("Jetzt deaktivieren");
            var space = document.createElement("br");
            nodeButton.addEventListener('click', () => this.setOptOutFacebook());
            nodeButton.append(textnodeButton);
            document.getElementById("facebookscript").appendChild(nodeButton);
            document.getElementById("facebookscript").after(space);
        }
    }
    FacebookPixelTrackingdisabled() {
        if (document.getElementById("facebookscript") && localStorage.getItem('optout_facebook')) {
            document.getElementById("facebookscript").innerHTML = "";
            var node = document.createElement("span");
            var textnode = document.createTextNode("Facebook Pixel Tracking ist deaktiviert. ");
            node.appendChild(textnode);
            node.setAttribute("class", "text-dark font-18 font-weight-noraml");
            document.getElementById("facebookscript").appendChild(node);
            var nodeButton = document.createElement("a");
            nodeButton.setAttribute("class", "font-18");
            // eslint-disable-next-line
            nodeButton.setAttribute("href", 'javascript:void(0);');
            var textnodeButton = document.createTextNode("Jetzt aktivieren");
            var space = document.createElement("br");
            nodeButton.addEventListener('click', () => this.deleteOptOutFacebook());
            nodeButton.append(textnodeButton);
            document.getElementById("facebookscript").appendChild(nodeButton);
            document.getElementById("facebookscript").after(space);
        }
    }
    setOptOutFacebook() {
        localStorage.setItem('optout_facebook', 'true');
        window.location.reload(false);
    }
    deleteOptOutFacebook() {
        localStorage.removeItem('optout_facebook');
        window.location.reload(false);
    }
    GoogleAnalyticsdisabled() {
        if (document.getElementById("googlescript") && localStorage.getItem('optout_GA')) {
            document.getElementById("googlescript").innerHTML = "";
            var node = document.createElement("span");
            var textnode = document.createTextNode("Google Analytics ist deaktiviert. ");
            node.appendChild(textnode);
            node.setAttribute("class", "text-dark font-18 font-weight-noraml");
            document.getElementById("googlescript").appendChild(node);
            var nodeButton = document.createElement("a");
            nodeButton.setAttribute("class", "font-18");
            // eslint-disable-next-line
            nodeButton.setAttribute("href", 'javascript:void(0);');
            var textnodeButton = document.createTextNode("Jetzt aktivieren");
            var space = document.createElement("br");
            nodeButton.addEventListener('click', () => this.deleteOptOutGA());
            nodeButton.append(textnodeButton);
            document.getElementById("googlescript").appendChild(nodeButton);
            document.getElementById("googlescript").after(space);
        }
    }
    GoogleAnalyticsenabled() {
        if (document.getElementById("googlescript") && !localStorage.getItem('optout_GA')) {
            document.getElementById("googlescript").innerHTML = "";
            var node = document.createElement("span");
            var textnode = document.createTextNode("Google Analytics ist aktiviert. ");
            node.appendChild(textnode);
            node.setAttribute("class", "text-dark font-18 font-weight-noraml");
            document.getElementById("googlescript").appendChild(node);
            var nodeButton = document.createElement("a");
            nodeButton.setAttribute("class", "font-18");
            // eslint-disable-next-line
            nodeButton.setAttribute("href", 'javascript:void(0);');
            var textnodeButton = document.createTextNode("Jetzt deaktivieren");
            var space = document.createElement("br");
            nodeButton.addEventListener('click', () => this.setOptOutGA());
            nodeButton.append(textnodeButton);
            document.getElementById("googlescript").appendChild(nodeButton);
            document.getElementById("googlescript").after(space);
        }
    }
    DataProtectionGuidelineDisabled() {
        if (document.getElementById("mouseflowscript") && localStorage.getItem('optout_mouseflow')) {
            document.getElementById("mouseflowscript").innerHTML = "";
            var node = document.createElement("span");
            var textnode = document.createTextNode("Mouseflow ist deaktiviert. ");
            node.appendChild(textnode);
            node.setAttribute("class", "text-dark font-18 font-weight-noraml");
            document.getElementById("mouseflowscript").appendChild(node);
            var nodeButton = document.createElement("a");
            nodeButton.setAttribute("class", "font-18");
            // eslint-disable-next-line
            nodeButton.setAttribute("href", 'javascript:void(0);');
            var textnodeButton = document.createTextNode("Jetzt aktivieren");
            var space = document.createElement("br");
            nodeButton.addEventListener('click', () => this.deleteOptOutMouseflow());
            nodeButton.append(textnodeButton);
            document.getElementById("mouseflowscript").appendChild(nodeButton);
            document.getElementById("mouseflowscript").after(space);
        }
    }
    DataProtectionGuidelineEnabled(){
        if (document.getElementById("mouseflowscript") && !localStorage.getItem('optout_mouseflow')) {
            document.getElementById("mouseflowscript").innerHTML = "";
            var node = document.createElement("span");
            var textnode = document.createTextNode("Mouseflow ist aktiviert. ");
            node.appendChild(textnode);
            node.setAttribute("class", "text-dark font-18 font-weight-noraml");
            document.getElementById("mouseflowscript").appendChild(node);
            var nodeButton = document.createElement("a");
            nodeButton.setAttribute("class", "font-18");
            // eslint-disable-next-line
            nodeButton.setAttribute("href", 'javascript:void(0);');
            var textnodeButton = document.createTextNode("Jetzt deaktivieren");
            var space = document.createElement("br");
            nodeButton.addEventListener('click', () => this.setOptOutMouseflow());
            nodeButton.append(textnodeButton);
            document.getElementById("mouseflowscript").appendChild(nodeButton);
            document.getElementById("mouseflowscript").after(space);
        }
    }
    setOptOutMouseflow() { localStorage.setItem('optout_mouseflow', 'true'); window.location.reload(false); }
    deleteOptOutMouseflow() { localStorage.removeItem('optout_mouseflow'); window.location.reload(false); }
    setOptOutGA() { localStorage.setItem('optout_GA', 'true'); window.location.reload(false); }
    deleteOptOutGA() { localStorage.removeItem('optout_GA'); window.location.reload(false); }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
        if (count === -1) {
            document.querySelector('.navbar-collapse').classList.remove('show');
            document.querySelector('header').classList.remove("header_collapse");
            document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
        }
    }
    datneschutzcontent = () => {
        this.setState({ loading: 'true' })
        let self = this;
        let datneschutzdata = '';
        let dataLength = '';
        let title = '';
        let meta_title = '';
        let meta_description = '';
        let meta_keywords = '';
        let og_title = '';
        let og_description = '';
        let og_image = '';
        axios.get(API_URL + '/cms/getCmsByID/89/' + LANGUAGE_CODE)
            .then((response) => {
                datneschutzdata = response.data.success.cms;
                dataLength = Object.keys(datneschutzdata).length;
                title = datneschutzdata['title'];
                meta_title = datneschutzdata['title'] + META_TITLE_APPEND
                meta_description = datneschutzdata['metadescription']
                meta_keywords = datneschutzdata['metakeyword']
                og_title = datneschutzdata['title'] + META_TITLE_APPEND;
                og_description = datneschutzdata['metadescription'];
                og_image = META_FACEBOOK['og_image'];
                window.setMeta({ "description": meta_description, "keywords": meta_keywords });
                window.setDocumentTitle(meta_title)
                window.setOgTag(og_title, og_description, og_image, window.location.href)
                self.setState({ loading: 'false', datneschutzdata, dataLength, title })
                this.optoutGOptimizeEnabled();
                this.optoutGOptimizeDisabled();
                this.FacebookPixelTrackingenabled();
                this.FacebookPixelTrackingdisabled();
                this.GoogleAnalyticsdisabled();
                this.GoogleAnalyticsenabled();
                this.DataProtectionGuidelineDisabled();
                this.DataProtectionGuidelineEnabled();
            })
            .catch((error) => {
                self.setState({ loading: 'false' })
            })
    }
    render() {
        let length = Object.keys(this.state.datneschutzdata).length
        let datneschutzdata = this.state.datneschutzdata;
        return (
            <main>
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                <DatenschutzHeader data={datneschutzdata} title={this.state.title} dataLength={this.state.dataLength} />
                {(length > 0 && this.state.loading === 'false') ?
                    <section className="section-blog">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <div className="blog-lising">
                                        <div dangerouslySetInnerHTML={{ __html: datneschutzdata['content'] }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    : null
                }
            </main>
        );
    }
}
export default Datenschutz;