import { ADD_USERDATA } from "../constants/ActionTypes";
import { REMOVE_USER } from "../constants/ActionTypes";
const initialState = {
  userdata: [],
};
function userData(state = initialState, action) {
  if (action.type === ADD_USERDATA) {
    return Object.assign({}, state, {
      userdata: state.userdata.concat(action.payload)
    });
  }
  if (action.type === REMOVE_USER) {
    window.localStorage.removeItem('userdata');
    const indexOfUserToDelete = state.userdata.findIndex(userdata => {
      return userdata.token === action.payload.token;
    });
    return Object.assign({}, state, {
      userdata: state.userdata.filter(userdata => userdata !== state.userdata[indexOfUserToDelete])
    })
  }
  return state;
}
export { userData };