import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { STATUS_DROPDOWN, DURATION_DROPDOWN, SURVEY_LIST_CONSTANT, BUBBLE_LOADING ,URL_CONSTANT} from '../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, LIMIT, OFFSET, IMAGE_BASEURL } from '../../../constants/DotEnv';
import Autocomplete from 'react-autocomplete';
class SurveyListContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownoption: '',
            university: '',
            filterdata: [],
            count: 0,
            loading: 'false',
            offset: '',
            checkLoader: 'false',
            surveycount: '',
            spinner: "false",
            cmsData: null,
            toggle_button : false,
            slidBarData : false,
            sidebarDataArrived:false
        }
    }
    componentDidMount() {
        this.getSidebarDataOfMonthlyKarmaRanking();
        this.getCmsData();
        this.scrollUpFunction();
        this.getDropdownData();
        this.getSurveyResult();
        let autosuggestionData = document.getElementsByClassName("form-autocomplete-suggestion-field");
        if (autosuggestionData.length) {
            for (let i = 0; i < autosuggestionData.length; i++) {
                document.getElementsByClassName("form-autocomplete-suggestion-field")[i].childNodes[1].remove();
            }
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let autosuggestionData = document.getElementsByClassName("form-autocomplete-suggestion-field");
        if (autosuggestionData.length) {
            for (let i = 0; i < autosuggestionData.length; i++) {
                if (document.getElementsByClassName("form-autocomplete-suggestion-field")[i].childNodes[1]) {
                    document.getElementsByClassName("form-autocomplete-suggestion-field")[i].childNodes[1].remove();
                }
            }
        }
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (document.querySelector('.navbar-toggler')) {
            let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
            if (count === -1) {
                document.querySelector('.navbar-collapse').classList.remove('show');
                document.querySelector('header').classList.remove("header_collapse");
                document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
            }
        }
    }
    /**API call for getting dropdown list data to filter survey */
    getDropdownData = async () => {
        let dropdownoption = null;
        let self = this;
        // dropdownoption = JSON.parse(localStorage.getItem("surveylistdropdown"));
        if (dropdownoption === null) {
            await axios.get(API_URL + '/commonApi/getAllSurveydp/' + LANGUAGE_CODE)
                .then((response) => {
                    dropdownoption = response.data.success;
                    localStorage.setItem("surveylistdropdown", JSON.stringify(dropdownoption));
                    self.setState({ dropdownoption })
                })
                .catch((error) => {
                })
        }
        else {
            dropdownoption = JSON.parse(localStorage.getItem("surveylistdropdown"))
            self.setState({ dropdownoption })
        }
        let filteritem = JSON.parse(localStorage.getItem('filter'));
        if (filteritem) {
            let id = ""
            var universityoption = dropdownoption.university;
            // eslint-disable-next-line
            universityoption.map((data) => {
                if (data.id === filteritem[0]['university']) {
                    id = data.university_name
                }
            })
            this.setState({ category: filteritem[0]['category'], duration: filteritem[0]['duration'], status: filteritem[0]['status'], reward: filteritem[0]['reward'], university: id });
        }
    }
    // Get Sidebar Data Of Monthly KarmaRanking
    getSidebarDataOfMonthlyKarmaRanking = async () => {
        let sidebar_data = null
        var user_details = JSON.parse(localStorage.getItem('userdata'));
        if(user_details == null){
            await axios.get(API_URL + '/commonApi/GetSidebarDataOfMonthlyKarmaRanking/' + LANGUAGE_CODE)
            .then((response) => {
                sidebar_data = response.data.success
                localStorage.setItem("MonthlyKarmaRanking", JSON.stringify(sidebar_data));
                this.setState({slidBarData : true,sidebarDataArrived:true})
            })
            .catch((error) => {
            })
        }else{
            let user_id = user_details.id
            await axios.get(API_URL + '/commonApi/GetSidebarDataOfMonthlyKarmaRanking/' + LANGUAGE_CODE + '?user_id=' + user_id)
            .then((response) => {
                sidebar_data = response.data.success
                localStorage.setItem("MonthlyKarmaRanking", JSON.stringify(sidebar_data));
                this.setState({slidBarData : true,sidebarDataArrived:true})
            })
            .catch((error) => {
            })
        }
    }
    getCmsData = async () =>{
        let cmsData = null
        await axios.get(API_URL + '/cms/getCmsByID/95/' + LANGUAGE_CODE)
            .then((response) => {
            cmsData = response.data.success.cms
            this.setState({cmsData: cmsData})
            localStorage.setItem("cmsData", JSON.stringify(cmsData));
            })
            .catch((error) => {
            })
    }
    getSurveyResult = async () => {
        var user_details = JSON.parse(localStorage.getItem('userdata'));
        if(user_details !== null ){
        let token = user_details.token
        let user_id = user_details.id
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
            await axios.get(API_URL + "/surveyresult/checkParticipatedInMySurvey/" + LANGUAGE_CODE + '?uId=' + user_id ,{ headers: headers })
                .then((response) => {
                    this.setState({toggle_button: response.data.success.checkParticipatedInMySurvey})
                })
                .catch((error) => {
                })
        }
    }
    loadMore = () => {
        this.setState({ loading: 'true', spinner: "true", surveycount: "" })
        var duration = document.getElementById('duration').value;
        var reward = document.getElementById('reward').value;
        var status = document.getElementById('status').value;
        var category = document.getElementById('category').value;
        var university = document.getElementById('university').value
        var universityoption = this.state.dropdownoption.university;
        let id = 0;
        universityoption.map((data) => {
            if (data.university_name === university) {
                id = data.id
            }
            return 0;
        })
        let offset = (this.state.offset) ? this.state.offset : OFFSET;
        offset = parseInt(offset) + parseInt(12);
        this.setState({ offset })
        let newdata = '';
        let olddata = [];
        let surveycount = '';
        if (this.state.filterdata.length > 0) {
            olddata = this.state.filterdata;
        }
        else {
            olddata = this.props.data;
        }
        let filterdata = '';
        let self = this;
        let userId = '';
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        if(storeitem !== null) {
            userId = storeitem.id;
        }
        setTimeout(async () => {
            await axios.get(API_URL + '/commonApi/getSurveyData/' + LANGUAGE_CODE + '?limit=' + LIMIT + '&offset=' + offset + '&category=' + category + '&university=' + id + '&duration=' + duration + '&reward=' + reward + '&status=' + status + '&userId=' + userId)
                .then((response) => {
                    newdata = response.data.success.surveydata;
                    filterdata = olddata.concat(newdata)
                    surveycount = response.data.success.surveycount
                    /**removed checkloader for not showing loading */
                    self.setState({ loading: 'false', spinner: "false", filterdata, surveycount })
                })
                .catch((error) => {
                    self.setState({ loading: 'false', spinner: "false" })
                })
        }, 500)
    }
    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }
    changeFilter = async (event) => {
        this.setState({ count: 0, loading: 'true', surveycount: '' })
        let filterdata = [];
        var universityoption = this.state.dropdownoption.university;
        let id = 0;
        let length = '';
        if (event.type) {
            if (event.type === 'click') {
                document.getElementById(event.target.id).value = "";
                if (event.target.id === 'university') {
                    this.setState({ university: "" })
                }
            }
        }
        var duration = document.getElementById('duration').value;
        var reward = document.getElementById('reward').value;
        var status = document.getElementById('status').value;
        var category = document.getElementById('category').value;
        var university = document.getElementById('university').value;
        let checkLoader = '';
        let surveycount = '';
        if (event.target !== undefined) {
            var name = event.target.name;
            var value = event.target.value;
            if (name === 'university') {
                checkLoader = 'false';
                if (value === '') {
                    checkLoader = 'true';
                }
            }
            else {
                checkLoader = 'true';
            }
            this.setState({ [name]: value, checkLoader })
        }
        else {
            checkLoader = "true";
            if (event.type) {
                if (event.type === 'click') {
                    if (event.target.id === 'university') {
                        this.setState({ university: "" })
                        university = ""
                    }
                }
            }
            else {
                this.setState({ university: event, checkLoader })
                university = event
            }
        }
        universityoption.map((data) => {
            if (data.university_name === university) {
                id = data.id
                checkLoader = "true";
                this.setState({ checkLoader: 'true' })
            }
            return 0;
        })
        let filter = [];
        filter.push({ "duration": duration, "reward": reward, "status": status, "category": category, "university": id })
        this.setState({ "duration": duration, "reward": reward, "status": status, "category": category })
        localStorage.setItem("filter", JSON.stringify(filter));
        let self = this;
        let userId = '';
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        if(storeitem !== null) {
            userId = storeitem.id;
        }
        if (checkLoader === 'true' ) {
            let toggle =  localStorage.getItem("toggle")
            var isParticipatedInMySurvey =  toggle === 'true'? '&IsParticipateInMySurvey=1' : ''
            await axios.get(API_URL + '/commonApi/getSurveyData/' + LANGUAGE_CODE + '?limit=' + LIMIT + '&offset=' + OFFSET + '&category=' + category + '&university=' + id + '&duration=' + duration + '&reward=' + reward + '&status=' + status + '&userId=' + userId + isParticipatedInMySurvey)
                .then((response) => {
                    filterdata = response.data.success.surveydata
                    surveycount = response.data.success.surveycount
                    length = filterdata.length;
                    if (length === 0) {
                        self.setState({ count: 1 })
                    }
                    self.setState({ filterdata, surveycount, loading: 'false', checkLoader: 'false' })
                })
                .catch((error) => {
                    self.setState({ loading: 'false', checkLoader: 'false' })
                })
            this.setState({ offset: 0 })
        }
    }
    _handleClick = (e) => {
        var id = e.target.id
        var element = document.getElementById(id);
        element.classList.toggle("active");
        var panel = document.getElementById(id).nextSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
        e.preventDefault();
    }
    handleChangeToggle = async (event)  => {
        localStorage.setItem("toggle", event.target.checked )
        }
    render() {
        var MonthlyKarmaRankingdata =  JSON.parse(localStorage.getItem("MonthlyKarmaRanking")) || [];
        var show_data = ''
        if(MonthlyKarmaRankingdata == null){
            show_data = false
        }else if(MonthlyKarmaRankingdata.length === 0){
            show_data = false
        }else{
            show_data = true
        }
        var sideBar = this.state.slidBarData ;
        var sidebarDataArrived = this.state.sidebarDataArrived ;
        var cmsData = this.state.cmsData ? this.state.cmsData : '';
        var filterElements = JSON.parse(localStorage.getItem("filter"));
        /**for identifying chrome and safari browser */
        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        var issafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        var duration, reward, status, category, university;
        if (document.getElementById('duration')) {
            duration = document.getElementById('duration').value;
        }
        if (document.getElementById('reward')) {
            reward = document.getElementById('reward').value;
        }
        if (document.getElementById('status')) {
            status = document.getElementById('status').value;
        }
        if (document.getElementById('category')) {
            category = document.getElementById('category').value;
        }
        if (document.getElementById('university')) {
            if (filterElements) {
                university = filterElements[0]['university'];
            } else {
                university = "";
            }
        }
        let autosuggestionData = document.getElementsByClassName("form-autocomplete-suggestion-field");
        if (autosuggestionData.length) {
            for (let i = 0; i < autosuggestionData.length; i++) {
                if (document.getElementsByClassName("form-autocomplete-suggestion-field")[i].childNodes[1]) {
                    document.getElementsByClassName("form-autocomplete-suggestion-field")[i].childNodes[1].remove();
                }
            }
        }
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let userid = '';
        if (storeitem !== null) {
            userid = storeitem.id;
        }
        let filterdatalength = this.state.filterdata.length;
        let length = this.state.dropdownoption.length;
        let optiondata = '';
        let universityOption = '';
        let categoryOption = '';
        let rewardOption = '';
        let statusoption = '';
        let durationoption = '';
        let surveycount = '';
        let count = this.state.count;
        if (length !== 0) {
            optiondata = this.state.dropdownoption;
        }
        if (optiondata !== '') {
            universityOption = optiondata.university;
            const category = optiondata.surveycategory;
            const reward = optiondata.reward;
            categoryOption = category.map((category, index) => {
                return (<option value={category.id} key={index}>{category.category_name}</option>)
            })
            rewardOption = reward.map((reward, index) => {
                return (<option value={reward.id} key={index}>{reward.reward}</option>)
            })
            const duration = DURATION_DROPDOWN[LANGUAGE_CODE];
            const status = STATUS_DROPDOWN[LANGUAGE_CODE];
            statusoption = status.map((status, index) => {
                return (<option value={status.id} key={index}>{status.status_name}</option>)
            })
            durationoption = duration.map((duration, index) => {
                return (<option value={duration.id} key={index}>{duration.duration}</option>)
            })
        }
        let component = [];
        let data = '';
        if (filterdatalength > 0) {
            data = this.state.filterdata;
            surveycount = this.state.surveycount
        }
        else {
            data = this.props.data;
            if (data.length > 0) {
                count = 1
            }
            surveycount = (this.state.surveycount !== "") ? this.state.surveycount : this.props.surveycount;
        }
        if ((duration || status || category || reward || university) && filterdatalength === 0 && count !== 1) {
            component.push(<div className="col-md-12 col-lg-8 col-xl-4"><p style={{ "fontSize": "24px" }}>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['norecordsfoundmsg']}</p></div>)
        }
        if (this.state.count === 1) {
            component.push(<div className="col-md-12 col-lg-8 col-xl-4"><p style={{ "fontSize": "24px" }}>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['norecordsfoundmsg']}</p></div>)
        }
        var elements = data.map((data, index) => {
            let startdate = data['start_date'].replace(/-/g, '/');
            let enddate = data['end_date'].replace(/-/g, '/');
            let currentdate = new Date();
            enddate = new Date(enddate);
            startdate = new Date(startdate);
            var timeDiff = enddate.getTime() - currentdate.getTime();
            var daysleft = Math.ceil(parseInt(timeDiff) / (1000 * 3600 * 24));
            daysleft = daysleft + 1;
            let newDate = new Date();
            var changeCurrentDate = new Date(newDate.setHours(0, 0, 0, 0));
            if (Date.parse(startdate) <= Date.parse(changeCurrentDate) && Date.parse(changeCurrentDate) <= Date.parse(enddate)) {
                let timediff = Math.abs(enddate.getTime() - startdate.getTime());
                let diffday2 = Math.ceil(timediff / (1000 * 3600 * 24));
                let totaldate = Math.abs(changeCurrentDate.getTime() - startdate.getTime());
                let diffday1 = Math.ceil(totaldate / (1000 * 3600 * 24));
                let cal = diffday1 / (diffday2 + 1) * 100;
                var currentwidth = cal.toFixed('2');
            }
            else if (startdate > currentdate) {
                currentwidth = '0';
            }
            else {
                currentwidth = '100';
            }
            currentwidth = parseInt(currentwidth);
            let title = data['survey_title_slug'];
            let isparticipated = data['isparticipate'];
            let participatemessage = '';
            let shouldParticipateMsg = '';
            // if (!userid) {
            //     if (data.id) {
            //         let check = false;
            //         check = this.getCookie(data.id)
            //         if (check) {
            //             isparticipated = '1';
            //         }
            //     }
            // }
            if (isparticipated === '1') {
                participatemessage = SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['alredy_participate'];
            }
            else {
                if(data.IsParticipateInMySurvey) {
                    shouldParticipateMsg = SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['should_participate'];
                }
            }
            let titleNew;
            if (data['survey_title']) {
                if (window.innerWidth >= 1200 && window.innerWidth < 1500) {
                    if (data['survey_title'].length > 57) {
                        titleNew = data['survey_title'].substring(0, 57).trim() + "...";
                    } else {
                        titleNew = data['survey_title']
                    }
                }
                else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
                    if (data['survey_title'].length > 70) {
                        titleNew = data['survey_title'].substring(0, 70).trim() + "...";
                    } else {
                        titleNew = data['survey_title']
                    }
                }
                else if (window.innerWidth >= 768 && window.innerWidth < 992) {
                    if (data['survey_title'].length > 76) {
                        titleNew = data['survey_title'].substring(0, 76).trim() + "...";
                    } else {
                        titleNew = data['survey_title']
                    }
                }
                else if (window.innerWidth >= 576 && window.innerWidth < 768) {
                    if (data['survey_title'].length > 152) {
                        titleNew = data['survey_title'].substring(0, 152).trim() + "...";
                    } else {
                        titleNew = data['survey_title']
                    }
                }
                else if (window.innerWidth >= 414 && window.innerWidth < 576) {
                    if (data['survey_title'].length > 105) {
                        titleNew = data['survey_title'].substring(0, 105).trim() + "...";
                    } else {
                        titleNew = data['survey_title']
                    }
                }
                else if (window.innerWidth >= 320 && window.innerWidth < 414) {
                    if (data['survey_title'].length > 70) {
                        titleNew = data['survey_title'].substring(0, 70).trim() + "...";
                    } else {
                        titleNew = data['survey_title']
                    }
                }
                else if (window.innerWidth < 320) {
                    if (data['survey_title'].length > 65) {
                        titleNew = data['survey_title'].substring(0, 65).trim() + "...";
                    } else {
                        titleNew = data['survey_title']
                    }
                }
                else {
                    if (data['survey_title'].length > 78) {
                        titleNew = data['survey_title'].substring(0, 78).trim() + "...";
                    } else {
                        titleNew = data['survey_title'];
                    }
                }
            }
            let backImage = IMAGE_BASEURL + "img/100x100.jpg"
            if (data['thumb_image']) {
                backImage = data['thumb_image'];
            }
            return (<div className="col-md-6 col-lg-6 col-xl-4" key={index}>
                <div className="blog-item">
                    <Link to={"/umfragen/" + title}>
                             <div className="blog-img position-relative h-220 background-image-css" style={{ "backgroundImage": "url(" + backImage + ")" }}></div>
                        <div className="blog-content">
                            <h6 className={(isChrome || issafari) ? "text-dark font-16 custom-test-text" : "text-dark font-16 text-hyphens"}>{(titleNew) ? (issafari || isChrome) ? data['survey_title'] : titleNew : null}</h6>
                        </div>
                        {(data['userimage']) ? <div className="user_profile_photo"> <div className="login-profile profile_pic">
                            <img src={data['userimage']} className="rounded-circle center" alt="person-img" width="70" height="70" />
                        </div></div>
                            : null}
                        {(isparticipated === '1') ?
                            <div className="progress custom-main-progress">
                                <span className="progress-value custom-progress">{participatemessage}</span>
                                <div className="progress-bar progress-bar-striped" role="progressbar" style={{ "ariaValuenow": "10", "ariaValuemin": "0", "ariaValuemax": "100" }}></div>
                            </div>
                            : (shouldParticipateMsg) ?
                                <div className="progress custom-main-progress">
                                    <span className="progress-value custom-progress">{shouldParticipateMsg}</span>
                                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ "ariaValuenow": "10", "ariaValuemin": "0", "ariaValuemax": "100" }}></div>
                                </div>
                                :<div className="progress">
                                    <span className="progress-value">{participatemessage}</span>
                                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ "width": currentwidth + "%", "ariaValuenow": "10", "ariaValuemin": "0", "ariaValuemax": "100" }}></div>
                                </div>
                        }
                        <div className="blog-content d-flex justify-content-around">
                            <ul className="list-unstyled mb-2 mr-4">
                                <li className="text-gray mb-2 d-flex"><i className="ti-star mr-3 font-18"></i><div className="blog__card-title d-block"><span className="d-block ">{data['karmapoint']}</span> {SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['karmapoint']}</div></li>
                                <li className="text-gray"><i className="ti-user mr-3 font-18"></i><div className="blog__card-title d-block"><span className="d-block">{data['totaluser']}</span> {SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['totaluser']}</div></li>
                            </ul>
                            <ul className="list-unstyled mb-2">
                                <li className="text-gray mb-2 d-flex"><i className="ti-time mr-3 font-18"></i><div className="blog__card-title d-block"><span className="d-block">{data['duration']}</span> {SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['duration']}</div></li>
                                <li className="text-gray d-flex"><i className="ti-tag mr-3 font-18"></i><div className="blog__card-title d-block"><span className="d-block">{daysleft}</span>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['daysleft']}</div></li>
                            </ul>
                        </div>
                    </Link>
                </div>
            </div>)
        })
        return (
            // <!-- custom-select -->
            <div>
                {(this.props.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {(this.state.loading === 'true' && this.state.checkLoader === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                { (window.innerWidth >= 320 && window.innerWidth < 768) ?
                    <div className="container container-large mt-4">
                        <div className="accordion-02">
                            <div className="acco-group mb-0" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                                {/* eslint-disable-next-line*/ }
                                <a href="#" className="acco-heading text-dark font-weight-bold font-18 font_16 acco-heading-plus" itemProp="name" id="filter" onClick={this._handleClick}>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['filter']}</a>
                                <div className="panel" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                    <section className="custom-select-survey custom-xs-survey bg-white pt-4">
                                        <div className="container container-large">
                                            <div className="row">
                                                <div className="col-12 col-md">
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <label className="input-group-text sr-only" htmlFor="inputGroupSelect01"></label>
                                                        </div>
                                                        <select className="custom-select" id="category" name="category" onChange={this.changeFilter} value={this.state.category}>
                                                            <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['category']}</option>
                                                            {categoryOption}
                                                        </select>
                                                        <div className={(category) ? "input-group-append input_close_btn" : "d-none"}>
                                                            <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="category" value=""></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md">
                                                    <div className="input-group custom-autocomplete-filter mb-3">
                                                        <div className="input-group-prepend">
                                                            <label className="input-group-text sr-only" htmlFor="inputGroupSelect02"></label>
                                                        </div>
                                                        <Autocomplete
                                                            value={this.state.university}
                                                            inputProps={{ id: 'university', name: "university", className: 'form-control hs-input filter-autocomplete text-truncate', placeholder: SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['university'] }}
                                                            items={universityOption}
                                                            getItemValue={item => item.university_name}
                                                            shouldItemRender={(this.state.university) ? (item) => item.university_name.toLowerCase().indexOf(this.state.university.toLowerCase()) !== -1 : null}
                                                            // eslint-disable-next-line
                                                            onChange={this.changeFilter}
                                                            // eslint-disable-next-line
                                                            onSelect={this.changeFilter}
                                                            renderMenu={children => (
                                                                <div className="form-autocomplete-suggestion">
                                                                    {children}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) => (
                                                                (item.university_name) ?
                                                                    <div key={item.id} id={item.id} className={(isHighlighted || this.state.university === item.university_name) ? "form-autocomplete-suggestion-field custom-autocomplete-background" : "form-autocomplete-suggestion-field"}>
                                                                        {item.university_name.trim()}
                                                                    </div>
                                                                    : null
                                                            )}
                                                        />
                                                        <div className={(university) ? "input-group-append input_close_btn" : 'd-none'}>
                                                            <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="university" value=""></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md">
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <label className="input-group-text sr-only" htmlFor="inputGroupSelect03"></label>
                                                        </div>
                                                        <select className="custom-select" id="duration" name="duration" onChange={this.changeFilter} value={this.state.duration}>
                                                            <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['durationfilter']}</option>
                                                            {durationoption}
                                                        </select>
                                                        <div className={(duration) ? "input-group-append input_close_btn" : "d-none"}>
                                                            <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="duration" value=""></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md">
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <label className="input-group-text sr-only" htmlFor="inputGroupSelect04"></label>
                                                        </div>
                                                        <select className="custom-select" id="reward" name="reward" onChange={this.changeFilter} value={this.state.reward}>
                                                            <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['reward']}</option>
                                                            {rewardOption}
                                                        </select>
                                                        <div className={(reward) ? "input-group-append input_close_btn" : "d-none"}>
                                                            <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="reward" value=""></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md">
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <label className="input-group-text sr-only" htmlFor="inputGroupSelect05"></label>
                                                        </div>
                                                        <select className="custom-select" id="status" name="status" onChange={this.changeFilter} value={this.state.status}>
                                                            <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['status']}</option>
                                                            {statusoption}
                                                        </select>
                                                        <div className={(status) ? "input-group-append input_close_btn" : "d-none"}>
                                                            <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="status" value=""></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                <section className="custom-select-survey custom-xs-survey bg-white">
                    <div className="container container-large">
                        <div className="row">
                            <div className="col-12 col-md">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text sr-only" htmlFor="inputGroupSelect01"></label>
                                    </div>
                                    <select className="custom-select" id="category" name="category" onChange={this.changeFilter} value={this.state.category}>
                                        <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['category']}</option>
                                        {categoryOption}
                                    </select>
                                    <div className={(category) ? "input-group-append input_close_btn" : "d-none"}>
                                        <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="category" value=""></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md">
                                <div className="input-group custom-autocomplete-filter mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text sr-only" htmlFor="inputGroupSelect02"></label>
                                    </div>
                                    <Autocomplete
                                        value={this.state.university}
                                        inputProps={{ id: 'university', name: "university", className: 'form-control hs-input filter-autocomplete text-truncate', placeholder: SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['university'] }}
                                        items={universityOption}
                                        getItemValue={item => item.university_name}
                                        shouldItemRender={(this.state.university) ? (item) => item.university_name.toLowerCase().indexOf(this.state.university.toLowerCase()) !== -1 : null}
					// eslint-disable-next-line
                                        onChange={this.changeFilter}
                                        // eslint-disable-next-line
                                        onSelect={this.changeFilter}
                                        renderMenu={children => (
                                            <div className="form-autocomplete-suggestion">
                                                {children}
                                            </div>
                                        )}
                                        renderItem={(item, isHighlighted) => (
					    (item.university_name) ?
                                                <div key={item.id} id={item.id} className={(isHighlighted || this.state.university === item.university_name) ? "form-autocomplete-suggestion-field custom-autocomplete-background" : "form-autocomplete-suggestion-field"}>
                                                    {item.university_name.trim()}
                                                </div>
						:null
                                        )}

                                    />
                                    <div className={(university) ? "input-group-append input_close_btn" : 'd-none'}>
                                        <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="university" value=""></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text sr-only" htmlFor="inputGroupSelect03"></label>
                                    </div>
                                    <select className="custom-select" id="duration" name="duration" onChange={this.changeFilter} value={this.state.duration}>
                                        <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['durationfilter']}</option>
                                        {durationoption}
                                    </select>
                                    <div className={(duration) ? "input-group-append input_close_btn" : "d-none"}>
                                        <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="duration" value=""></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text sr-only" htmlFor="inputGroupSelect04"></label>
                                    </div>
                                    <select className="custom-select" id="reward" name="reward" onChange={this.changeFilter} value={this.state.reward}>
                                        <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['reward']}</option>
                                        {rewardOption}
                                    </select>
                                    <div className={(reward) ? "input-group-append input_close_btn" : "d-none"}>
                                        <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="reward" value=""></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text sr-only" htmlFor="inputGroupSelect05"></label>
                                    </div>
                                    <select className="custom-select" id="status" name="status" onChange={this.changeFilter} value={this.state.status}>
                                        <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['status']}</option>
                                        {statusoption}
                                    </select>
                                    <div className={(status) ? "input-group-append input_close_btn" : "d-none"}>
                                        <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="status" value=""></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                }
                {/* <!-- custom-select-end --> */}
                {/* <!-- main survey --> */}
                <section className="section-blog bg-white">
                    <div className="container container-large">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="blog-lising" style={{display: "flex",}}>
                                    <div className="row blog-listing-container">
                                        {/* surveylist data start */}
                                        {(component.length !== 0) ?
                                            component
                                            : elements
                                        }
                                </div>
                                    {/* EmpirioKarma Ranking Sidebar */}
                                    <div className="col-md-6 col-lg-4 col-xl-3 ranking-container">
                                        <div className="stick-container">
                                            {
                                                this.state.toggle_button === 1?
                                                <>
                                                  <div className="blog-item toggle-button">
                                                    <div style={{padding:"10px 16px"}}>
                                                        <ul className="list-unstyled " style={{marginBottom: "0.0rem"}}>
                                                            <li className="text-gray d-flex">
                                                                <div class="swich-slider d-flex align-items-center">
                                                                    <label class="switch">
                                                                        <input type="checkbox" name="newsletter" checked={this.state.notification} onChange={this.changeFilter} onClick={this.handleChangeToggle} data-toggle="toggle"/><span class="slider round"></span></label>
                                                                </div>
                                                                <div className="blog__card-title d-block" style={{margin: "auto",marginInlineEnd: "inherit"}}>
                                                                    <span className="d-block ">
                                                                        <h5 class="text-dark font-16 custom-test-text text-nowrap sideBarHeading" style={{"marginBottom": "0px"}}>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['toggle_button_heading']}</h5>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                  </div>
                                                </>
                                                :null
                                            }
                                        <div className="blog-item">
                                        <div className=" ranking-bar">
                                            <p className="text-dark font-16 custom-test-text" style={{fontWeight: "700",marginBottom: "10px"}}>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['sliderbar_heading']}</p>
                                            <div className="table-responsive">
                                                    { sidebarDataArrived===true ? (sideBar === true ?
                                                    <>
                                                     <table className="table-container custom-scroll ">
                                                        <thead className= "table-heading">
                                                           <tr style={{width: "100%"}}>
                                                            <th></th>
                                                            <th>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['table-name-heading']}</th>
                                                            <th>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['table-karmapoint-heading']}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody  className={"table-body " + (this.state.toggle_button !== 1 ? "table-body-user" : "")  }>
                                                            {
                                                                MonthlyKarmaRankingdata.map((item,index) =>
                                                                <>
                                                                    {item.bold?
                                                                    <tr style={{fontWeight: "bold"}}>
                                                                        <td>{index+1}.</td>
                                                                        {
                                                                            item.link?
                                                                            <td style={{textTransform: "capitalize"}}><Link to={'/' + URL_CONSTANT['survey_list'] + '/' + item.link}>{item.first_name} {item.last_name[0]}.</Link></td>
                                                                            :
                                                                            <td style={{textTransform: "capitalize"}}>{item.first_name} {item.last_name[0]}.</td>
                                                                        }
                                                                        <td>{item.karmapoint}</td>
                                                                    </tr >
                                                                    :
                                                                    <tr>
                                                                        <td>{index+1}.</td>
                                                                        {
                                                                            item.link?
                                                                            <td style={{textTransform: "capitalize"}}><Link to={'/' + URL_CONSTANT['survey_list'] + '/' + item.link}>{item.first_name} {item.last_name[0]}.</Link></td>
                                                                            :
                                                                            <td style={{textTransform: "capitalize"}}>{item.first_name} {item.last_name[0]}.</td>
                                                                        }
                                                                        <td>{item.karmapoint}</td>
                                                                    </tr>
                                                                    }
                                                                </>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                    </>:
                                                    <div className={"error_container " + (storeitem == null ? "error_container_user" : "")  }>
                                                        <p>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['data_not_found_msg']}</p>
                                                    </div>):
                                                    <div className={"error_container " + (storeitem == null ? "error_container_user" : "")  }>
                                                        <i className="fa fa-spinner fa-spin" style={{ "fontSize": "32px", "color": "#128df5" }}></i>
                                                    </div>
                                                    }
                                            </div>
                                            {/* End table */}
                                            <p style={{color: "#4a4a4a"}} dangerouslySetInnerHTML={{ __html: cmsData.content }}></p>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    {/*<!-- row -->*/}
                                </div>
                                {(surveycount === parseInt(0)) ? null : (elements.length === surveycount) ? null
                                        : <div className="btnlink load-more text-center mt-4">
                                            {(this.state.spinner === "true") ? <div><i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i></div> : <button type="button" onClick={this.loadMore} className="btn btn-clr text-uppercase text-white font-weight-bold font-18" title={SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['loadmore_button']}>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['loadmore_button']}</button>}
                                        </div>
                                    }{/* <!-- blog lisgin -->*/}
                            </div> {/* <!-- col -->*/}
                        </div> {/* <!-- row -->*/}
                    </div>  {/*<!-- container -->*/}
                </section>
                {/* <!--main-survey-end --> */}
            </div>
        );
    }
}

export default SurveyListContent;

