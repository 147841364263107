import React from 'react'
import { SURVEY_PARTICIPATION } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
import Button from '@material-ui/core/Button';
class SurveyMultipleQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.setOptionWidth()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setOptionWidth()
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
            document.querySelectorAll("input[type='checkbox']").forEach((cb) => {
                if (!document.getElementById('yourcolour')) {
                    cb.blur();
                }
            })
        }
    }
    setOptionWidth = () => {
        if (window.innerWidth > 767 && this.props.questiondata['option'].some(option => option.option_text.length > 28)) {
            this.withoutFreeText_opt_Classes = "col-12 col-md-12 pb-4 pb-sm-3 pb-md-0 d-flex full_width_option";
            this.withFreeText_opt_Classes = "col-12 col-md-12 pb-4 pb-sm-3 pb-md-0 full_width_option";
            this.freeTextclasses = "col-12 col-md-12 pb-3 pb-md-0 full_width_option";
        } else {
            this.withoutFreeText_opt_Classes = "col-12 col-md-6 pb-4 pb-sm-3 pb-md-0 d-flex";
            this.withFreeText_opt_Classes = "col-12 col-md-6 pb-4 pb-sm-3 pb-md-0";
            this.freeTextclasses = "col-12 col-md-6 pb-3 pb-md-0";
        }
    }

    /**this function is for prevent form data submit when press enter key */
    stopSubmitting = (e) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }
    clickOnInput = (e) => {
        let id = e.target.id.split('_')[0];
        document.getElementById(id).click();
    }
    changeSelected = (id) => {
        if (id && document.getElementById(id)) {
            if (document.getElementById(id).checked) {
                if (!this.props.questionanswer['free_text_answer'] && !this.props.questionanswer['free_answer_multiple']) {
                    document.getElementById(id).checked = false;
                }
            }
            else {
                document.getElementById(id).checked = true;
            }
        }
    }
    render() {
        let data = this.props.questiondata;
        var surveyDetail = JSON.parse(localStorage.getItem('surveydetail_' + data.survey_id))
        let englishbuttonlabels;
        englishbuttonlabels = surveyDetail.englishbuttonlabels == "1" ? "EN" : "DE"
        let optionlist = [];
        let optionFreeText = [];
        let is_free = data.is_free_text;
        let dataTotalLength = Object.keys(data['option']).length;
        let dataLength =
            (data.option[0]['option_image']) ?
                Object.keys(data['option']).length
                : dataTotalLength - 1;
        var j = 0;
        let checkFreeText;
        if (document.getElementById(data.question_id)) {
            checkFreeText = document.getElementById(data.question_id).checked
        }
        let CheckIeBrowser = false;
        if (window.navigator.userAgent.indexOf('MSIE ') > 0 || window.navigator.userAgent.indexOf('Trident/') > 0 || window.navigator.userAgent.indexOf('Edge/') > 0) {
            CheckIeBrowser = true;
        }
        if (is_free === 1) {
            if (document.getElementById(data.question_id)) {
                if (this.props.questionanswer['free_answer_multiple']) {
                    checkFreeText = true;
                }
                else {
                    checkFreeText = false;
                }
            }
        }
        // eslint-disable-next-line
        let checkLength;
        if (is_free === 1) {
            dataLength = dataLength - 1;
            dataTotalLength = dataTotalLength - 1;
        }
        if (dataLength % 2 === 0) {
            if (is_free === 1) {
                optionFreeText.push(
                    <div key={j} className={this.freeTextclasses}>
                        <div className="form-row pb-3">
                            <div className="form-group col-12 fill-colour custom_text_checkbox">
                                <div className="registration-form xyz ">
                                    <div className="custom-control custom-checkbox checkbox-animation pl-0 free-text-shadow">
                                        <input type="checkbox" className="custom-control-input check-condition" id={data.question_id} name={data.question_id} checked={(this.props.questionanswer['free_answer_multiple'] !== undefined) ?
                                            this.props.questionanswer["free_answer_multiple"] !== undefined ? (this.props.questionanswer['free_answer_multiple'] !== "") ? true : (checkFreeText) ? true : false : (checkFreeText) ? true : false
                                            : (this.props.questionanswer['free_text_answer']) ? true : (checkFreeText) ? true : false
                                        } />
                                        <label className="custom-control-label font-18 text-dark mb-0 w-100 free-text-checkbox" htmlFor="270"><span class="check__cust"  style={surveyDetail.survey_color? {"--check-tick": surveyDetail.survey_color} : {"--check-tick": "#00b3f4"}}><svg width="12px" height="10px"> <polyline points="1.5 6 4.5 9 10.5 1"></polyline> </svg></span></label>
                                        <label htmlFor="yourcolour" className="sr-only">{SURVEY_PARTICIPATION[englishbuttonlabels]['common_textfield_IPH']}</label>
                                        <input type="text" className="form-control free_answer_multiple border-0" name="free_answer_multiple" id="yourcolour" onClick={() => this.changeSelected(data.question_id)} onKeyPress={this.stopSubmitting} onChange={this.props.onChange} placeholder={SURVEY_PARTICIPATION[englishbuttonlabels]['common_textfield_IPH']} value={(this.props.questionanswer['free_answer_multiple'] !== undefined) ? (this.props.questionanswer['free_answer_multiple']) : (this.props.questionanswer['free_text_answer'] !== undefined) ? (this.props.questionanswer['free_text_answer']) : ""} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        else {
            if (is_free === 1) {
                optionFreeText.push(
                    <div key={j} className={this.withoutFreeText_opt_Classes.indexOf("full_width_option") !== -1 ? "row pb-0" : "row pb-0 pb-md-3"}>
                        <div className={this.freeTextclasses}>
                            <div className="form-row pb-3">
                                <div className="form-group col-12 fill-colour custom_text_checkbox">
                                    <div className="registration-form xyz ">
                                        <div className="custom-control custom-checkbox checkbox-animation pl-0 free-text-shadow">
                                            <input type="checkbox" className="custom-control-input check-condition" id={data.question_id} name={data.question_id} checked={(this.props.questionanswer['free_answer_multiple'] !== undefined) ?
                                                this.props.questionanswer["free_answer_multiple"] !== undefined ? ((this.props.questionanswer['free_answer_multiple'] !== "") ? true : (checkFreeText) ? true : false) : (checkFreeText) ? true : false
                                                : (this.props.questionanswer['free_text_answer']) ? true : (checkFreeText) ? true : false
                                            } />
                                            <label className="custom-control-label font-18 text-dark mb-0 w-100 free-text-checkbox" htmlFor="270"><span class="check__cust" style={surveyDetail.survey_color? {"--check-tick": surveyDetail.survey_color} : {"--check-tick": "#00b3f4"}}><svg width="12px" height="10px"> <polyline points="1.5 6 4.5 9 10.5 1"></polyline> </svg></span></label>
                                            <label htmlFor="yourcolour" className="sr-only">{SURVEY_PARTICIPATION[englishbuttonlabels]['common_textfield_IPH']}</label>
                                            <input type="text" className="form-control free_answer_multiple border-0" name="free_answer_multiple" id="yourcolour" onClick={() => this.changeSelected(data.question_id)} onKeyPress={this.stopSubmitting} onChange={this.props.onChange} placeholder={SURVEY_PARTICIPATION[englishbuttonlabels]['common_textfield_IPH']} value={(this.props.questionanswer['free_answer_multiple'] !== undefined) ? (this.props.questionanswer['free_answer_multiple']) : (this.props.questionanswer['free_text_answer'] !== undefined) ? (this.props.questionanswer['free_text_answer']) : ""} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        for (var i = 0; i <= dataLength / 2; i++) {
            optionlist.push(
                (j !== dataTotalLength) ?
                    <div key={data.option[j]['id']} className={this.withoutFreeText_opt_Classes.indexOf("full_width_option") !== -1 && (!data.option[j]['option_image']) ? "row pb-0" : (data.option[j]['option_image']) ? "row pb-0 pb-md-3 justify-content-center" : "row pb-0 pb-md-3"} >
                        {(j < dataTotalLength) ?
                            (data.option[j]['option_text'] !== "free_text_condition") ?
                                <div className={ "check-shadow-custom MuiButton-effect p-0" + ((data.option[j]['option_image']) ? "col-12 col-md-5 pb-5 pb-sm-3 pb-md-0 d-flex" : (dataLength % 2 === 0 && is_free) ? this.withFreeText_opt_Classes : this.withoutFreeText_opt_Classes)}>
                                    <Button variant="contained" color="#00b3f4" name={data.option[j]['id']} id={data.option[j]['id'] + "_button"} onClick={CheckIeBrowser ? this.clickOnInput : null} >
                                        <label htmlFor={data.option[j]['id']} className={(data.option[j]['option_image']) ? "w-100 mb-0 pointer box-shadow multiple-select-img d-flex flex-wrap flex-column" : "w-100 mb-0 pointer multiple-select-img box-shadow "}>
                                                {(data.option[j]['option_image']) ? <div className="fill-by-img" style={{ "backgroundImage": "url(" + data.option[j]['option_image'] + ")" }}>
                                                </div>
                                                    : null}
                                                <div className={(data.option[j]['option_image']) ? "registration-form xyz checkbox-align" : "registration-form xyz checkbox-align min-height-for-img"}>
                                                    <div className="custom-control custom-checkbox checkbox-animation pl-0 w-100">
                                                        {// eslint-disable-next-line 
                                                        }<input type="checkbox" name={data.option[j]['id']} value={data.option[j]['id']} onChange={this.props.onChange} onClick={this.props.onChange} id={data.option[j]['id']}
                                                            checked={(this.props.questionanswer.option === undefined) ?
                                                                (this.props.questionanswer[data.option[j]['id']] === true) ? true : false
                                                                : // eslint-disable-next-line
                                                                (this.props.questionanswer.option.findIndex(ans => ans.option_id === parseInt(data.option[j]['id'])) > -1) ? true : false
                                                            }
                                                            className={(this.props.questionanswer.option === undefined) ?
                                                                (this.props.questionanswer[data.option[j]['id']] === true) ? "custom-control-input selected-checkbox" : "custom-control-input"
                                                                : // eslint-disable-next-line
                                                                (this.props.questionanswer.option.findIndex(ans => ans.option_id === parseInt(data.option[j]['id'])) > -1) ? "custom-control-input selected-checkbox" : "custom-control-input"} />
                                                        <label className="custom-control-label font-18 text-dark mb-0 w-100" htmlFor={data.option[j]['id']}><span class="check__cust" style={surveyDetail.survey_color? {"--check-tick": surveyDetail.survey_color} : {"--check-tick": "#00b3f4"}}><svg width="12px" height="10px"> <polyline points="1.5 6 4.5 9 10.5 1"></polyline> </svg></span><span className="ml-3 font-weight-normal">{data.option[j]['option_text']}</span></label>
                                                    </div>
                                                </div>
                                        </label>
                                    </Button>
                                </div>
                                : null
                            : null
                        }
                        {(parseInt(j + 1) < dataTotalLength) ?
                            (data.option[parseInt(j + 1)]['option_text'] !== "free_text_condition") ?
                                <div key={data.option[j + 1]['id']} className={ "check-shadow-custom MuiButton-effect p-0" + ((data.option[j + 1]['option_image']) ? "col-12 col-md-5 pb-5 pb-sm-3 pb-md-0 d-flex" : this.withoutFreeText_opt_Classes)}>
                                    <Button variant="contained" color="#00b3f4" name={data.option[j]['id']} id={data.option[j + 1]['id'] + "_button"} onClick={CheckIeBrowser ? this.clickOnInput : null} >
                                        <label htmlFor={data.option[j + 1]['id']} className={(data.option[j + 1]['option_image']) ? "w-100 mb-0 pointer box-shadow multiple-select-img d-flex flex-wrap flex-column" : "w-100 mb-0 pointer box-shadow multiple-select-img"}>
                                                {(data.option[j + 1]['option_image']) ? <div className="fill-by-img" style={{ "backgroundImage": "url(" + data.option[parseInt(j + 1)]['option_image'] + ")" }}>
                                                </div>
                                                    : null}
                                                <div className={(data.option[j + 1]['option_image']) ? "registration-form xyz checkbox-align" : "registration-form xyz checkbox-align min-height-for-img"}>
                                                    <div className="custom-control custom-checkbox checkbox-animation pl-0 w-100">
                                                        {// eslint-disable-next-line 
                                                        }<input type="checkbox" name={data.option[j + 1]['id']} value={data.option[j + 1]['id']}
                                                            checked={(this.props.questionanswer.option === undefined) ?
                                                                (this.props.questionanswer[data.option[j + 1]['id']] === true) ? true : false
                                                                : // eslint-disable-next-line
                                                                (this.props.questionanswer.option.findIndex(ans => ans.option_id === parseInt(data.option[j + 1]['id'])) > -1) ? true : false
                                                            }
                                                            className={(this.props.questionanswer.option === undefined) ?
                                                                (this.props.questionanswer[data.option[j + 1]['id']] === true) ? "custom-control-input selected-checkbox" : "custom-control-input"
                                                                : // eslint-disable-next-line
                                                                (this.props.questionanswer.option.findIndex(ans => ans.option_id === parseInt(data.option[j + 1]['id'])) > -1) ? "custom-control-input selected-checkbox" : "custom-control-input"}
                                                            onChange={this.props.onChange} onClick={() => this.props.onChange} id={data.option[j + 1]['id']} />
                                                        <label className="custom-control-label font-18 text-dark mb-0 w-100" htmlFor={data.option[j + 1]['id']}><span class="check__cust" style={surveyDetail.survey_color? {"--check-tick": surveyDetail.survey_color} : {"--check-tick": "#00b3f4"}}><svg width="12px" height="10px"> <polyline points="1.5 6 4.5 9 10.5 1"></polyline> </svg></span><span className="ml-3 font-weight-normal">{data.option[j + 1]['option_text']}</span></label>
                                                    </div>
                                                </div>
                                        </label>
                                    </Button>
                                </div>
                                : null
                            : null
                        }
                        {(dataTotalLength === parseInt(j + 1))
                            ? (dataLength % 2 === 0) ? optionFreeText : null : null}
                    </div>
                    : null
            )
            j = j + 2;
        }
        return (<div key={this.props.questiondata.question_id} className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-5" data-step="4">
            <div className="last-tab-pane">
                <div className="question-heading">
                    <p className="text-dark h3 font-weight-bold text-break survey-title">{data.question_text}</p>
                    <h3 class="font-14 lh-24 fw-400 survey-subtitle">{SURVEY_PARTICIPATION[englishbuttonlabels]['multipal_selection_question']}</h3>
                    {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
                </div>
                <div className="row">
                    <div className="col-12 col-md-12">
                        {optionlist}
                        {(dataLength % 2 !== 0) ?
                            optionFreeText
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>)
    }
}
export default SurveyMultipleQuestion;
