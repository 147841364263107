import React from 'react';
import Button from '@material-ui/core/Button';
class SurveyRatingWithThumbQuestion extends React.Component {
	labelOnClick = (e) => {
        if (e.currentTarget.name) {
            document.getElementById(e.currentTarget.name).click();
            document.getElementById(e.currentTarget.name).checked = !(document.getElementById(e.currentTarget.name).checked);
        }
    }
    render() {
        let data = this.props.questiondata;
        let surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + this.props.questiondata.survey_id));
        let option = [];
        let length = 6;
        for (let i = 1; i < length; i++) {
            option.push(
			<button
                type='button'
                className={"MuiCustom-thumbs-up px-0 " + ((this.props.questionanswer.free_text_answer === undefined) ?
                    (Object.keys(this.props.questionanswer).length === 0) ? "default box-shadow thumbs-button-cusom" : (parseInt(this.props.questionanswer[data.question_id]) === parseInt(i)) ? "btn-rank box-shadow thumbs-button-cusom font-weight-normal selected-thumb" : (parseInt(this.props.questionanswer[data.question_id]) >= parseInt(i)) ? 'btn-rank box-shadow thumbs-button-cusom font-weight-normal' : 'default box-shadow thumbs-button-cusom'
                    : (this.props.questionanswer.free_text_answer === undefined) ? "default box-shadow thumbs-button-cusom" : (parseInt(this.props.questionanswer.free_text_answer) === parseInt(i)) ? "btn-rank box-shadow thumbs-button-cusom font-weight-normal selected-thumb" : (parseInt(this.props.questionanswer.free_text_answer) >= parseInt(i)) ? 'btn-rank box-shadow thumbs-button-cusom font-weight-normal' : 'default box-shadow thumbs-button-cusom')
                }
                name={data.question_id}
                key={i}
                value={i}
                onClick={this.props.onButtonRating}
                style={{ "--school-gradian": surveydetail.survey_color? surveydetail.survey_color : "#00b3f4" }}
            >
                <Button variant="contained" color="#00b3f4" className="box-shadow" onClick={(e) => this.labelOnClick(e)}>
                    {(this.props.questionanswer.free_text_answer === undefined) ?
                    (Object.keys(this.props.questionanswer).length === 0) ? <i className="far fa-thumbs-up rating-gray"></i> : (parseInt(this.props.questionanswer[data.question_id]) >= parseInt(i)) ? <i className="far fa-thumbs-up rating-white"></i> : <i className="far fa-thumbs-up rating-gray"></i>
                    : (this.props.questionanswer.free_text_answer === undefined) ? <i className="far fa-thumbs-up rating-gray"></i> : (parseInt(this.props.questionanswer.free_text_answer) >= parseInt(i)) ? <i className="far fa-thumbs-up rating-white"></i> : <i className="far fa-thumbs-up rating-gray"></i>
                    }
                </Button>
            </button>)
        }
        return (<div key={this.props.questiondata.question_id} className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
            <div className="text-center survey-rating-sm">
                <div className="question-heading text-left">
                    <h3 className="text-dark font-weight-bold text-break survey-title">{data.question_text}</h3>
                    {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
                </div>
                {option}
            </div>
        </div>)
    }
}
export default SurveyRatingWithThumbQuestion;