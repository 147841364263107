import React from 'react';
import axios from "axios";
import { BUBBLE_LOADING } from '../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, META_TITLE_APPEND } from '../../constants/DotEnv';
import EmpirioPageHeader from '../subPageHeader/SubPageHeader';
class Empirio extends React.Component {
    constructor() {
        super();
        this.state = {
            empirioData: {},
            loading: 'false',
            dataLength: '',
            title: '',
            sub_title: ''
        }
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.empirioContent();
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
        if (count === -1) {
            document.querySelector('.navbar-collapse').classList.remove('show');
            document.querySelector('header').classList.remove("header_collapse");
            document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
        }
    }
    empirioContent = () => {
        this.setState({ loading: 'true' })
        let self = this;
        let empirioData = '';
        let dataLength = '';
        let title = '';
        let sub_title = '';
        let meta_title = '';
        let meta_description = '';
        let meta_keywords = '';
        axios.get(API_URL + '/cms/getCmsByID/2/' + LANGUAGE_CODE)
            .then((response) => {
                empirioData = response.data.success.cms;
                dataLength = Object.keys(empirioData).length;
                title = empirioData['title'];
                sub_title = empirioData['sub_title'];
                meta_title = empirioData['title'] + META_TITLE_APPEND
                meta_description = empirioData['metadescription']
                meta_keywords = empirioData['metakeyword']
                window.setMeta({ "description": meta_description, "keywords": meta_keywords });
                window.setDocumentTitle(meta_title)
                self.setState({ loading: 'false', empirioData, dataLength, title, sub_title })
            })
            .catch((error) => {
                self.setState({ loading: 'false' })
            })
    }
    render() {
        let length = Object.keys(this.state.empirioData).length
        let empirioData = this.state.empirioData;
        return (
            <main>
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                <EmpirioPageHeader data={empirioData} title={this.state.title} sub_title={this.state.sub_title} dataLength={this.state.dataLength} />
                {(length > 0 && this.state.loading === 'false') ?
                    <section className="section-blog">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <div className="blog-lising">
                                        <div dangerouslySetInnerHTML={{ __html: empirioData['content'] }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    : null
                }
            </main>
        );
    }
}
export default Empirio;