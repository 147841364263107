import React from 'react';
class SurveyContentPage extends React.Component {
    render() {
        let data = this.props.questiondata;
        let survey_content = "";
        if (data.surveyContent) {
            // eslint-disable-next-line
            survey_content = data.surveyContent.map((data, index) => {
                if (data.content_text && data.is_header === 1) {
                    return (<div className="question-heading"><h3 style={{marginBottom: "20px" }} className={(index === 0) ? "text-dark font-weight-bold survey-title " : "text-dark font-weight-bold survey-title "} key={index} dangerouslySetInnerHTML={{ __html: data.content_text.replace(/<br\s*\/?>/mg, "").replace( /(<([^>]+)>)/ig, '').replace(/(?:\r\n|\r|\n)/g, '<br>') }}></h3></div>)
                }
                else if (data.content_text && data.is_header === 0) {
                    return (<div className="text-break"><p className="font-18 text-dark font-weight-normal" key={index} dangerouslySetInnerHTML={{ __html: data.content_text.replace(/<br\s*\/?>/mg, "").replace( /(<([^>]+)>)/ig, '').replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p></div>)
                }
                else if (data.content_image) {
                    // eslint-disable-next-line
                    return (<div className={`${(data?.image_width == 1024 && data?.image_height == 1024) ? "question-image" : (data?.image_width == 1024 && data?.image_height!= 1024) ?"question-image-width" : (data?.image_width != 1024 && data?.image_height == 1024) ? "question-image-height" : ""} d-block mb-4 text-center`}><img src={data.content_image} id={data.id} key={index} onLoad={this.onImgLoad} ></img></div>)
                }
            })
        }
        return (<div key={this.props.questiondata.question_id} className={"custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")}>
            {survey_content}
        </div>)
    }
}
export default SurveyContentPage;