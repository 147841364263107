import React from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { removeUser } from "../../actions";
import { HEADER_LINKS, URL_CONSTANT } from "../../constants/FrontEndConstant";
import { LANGUAGE_CODE, API_URL, BASEURL, IMAGE_BASEURL} from "../../constants/DotEnv";
import axios from "axios";
const mapStateToProps = (state) => {
  return { newuserdata: state.LogInUserData };
};
function mapDispatchToProps(dispatch) {
  return {
    removeUser: (userdata) => dispatch(removeUser(userdata)),
  };
}
class MainHeader extends React.Component {
  constructor() {
    super();
    this.state = {
      redirect: false,
    };
  }
  componentDidMount() {
    this.scrollUpFunction();
  }
  scrollUpFunction = () => {
    /**for scrolling up page */
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let count = document
      .querySelector(".navbar-toggler")
      .className.indexOf("collapsed");
    if (count === -1) {
      document.querySelector(".navbar-collapse").classList.remove("show");
      document.querySelector("header").classList.remove("header_collapse");
      document
        .querySelector(".navbar-toggler")
        .setAttribute("aria-expanded", false);
    }
  };
  customHandleClick = async (e) => {
    let token = e.target.name;
    let storeitem = JSON.parse(localStorage.getItem("userdata"));
    let apitoken = "";
    let userid = "";
    if (storeitem !== null) {
      apitoken = storeitem.token;
      userid = storeitem.id;
    }
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apitoken,
    };
    var data = {
      userid: userid,
      languagecode: LANGUAGE_CODE,
    };
    this.props.removeUser({ token });
    localStorage.clear();
    this.setState({ redirect: true });
    let self = this;
    let count = document
      .querySelector(".navbar-toggler")
      .className.indexOf("collapsed");
    if (count === -1) {
      document.querySelector(".navbar-collapse").classList.remove("show");
      document.querySelector("header").classList.remove("header_collapse");
      document
        .querySelector(".navbar-toggler")
        .setAttribute("aria-expanded", false);
    }
    // eslint-disable-next-line
    await axios
      .post(API_URL + "/logout" + "?uId=" + userid, data, { headers: headers })
      .then((response) => {
        self.setState({ redirect: false });
      })
      .catch((error) => {
        self.setState({ redirect: false });
      });
    this.scrollUpFunction();
    return this.props.removeUser({ token });
  };
  render() {
    let registration_button_link = this.props.data.registration_button_link
    let sessionToken;
    let storeitem;
    storeitem = JSON.parse(localStorage.getItem("userdata"));
    let tokenCheck = "";
    if (storeitem !== null) {
      tokenCheck = storeitem.token;
    }
    if (this.props.newuserdata.userdata.length !== 0 && tokenCheck === "") {
      sessionToken = this.props.newuserdata.userdata["0"]["token"];
      storeitem = this.props.newuserdata.userdata["0"];
    } else {
      sessionToken = tokenCheck;
    }
    return (
      <header>
        {this.state.redirect === true ? <Redirect to="/anmelden" /> : null}
        <nav className="navbar header-nav navbar-expand-lg">
          <div className="container container-large">
            {/* Brand*/}
            <div className="brand">
            <a className="navbar-brand" href={BASEURL}>
              <img
                className="light-logo mainlogo"
                src={this.props.data.logo? this.props.data.logo.logo_image1 : null}
                alt="empirio-logo"
                width="163" height="50"
              />
              <img
                className="dark-logo"
                src={this.props.data.logo? this.props.data.logo.logo_image : null}
                alt="empirio-logo"
                width="163" height="50"
              />
            </a>
            <span className="header-text">
              {this.props.logo && ` | ${this.props.logo["logo_name"]}`}
            </span>
            </div>
            {/* /*/}
            {/* Mobile Toggle*/}
            {sessionToken === "" ? (
              <Link
                onClick={this.scrollUpFunction}
                className="nav-link-btn btn btn-clr text-white m-25px-l md-m-0px-l d-none d-sm-block d-md-none ml-sm-auto ml-lg-0 mr-sm-4 mr-lg-0"
                to="/anmelden"
                title={HEADER_LINKS[LANGUAGE_CODE]["login"]}
              >
                {HEADER_LINKS[LANGUAGE_CODE]["login"]}
              </Link>
            ) : null}
            {sessionToken !== "" ? (
              <div className="login-profile ml-auto sm-4 d-sm-block d-md-block d-lg-none">
                {/* alt="person-img" */}
                {
                  // eslint-disable-next-line
                }
                <Link
                  onClick={this.scrollUpFunction}
                  to="/mein-bereich"
                  title={HEADER_LINKS[LANGUAGE_CODE]["myprofile_title_label"]}
                  className="nav-link py-0 px-2"
                >
                  <img
                    src={
                      storeitem.userimage
                        ? storeitem.userimage
                        : IMAGE_BASEURL + "img/user_profile.png"
                    }
                    className="rounded-circle"
                    width="408"
                    height="414"
                  />
                </Link>
                {/* <button className="nav-link-btn btn btn-clr text-white m-25px-l sm-m-0px-l" name={sessionToken} title="Abmelden" onClick={this.customHandleClick}>{HEADER_LINKS[LANGUAGE_CODE]['logout']}</button> */}
              </div>
            ) : null}
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbar"
              aria-controls="navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="ti-menu text-white" />
            </button>
            {/* /*/}
            {/* Top Menu*/}
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbar"
            >
              <ul className="navbar-nav align-items-center">
                <li className="m-dropdown m-dropdown-multilevel">
                  <a
                    onClick={() => { this.props.function.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); }}
                    className="nav-link pointer"
                    title={HEADER_LINKS[LANGUAGE_CODE]["features"]}
                  >
                    {HEADER_LINKS[LANGUAGE_CODE]["features"]}
                  </a>
                </li>
                <li className="m-dropdown m-dropdown-multilevel">
                  <a
                    className="nav-link pointer"
                    onClick={() => { this.props.useCases.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); }}
                    title={HEADER_LINKS[LANGUAGE_CODE]["solution"]}
                  >
                    {HEADER_LINKS[LANGUAGE_CODE]["solution"]}
                  </a>
                </li>
                <li>
                  <a
                    className="nav-link pointer"
                    onClick={() => { this.props.packages.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); }}
                    title={HEADER_LINKS[LANGUAGE_CODE]["packages"]}
                  >
                    {HEADER_LINKS[LANGUAGE_CODE]["packages"]}
                  </a>
                </li>
                <li>
                  <a
                    className="nav-link pointer"
                    onClick={() => { this.props.community.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); }}
                    title={HEADER_LINKS[LANGUAGE_CODE]["advantages"]}
                  >
                    {HEADER_LINKS[LANGUAGE_CODE]["advantages"]}
                  </a>
                </li>
                {sessionToken === "" ? (
                  <li className="d-block d-sm-none d-md-block d-lg-none d-lg-block">
                    <Link
                      onClick={this.scrollUpFunction}
                      className="nav-link nav-link-btn btn btn-clr text-white m-25px-l sm-m-0px-l btn-login-margin"
                      to={registration_button_link? registration_button_link : '/registrieren'}
                      title={HEADER_LINKS[LANGUAGE_CODE]["landingPage_header_button"]}
                    >
                      {HEADER_LINKS[LANGUAGE_CODE]["landingPage_header_button"]}
                    </Link>
                  </li>
                ) : (
                    <li className="login-profile d-none d-lg-block">
                      {
                        // eslint-disable-next-line
                      }
                      <Link
                        to="/mein-bereich"
                        onClick={this.scrollUpFunction}
                        title={
                          HEADER_LINKS[LANGUAGE_CODE]["myprofile_title_label"]
                        }
                        className="nav-link"
                      >
                        <img
                          src={
                            storeitem.userimage
                              ? storeitem.userimage
                              : IMAGE_BASEURL + "img/user_profile.png"
                          }
                          className="rounded-circle"
                        />
                      </Link>
                    </li>
                  )}
                {sessionToken !== "" ? (
                  <li>
                    <Link
                      to="/anmelden"
                      className="nav-link nav-link-btn btn btn-clr text-white m-25px-l sm-m-0px-l btn-login-margin"
                      name={sessionToken}
                      title={HEADER_LINKS[LANGUAGE_CODE]["logout"]}
                      onClick={this.customHandleClick}
                    >
                      {HEADER_LINKS[LANGUAGE_CODE]["logout"]}
                    </Link>
                  </li>
                ) : null}
              </ul>
            </div>
            {/* /*/}
          </div>
          {/* Container*/}
        </nav>{" "}
        {/* Navbar*/}
      </header>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainHeader);
