import React from 'react';
import { BUBBLE_LOADING } from '../../constants/FrontEndConstant';
class FaqContent extends React.Component {
    render() {
        let data = this.props.data;
        let i = 0;
        let elements = []
        // eslint-disable-next-line
        data.map((dataNew, index) => {
            elements.push(
                <div className={(index) ? "faq-category-margin text-center" : "first-faq-category-margin text-center"}>
                    <h2 className={(index) ? "text-dark font-weight-bold faq-category-margin" : "text-dark font-weight-bold first-faq-category-margin"}>{dataNew.category_name}</h2>
                </div>
            );
            // eslint-disable-next-line
            dataNew.faqdata.map((dataChange, indexChange) => {
                i++;
                elements.push(
                    <div className="acco-group" key={i} itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        {// eslint-disable-next-line
                        }<a href="#" className="acco-heading text-dark font-weight-bold font-18" itemProp="name" id={i} onClick={this.props.onClick}>{dataChange.faq_question}</a>
                        <div className="panel" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                            <p className="acco-des font-weight-normal font-18 text-dark" itemProp="text">{dataChange.faq_answer}</p>
                        </div>
                    </div>)
            })
        })
        return (
            <section className="section gray-bg">
                {(this.props.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                <div className="container">
                    <div className="accordion accordion-02" id="accordion">
                        {elements}
                    </div>
                </div>
            </section>
        );
    }
}
export default FaqContent;