import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
    HOMEPAGE_CONSTANT,
    BUBBLE_LOADING,
    META_FACEBOOK,
    BREADCRUMB_SCHEMA,
    USER_TYPE,
    USER_GRP,
    GRP,
    PAGE_NOT_FOUND_CONSTANT
} from "../../constants/FrontEndConstant";
import {
    LANGUAGE_CODE,
    API_URL,
    META_TITLE_HOME_PREPEND,
    IMAGE_BASEURL
} from "../../constants/DotEnv";
import Header from "./Header";
import Redirect from "react-router-dom/Redirect";
import Footer from "../footer/Footer.js";
const url = {
    1440 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-1440.svg',
    1280 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-1280.svg',
    1024 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-1024.svg',
    768 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-768.svg',
    375 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-375.svg',
    'default' : 'img/empirio_startseiten_grafiken_hintergrund01.svg'
  } 
  const homeSectionUrl = {
    768 : 'img/empirio_startseiten_grafiken_hintergrund01_content03.svg',
    375 : 'img/empirio_startseiten_grafiken_hintergrund01_content03-375.svg',
    320 : 'img/empirio_startseiten_grafiken_hintergrund01_content03-320.svg',
    'default' : 'img/empirio_startseiten_grafiken_hintergrund01_content02.svg'
  }
class LandingPage extends React.Component {
    constructor() {
        super();
        this.setImageVar();
        this.setHomeSectionVar()
        this.state = {
            landingcontent: {},
            loading: "false",
            data : ''
        };
        this.function = React.createRef();
        this.useCases = React.createRef();
        this.packages = React.createRef();
        this.community = React.createRef();
    }
    componentWillUnmount() {
        setTimeout(() => {
            window.setOgTag(
                META_FACEBOOK["og_title"],
                META_FACEBOOK["og_description"],
                META_FACEBOOK["og_image"],
                META_FACEBOOK["og_url"]
            );
            window.removeFaqItemtype();
            window.removeSchema();
        }, 100);
    }
    componentDidUpdate(prevProps){
        if (prevProps.match.params !== this.props.match.params) {
            this.scrollUpFunction();
        // this.landingcmscontent();
        setTimeout(() => {
            //var schema2 = window.positionCreate(BREADCRUMB_SCHEMA['main']['schema2']['position'],BREADCRUMB_SCHEMA['main']['schema2']['url'],BREADCRUMB_SCHEMA['main']['schema2']['name']);
            var schemaObject = window.schemaObj(
                BREADCRUMB_SCHEMA["schema1"],
                BREADCRUMB_SCHEMA["main"]["schema2"],
                BREADCRUMB_SCHEMA["main"]["schema3"]
            );
            window.setSchema(schemaObject);
  
        }, 100);
        }
    }
    componentDidMount() {
        this.landingcmscontent();
        this.scrollUpFunction();
        setTimeout(() => {
            //var schema2 = window.positionCreate(BREADCRUMB_SCHEMA['main']['schema2']['position'],BREADCRUMB_SCHEMA['main']['schema2']['url'],BREADCRUMB_SCHEMA['main']['schema2']['name']);
            var schemaObject = window.schemaObj(
                BREADCRUMB_SCHEMA["schema1"],
                BREADCRUMB_SCHEMA["main"]["schema2"],
                BREADCRUMB_SCHEMA["main"]["schema3"]
            );
            window.setSchema(schemaObject);
        }, 100);
    }
    setHomeSectionVar = () => {
        if(window.innerWidth <= 320) {
          this.homeSectionImageBg = "url(" +IMAGE_BASEURL+ homeSectionUrl[320]+")"
        }else if(window.innerWidth <= 375) {
          this.homeSectionImageBg = "url(" +IMAGE_BASEURL+ homeSectionUrl[375]+")"
        }else if(window.innerWidth < 768) {
          this.homeSectionImageBg = "url(" +IMAGE_BASEURL+ homeSectionUrl[768]+")"
        }else{
          this.homeSectionImageBg = "url(" + IMAGE_BASEURL+ homeSectionUrl['default'] +")"
        }
      }
    setImageVar = () => {
        if(window.innerWidth <= 375) {
          this.mainImageBg = "url(" +IMAGE_BASEURL+ url[375]+")"
        }else if(window.innerWidth <= 768) {
          this.mainImageBg = "url(" +IMAGE_BASEURL+ url[768]+")"
        }else if(window.innerWidth <= 1024) {
          this.mainImageBg = "url(" +IMAGE_BASEURL+ url[1024]+")"
        }else if(window.innerWidth <= 1440){
          this.mainImageBg = "url(" +IMAGE_BASEURL+ url[1440]+")"
        }else{
          this.mainImageBg = "url(" + IMAGE_BASEURL+ url['default'] +")"
        }
      }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let count = document
            .querySelector(".navbar-toggler")
            .className.indexOf("collapsed");
        if (count === -1) {
            document.querySelector(".navbar-collapse").classList.remove("show");
            document.querySelector("header").classList.remove("header_collapse");
            document
                .querySelector(".navbar-toggler")
                .setAttribute("aria-expanded", false);
        }
    };
    landingcmscontent = () => {
        this.setState({ loading: "true" });
        let self = this;
        // let meta_title = "";
        let meta_description = "";
        let meta_keyword = "";
        // let og_title = "";
        // let og_description = "";
        // let og_image = "";
        const slug = this.props.match.params.accType
        axios
            .get(API_URL + `/NSULandingPage/getNSULandingPage/${slug}/` + LANGUAGE_CODE)
            .then((response) => {
                // meta_title =
                //   META_TITLE_HOME_PREPEND +
                //   response.data.success.cms.homecms[LANGUAGE_CODE]["title"];
                meta_description =
                response.data.success.NSULandingPage.culture[LANGUAGE_CODE]["meta_description"]
                meta_keyword =
                response.data.success.NSULandingPage.culture[LANGUAGE_CODE]["meta_keyword"]
                // og_title =
                //   META_TITLE_HOME_PREPEND +
                //   response.data.success.cms.homecms[LANGUAGE_CODE]["title"];
                // og_description =
                //   response.data.success.cms.homecms[LANGUAGE_CODE]["meta_description"];
                // og_image = META_FACEBOOK["og_image"];
                window.setMeta({
                  description: meta_description,
                  keywords: meta_keyword,
                });
                // window.setOgTag(
                //   og_title,
                //   og_description,
                //   og_image,
                //   window.location.href
                // );
                // window.setDocumentTitle(meta_title);
                document.title=response.data.success.NSULandingPage.culture[LANGUAGE_CODE]["header_h1_headline"]
                self.setState({
                    loading: "false",
                    landingcontent: response.data.success.NSULandingPage,
                });
            })
            .catch((error) => {
                self.setState({ loading: "false" });
                this.setState({data : true})
            });
    };
    getKeys = (part, o) =>
        Object.entries(o).filter(([k, v]) => k.startsWith(part));
    filterKeys = (part, arr) => arr.filter((e) => e[0].endsWith(part));
    render() {
        let length = Object.keys(this.state.landingcontent).length;
        let data = "";
        let faqdata = "";
        let faq = [];
        let functioncontent = [];
        let usecaseContent = [];
        let packageContent = [];
        let exampleContent = [];
        let advantagesContent = [];
        let cultureData = "";
        let BottomTextData = [];
        if (length !== 0) {
            data = this.state.landingcontent;
        }
        if (data.culture && data.culture !== "" && data.culture !== null) {
            cultureData = data.culture[LANGUAGE_CODE];

            let functionData = this.getKeys("function_content", cultureData);
            let functionImages = this.getKeys("function_image", data);
            let functionlength = functionData.length;
            let finctionImgLength = functionImages.length;

            let usecaseData = this.getKeys("usecase_card", cultureData);
            let usecaseTitles = this.filterKeys("title", usecaseData);
            let usecaseContents = this.filterKeys("content", usecaseData);
            let usecaseImages = this.getKeys("usecase_card", data);
            let usecaseLength = usecaseData.length;

            let packageData = this.getKeys("package_card", cultureData);
            let packageTitles = this.filterKeys("h1_headline", packageData);
            let packageSubTitles = this.filterKeys("h2_subheadline", packageData);
            let packageContents = this.filterKeys("content", packageData);

            let exampleCultureData = data.example_culture[LANGUAGE_CODE];
            let exampleImgData = data.example;

            let advantagesData = this.getKeys("advantages_card", cultureData);
            let advantagesTitles = this.filterKeys("title", advantagesData);
            let advantagesContents = this.filterKeys("content", advantagesData);
            let advantagesIcons = this.filterKeys("icon", advantagesData);

            let faqData = this.getKeys("faq_card", cultureData);
            let faqQuestions = this.filterKeys("question", faqData);
            let faqAns = this.filterKeys("answer", faqData);

            let bottomText = this.getKeys("bottom_section_text", cultureData);
            let bottomTextLenght = bottomText.length

            //   adding functions content
            if (
                functionlength &&
                functionlength !== 0 &&
                finctionImgLength &&
                finctionImgLength !== 0
            ) {
                for (let i = 0; i < functionlength; i++) {
                    let count = i + 1;
                    let alt_text = cultureData[functionData[i][0]]
                        ? cultureData["function_image" +[i+1] + "_alt_text"]
                        : "mian-banner";
                    if (count % 2 !== 0) {
                        functioncontent.push(
                            <section
                                className="content-right bg-white home-content-right"
                                key={i}
                            >
                                <div className="container container-large">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 text-center mxh-img">
                                            <img
                                                src={data[functionImages[i][0]]}
                                                alt={alt_text}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-2 mt-md-0">
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: cultureData[functionData[i][0]],
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        );
                    } else {
                        functioncontent.push(
                            <section
                                className="content-left content-left-bg-img home-section home-content-left second-section-landingpage"
                                key={i}
                                style={{"--home-section" : this.homeSectionImageBg, padding: "0px"}}
                            >
                                {/* <div className="home-effect-bg d-none d-md-block" ><img className="block-contant-bg-img" src={"http://192.168.100.96:3000/img/empirio_startseiten_grafiken_hintergrund01_content02.jpg"} title="" alt="" /></div> */}
                                <div className="container container-large">
                                    <div className="row align-items-center screen-height-landingpage p-100px-tb sm-p-30px-b pt-0">
                                        <div className="col-md-6 mt-2 mt-md-0">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: cultureData[functionData[i][0]],
                                                }}
                                            ></div>
                                        </div>
                                        <div className="col-md-6 text-center mxh-img order-class-sm">
                                            <img
                                                src={data[functionImages[i][0]]}
                                                alt={alt_text}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        );
                    }
                }
            }

            //   // adding usecase content
            if (usecaseData && usecaseLength) {
                for (let i = 0; i < usecaseTitles.length; i++) {
                    usecaseContent.push(
                        <div className="card card-body col-md-5 m-3 feature-box feature-content d-table">
                            <span>
                                {/* <i class="fas fa-edit" /> */}
                                <i className={data[usecaseImages[i][0]]} />
                            </span>
                            <h5 className="card-title text-dark font-weight-bold" style={{marginTop: "15px"}}>
                                {cultureData[usecaseTitles[i][0]]}
                            </h5>
                            <p
                                className="card-text text-dark"
                                dangerouslySetInnerHTML={{
                                    __html: cultureData[usecaseContents[i][0]],
                                }}
                            ></p>
                        </div>
                    );
                }
            }

            if (packageData && packageData.length) {
                for (let i = 0; i < packageTitles.length; i++) {
                    packageContent.push(
                        <div className="col-lg-5 mb-5 mx-auto p-2 ">
                            <div className="card card-body feature-box feature-content d-table w-100">
                                <h5 className="card-title text-center font-weight-bold text-dark">
                                    {cultureData[packageTitles[i][0]]}
                                </h5>
                                <h6 className="card-subtitle mb-2 text-center text-muted">
                                    {cultureData[packageSubTitles[i][0]]}
                                </h6>
                                <p
                                    className="text-dark paket-list p-2"
                                    dangerouslySetInnerHTML={{
                                        __html: cultureData[packageContents[i][0]],
                                    }}
                                ></p>
                                <div class="btn-bar" style={{textAlign: "center"}}>
                                <Link
                                style={{opacity: "100%"}}
                                    to={data["package_card" + [i+1] + "_button_link"]}
                                    className="btn-package btn-clr text-uppercase text-white font-18"
                                    title={cultureData["package_card" + [i+1] + "_button_text"]
}
                                    >
                                    {cultureData["package_card" + [i+1] + "_button_text"]}
                                </Link>
                                </div>
                            </div>
                        </div>
                    );
                }
            }

            if (exampleCultureData && exampleCultureData.length) {
                for (let i = 0; i < exampleCultureData.length; i++) {
                    exampleContent.push(
                            <section
                                className="content-right home-content-right content-left-bg-img home-section "
                                key={i}
                                style={{"--home-section" : this.homeSectionImageBg}}
                            >
                                  <div className="container container-large ">
                                  <div class="row align-items-center screen-height-landingpage  sm-p-30px-b pt-0">
                                      <div class=" mt-2 mt-md-0" style={{padding : "0px 15px"}}>
                                     <div className="row justify-content-center mb-0 mb-md-0 m-45px-b md-m-25px-b">
                                            <div className="col-12 text-center">
                                                <div className="section-title text-center">
                                                    <h2 className="text-dark font-weight-bold">
                                                    {cultureData["example_centered_headline"]}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    <div className="row align-items-center ">
                                        <div className="col-md-6 text-center mxh-img">
                                            <img
                                                src={exampleImgData[i]["example_image"]}
                                                alt={exampleCultureData[i]["example_image_alt_text"]}
                                                className="img-fluid example-img "
                                            />
                                        </div>
                                        <div className="col-md-6 mt-2 mt-md-0">
                                            <h3 className="font-weight-bold text-dark">
                                                {exampleCultureData[i]["example_title"]}
                                            </h3>
                                            <p
                                                className="text-dark"
                                                dangerouslySetInnerHTML={{
                                                __html: exampleCultureData[i]["example_content"],
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </div>
                            </section>
                    );
                }
            }
            if (advantagesData && advantagesData.length !== 0) {
                for (let i = 0; i < advantagesTitles.length; i++) {
                    advantagesContent.push(
                        <div className="col-lg-4 col-md-6 col-sm-6 m-15px-tb">
                            <div className="feature-box text-center">
                                <div className="icon">
                                    <i className={cultureData[advantagesIcons[i][0]]} />
                                </div>
                                <div className="feature-content mt-4">
                                    <h3 className="mb-4 text-dark font-weight-bold">
                                        {cultureData[advantagesTitles[i][0]]}
                                    </h3>
                                    <p
                                    className="text-dark"
                                        dangerouslySetInnerHTML={{
                                            __html: cultureData[advantagesContents[i][0]],
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                }
            }

            if (faqData.length !== 0) {
                for (let i = 0; i < faqQuestions.length; i++) {
                    faq.push(
                        <div className="row" key={i}>
                            <div className="col-12 col-md-12 my-0 m-15px-tb">
                                <div
                                    className="row mt-4 "
                                    itemScope
                                    itemProp="mainEntity"
                                    itemType="https://schema.org/Question"
                                >
                                    <div className="col-12 col-md-5 col-lg-4">
                                        <p
                                            className="text-dark font-weight-bold font-18 mb-0"
                                            itemProp="name"
                                        >
                                            {" "}
                                            {cultureData[faqQuestions[i][0]]}
                                        </p>
                                    </div>
                                    <div
                                        className="col-12 col-md-7 col-lg-8"
                                        itemScope
                                        itemProp="acceptedAnswer"
                                        itemType="https://schema.org/Answer"
                                    >
                                        <p
                                            className="font-weight-noraml text-dark font-18 mb-0"
                                            itemProp="text"
                                        >
                                            {cultureData[faqAns[i][0]]}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            }
            if (bottomText && bottomTextLenght !== 0){
                BottomTextData.push(
                    <section className="section-community card-bg-white mt-0 mt-md-5 mt-lg-5 nsu_freeText">
                        <div className="container">
                            <div className="row justify-content-center mb-0 m-45px-b md-m-25px-b">
                                <div className="col-12 text-center">
                                    <div className="section-title text-center">
                                        <p
                                            className="text-dark"
                                            dangerouslySetInnerHTML={{
                                            __html: cultureData["bottom_section_text"],
                                        }}
                                    />
                                    </div>
                                </div>
                            {/*   col    */}
                            </div>
                        </div>
                    </section>
                )
            }
        }
        return (
            <>
            {
                this.state.data?
                <Redirect to="/404"></Redirect>
                :null
            }
                {this.state.loading === "true" ? BUBBLE_LOADING : null}{/* header  */}
                {
                    <>
                    <Header data={this.state.landingcontent} function={this.function} useCases={this.useCases} packages={this.packages} community={this.community}/>
                    <main>
                        {length > 0 && this.state.loading === 'false'? (
                            //  Main
                            <div>
                                {/*   Home Banner  */}
                                {/* <section
                                    id="home"
                                    className="theme-main-homepage home-section home-banner home-banner-changed nsu-home-banner-changed theme-main-changed theme-main theme-banner"
                                >
                                    <div id="banner" className="landing-home-effect-bg home-effect-bg home-effect-bg-img d-md-block"
                                        // style={{
                                        //     "--my-color-var": data.logo_header_color["header_color"]
                                        // }}
                                    /> */}
                                    <section id="home" className="theme-main-homepage home-section theme-main home-banner home-banner-changed theme-main-changed">
                                    <div className="home-effect-bg home-effect-bg-img d-md-block" style={{"--main-img" : this.mainImageBg}}>
                                        </div>
                                    <div className="container container-large">
                                    <div class="row align-items-center screen-height-landingpage p-100px-tb sm-p-30px-b ">
                                        <div class="col-md-6 mt-2 mt-md-0 textcenter"><div>
                                        <h1 className="text-white font-weight-bold mb-0 ">
                                                    {cultureData["header_h1_headline"]}
                                                </h1>
                                                <h4 className="text-white mt-4 ">
                                                    {cultureData["header_h2_subheadline"]}
                                                </h4>
                                                <div className="btn-bar">
                                                    <Link
                                                        className="btn btn-clr text-white text-uppercase font-18"
                                                        to={data["header_cta_button_link"]}
                                                        title={cultureData["header_cta_button_text"]}
                                                    >
                                                        {cultureData["header_cta_button_text"]}
                                                    </Link>
                                                </div>
                                            {/* </div>   */}
                                    </div>
                                    </div>
                                    <div class="col-md-6 text-center mxh-img order-class-home imgcenter">
                                    <img src={ data['header_image1']} title="" alt={cultureData["header_image1_alt_text"]}/>
                                    </div>
                                    </div>
                                    </div>
                                </section>
                                {/* Block content start*/}
                                <section id="functions" ref={this.function}>{functioncontent}</section>
                                {/* Block content end */}
                                {/* use cases  */}
                                <section id="use-cases" ref={this.useCases} className="section content-left-bg-img home-section theme-main nsu_usecse">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-12 text-left use-cases">
                                                <div className="section-title text-left" style={{paddingTop: "10px"}}>
                                                    <h3 className=" font-weight-bold text-light">
                                                        {cultureData["usecase_h2_headline"]}
                                                    </h3>
                                                </div>
                                                <p className="text-left text-light">
                                                    {cultureData["usecase_subheadline"]}
                                                </p>
                                                <Link
                                                    to={data["usecase_button_link"]}
                                                    className="btn btn-clr text-uppercase text-white font-16"
                                                    title={cultureData["usecase_buttontext"]}
                                                >
                                                    {cultureData["usecase_buttontext"]}
                                                </Link>
                                            </div>
                                            <div className="col-lg-9 col-md-12 usecaseContent" >
                                                <div className="row h-auto d-flex justify-content-center">{usecaseContent}</div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/* packages  */}
                                <section id="packages" ref={this.packages} className="section bg-white">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 mb-5">
                                                <div className="section-title text-center">
                                                    <h2 className="text-dark font-weight-bold mb-2">
                                                        {cultureData["package_centered_headline"]}
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="col-12 row mx-auto ">{packageContent}</div>
                                        </div>
                                    </div>
                                </section>
                                {/* section-example */}
                                {exampleContent}
                                {/*   section-community    */}
                                <section
                                    id="community"
                                    ref={this.community}
                                    className="section-community card-bg-white mt-0 mt-md-5 mt-lg-5 nsu_community"
                                >
                                    <div className="container">
                                        <div className="row justify-content-center mb-0 m-45px-b md-m-25px-b">
                                            <div className="col-12 text-center">
                                                <div className="section-title text-center">
                                                    <h2 className="text-dark font-weight-bold">
                                                        {cultureData["advantages_centered_headline"]}
                                                    </h2>
                                                </div>
                                            </div>
                                            {/*   col    */}
                                        </div>
                                        {/*   row    */}
                                        <div className="row justify-content-center text-center text-dark">
                                            {advantagesContent}
                                        </div>
                                        {/*   row    */}
                                    </div>
                                    {/*   container    */}
                                </section>
                                <section id="faq" className=" faq-question nsu_FAQ content-left-bg-img home-section" style={{"--home-section" : this.homeSectionImageBg,}}>
                                    <div className="container ">
                                    <div class="row align-items-center screen-height-landingpage sm-p-30px-b pt-0">
                                        <div class=" mt-2 mt-md-0" style={{padding : "0px 15px"}}>
                                        <div className="row justify-content-center mb-0 mb-md-0 m-45px-b md-m-25px-b">
                                            <div className="col-12 text-center">
                                                <div className="section-title text-center">
                                                    <h2 className="text-dark font-weight-bold">
                                                        {cultureData["faq_centered_headline"]}
                                                    </h2>
                                                </div>
                                            </div>
                                            {/*   col    */}
                                        </div>
                                        {/*   row    */}
                                        {faq.length !== 0 ? (
                                            faq
                                        ) : (
                                                <div className="row justify-content-center">
                                                    <h1>
                                                        {HOMEPAGE_CONSTANT[LANGUAGE_CODE]["data_not_found"]}
                                                    </h1>
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                    {/*   container    */}
                                </section>
                                {BottomTextData}
                                {/* //   sign-up-now-section    */}
                                <div className="analysiere theme-main">
                                    <div className="container">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-10 col-lg-8">
                                                <div className="section-title text-center">
                                                    <h2 className="pb-2 text-white font-weight-bold">
                                                        {cultureData["call_to_action_title"]}
                                                    </h2>
                                                    <Link
                                                        to={data["registration_button_link"]}
                                                        className="btn btn-clr text-uppercase text-white font-18"
                                                        title={
                                                            cultureData["registration_button_text"]
                                                        }
                                                    >
                                                        {cultureData["registration_button_text"]}
                                                    </Link>
                                                </div>
                                            </div>
                                            {/*   col    */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) :
                        (
                            <section class="page-title-section theme-bg d-flex justify-content-center align-items-center text-center">
                                <div class="container text-white error">
                                    <h2 class="text-uppercase">{PAGE_NOT_FOUND_CONSTANT[LANGUAGE_CODE]['title_text']}</h2>
                                    <h3 class="text-white h2">{PAGE_NOT_FOUND_CONSTANT[LANGUAGE_CODE]['sub_title_text']}</h3>
                                </div>
                            </section>
                        )
                            }
                    </main>
                    </>
                }
                <Footer />
            </>
        );
    }
}

export default LandingPage;
