import React from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import { META_TAGS_CONSTANT, BUBBLE_LOADING } from '../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, META_TITLE_APPEND } from '../../constants/DotEnv';
import CommonCmsPageHeader from '../subPageHeader/SubPageHeader';
import 'react-confirm-alert/src/react-confirm-alert.css'
class CmsContentPage extends React.Component {
    constructor() {
        super();
        this.state = {
            length: '',
            loading: 'false',
            redirect: 'false',
            redirectExternal: false,
            redirectto404: false,
            cmscontentdata: {},
            cmscontentlength: '',
            iscms: false,
            issurveydetail: false,
        }
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.getCmsDetail();
    }
    /**this function is for scrolling up page */
    scrollUpFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (document.querySelector('.navbar-toggler')) {
            if (document.querySelector('.navbar-toggler').className) {
                let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
                if (count === -1) {
                    document.querySelector('.navbar-collapse').classList.remove('show');
                    document.querySelector('header').classList.remove("header_collapse");
                    document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
                }
            }
        }
    }
    /**API call for getting cms page details */
    getCmsDetail = async () => {
        this.setState({ loading: 'true' })
        let title = this.props.match.params.title;
        let self = this;
        let meta_title = '';
        let meta_description = '';
        let meta_keywords = '';
        let redirectto404 = false;
        let iscms = false;
        let cmscontentdata = '';
        let cmscontentlength = '';
        let cmstitle = '';
        let cmssub_title = '';
        await axios.get(API_URL + '/cms/getCmsBySlug/' + title + '/' + LANGUAGE_CODE)
            .then((response) => {
                cmscontentdata = response.data.success.cms
                cmscontentlength = Object.keys(cmscontentdata).length;
                cmstitle = response.data.success.cms.title;
                cmssub_title = response.data.success.cms.sub_title;
                meta_title = response.data.success.cms.title + META_TITLE_APPEND;;
                meta_description = (response.data.success.cms.metadescription) ? response.data.success.cms.metadescription : META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_description'];
                meta_keywords = (response.data.success.cms.metakeyword) ? response.data.success.cms.metakeyword : META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords'];
                window.setMeta({ "description": meta_description, "keywords": meta_keywords });
                window.setDocumentTitle(meta_title)
                if (!cmscontentlength) {
                    redirectto404 = true
                } else {
                    iscms = true
                }
                self.setState({ cmscontentlength, cmscontentdata, cmstitle, cmssub_title })
            })
            .catch((error) => {
            })
        this.setState({ redirectto404, loading: 'false', iscms })
    }
    render() {
        let cmslength = Object.keys(this.state.cmscontentdata).length
        let cmscontentpagedata = this.state.cmscontentdata;
        return (
            <main>
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {(this.state.redirectto404) ?
                    <Redirect to="/404"></Redirect>
                    : null
                }
                {(this.state.iscms) ?
                    <div>
                        <CommonCmsPageHeader data={cmscontentpagedata} title={this.state.cmstitle} sub_title={this.state.cmssub_title} dataLength={this.state.cmscontentlength} />
                        {(cmslength > 0 && this.state.loading === 'false') ?
                            <section className="section-blog">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-md-12">
                                            <div className="blog-lising">
                                                <div dangerouslySetInnerHTML={{ __html: cmscontentpagedata['content'] }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            : null}
                    </div>
                    : null
                }
            </main>
        );
    }
}
export default CmsContentPage;