import React from 'react';
import SurveyCoverPage from './SurveyCoverPage';
import SurveyOtherParticipationComponents from '../surveyParticipationComponents/SurveyOtherParticipationComponents';
import axios from "axios";
import { SURVEY_DETAIL_CONSTANT, SURVEY_PARTICIPATION, URL_CONSTANT, META_TAGS_CONSTANT, BUBBLE_LOADING, META_FACEBOOK, OG_PARTICIPATION } from '../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, META_TITLE_APPEND } from '../../../constants/DotEnv';
import { Redirect } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import html2canvas from 'html2canvas';
class SurveyParticipation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstcomponent: 0,
            loading: 'false',
            surveyParticipationQuestion: [],
            start_time: "",
            sessionRedirect: false,
            redirectPagenotfound: false,
            isNormalImage:'yes',
            is_premium_user_survey:null,
            toggle: 0
        }
        this.surveyIdMain = (this.props.match.params.surveyid) ? this.checkBase64(this.props.match.params.surveyid, false) : "";
        this.startSurvey = (this.props.match.params.start === URL_CONSTANT['start']) ? this.props.match.params.start : "";
        this.previewSurvey = (this.props.match.params.preview === URL_CONSTANT['preview']) ? this.props.match.params.preview : "";
        this.title = (this.props.match.params.title) ? this.checkBase64(this.props.match.params.title, true) : "";
        this.userId = (this.props.match.params.userid) ? this.checkBase64(this.props.match.params.userid, false) : "";
        setTimeout(() => {
            window.setNoIndex();
        }, 10000)
    }
    /* this function is use for check base64 url is valid or not */
    checkBase64 = (string, check) => {
        try {
            // eslint-disable-next-line
            this.state.redirectPagenotfound = false;
            if (check) {
                return string;
            }
            else {
                window.atob(string);
                return window.atob(string);
            }
        } catch (e) {
            if (e.code === 5) {
                // eslint-disable-next-line
                this.state.redirectPagenotfound = true
            }
            return "";
        }
    }
    componentWillUnmount() {
        document.getElementsByTagName("body")[0].classList.remove("survey-participation-fixed-body");
        window.removeNoIndex();
        setTimeout(() => {
            window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
        }, 100)
    }
    componentDidMount() {
        document.getElementsByTagName("body")[0].classList.add("survey-participation-fixed-body");
        this.scrollUpFunction();
    }

    captureScreen = (ImageType) => {
        if (window.getQueryStringValue('capture')) {
            /* --- Using Html2Canvas because domtoImage not working in Safari --- */
            setTimeout(() => {
                html2canvas(document.body, {
                    allowTaint: true
                }).then(function (canvas) {
                    var img_tag = document.createElement('a');
                    var ext = ImageType.split("/")[1];
                    img_tag.href = canvas.toDataURL(ImageType);
                    img_tag.download = document.title.replace(/[^A-Z0-9]+/ig, "_") + '.' + ext;
                    img_tag.click();
                    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
                    if (isSafari || isChrome) {
                        setTimeout(() => window.close(), 1500)
                    }
                });
            }, 1000)
        }
    }
    /**
    * Warning: This lifecycle is currently deprecated, and will be removed in React version 17+
    More details here: https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
    */
    componentWillMount() {
        this.getSurveyDetail();
    }
    /**this function is for counting external users who visited survey cover page  */
    isVisitedExternalCall = () => {
        if (this.surveyIdMain) {
            var data = {
                "survey_id": this.surveyIdMain,
            }
            axios.post(API_URL + "/anonymoussurveyresult/surveyparticipantByAnonymousUser", data)
                .then((response) => {
                })
                .catch((error) => {
                })
        }
    }
    /**Get survey detail if not available in loacl stroge */
    getSurveyDetail = async () => {
        this.setState({ loading: 'true' })
        let surveyid;
        let annonymus_survey_slug;
        // eslint-disable-next-line
        let titleNew;
        var apiCall = 'getSurveyBySlug';
        var backgroundImageType = "image/png";
        let redirectPagenotfound = this.state.redirectPagenotfound;
        if (this.props) {
            surveyid = this.surveyIdMain;
            annonymus_survey_slug = this.title;
            if (annonymus_survey_slug && !surveyid) {
                if (!this.startSurvey && !this.previewSurvey) {
                    apiCall = 'getSurveyByIdentifier';
                }
                await axios.get(API_URL + '/commonApi/' + apiCall + '/' + LANGUAGE_CODE + '/' + annonymus_survey_slug + '/live')
                    .then((response) => {
                        if (response.data.success) {
                            if (response.data.success.surveydata) {
                                if (response.data.success.surveydata.id) {
                                    surveyid = parseInt(response.data.success.surveydata.id);
                                    this.surveyIdMain = parseInt(response.data.success.surveydata.id);
                                }
                                if (!Object.keys(response.data.success.surveydata).length) {
                                    redirectPagenotfound = true;
                                }
                            }
                            else {
                                redirectPagenotfound = true;
                            }
                        }
                    })
                    .catch((error) => {
                        redirectPagenotfound = true;
                        console.log("error", error)
                    })
            }
        }
        if (!this.startSurvey && !this.previewSurvey) {
            this.isVisitedExternalCall();
        }
        if (surveyid) {
            let surveycreation = JSON.parse(localStorage.getItem('surveycreationdata_' + surveyid));
            let surveydetail;
            let meta_title = '';
            let meta_description = '';
            let meta_keywords = '';
            let og_title = '';
            let og_description = '';
            let og_image = '';
            localStorage.removeItem('surveydetail_' + surveyid);
            surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + surveyid));
            if (surveydetail) {
                let username = surveydetail.user_name;
                username = username.split(" ")[0];
                let user_gender = surveydetail.gender;
                meta_title = surveydetail.survey_title + META_TITLE_APPEND;
                meta_description = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_description'];
                meta_keywords = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords'];
                og_title = surveydetail.survey_title
                if (user_gender === 1) {
                    og_description = OG_PARTICIPATION[LANGUAGE_CODE]['help'] + " " + username + " " + OG_PARTICIPATION[LANGUAGE_CODE]['male_description'];
                } else if (user_gender === 2) {
                    og_description = OG_PARTICIPATION[LANGUAGE_CODE]['help'] + " " + username + " " + OG_PARTICIPATION[LANGUAGE_CODE]['female_description'];
                } else {
                    og_description = OG_PARTICIPATION[LANGUAGE_CODE]['help'] + " " + username + OG_PARTICIPATION[LANGUAGE_CODE]['common_description'];
                }
                og_image = (surveydetail.ogImageUrl) ? surveydetail.ogImageUrl : META_FACEBOOK['og_image'];
                setTimeout(() => {
                    window.setOgTag(og_title, og_description, og_image, window.location.href)
                    window.setMeta({ "description": meta_description, "keywords": meta_keywords })
                    window.setDocumentTitle(meta_title)
                    if (surveydetail.hasOwnProperty("mime")) {
                        backgroundImageType = surveydetail['mime'].split(";")[0]
                    }
                    this.captureScreen(backgroundImageType);
                }, 100)
            }
            if (!surveydetail) {
                if (surveycreation) {
                    if (surveycreation.survey_title_slug) {
                        titleNew = surveycreation.survey_title_slug
                    }
                }
                else if (annonymus_survey_slug) {
                    titleNew = annonymus_survey_slug;
                }
                let self = this;
                let surveydata = '';
                await axios.get(API_URL + '/commonApi/getSurveyById/' + LANGUAGE_CODE + '/' + surveyid + '/live')
                    .then((response) => {
                        surveydetail = response.data.success.surveydata;
                        if(surveydetail.image_surveyid)
                        {
                            this.setState({isNormalImage : surveydetail.image_surveyid})
                        }
                        if (surveydetail) {
                            let username = surveydetail.user_name;
                            username = username.split(" ")[0];
                            let user_gender = surveydetail.gender;
                            surveydata = response.data.success.surveydata;
                            localStorage.setItem("surveydetail_" + surveyid, JSON.stringify(surveydata));
                            meta_title = response.data.success.surveydata.survey_title + META_TITLE_APPEND;
                            meta_description = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_description'];
                            meta_keywords = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords'];
                            og_title = response.data.success.surveydata.survey_title;
                            if (user_gender === 1) {
                                og_description = OG_PARTICIPATION[LANGUAGE_CODE]['help'] + " " + username + " " + OG_PARTICIPATION[LANGUAGE_CODE]['male_description'];
                            } else if (user_gender === 2) {
                                og_description = OG_PARTICIPATION[LANGUAGE_CODE]['help'] + " " + username + " " + OG_PARTICIPATION[LANGUAGE_CODE]['female_description'];
                            } else {
                                og_description = OG_PARTICIPATION[LANGUAGE_CODE]['help'] + " " + username + OG_PARTICIPATION[LANGUAGE_CODE]['common_description'];
                            }
                            og_image = (response.data.success.surveydata.ogImageUrl) ? response.data.success.surveydata.ogImageUrl : META_FACEBOOK['og_image'];
                            setTimeout(() => {
                                window.setOgTag(og_title, og_description, og_image, window.location.href)
                                window.setMeta({ "description": meta_description, "keywords": meta_keywords })
                                window.setDocumentTitle(meta_title)
                            }, 100)
                            if (surveydetail.hasOwnProperty("mime")) {
                                backgroundImageType = surveydetail['mime'].split(";")[0]
                            }
                        }
                        self.setState({ surveydetail }, () => { self.captureScreen(backgroundImageType) })
                    })
                    .catch((error) => {
                        console.log("error", error)
                    })
            }
        }
        this.setState({ loading: 'false', redirectPagenotfound })
    }
    /** this function is for scrolling up page */
    scrollUpFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }
    onClickOfSurveyStart = async (event) => {
        this.annonymousSurveySlug = this.title;
        let surveyid = this.surveyIdMain;
        var apiCall = 'getSurveyBySlug';
        // eslint-disable-next-line
        let userid = this.userId;
        await axios.get(API_URL + '/commonApi/getallowmultipleparticipations/' + LANGUAGE_CODE + '/' + surveyid)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({toggle: response.data.success.allowmultipleparticipations})
                    }
                })
                .catch((error) => {
                    console.log("error", error)
                })
        if (this.annonymousSurveySlug && !surveyid) {
            if (!this.startSurvey && !this.previewSurvey) {
                apiCall = 'getSurveyByIdentifier';
            }
            await axios.get(API_URL + '/commonApi/' + apiCall + '/' + LANGUAGE_CODE + '/' + this.annonymousSurveySlug + '/live')
                .then((response) => {
                    if (response.data.success) {
                        if (response.data.success.surveydata) {
                            if (response.data.success.surveydata.id) {
                                surveyid = parseInt(response.data.success.surveydata.id);
                                this.surveyIdMain = parseInt(response.data.success.surveydata.id);
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.log("error", error)
                })
        }
        let sessionRedirect = this.state.sessionRedirect;
        let checkParticipation = false;
        let surveydetail;
        let surveyParticipationQuestion;
        let localstorageUserid ;
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        if (storeitem && storeitem.id) {
            localstorageUserid = parseInt(storeitem.id);
        }
        if (this.title && localstorageUserid && this.startSurvey) {
            await axios.get(API_URL + '/commonApi/getSurveyBySlug/' + LANGUAGE_CODE + '/' + this.title + '/' + localstorageUserid)
                .then((response) => {
                    surveydetail = response.data.success.surveydata;
                    if (surveydetail) {
                        let surveydataNew = response.data.success.surveydata;
                        localStorage.setItem("surveydetail_" + surveyid, JSON.stringify(surveydataNew));
                    }
                })
                .catch((error) => {
                    console.log("error", error)
                })
        }
        else if (surveyid) {
            await axios.get(API_URL + '/commonApi/getSurveyById/' + LANGUAGE_CODE + '/' + surveyid + '/live')
                .then((response) => {
                    surveydetail = response.data.success.surveydata;
                    if (surveydetail) {
                        let surveydataNew = response.data.success.surveydata;
                        this.setState({is_premium_user_survey:surveydataNew.isUserHaveActivePlan})
                        localStorage.setItem("surveydetail_" + surveyid, JSON.stringify(surveydataNew));
                    }
                })
                .catch((error) => {
                    console.log("error", error)
                })
        }
        let notfilledattribute = [];
        let displayDemographicPopUp = false;
        var demographicAttribute;
        if (this.startSurvey) {
            surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + surveyid));
            if (surveydetail) {
                if (parseInt(surveydetail.isparticipate) === 1) {
                    checkParticipation = true;
                }
                let userinfo = JSON.parse(localStorage.getItem('userdata'));
                let demographic = this.state.surveydetail.demographic;
                if(userinfo !== null && parseInt(userinfo.autoprofile) === 1 && !checkParticipation && demographic) {
                    // eslint-disable-next-line
                    Object.keys(demographic).map((attribute, index) => {
                        var attributetitle = attribute;
                        var value = demographic[attribute];
                        if (attributetitle === 'age') {
                            attributetitle = 'birthday'
                        }
                        if (userinfo.hasOwnProperty(attributetitle)) {
                            const keytitle = attributetitle;
                            const attributevalue = userinfo[keytitle]
                            if (attributevalue === null || attributevalue === '' || attributevalue === undefined || attributevalue === 0) {
                                notfilledattribute.push(value);
                            }
                        }
                    })
                    if (notfilledattribute.length > 0) {
                        displayDemographicPopUp = true;
                        demographicAttribute = SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_attribute_message'];
                        // eslint-disable-next-line
                        notfilledattribute.map((msg, index) => {
                            return demographicAttribute += "<li>" + msg + "</li>";
                        })
                        
                    }
                }
         }

        }

        if(surveydetail.englishbuttonlabels == "1") {
        let englishbuttonlabels = "EN";
        await axios.get(API_URL + '/commonApi/getDemographicattribute/' + englishbuttonlabels )
            .then((response) => {         
                localStorage.setItem("allDemographic",JSON.stringify(response.data['success']));
            })
            .catch((error) => {
                console.log(error);
            });
        } else {
            localStorage.removeItem("allDemographic");
        }
        if (checkParticipation) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <div className="close-btn text-right">
                                <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close"></i></button>
                            </div>
                            <p className="mt-3 mb-4">{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_participated_title']}</p>
                            <button onClick={onClose} className="btn">{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_participated_button']}</button>
                        </div>
                    )
                }
            })
        } 
        else if (displayDemographicPopUp) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <div className="close-btn text-right">
                                <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                            </div>
                            <h2 className="mt-3 mb-4">{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_attribute_title']}</h2>
                            <p dangerouslySetInnerHTML={{ __html: demographicAttribute }}></p>
                            <button onClick={onClose} >{SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['alert_attribute_button']}</button>
                        </div>
                    )
                }
            })
        } else {
            let checkIp = false;
            if (!this.startSurvey && !this.previewSurvey) {
                let surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + surveyid));
                if (surveyid && surveydetail.hasOwnProperty('ip')) {
                    this.setState({ loading: 'true' })
                    let check = this.getCookie(surveyid)
                    if (check) {
                        checkIp = true;
                    }
                }
            }
            if (checkIp && this.state.toggle == 0) {
                this.setState({ loading: 'false', sessionRedirect })
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <div className="close-btn text-right">
                                    <button className="d-inline-block bg-white border-0 w-auto" onClick={onClose}><i className="ti-close"></i></button>
                                </div>
                                <p className="mt-3 mb-4">{SURVEY_PARTICIPATION[LANGUAGE_CODE]['cover_page_survey_already_participate_alert_msg']}</p>
                                <button onClick={onClose} className="btn btn-hover">{SURVEY_PARTICIPATION[LANGUAGE_CODE]['cover_page_survey_inactive_alert_ok_btn']}</button>
                            </div>
                        )
                    }
                })
            }
            else
                if (surveyParticipationQuestion === undefined || surveyParticipationQuestion === null) {
                    this.setState({ loading: 'true' })
                    let storeitem = JSON.parse(localStorage.getItem('userdata'));
                    let apitoken = '';
                    let userId = '';
                    if (storeitem !== null) {
                        apitoken = storeitem.token;
                        userId = storeitem.id;
                    }
                    var headers = {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + apitoken
                    }
                    let self = this;
                    var d = new Date();
                    let day = d.getDate();
                    let month = d.getMonth() + 1;
                    let year = d.getFullYear();
                    if (day < 10) {
                        day = "0" + day;
                    }
                    if (month < 10) {
                        month = "0" + month;
                    }
                    let newstartdate = year + "-" + month + "-" + day;
                    var n = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
                    var final = newstartdate + " " + n;
                    /**if survey slug is exist then call for annonymous participation otherwise call surveyparticipation */
                    if (!this.startSurvey && !this.previewSurvey) {
                        let surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + surveyid));
                        if ((parseInt(surveydetail.status) === parseInt(3)) || parseInt(surveydetail.status) === parseInt(2)) {
                            this.setState({ loading: "false" })
                            confirmAlert({
                                customUI: ({ onClose }) => {
                                    return (
                                        <div className='custom-ui'>
                                            <div className="close-btn text-right">
                                                <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close"></i></button>
                                            </div>
                                            <p className="mt-3 mb-4">{SURVEY_PARTICIPATION[LANGUAGE_CODE]['cover_page_survey_inactive_alert_msg']}</p>
                                            <button onClick={onClose} className="btn">{SURVEY_PARTICIPATION[LANGUAGE_CODE]['cover_page_survey_inactive_alert_ok_btn']}</button>
                                        </div>
                                    )
                                }
                            })
                        } else {
                            axios.get(API_URL + "/anonymoussurveyresult/getSurveyByParticipation/" + LANGUAGE_CODE + '/' + surveyid)
                                .then((response) => {
                                    surveyParticipationQuestion = response.data['success'];
                                    if (surveyParticipationQuestion) {
                                        if (surveyParticipationQuestion.surveyquestiondata) {
                                            if (surveyParticipationQuestion.surveyquestiondata.length) {
                                                // eslint-disable-next-line
                                                surveyParticipationQuestion.surveyquestiondata.map((data, index) => {
                                                    surveyParticipationQuestion.surveyquestiondata[index]['question_order_index'] = index + 1;
                                                })
                                            }
                                        }
                                    }
                                    if (surveyParticipationQuestion && surveyid) {
                                        localStorage.setItem("surveyparticipationquestion_" + surveyid, JSON.stringify(surveyParticipationQuestion));
                                    }
                                    self.setState({ loading: 'false', surveyParticipationQuestion, firstcomponent: 1, start_time: final })
                                })
                                .catch((error) => {
                                    self.setState({ loading: 'false' })
                                })
                        }
                    } else {
                        axios.get(API_URL + "/userssurvey/getSurveyByParticipation/" + LANGUAGE_CODE + '/' + surveyid + '?uId=' + userId, { headers: headers })
                            .then((response) => {
                                surveyParticipationQuestion = response.data['success'];
                                if (surveyParticipationQuestion) {
                                    if (surveyParticipationQuestion.surveyquestiondata) {
                                        if (surveyParticipationQuestion.surveyquestiondata.length) {
                                            // eslint-disable-next-line
                                            surveyParticipationQuestion.surveyquestiondata.map((data, index) => {
                                                surveyParticipationQuestion.surveyquestiondata[index]['question_order_index'] = index + 1;
                                            })
                                        }
                                    }
                                }
                                if (surveyParticipationQuestion && surveyid) {
                                    localStorage.setItem("surveyparticipationquestion_" + surveyid, JSON.stringify(surveyParticipationQuestion));
                                }
                                self.setState({ loading: 'false', surveyParticipationQuestion, firstcomponent: 1, start_time: final, sessionRedirect })
                            })
                            .catch((error) => {
                                if (error) {
                                    if (error.response) {
                                        if (error.response.data) {
                                            let msg = error.response.data['error']
                                            if (msg === 'Expired token' || msg === 'Invalid token') {
                                                localStorage.clear();
                                                sessionRedirect = true;
                                            }
                                        }
                                    }
                                }
                                self.setState({ loading: 'false', sessionRedirect })
                            })
                    }
                }
                else {
                    if (surveyid) {
                        surveyParticipationQuestion = JSON.parse(localStorage.getItem('surveyparticipationquestion_' + surveyid));
                    }
                    this.setState({ surveyParticipationQuestion, firstcomponent: 1, start_time: final, loading: 'false' })
                }
        }
    }
    changeStatus = (firstcomponent) => {
        this.setState({ firstcomponent });
    }
    section = (data) => {
        var component = data;
        var surveyid = this.surveyIdMain;
        var userid = this.userId;
        var annonymouslug = this.title;
        if (this.startSurvey) {
            let storeitem = JSON.parse(localStorage.getItem('userdata'));
            if (storeitem && storeitem.id) {
                userid = parseInt(storeitem.id);
            }
        }
        let surveydetail;
        if (surveyid) {
            surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + surveyid));
        }
        let surveydetails = '';
        let surveyParticipationQuestion = this.state.surveyParticipationQuestion;
        var slug = '';
        if (surveydetail) {
            surveydetails = surveydetail;
            slug = surveydetails.survey_title_slug
        }
        switch (component) {
            case 0:
                return < SurveyCoverPage slug={slug} onClick={this.onClickOfSurveyStart} surveyid={surveyid} userid={userid} annonymouslug={annonymouslug} previewSurvey={this.previewSurvey} startSurvey={this.startSurvey} isNormalImage = {this.state.isNormalImage} />
            case 1:
                return < SurveyOtherParticipationComponents slug={slug} isNormalImage = {this.state.isNormalImage} start_time={this.state.start_time} previewSurvey={this.previewSurvey} questionData={surveyParticipationQuestion} changeIndex={this.changeStatus} startSurvey={this.startSurvey} surveyid={surveyid} userid={userid} annonymouslug={annonymouslug} is_premium_user_survey={this.state.is_premium_user_survey} surveydetail={this.state.surveydetail}/>
            default:
        }
    }
    render() {
        let data = this.state.firstcomponent;
        const element = this.section(data);
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        // eslint-disable-next-line
        let annonymousUser = this.title;
        let token = '';
        if (storeitem !== null) {
            token = storeitem.token;
        }
        return (<div>
            {((this.previewSurvey || this.startSurvey) && (token === '')) ?
                <Redirect to="/anmelden" />
                : null
            }
            {(this.state.redirectPagenotfound === true) ?
                <Redirect to="/404"></Redirect>
                : null
            }
            {(this.state.loading === 'true') ?
                BUBBLE_LOADING
                : null
            }
            {(this.state.sessionRedirect === true) ?
                <Redirect to="/anmelden"></Redirect>
                : null
            }
            {element}
        </div>
        )
    }
}
export default SurveyParticipation;