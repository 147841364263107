import React from "react";
import Button from '@material-ui/core/Button';
class SurveyRatingWithMorePointsQuestion extends React.Component {
	
    getScaleOption = (data) => {
        var scalebutton = [];
        let survey_id_data = this.props.questiondata;
        let survey_data = JSON.parse(localStorage.getItem('surveydetail_' + survey_id_data.survey_id));
        let length = 11;
        for (let i = 1; i < length; i++) {
            scalebutton.push(
				<span className="MuiButton-effect MuiButton-effect-rating MuiButton-point">
			    <Button variant="contained" color="#00b3f4" className="box-shadow" onClick={(e) => this.labelOnClick(e)}>
				<button
                type='button'
                className={(this.props.questionanswer.option === undefined) ?
                    (this.props.questionanswer[data] === undefined) ? "default box-shadow rating-gray points-rank-button" : (parseInt(this.props.questionanswer[data]) === parseInt(i)) ? "btn-rank selected-points box-shadow points-rank-button" : (parseInt(this.props.questionanswer[data]) >= parseInt(i)) ? "btn-rank box-shadow points-rank-button" : "default box-shadow rating-gray points-rank-button"
                    : (this.props.questionanswer.option === undefined) ? "default box-shadow rating-gray points-rank-button" : (this.props.questionanswer.option.findIndex(ans => ans.option_id === data && parseInt(ans.answer) === parseInt(i)) > -1) ? "btn-rank selected-points box-shadow points-rank-button" : (this.props.questionanswer.option.findIndex(ans => ans.option_id === data && parseInt(ans.answer) >= parseInt(i)) > -1) ? "btn-rank box-shadow points-rank-button" : "default box-shadow rating-gray points-rank-button"}
                name={data}
                key={i}
                value={i}
                onClick={this.props.onButtonRating}
                style={survey_data.survey_color? {"--school-gradian": survey_data.survey_color} : {"--school-gradian": "#00b3f4"}}
            ><span class="font-weight-bold h6 mb-0 rating-grey text-nowrap" style={{textAlign: "center", margin:"auto"}}>{i}</span></button></Button></span>)
        }
        return scalebutton;
    }
	labelOnClick = (e) => {
        if (e.currentTarget.name) {
            document.getElementById(e.currentTarget.name).click();
            document.getElementById(e.currentTarget.name).checked = !(document.getElementById(e.currentTarget.name).checked);
        }
    }
    render() {
        let data = this.props.questiondata;
        // eslint-disable-next-line
        let scalebutton;
        let option = data.option.map((scale, index) => {
            return (<div key={index} className="d-flex align-items-center row mb-5"><div className="col-12 text-center mb-3 md-5"><span className="font-size font-weight-bold">{scale.option_text}</span></div><div className="d-flex align-items-center justify-content-center flex-wrap col-12">{scalebutton = this.getScaleOption(scale.id)}</div></div>)
        })
        return (<div key={this.props.questiondata.question_id} className={"tab-pane sectionstep one-ten custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
            <div className="">
                <div className="question-heading">
                    <h3 className="text-dark font-weight-bold text-break survey-title">{data.question_text}</h3>
                    {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
                </div>
                {option}
            </div>
        </div>
        )
    }
}

export default SurveyRatingWithMorePointsQuestion;