import React from 'react';
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { removeUser } from "../../actions";
import { HEADER_LINKS, URL_CONSTANT } from "../../constants/FrontEndConstant";
import { LANGUAGE_CODE, API_URL, BASEURL, IMAGE_BASEURL } from '../../constants/DotEnv';
import axios from "axios";
const mapStateToProps = state => {
  return { newuserdata: state.LogInUserData };
};
function mapDispatchToProps(dispatch) {
  return {
    removeUser: userdata => dispatch(removeUser(userdata)),
  };
}
class MainHeader extends React.Component {
  constructor() {
    super();
    this.state = {
      redirect: false,
      scrollPostion : 0,
      path_url : "empiriowissen",
      refresh: false,
    }
  }
  listenToScrollEvent = () => {
    document.addEventListener("scroll", () => {
      this.calculateScrollDistance();
    });
  };
  doRefresh = () =>
  {
    this.setState({refresh:!this.state.refresh})
  }
  calculateScrollDistance = () => {
    const totalScroll = document.documentElement.scrollTop;
    const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scroll = `${totalScroll / windowHeight *100}`;
    this.setState({
      scrollPostion:scroll
      });
  }
  getDocHeight  =  ()  =>  {
    return Math.max(
      document.body.scrollHeight,  document.documentElement.scrollHeight,
      document.body.offsetHeight,  document.documentElement.offsetHeight,
      document.body.clientHeight,  document.documentElement.clientHeight
    );
  }
  async componentDidMount() {
    this.setState({show_logo : true})
    this.listenToScrollEvent();
    // this.calculateScrollDistance();
    this.scrollUpFunction();//added for scroll progress
    window.scrollTo(0, 0);
  }
  componentWillUnmount () {
    window.removeEventListener("scroll", this.calculateScrollDistance,false);
  }
  componentDidUpdate()
  {
    if ( this.state.refresh !== this.state.refresh ) {
      this.doRefresh();
    }
  }

  scrollUpFunction = () => {
    /**for scrolling up page */
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
    if (count === -1) {
      document.querySelector('.navbar-collapse').classList.remove('show');
      document.querySelector('header').classList.remove("header_collapse");
      document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
    }
  }
  customHandleClick = async (e) => {
    let token = e.target.name;
    let storeitem = JSON.parse(localStorage.getItem('userdata'));
    let apitoken = '';
    let userid = '';
    if (storeitem !== null) {
      apitoken = storeitem.token;
      userid = storeitem.id;
    }
    var headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + apitoken
    }
    var data = {
      userid: userid,
      languagecode: LANGUAGE_CODE
    }
    this.props.removeUser({ token });
    localStorage.clear();
    this.setState({ redirect: true })
    let self = this;
    let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
    if (count === -1) {
      document.querySelector('.navbar-collapse').classList.remove('show');
      document.querySelector('header').classList.remove("header_collapse");
      document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
    }
    // eslint-disable-next-line
    await axios.post(API_URL + "/logout" + '?uId=' + userid, data, { headers: headers })
      .then((response) => {
        self.setState({ redirect: false })
      })
      .catch((error) => {
        self.setState({ redirect: false })
      })
    this.scrollUpFunction();
    return this.props.removeUser({ token });
  }
  render() {
    let path = window.location.pathname
    let sessionToken;
    let storeitem;
    storeitem = JSON.parse(localStorage.getItem('userdata'));
    let tokenCheck = '';
    let dark_logo ;
    let white_logo ;
    let account_type_category_id;
    if (storeitem !== null) {
      tokenCheck = storeitem.token;
      dark_logo = storeitem.logo_image;
      white_logo = storeitem.logo_image1;
      account_type_category_id = storeitem.account_type_category_id
    }else if( this.props.dark_logo &&  this.props.white_logo){
        dark_logo = this.props.dark_logo;
        white_logo = this.props.white_logo;
    }else{
      dark_logo = IMAGE_BASEURL + "img/empirio_main.svg";
      white_logo = IMAGE_BASEURL + "img/empirio_white.svg";
    }
    if (this.props.newuserdata.userdata.length !== 0 && tokenCheck === '') {
      sessionToken = this.props.newuserdata.userdata['0']['token']
      storeitem = this.props.newuserdata.userdata['0'];
    } else {
      sessionToken = tokenCheck;
    }
    return (
      <header>
        {(this.state.redirect === true) ?
          <Redirect to="/anmelden"></Redirect>
          : null
        }
        <nav className="navbar header-nav navbar-expand-lg">
          <div className="container container-large">
              <a className="navbar-brand" href={BASEURL}>
                <img className="light-logo mainlogo" src={white_logo} alt="empirio-logo" width="163" height="50"/>
                <img className="dark-logo" src={dark_logo}  alt="empirio-logo" width="163" height="50"/>
              </a>
            {/* /*/}
            {/* Mobile Toggle*/}
            {(sessionToken === '') ?
              (window.location.pathname.match('/empiriowissen') ?
              <Link onClick={this.scrollUpFunction} className="nav-link-btn btn btn-clr text-white m-25px-l md-m-0px-l d-none d-sm-block d-md-none ml-sm-auto ml-lg-0 mr-sm-4 mr-lg-0" to="/registrieren" title={HEADER_LINKS[LANGUAGE_CODE]['landingPage_header_button']}>{HEADER_LINKS[LANGUAGE_CODE]['landingPage_header_button']}</Link>
              :
              <Link onClick={this.scrollUpFunction} className="nav-link-btn btn btn-clr text-white m-25px-l md-m-0px-l d-none d-sm-block d-md-none ml-sm-auto ml-lg-0 mr-sm-4 mr-lg-0" to="/anmelden" title={HEADER_LINKS[LANGUAGE_CODE]['login']}>{HEADER_LINKS[LANGUAGE_CODE]['login']}</Link>)
              : null
            }
            {(sessionToken !== '') ?
              <div className="login-profile ml-auto sm-4 d-sm-block d-md-block d-lg-none">
                {/* alt="person-img" */}
                {// eslint-disable-next-line
                }<Link onClick={this.scrollUpFunction} to="/mein-bereich" title={HEADER_LINKS[LANGUAGE_CODE]['myprofile_title_label']} className="nav-link py-0 px-2"><img src={(storeitem.userimage) ? storeitem.userimage : IMAGE_BASEURL + "img/user_profile.png"} className="rounded-circle" width="408" height="414" /></Link>
                {/* <button className="nav-link-btn btn btn-clr text-white m-25px-l sm-m-0px-l" name={sessionToken} title="Abmelden" onClick={this.customHandleClick}>{HEADER_LINKS[LANGUAGE_CODE]['logout']}</button> */}
              </div>
              : null
            }
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
              <i className="ti-menu text-white"></i>
            </button>
            {/* /*/}
            {/* Top Menu*/}
            <div className="collapse navbar-collapse justify-content-end" id="navbar">
              <ul className="navbar-nav align-items-center">
                <li className="m-dropdown m-dropdown-multilevel">
                  <Link onClick={()=>{this.scrollUpFunction(); this.doRefresh()}} className="nav-link" to="/funktionen" title={HEADER_LINKS[LANGUAGE_CODE]['features']}>{HEADER_LINKS[LANGUAGE_CODE]['features']}</Link>
                </li>
                <li>
                  <Link onClick={()=>{this.scrollUpFunction(); this.doRefresh()}} className="nav-link" to={"/" + URL_CONSTANT['survey_list']} title={HEADER_LINKS[LANGUAGE_CODE]['survey']}>{HEADER_LINKS[LANGUAGE_CODE]['survey']}</Link>
                </li>
                {account_type_category_id !== 4 &&
                  <li>
                    <Link onClick={()=>{this.scrollUpFunction(); this.doRefresh()}} className="nav-link" to={"/" + URL_CONSTANT['survey_result']} title={HEADER_LINKS[LANGUAGE_CODE]['survey_result']}>{HEADER_LINKS[LANGUAGE_CODE]['survey_result']}</Link>
                  </li>
                }
                <li>
                  <Link onClick={()=>{this.scrollUpFunction(); this.doRefresh()}} className="nav-link" to={"/" + URL_CONSTANT['empiriowissen']} title={HEADER_LINKS[LANGUAGE_CODE]['empirio_knowledge']}>{HEADER_LINKS[LANGUAGE_CODE]['empirio_knowledge']}</Link>
                </li>
                {(sessionToken === '') ?
                  (window.location.pathname.match('/empiriowissen') ?
                  <li className="d-block d-sm-none d-md-block d-lg-none d-lg-block" >
                    <Link onClick={()=>{this.scrollUpFunction(); this.doRefresh()}} className="nav-link nav-link-btn btn btn-clr text-white m-25px-l sm-m-0px-l btn-login-margin" to="/registrieren" title={HEADER_LINKS[LANGUAGE_CODE]['landingPage_header_button']}>{HEADER_LINKS[LANGUAGE_CODE]['landingPage_header_button']}</Link>
                  </li>
                  : <><li>
                  <a href={"/umfrage-unternehmen"}><button type="button" name="for_companies" className=" btn m-25px-l btn-outline-white_community" id="voucher_code" title={HEADER_LINKS[LANGUAGE_CODE]['for_companies']} style={{margin: "12px 0px 12px 10px"}}>{HEADER_LINKS[LANGUAGE_CODE]['for_companies']}</button></a>
                  </li>
                  <li className="d-block d-sm-none d-md-block d-lg-none d-lg-block" >
                    <Link onClick={()=>{this.scrollUpFunction(); this.doRefresh()}} className="nav-link nav-link-btn btn btn-clr text-white m-25px-l sm-m-0px-l btn-login-margin" to="/anmelden" title={HEADER_LINKS[LANGUAGE_CODE]['login']}>{HEADER_LINKS[LANGUAGE_CODE]['login']}</Link>
                  </li></>
                  )
                  :
                  <li className="login-profile d-none d-lg-block">
                    {// eslint-disable-next-line
                    }<Link to="/mein-bereich" onClick={()=>{this.scrollUpFunction(); this.doRefresh()}} title={HEADER_LINKS[LANGUAGE_CODE]['myprofile_title_label']} className="nav-link"><img src={(storeitem.userimage) ? storeitem.userimage : IMAGE_BASEURL + "img/user_profile.png"} className="rounded-circle" /></Link>
                  </li>
                }
                {(sessionToken !== '') ?
                  <li>
                    <Link to="/anmelden" className="nav-link nav-link-btn btn btn-clr text-white m-25px-l sm-m-0px-l btn-login-margin" name={sessionToken} title={HEADER_LINKS[LANGUAGE_CODE]['logout']} onClick={this.customHandleClick}>{HEADER_LINKS[LANGUAGE_CODE]['logout']}</Link>
                  </li>
                  : null
                }
              </ul>
            </div>
            {/* /*/}
          </div>{/* Container*/}
          {this.state.scrollPostion != 0 && this.state.scrollPostion !== 'NaN' && this.state.scrollPostion !== 'undefined' &&  window.location.pathname.split('/')['1'] == this.state.path_url &&  window.location.pathname.split('/')['2'] !=null ?
           <div id="progressBarContainer">
                <div class="progress-blue-line">
                    <span style={{ "width": this.state.scrollPostion + "%" }}></span>
                </div>
            </div>:null
          }
        </nav> {/* Navbar*/}
      </header>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);