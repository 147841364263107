let apiUrl: string       = 'https://admin.empirio.de/api';
let baseUrl: string      = 'https://www.empirio.de/';
let imageBaseUrl: string = 'https://images.empirio.de/';

if (process.env.REACT_APP_ENV === 'production') {
    apiUrl       = 'https://admin.empirio.de/api';
    baseUrl      = 'https://www.empirio.de/';
    imageBaseUrl = 'https://images.empirio.de/';
}

if (process.env.REACT_APP_ENV === 'qa') {
    apiUrl       = 'https://admin.qa4ja.empirio.de/api';
    baseUrl      = 'https://qa4ja.empirio.de/';
    imageBaseUrl = 'https://images.qa4ja.empirio.de/';
}

if (process.env.REACT_APP_ENV === 'dev') {
    apiUrl       = 'https://admin.dev43xh.empirio.de/api';
    baseUrl      = 'https://dev43xh.empirio.de/';
    imageBaseUrl = 'https://images.dev43xh.empirio.de/';
}

export const API_URL = apiUrl;
export const BASEURL = baseUrl;
export const IMAGE_BASEURL = imageBaseUrl;
export const CHART_API = 'http://localhost:5000/';
export const INSTAGEAM_URL = 'https://www.instagram.com/empirio.de';
export const FACEBOOK_URL = 'https://www.mitarbeiterbefragungen.net';//https://www.facebook.com/EmpirioDE/';
export const TIKTOK_URL = 'https://www.gothesis.de/themen';//'https://www.tiktok.com/@empirio.de';
export const UMFRAG_RATGEBER_URL = 'https://www.online-umfrage.org/';
export const FRONT_USER = '/frontuser';
export const MESSAGE_TIME = 15000;
export const LANGUAGE_CODE = 'DE';
export const LIMIT = '12';
export const OFFSET = '0';
export const RANKING_MAX_VALUE = 10;
export const META_TITLE_APPEND = ' | empirio';
export const META_TITLE_HOME_PREPEND = 'empirio - ';
export const META_TITLE_STATISTICS_PREPEND = '[Statistiken] ';
export const SITEKEY = '6LeA-qsUAAAAALoKOAk0FbLqr_5yq2slRAEVhKAD';
export const RECAPTCHA_LANGUAGE = 'de';
export const UrlConstant = {
    header: ['umfrage-erstellen', 'surveyparticipation', 'meine-umfrage', 'survey', 'c3VydmV5', 'nsu', 'registrieren',],
    footer: ['umfrage-erstellen', 'surveyparticipation', 'meine-umfrage', 'survey', 'c3VydmV5', '/c3VydmV5'],
}
export const MONTHLY_AMOUNT= localStorage.getItem('monthly_plan');
export const ANNUAL_AMOUNT= localStorage.getItem('quarterly_plan');

