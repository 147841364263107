import React from "react";
import Button from '@material-ui/core/Button';
class SurveyRatingWithFreeTextQuestion extends React.Component {
  constructor(props) {
    super(props);
  }
  labelOnClick = (e) => {
    if (e.currentTarget.name) {
      document.getElementById(e.currentTarget.name).click();
      document.getElementById(e.currentTarget.name).checked = !(document.getElementById(e.currentTarget.name).checked);
    }
  }
  render() {
    let data = this.props.questiondata;
    let surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + data.survey_id));
    let option = data.option.map((option, index) => {
      return (
        <div className='col-12 pb-3 mb-0 full_width_option'>
          <div className="custom-radio-btn w-100">
            <div className="custom-control custom-radio custom-control-inline pl-0 w-100">
              <input
                type="radio"
                id={option.id}
                className={
                  this.props.questionanswer.option === undefined
                    ? Object.keys(this.props.questionanswer).length === 0
                      ? "btn-grade box-shadow rating-gray custom-control-input singleselection"
                      : parseInt(this.props.questionanswer[data.question_id]) ===
                        parseInt(option.id)
                        ? "btn-grade-clr box-shadow custom-control-input singleselection"
                        : "btn-grade box-shadow rating-gray custom-control-input singleselection"
                    : this.props.questionanswer.option === undefined
                      ? "btn-grade box-shadow rating-gray custom-control-input singleselection"
                      : parseInt(this.props.questionanswer.option["option_id"]) ===
                        parseInt(option.id)
                        ? "btn-grade-clr box-shadow custom-control-input singleselection"
                        : "btn-grade box-shadow rating-gray custom-control-input singleselection"
                }
                checked={
                  this.props.questionanswer.option === undefined
                    ? Object.keys(this.props.questionanswer).length === 0
                      ? false
                      : parseInt(this.props.questionanswer[data.question_id]) ===
                        parseInt(option.id)
                        ? true
                        : false
                    : this.props.questionanswer.option === undefined
                      ? false
                      : parseInt(this.props.questionanswer.option["option_id"]) ===
                        parseInt(option.id)
                        ? true
                        : false
                }
                name={data.question_id}
                key={index}
                value={option.id}
                onClick={this.props.onSchoolGrade}
              />
              <label className="custom-control-label mb-0 box-shadow p-0 MuiButton-effect" for={option.id}>
                <Button className=" custom-free-btn border-0 text-left" type="button" variant="contained" color="#00b3f4" name={option.id} onClick={(e) => this.labelOnClick(e)}>
                  <span className="font-weight-normal" style={{ "--freetext-color": surveydetail.survey_color? surveydetail.survey_color : "#00b3f4" }}>{option.option_text}</span>
                </Button>
              </label>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div key={this.props.questiondata.question_id} className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
        <div className="survey-form survey-rating-free col-11 col-sm-10 col-md-7 col-lg-5 px-0">
          <div className="question-heading">
            <h3 className="text-dark font-weight-bold text-break survey-title">
              {data.question_text}
            </h3>
            {this.props.errormessage !== undefined ? (
              <div id="errorSurveyMessage" className="error-text">
                <p>{this.props.errormessage}</p>
              </div>
            ) : null}
          </div>
          <div className="free_text d-flex flex-wrap three-row-button row">
            {option}
          </div>
        </div>
      </div>
    );
  }
}
export default SurveyRatingWithFreeTextQuestion;
