import React, { Fragment } from 'react';
import Redirect from 'react-router-dom/Redirect';
import { SURVEY_CREATION_CONSTANT, BUBBLE_LOADING } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
class SurveyEnd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            allQuestionData: [],
            optionValue: '',
            loading: 'false',
            redirect: 'false'
        }
    }
    /** add survey_id to surveycreationeditid in localstorage */
    addIdToLocalstorage = () => {
        let surveycreationdata = JSON.parse(localStorage.getItem('surveycreationdata'))
        if (surveycreationdata) {
            let survey_title = surveycreationdata.survey_title;
            let id = surveycreationdata.id;
            let surveycreationeditid = { "id": id, "survey_title": survey_title }
            localStorage.setItem(('surveycreationeditid'), JSON.stringify(surveycreationeditid))
        }
    }

    render() {
        return (
            <Fragment>
                {(this.state.redirect === 'true') ?
                    <Redirect to="/anmelden"></Redirect>
                    : null
                }
                {(!this.props.value) ?
                    BUBBLE_LOADING
                    :
                    <div>
                        {(this.props.value) ? <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle class="path-new circle-new" fill="none" stroke="#4a4a4a" stroke-width="10" stroke-miterlimit="10" cx="65.1" cy="65.1" r="60" />
                            <polyline class="path-new check-new" fill="none" stroke="#4a4a4a" stroke-width="10" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                        </svg>
                        : null
                        }
                        <h2 className="text-dark h3 font-weight-bold survey-title text-center">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_end_page_title']}</h2>
                        <h3 className="font-14 lh-24 fw-400 survey-subtitle text-center">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_end_page_subtitle']}</h3>
                        <div id="errorSurveyMessage" className="error-text"></div>
                    </div>
                }

            </Fragment>
        );
    }
}
export default SurveyEnd;