import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { removeUser } from "../../actions";
import { BASEURL } from "../../constants/DotEnv";
const mapStateToProps = (state) => {
  return { newuserdata: state.LogInUserData };
};
function mapDispatchToProps(dispatch) {
  return {
    removeUser: (userdata) => dispatch(removeUser(userdata)),
  };
}
class MainHeader extends React.Component {
  constructor() {
    super();
    this.state = {
      redirect: false,
    };
  }
  redirectCall = () => {
    window.location.href = localStorage.getItem('redirectURL')
    if(!localStorage.getItem('redirectURL')){
      window.location.href = BASEURL + "mein-bereich"
    }
  }
  render() {
    let sessionToken;
    let storeitem;
    storeitem = JSON.parse(localStorage.getItem("userdata"));
    let tokenCheck = "";
    if (storeitem !== null) {
      tokenCheck = storeitem.token;
    }
    if (this.props.newuserdata.userdata.length !== 0 && tokenCheck === "") {
      sessionToken = this.props.newuserdata.userdata["0"]["token"];
      storeitem = this.props.newuserdata.userdata["0"];
    } else {
      sessionToken = tokenCheck;
    }
    return (
      <header>
        {this.state.redirect === true ? <Redirect to="/anmelden" /> : null}
        <nav className="navbar header-nav navbar-expand-lg">
          <div className="container container-large">
            <a className="navbar-brand" href={BASEURL}>
              <img
                className="light-logo mainlogo"
                src={storeitem.logo_image1}
                alt="empirio-logo"
                width="163" height="50"
              />
              <img
                className="dark-logo"
                src={storeitem.logo_image}
                alt="empirio-logo"
                width="163" height="50"
              />
            </a>
            <div className="justify-content-end" id="navbar">
              <span className="payment-bar">
                <ul className="navbar-nav align-items-center">
                  <li className="m-dropdown m-dropdown-multilevel">
                    <span className="nav-link color" style={{fontSize:'1.7rem'}}>
                    <i onClick={this.redirectCall} className="ti-close ti-close-participation"/>
                    </span>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(MainHeader);
