import React from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import AbountUsHeader from '../subPageHeader/SubPageHeader';
import { BUBBLE_LOADING, META_FACEBOOK, BREADCRUMB_SCHEMA } from '../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, META_TITLE_APPEND } from '../../constants/DotEnv';
class AboutUs extends React.Component {
    constructor() {
        super();
        this.state = {
            aboutcontent: {},
            loading: 'false',
            dataLength: '',
            title: '',
            count_numbers: 0
        }
    }
    componentWillUnmount() {
        setTimeout(() => {
            window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
            window.removeSchema();
        }, 100)
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.aboutuscontent();
        setTimeout(() => {
            var schema2 = window.positionCreate(BREADCRUMB_SCHEMA['aboutus']['schema2']['position'], BREADCRUMB_SCHEMA['aboutus']['schema2']['url'], BREADCRUMB_SCHEMA['aboutus']['schema2']['name']);
            var schemaObject = window.schemaObj(BREADCRUMB_SCHEMA['schema1'], schema2, BREADCRUMB_SCHEMA['aboutus']['schema3']);
            window.setSchema(schemaObject);
            window.addEventListener('scroll',this.counterData)
        }, 500)
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
        if (count === -1) {
            document.querySelector('.navbar-collapse').classList.remove('show');
            document.querySelector('header').classList.remove("header_collapse");
            document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
        }
    }
    aboutuscontent = () => {
        this.setState({ loading: 'true' });
        let aboutcontent = '';
        let dataLength = '';
        let title = '';
        let meta_title = '';
        let meta_description = '';
        let meta_keywords = '';
        let og_title = '';
        let og_description = '';
        let og_image = '';
        let self = this;
        axios.get(API_URL + '/cms/getCmsByID/4/' + LANGUAGE_CODE)
            .then((response) => {
                aboutcontent = response.data.success.cms;
                dataLength = Object.keys(aboutcontent).length;
                title = aboutcontent.aboutuscms[LANGUAGE_CODE]['title'];
                meta_title = aboutcontent.aboutuscms[LANGUAGE_CODE]['title'] + META_TITLE_APPEND;
                meta_description = aboutcontent.aboutuscms[LANGUAGE_CODE]['meta_description'];
                meta_keywords = aboutcontent.aboutuscms[LANGUAGE_CODE]['meta_keyword'];
                og_title = aboutcontent.aboutuscms[LANGUAGE_CODE]['title'] + META_TITLE_APPEND;
                og_description = aboutcontent.aboutuscms[LANGUAGE_CODE]['meta_description'];
                og_image = META_FACEBOOK['og_image'];
                window.setMeta({ "description": meta_description, "keywords": meta_keywords });
                window.setOgTag(og_title, og_description, og_image, window.location.href);
                window.setDocumentTitle(meta_title)
                self.setState({ loading: 'false', aboutcontent, dataLength, title })
            })
            .catch((error) => {
                self.setState({ loading: 'false' })
            })
    }
    counterData = () => {
        for(let i=1;i<= 4;i++){
            this.animateValue(i);
        }
    }
    //Added for conter-up animation
    animateValue = (i)  =>{
        var section = document.getElementsByClassName("our-motivation")
        var hasEntered = false;
                var shouldAnimate = (window.scrollY + window.innerHeight) >= section[0].offsetTop;
                    if (shouldAnimate && !hasEntered) {
                        hasEntered = true;
                        let obj = document.getElementById("count"+i)
                            var data = obj.innerHTML
                            var end_count = data.replace(/[.]+/g,"");
                            let startTimestamp = null;
                            const step = (timestamp) => {
                                if(!startTimestamp) startTimestamp = timestamp;
                                    const progress = Math.min((timestamp - startTimestamp) / 3000, 1);
                                    this.setState({count_numbers : Math.floor(progress * (end_count - 0) + 0)})
                                    obj.innerHTML = this.state.count_numbers
                                if(progress < 1){
                                    window.requestAnimationFrame(step)
                                    window.removeEventListener('scroll',this.counterData, false)
                                }
                                if(progress == 1){
                                    obj.innerHTML = data
                                }
                        };
                    window.requestAnimationFrame(step);
                }
    };
    render() {
        let length = Object.keys(this.state.aboutcontent).length;
        let aboutdata = this.state.aboutcontent;
        return (
            <main>
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {/* abous us banner section */}
                <AbountUsHeader data={aboutdata} title={this.state.title} dataLength={this.state.dataLength} />
                {/* abous us banner section end */}
                {/* idea-content section */}
                {(length > 0 && this.state.loading === 'false') ?
                    <div>
                        <section className="idea-content bg-gray">
                            <div className="container">
                                <div className="row no-gutters">
                                    <div className="col-nd-12">
                                        <h2 className="text-dark font-weight-bold mb-0">{aboutdata.aboutuscms[LANGUAGE_CODE]['section1_title']}</h2>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-12">
                                        <p className="text-dark font-weight-normal font-18" dangerouslySetInnerHTML={{ __html: aboutdata.aboutuscms[LANGUAGE_CODE]['section1_text'] }}></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* idea-content section end */}
                        {/* our-motivation section  */}
                        <section className="our-motivation bg-white">
                            <div className="container">
                                <div className="row no-gutters">
                                    <div className="col-nd-12">
                                        <h2 className="text-dark font-weight-bold mb-0">{aboutdata.aboutuscms[LANGUAGE_CODE]['section2_title']}</h2>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-12">
                                        <p className="text-dark font-weight-normal font-18" dangerouslySetInnerHTML={{ __html: aboutdata.aboutuscms[LANGUAGE_CODE]['section2_text'] }}></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* our-motivation section end  */}
                        {/* our-team section  */}
                        <section className="our-team-section bg-gray">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="text-dark font-weight-bold mb-0">{aboutdata.aboutuscms[LANGUAGE_CODE]['team_title']}</h2>
                                    </div>
                                    </div>
                                    <div className="row team-member-detail">
                                    <div className="col-sm-6 col-md-4 col-lg-3 text-center">
                                        <div className="team-member-detail-content">
                                        <div className="team-member-img">
                                            <img
                                            src={aboutdata.aboutuscms["team1_image"]}
                                            className="rounded-circle mx-auto"
                                            alt={
                                                aboutdata.aboutuscms[LANGUAGE_CODE][
                                                "team1_image_alt_text"
                                                ]
                                                ? aboutdata.aboutuscms[LANGUAGE_CODE][
                                                    "team1_image_alt_text"
                                                    ]
                                                : "team"
                                            }
                                            />
                                        </div>
                                        <h3 className="text-dark font-weight-bold mt-3">
                                            {aboutdata.aboutuscms[LANGUAGE_CODE]["team1_title"]}
                                        </h3>
                                        <p
                                            className="text-dark mb-0 font-weight-normal font-18"
                                            dangerouslySetInnerHTML={{
                                            __html:
                                                aboutdata.aboutuscms[LANGUAGE_CODE]["team1_text"],
                                            }}
                                        ></p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3 text-center mt-5 mt-sm-0">
                                        <div className="team-member-detail-content">
                                        <div className="team-member-img">
                                            <img
                                            src={aboutdata.aboutuscms["team2_image"]}
                                            className="rounded-circle mx-auto"
                                            alt={
                                                aboutdata.aboutuscms[LANGUAGE_CODE][
                                                "team2_image_alt_text"
                                                ]
                                                ? aboutdata.aboutuscms[LANGUAGE_CODE][
                                                    "team2_image_alt_text"
                                                    ]
                                                : "team"
                                            }
                                            />
                                        </div>
                                        <h3 className="text-dark font-weight-bold mt-3">
                                            {aboutdata.aboutuscms[LANGUAGE_CODE]["team2_title"]}
                                        </h3>
                                        <p
                                            className="text-dark mb-0 font-weight-normal font-18"
                                            dangerouslySetInnerHTML={{
                                            __html:
                                                aboutdata.aboutuscms[LANGUAGE_CODE]["team2_text"],
                                            }}
                                        ></p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3 text-center mt-5 mt-sm-5 mt-md-0">
                                        <div className="team-member-detail-content">
                                        <div className="team-member-img">
                                            <img
                                            src={aboutdata.aboutuscms["team3_image"]}
                                            className="rounded-circle mx-auto"
                                            alt={
                                                aboutdata.aboutuscms[LANGUAGE_CODE][
                                                "team3_image_alt_text"
                                                ]
                                                ? aboutdata.aboutuscms[LANGUAGE_CODE][
                                                    "team3_image_alt_text"
                                                    ]
                                                : "team"
                                            }
                                            />
                                        </div>
                                        <h3 className="text-dark font-weight-bold mt-3">
                                            {aboutdata.aboutuscms[LANGUAGE_CODE]["team3_title"]}
                                        </h3>
                                        <p
                                            className="text-dark mb-0 font-weight-normal font-18"
                                            dangerouslySetInnerHTML={{
                                            __html:
                                                aboutdata.aboutuscms[LANGUAGE_CODE]["team3_text"],
                                            }}
                                        ></p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3 text-center mt-5 mt-sm-5 mt-md-5 mt-lg-0">
                                        <div className="team-member-detail-content">
                                        <div className="team-member-img">
                                            <img
                                            src={aboutdata.aboutuscms["team4_image"]}
                                            className="rounded-circle mx-auto"
                                            alt={
                                                aboutdata.aboutuscms[LANGUAGE_CODE][
                                                "team4_image_alt_text"
                                                ]
                                                ? aboutdata.aboutuscms[LANGUAGE_CODE][
                                                    "team4_image_alt_text"
                                                    ]
                                                : "team"
                                            }
                                            />
                                        </div>
                                        <h3 className="text-dark font-weight-bold mt-3">
                                            {aboutdata.aboutuscms[LANGUAGE_CODE]["team4_title"]}
                                        </h3>
                                        <p
                                            className="text-dark mb-0 font-weight-normal font-18"
                                            dangerouslySetInnerHTML={{
                                            __html:
                                                aboutdata.aboutuscms[LANGUAGE_CODE]["team4_text"],
                                            }}
                                        ></p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3 text-center mt-5 mt-sm-5">
                                        <div className="team-member-detail-content">
                                        <div className="team-member-img">
                                            <img
                                            src={aboutdata.aboutuscms["team5_image"]}
                                            className="rounded-circle mx-auto"
                                            alt={
                                                aboutdata.aboutuscms[LANGUAGE_CODE][
                                                "team5_image_alt_text"
                                                ]
                                                ? aboutdata.aboutuscms[LANGUAGE_CODE][
                                                    "team5_image_alt_text"
                                                    ]
                                                : "team"
                                            }
                                            />
                                        </div>
                                        <h3 className="text-dark font-weight-bold mt-3">
                                            {aboutdata.aboutuscms[LANGUAGE_CODE]["team5_title"]}
                                        </h3>
                                        <p
                                            className="text-dark mb-0 font-weight-normal font-18"
                                            dangerouslySetInnerHTML={{
                                            __html:
                                                aboutdata.aboutuscms[LANGUAGE_CODE]["team5_text"],
                                            }}
                                        ></p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3 text-center mt-5 mt-sm-5">
                                        <div className="team-member-detail-content">
                                        <div className="team-member-img">
                                            <img
                                            src={aboutdata.aboutuscms["team6_image"]}
                                            className="rounded-circle mx-auto"
                                            alt={
                                                aboutdata.aboutuscms[LANGUAGE_CODE][
                                                "team6_image_alt_text"
                                                ]
                                                ? aboutdata.aboutuscms[LANGUAGE_CODE][
                                                    "team6_image_alt_text"
                                                    ]
                                                : "team"
                                            }
                                            />
                                        </div>
                                        <h3 className="text-dark font-weight-bold mt-3">
                                            {aboutdata.aboutuscms[LANGUAGE_CODE]["team6_title"]}
                                        </h3>
                                        <p
                                            className="text-dark mb-0 font-weight-normal font-18"
                                            dangerouslySetInnerHTML={{
                                            __html:
                                                aboutdata.aboutuscms[LANGUAGE_CODE]["team6_text"],
                                            }}
                                        ></p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3 text-center mt-5 mt-sm-5">
                                        <div className="team-member-detail-content">
                                        <div className="team-member-img">
                                            <img
                                            src={aboutdata.aboutuscms["team7_image"]}
                                            className="rounded-circle mx-auto"
                                            alt={
                                                aboutdata.aboutuscms[LANGUAGE_CODE][
                                                "team7_image_alt_text"
                                                ]
                                                ? aboutdata.aboutuscms[LANGUAGE_CODE][
                                                    "team7_image_alt_text"
                                                    ]
                                                : "team"
                                            }
                                            />
                                        </div>
                                        <h3 className="text-dark font-weight-bold mt-3">
                                            {aboutdata.aboutuscms[LANGUAGE_CODE]["team7_title"]}
                                        </h3>
                                        <p
                                            className="text-dark mb-0 font-weight-normal font-18"
                                            dangerouslySetInnerHTML={{
                                            __html:
                                                aboutdata.aboutuscms[LANGUAGE_CODE]["team7_text"],
                                            }}
                                        ></p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3 text-center mt-5 mt-sm-5">
                                        <div className="team-member-detail-content">
                                        <div className="team-member-img">
                                            <img
                                            src={aboutdata.aboutuscms["team8_image"]}
                                            className="rounded-circle mx-auto"
                                            alt={
                                                aboutdata.aboutuscms[LANGUAGE_CODE][
                                                "team8_image_alt_text"
                                                ]
                                                ? aboutdata.aboutuscms[LANGUAGE_CODE][
                                                    "team8_image_alt_text"
                                                    ]
                                                : "team"
                                            }
                                            />
                                        </div>
                                        <h3 className="text-dark font-weight-bold mt-3">
                                            {aboutdata.aboutuscms[LANGUAGE_CODE]["team8_title"]}
                                        </h3>
                                        <p
                                            className="text-dark mb-0 font-weight-normal font-18"
                                            dangerouslySetInnerHTML={{
                                            __html:
                                                aboutdata.aboutuscms[LANGUAGE_CODE]["team8_text"],
                                            }}
                                        ></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* our-team section end  */}
                        {/* our-wish-section */}
                        <section className="our-wish bg-white">
                            <div className="container">
                                <div className="row no-gutters">
                                    <div className="col-nd-12">
                                        <h2 className="text-dark font-weight-bold mb-0">{aboutdata.aboutuscms[LANGUAGE_CODE]['bottom_section_title']}</h2>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-12">
                                        <p className="text-dark font-weight-normal font-18" dangerouslySetInnerHTML={{ __html: aboutdata.aboutuscms[LANGUAGE_CODE]['bottom_section_text'] }}></p>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-12 text-center contact-us">
                                        <Link to={aboutdata.aboutuscms['required_btn_link']} className="btn btn-clr text-uppercase text-white font-18" title={aboutdata.aboutuscms[LANGUAGE_CODE]['required_btn_text']}>{aboutdata.aboutuscms[LANGUAGE_CODE]['required_btn_text']}</Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* our-wish-section-end */}
                    </div>
                    : null
                }
            </main>
            //{/* main end */}
        );
    }
}
export default AboutUs;
