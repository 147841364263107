import React from 'react';
import FaqContent from './FaqContent';
import FaqHeader from '../subPageHeader/SubPageHeader';
import axios from "axios";
import { META_TAGS_CONSTANT, META_FACEBOOK, BREADCRUMB_SCHEMA } from "../../constants/FrontEndConstant";
import { LANGUAGE_CODE, API_URL, META_TITLE_APPEND } from '../../constants/DotEnv';
class Faq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: 'false',
            dataLength: '',
            title: '',
            sub_title: ''
        };
    }
    componentWillUnmount() {
        setTimeout(() => {
            window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
            window.removeFaqItemtype();
            window.removeSchema();
        }, 100)
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.faqContentfunction();
        setTimeout(() => {
            var schema2 = window.positionCreate(BREADCRUMB_SCHEMA['faq']['schema2']['position'], BREADCRUMB_SCHEMA['faq']['schema2']['url'], BREADCRUMB_SCHEMA['faq']['schema2']['name']);
            var schemaObject = window.schemaObj(BREADCRUMB_SCHEMA['schema1'], schema2, BREADCRUMB_SCHEMA['faq']['schema3']);
            window.setSchema(schemaObject);
        }, 100)
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (document.querySelector('.navbar-toggler')) {
            let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
            if (count === -1) {
                document.querySelector('.navbar-collapse').classList.remove('show');
                document.querySelector('header').classList.remove("header_collapse");
                document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
            }
        }
    }
    _handleClick = (e) => {
        var id = e.target.id
        var element = document.getElementById(id);
        element.classList.toggle("active");
        var panel = document.getElementById(id).nextSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
        e.preventDefault();
    }
    faqContentfunction() {
        this.setState({ loading: 'true' })
        var headers = {
            "Content-Type": "application/json"
        }
        let data = '';
        let dataLength = '';
        let title = '';
        let sub_title = '';
        let self = this;
        let meta_title = "";
        let meta_description = "";
        let meta_keywords = "";
        let og_title = '';
        let og_description = '';
        let og_image = META_FACEBOOK['og_image'];
        axios.get(API_URL + "/faq/getAllFaq/" + LANGUAGE_CODE, { headers: headers })
            .then((response) => {
                data = response.data['success'].faq;
                title = response.data['success']['title'];
                sub_title = response.data['success']['sub_title'];
                dataLength = data.length;
                meta_title = response.data['success']['title'] + META_TITLE_APPEND;
                meta_description = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_description']
                meta_keywords = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords']
                og_title = response.data['success']['title'] + META_TITLE_APPEND;
                og_description = META_FACEBOOK['og_description'];
                window.setMeta({ "description": meta_description, "keywords": meta_keywords });
                window.setOgTag(og_title, og_description, og_image, window.location.href)
                window.setDocumentTitle(meta_title)
                window.setFaqItemtype();
                self.setState({ data, loading: 'false', dataLength, title, sub_title })
            })
            .catch((error) => {
                self.setState({ loading: 'false' })
            })
    }
    render() {
        let data = this.state.data;
        return (
            <main>
                <FaqHeader data={data} title={this.state.title} sub_title={this.state.sub_title} dataLength={this.state.dataLength} />
                <FaqContent data={data} onClick={this._handleClick} loading={this.state.loading} />
            </main>
        );
    }
}
export default Faq;