import React, { Fragment } from 'react';
import axios from "axios";
import Redirect from 'react-router-dom/Redirect';
import SurveyWelcomeText from "../surveyWelcomeText/SurveyWelcomeText.js";
import Slider from '@material-ui/core/Slider';
import { SURVEY_CREATION_CONSTANT, SCHOOL_GRADE_SCALE_CONSTANT, SURVEY_APPROVAL_QUESTION_ANSWER, BUBBLE_LOADING } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL } from '../../../../constants/DotEnv';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
class SurveyCreatingConditions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            allQuestionData: [],
            optionValue: '',
            loading: 'false',
            redirect: 'false',
            dropdownText : false,
            redirectToCheckout: false,
        }
        this.getAllDemographic()
    }
    async getAllDemographic() {
        let surveyAllDemographic = JSON.parse(localStorage.getItem('surveyalldemographic'));
        if (surveyAllDemographic) {
            surveyAllDemographic = surveyAllDemographic.demographicattribute;
        }
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let userid = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
            userid = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        let self = this;
        if (!surveyAllDemographic && storeitem !== null) {
            await axios.get(API_URL + '/survey/getDemographicattribute/' + LANGUAGE_CODE + '?uId=' + userid, { headers: headers })
                .then((response) => {
                    let responseNew = '';
                    responseNew = response.data['success'];
                    surveyAllDemographic = responseNew.demographicattribute;
                    self.setState({ surveyAllDemographic, loading: 'false' })
                    localStorage.setItem("surveyalldemographic", JSON.stringify(responseNew));
                })
                .catch((error) => {
                    if (error) {
                        let msg = error.response.data['error']
                        if (msg === 'Expired token' || msg === 'Invalid token') {
                            localStorage.clear();
                            self.setState({ redirect: 'true' })
                        }
                    }
                    self.setState({ loading: 'false' })
                })
        }
    }
    componentDidMount() {
        this.getQuestionScaleType();
        let eles = document.getElementsByClassName("acco-heading");
        setTimeout(() => {
            Array.from(eles).forEach(element => {
                element.addEventListener("click", this._handleClick)
            })
        }, 2000);
        if (this.props) {
            if (this.props.checkAddQuestion) {
                this.props.checkAddQuestion = false;
            }
            if (this.props.questionIndexUpdated) {
                this.props.questionIndexUpdated = false;
            }
        }
        let servayQutionData = this.props.value
        if (servayQutionData.demographic){
            this.setState({dropdownText : true})
        }
    }

    saveConditionData = async () => {
        this.props.saveCondition();
    }
    clearLocalstorageForLiveTest = async () => {
        if (this.surveyMainId) {
            localStorage.removeItem('surveyparticipationquestion_' + this.surveyMainId);
            localStorage.removeItem('surveydetail_' + this.surveyMainId)
        }
    }
    accordionData = (e) => {
        let id = e.currentTarget.name;
        let icon = "icon_" + e.currentTarget.value;
        var x = document.getElementById(id);
        document.getElementById(id).classList.toggle('visible')
        document.getElementById("condition_" + e.currentTarget.value).classList.toggle('change-bottom-border-accordian');
        document.getElementById(icon).classList.toggle('fa-map-signs');
        document.getElementById(icon).classList.toggle('fa-minus');      
        // if (x.className.indexOf("hidden") !== -1) {
        //     document.getElementById(id).classList.remove('d-none');
        //     document.getElementById(id).classList.remove('hidden')
        //     document.getElementById(id).classList.add('visible')
        //     document.getElementById("condition_" + e.currentTarget.value).classList.add('change-bottom-border-accordian');
        //     document.getElementById(icon).classList.remove('fa-map-signs');
        //     document.getElementById(icon).classList.add('fa-minus');
        // } else {
        //     document.getElementById(id).classList.remove('d-none');
        //     document.getElementById(id).classList.add('hidden')
        //     document.getElementById(id).classList.remove('visible')
        //     document.getElementById("condition_" + e.currentTarget.value).classList.remove('change-bottom-border-accordian');
        //     document.getElementById(icon).classList.remove('fa-minus');
        //     document.getElementById(icon).classList.add('fa-map-signs');
        // }
        var panel = document.getElementById("condition_" + e.currentTarget.value).nextSibling;
        if (panel) {
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.display = null;
                panel.style.maxHeight = panel.scrollHeight + "px";
            }
        }
        e.preventDefault();
    }
    displaySelectedValue = (e) => {
        this.setState({ optionValue: e.target.id })
        this.props.onClick(e);
    }
    getQuestionScaleType = () => {
        // eslint-disable-next-line
        let apitoken = '';
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let surveyCreationAllQuestionData;
        let surveyCreationData;
        if (this.surveyMainId) {
            surveyCreationAllQuestionData = JSON.parse(localStorage.getItem("surveycreationallquestiondata_" + this.surveyMainId));
            surveyCreationData = JSON.parse(localStorage.getItem('surveycreationdata_' + this.surveyMainId));
        }
        if (storeitem !== null) {
            apitoken = storeitem.token;
        }
        let allQuestionData = '';
        if (!this.props.data) {
            if (surveyCreationData && surveyCreationAllQuestionData) {
                allQuestionData = surveyCreationAllQuestionData;
                this.setState({ allQuestionData });
            }
        }
    }
    startData = (demographicQuestionData) => {
        return (
            <>{demographicQuestionData}</>
        )
    }
    dataProtection = (dataProtectionQuestion) => {
        return (
            <>{dataProtectionQuestion}</>
        )
    }
    welcomeData = (welcomePageData) => {
        return (
            <>{welcomePageData}</>
        )
    }
    allQuestionDataSection = (allQuestionDataOption, onChangeFunction, data) => {
        return (
            <>{allQuestionDataOption}</>
        );
    }
    /*-- Update data of option in queestion---*/
    changeDataFunction = (optionData, position, optionDropdownValue, optionIndex) => {
        if (optionData) {
            if (optionData[optionIndex]) {
                if (optionData[optionIndex].option_skip_question && optionDropdownValue) {
                    if (optionData[optionIndex].option_skip_question !== optionDropdownValue) {
                        delete optionData[optionIndex].option_skip_question;
                        optionData[optionIndex].option_skip_question = optionDropdownValue;
                        this.props.data[position].option = optionData;
                    }
                }
            }
        }
    }
    /**---End Update data of option in queestion--- */
    optionData = (allQuestionData, optionData, questionData, position, totalLength) => {
        let option = [];
        let optionDropdown = [];
        let questionConditionOption = [];
        let optionThumb = [];
        let questionId = parseInt(questionData.question_type_id);
        position = parseInt(position);
        let nextQuestionIndex = this.props.nextQuestionIndex;
        let prevQuestionIndex = this.props.prevQuestionIndex;
        let changeConditionDropdown = this.props.changeConditionDropdown;
        let nextFlag = false;
        let prevFlag = false;
        let checkAddQuestion = this.props.checkAddQuestion;
        let addQuestionIndexCheck = this.props.addQuestionIndexCheck;
        // eslint-disable-next-line
        let addFreeTextOption = false;
        for (let i = (position + 2); i <= totalLength; i++) {
            let optionWithQuestion = allQuestionData[i - 1].question_text;
            // eslint-disable-next-line
            questionConditionOption.push(<option value={i} key={i}>{(optionWithQuestion ? ((optionWithQuestion.length > 25) ? i + ". " + optionWithQuestion.substring(0, 25).trim() + "..." : i + ". " + optionWithQuestion) : i + ". Informationsseite")}</option>);
        }
        // eslint-disable-next-line
        let optionValue = '';
        let optionDropdownValue = '';
        let optionStaticIndex = 0;
        let surveyData = this.props.value;
        let englishbuttonlabels;
        englishbuttonlabels = surveyData.englishbuttonlabels == "1" ? "EN" : "DE"
        if (optionData) {
            if (optionData[optionStaticIndex]) {
                if (optionData[optionStaticIndex].option_text) {
                    optionValue = optionData[optionStaticIndex].option_text;
                }
                if (optionData[optionStaticIndex].option_skip_question) {
                    optionDropdownValue = optionData[optionStaticIndex].option_skip_question;
                }
                if (parseInt(optionData[optionStaticIndex].option_end_survey) === 1 || optionData[optionStaticIndex].option_end_survey === true) {
                    optionDropdownValue = 0;
                }
            }
        }
        if (questionId !== 3 && questionId !== 6) {
            /*--- Added for Deleted Question Condition --- */
            if (optionDropdownValue && this.props.questionIndexUpdated && this.props.deletedQuestionIndex && optionDropdownValue > this.props.deletedQuestionIndex) {
                optionDropdownValue = parseInt(optionDropdownValue) - 1;
                this.changeDataFunction(optionData, position, optionDropdownValue, optionStaticIndex)
            }
            /*---End Added for Deleted Question Condition --- */
            /** Check for new add question */
            if (addQuestionIndexCheck && checkAddQuestion && parseInt(optionDropdownValue) > addQuestionIndexCheck) {
                optionDropdownValue = parseInt(optionDropdownValue) + 1;
                this.changeDataFunction(optionData, position, optionDropdownValue, optionStaticIndex)
            }
            /**End Check for new add question */
            /**---Added for up down question change like change condition--- */
            if (changeConditionDropdown) {
                if (nextQuestionIndex && prevQuestionIndex && optionDropdownValue) {
                    if (nextQuestionIndex === parseInt(optionDropdownValue)) {
                        nextFlag = true;
                    }
                    else if (prevQuestionIndex === parseInt(optionDropdownValue)) {
                        prevFlag = true;
                    }
                    if (nextFlag) {
                        optionDropdownValue = prevQuestionIndex;
                        this.changeDataFunction(optionData, position, optionDropdownValue, optionStaticIndex)
                        nextFlag = false;
                    }
                    if (prevFlag) {
                        optionDropdownValue = nextQuestionIndex;
                        this.changeDataFunction(optionData, position, optionDropdownValue, optionStaticIndex)
                        prevFlag = false
                    }
                }
            }
            /**---End Added for up down question change like change condition--- */
        }
        if (parseInt(questionData.scale_type) === 1 && (parseInt(questionId) !== 7)) {
            let length = 10;
            let optionDropdownValueNew = ''
            for (let index = 0; index < length; index++) {
                if (optionData) {
                    if (optionData[index]) {
                        if (optionData[index].option_skip_question) {
                            optionDropdownValueNew = optionData[index].option_skip_question;
                        }
                        if (!optionData[index].option_skip_question && !parseInt(optionData[index].option_end_survey)) {
                            optionDropdownValueNew = '';
                        }
                        if (parseInt(optionData[index].option_end_survey) === 1) {
                            optionDropdownValueNew = 0;
                        }
                    }
                }
                /*--- Added for Deleted Question Condition --- */
                if (optionDropdownValueNew && this.props.questionIndexUpdated && this.props.deletedQuestionIndex && optionDropdownValueNew > this.props.deletedQuestionIndex) {
                    optionDropdownValueNew = parseInt(optionDropdownValueNew) - 1;
                    this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                }
                /*---End Added for Deleted Question Condition --- */
                /** Check for new add question */
                if (addQuestionIndexCheck && checkAddQuestion && parseInt(optionDropdownValueNew) > addQuestionIndexCheck) {
                    optionDropdownValueNew = parseInt(optionDropdownValueNew) + 1;
                    this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                }
                /**End Check for new add question */
                /**---Added for up down question change like change condition--- */
                if (changeConditionDropdown) {
                    if (nextQuestionIndex && prevQuestionIndex && optionDropdownValueNew) {
                        if (nextQuestionIndex === parseInt(optionDropdownValueNew)) {
                            nextFlag = true;
                        }
                        else if (prevQuestionIndex === parseInt(optionDropdownValueNew)) {
                            prevFlag = true;
                        }
                        if (nextFlag) {
                            optionDropdownValueNew = prevQuestionIndex;
                            this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                            nextFlag = false;
                        }
                        if (prevFlag) {
                            optionDropdownValueNew = nextQuestionIndex;
                            this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                            prevFlag = false
                        }
                    }
                }
                /**---End Added for up down question change like change condition--- */
                option.push(
                    <div className="row align-items-center mb-3">
                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                            <button
                                type='button'
                                id={position}
                                className={'mb-0 rating-element-font default btn-rank change-rating-element-creation change-points-button option_' + position}
                                name={'ButtonChange'}
                                key={index + 1}
                                value={index + 1}
                            >{index + 1}</button>
                        </div>
                        {((parseInt(questionId) !== 7)) ?
                            <div className="continue-btn survey-form-detail col-12 col-md-6">
                                <div className="input-group-prepend">
                                    <label className="input-group-text sr-only" htmlFor="question-type"></label>
                                </div>
                                <select className={"custom-select question-condition-select option_" + position} value={parseInt(optionDropdownValueNew)} name='question_type_id' id={position} onChange={this.props.onClick}>
                                    <option value=''>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_1']}</option>
                                    {questionConditionOption}
                                    {
                                        this.state.dropdownText?
                                        <option value='0'>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_2']}</option>
                                        :
                                        <option value='0'>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_3']}</option>
                                    }
                                </select>
                            </div>
                            : null}
                    </div>)
            }
            option = <div className="col-12"> <div className="btn-col-1">{option}</div></div>
        }
        else if (parseInt(questionData.scale_type) === 2 && (parseInt(questionId) !== 7)) {
            let optionDropdownValueNew = ''
            option = SCHOOL_GRADE_SCALE_CONSTANT[LANGUAGE_CODE].map((option, index) => {
                if (optionData) {
                    if (optionData[index]) {
                        if (optionData[index].option_skip_question) {
                            optionDropdownValueNew = optionData[index].option_skip_question;
                        }
                        if (!optionData[index].option_skip_question && !parseInt(optionData[index].option_end_survey)) {
                            optionDropdownValueNew = '';
                        }
                        if (parseInt(optionData[index].option_end_survey) === 1) {
                            optionDropdownValueNew = 0;
                        }
                    }
                }
                /*--- Added for Deleted Question Condition --- */
                if (optionDropdownValueNew && this.props.questionIndexUpdated && this.props.deletedQuestionIndex && optionDropdownValueNew > this.props.deletedQuestionIndex) {
                    optionDropdownValueNew = parseInt(optionDropdownValueNew) - 1;
                    this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                }
                /*---End Added for Deleted Question Condition --- */
                /** Check for new add question */
                if (addQuestionIndexCheck && checkAddQuestion && parseInt(optionDropdownValueNew) > addQuestionIndexCheck) {
                    optionDropdownValueNew = parseInt(optionDropdownValueNew) + 1;
                    this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                }
                /**End Check for new add question */
                /**---Added for up down question change like change condition--- */
                if (changeConditionDropdown) {
                    if (nextQuestionIndex && prevQuestionIndex && optionDropdownValueNew) {
                        if (nextQuestionIndex === parseInt(optionDropdownValueNew)) {
                            nextFlag = true;
                        }
                        else if (prevQuestionIndex === parseInt(optionDropdownValueNew)) {
                            prevFlag = true;
                        }
                        if (nextFlag) {
                            optionDropdownValueNew = prevQuestionIndex;
                            this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                            nextFlag = false;
                        }
                        if (prevFlag) {
                            optionDropdownValueNew = nextQuestionIndex;
                            this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                            prevFlag = false
                        }
                    }
                }
                /**---End Added for up down question change like change condition--- */
                return (
                    <div className="row align-items-center mb-3">
                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                            <button
                                type="button"
                                id={option.id}
                                className={'text-capitalize rating-element-font change-rating-element-creation lh-50 defalut option_' + position}
                                name={questionData.id}
                                key={index}
                                value={option.title}
                                onClick={this.props.onClick}
                            >{option.id + " - " + SCHOOL_GRADE_SCALE_CONSTANT[englishbuttonlabels][index]["title"]} </button>
                        </div>
                        {((parseInt(questionId) !== 7)) ?
                            <div className="continue-btn survey-form-detail col-12 col-md-6">
                                <div className="input-group-prepend">
                                    <label className="input-group-text sr-only" htmlFor="question-type"></label>
                                </div>
                                <select className={'custom-select question-condition-select option_' + position} value={parseInt(optionDropdownValueNew)} name="question_type_id" id={position} onChange={this.props.onClick}>
                                    <option value=''>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_1']}</option>
                                    {questionConditionOption}
                                    {
                                        this.state.dropdownText?
                                        <option value='0'>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_2']}</option>
                                        :
                                        <option value='0'>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_3']}</option>
                                    }
                                </select>
                            </div>
                            : null}
                    </div>)
            })
            option = <div className="col-12"> <div className="btn-col-1">{option}</div></div>
        }
        else if (parseInt(questionData.scale_type) === 6 && (parseInt(questionId) !== 7)) {
            let optionDropdownValueNew = ''
            option = SURVEY_APPROVAL_QUESTION_ANSWER[LANGUAGE_CODE].map((option, index) => {
                if (optionData) {
                    if (optionData[index]) {
                        if (optionData[index].option_skip_question) {
                            optionDropdownValueNew = optionData[index].option_skip_question;
                        }
                        if (!optionData[index].option_skip_question && !parseInt(optionData[index].option_end_survey)) {
                            optionDropdownValueNew = '';
                        }
                        if (parseInt(optionData[index].option_end_survey) === 1) {
                            optionDropdownValueNew = 0;
                        }
                    }
                }
                /*--- Added for Deleted Question Condition --- */
                if (optionDropdownValueNew && this.props.questionIndexUpdated && this.props.deletedQuestionIndex && optionDropdownValueNew > this.props.deletedQuestionIndex) {
                    optionDropdownValueNew = parseInt(optionDropdownValueNew) - 1;
                    this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                }
                /*---End Added for Deleted Question Condition --- */
                /** Check for new add question */
                if (addQuestionIndexCheck && checkAddQuestion && parseInt(optionDropdownValueNew) > addQuestionIndexCheck) {
                    optionDropdownValueNew = parseInt(optionDropdownValueNew) + 1;
                    this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                }
                /**End Check for new add question */
                /**---Added for up down question change like change condition--- */
                if (changeConditionDropdown) {
                    if (nextQuestionIndex && prevQuestionIndex && optionDropdownValueNew) {
                        if (nextQuestionIndex === parseInt(optionDropdownValueNew)) {
                            nextFlag = true;
                        }
                        else if (prevQuestionIndex === parseInt(optionDropdownValueNew)) {
                            prevFlag = true;
                        }
                        if (nextFlag) {
                            optionDropdownValueNew = prevQuestionIndex;
                            this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                            nextFlag = false;
                        }
                        if (prevFlag) {
                            optionDropdownValueNew = nextQuestionIndex;
                            this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                            prevFlag = false
                        }
                    }
                }
                /**---End Added for up down question change like change condition--- */
                return (
                    <div className="row align-items-center mb-3">
                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                            <button
                                type="button"
                                id={option.id}
                                className={'text-capitalize rating-element-font change-rating-element-creation lh-50 defalut option_' + position}
                                name={questionData.id}
                                key={index}
                                value={option.title}
                                onClick={this.props.onClick}
                            >{option.id + " - " + SCHOOL_GRADE_SCALE_CONSTANT[englishbuttonlabels][index]["title"]} </button>
                        </div>
                        {((parseInt(questionId) !== 7)) ?
                            <div className="continue-btn survey-form-detail col-12 col-md-6">
                                <div className="input-group-prepend">
                                    <label className="input-group-text sr-only" htmlFor="question-type"></label>
                                </div>
                                <select className={'custom-select question-condition-select option_' + position} value={parseInt(optionDropdownValueNew)} name="question_type_id" id={position} onChange={this.props.onClick}>
                                    <option value=''>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_1']}</option>
                                    {questionConditionOption}
                                    {
                                        this.state.dropdownText?
                                        <option value='0'>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_2']}</option>
                                        :
                                        <option value='0'>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_3']}</option>
                                    }
                                </select>
                            </div>
                            : null}
                    </div>)
            })
            option = <div className="col-12"> <div className="btn-col-1">{option}</div></div>
        }
        else if (parseInt(questionData.scale_type) === 3 && (parseInt(questionId) !== 7)) {
            let totallength = 5;
            let optionDropdownValueNew = ''
            for (let index = 0; index < totallength; index++) {
                optionThumb = [];
                if (optionData) {
                    if (optionData[index]) {
                        if (optionData[index].option_skip_question) {
                            optionDropdownValueNew = optionData[index].option_skip_question;
                        }
                        if (!optionData[index].option_skip_question && !parseInt(optionData[index].option_end_survey)) {
                            optionDropdownValueNew = '';
                        }
                        if (parseInt(optionData[index].option_end_survey) === 1) {
                            optionDropdownValueNew = 0;
                        }
                    }
                }
                /*--- Added for Deleted Question Condition --- */
                if (optionDropdownValueNew && this.props.questionIndexUpdated && this.props.deletedQuestionIndex && optionDropdownValueNew > this.props.deletedQuestionIndex) {
                    optionDropdownValueNew = parseInt(optionDropdownValueNew) - 1;
                    this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                }
                /*---End Added for Deleted Question Condition --- */
                /** Check for new add question */
                if (addQuestionIndexCheck && checkAddQuestion && parseInt(optionDropdownValueNew) > addQuestionIndexCheck) {
                    optionDropdownValueNew = parseInt(optionDropdownValueNew) + 1;
                    this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                }
                /**End Check for new add question */
                /**---Added for up down question change like change condition--- */
                if (changeConditionDropdown) {
                    if (nextQuestionIndex && prevQuestionIndex && optionDropdownValueNew) {
                        if (nextQuestionIndex === parseInt(optionDropdownValueNew)) {
                            nextFlag = true;
                        }
                        else if (prevQuestionIndex === parseInt(optionDropdownValueNew)) {
                            prevFlag = true;
                        }
                        if (nextFlag) {
                            optionDropdownValueNew = prevQuestionIndex;
                            this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                            nextFlag = false;
                        }
                        if (prevFlag) {
                            optionDropdownValueNew = nextQuestionIndex;
                            this.changeDataFunction(optionData, position, optionDropdownValueNew, index)
                            prevFlag = false
                        }
                    }
                }
                /**---End Added for up down question change like change condition--- */
                let length = 1;
                for (let i = 0; i < length; i++) {
                    optionThumb.push(<button
                        type='button'
                        id={position}
                        className={'mb-2 mb-lg-0 default change-thumb-button rating-element-font change-rating-element-creation lh-50 btn-rank option_' + position}
                        name={'ButtonChange'}
                        key={i + 1}
                        value={index + 1}
                    > <i className="fa fa-thumbs-up"></i></button >)
                }
                if ((parseInt(questionId) !== 7)) {
                    option.push(
                        <div className="row align-items-center mb-3">
                            <div className="col-12 col-md-6 mb-3 mb-md-0 change-rating-element-creation">
                                {(index + 1) + " "}{optionThumb}
                            </div>
                            <div className="continue-btn survey-form-detail col-12 col-md-6">
                                <div className="input-group-prepend">
                                    <label className="input-group-text sr-only" htmlFor="question-type"></label>
                                </div>
                                <select className={"custom-select question-condition-select option_" + position} id={position} value={parseInt(optionDropdownValueNew)} name="question_type_id" onChange={this.props.onClick}>
                                    <option value=''>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_1']}</option>
                                    {questionConditionOption}
                                    {
                                        this.state.dropdownText?
                                        <option value='0'>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_2']}</option>
                                        :
                                        <option value='0'>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_3']}</option>
                                    }
                                </select>
                            </div>
                        </div>)
                }
            }
            option = <div className="col-12"> <div className="btn-col-1">{option}</div></div>
        }
        else if (parseInt(questionData.scale_type) === 4 && (parseInt(questionId) !== 7)) {
            let optionValue = '';
            let optionValueSecond = '';
            let optionStaticIndex = 0;
            if (optionData) {
                if (optionData[optionStaticIndex]) {
                    if (optionData[optionStaticIndex].option_text) {
                        let dataArray;
                        if (optionData[optionStaticIndex].option_text) {
                            dataArray = optionData[optionStaticIndex].option_text.toString()
                            dataArray = dataArray.split(',');
                        }
                        optionValue = (dataArray) ? dataArray[0] : 0;
                        optionValueSecond = (dataArray) ? dataArray[1] : 100;
                    }
                }
            }
            option.push(<div className="row align-items-center">
                <div key={1} className="default col-12 col-md-6 d-flex flex-wrap mb-2 mb-md-0">
                    <h2 className="w-100 mb-0"><span className="font-14">von</span> {(optionValue) ? parseInt(optionValue) : 0}%</h2>
                    <div className="tick-marks-creation col-12 col-md-9 px-0">
                        <div className="MuiSlider-main text-center">
                            <Slider
                                id={position}
                                name={questionData.id}
                                key={questionData.id}
                                aria-label="range-slider"
                                className={'selected-probability default col flex-1 change-rating-element-creation option_' + position + ' 0 ' + questionData.id}
                                min={0}
                                max={99}
                                value={(optionValue) ? parseInt(optionValue) : 0}
                                onChange={(e,optionValue)=>this.props.onClick(e,[optionValue,position,0])}
                                step="1"
                                valueLabelDisplay="auto"
                            />
                            <div className="MuiSlider-per"> <span className="d-inline-block text-gray">0%</span> <span className="d-inline-block text-gray">100%</span></div>
                        </div>
                        {/* <br /> */}
                    </div>
                    <h2 className="w-100 mb-0 mt-2"><span className="font-14">bis</span> {(optionValueSecond) ? parseInt(optionValueSecond) : 100}%</h2><br />
                    <div className="tick-marks-creation col-12 col-md-9 px-0">
                        <div className="MuiSlider-main text-center">
                            <Slider
                                id={position}
                                name={questionData.id}
                                key={questionData.id}
                                aria-label="range-slider"
                                className={'selected-probability default col flex-1 change-rating-element-creation option_' + position + ' 1 ' + questionData.id}
                                min={1}
                                max={100}
                                value={(optionValueSecond) ? parseInt(optionValueSecond) : 100}
                                onChange={(e,optionValueSecond)=>this.props.onClick( e,[optionValueSecond,position,1])}
                                step="1"
                                valueLabelDisplay="auto"
                            />
                            <div className="MuiSlider-per"> <span className="d-inline-block text-gray">0%</span> <span className="d-inline-block text-gray">100%</span></div>
                        </div>
                    </div>
                </div>

                {((parseInt(questionId) !== 7)) ?
                    <div className="continue-btn survey-form-detail col-12 col-md-6">
                        <div className="input-group-prepend">
                            <label className="input-group-text sr-only" htmlFor="question-type"></label>
                        </div>
                        <select className={'custom-select question-condition-select option_' + position} id={position} value={parseInt(optionDropdownValue)} name="question_type_id" onChange={this.props.onClick}>
                            <option value=''>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_1']}</option>
                            {questionConditionOption}
                            {
                            this.state.dropdownText?
                            <option value='0'>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_2']}</option>
                            :
                            <option value='0'>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_3']}</option>
                            }
                        </select>
                    </div>
                    : null}
            </div>)
            option = <div className="col-12 col-md-12"> <div className="btn-col-1">{option}</div></div>
        }
        else if (parseInt(questionId) === 3 || parseInt(questionId) === 6) {
            if (optionData) {
                if (questionData) {
                    if (parseInt(questionData.is_free_text) === 1) {
                        addFreeTextOption = true;
                    }
                }
                // eslint-disable-next-line
                optionData.map((optionDataNew, index) => {
                    let dropdownValue = '';
                    // eslint-disable-next-line
                    let checkFreeTextCondition = false;
                    if (optionDataNew) {
                        if (optionDataNew.option_skip_question) {
                            dropdownValue = optionDataNew.option_skip_question
                        }
                        if (optionDataNew.option_end_survey === 1) {
                            dropdownValue = 0;
                        }
                    }
                    if (parseInt(questionId) === 3) {
                        if (optionDataNew.option_text === "free_text_condition" || optionDataNew.option_text === "") {
                            addFreeTextOption = false;
                            checkFreeTextCondition = true;
                        }
                    }
                    /*--- Added for Deleted Question Condition --- */
                    if (this.props.questionIndexUpdated && this.props.deletedQuestionIndex && parseInt(dropdownValue) > this.props.deletedQuestionIndex) {
                        dropdownValue = parseInt(dropdownValue - 1);
                        this.changeDataFunction(optionData, position, dropdownValue, index)
                    }
                    /*---End Added for Deleted Question Condition --- */
                    /** Check for new add question */
                    if (addQuestionIndexCheck && checkAddQuestion && parseInt(dropdownValue) > addQuestionIndexCheck) {
                        dropdownValue = parseInt(dropdownValue) + 1;
                        this.changeDataFunction(optionData, position, dropdownValue, index)
                    }
                    /**End Check for new add question */
                    /**---Added for up down question change like change condition--- */
                    if (changeConditionDropdown) {
                        if (nextQuestionIndex && prevQuestionIndex && dropdownValue) {
                            if (nextQuestionIndex === parseInt(dropdownValue)) {
                                nextFlag = true;
                            }
                            else if (prevQuestionIndex === parseInt(dropdownValue) && !nextFlag) {
                                prevFlag = true;
                            }
                            if (nextFlag) {
                                dropdownValue = prevQuestionIndex;
                                nextFlag = false;
                                this.changeDataFunction(optionData, position, dropdownValue, index)
                            }
                            if (prevFlag) {
                                dropdownValue = nextQuestionIndex;
                                prevFlag = false
                                this.changeDataFunction(optionData, position, dropdownValue, index)
                            }
                        }
                    }
                    /**---End Added for up down question change like change condition--- */
                    option.push(
                        <div className="col-12" >
                            <div className="row mb-2">
                                <div className={'default col-12 col-md-6 valign-middle change-rating-element-creation option_' + position} id={optionDataNew.id} value={optionDataNew.option_text}>
                                    {(checkFreeTextCondition) ? "Freitext" : optionDataNew.option_text}
                                </div>
                                <div className="continue-btn survey-form-detail col-12 col-md-6">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text sr-only" htmlFor="question-type"></label>
                                    </div>
                                    <select className={'custom-select question-condition-select mb-2 option_' + position} id={position} value={parseInt(dropdownValue)} name="question_type_id" onChange={this.props.onClick}>
                                        <option value=''>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_1']}</option>
                                        {questionConditionOption}
                                        {
                                        this.state.dropdownText?
                                        <option value='0'>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_2']}</option>
                                        :
                                        <option value='0'>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_3']}</option>
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    )
                })
                if (questionId === 3 && addFreeTextOption) {
                    // eslint-disable-next-line
                    let lengthopt
                    if (optionData.length) {
                        lengthopt = optionData.length;
                    }
                    else {
                        lengthopt = 0;
                    }
                    option.push(
                        <div className="col-12" >
                            <div className="row">
                                <div className={'default col-12 col-md-6 valign-middle change-rating-element-creation option_' + position} id={" "} value={"free_text_condition"}>
                                    {"Freitext"}
                                </div>
                                <div className="continue-btn survey-form-detail col-12 col-md-6">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text sr-only" htmlFor="question-type"></label>
                                    </div>
                                    <select className={'custom-select question-condition-select mb-2 option_' + position} id={position} value={""} name="question_type_id" onChange={this.props.onClick}>
                                        <option value=''>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_1']}</option>
                                        {questionConditionOption}
                                        {
                                        this.state.dropdownText?
                                        <option value='0'>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_2']}</option>
                                        :
                                        <option value='0'>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_default_dropdown_3']}</option>
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        }
        else if (optionData) {
            // eslint-disable-next-line
            optionData.map((optionData, index) => {
                option.push(
                    <div className="col-12 mb-3">
                        <div>
                            <div className={'default col-12 valign-middle change-rating-element-creation  option_' + position} id={optionData.id} value={optionData.option_text}>
                                {(optionData.option_text === "free_text_condition") ? "Freitext" : optionData.option_text}
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return (
            <div className="row align-items-center">
                {option}
                {optionDropdown}
            </div>
        )
    }
    render() {
        let data = this.props.data;
        let surveyData = this.props.value;
        let question_data;
        let Max;
        if (data) {
            question_data = this.props.data;
            Max = question_data.length;
        }
        else {
            question_data = this.state.allQuestionData;
            Max = question_data.length;
        }
        let surveyId = parseInt(this.props.value.id);
        if (surveyId) {
            this.surveyMainId = surveyId;
        }
        let onChangeFunction = this.props.onChange;
        let surveyAllDemographic
        if (this.state.surveyAllDemographic) {
            surveyAllDemographic = this.state.surveyAllDemographic;
        }
        else {
            if (JSON.parse(localStorage.getItem('surveyalldemographic'))) {
                surveyAllDemographic = JSON.parse(localStorage.getItem('surveyalldemographic')).demographicattribute;
            }
        }
        let allQuestionDataOption = [];
        let demographicQuestionData = [];
        let dataProtectionQuestion = []
        let welcomeText = [];
        if (surveyData) {
            let storeitem = JSON.parse(localStorage.getItem('userdata'));
            if (surveyData.demographic) {
                let questionTitle = '';
                let questionId = '';
                // eslint-disable-next-line
                let activeDemography;
                if (surveyData.demographic.length) {
                    // eslint-disable-next-line
                    surveyData.demographic.map((data, index) => {
                        if (data.id) {
                            questionId = parseInt(data.id);
                        }
                        if (surveyAllDemographic) {
                            // eslint-disable-next-line
                            surveyAllDemographic.map((demoData, index) => {
                                if (parseInt(demoData.id) === questionId  && demoData.is_sie_demographic === this.props.value.is_sie_demographic) {
                                    activeDemography = index;
                                    questionTitle = demoData.question;
                                    demographicQuestionData.push(
                                        <div key={index} className="row no-gutters pb-4 pb-sm-0">
                                            <div className="acco-group col boxshadow">
                                                {/* change-bottom-border-accordian */}
                                                <div name="question_text" className="acco-heading text-colors font-weight-bold font-16 d-flex justify-content-between" >
                                                    <div name="demo_question_text" style={{"fontWeight": "600"}}>
                                                        {questionTitle}
                                                    </div>
                                                    <ul className="d-inline-block text-right">
                                                        <li className="d-inline-block mx-1 mx-sm-2">
                                                            <button
                                                                type="button"
                                                                id={demoData.id}
                                                                className={"text-colors bg-transparent border-0 pointer"}
                                                                name={"EditDemographicButton"}
                                                                value={index}
                                                                onClick={this.props.onClick}>
                                                                <i className="fas fa-edit"></i>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className={"acco-des hidden d-none"} id={"acc_demo_" + (index + Max)}>
                                                    {questionTitle}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    })
                }
            }else{
                demographicQuestionData.push(
                    <div className="row no-gutters pb-4 pb-sm-0">
                        <div className="acco-group col boxshadow">
                            {/* change-bottom-border-accordian */}
                            <div name="question_text" className="acco-heading text-colors font-weight-bold font-16 d-flex justify-content-between" >
                                <div name="demo_question_text" style={{"fontWeight": "600"}}>
                                    {SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["demographic_characteristic_inquiry"]}
                                </div>
                                <ul className="d-inline-block text-right">
                                    <li className="d-inline-block mx-1 mx-sm-2">
                                        <button
                                            type="button"
                                            //id={demoData.id}
                                            className={"text-colors bg-transparent border-0 pointer"}
                                            name={"EditDemographicButton"}
                                            //value={index}
                                            onClick={this.props.onClick}>
                                            <i className="fas fa-edit"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }
            // Data Protection Declaration of Consent
            dataProtectionQuestion.push(
                <div className="row no-gutters pb-4 pb-sm-0">
                    <div className="acco-group col boxshadow">
                        {/* change-bottom-border-accordian */}
                        <div name="question_text" className="acco-heading text-colors font-weight-bold font-16 d-flex justify-content-between" >
                            <div name="demo_question_text" style={{"fontWeight": "600"}}>
                            {storeitem.plan_type ? null : <i onClick={() => localStorage.setItem('redirectURL', window.location.href)} className="fas fa-lock mr-3" ></i>}{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["data_protection"]}
                            </div>
                            <ul className="d-inline-block text-right">
                                <li className="d-inline-block mx-1 mx-sm-2">
                                {storeitem.plan_type ?
                                    <button className="text-colors bg-transparent border-0 pointer" name="EditdataProtectionPageButton" onClick={this.props.onClick}>
                                        <i className="fas fa-edit"></i>
                                    </button>
                                   : <button className='text-colors bg-transparent border-0 pointer'>
                                        <Link className="text-colors bg-transparent border-0 pointer" to={storeitem.account_type_category_id == 4 ? "/business-checkout" :storeitem.account_type_category_id == 2 ? "/university-checkout" : "/checkout"}>
                                        <i onClick={() => localStorage.setItem('redirectURL', window.location.href)} className="fas fa-edit"></i>
                                        </Link>
                                    </button>
                                }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
            /**this push is for survey end page custom header and text for plus package users*/
            demographicQuestionData.push(
                <div className="row no-gutters pb-4 pb-sm-0">
                    <div className="acco-group col boxshadow">
                        {/* change-bottom-border-accordian */}
                        <div name="question_text" className="acco-heading text-colors font-weight-bold font-16 d-flex justify-content-between" >
                            <div name="demo_question_text" style={{"fontWeight": "600"}}>
                            {storeitem.plan_type ? null : <i onClick={() => localStorage.setItem('redirectURL', window.location.href)} className="fas fa-lock mr-3" ></i>}{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["Define_closing_page"]}
                            </div>
                            <ul className="d-inline-block text-right">
                                <li className="d-inline-block mx-1 mx-sm-2">
                                { storeitem.plan_type ?
                                    <button className="text-colors bg-transparent border-0 pointer" name="EditClosingPageButton" onClick={this.props.onClick}>
                                        <i className="fas fa-edit"></i>
                                    </button>
                                   : <button className='text-colors bg-transparent border-0 pointer'>
                                        <Link className="text-colors bg-transparent border-0 pointer" to={storeitem.account_type_category_id == 4 ? "/business-checkout" :storeitem.account_type_category_id == 2 ? "/university-checkout" : "/checkout"}>
                                        <i onClick={() => localStorage.setItem('redirectURL', window.location.href)} className="fas fa-edit"></i>
                                        </Link>
                                    </button>
                                }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
            /**To show displaying welcome-edit then displayWelcomeEdit set as true */
            let displayWelcomeEdit = true;
            let is_welcome_page = surveyData.is_welcome_page;
            if (displayWelcomeEdit && (surveyData.is_welcome_page === true || is_welcome_page === 1 || is_welcome_page === "true" || is_welcome_page === "1")) {
                welcomeText.push(
                    <div key="1" className="row no-gutters pb-4 pb-sm-0">
                        <div className="acco-group col boxshadow">
                            <div name="question_text" className="acco-heading text-colors font-weight-bold font-16 d-flex justify-content-between" >
                                <div name="demo_question_text" style={{"fontWeight": "600"}}>
                                    Begrüßungsseite
                                </div>
                                <ul className="d-inline-block text-right">
                                    <li className="d-inline-block mx-1 mx-sm-2">
                                        <button
                                            type="button"
                                            id={"test"}
                                            className={"text-colors bg-transparent border-0 pointer"}
                                            name={"EditWelcomPage"}
                                            value={"test"}
                                            onClick={this.props.onClick}>
                                            <i className="fas fa-edit"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className={"acco-des hidden d-none"} id={"acc_demo_"}>
                                Begrüßungsseite
                            </div>
                        </div>
                    </div>
                )
            }
        }
        for (var index = 0; index < Max; index++) {
            let questionTitleDisplay;
            if (window.innerWidth >= 576 && window.innerWidth < 768) {
                if (question_data[index]) {
                    if (question_data[index].question_text) {
                        if (question_data[index].question_text.length > 16) {
                            questionTitleDisplay = question_data[index].question_text.substring(0, 16).trim() + "...";
                        }
                        else {
                            questionTitleDisplay = question_data[index].question_text;
                        }
                    }
                }
            }
            else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
                if (question_data[index]) {
                    if (question_data[index].question_text) {
                        if (question_data[index].question_text.length > 35) {
                            questionTitleDisplay = question_data[index].question_text.substring(0, 35).trim() + "...";
                        }
                        else {
                            questionTitleDisplay = question_data[index].question_text;
                        }
                    }
                }
            }
            else if (window.innerWidth >= 1024) {
                if (question_data[index]) {
                    if (question_data[index].question_text) {
                        if (question_data[index].question_text.length > 51) {
                            questionTitleDisplay = question_data[index].question_text.substring(0, 51).trim() + "...";
                        }
                        else {
                            questionTitleDisplay = question_data[index].question_text;
                        }
                    }
                }
            }
            else {
                if (question_data[index]) {
                    if (question_data[index].question_text) {
                        questionTitleDisplay = question_data[index].question_text;
                    }
                }
            }
            allQuestionDataOption.push(
                <div>
                    {(index === 0) ?
                        <div className="add-answer mb-3 text-center">
                            {// eslint-disable-next-line
                            }<a href="#" onClick={this.props.onClick} id={(index)} value={(index)} name="AddQuestion" title="Frage hinzufügen"><i className="fa-plus fa icon-m border-radius create-plus"></i></a>
                        </div>
                        : null}
                    <div key={index} className="row no-gutters pb-2 pb-sm-0">
                        <div className="acco-group col boxshadow">
                            <div name="question_text" id={"condition_" + index} className="acco-heading text-colors font-weight-bold font-16 d-flex justify-content-between" >
                                <div className="creating-condition text-hyphens" id={index + 1} name="question_text" value={question_data[index].id || ""}>{index + 1}{". "}<p style={{"display" : "inline", "fontWeight": "600"}}>{(parseInt(question_data[index].question_type_id) === 2) ? "Informationsseite" : questionTitleDisplay}</p></div>
                                <ul className="d-inline-block acco_survey_btn text-right">
                                    <li className="d-inline-block mx-1 mx-sm-2">
                                        <button
                                            type="button"
                                            id={question_data[index].id}
                                            className={"text-colors bg-transparent border-0 pointer"}
                                            name={"EditButton"}
                                            value={index}
                                            onClick={this.props.onClick}>
                                            <i className="fas fa-edit"></i>
                                        </button>
                                    </li>
                                    {/* For copy survey question is user has plus plan package */}
                                    <li className="d-inline-block mx-1 mx-sm-2">
                                        <button
                                            type="button"
                                            id={question_data[index].id}
                                            name={"CopyQuestionButton"}
                                            value={index}
                                            className={"text-colors bg-transparent border-0 pointer"}
                                            onClick={this.props.onCopyQuestion}>
                                            <i className="fas fa-clone"></i>
                                        </button>
                                    </li>
                                    <li className="d-inline-block mx-1 mx-sm-2">
                                        <button
                                            type="button"
                                            id={question_data[index].id}
                                            name={"DeleteButton"}
                                            value={index}
                                            className={"text-colors bg-transparent border-0 pointer"}
                                            onClick={this.props.onDelete}>
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </li>
                                    <li className={(index === 0) ? "d-inline-block mx-1 mx-sm-3" : "d-inline-block mx-1 mx-sm-2"}>
                                        <button
                                            type="button"
                                            onClick={this.props.onChange}
                                            name={"Decbutton"}
                                            id={index}
                                            disabled={(index === 0) ? "disabled" : null}
                                            className={"buttonDecrement text-colors bg-transparent border-0 pointer mr"}>
                                            <i className={(index === 0) ? "fas fa-arrow-up d-none" : "fas fa-arrow-up"}></i>
                                        </button>
                                    </li>
                                    <li className={index === (Max - 1) ? "d-inline-block mx-1 mx-sm-3" : "d-inline-block mx-1 mx-sm-2"}>
                                        <button
                                            type="button"
                                            id={index}
                                            name={"Increbutton"}
                                            disabled={(index === parseInt(Max) - 1) ? "disabled" : null}
                                            className={"buttonDecrement text-colors bg-transparent border-0 pointer mr"}
                                            onClick={this.props.onChange}>
                                            <i className={(index === parseInt(Max) - 1) ? "fas fa-arrow-down d-none" : "fas fa-arrow-down"}></i>
                                        </button>
                                    </li>
                                    <li className={(parseInt(question_data[index].question_type_id) === 2 || parseInt(question_data[index].question_type_id) === 1) ? "d-inline-block mx-1 mx-sm-3 disable-rating-element" : "d-inline-block mx-1 mx-sm-2"}>
                                        <button
                                            className="text-colors bg-transparent border-0 pointer"
                                            name={"acc_" + index}
                                            value={index}
                                            onClick={(e) => { this.accordionData(e) }}>
                                            <i
                                                className={(parseInt(question_data[index].question_type_id) === 2 || parseInt(question_data[index].question_type_id) === 1) ? "fas fa-map-signs d-none" : "fas fa-map-signs"}
                                                id={"icon_" + index} style={{width : "30px"}}
                                            ></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className={"acc_div"} id={"acc_" + index}>
                                <div className={"acco-des"}>
                                    {(question_data[index].option) ?
                                        <div>
                                            {this.optionData(question_data, question_data[index].option, question_data[index], index, Max)}
                                        </div>
                                        : this.optionData(question_data, question_data[index].option, question_data[index], index, Max)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="add-answer mb-3 text-center">
                        {// eslint-disable-next-line
                        }<a href="#" onClick={this.props.onClick} id={(index + 1)} value={(index + 1)} name="AddQuestion" title="Frage hinzufügen"><i className="fa-plus fa icon-m border-radius create-plus"></i></a>
                    </div>
                </div>);
        }
        const elements = < div className="accordion accordion-02" id="accordion"><div className="custom-accordion margin-top-40"> {this.dataProtection(dataProtectionQuestion)}{this.welcomeData(welcomeText)} {this.allQuestionDataSection(allQuestionDataOption, onChangeFunction, data)} {this.startData(demographicQuestionData)} </div></div >;
        return (
            <Fragment>
                {(this.state.redirect === 'true') ?
                    <Redirect to="/anmelden"></Redirect>
                    : null
                }
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {
                    this.state.redirectToCheckout?
                    <Redirect to={"/checkout"}></Redirect> : null
                }
                {(parseInt(this.props.childIndex) === 1) ?
                    <div className={"section-overview custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")}>
                        {/* <h3 className="font-14 lh-24 fw-400 survey-subtitle">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_subtitle']}</h3> */}
                        <div id="errorSurveyMessage" className="error-text"></div>
                        <div className="container">
                            {elements}
                        </div>
                    </div>
                    : 
                    // <div className={"custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")}>
                    //     <h2 className="text-dark h3 font-weight-bold survey-title">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_information_title']}</h2>
                    //     <p className="text-dark font-16 lh-26 fw-400 font-weight-normal mt-5">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_creating_condition_page_information_subtitle']}</p>
                    //     <div id="errorSurveyMessage" className="error-text"></div>
                    // </div>
                    <SurveyWelcomeText backButtonClick={this.state.backButtonClick}/>
                }
            </Fragment>
        );
    }
}
export default SurveyCreatingConditions;