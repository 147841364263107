import React from 'react';
import { Redirect } from "react-router-dom";
import { ARTICLE_POPUP_CONSTANT} from "../../constants/FrontEndConstant";
import { LANGUAGE_CODE } from '../../constants/DotEnv';
class SubPageHeader extends React.Component {
    constructor() {
        super();
          this.state = {
            showPopup: false,
            user_data : null
        };
      }
    componentDidMount() {
        this.setState({user_data : localStorage.getItem("userdata") })
        this.scrollUpFunction();
        this.callPopup();
    }
    eventDetect = (event) => {
        if(event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)){
            this.setState({showPopup:true})
            document.removeEventListener('mouseout',this.eventDetect,false)
        }
    }
    callPopup = () => {
        setTimeout(() => {
            document.addEventListener("mouseout",this.eventDetect);
        }, 20000);
    }
    popupClose = () => {
        this.setState({showPopup:false})
        this.callPopup()
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (document.querySelector('.navbar-toggler')) {
            let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
            if (count === -1) {
                document.querySelector('.navbar-collapse').classList.remove('show');
                document.querySelector('header').classList.remove("header_collapse");
                document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
            }
        }
    }
    render() {
        // eslint-disable-next-line 
        let data = this.props.data;
        return (
            <section className="banner_text theme-main d-flex align-items-center bannet-text-h1">
                {(this.props.dataLength === 0) ?
                    <Redirect to="/404" />
                    : null
                }
                {/* Added for popup */}
                {this.state.showPopup == true && this.state.user_data == null?
               <>
                <div id="react-confirm-alert">
                    <div class="react-confirm-alert-overlay intent-bg">
                        <div class="react-confirm-alert">
                        <div className='custom-ui text-left position-relative share_popup'>
                        <div className="close-btn text-right">
                            <button className="d-inline-block bg-white border-0 w-auto sharing-close-button text-rgb" onClick={this.popupClose}><i className="ti-close pointer"></i></button>
                        </div>
                        <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin">{ARTICLE_POPUP_CONSTANT[LANGUAGE_CODE]['heading_text']}</h3>
                        <p dangerouslySetInnerHTML={{ __html : ARTICLE_POPUP_CONSTANT[LANGUAGE_CODE]['sub_text'] }}>
                        </p>
                        <p dangerouslySetInnerHTML={{ __html : ARTICLE_POPUP_CONSTANT[LANGUAGE_CODE]['message_text'] }}>
                        </p>
                        <ul class="list-unstyled mt-2  intent-popup-types">
                            <li class="position-relative"><i class="ti-check"></i>{ARTICLE_POPUP_CONSTANT[LANGUAGE_CODE]['point1_text']}</li>
                            <li class="position-relative"><i class="ti-check"></i>{ARTICLE_POPUP_CONSTANT[LANGUAGE_CODE]['point2_text']}</li>
                            <li class="position-relative"><i class="ti-check"></i>{ARTICLE_POPUP_CONSTANT[LANGUAGE_CODE]['point3_text']}</li>
                        </ul>
                        <div className="pt-3 text-center">
                            <a href={ARTICLE_POPUP_CONSTANT[LANGUAGE_CODE]['button_link']} rel="noopener noreferrer" className="btn btn-clr text-white text-uppercase font-18 w-100" title={ARTICLE_POPUP_CONSTANT[LANGUAGE_CODE]['button_text']}> {ARTICLE_POPUP_CONSTANT[LANGUAGE_CODE]['button_text']}</a>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
                </>: null
                }
                <div className="container">
                    <div className="page-title">
                        <h1 className="text-white font-weight-bold px-0 mb-0 without-word-break survey-title-sm">{this.props.title}</h1>
                        <p className="text-white text-center mb-0 font-18 line-height">{this.props.sub_title}</p>
                    </div>
                </div>
            </section>
        );
    }
}
export default SubPageHeader; 