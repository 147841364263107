import React, { Fragment } from 'react';
import axios from "axios";
import { SURVEY_CREATION_CONSTANT, BUBBLE_LOADING } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL } from '../../../../constants/DotEnv';
import Button from '@material-ui/core/Button';
class SurveyDemographicComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			allDemographicAttrDu: [],
			allDemographicAttrSie : [],
			loading: 'false',
			redirect: 'false'
		}
	}
	componentDidMount() {
		this.getAllDemographic();
	}
	getAllDemographic = async () => {
		let storeitem = JSON.parse(localStorage.getItem('userdata'));
		let SurveyAllDemographic = JSON.parse(localStorage.getItem("surveyalldemographic"));
		// eslint-disable-next-line
		let apitoken = '';
        let userid = '';
		if (storeitem !== null) {
			apitoken = storeitem.token;
			userid = storeitem.id;
		}
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
		let allDemographicAttrDu = [];
		let allDemographicAttrSie = [];
		let self = this;
		this.setState({ loading: 'true' })
		if (SurveyAllDemographic === null && storeitem !== null) {
			await axios.get(API_URL + '/survey/getDemographicattribute/' + LANGUAGE_CODE + '?uId=' + userid, { headers: headers })
				.then((response) => {
					let responseNew = '';
					responseNew = response.data['success'];
					localStorage.setItem("surveyalldemographic", JSON.stringify(responseNew));
					responseNew.demographicattribute.map((data, index) => {
						if (data['is_sie_demographic'] === 0) {
							allDemographicAttrDu.push(data)
						}
						if (data['is_sie_demographic'] === 1) {
							allDemographicAttrSie.push(data)
						}
						return null;
					})
					this.setState({ loading: 'false', allDemographicAttrDu, allDemographicAttrSie });
				})
				.catch((error) => {
					if (error) {
						let msg = error.response.data['error']
						if (msg === 'Expired token' || msg === 'Invalid token') {
							localStorage.clear();
							self.setState({ redirect: 'true' })
						}
					}
					self.setState({ loading: 'false' })
				})
		}
		else {
			SurveyAllDemographic.demographicattribute.map((data, index) => {
				if (data['is_sie_demographic'] === 0) {
					allDemographicAttrDu.push(data)
				}
				if (data['is_sie_demographic'] === 1) {
					allDemographicAttrSie.push(data)
				}
				return null;
			})
			this.setState({ loading: 'false', allDemographicAttrDu, allDemographicAttrSie });
		}
	}

	labelOnClick = (e) => {
        if (e.currentTarget.name) {
            document.getElementById(e.currentTarget.name).click();
            document.getElementById(e.currentTarget.name).checked = !(document.getElementById(e.currentTarget.name).checked);
        }
    }

	// eslint-disable-next-line
	section = (stateData, value, method) => {
		let optionlist = [];
		if (stateData) {
			let dataTotalLength = Object.keys(stateData).length;
			let dataLength = Math.floor(dataTotalLength / 2);
			var j = 0;
			for (var i = 0; i < dataLength; i++) {
				let demographicValue = 2;
				let demographicValueNew = 2;
				if (value['demographic']) {
					// eslint-disable-next-line
					value['demographic'].map((data1, index) => {
						if (data1['id'] === stateData[j]['id']) {
							demographicValue = 1;
						}
					})
					// eslint-disable-next-line
					value['demographic'].map((data1, index) => {
						if (data1['id'] === stateData[j + 1]['id']) {
							demographicValueNew = 1;
						}
					})
				}
				optionlist.push(
					<div className={(i === 0) ? "row" : "row mt-md-4"} key={j}>
						{(j < dataTotalLength) ?
							<div className="col-12 col-md-6 pt-3 pt-md-0">
								<div className="registration-form xyz MuiButton-effect">
									<div className="custom-control custom-checkbox pl-0 checkbox-animation">
										<Button variant="contained" color="#00b3f4" name={stateData[j]['id']} >
											<input type="checkbox" name={"demographic"} value={stateData[j]['id']} checked={(demographicValue === 1) ? true : false} className="custom-control-input element-demographic" onChange={this.props.onChange} id={stateData[j]['id']} />
											<label className="custom-control-label font-18 text-dark mb-0 w-100 box-shadow" htmlFor={stateData[j]['id']}><span class="check__cust" style={{"--check-tick": "#00b3f4"}}><svg width="12px" height="10px"> <polyline points="1.5 6 4.5  9 10.5 1"></polyline> </svg></span><span className="ml-3 font-weight-normal">{stateData[j]['title']}</span></label>
										</Button>
									</div>
								</div>
							</div>
							: null
						}
						{(parseInt(j + 1) < dataTotalLength) ?
							<div className="col-12 col-md-6 pt-3 pt-md-0">
								<div className="registration-form xyz MuiButton-effect">
									<div className="custom-control custom-checkbox pl-0 checkbox-animation">
										<Button variant="contained" color="#00b3f4" name={stateData[j + 1]['id']} >
											<input type="checkbox" name={"demographic"} checked={(demographicValueNew === 1) ? true : false} value={stateData[j + 1]['id']} className="custom-control-input element-demographic" onChange={this.props.onChange} id={stateData[j + 1]['id']} />
											<label className="custom-control-label font-18 text-dark mb-0 w-100 box-shadow" htmlFor={stateData[j + 1]['id']}><span class="check__cust" style={{"--check-tick": "#00b3f4"}}><svg width="12px" height="10px"> <polyline points="1.5 6 4.5  9 10.5 1"></polyline> </svg></span><span className="ml-3 font-weight-normal">{stateData[j + 1]['title']}</span></label>
										</Button>
									</div>
								</div>
							</div>
							: null
						}
					</div>
				)
				j = j + 2;
			}
		}
		return (
			<div className="last-tab-pane">
				<div className="survey-form col-11 col-sm-10 col-md-7 col-lg-5 mx-auto">
					<div className="hs-form-field">
						<h2 class="text-dark h3 font-weight-bold survey-title">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_demographic_page_title']}</h2>
						<h3 className="font-14 lh-24 fw-400 survey-subtitle">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_demographic_page_subtitle']}</h3>
					</div>
					<div className="row survey-form-detail mt-4">
						<div className="col-12 col-md-12 mb-4">
							<div className="swich-slider d-flex align-items-center">
								<span className="text-dark slider-text">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_demographic_toggle_du']}</span>
								<label className="switch mx-2">
									<input type="checkbox" data-toggle="toggle" name="sie-du-toggle" checked={this.props.value.is_sie_demographic} onClick={this.props.sieDuToggle} />
									<span className="slider slider-change round"></span>
								</label>
								<span className="text-dark slider-text">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_demographic_toggle_sie']}</span>
							</div>
						</div>
						<div className="col-12 col-md-12">
							{optionlist}
						</div>
					</div>
				</div>
			</div>
		);
	}
	render() {
		let stateData = this.props.value.is_sie_demographic === 1 ? this.state.allDemographicAttrSie : this.state.allDemographicAttrDu;
		let value = this.props.value;
		let method = this.props.onChange;
		const elements = <div className={"tab-pane sectionstep body current custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1"> {this.section(stateData, value, method)} </div >;
		return (
			<Fragment>
				{(this.state.loading === 'true') ?
					BUBBLE_LOADING
					: null
				}
				{elements}
			</Fragment>
		);
	}
}
export default SurveyDemographicComponent;