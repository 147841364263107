import React, { Fragment } from 'react';
import { SURVEY_CREATION_CONSTANT } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
import * as loadImage from 'blueimp-load-image';
class SurveyElementContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            optionCount: 2,
            optionLimit: 20
        };
    }
    section = (allOption, onChangeFunction, value) => {
        return (
            <div>
                {(!this.props.ratingSingleContent) ?
                    <div className="form-group survey-form-detail mt-4 mt-md-5">
                        <label htmlFor={SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_question_IPH']} className="sr-only">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_question_IPH']}</label>
                        <input type="text" value={value.question_text} name="question_text" onChange={onChangeFunction} className="form-control" placeholder="Wie lautet deine Frage ?" />
                        {(this.props.errorElementPosition === 1 && this.props.validationMessage)
                            ? <div id="errorSurveyMessage" className="error-text mt-2">{(this.props.validationMessage) ? <p>{this.props.validationMessage}</p> : null}</div>
                            : null}
                        {(this.props.errorElementPosition === 2 && this.props.validationMessage)
                            ? <div id="errorSurveyMessage" className="error-text mt-2">{(this.props.validationMessage) ? <p>{this.props.validationMessage}</p> : null}</div>
                            : null}
                        {(this.props.message && (this.props.validationMessage === undefined || !this.props.validationMessage))
                            ? <div id="errorSurveyMessage" className="error-text mt-2"><p>{this.props.message}</p></div>
                            : null
                        }
                    </div>
                    : null
                }
                {allOption}
                {(this.props.optionCount < this.props.optionLimit) ?
                    <div className="add-answer mt-3 mb-4 text-center">
                        {// eslint-disable-next-line
                        }<a href="#" onClick={this.props.onChange} name="addoption" title="Add-answer"><i className="fa-plus fa icon-m border-radius create-plus"></i></a>
                    </div>
                    : null}
            </div>
        );
    }
    render() {
        let value = this.props.value;
        let ratingSingleContent = this.props.ratingSingleContent;
        let onChangeFunction = this.props.onChange;
        // eslint-disable-next-line
        let Max = this.props.optionCount;
        let allOption = [];
        let optionTitle;
        if (this.props.ratingSingleContent) {
            optionTitle = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_rating_option_lable'];
        }
        else {
            optionTitle = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_option_lable'];
        }
        if (value.option) {
            if (value.option.length) {
                // eslint-disable-next-line
                value.option.map((optiondata, index) => {
                    let checkFocus;
                    if (optiondata.option_text) {
                        if (optiondata.option_text !== "free_text_condition") {
                            checkFocus = true;
                        }
                    }
                    if (ratingSingleContent) {
                        allOption.push(
                            <div className={(index === 0) ? (checkFocus) ? "form-group custom-label-size hs-form-field d-flex align-items-center space-between mt-4 mt-md-4 del-btn focus" : "form-group custom-label-size hs-form-field d-flex align-items-center space-between mt-3 mt-md-3 del-btn " : (checkFocus) ? "form-group custom-label-size hs-form-field d-flex align-items-center space-between del-btn focus" : "form-group custom-label-size hs-form-field d-flex align-items-center space-between del-btn "} id={index} key={index}>
                                <input type="hidden" name="id" value={(optiondata.id) ? optiondata.id : ""} onChange={onChangeFunction} className="element-content" />
                                <input type="text" name={"option_text"} value={(optiondata.option_text === "free_text_condition") ? "" : optiondata.option_text} onChange={onChangeFunction} className="form-control hs-input minwi element-content" />
                                <label htmlFor={(this.props.changePlaceholder) ? SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_rating_scale_option_lable'] : optionTitle}>{(this.props.changePlaceholder) ? SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_rating_scale_option_lable'] : optionTitle}</label>
                                {value.option.length > 1 ?
                                    <button className="btn p-0 icon-for-image" type="button" name="removeoption" id={optiondata.id} onClick={onChangeFunction} data-value={index}><i className="fas fa-trash"></i></button>
                                    : <div className="icon-for-image"></div>
                                }
                            </div>);
                    }
                    else {
                        let optionImage;
                        if (optiondata.option_image) {
                            if (optiondata.option_image.length) {
                                optionImage = optiondata.option_image;
                            }
                            else {
                                // eslint-disable-next-line
                                let self = this;
                                let ext;
                                if (optiondata.option_image.type) {
                                    ext = optiondata.option_image.type.split('/').pop().toLowerCase();
                                }
                                if (ext === 'png' || ext === 'jpeg' || ext === 'jpg') {
                                    loadImage.parseMetaData(optiondata.option_image, function (data) {
                                        //default image orientation
                                        var orientation = 0;
                                        //if exif data available, update orientation
                                        if (data.exif) {
                                            orientation = data.exif.get('Orientation');
                                        }
                                        // eslint-disable-next-line
                                        var loadingImage = loadImage(
                                            optiondata.option_image,
                                            function (canvas) {
                                                //here's the base64 data result
                                                var base64data = canvas.toDataURL(optiondata.option_image.type);
                                                //here's example to show it as on imae preview
                                                // eslint-disable-next-line
                                                var img_src = base64data.replace(/^data\:image\/\w+\;base64\,/, '');
                                                document.getElementById("output_" + index).src = base64data;
                                            }, {
                                                //should be set to canvas : true to activate auto fix orientation
                                                canvas: true,
                                                orientation: orientation,
                                                maxHeight: 50,
                                                maxWidth: 50,
                                            }
                                        );
                                    });
                                }
                                else {
                                    optionImage = window.URL.createObjectURL(optiondata.option_image);
                                }
                            }
                        }
                        allOption.push(
                            <div className={(index === 0) ? (checkFocus) ? "form-group custom-label-size hs-form-field d-flex align-items-center space-between mt-4 mt-md-5 focus" : "form-group custom-label-size hs-form-field d-flex align-items-center space-between mt-4 mt-md-5 " : (checkFocus) ? "form-group custom-label-size hs-form-field d-flex align-items-center space-between focus" : "form-group custom-label-size hs-form-field d-flex align-items-center space-between "} id={index} key={index}>
                                <input type="hidden" name="id" value={(optiondata.id) ? optiondata.id : ""} onChange={onChangeFunction} className="element-content" />
                                <input type="text" name={"option_text"} value={(optiondata.option_text === "free_text_condition") ? "" : optiondata.option_text} onChange={onChangeFunction} className="form-control hs-input minwi element-content" />
                                <label htmlFor={optionTitle}>{optionTitle}</label>
                                <div className="icon-for-image d-inline-block">
                                    <label id="upload_photo" className="upload">
                                        <input type="file" onChange={onChangeFunction} className={"get-image element-content delete-change-value-" + index} id="upload-photo" name="option_image" style={{ "display": "none" }} />
                                    </label>
                                    {// eslint-disable-next-line
                                    }<img width="50" height="50" className={(optiondata.option_image) ? "get-image-preview element-content element-content-img" : "get-image-preview element-content element-content-img d-none"} id={"output_" + index} src={(optionImage) ? optionImage : null}></img>
                                    {value.option.length > 1 ?
                                        <button className="btn p-0 icon-for-image delete-icon" type="button" name="removeoption" id={optiondata.id} onClick={onChangeFunction} data-value={index}><i className="fas fa-trash"></i></button>
                                        : null
                                    }
                                </div>
                            </div>);
                    }
                })
            }
        }
        const elements = < div>  {this.section(allOption, onChangeFunction, value)} </div >;
        return (
            <Fragment>
                {elements}
            </Fragment >
        );
    }
}
export default SurveyElementContent;