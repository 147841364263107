import React from 'react';
import { Link } from 'react-router-dom';
import { SURVEY_PARTICIPATION, SURVEY_TERMS_CONDITION } from '../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, BASEURL, IMAGE_BASEURL } from '../../../constants/DotEnv';
class SurveyCoverPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 'false',
        }
    }
    componentDidMount() {
        this.scrollUpFunction();
    }
    /** this function is for scrolling up page */
    scrollUpFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    clearLocalstorageForQuestion = () => {
        if (this.surveyMainId) {
            localStorage.removeItem('surveyparticipationquestion_' + this.surveyMainId);
            if (this.props.previewSurvey) {
                localStorage.removeItem('surveydetail_' + this.surveyMainId);
                window.close()
            }
        }
    }
    render() {
        let slug = this.props.slug;
        let surveydetail;
        if (this.props.surveyid) {
            this.surveyMainId = this.props.surveyid;
            surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + this.surveyMainId));
        }
        let reward = JSON.parse(localStorage.getItem("surveylistdropdown"));
        let surveycoverpagedetails = '';
        let reward_text = "";
        let survey_optional_logo;
        let survey_color;
        let account_type_category_id;
        let englishbuttonlabels;
        if (surveydetail) {
            account_type_category_id = surveydetail.account_type_category_id
            survey_color = surveydetail.survey_color
            surveycoverpagedetails = surveydetail;
            survey_optional_logo = surveycoverpagedetails.survey_optional_logo;
            englishbuttonlabels = surveycoverpagedetails.englishbuttonlabels == "1" ? "EN" : "DE"
            if (reward) {
                let reward_dropdown = reward.reward
                // eslint-disable-next-line
                reward_dropdown.map((key, index) => {
                    if (surveycoverpagedetails.reward_type === key.id) {
                        reward_text = key.reward
                    }
                })
            }
        }
        return (<main>
            <div className="" role="document">
                <div className="survey-bg-img position-relative change-header-design_line">
                    <div className="container-fluid px-0">
                        <div className="modal_header border-bottom-0">
                            <div className="container container-large">
                                <div className="row align-items-center">
                                <div className="col-8 col-md-9 text-left ">
                                {surveycoverpagedetails.account_type_category_id == 4 || surveycoverpagedetails.account_type_category_id == 2?
                                surveycoverpagedetails.isUserHaveActivePlan == 1?
                                survey_optional_logo?
                                    <div className="modal-logo">
                                    <a href={BASEURL} className="navbar-brand">
                                        <img className="light-logo custom-logo-size" src={survey_optional_logo} alt="empirio-logo" />
                                    </a>
                                </div>
                                : null : <div className="modal-logo">
                                    <a href={BASEURL} className="navbar-brand">
                                        <img className="light-logo custom-logo-size" src={surveycoverpagedetails.logo_image1} alt="empirio-logo" />
                                    </a>
                                </div>
                                : <div className="modal-logo">
                                    <a href={BASEURL} className="navbar-brand">
                                        <img className="light-logo custom-logo-size" src={surveycoverpagedetails.logo_image1} alt="empirio-logo" />
                                    </a>
                                </div>
                                }
                                </div>
                                    {(this.props.previewSurvey) ?
                                        <div className="col-4 col-md-3 text-right closetab pb-2">
                                            <button className="close" onClick={this.clearLocalstorageForQuestion}><i className="ti-close ti-close-participation"></i></button>
                                        </div>
                                        :
                                        <div className="col-4 col-md-3 text-right closetab pb-2">
                                            <Link to={(!this.props.startSurvey && !this.props.previewSurvey) ? "/" : "/umfragen/" + slug} className="close" onClick={this.clearLocalstorageForQuestion}><i className="ti-close ti-close-participation"></i></Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="survey-main-image" style={this.props.isNormalImage === 'yes' ?
                        { "backgroundImage": "url(" + surveycoverpagedetails.survey_picture + ")" }
                        : { "backgroundImage": "url(" + surveycoverpagedetails.survey_picture + ")" }}>
                        </div>
                        <div className="text-center survey-cover-page-detail">
                            <h1 className="text-white px-sm-0 font-weight-bold text-break">{surveycoverpagedetails.survey_title}</h1>
                            <div className="participate-surevey-link">
                                <button type="button" className="text-uppercase btn text-white font-weight-bold font-18 mt-5" name="surveystart" onClick={this.props.onClick} style={{"backgroundColor": survey_color? survey_color : "#00b3f4" }}>{englishbuttonlabels && SURVEY_PARTICIPATION[englishbuttonlabels]['participation_button']}</button>
                            </div>
                            <div className="voucher-text mt-4">
                                {(reward_text) ? <span className="text-white d-block d-sm-inline-block"><i className="ti-gift mr-1"></i>{reward_text}{(surveycoverpagedetails.reward_description) ? " - " + surveycoverpagedetails.reward_description.replace(/<br\s*\/?>/mg, "").replace( /(<([^>]+)>)/ig, '') : null}</span> : null}
                            </div>
                        </div>
                        <div className="modal-footer terms_footer">
                            <div className="row no-gutters fixed-in-mb">
                                <div className="col-12">
                                    <p className="text-white text-left text-md-left mb-0 pt-0 pt-lg-2 pt-xl-0 terms_cover">{SURVEY_TERMS_CONDITION[LANGUAGE_CODE]['terms_condition1'] + " "}<Link to="/agb" style={{ color: "#fff", borderBottom: "1px solid white" }}>{SURVEY_TERMS_CONDITION[LANGUAGE_CODE]['terms_condition2']}</Link>{" " + SURVEY_TERMS_CONDITION[LANGUAGE_CODE]['terms_condition3'] + " "}<Link to="/datenschutz" style={{ color: "#fff", borderBottom: "1px solid white" }}>{SURVEY_TERMS_CONDITION[LANGUAGE_CODE]['terms_condition4']}</Link>{" " + SURVEY_TERMS_CONDITION[LANGUAGE_CODE]['terms_condition5']}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>)
    }
}
export default SurveyCoverPage;