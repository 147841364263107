import React from "react"
import Button from '@material-ui/core/Button';
import { SURVEY_CREATION_CONSTANT } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from "../../../../constants/DotEnv";

class SurveyDataProtectionParticipantPage extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            check: false
        }
    }
    render() {
        let data = this.props.declaration_consent
        var surveyDetail = JSON.parse(localStorage.getItem('surveydetail_' + data?.id))
        let surveyColor = surveyDetail?.survey_color;
        let englishbuttonlabels = surveyDetail.englishbuttonlabels == "1" ? "EN" : "DE"

        return (
            
            <div className="survey-form col-11 col-sm-10 col-md-7 col-lg-5 container">
                <div className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
                    <div className="question-heading">
                        <h3 className="text-dark font-weight-bold text-break survey-title" style={{"marginBottom": "20px"}}>{data.declaration_consent_title}</h3>
                        {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
                    </div>
                    <p className="font-18 text-dark font-weight-normal mb-5"
                    dangerouslySetInnerHTML={{ __html: data.declaration_consent_description?.replace(/<br\s*\/?>/mg, "").replace( /(<([^>]+)>)/ig, '').replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                    {/* Check-box : If not selected do not processed forward */}
                    <div className="registration-form xyz MuiButton-effect">
                        <div className="custom-control custom-checkbox pl-0 checkbox-animation">
                            <input type="checkbox" className="custom-control-input element-" id={1} checked={this.props.dataProtection == true ? true : false}/>
                            <label className="custom-control-label font-18 text-dark mb-0 w-100" htmlFor={1} style={{"cursor" : "default","display":"flex"}}>
                            <span class="check__cust data-chk" style={{"--check-tick": surveyColor? surveyColor : "#00b3f4"}} onClick={this.props.labelOnClick_ForDataProtection}>
                                <svg width="12px" height="10px" className="check-box"> <polyline points="1.5 6 4.5  9 10.5 1"></polyline> </svg>
                            </span>
                            <span className="ml-3 font-weight-normal">
                            {englishbuttonlabels && SURVEY_CREATION_CONSTANT[englishbuttonlabels]['Survey_data_protection_checkbox_text']}
                            {/* {SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]["Survey_data_protection_checkbox_text"]} */}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SurveyDataProtectionParticipantPage;