import React from 'react';
import axios from 'axios';
import { Redirect, Link} from "react-router-dom";
import { EDIT_SURVEY_RESULT_CONSTANT, EDIT_SURVEY_RESULT_ERROR_CONSTANT, FIND_PARTICIPANTS_ERROR_MESSAGE, EDIT_SURVEY_CONSTANT, NO_OF_PARTICIPANTS_ALLOW, URL_CONSTANT, NO_OF_SYSTEM_LOGIN_USERS_ALLOW, CHART_HEIGHT, CHART_WIDTH,BUBBLE_LOADING } from '../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, MESSAGE_TIME, BASEURL, CHART_API } from '../../../constants/DotEnv';
import CountUp from 'react-countup';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterIcon,
    TwitterShareButton,
    WhatsappShareButton,
    WhatsappIcon } from "react-share";
class PublishResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            redirect: false,
            redirectToNotFound : false,
            participate_count: 0,
            userName: '',
            bubbleLoading : false,
            fieldErrorMessage: [],
            changebutton: false,
            responseResult: {},
            statisticsData: {},
            error : false,
            copyMessage: "",
            system_user_count: 0,
        }
        this.userData = JSON.parse(localStorage.getItem("userdata"))
    }
    componentDidMount() {
        this.checkData();
        this.scrollUpFunction(true);
    }
    scrollUpFunction = (smoothScroll = false) => {
        /** For scrolling up page */
        if (smoothScroll) {
            let position = document.documentElement.scrollTop;
            document.documentElement.scrollBy(0, -Math.max(10, Math.floor(position / 10)));
            this.scrollAnimation = window.setTimeout(() => {
                if (document.documentElement.scrollTop !== 0) {
                    this.scrollUpFunction(true)
                }
            }, 10);
        }
        else {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }
    /** Function for getting survey-result data using api 'getDataToPublishResult' */
    checkData = async (loading = true) => {
        if (loading) {
            this.setState({ bubbleLoading : true })
        }
        let poll_data = this.props.poll_data;
        let survey_id = poll_data.id;
        let storeitem = '';
        var responseResult = {};
        let statisticsData = {};
        storeitem = JSON.parse(localStorage.getItem('userdata'));
        if (survey_id) {
            this.surveyMainId = poll_data.id;
        }
        if (storeitem) {
            let apitoken = '';
            let userid = '';
            let userName = storeitem.first_name;
            if (storeitem !== null) {
                apitoken = storeitem.token;
                userid = storeitem.id;
            }
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + apitoken
            }
            await axios.get(API_URL + '/surveyresult/getDataToPublishResult/' + LANGUAGE_CODE + '/' + this.surveyMainId + '?uId=' + userid, { headers: headers })
                .then((response) => {
                    if (response && response.data && response.data.success) {
                        if (response.data.success.hasOwnProperty('surveydata')) {
                            responseResult = response.data['success'].surveydata;
                        }
                        if (responseResult['survey']) {
                            this.props.changePublishedResultStatus(responseResult['survey'].id, responseResult['survey'].status);
                            delete responseResult['survey']['demographic_id'];
                            delete responseResult['survey']['is_rewarded'];
                            delete responseResult['survey']['language_code'];
                            delete responseResult['survey']['modified'];
                            delete responseResult['survey']['publish_date'];
                            let fact_odds = responseResult['survey']['facts_and_odds'] ? responseResult['survey']['facts_and_odds'] : "";
                            if (!fact_odds) {
                                if (responseResult['maxdate'] && responseResult['mindate']) {
                                    let regEx = /-/gi;
                                    let maxdate = responseResult['maxdate'].replace(regEx,".");
                                    let mindate = responseResult['mindate'].replace(regEx,".");
                                    fact_odds += EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['survey_period'] + ": " + mindate + " - " + maxdate + "\n";
                                    delete responseResult['maxdate'];
                                    delete responseResult['mindate'];
                                }
                                if (responseResult['country'] && responseResult['country'].length) {
                                    let country = EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['region_surveyed'] + ": ";
                                    // eslint-disable-next-line
                                    responseResult['country'].map((data,index)=> {
                                        if (index === responseResult['country'].length-1) {
                                            country += data;
                                        }
                                        else {
                                            country += data + ', ';
                                        }
                                    })
                                    fact_odds += country + "\n";
                                    delete responseResult['country'];
                                }
                                if (responseResult['minage'] && responseResult['maxage']) {
                                    fact_odds += EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['age_group'] + ": " + responseResult['minage'] + " - " + responseResult['maxage'] + " " + EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['years'] + "\n";
                                    delete responseResult['minage'];
                                    delete responseResult['maxage'];
                                }
                                responseResult['survey']['facts_and_odds'] = fact_odds;
                            }
                            else {
                                if (responseResult['maxdate'] && responseResult['mindate']) {
                                    delete responseResult['maxdate'];
                                    delete responseResult['mindate'];
                                }
                                if (responseResult['country'] && responseResult['country'].length) {
                                    delete responseResult['country'];
                                }
                                if (responseResult['minage'] && responseResult['maxage']) {
                                    delete responseResult['minage'];
                                    delete responseResult['maxage'];
                                }
                            }
                        }
                        if (responseResult.hasOwnProperty('statisticsdata') && Object.keys(responseResult['statisticsdata']).length) {
                            statisticsData = responseResult['statisticsdata'];
                            delete responseResult['statisticsdata'];
                        }
                        if (responseResult['questiondata'] && responseResult['questiondata'].length) {
                            for (let i = 0; i < responseResult['questiondata'].length; i++) {
                                delete responseResult['questiondata'][i]['created'];
                                delete responseResult['questiondata'][i]['modified'];
                            }
                        }
                        if(responseResult['demographic'] && responseResult['demographic'].length) {
                            for (let i = 0; i < responseResult['demographic'].length; i++) {
                                if (!responseResult['demographic'][i]['user_question_type']) {
                                    responseResult['demographic'][i]['user_question_type'] = "demographic";
                                }
                                delete responseResult['demographic'][i]['created'];
                                delete responseResult['demographic'][i]['modified'];
                                delete responseResult['demographic'][i]['question_type'];
                            }
                        }
                        if (response.data.success.participate_count || response.data.success.participate_count === 0) {
                            this.setState({ participate_count : response.data.success.participate_count, responseResult, statisticsData})
                        }
                        else {
                            this.setState({ responseResult, statisticsData })
                        }
                        if(response.data.success.system_user_count && response.data.success.system_user_count !== 0) {
                            this.setState({ system_user_count : response.data.success.system_user_count})
                        }
                    }
                })
                .catch((error) => {
                    if (error && error.response && error.response.data) {
                        let msg = error.response.data['error']
                        if (msg === 'Expired token' || msg === 'Invalid token') {
                            localStorage.clear();
                            this.setState({ redirect: true })
                        }
                        if (msg === 'Invalid User') {
                            this.setState({ redirectToNotFound: true })
                        }
                    }
                })
            this.setState({ userName, bubbleLoading : false });
        }
        else {
            this.setState({ redirect : true, bubbleLoading : false })
        }
    }
    /** Change event for below fields :
     * scientific_work_type
     * note
     * facts_and_odds
     * presentation_text
     */
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let responseResult = this.state.responseResult;
        if (name === 'scientific_work_type') {
            responseResult['survey']['type_of_scientific_work'] = value;
        }
        if (name === 'note') {
            if (/^[0-9,]*$/.test(value)) {
                responseResult['survey']['note'] = value;
            }
        }
        if (name === 'facts_and_odds') {
            if (value.replace(/\s/g, '').length || value.length === 0) {
                responseResult['survey']['facts_and_odds'] = value;
            }
        }
        if (name === 'presentation_text') {
            if (value.replace(/\s/g, '').length || value.length === 0) {
                responseResult['survey']['presentation_of_the_survey'] = value;
            }
        }
        var fieldIndex = this.state.fieldErrorMessage.indexOf(name);
        if (fieldIndex > -1) {
            this.state.fieldErrorMessage.splice(fieldIndex, 1);
        }
        this.setState({responseResult})
    }
    /** Function for submiting data and before submiting check whether all detail is fill up or not,if not then display error message */
    handleSubmit = async (event, isUnPublish = false) => {
        var questiondata = this.state.responseResult['questiondata'];
        let responseResult = this.state.responseResult;
        let length = '';
        var errorStore = [];
        var errorCount = 0;
        if (!isUnPublish) {
            var formfield = {
                presentation_text: responseResult['survey']['presentation_of_the_survey'],
                scientific_work_type: responseResult['survey']['type_of_scientific_work'],
                note: responseResult['survey']['note'],
                facts_and_odds: responseResult['survey']['facts_and_odds']
            }
            for (let key in formfield) {
                if (formfield.hasOwnProperty(key)) {
                    var errorkey = key;
                    const value = formfield[errorkey];
                    if (value === '' || value === null || value === undefined) {
                        if (key === 'scientific_work_type') {
                            errorStore.push(errorkey);
                            errorCount += 1;
                        }
                    }
                    if (value === '' || value === null || value === undefined) {
                        if (key === 'presentation_text') {
                            errorStore.push(errorkey);
                            errorCount += 1;
                        }
                    }
                    if (value === '' || value === null || value === undefined) {
                        if (key === 'facts_and_odds') {
                            errorStore.push(errorkey);
                            errorCount += 1;
                        }
                    }
                    if (value !== "" && value !== null && value !== undefined) {
                        if (key === 'note') { 
                            let re = /^([0-9]{1,1})(,{1,1})([0-9]{1,2})$/;
                            if (!value.match(re)) {
                                errorStore.push(errorkey);
                                errorCount += 1;
                            }
                        }
                    }
                }
            }
            for( let key in questiondata ) {
                if( questiondata.hasOwnProperty(key) ) {
                    ++length;
                }
            }
            // Count of selected normal question
            let count = 0;
            for (let i = 0; i < length; i++ ) {
                if (this.state.responseResult['questiondata'][i]['is_publish'] === 1) {
                    count ++;
                }
            }
            // Display error in less than 5 normal question select
            if (count < 5) {
                errorStore.push("questions");
                errorCount += 1;
            }
            for (let i = 0; i < length; i++ ) {
                if (this.state.responseResult['questiondata'][i]['is_publish'] === 1) {
                    if (this.state.responseResult['questiondata'][i]['user_question_type'] === '' || this.state.responseResult['questiondata'][i]['user_question_type'] === null) {
                        errorStore.push(i);
                        errorCount += 1;
                    }
                }
            }
        }
        if (errorCount === 0) {
            let storeitem = JSON.parse(localStorage.getItem('userdata'));
            let apitoken = '';
            let userid = '';
            if (storeitem !== null) {
                apitoken = storeitem.token;
                userid = storeitem.id;
            }
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + apitoken
            }
            let data = this.state.responseResult;
            if (data['survey']['presentation_of_the_survey']) {
                data['survey']['presentation_of_the_survey'] = data['survey']['presentation_of_the_survey'] ? data['survey']['presentation_of_the_survey'].replace(/<br\s*\/?>/mg, "") : '';
            }
            if (data['survey']['facts_and_odds']) {
                data['survey']['facts_and_odds'] = data['survey']['facts_and_odds'] ? data['survey']['facts_and_odds'].replace(/<br\s*\/?>/mg, "") : '';
            }
            if (data['survey'].status === null || data['survey'].status === 0) {
                data['survey'].status = 1;
            }
            else if (data['survey'].status === 1) {
                data['survey'].status = 0;
            }
            let oldResultData = {...data};
            await axios.post(API_URL + '/surveyresult/publishUnpublishSurveyResult?uId=' + userid, {"surveydata" : data} , { headers: headers })
                .then((response) => {
                    if (response && response.data && response.data.success) {
                        this.props.changePublishedResultStatus(data['survey'].id, data['survey'].status);
                        if (data['survey'].status === 1) {
                            axios.get(API_URL + '/surveyresult/SendEmailToParticiantForPublishSurveyResult/' + LANGUAGE_CODE + "/" + this.surveyMainId + '?uId=' + userid, { headers: headers })
                                .then((response) => {})
                                .catch((error) => {
                                    console.log(error)
                                })
                        }
                        //statisticsdata
                        let statisticsData = {};
                        if (response.data.success.statisticsdata) {
                            statisticsData = response.data.success.statisticsdata;
                        }
                        this.checkData(false);
                        this.setState({ responseResult: oldResultData, statisticsData })
                    }
                })
                .catch((error) => {
                    if (error && error.response && error.response.data) {
                        let msg = error.response.data['error']
                        if (msg === 'Expired token' || msg === 'Invalid token') {
                            localStorage.clear();
                            this.setState({ redirect: true })
                        }
                        if (msg === 'Invalid User') {
                            this.setState({ redirectToNotFound: true })
                        }
                    }
                })
            this.scrollUpFunction(true);
        }
        else {
            this.scrollUpFunction(true);
        }
        this.setState({ error: errorCount, fieldErrorMessage: errorStore });
        event.preventDefault();
    }
    // Question selection in checkbox.
    onChangequestion = (e, questionIndex) => {
        if(questionIndex || questionIndex === 0) {
            var responseResult = this.state.responseResult;
            var fieldIndex = this.state.fieldErrorMessage.indexOf(questionIndex);
            let questionType = e.target.name;
            if (questionType === 'questions') {
                if(responseResult['questiondata'][questionIndex] && responseResult['questiondata'][questionIndex].hasOwnProperty("is_publish")) {
                    if (responseResult['questiondata'][questionIndex]['is_publish']) {
                        responseResult['questiondata'][questionIndex]['is_publish'] = 0;
                        //responseResult['questiondata'][questionIndex]['user_question_type'] = null;
                        if (fieldIndex > -1) {
                            this.state.fieldErrorMessage.splice(fieldIndex, 1);
                        }
                    }
                    else {
                        responseResult['questiondata'][questionIndex]['is_publish'] = 1;
                    }
                }
            }
            if (questionType === 'demographic') {
                if(responseResult['demographic'][questionIndex] && responseResult['demographic'][questionIndex].hasOwnProperty("is_publish")) {
                    if (responseResult['demographic'][questionIndex]['is_publish']) {
                        responseResult['demographic'][questionIndex]['is_publish'] = 0;
                        //responseResult['demographic'][questionIndex]['user_question_type'] = null;
                    }
                    else {
                        responseResult['demographic'][questionIndex]['is_publish'] = 1;
                        responseResult['demographic'][questionIndex]['user_question_type'] = "demographic";
                    }
                }
            }
            this.setState({ responseResult })
        }
    }
    // Change of question-type
    questionTypeChange = (e, questionIndex) => {
        if(questionIndex || questionIndex === 0) {
            var responseResult = this.state.responseResult;
            let questionType = e.target.name;
            if (questionType === 'question') {
                if(responseResult['questiondata'][questionIndex] && responseResult['questiondata'][questionIndex].hasOwnProperty("user_question_type")) {
                    responseResult['questiondata'][questionIndex]['user_question_type'] = e.target.value;
                }
            }
        }
        var fieldIndex = this.state.fieldErrorMessage.indexOf(questionIndex);
        if (fieldIndex > -1) {
            this.state.fieldErrorMessage.splice(fieldIndex, 1);
        }
        this.setState({ responseResult })
    }
    // Display Question in 100 above participate.
    questionDisplay = () => {
        /** For identifying chrome and safari browser */
        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        var issafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        var questiondata = this.state.responseResult['questiondata'];
        var demographicData = this.state.responseResult['demographic'];
        let titleNew = '';
        var questions = [];
        var length = '';
        var demograpghlength = '';
        // Get normal question length
        for(var key in questiondata) {
            if(questiondata.hasOwnProperty(key)) {
                ++length;
            }
        }
        // Normal question display
        for (let i = 0; i < length; i++) {
            if (questiondata) {
                if (questiondata[i]['question_text'] !== '') {
                    titleNew = questiondata[i]['question_text'];
                    let id = questiondata[i]['id'];
                    var span = {
                        color: 'red'
                    }
                    questions.push(<div className="col-md-6 col-lg-4 col-xl-3" key={i}>
                        <div className="blog-item">
                            <div className="blog-content d-flex">
                                <h6 className={(isChrome || issafari) ? "text-dark font-16 custom-test-text" : "text-dark font-16 text-hyphens"}>{titleNew}</h6>
                            </div>
                            <div className="blog-content pb-2 pt-0">
                                <h3 className="font-14 fw-400 mb-0 text-gray">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_question']}</h3>
                            </div>
                            <div className="col-12 pt-0 pb-2">
                                <div className="swich-slider d-flex align-items-center mb-2">
                                    <span class="text-gray font-14 custom-padding-5 pl-0">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_question_yes_button']}</span>
                                    <label className="switch mx-1">
                                        <input type="checkbox" data-toggle="toggle" name="questions" checked={(questiondata[i].is_publish === 1) ? true : false} value={id} id={id} onClick={(e)=>this.onChangequestion(e,i)} />
                                        <span className="slider sliders round"></span>
                                    </label>
                                    <span class="text-gray font-14 custom-padding-5">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_question_no_button']}</span>
                                </div>
                            </div>
                            <div className="col-12 pt-0 pb-2">
                                <div className={(this.state.fieldErrorMessage.filter(a => a === i).length > 0) ? "form-group focus mt-0 mb-0" : "form-group focus mt-0 mb-4"}>
                                    <select className={(this.state.fieldErrorMessage.filter(a => a === i).length > 0) ? "custom-select font-weight-noraml field-highlight question-condition-select" : "custom-select font-weight-noraml question-condition-select" } id={id} name="question" onChange={(e)=>this.questionTypeChange(e,i)} >
                                        <option value="demographic" selected={(questiondata[i].user_question_type === "demographic" ) ? "selected" : ""}>{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['demograpghic_attribute']}</option>
                                        <option value="normal" selected={(questiondata[i].user_question_type === "normal" ) ? "selected" : ""}>{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['question_type']}</option>
                                    </select>
                                </div>
                                <div>
                                    {(this.state.fieldErrorMessage.filter(a => a === i).length > 0) ?
                                        <span style={span}>{FIND_PARTICIPANTS_ERROR_MESSAGE[LANGUAGE_CODE]['reward_type']}</span>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>)
                }
            }
        }
        // Get the demographic question length
        for( var keys in demographicData ) {
            if( demographicData.hasOwnProperty(keys) ) {
                ++demograpghlength;
            }
        }
        // Demographic question display
        for (let i = 0; i < demograpghlength; i++ ) {
            if (demographicData) {
                if (demographicData[i]['question'] !== '') {
                    titleNew = demographicData[i]['question'];
                    let id = demographicData[i]['demographic_id'];
                    questions.push(<div className="col-md-6 col-lg-4 col-xl-3" key={i}>
                        <div className="blog-item">
                            <div className="blog-content d-flex">
                                <h6 className={(isChrome || issafari) ? "text-dark font-16 custom-test-text" : "text-dark font-16 text-hyphens"}>{(titleNew) ? (issafari || isChrome) ? titleNew : titleNew : null}</h6>
                            </div>
                            <div className="blog-content pb-2 pt-0 ">
                                <h3 className="font-14 fw-400 mb-0 text-gray">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_question']}</h3>
                            </div>
                            <div className="col-12 pt-0 pb-2">
                                <div className="swich-slider d-flex align-items-center mb-2">
                                    <span class="text-gray font-14 custom-padding-5 pl-0">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_question_yes_button']}</span>
                                    <label className="switch mx-1">
                                        <input type="checkbox" data-toggle="toggle" name="demographic" checked={(demographicData[i].is_publish === 1) ? true : false} value={id} id={id} onClick={(e)=>this.onChangequestion(e,i)} />
                                        <span className="slider sliders round"></span>
                                    </label>
                                    <span class="text-gray font-14 custom-padding-5">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_question_no_button']}</span>
                                </div>
                            </div>
                            <div className="col-12 pt-0 pb-2">
                                <div className="form-group focus mt-0 mb-4">
                                    <select className="custom-select font-weight-noraml disable question-condition-select" disabled={true} id={id} name="demographic" onChange={(e)=>this.questionTypeChange(e,i)} >
                                        <option value="demographic" selected={(demographicData[i]['user_question_type']  === "demographic" ) ? "selected" : ''}>{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['demograpghic_attribute']}</option>
                                        <option value="normal">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['question_type']}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>)
                }
            }
        }
        return questions;
    }
    /*** for copy text which is url for survey published result details which is in readonly mode in text field */
    copyToClipBoard = (e) => {
        var input = document.getElementById("copyurl");
        var isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);
        if (isiOSDevice) {
            var editable = input.contentEditable;
            var readOnly = input.readOnly;
            input.contentEditable = true;
            input.readOnly = false;
            var range = document.createRange();
            range.selectNodeContents(input);
            var selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            input.setSelectionRange(0, 999999);
            input.contentEditable = editable;
            input.readOnly = readOnly;
        } else {
            this.text.select();
        }
        document.execCommand('copy');
        e.target.focus();
        this.setState({ copyMessage: "true" })
        window.setTimeout(() => {
            this.setState({ copyMessage: "" })
        }, MESSAGE_TIME);
    }
    render() {
        var questions = this.questionDisplay();
        var span = {
            color: 'red'
        }
        var success = {
            color: "green"
        }
        let url = '';
        if (this.state.responseResult['survey'] && this.state.responseResult['survey']['survey_title_slug']) {
            url = BASEURL + URL_CONSTANT['survey_result'] + "/" + this.state.responseResult['survey']['survey_title_slug'];
        }
        var title = '';
        if (localStorage.getItem("surveycreationdata_" + this.surveyMainId)) {
            let data = JSON.parse(localStorage.getItem("surveycreationdata_" + this.surveyMainId));
            title = data['survey_title_slug'];
        } 
        else {
            if (this.state.responseResult['survey'] && this.state.responseResult['survey']['survey_title_slug']) {
                title = this.state.responseResult['survey']['survey_title_slug'];
            }
        }
        var survey_title = '';
        if (this.state.responseResult['survey'] && this.state.responseResult['survey']['survey_title']) {
            survey_title = this.state.responseResult['survey']['survey_title'];
        }
        return (
            <div className="tab-pane fade active show" id="Veröffentlichen" role="tabpanel" aria-labelledby="Veröffentlichen-tab">
                {(this.state.redirect === true) ?
                    <Redirect to='/anmelden'></Redirect>
                    : null
                }
                {(this.state.redirectToNotFound) ?
                    <Redirect to='/404'></Redirect>
                    :null
                }
                <div className="participant-tab">
                    <div className="container-fluid px-0">
                        {(this.state.bubbleLoading === true) ?
                            <div className="d-flex align-items-center justify-content-center" style={{"height" : "300px"}}><i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i></div>
                            : null 
                        }
                        {( this.state.userName && this.state.participate_count !== null ) ?
                            <div className="send-survey-by-link container-md px-0 mb-4">
                                {/* lessthan 100 participate count then text message Display. */}
                                { (this.state.participate_count < NO_OF_PARTICIPANTS_ALLOW['count']) ?
                                    <React.Fragment>
                                        {/* more then 3 System login user participated then display Information Message */}
                                        { (this.state.system_user_count >= NO_OF_SYSTEM_LOGIN_USERS_ALLOW['login_users']) ?
                                            <div className="row mx-0 box-shadow chart-main-block-freetext">
                                                <div className="col-12 col-lg-1 text-center add-answer py-lg-5 py-1 px-0 pl-3 pl-lg-0 mt-2">
                                                    <div className="round-information">
                                                        <i className="fas fa-info icon-m border-radius"></i>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-11 pt-2 px-lg-0 px-3 py-lg-4 pb-3">
                                                    <p className="font-20 text-dark font-weight-bold mb-1 text-left">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['survey_participants_weiting_heading']}</p>
                                                    <p className="mr-lg-4 mr-0 font-16 text-dark mb-1 text-left">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['survey_participants_weiting_subtext1']} <b>{this.state.system_user_count}</b> {EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['survey_participants_weiting_subtext2']}</p>
                                                </div>
                                            </div>
                                            : null
                                        }
                                        <div className="bg-white box-shadow custom-tag-parent chart-main-block-freetext mb-0">
                                            <div className="table-content bg-white">
                                                <h3 className="mb-0 text-dark font-weight-bold text-break">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_message_text'] + " " + this.state.userName + ","}</h3>
                                            </div>
                                            <div className="table-content bg-white pt-2">
                                                <h3 className="font-14 lh-24 fw-400 mb-0">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_message_subtext1']}</h3>
                                                <h3 className="font-14 lh-24 fw-400">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_message_subtext2']}</h3>
                                            </div>
                                            <div className="table-content bg-white pt-2">
                                                <h3 className="font-14 lh-24 fw-400">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_message_participants'] + " "} <b>{this.state.participate_count + "/100"}</b></h3>
                                                <h3 className="font-14 lh-24 fw-400 mb-2 mb-lg-0 pt-2"><b>{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_message_question']}</b></h3>
                                            </div>
                                            <div className="pt-0 pb-4 px-3 px-sm-4" dangerouslySetInnerHTML={{ __html: EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_message_content'] }} />
                                        </div>
                                    </React.Fragment>
                                    :
                                    (this.state.responseResult['survey'] && (this.state.responseResult['survey'].status === 0 || this.state.responseResult['survey'].status === null)) ?
                                        <React.Fragment>
                                            {/* more then 3 System login user participated then display Information Message */}
                                            { (this.state.system_user_count >= NO_OF_SYSTEM_LOGIN_USERS_ALLOW['login_users']) ?
                                                <div className="row mx-0 box-shadow survey-title-box mx-3 mx-sm-4">
                                                    <div className="col-12 col-lg-1 text-center add-answer py-lg-5 py-1 px-0 pl-3 pl-lg-0 mt-2">
                                                        <div className="round-information">
                                                            <i className="fas fa-info icon-m border-radius"></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-11 pt-2 px-lg-0 px-3 py-lg-4 pb-3">
                                                        <p className="font-20 text-dark font-weight-bold mb-1 text-left">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['survey_participants_weiting_heading']}</p>
                                                        <p className="mr-lg-4 mr-0 font-16 text-dark mb-1 text-left">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['survey_participants_weiting_subtext1']} <b>{this.state.system_user_count}</b> {EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['survey_participants_weiting_subtext2']}</p>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            <div className="bg-white custom-tag-parent chart-main-block-freetext mb-0">
                                                <div className="table-content bg-white px-3 px-sm-4">
                                                    <h3 className="mb-0 text-dark font-weight-bold text-break">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_message_question']}</h3>
                                                </div>
                                                <div className="bottom-border-blue px-3 px-sm-4">
                                                </div>
                                                <div className="py-3 px-3 px-sm-4 px-3 px-sm-4" dangerouslySetInnerHTML={{ __html: EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_section1_content'] }} />
                                                <section className="section-blog bg-white pt-0 pb-0 px-0 px-md-2">
                                                    <div className="container container-large">
                                                        <div className="mb-3">
                                                            {(this.state.fieldErrorMessage.filter(a => a === 'questions').length > 0) ?
                                                                <span style={span}>{EDIT_SURVEY_RESULT_ERROR_CONSTANT[LANGUAGE_CODE]['error_message']}</span>
                                                                : null
                                                            }
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-12">
                                                                <div className="blog-lising">
                                                                    <div className="row">
                                                                        { questions }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                            <div className="bg-white custom-tag-parent chart-main-block-freetext mb-0">
                                                <div className="table-content bg-white px-3 px-sm-4">
                                                    <h3 className="mb-0 text-dark font-weight-bold text-break">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_heading_section3']}</h3>
                                                </div>
                                                <div className="bottom-border-blue px-3 px-sm-4">
                                                </div>
                                                <div className="freetext-subtitle px-3 px-sm-4">
                                                    <div className="find-out about-emporio-participants mt-3 mt-md-3 container-md px-0">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className={((this.state.responseResult['survey']['presentation_of_the_survey']) ? "form-group hs-form-field focus" : "form-group hs-form-field") + (this.state.fieldErrorMessage.filter(a => a === 'presentation_text').length > 0 ? " mb-1" : "")}>
                                                                    <textarea className={(this.state.fieldErrorMessage.filter(a => a === 'presentation_text').length > 0) ? "form-control hs-input custom-padding-findparticipation textarea-height field-highlight" : "form-control hs-input custom-padding-findparticipation textarea-height"} id="presentation_text" rows="5" name="presentation_text" onChange={this.handleChange} value={this.state.responseResult['survey']['presentation_of_the_survey'] ? this.state.responseResult['survey']['presentation_of_the_survey'].replace(/<br\s*\/?>/mg, "") : "" }></textarea>
                                                                    <label htmlFor="presentation_text">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_heading_section3']}</label>
                                                                </div>
                                                                <div className="mb-3">
                                                                    {(this.state.fieldErrorMessage.filter(a => a === 'presentation_text').length > 0) ?
                                                                        <span style={span}>{EDIT_SURVEY_RESULT_ERROR_CONSTANT[LANGUAGE_CODE]['error_present_survey']}</span>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-content bg-white px-0 pt-0">
                                                            <h3 className="mb-0 text-dark font-18 font-weight-bold text-break">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['type_of_scientific_work_lable']}</h3>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group focus mb-1">
                                                                        <select className={(this.state.fieldErrorMessage.filter(a => a === 'scientific_work_type').length > 0) ? "custom-select font-weight-noraml field-highlight question-condition-select" : "custom-select font-weight-noraml question-condition-select" } id="scientificWork" name="scientific_work_type" onChange={this.handleChange} value={this.state.responseResult['survey']['type_of_scientific_work'] ? this.state.responseResult['survey']['type_of_scientific_work'] : '' } >
                                                                            <option value="" >{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['default_reward_option']}</option>
                                                                            <option value={EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['seminar_paper']} >{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['seminar_paper']}</option>
                                                                            <option value={EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['bachelor_thesis']} >{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['bachelor_thesis']}</option>
                                                                            <option value={EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['master_thesis']} >{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['master_thesis']}</option>
                                                                            <option value={EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['dissertation']} >{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['dissertation']}</option>
                                                                            <option value={EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['house_work']} >{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['house_work']}</option>
                                                                            <option value={EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['project_work']} >{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['project_work']}</option>
                                                                            <option value={EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['other_option']} >{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['other_option']}</option>
                                                                        </select>
                                                                    </div>
                                                                    <div>
                                                                        {(this.state.fieldErrorMessage.filter(a => a === 'scientific_work_type').length > 0) ?
                                                                            <span style={span}>{EDIT_SURVEY_RESULT_ERROR_CONSTANT[LANGUAGE_CODE]['error_scientific_work']}</span>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group focus mb-1 result-optional-note">
                                                                        <div className={this.state.responseResult['survey']['note'] ? "form-group hs-form-field focus mb-1" : "form-group hs-form-field mb-1"}>
                                                                            <input type="text" className= {(this.state.fieldErrorMessage.filter(a => a === 'note').length > 0) ? "form-control hs-input custom-padding-findparticipation field-highlight height-36" : "form-control hs-input custom-padding-findparticipation height-36"} id="note" name="note" onChange={this.handleChange} value={this.state.responseResult['survey']['note'] ? this.state.responseResult['survey']['note'] : '' } />
                                                                            <label htmlFor="note">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['note']}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {(this.state.fieldErrorMessage.filter(a => a === 'note').length > 0) ?
                                                                            <span style={span}>{EDIT_SURVEY_RESULT_ERROR_CONSTANT[LANGUAGE_CODE]['error_note_message']}</span>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-white custom-tag-parent chart-main-block-freetext mb-0">
                                                <div className="table-content bg-white px-3 px-sm-4">
                                                    <h3 className="mb-0 text-dark font-weight-bold text-break">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_heading_section4']}</h3>
                                                </div>
                                                <div className="bottom-border-blue px-3 px-sm-4">
                                                </div>
                                                <div className="freetext-subtitle py-4 px-3 px-sm-4">
                                                    <h3 className="font-14 lh-24 fw-400 mb-0">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_heading_section4_subtext']}</h3>
                                                    <div className="find-out about-emporio-participants mt-3 mt-md-3 container-md px-0">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className={((this.state.responseResult['survey']['facts_and_odds']) ? "form-group hs-form-field focus" : "form-group hs-form-field") + (this.state.fieldErrorMessage.filter(a => a === 'facts_and_odds').length > 0 ? " mb-1" : "")}>
                                                                    <textarea className={(this.state.fieldErrorMessage.filter(a => a === 'facts_and_odds').length > 0) ? "form-control hs-input custom-padding-findparticipation textarea-height field-highlight mb-0" : "form-control hs-input custom-padding-findparticipation textarea-height"} id="facts_and_odds" rows="5" name="facts_and_odds" onChange={this.handleChange} value={this.state.responseResult['survey']['facts_and_odds'] ? this.state.responseResult['survey']['facts_and_odds'].replace(/<br\s*\/?>/mg, "") : "" } ></textarea>
                                                                    <label htmlFor="facts_and_odds">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_heading_section4']}</label>
                                                                </div>
                                                                <div className="mb-3">
                                                                    {(this.state.fieldErrorMessage.filter(a => a === 'facts_and_odds').length > 0) ?
                                                                        <span style={span}>{EDIT_SURVEY_RESULT_ERROR_CONSTANT[LANGUAGE_CODE]['error_facts_odds']}</span>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="publish-survey text-center my-3 mt-md-4 mb-md-4">
                                                <button type="button" onClick={(e) => this.handleSubmit(e)} className="btn btn-clr text-white text-uppercase font-18 disable custom-disable" title={EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_button'] }>{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_result_button'] }</button>
                                            </div>
                                        </React.Fragment>
                                    :
                                    (this.state.responseResult['survey'] && this.state.responseResult['survey'].status === 1 && this.state.statisticsData && Object.keys(this.state.statisticsData).length)
                                        ? <React.Fragment>
                                            <div className="bg-white box-shadow custom-tag-parent chart-main-block-freetext">
                                                <div className="table-content bg-white px-3 px-sm-4">
                                                    <h3 className="mb-0 text-dark font-weight-bold text-break">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['send_survey_link_heading']}</h3>
                                                </div>
                                                <div className="bottom-border-blue px-3 px-sm-4" name="hidden-tag-chart">
                                                </div>
                                                <div className="freetext-subtitle py-4 px-3 px-sm-4" name="hidden-tag-chart">
                                                    <h3 className="font-14 lh-24 fw-400">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['send_survey_link_subtitle']}</h3>
                                                    <div className="form-group hs-form-field focus mb-3 mt-4">
                                                        <input type="text" name="enter the link here" className="form-control hs-input" id="copyurl" readOnly value={url} ref={(text) => this.text = text} />
                                                        <div className="input-group-append">
                                                            <div className="icon-for-copy">
                                                                <button type="button" className="btn-copy" onClick={this.copyToClipBoard} title={EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['copy_to_clipboard']}><i className="far fa-copy"></i></button>
                                                            </div>
                                                        </div>
                                                        <div className="validation-message" style={success}>{(this.state.copyMessage === "true") ? EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['copied_message'] : null}</div>
                                                    </div>
                                                    <div className="text-center mt-4">
                                                        <FacebookShareButton
                                                            className="mr-1 mr-sm-3"
                                                            url={url}
                                                            quote={EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['default_text_external_link'] + ' "' + survey_title + '".'} >
                                                            <FacebookIcon size={40} round={true} bgStyle={{"fill": "#00b3f4"}} />
                                                        </FacebookShareButton>
                                                        <WhatsappShareButton
                                                            className="mr-1 mr-sm-3"
                                                            url={url}
                                                            title={EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['default_text_external_link'] + ' "' + survey_title + '".'}
                                                            hashtag="#Here is the link to the results of my survey">
                                                            <WhatsappIcon size={40} round={true} bgStyle={{"fill": "#00b3f4"}} />
                                                        </WhatsappShareButton>
                                                        <LinkedinShareButton
                                                            className="mr-1 mr-sm-3"
                                                            url={url}
                                                            title={EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['default_text_external_link'] + ' "' + survey_title + '".'}
                                                            hashtag="#Here is the link to the results of my survey">
                                                            <LinkedinIcon size={40} round={true} bgStyle={{"fill": "#00b3f4"}} />
                                                        </LinkedinShareButton>
                                                        <TwitterShareButton
                                                            className="mr-1 mr-sm-3"
                                                            url={url}
                                                            title={EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['default_text_external_link'] + ' "' + survey_title + '".'}
                                                            hashtag="#Here is the link to the results of my survey">
                                                            <TwitterIcon size={40} round={true} bgStyle={{"fill": "#00b3f4"}} />
                                                        </TwitterShareButton>
                                                        <EmailShareButton
                                                            subject = {EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['default_text_external_link'] + ' "' + survey_title + '".'}
                                                            url={url}
                                                            hashtag="#Here is the link to the results of my survey">
                                                            <EmailIcon size={40} round={true} bgStyle={{"fill": "#00b3f4"}} />
                                                        </EmailShareButton>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-white box-shadow custom-tag-parent chart-main-block-freetext">
                                                <div className="table-content bg-white px-3 px-sm-4">
                                                    <h3 className="mb-0 text-dark font-weight-bold text-break">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['heading_result_publish']}</h3>
                                                </div>
                                                <div className="bottom-border-blue px-3 px-sm-4">
                                                </div>
                                                <div className="freetext-subtitle py-4 px-3 px-sm-4">
                                                    <h3 className="font-14 lh-24 fw-400 mb-0">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['subheading_result_publish'] + " "}{<span style={{ "color": "#00b3f4", "text-decoration": "underline", "cursor": "pointer" }}><Link to={"/" + URL_CONSTANT['survey_result'] + "/" + title}>{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['survey_mypoll_link']}</Link></span>}{" " + EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['subheading_result_publish2']}</h3>
                                                    <div className="find-out about-emporio-participants mt-3 mt-md-3 container-md px-0">
                                                        <div className="row py-4 py-lg-0 my-lg-5">
                                                            <div className="col-12">
                                                                <div className="count-box counter auto-height bg-white box-border">
                                                                    <div className="row no-gutters">
                                                                        <div className="col-12 col-md pt-3 pt-md-0">
                                                                            <div className="counter-col">
                                                                                <div className="counter-data custom-static-data">
                                                                                    <div className="count">
                                                                                        {this.state.statisticsData.visited_resultpage
                                                                                            ? <CountUp end={this.state.statisticsData.visited_resultpage} />
                                                                                            : 0
                                                                                        }
                                                                                    </div>
                                                                                    <h6 className="font-18 text-truncate">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['calls']}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md pt-3 pt-md-0">
                                                                            <div className="counter-col">
                                                                                <div className="counter-data custom-static-data">
                                                                                    <span className="count">
                                                                                        {(this.state.statisticsData.publish_date && this.state.statisticsData.publish_date.split("-").length && this.state.statisticsData.publish_date.split("-")[0])
                                                                                            ? this.state.statisticsData.publish_date.split("-")[0]
                                                                                            : "00"
                                                                                        }</span>
                                                                                    <span className="count">.</span>
                                                                                    <span className="count">
                                                                                        {(this.state.statisticsData.publish_date && this.state.statisticsData.publish_date.split("-").length && this.state.statisticsData.publish_date.split("-")[1])
                                                                                            ? this.state.statisticsData.publish_date.split("-")[1]
                                                                                            : "00"
                                                                                        }
                                                                                    </span>
                                                                                    <span className="count">.</span>
                                                                                    <span className="count">
                                                                                        {(this.state.statisticsData.publish_date && this.state.statisticsData.publish_date.split("-").length && this.state.statisticsData.publish_date.split("-")[2])
                                                                                            ? this.state.statisticsData.publish_date.split("-")[2]
                                                                                            : "0000"
                                                                                        }
                                                                                    </span>
                                                                                    <h6 className="font-18 text-truncate">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['since_published']}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md pt-3 pt-md-0">
                                                                            <div className="counter-col br-none">
                                                                                <div className="counter-data custom-static-data">
                                                                                    <div className="count">
                                                                                        {this.state.statisticsData.karmapoint
                                                                                            ? <CountUp end={this.state.statisticsData.karmapoint} />
                                                                                            : 0
                                                                                        }
                                                                                    </div>
                                                                                    <h6 className="font-18 text-truncate">{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['karma_point_received']}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="publish-survey text-center my-3 mt-md-4 mb-md-4">
                                                        <button type="button" onClick={(e)  => this.handleSubmit(e,true)} className="btn btn-clr text-white text-uppercase font-18 disable custom-disable" title={EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_update_result_button'] }>{EDIT_SURVEY_RESULT_CONSTANT[LANGUAGE_CODE]['publish_update_result_button'] }</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    : null
                                }
                            </div>
                        : null}
                    </div>
                </div>
            </div>
        )
    }
}
export default PublishResult;