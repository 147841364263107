import React from "react";
import axios from "axios";
import { MYPROFILE_SETTING_ERROR_MESSAGE, MYPROFILE_SETTINGS_CONSTANT, BUBBLE_LOADING, MYPROFILE_MAINPROFILE_CONSTANT } from "../../constants/FrontEndConstant";
import { LANGUAGE_CODE, MESSAGE_TIME, FRONT_USER, API_URL,BASEURL } from '../../constants/DotEnv';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';
import { removeUser } from "../../actions/index";
import 'react-confirm-alert/src/react-confirm-alert.css'
function mapDispatchToProps(dispatch) {
    return {
        removeUser: userdata => dispatch(removeUser(userdata)),
    };
}
class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 'false',
            notification: false,
            newsletter: false,
            autoprofile: false,
            errorCount: 0,
            responseMessage: '',
            successMessage : '',
            message: '',
            errorKeyName: [],
            redirectLogin: 'false',
            password: "",
            confirm_password: "",
            reason_delete_account: '',
            accountType: [],
            usertype: null,
            account_type_category_id:null,
            quick_click_msg_show: true,
            plan_end_date : '',
            change_text : null,
            reason_cancle_subscription : '',
            active_plus_plan: '',
            user_ID: null
        }
        this.validPassword = true;
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.getData();
        this.getAccountTypes();
        var userdata = JSON.parse(localStorage.getItem('userdata'))
        this.setState({ active_plus_plan: userdata.plan_type })
        this.setState({ user_ID: userdata.account_type_category_id})
        if(userdata.account_type_category_id == 4){
            this.setState({quick_click_msg_show: false})
        }
        if(userdata.plan_type && (userdata.IsSubscriptioncancle === null || userdata.IsSubscriptioncancle === 0)){
            this.setState({change_text: 0})
        }
        const end_date = userdata.plan_end_date
        if(userdata.plan_type){
            var plan_end_date = end_date.substring(0, 10).split("-").join(".")
            this.setState({plan_end_date: plan_end_date})
        }
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (document.querySelector('.navbar-toggler')) {
            let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
            if (count === -1) {
                document.querySelector('.navbar-collapse').classList.remove('show');
                document.querySelector('header').classList.remove("header_collapse");
                document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
            }
        }
    }
    getAccountTypes = async () => {
        await axios.get(API_URL + "/common/getAccountTypes/" + LANGUAGE_CODE)
       .then((response) => {
           this.setState({accountType:  response.data.success.AccountTypes})
       })
       .catch((error) => {
           // console.log("error", error.response)
       })
       let userdata =  JSON.parse(localStorage.getItem('userdata'))
       if(userdata.account_type){
        this.setState({usertype:userdata.account_type})
       }
   }
    getData = () => {
        let userData = this.props.data;
        // eslint-disable-next-line
        Object.keys(userData).map((key, index) => {
            let keyNew = key;
            let value = userData[key];
            if (keyNew === 'notification' || keyNew === 'newsletter' || keyNew === 'autoprofile') {
                if (value !== null) {
                    if (value === 1) { value = true; }
                    else { value = false; }
                    this.setState({ [keyNew]: value })
                }
            }
            this.setState({ 'email': JSON.parse(localStorage.getItem('userdata')).email })
        });
        return 0;
    }
    handleSubmit = (event) => {
        let userdata = JSON.parse(localStorage.getItem('userdata'))
        var quick_click = this.state.autoprofile;
        if(this.state.account_type_category_id == 4){
            quick_click = false
            this.setState({autoprofile :false})
        }else{
            quick_click = this.state.autoprofile
        }
        var formfield = {
            id: JSON.parse(localStorage.getItem('userdata')).id,
            email: this.state.email,
            password: this.state.password,
            confirm_password: this.state.confirm_password,
            notification: this.state.notification,
            newsletter: this.state.newsletter,
            autoprofile: quick_click,
            account_type: this.state.usertype,
            account_type_category_id: this.state.account_type_category_id,
            is_account_type_change: 0
        }
        var errorKeyName = [];
        this.setState({ errorKeyName })
        var count = 0;
        var errorCount = '';
        let passwordValidation = false;
        let confirmpasswordValidation = false;
        for (var key in formfield) {
            if (formfield.hasOwnProperty(key)) {
                const valueNew = formfield[key];
                if (valueNew === '' || valueNew === null || valueNew === undefined) {
                    // eslint-disable-next-line
                    if (key === 'email') {
                        errorKeyName.push(key);
                        errorCount = count + 1;
                        count = errorCount;
                    }
                    else {
                        if (key === 'password') {
                            passwordValidation = true;
                        }
                        if (key === 'confirm_password') {
                            confirmpasswordValidation = true;
                        }
                    }
                }
                else {
                    if (key === 'email') {
                        // eslint-disable-next-line 
                        var emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        var checkEmail = emailRE.test(valueNew);
                        if (checkEmail === false) {
                            errorKeyName.push(key + "validation");
                            errorCount = count + 1;
                            count = errorCount;
                        }
                    }
                    if (key === 'password' && valueNew.length < 8) {
                        errorKeyName.push("passwordLengthValidation");
                        errorCount = count + 1;
                        count = errorCount;
                    }
                }
            }
        }
        if (passwordValidation === true && confirmpasswordValidation === false) {
            errorKeyName.push("password");
            errorCount = count + 1;
            count = errorCount;
        }
        if (passwordValidation === false && confirmpasswordValidation === true) {
            errorKeyName.push("confirm_password");
            errorCount = count + 1;
            count = errorCount;
        }
        this.setState({ errorCount: count, errorKeyName });
        if (count === undefined || count === 0) {
            this.changeUserDataSetting(formfield);
        }
        event.preventDefault();
    }
    // Reason for account delete in validation check.
    handleClickValidation = (event, onClose) => {
        var count = 0;
        let value = this.state.reason_delete_account
        if (value === '' || value === null || value === undefined) {
            count = count + 1;
        }
        if (count === undefined || count === 0) {
            this.handleClickDelete(event)
            onClose()
        }
        else {
            if (count){
                if (!document.getElementById("error_reason")) {
                    var id = document.getElementById("reasons");
                    var node = document.createElement("span");
                    node.innerText = MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]["reason_error_delete_account"];
                    node.setAttribute("style","color: red;")
                    node.setAttribute("id","error_reason")
                    id.appendChild(node);
                }
                else {
                    var error_id = document.getElementById("error_reason");
                    if(error_id) {
                        error_id.removeAttribute("class")
                    }
                }
            }
        }
        event.preventDefault();
    }
    handleClickValidationsubscription = (event, onClose) => {
        var count = 0;
        let value = this.state.reason_cancle_subscription
        if (value === '' || value === null || value === undefined) {
            count = count + 1;
        }
        if (count === undefined || count === 0) {
            this.cancelSubscripton(event)
            onClose()
        }
        else {
            if (count){
                if (!document.getElementById("error_reason")) {
                    var id = document.getElementById("reasons");
                    var node = document.createElement("span");
                    node.innerText = MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]["reason_error_sub_cancel"]
                    node.setAttribute("style","color: red;")
                    node.setAttribute("id","error_reason")
                    id.appendChild(node);
                }
                else {
                    var error_id = document.getElementById("error_reason");
                    if(error_id) {
                        error_id.removeAttribute("class")
                    }
                }
            }
        }
        event.preventDefault();
    }
    changeUserDataSetting = async (formfield) => {
        this.setState({ loading: 'true' })
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let userId = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
            userId = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        var data = {
            id: formfield.id,
            email: formfield.email,
            password: formfield.password,
            confirm_password: formfield.confirm_password,
            notification: formfield.notification,
            newsletter: formfield.newsletter,
            autoprofile: formfield.autoprofile,
            languagecode: LANGUAGE_CODE,
            account_type: parseInt(formfield.account_type),
        }
        if (formfield.newsletter === true) {
            data['first_name'] = JSON.parse(localStorage.getItem('userdata')).first_name;
            data['last_name'] = JSON.parse(localStorage.getItem('userdata')).last_name;
        }
        if (!(data.password && data.confirm_password)) {
            delete data.password;
            delete data.confirm_password;
        }
        let self = this;
        // eslint-disable-next-line
        await axios.post(API_URL + "/userprofile/updateprofile" + '?uId=' + userId, data, { headers: headers })
            .then((response) => {
                self.setState({ loading: 'false', message: 'successfull', responseMessage: response.data['success'], successMessage : response.data['message'] ? response.data['message'] : '' });
                if (response.data['success']) {
                    // Get the existing datacons
                    var existing = localStorage.getItem('userdata');
                    // If no existing data, create an array
                    // Otherwise, convert the localStorage string to an array
                    existing = existing ? JSON.parse(existing) : {};
                    // Add new data to localStorage Array
                    for (var key in data) {
                        if (formfield.hasOwnProperty(key)) {
                            if (key !== 'password' && key !== 'confirm_password' && key !== 'languagecode') {
                                const value = data[key];
                                existing[key] = value;
                            }
                        }
                    }
                    // Save back to localStorage
                    localStorage.setItem('userdata', JSON.stringify(existing));
                }
                window.setTimeout(() => {
                    self.setState({ message: '',successMessage:'', errorCount: 0, password: "", confirm_password: "" })
                }, MESSAGE_TIME);
                let accounttype = JSON.parse(localStorage.getItem('accountType'))
                    let  account_type_category_id = accounttype.filter((item) => item.id == this.state.usertype)
                    var user = account_type_category_id[0].account_type_category_id
                    existing["account_type_category_id"] = account_type_category_id[0].account_type_category_id
                    existing["logo_image"] = account_type_category_id[0].logo_image
                    existing["logo_image1"] = account_type_category_id[0].logo_image1
                    this.setState({user_ID: user})
                    this.props.getUserAccountID(user)
                    if(user == 4){
                        this.setState({quick_click_msg_show : false})
                    }else{
                        this.setState({quick_click_msg_show : true})
                    }
                    localStorage.setItem('userdata', JSON.stringify(existing))
            })
            .catch((error) => {
                let errors = ''
                if(Object.keys(error.response.data['error']).length === 1){
                    errors =  error.response.data.error[0]
                }else{
                    errors = error.response.data['error']
                }
                self.setState({ loading: 'false', message: 'error', responseMessage: errors });
                window.setTimeout(() => {
                    self.setState({ message: '', errorCount: 0 })
                }, MESSAGE_TIME);
            })
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

    }
    onKeyDownPassword = (event) =>{
        var key = event ? event.which : window.event.keyCode;
        if (key === 32) {
            this.validPassword = false;
            return false;
        }
    }
    handleChange = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if(name === "password" || name === "confirm_password"){
            if(this.validPassword) {
                value = value.replace(/\s/g,'');
                this.setState({ [name]: value });
            }
            else {
                this.validPassword = true;
            }
        }
        else if(name === "reason_delete_account"){
            if(value.replace(/\s/g,'').length || value.length === 0) {
                this.setState({ [name] : value });
            }
            var id = document.getElementById("error_reason");
            if(id) {
                id.setAttribute("class","d-none")
            }
            this.handleDeleteAccount(event)
        }
        else if(name === "reason_cancle_subscription"){
            if(value.replace(/\s/g,'').length || value.length === 0) {
                this.setState({ [name] : value });
            }
            var id = document.getElementById("error_reason");
            if(id) {
                id.setAttribute("class","d-none")
            }
            this.cancelSubscriptonPopUp(event)
        }
        else {
            this.setState({ [name]: value });
        }
        if(name === "usertype"){
            var data = this.state.accountType.filter((item) => item.id == value)
            this.setState({account_type_category_id:  data[0].account_type_category_id})
        }
        if(name === "confirm_password" && value === "" && value === this.state.password) {
            this.state.errorKeyName.splice(this.state.errorKeyName.indexOf("confirm_password"), 1);
            this.state.errorKeyName.splice(this.state.errorKeyName.indexOf("password"), 1);
            this.state.errorKeyName.splice(this.state.errorKeyName.indexOf("passwordLengthValidation"), 1);
        }
        if(name === "password" && value === "" && this.state.confirm_password === value) {
            this.state.errorKeyName.splice(this.state.errorKeyName.indexOf("confirm_password"), 1);
            this.state.errorKeyName.splice(this.state.errorKeyName.indexOf("password"), 1);
            this.state.errorKeyName.splice(this.state.errorKeyName.indexOf("passwordLengthValidation"), 1);
        }
        var index = this.state.errorKeyName.indexOf(name);
        var indexRE = this.state.errorKeyName.indexOf(name + "validation");
        var indexREPwd = this.state.errorKeyName.indexOf(name + "LengthValidation");
        if (index > -1) {
            this.state.errorKeyName.splice(index, 1);
        }
        if (indexRE > -1) {
            this.state.errorKeyName.splice(indexRE, 1);
        }
        if (indexREPwd > -1 && name === "password" && value.length > 7) {
            this.state.errorKeyName.splice(indexREPwd, 1);
        }
    }
    handleChangeToggle = (event) => {
        const name = event.target.name;
        const value = event.target.checked;
        this.setState({ [name]: value });
    }
    handleDeleteAccount = (event) => {
        var apiToken;
        if (JSON.parse(localStorage.getItem('userdata'))) {
            apiToken = JSON.parse(localStorage.getItem('userdata')).token;
        }
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="delete-account-popup">
                        <div className='custom-ui position-relative share_popup'>
                            <div className="close-btn text-right">
                                <button className="d-inline-block bg-white border-0 w-auto sharing-close-button text-rgb" onClick={(event) => {
                                    this.setState({reason_delete_account : ''})
                                    onClose()
                                }}><i className="ti-close pointer"></i></button>
                            </div>
                            <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin pr-4 pr-sm-0">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['delete_account_heading_message']}</h3>
                            <p className="mb-4 text-left">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['delete_account_subtext_message']}</p>
                            <div className={"form-group hs-form-field focus"} id="reasons">
                                <textarea className={"form-control hs-input textarea-height mb-0 p-3"} id="reason_delete_account" rows="5" name="reason_delete_account" onChange={this.handleChange} value={this.state.reason_delete_account} ></textarea>
                            </div>
                            <p>{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['delete_account_popup_message']}</p>
                            <button name={apiToken} onClick={(event) => {
                                this.handleClickValidation(event, onClose)
                            }}>{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['delete_account_popup_yes_button']}</button>
                            <button className="btn-bgcolor" onClick={(event) => {
                                this.setState({reason_delete_account : ''})
                                onClose()
                            }}>{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['delete_account_popup_cancel_button']}</button>
                        </div>
                    </div>
                )
            }
        })
        event.preventDefault();
    }
    UserCanNotDelteAccount = (event) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                     <div className='custom-ui'>
                     <div className="close-btn text-right">
                             <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                     </div>
                     <p class="font-weight-bold">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]["UserCanNotDelteAccount"]}</p>
             </div>
                )
            }
        })
        event.preventDefault();
    }
    handleClickDelete = async (event) => {
        let token = event.target.name;
        this.setState({ loading: 'true' })
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let userid = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
            userid = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        let data = '';
        let self = this;
        var mailData = {
            reasonForDeleteAccount: this.state.reason_delete_account.replace(/<br\s*\/?>/mg, "").replace( /(<([^>]+)>)/ig, '').replace(/(?:\r\n|\r|\n)/g, '<br>'),
            email: storeitem.email,
            first_name: storeitem.first_name,
            last_name: storeitem.last_name
        }
        await axios.post(API_URL + FRONT_USER + "/reasonForDeleteAccount/" + userid + '?uId=' + userid, mailData, { headers: headers })
            .then((response) => {
            })
            .catch((error) => {
                let msg = error.response.data['error']
                if (msg === 'Expired token' || msg === 'Invalid token') {
                    localStorage.clear();
                    self.setState({redirectLogin: 'true' })
                }
            })
        await axios.delete(API_URL + FRONT_USER + "/delete/" + userid + '?uId=' + userid, { headers: headers })
            .then((response) => {
                data = response.data['success'];
                self.props.removeUser({ token });
                if (data) {
                    localStorage.clear();
                }
                self.setState({ loading: 'false', redirectLogin: 'true' })
            })
            .catch((error) => {
                let msg = error.response.data['error']
                if (msg === 'Expired token' || msg === 'Invalid token') {
                    self.props.removeUser({ token });
                    localStorage.clear();
                }
                self.setState({ loading: 'false' })
            })
        self.setState({ loading: 'false' })
    }
    cancelSubscriptonPopUp = (event) => {
        var apiToken;
        if (JSON.parse(localStorage.getItem('userdata'))) {
            apiToken = JSON.parse(localStorage.getItem('userdata')).token;
        }
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="delete-account-popup">
                        <div className='custom-ui position-relative share_popup'>
                            <div className="close-btn text-right">
                                <button className="d-inline-block bg-white border-0 w-auto sharing-close-button text-rgb" onClick={(event) => {
                                    this.setState({reason_cancle_subscription : ''})
                                    onClose()
                                }}><i className="ti-close pointer"></i></button>
                            </div>
                            <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin pr-4 pr-sm-0">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['cancle_subscripton_heading']}</h3>
                            <p className="mb-4 text-left">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['cancle_subscripton_text']}</p>
                            <div className={"form-group hs-form-field focus"} id="reasons">
                                <textarea className={"form-control hs-input textarea-height mb-0 p-3"} id="reason_cancle_subscription" rows="5" name="reason_cancle_subscription" onChange={this.handleChange} value={this.state.reason_cancle_subscription} ></textarea>
                            </div>
                            <button onClick={(event) => {this.handleClickValidationsubscription(event, onClose)
                            }}>{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['delete_account_popup_yes_button']}</button>
                            <button className="btn-bgcolor" onClick={(event) => {
                                onClose()
                            }}>{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['delete_account_popup_cancel_button']}</button>
                        </div>
                    </div>
                )
            }
        })
        event.preventDefault();
    }
    cancelSubscripton = async (event, onClose) =>{
        this.setState({ loading: 'true' })
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let userid = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
            userid = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
      var mailData = {
        reasonforcancelsubscription: this.state.reason_cancle_subscription.replace(/<br\s*\/?>/mg, "").replace( /(<([^>]+)>)/ig, '').replace(/(?:\r\n|\r|\n)/g, '<br>'),
        email: storeitem.email,
        first_name: storeitem.first_name,
        last_name: storeitem.last_name
    }
    await axios.post(API_URL + "/payment/reasonforcancelsubscription/" + userid + '?uId=' + userid, mailData, { headers: headers })
        .then( async (response) => {
            if(response.data.success){
                await axios.post(API_URL + '/payment/cancelsubscripton/' + userid+ '?uId=' + userid, {},
                { headers: headers }).then((res)=>{
                    this.setState({change_text: 1, successMessage : MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]["cancel_sub_success_msg"], loading: 'false'} )
                    let data =  JSON.parse(localStorage.getItem('userdata'));
                    data['IsSubscriptioncancle'] = 1
                    localStorage.setItem("userdata",JSON.stringify(data))
                    window.setTimeout(() => {
                        this.setState({successMessage:'', errorCount: 0})
                    }, MESSAGE_TIME);
                    window.location =  BASEURL + "mein-bereich";
                    onClose();
                })
                .catch((err)=> { onClose();
                this.setState({error:err.response.data.error, loading:false});
            })
            }
        })
        .catch((error) => {
        })
       /**for scrolling up page */
       document.body.scrollTop = 0;
       document.documentElement.scrollTop = 0;
    }
    redirectToCheckoutPage = () =>{
        let userdata = JSON.parse(localStorage.getItem('userdata'));
        var userID = userdata.account_type_category_id
            if(userID == 2){
            window.location.href = '/university-checkout'
        }else if(userID == 4){
            window.location.href = '/business-checkout'
        }
        localStorage.setItem('redirectURL',window.location.href)
    }
    render() {
        var span = {
            color: 'red'
        }
        var apiToken;
        var userdata = JSON.parse(localStorage.getItem('userdata'));
        if (JSON.parse(localStorage.getItem('userdata'))) {
            apiToken = JSON.parse(localStorage.getItem('userdata')).token;
        }
        let myprofileactivetab = localStorage.getItem('myprofileactivetab');
        if (myprofileactivetab) {
            myprofileactivetab = parseInt(myprofileactivetab);
        }
        let typeData = '';
        let FinalDropdownListOfAccountType =this.state.accountType;
        if (FinalDropdownListOfAccountType !== null) {
            const type = FinalDropdownListOfAccountType;
            typeData = type.map((type, index) => {
             return  <option value={type.id} key={index}>{type.account_type}</option>
            })
        }
        return (
            <div className={(this.props.myprofileactivetab === 4) ? "tab-pane fade show active" : "tab-pane fade"} id="einstellungen" role="tabpanel" aria-labelledby="einstellungen-tab">
                <div className="notifying-data">
                    <div className="row">
                        <div className="col-12">
                            <div className="notifying-data-form md-m-30px-b">
                                {(this.state.redirectLogin === 'true') ?
                                    <Redirect to="/anmelden"></Redirect>
                                    : null
                                }
                                {(this.state.loading === 'true') ?
                                    BUBBLE_LOADING
                                    : null
                                }
                                {(this.state.message === 'successfull') ?
                                    <div className='alert alert-success'>
                                        {this.state.responseMessage}
                                    </div>
                                    : null
                                }
                                {(this.state.successMessage) ?
                                    <div className='alert alert-success'>
                                        {this.state.successMessage}
                                    </div>
                                    : null
                                }
                                {(this.state.message === 'error') ?
                                    <div className='alert alert-danger'>
                                        {this.state.responseMessage}
                                    </div>
                                    : null
                                }
                                <form className="" onSubmit={this.handleSubmit}>
                                    <div className="row chart-main-block-freetext mb-4 pb-2">
                                        <div className="col-12">
                                            <h3 className="text-dark font-weight-bold text-break mb-0">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['credentials']}</h3>
                                        </div>
                                        <div className="col-12 bottom-border-blue px-3" name="hidden-tag-chart">
                                        </div>
                                    </div>
                                    <div className="row mt-3 mt-md-4">
                                        <div className="col-md-6">
                                            <div className="form-group hs-form-field focus">
                                                <input type="text" name="email" autoCapitalize="none" onChange={this.handleChange} value={this.state.email} className={(this.state.errorKeyName.filter(errorKeyName => errorKeyName === 'email' || errorKeyName === 'emailvalidation').length > 0) ? " form-control hs-input field-highlight" : this.state.email === '' ? "form-control hs-input yellow-border" :"form-control hs-input"} />
                                                <label htmlFor="email">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['email_address']}</label>
                                                {(this.state.errorKeyName.filter(errorKeyName => errorKeyName === 'email' || errorKeyName === 'emailvalidation').length > 0) ?
                                                    <span style={span}>{MYPROFILE_SETTING_ERROR_MESSAGE[LANGUAGE_CODE][[this.state.errorKeyName.filter(errorKeyName => errorKeyName === 'email' || errorKeyName === 'emailvalidation')]]}</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group hs-form-field">
                                                <input type="password" name="password" onChange={this.handleChange} className={(this.state.errorKeyName.filter(errorKeyName => errorKeyName === 'password' ||  errorKeyName === 'passwordLengthValidation').length > 0 ) ? " form-control hs-input field-highlight" : "form-control hs-input"} value={this.state.password} onKeyDown={(event) => this.onKeyDownPassword(event)}/>
                                                <label htmlFor="password">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['new_password']}</label>
                                                <div>
                                                    {(this.state.errorKeyName.filter(errorKeyName => errorKeyName === 'password').length > 0) ?
                                                        <span style={span}>{MYPROFILE_SETTING_ERROR_MESSAGE[LANGUAGE_CODE]['password']}</span>
                                                        : null
                                                    }
                                                    {(this.state.errorKeyName.filter(errorKeyName => errorKeyName === 'passwordLengthValidation').length > 0) ?
                                                        <span style={span}>{MYPROFILE_SETTING_ERROR_MESSAGE[LANGUAGE_CODE]['passwordLength']}</span>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-6">
                                            <div className="form-group hs-form-field">
                                                <input type="password" name="confirm_password" onChange={this.handleChange} className={(this.state.errorKeyName.filter(errorKeyName => errorKeyName === 'confirm_password').length > 0) ? " form-control hs-input field-highlight" : "form-control hs-input"} value={this.state.confirm_password} onKeyDown={(event) => this.onKeyDownPassword(event)}/>
                                                <label htmlFor="confirm_password">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['repeat_new_password']}</label>
                                                <div>
                                                    {(this.state.errorKeyName.filter(errorKeyName => errorKeyName === 'confirm_password').length > 0) ?
                                                        <span style={span}>{MYPROFILE_SETTING_ERROR_MESSAGE[LANGUAGE_CODE]['confirm_password']}</span>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3 mt-md-5 chart-main-block-freetext mb-4 pb-2">
                                        <div className="col-12">
                                            <h3 className="text-dark font-weight-bold text-break mb-0">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['notification']}</h3>
                                        </div>
                                        <div className="col-12 bottom-border-blue px-3" name="hidden-tag-chart">
                                        </div>
                                    </div>
                                    <div className="row mt-md-4">
                                        <div className="col-lg-1 col-sm-2 col-3">
                                            <div className="swich-slider d-flex align-items-center">
                                                <label className="switch">
                                                    <input type="checkbox" data-toggle="toggle" name="notification" checked={this.state.notification} onClick={this.handleChangeToggle} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-11 col-sm-10 col-9">
                                            <span className="text-dark">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['notification_about_activities']}</span>
                                        </div>
                                    </div>
                                    <div className="row pt-3">
                                        <div className="col-lg-1 col-sm-2 col-3">
                                            <div className="swich-slider d-flex align-items-center">
                                                <label className="switch">
                                                    <input type="checkbox" name="newsletter" checked={this.state.newsletter} onClick={this.handleChangeToggle} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-11 col-sm-10 col-9">
                                            <span className="text-dark">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['notification_about_newsletter']}</span>
                                        </div>
                                    </div>
                                    {/* Registration drop-down */}
                                    <div className="row mt-3 mt-md-5 chart-main-block-freetext mb-4 pb-2">
                                        <div className="col-12">
                                            <h3 className="text-dark font-weight-bold text-break mb-0">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['quick_click_NSU']}</h3>
                                        </div>
                                        <div className="col-12 bottom-border-blue px-3" name="hidden-tag-chart">
                                        </div>
                                    </div>
                                    <div className="row mt-3 mt-md-4">
                                    <div className="col-md-6">
                                        <div className="form-group hs-form-field focus">
                                        <div className="form-group focus">
                                            <select className={(this.state.usertype == 0) ? "custom-select yellow-border" : "custom-select"} onChange={this.handleChange} id="wahle dein" name="usertype" value={ this.state.usertype} disabled={userdata ? userdata.is_account_type_change == 1 || userdata.plan_type ? true : false : null} >
                                                {typeData}
                                            </select>
                                        </div>
                                        </div>
                                        </div>
                                    </div>
                                     {/* Registration drop-down */}
                                    {this.state.quick_click_msg_show ?
                                    <>
                                    <div className="row mt-3 mt-md-5 chart-main-block-freetext mb-4 pb-2">
                                        <div className="col-12">
                                            <h3 className="text-dark font-weight-bold text-break text-capitalize mb-0">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['miscellaneous']}</h3>
                                        </div>
                                        <div className="col-12 bottom-border-blue bottom-border-blue-sm px-3" name="hidden-tag-chart">
                                        </div>
                                    </div>
                                        <div className="row mt-4">
                                            <div className="col-lg-1 col-sm-2 col-3">
                                                <div className="swich-slider d-flex align-items-center">
                                                    <label className="switch">
                                                        <input type="checkbox" name="autoprofile" checked={this.state.autoprofile} onClick={this.handleChangeToggle} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-sm-10 col-9">
                                                <span className="text-dark">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['notification_about_quickclick']}</span>
                                            </div>
                                        </div>
                                        </>
                                     : null
                                    }
                                      <div className="row no-gutters sonstiges">
                                        { this.state.user_ID == 4 || this.state.user_ID == 2?
                                        this.state.active_plus_plan ?
                                        (this.state.change_text == 0 || (userdata && userdata.IsSubscriptioncancle != 1)) ?
                                            <div className="col-12 mt-3">
                                                <p className="text-dark mb-0">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['plus_membership_active_msg_category_D']} {this.state.plan_end_date} {MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['plus_membership_active_msg_category2_D']}
                                                <span onClick={this.cancelSubscriptonPopUp} className="text-gray custom-button-link" > {MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['plus_membership_active_link_category_D']}</span></p>
                                            </div>
                                            : <div className="col-12 mt-3">
                                                <p className="text-dark mb-0">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['plus_membership_cancel_msg_category_D']} {this.state.plan_end_date} {MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['plus_membership_cancel_msg_category2_D']}
                                                <span onClick={this.redirectToCheckoutPage} className="text-gray custom-button-link" > {MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['plus_membership_cancel_link_category_D']}</span></p>
                                            </div>
                                        : "" : null}
                                     </div>
                                     <div className="row no-gutters sonstiges">
                                     <div className="col-12 mt-3">
                                             <p className="text-dark mb-0">{MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['You_mochcount_yours']} <span title={MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['delete_account_link']} className="text-gray custom-button-link" name={apiToken} onClick={this.state.change_text == 0 ? this.UserCanNotDelteAccount : this.handleDeleteAccount}> {MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['delete_account_link']}</span></p>
                                         </div>
                                     </div>
                                    <div className="text-center anderungen-btn">
                                        <button type="submit" className="btn btn-clr text-white text-uppercase font-18" title={MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['save_changes_button']}> {MYPROFILE_SETTINGS_CONSTANT[LANGUAGE_CODE]['save_changes_button']}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(null, mapDispatchToProps)(Settings);
