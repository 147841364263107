import React from "react";
import { MYPROFILE_PROFILETAB_CONSTANT } from "../../constants/FrontEndConstant";
import { LANGUAGE_CODE } from '../../constants/DotEnv';
import MainProfile from "./MainProfile";
import Polls from "./Polls";
import Settings from "./Settings";
import Statistic from "./Statistic";
class ProfileTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            myprofileactivetab : null
        };
    }
    componentDidMount(){
        let myprofileactivetab = localStorage.getItem('myprofileactivetab')
        if(myprofileactivetab){
            this.setState({myprofileactivetab : parseInt(myprofileactivetab)})
        }else{
            this.setState({myprofileactivetab : 1})
        }
    }
    changeActiveTab = (activeTab, id) => {
        let myprofileactivetab = localStorage.getItem('myprofileactivetab')
        if (myprofileactivetab) {
            myprofileactivetab = parseInt(myprofileactivetab);
        }
        else {
            myprofileactivetab = 1;
        }
        let data = document.getElementsByClassName("complete");
        for (var i = 0; i < data.length; i++) {
            if (data[i]) {
                data[i].classList.remove("complete")
            }
        }
        if (document.getElementsByName(myprofileactivetab)) {
            if (document.getElementsByName(myprofileactivetab)[0]) {
                if (document.getElementsByName(myprofileactivetab)[0].classList) {
                    document.getElementsByName(myprofileactivetab)[0].classList.remove("active-tab")
                    document.getElementsByName(myprofileactivetab)[0].classList.add("complete")
                }
            }
        }
        if (id) {
            if (document.getElementById(id)) {
                document.getElementById(id).classList.add("active-tab")
                document.getElementById(id).classList.remove("complete")
            }
        }
        localStorage.setItem('myprofileactivetab', activeTab);
        this.setState({myprofileactivetab: activeTab})
    }
    render() {
        let userdata = JSON.parse(localStorage.getItem('userdata'))
        let myprofileactivetab = localStorage.getItem('myprofileactivetab');
        if (myprofileactivetab) {
            myprofileactivetab = parseInt(myprofileactivetab);
        }
        let classname;
        let user_account_id;
        if(this.props.user_account_id){
            user_account_id = this.props.user_account_id
        }else{
            user_account_id = userdata.account_type_category_id
        }
        if(user_account_id !== 4){
            classname = "nav-item text-center col-12 col-lg-3 px-0"
        }else{
            classname = "nav-item text-center col-12 col-lg-4 px-0"
        }
        return (
            <>
            <ul className="nav nav-tabs custom-background-tabs flex-column flex-md-row" id="myTab" role="tablist">
                <li className={classname}>
                    <a name={1} className={(myprofileactivetab) ? ((myprofileactivetab === 1) ? "nav-link active font-weight-bold active-tab" : "nav-link font-weight-bold") : "nav-link active active-tab font-weight-bold"} title={MYPROFILE_PROFILETAB_CONSTANT[LANGUAGE_CODE]['my_profile']} id="profil-tab" data-toggle="tab" href="#profil" role="tab" aria-controls="profil" aria-selected={(myprofileactivetab === 1) ? "true" : "false"} onClick={() => this.changeActiveTab(1, "profil-tab")} >{MYPROFILE_PROFILETAB_CONSTANT[LANGUAGE_CODE]['my_profile']}</a>
                </li>
                <li className={classname}>
                    <a name={2} className={(myprofileactivetab === 2) ? "nav-link active font-weight-bold active-tab" : "nav-link font-weight-bold"} title={MYPROFILE_PROFILETAB_CONSTANT[LANGUAGE_CODE]['my_polls']} id="umfragen-tab" data-toggle="tab" href="#umfragen" role="tab" aria-controls="umfragen" aria-selected={(myprofileactivetab === 2) ? "true" : "false"} onClick={() => this.changeActiveTab(2, "umfragen-tab")}>{MYPROFILE_PROFILETAB_CONSTANT[LANGUAGE_CODE]['my_polls']}</a>
                </li>
                {
                   user_account_id !== 4 ?
                <li className={classname}>
                    <a name={3} className={(myprofileactivetab === 3) ? "nav-link active font-weight-bold active-tab" : "nav-link font-weight-bold"} title={MYPROFILE_PROFILETAB_CONSTANT[LANGUAGE_CODE]['statistic']} id="statistiken-tab" data-toggle="tab" href="#statistiken" role="tab" aria-controls="statistiken" aria-selected={(myprofileactivetab === 3) ? "true" : "false"} onClick={() => this.changeActiveTab(3, "statistiken-tab")}>{MYPROFILE_PROFILETAB_CONSTANT[LANGUAGE_CODE]['statistic']}</a>
                </li> : null
                }
                <li className={classname}>
                    <a name={4} className={(myprofileactivetab === 4) ? "nav-link active font-weight-bold active-tab" : "nav-link font-weight-bold"} title={MYPROFILE_PROFILETAB_CONSTANT[LANGUAGE_CODE]['settings']} id="einstellungen-tab" data-toggle="tab" href="#einstellungen" role="tab" aria-controls="einstellungen" aria-selected={(myprofileactivetab === 4) ? "true" : "false"} onClick={() => this.changeActiveTab(4, "einstellungen-tab")}>{MYPROFILE_PROFILETAB_CONSTANT[LANGUAGE_CODE]['settings']}</a>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <MainProfile data={this.props.data} myprofileactivetab={this.state.myprofileactivetab}/>
                <Polls useraccountid={user_account_id} data={this.props.data} myprofileactivetab={this.state.myprofileactivetab}/>
                <Settings data={this.props.data} myprofileactivetab={this.state.myprofileactivetab} getUserAccountID={this.props.getUserAccountID}/>
                <Statistic data={this.props.data} myprofileactivetab={this.state.myprofileactivetab}/>
            </div></>
        );
    }
}
export default ProfileTab;