import React from 'react';
import SurveyContentPage from './SurveyContentPage';
import SurveySingleSelectionQuestion from './SurveySingleSelectionQuestion';
import SurveyMultipleQuestion from './SurveyMultipleSelectionQuestion';
import SurveyRatingQuestion from './SurveyRatingQuestion';
import SurveyRatingWithOneElementQuestion from './SurveyRatingWithOneElementQuestion';
import SurveyRatingWithMoreElements from './SurveyRatingWithMoreElements';
import FreeTextTypeQuestion from './FreeTextTypeQuestion';
class SurveyDemographicAttribute extends React.Component {
    componentDidMount() {
        window.addEventListener('popstate', window.preventBackNav);
    }
    componentWillUnmount() {
        // you need to unbind the same listener that was binded.
        window.removeEventListener('popstate', window.preventBackNav, false);

    }
    section = (questiontype, data) => {
        var questioncomponent = questiontype;
        var questiondata = data
        switch (questioncomponent) {
            case 1:
                return < FreeTextTypeQuestion questiondata={questiondata} onChange={this.props.onChange} counter={this.props.counter} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} lastIndex={this.props.lastIndex} continue={this.props.continue} finish={this.props.finish} backButtonClick={this.props.backButtonClick} demographyQuestionDisplay={this.props.demographyQuestionDisplay} surveydetail={this.props.surveydetail}/>
            case 2:
                return < SurveyContentPage questiondata={questiondata} onChange={this.props.onChange} backButtonClick={this.props.backButtonClick} />
            case 3:
                return < SurveySingleSelectionQuestion questiondata={questiondata} onChange={this.props.onChange} counter={this.props.counter} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} dummyStateChange={this.props.dummyStateChange} />
            case 4:
                return < SurveyMultipleQuestion questiondata={questiondata} onChange={this.props.onChange} counter={this.props.counter} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} is_Free_text_checked={this.props.is_free_text_checked} backButtonClick={this.props.backButtonClick} />
            case 5:
                return < SurveyRatingQuestion questiondata={questiondata} onChange={this.props.onChange} onSelect={this.props.onSelect} onClick={this.props.onClick} counter={this.props.counter} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} onSchoolGrade={this.props.onSchoolGrade} backButtonClick={this.props.backButtonClick} />
            case 6:
                return < SurveyRatingWithOneElementQuestion questiondata={questiondata} onChange={this.props.onChange} onButtonRating={this.props.onButtonRating} onSchoolGrade={this.props.onSchoolGrade} onLoad={this.props.onLoad} counter={this.props.counter} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} />
            case 7:
                return < SurveyRatingWithMoreElements questiondata={questiondata} onChange={this.props.onChange} onSchoolGrade={this.props.onSchoolGrade} onLoad={this.props.onLoad} onButtonRating={this.props.onButtonRating} counter={this.props.counter} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} />
            default:
        }
    }
    render() {
        let data = this.props.questiondata;
        let questiontype = '';
        if (data) {
            questiontype = data.question_type_id;
        }
        const element = this.section(questiontype, data)
        return (this.props.loading === "true" || this.props.loading === true
                ? null
                : <div className="survey-form col-11 col-sm-10 col-md-7 col-lg-5 container">{element}</div>
        )
    }
}
export default SurveyDemographicAttribute;