import React from 'react';
import axios from "axios";
import Autocomplete from 'react-autocomplete';
import { MYPROFILE_ERROR_MESSAGE, FINAL_YEAR_DROPDOWN, MYPROFILE_MAINPROFILE_CONSTANT, BUBBLE_LOADING, REGISTRATION_CONSTANT, } from "../../constants/FrontEndConstant";
import { LANGUAGE_CODE, MESSAGE_TIME, API_URL } from '../../constants/DotEnv';
import MaskedInput from 'react-text-mask'
class MainProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            id: '',
            first_name: '',
            last_name: '',
            email: '',
            birthday: '',
            gender: '',
            userimage: '',
            education: '',
            nationality: '',
            marital_status: null,
            employment: null,
            income: null,
            university: '',
            postalcode_id: null,
            targeteducation_id: "",
            final_year: "",
            major: '',
            message: '',
            status: '',
            created: '',
            modified: '',
            regions: '',
            newsletter: '',
            errorCount: 0,
            responseMessage: '',
            loading: 'false',
            dropDownData: '',
            a: [],
            postalcodedata: [],
            postalloading: false,
            postalcode: null,
            ui_id: "",
            universityMatchFound: false,
            postalCodeMatchFound: false,
            current_school: '',
            organization: '',
            house_number: '',
            telephone: null,
            street: '',
            position_in_the_organization: '',
            subject_area: '',
            usertype: null,
            accountType: []
        }
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.getUserdata();
        this.demographicErrorCount();
        this.getAccountTypes();
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (document.querySelector('.navbar-toggler')) {
            let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
            if (count === -1) {
                document.querySelector('.navbar-collapse').classList.remove('show');
                document.querySelector('header').classList.remove("header_collapse");
                document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
            }
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let autosuggestionData = document.getElementsByClassName("form-autocomplete-suggestion-field");
        if (autosuggestionData.length) {
            for (let i = 0; i < autosuggestionData.length; i++) {
                if (document.getElementsByClassName("form-autocomplete-suggestion-field")[i].childNodes[1]) {
                    document.getElementsByClassName("form-autocomplete-suggestion-field")[i].childNodes[1].remove();
                }
            }
        }
    }
    getAccountTypes = async () => {
        await axios.get(API_URL + "/common/getAccountTypes/" + LANGUAGE_CODE)
       .then((response) => {
        let account_type =  response.data.success.AccountTypes
           this.setState({accountType: account_type})
           if( !localStorage.getItem("accountType")){
                localStorage.setItem("accountType", JSON.stringify(account_type))
           }
       })
       .catch((error) => {
           // console.log("error", error.response)
       })
       let userdata =  JSON.parse(localStorage.getItem('userdata'))
       if(userdata.account_type){
        this.setState({usertype:userdata.account_type})
       }
   }
    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value
        this.setState({ [name]: value });
        var index = this.state.a.indexOf(name);
        var indexInvalid = this.state.a.indexOf("invalid");
        if (index > -1) {
            this.state.a.splice(index, 1);
        }
        if (indexInvalid > -1) {
            this.state.a.splice(indexInvalid, 1);
        }
        event.preventDefault();
    }
    getUserdata = async () => {
        this.setState({ loading: 'true' })
        if (this.props.data.length !== 0) {
            let dropDownData = '';
            let FinalDropdownList = JSON.parse(localStorage.getItem("dropdownList"));
            if (FinalDropdownList === null) {
                let storeitem = JSON.parse(localStorage.getItem('userdata'));
                let apitoken = '';
                let userId = '';
                if (storeitem !== null) {
                    apitoken = storeitem.token;
                    userId = storeitem.id;
                }
                var headers = {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + apitoken
                }
                let self = this;
                await axios.get(API_URL + "/common/getAll/" + LANGUAGE_CODE + '?uId=' + userId, { headers: headers })
                    .then((response) => {
                        dropDownData = response.data['success'];
                        localStorage.setItem("dropdownList", JSON.stringify(dropDownData));
                        setTimeout(() => {
                            self.myprofileDropDown(JSON.stringify(dropDownData))
                        }, 2000);
                        self.setState({ dropDownData })
                    })
                    .catch((error) => {
                        // console.log("error", error.response)
                    })
            }
            else {
                let data = localStorage.getItem("dropdownList");
                this.myprofileDropDown(data);
            }
        }
        return 0;
    }
    myprofileDropDown = (data) => {
        this.setState({ loading: 'false' });
        let userData = this.props.data;
        // eslint-disable-next-line
        Object.keys(userData).map((key, index) => {
            const keyNew = key;
            const value = userData[key];
            if (value !== null) {
                if (keyNew === 'university') {
                    let universityName = JSON.parse(data).university;
                    let userDataUniversity = "";
                    for (let i = 0; i < universityName.length; i++) {
                        if (universityName[i].id === value) {
                            userDataUniversity = universityName[i].university_name;
                        }
                    }
                    this.setState({ [keyNew]: userDataUniversity, universityMatchFound: true })
                } else if (keyNew === 'postalcode_id') {
                    let userDataLocation = "";
                    userDataLocation = userData.postalcode.toString();
                    this.setState({ [keyNew]: userDataLocation, postalCodeMatchFound: true })
                } else if (keyNew === 'postalcode') {
                    let userPostalCode = '';
                    userPostalCode = userData.postalcode_id.toString();
                    this.setState({ [keyNew]: userPostalCode, postalCodeMatchFound: true })
                }
                else {
                    this.setState({ [keyNew]: value })
                }
            }
        });
    }
    demographicErrorCount = () => {
        var demographicerrorCount = 0;
        var formfield = JSON.parse(localStorage.getItem('userdata'));
        for (var key in formfield) {
            if (formfield.hasOwnProperty(key)) {
                if(key === 'gender' || key === 'nationality' || key === 'birthday' || key === 'postalcode_id' || key === 'marital_status' || key === 'employment' || key === 'income' || key === 'education'){
                    if (!formfield[key] || formfield[key] === 'nationality' || formfield[key] === '' || formfield[key] === null) {
                        demographicerrorCount = demographicerrorCount + 1;
                    }
                }
            }
        }
        this.setState({ demographiccount: demographicerrorCount });
    }
    handleSubmit = (event) => {
        let userdata = JSON.parse(localStorage.getItem('userdata'))
        let accountType = userdata.account_type_category_id
        var formfield = {
            id: JSON.parse(localStorage.getItem('userdata')).id,
            email: JSON.parse(localStorage.getItem('userdata')).email,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            birthday: this.state.birthday,
            major: this.state.major,
            gender: this.state.gender,
            nationality: this.state.nationality,
            postalcode_id: this.state.postalcode_id,
            marital_status: this.state.marital_status,
            university: this.state.university,
            education: this.state.education,
            employment: this.state.employment,
            income: this.state.income,
            targeteducation_id: this.state.targeteducation_id,
            final_year: this.state.final_year,
            current_school: this.state.current_school,
            organization: this.state.organization,
            house_number: this.state.house_number,
            telephone: this.state.telephone,
            street: this.state.street,
            position_in_the_organization: this.state.position_in_the_organization,
            subject_area: this.state.subject_area,
        }
        let mySurveyList = '';
        if (localStorage.getItem('mySurveyList')) {
            mySurveyList = JSON.parse(localStorage.getItem("mySurveyList"));
        }
        var a = [];
        var Count = 0;
        var errorCount = '';
        var demographicerrorCount = 0;
        var checkDateFormate = "";
        for (var key in formfield) {
            if (formfield.hasOwnProperty(key)) {
                var key1 = key;
                const value1 = formfield[key1];
                if (key1 === 'first_name' || key1 === 'last_name') {
                    if (value1 === '' || value1 === null || value1 === undefined) {
                        a.push(key1);
                        errorCount = Count + 1;
                        Count = errorCount;
                    }
                }
                if(accountType === 4){
                    if (key === 'organization' ) {
                        if (value1 === '' || value1 === null || value1 === undefined) {
                            a.push(key1);
                            errorCount = Count + 1;
                            Count = errorCount;
                        }
                    }
                }
                if(accountType === 3){
                    if (key === 'current_school' ) {
                        if (value1 === '' || value1 === null || value1 === undefined) {
                            a.push(key1);
                            errorCount = Count + 1;
                            Count = errorCount;
                        }
                    }
                }
                if (key === 'postalcode_id') {
                    if (formfield[key]) {
                        if (!this.state.postalCodeMatchFound) {
                            a.push(key1)
                            errorCount = Count + 1;
                            Count = errorCount;
                        }
                    }
                }
                if(accountType <= 2){
                    if (key === 'university') {
                        if (formfield[key]) {
                            if (!this.state.universityMatchFound) {
                                a.push(key1)
                                errorCount = Count + 1;
                                Count = errorCount;
                            }
                        }
                        else {
                            if (!formfield[key] && mySurveyList !== '' && Object.keys(mySurveyList).length > 0) {
                                a.push("university_empty");
                                errorCount = Count + 1;
                                Count = errorCount;
                            }
                        }
                    }
                }
                if (key === 'birthday') {
                    if (formfield[key]) {
                        var currentdate = new Date();
                        var selectedbirthDate = formfield[key].split('.');
                        selectedbirthDate = new Date(+selectedbirthDate[2], selectedbirthDate[1] - 1, +selectedbirthDate[0]);
                        this.checkDate = (field) => {
                            let regs;
                            var errorMsg = "";
                            // regular expression to match required date format
                            let re = /^(\d{1,2})\.(\d{1,2})\.(\d{4})$/;
                            if (field !== '') {
                                // eslint-disable-next-line
                                if (regs = field.match(re)) {
                                    if (regs[1] < 1 || regs[1] > 31) {
                                        errorMsg = "Invalid date";
                                    } else if (regs[2] < 1 || regs[2] > 12) {
                                        errorMsg = "Invalid date";
                                    }
                                } else {
                                    errorMsg = "Invalid date";
                                }
                            }
                            return errorMsg;
                        }
                        checkDateFormate = this.checkDate(formfield[key]);
                        if (checkDateFormate) {
                            a.push("invalid");
                            errorCount = Count + 1;
                            Count = errorCount;
                        }
                        else if (selectedbirthDate > currentdate) {
                            a.push(key1);
                            errorCount = Count + 1;
                            Count = errorCount;
                        }
                    }
                }
                if (key === 'gender' || key === 'birthday' || key === 'nationality' || key === 'postalcode_id' || key === 'marital_status' || key === 'employment' || key === 'income' || key === 'education') {
                    if (!formfield[key] || formfield[key] === 'nationality' || formfield[key] === '' || formfield[key] === null) {
                        demographicerrorCount = demographicerrorCount + 1;
                    }
                }
            }
        }
        this.scrollUpFunction();
        if (Count === undefined || Count === 0) {
            this.changeUserData(formfield);
            this.setState({ errorCount: Count, demographiccount: demographicerrorCount, a });
        }
        else {
            this.setState({ errorCount: Count, demographiccount: demographicerrorCount, a, responseMessage: MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]["all_field_required"], message: "error" });
            let self = this;
            window.setTimeout(() => {
                self.setState({ message: '', errorCount: 0 })
            }, MESSAGE_TIME);
        }
        event.preventDefault();
    }
    changeUserData = async (formfield) => {
        this.setState({ loading: 'true' })
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let userData = this.props.data;
        let apitoken = '';
        let userId = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
            userId = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        let userUniversityId = '';
        let userLocationId = '';
        let userpostalcode = '';
        if (JSON.parse(localStorage.getItem("dropdownList")) && formfield.university) {
            let universityDropdown = JSON.parse(localStorage.getItem("dropdownList")).university;
            for (let i = 0; i < universityDropdown.length; i++) {
                if (universityDropdown[i].university_name === formfield.university) {
                    userUniversityId = universityDropdown[i].id;
                }
            }
            if (userUniversityId === '') {
                this.setState({ universityMatchFound: false }, () => {
                    userUniversityId = '';
                })
            }
        }
        if (formfield.postalcode_id) {
            if (this.state.postalcodedata.length > 0) {
                if (this.state.ui_id) {
                    for (let i = 0; i < this.state.postalcodedata.length; i++) {
                        if (this.state.postalcodedata[i].id === this.state.ui_id) {
                            userLocationId = this.state.postalcodedata[i].id;
                            userpostalcode = this.state.postalcodedata[i].postalcode_city;
                        }
                    }
                }
                else {
                    for (let i = 0; i < this.state.postalcodedata.length; i++) {
                        if (this.state.postalcodedata[i].postalcode_city === formfield.postalcode_id) {
                            userLocationId = this.state.postalcodedata[i].id;
                            userpostalcode = this.state.postalcodedata[i].postalcode_city;
                        }
                    }
                }
                this.setState({ postalcode: userpostalcode })
            }
            else {
                if (formfield.postalcode_id.length > 3) {
                    if (parseInt(formfield.postalcode_id) === parseInt(userData.postalcode)) {
                        userLocationId = this.state.postalcode
                        this.setState({ postalcode: this.state.postalcode_id })
                    }
                }
            }
            if (userLocationId === '' || userLocationId === null || userLocationId === undefined) {
                this.setState({ postalcode_id: '', postalcode: '' });
            }
        }
        var data = {
            id: parseInt(formfield.id),
            email: formfield.email,
            first_name: formfield.first_name,
            last_name: formfield.last_name,
            birthday: formfield.birthday,
            gender: parseInt(formfield.gender),
            nationality: parseInt(formfield.nationality),
            marital_status: parseInt(formfield.marital_status),
            major: formfield.major,
            targeteducation_id: parseInt(formfield.targeteducation_id),
            final_year: formfield.final_year,
            university: parseInt(userUniversityId),
            postalcode_id: parseInt(userLocationId),
            education: parseInt(formfield.education),
            employment: parseInt(formfield.employment),
            income: parseInt(formfield.income),
            languagecode: LANGUAGE_CODE,
            current_school: formfield.current_school,
            organization:  formfield.organization,
            house_number:  formfield.house_number,
            telephone: formfield.telephone,
            street: formfield.street,
            position_in_the_organization: formfield.position_in_the_organization,
            subject_area:  formfield.subject_area,
            account_type: formfield.account_type,
        }
        let self = this;
        // eslint-disable-next-line
        await axios.post(API_URL + "/userprofile/updateprofile" + '?uId=' + userId, data, { headers: headers })
            .then((response) => {
                self.setState({ loading: 'false', message: 'successfull', responseMessage: response.data['success'] });
                if (response.data['success']) {
                    // Get the existing data
                    var existing = localStorage.getItem('userdata');
                    // If no existing data, create an array
                    // Otherwise, convert the localStorage string to an array
                    existing = existing ? JSON.parse(existing) : {};
                    // Add new data to localStorage Array
                    for (var key in data) {
                        if (formfield.hasOwnProperty(key)) {
                            const value = data[key];
                            existing[key] = value;
                        } else {
                            const value = (this.state.postalcode)
                            existing['postalcode'] = value;
                        }
                    }
                    // Save back to localStorage
                    localStorage.setItem('userdata', JSON.stringify(existing));
                }
                window.setTimeout(() => {
                    self.setState({ message: '', errorCount: 0 })
                }, MESSAGE_TIME);
            })
            .catch((error) => {
                self.setState({ loading: 'false', message: 'error', responseMessage: error.response.data['error'] });
                window.setTimeout(() => {
                    self.setState({ message: '', errorCount: 0 })
                }, MESSAGE_TIME);
            })
        /**for scrolling page up to show message */
        //document.body.scrollTop = 0;
        this.scrollUpFunction();
    }
    /**this function is for only allow digits to enter otherwise it restrict to type a user */
    OnlyDigitsAllow = (event) => {
        var keyCode = event.which || event.keycode;
        // eslint-disable-next-line               
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || keyCode == 32 || (keyCode >= 97 && keyCode <= 122)) {

        } else {
            event.preventDefault();
        }
    }
    /**this function is for api call for postalcode data after 3 digit type */
    getPostalCode = async (event, ui) => {
        if (this.postalDataRequest) {
            clearTimeout(this.postalDataRequest)
        }
        let i = this.state.a.indexOf('postalcode_id');
        if (i > -1) {
            this.state.a.splice(i, 1)
        }
        this.postalcodedata = '';
        this.ui_id = this.state.ui_id;
        var value = (event.target !== undefined) ? event.target.value : event;
        const valLength = value.length;
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let userId = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
            userId = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        var self = this;
        if (valLength > 1 && event.target !== undefined) {
            this.setState({ postalloading: true })
            this.postalDataRequest = setTimeout(async () => {
                // eslint-disable-next-line
                await axios.get(API_URL + "/userprofile/getPostalcodedata/" + value + "/" + LANGUAGE_CODE + '?uId=' + userId, { headers: headers })
                    .then((response) => {
                        this.postalcodedata = response.data.success.postalcodedata;
                        this.postalcodedata = JSON.parse(JSON.stringify(this.postalcodedata))
                        // eslint-disable-next-line
                        self.setState({ postalcodedata: this.postalcodedata, postalloading: false })
                    })
                    .catch((error) => {
                        if (axios.isCancel(error)) {
                        }
                        self.setState({ postalloading: false })
                    })
            }, 400);
        }
        if (self.state.postalcodedata.length) {
            let postalCodeFound = false;
            // eslint-disable-next-line
            self.state.postalcodedata.map((postalData) => {
                if (postalData.postalcode_city === value.toString()) {
                    postalCodeFound = true
                }
            })
            self.setState({
                postalCodeMatchFound: postalCodeFound
            })
        } else if (valLength) {
            self.setState({
                postalCodeMatchFound: false
            })
        }
        this.ui_id = (ui.id) ? ui.id : ""
        this.setState({ postalcode_id: value, ui_id: this.ui_id });
    }
    universityChanged = (event, ui) => {
        let name = (event.target !== undefined) ? event.target.name : "university";
        let value = (event.target !== undefined) ? event.target.value : event;
        let i = this.state.a.indexOf('university');
        let j = this.state.a.indexOf('university_empty');
        if (i > -1) {
            this.state.a.splice(i, 1)
        }
        if (j > -1) {
            this.state.a.splice(j, 1);
        }
        this.setState({ [name]: value }, () => {
            let FinalDropdownList = JSON.parse(localStorage.getItem("dropdownList"));
            // eslint-disable-next-line
            let universityFound = false;
            // eslint-disable-next-line
            FinalDropdownList.university.map((dropdownvalue) => {
                if (value === dropdownvalue.university_name) {
                    universityFound = true
                }
            });
            this.setState({
                universityMatchFound: universityFound
            })
        })
    }
    render() {
        let userdata = JSON.parse(localStorage.getItem('userdata'))
        let accountType;
        if(this.props.user_account_id){
            accountType = this.props.user_account_id
        }else{
            accountType = userdata.account_type_category_id
        }
        let genderData = '';
        let educationData = '';
        let nationalityData = '';
        let maritalstatusData = '';
        let employmentData = '';
        let incomeData = '';
        let universityData = '';
        let locationData = '';
        // eslint-disable-next-line
        let i;
        // eslint-disable-next-line
        let j;
        let locationName = '';
        let universityName = '';
        let targeteducationdata = "";
        let finalyearData = "";
        let FinalDropdownList = JSON.parse(localStorage.getItem("dropdownList"));
        if (FinalDropdownList !== null) {
            const gender = FinalDropdownList.gender;
            const education = FinalDropdownList.education;
            const nationality = FinalDropdownList.nationality;
            const maritalstatus = FinalDropdownList.MaritalStatus;
            const employment = FinalDropdownList.employment;
            const income = FinalDropdownList.income;
            const targeteducation = FinalDropdownList.targeteducationdata;
            const finalyear = FINAL_YEAR_DROPDOWN[LANGUAGE_CODE];
            locationData = this.state.postalcodedata
            universityData = FinalDropdownList.university;
            i = this.state.a.indexOf('university');
            j = this.state.a.indexOf('postalcode_id');
            genderData = gender.map((gender, index) => {
                return <option value={gender.id} key={index}>{gender.title}</option>
            })
            educationData = education.map((education, index) => {
                return <option value={education.id} key={index}>{education.education_name}</option>
            })
            nationalityData = nationality.map((nationality, index) => {
                return <option value={nationality.id} key={index}>{nationality.nationality}</option>
            })
            maritalstatusData = maritalstatus.map((maritalstatus, index) => {
                return <option value={maritalstatus.id} key={index}>{maritalstatus.title}</option>
            })
            locationData = locationData.map((locationDataNew, index) => {
                locationData[index].postalcode_city = locationDataNew.postalcode_city.toString();
                return locationData[index];
            })
            targeteducationdata = targeteducation.map((targeteducation, index) => {
                return <option value={targeteducation.id} key={index}>{targeteducation.targeteducation_name}</option>
            })
            finalyearData = finalyear.map((finalyear, index) => {
                return <option value={finalyear.id} key={index}>{finalyear.final_year}</option>
            })
            employmentData = employment.map((employment, index) => {
                return <option value={employment.id} key={index}>{employment.employment_name}</option>
            })
            incomeData = income.map((income, index) => {
                return <option value={income.id} key={index}>{income.income_range}</option>
            })
            if (this.state.postalcode_id !== undefined && this.state.postalcode_id !== null && this.state.postalcode_id !== '') {
                locationName = this.state.postalcode_id;
            }
            if (this.state.university !== undefined && this.state.university !== null && this.state.university !== '') {
                universityName = this.state.university;
            }
        }
        var span = {
            color: 'red'
        }
        let myprofileactivetab = localStorage.getItem('myprofileactivetab');
        if (myprofileactivetab) {
            myprofileactivetab = parseInt(myprofileactivetab);
        }
        return (
            <div className={(this.props.myprofileactivetab === 1) ? "tab-pane fade show active" : "tab-pane fade"} id="profil" role="tabpanel" aria-labelledby="profil-tab">
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="contactform md-m-30px-b">
                            {(this.state.loading === 'true') ?
                                BUBBLE_LOADING
                                : null
                            }
                            {(this.state.message === 'successfull') ?
                                (this.state.responseMessage) ? <div className='alert alert-success'>
                                    {this.state.responseMessage}
                                </div>
                                    : null
                                : null
                            }
                            {(this.state.message === 'error') ?
                                (this.state.responseMessage) ? <div className='alert alert-danger'>
                                    {this.state.responseMessage}
                                </div>
                                    : null
                                : null
                            }
                            <form className="contactform" name='myprofile'>
                                {(this.state.demographiccount >4) && accountType !== 4 ?
                                    <div className="row mx-0 box-shadow survey-title-box">
                                        <div className="col-12 col-lg-1 text-center add-answer py-lg-5 py-1 px-0 pl-3 pl-lg-0 mt-2">
                                            <div className="round-information">
                                                <i className="fas fa-info icon-m border-radius"></i>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-11 pt-2 px-lg-0 px-3 py-lg-4 pb-3">
                                            <p className="font-20 text-dark font-weight-bold mb-1 text-left">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['quick_click_heading']}</p>
                                            <p className="mr-lg-4 mr-0 font-16 text-dark mb-1 text-left">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['quick_click_text']}</p>
                                        </div>
                                    </div>
                                    : null
                                }
                                 <div className={(this.state.demographiccount >4) ? "row mt-3 mt-md-4 chart-main-block-freetext mb-4 pb-2" : "row chart-main-block-freetext mb-4 pb-2" }>
                                    <div className="col-12">
                                        <h3 className="mb-0 text-dark font-weight-bold text-break">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['general_information']}</h3>
                                    </div>
                                    <div className="col-12 bottom-border-blue px-3" name="hidden-tag-chart">
                                    </div>
                                </div>
                                <div className="row mt-3 mt-md-4">
                                    <div className="col-md-6">
                                        <div className="form-group hs-form-field focus">
                                            <input type="text" name="first_name" className={(this.state.a.filter(a => a === 'first_name').length > 0) ? "form-control hs-input field-highlight" : this.state.first_name === '' ? "form-control hs-input yellow-border" : "form-control hs-input "} onChange={this.handleChange} value={this.state.first_name}  maxLength={20}/>
                                            <label htmlFor="first_name">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['first_name']}</label>   
                                            <div>
                                                {(this.state.a.filter(a => a === 'first_name').length > 0) ?
                                                    <span style={span}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]['first_name']}</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group hs-form-field focus">
                                            <input type="text" name="last_name" className={(this.state.a.filter(a => a === 'last_name').length > 0) ? " form-control hs-input field-highlight" : this.state.last_name === '' ? "form-control hs-input yellow-border" : "form-control hs-input"} onChange={this.handleChange} value={this.state.last_name}  maxLength={20}/>
                                            <label htmlFor="last_name">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['surname']}</label>
                                            <div>
                                                {(this.state.a.filter(a => a === 'last_name').length > 0) ?
                                                    <span style={span}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]['last_name']}</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group hs-form-field focus focus-sustain" >
                                            <MaskedInput
                                                mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                className={((this.state.a.filter(a => a === 'birthday').length > 0) || (this.state.a.filter(a => a === 'invalid').length > 0)) ? 'form-control hs-input field-highlight' : this.state.birthday === '' ? "form-control hs-input yellow-border" : 'form-control hs-input'}
                                                id='1'
                                                type='text'
                                                placeholder="tt.mm.jjjj"
                                                name="birthday"
                                                value={this.state.birthday.split(' ')[0]}
                                                onChange={this.handleChange}
                                            />
                                            <label htmlFor="birthday">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['date_of_birth']}</label>
                                            <div>
                                                {(this.state.a.filter(a => a === 'birthday').length > 0) ?
                                                    <span style={span}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]['birthday']}</span>
                                                    : (this.state.a.filter(a => a === 'invalid').length > 0) ? <span style={span}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]['invalid']}
                                                    </span> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group focus">
                                            <select className={(this.state.gender === "") ? "custom-select yellow-border" : "custom-select"} id="wahle dein" name="gender" onChange={this.handleChange} value={this.state.gender} >
                                                <option value=''>{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['dropdown_default_value']}</option>
                                                {genderData}
                                            </select>
                                            <label htmlFor="gender">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['gender']}</label>
                                            <div>
                                                {(this.state.a.filter(a => a === 'gender').length > 0) ?
                                                    <span style={span}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]['gender']}</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                            {accountType === 4 ? <></>
                                            :
                                            <>
                                            <div className="col-md-6">
                                                <div className="form-group focus">
                                                <select className={(this.state.nationality === 'nationality' || this.state.nationality === '' || this.state.nationality === null) ? "custom-select yellow-border" : "custom-select"} name="nationality" onChange={this.handleChange} value={this.state.nationality} >
                                                    <option value='nationality'>{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['dropdown_default_value']}</option>
                                                    {nationalityData}
                                                </select>
                                                <label htmlFor="nationality">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['nationality']}</label>
                                                <div>
                                                    {(this.state.a.filter(a => a === 'nationality').length > 0) ?
                                                        <span style={span}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]['nationality']}</span>
                                                        : null
                                                    }
                                                </div>
                                                </div>
                                            </div></>
                                            }
                                            {accountType !== 4 ?
                                                <div className="col-md-6">
                                                <div className="form-group hs-form-field autoComplete-custom focus">
                                                    <label htmlFor="postalcode_id">{accountType === 4 ? MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['postal_code'] : MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['residence']}</label>
                                                    <Autocomplete
                                                        value={locationName}
                                                        inputProps={{ id: 'states-autocomplete1', className: (this.state.a.filter(a => a === 'postalcode_id').length > 0) ? " form-control hs-input field-highlight" : locationName === '' ? "form-control hs-input yellow-border" : "form-control hs-input", name: "postalcode" }}//removed to allow german charactors onKeyPress: this.OnlyDigitsAllow,
                                                        items={locationData}
                                                        getItemValue={item => item.postalcode_city}
                                                        shouldItemRender={(locationName) ? (item) => item.postalcode_city : null}
                                                        // eslint-disable-next-line
                                                        onChange={this.getPostalCode}
                                                        // eslint-disable-next-line
                                                        onSelect={this.getPostalCode}
                                                        renderMenu={children => (
                                                            (children.length) ?
                                                                <div className="form-autocomplete-suggestion">
                                                                    {children}
                                                                </div>
                                                            : <div />
                                                        )}
                                                        renderItem={(item, isHighlighted) => (
                                                            <div key={item.id} id={item.id} className={(isHighlighted || item.postalcode_city === locationName) ? "form-autocomplete-suggestion-field custom-autocomplete-background" : "form-autocomplete-suggestion-field"}>
                                                                {item.postalcode_city}
                                                </div>
                                                        )}
                                                    />
                                                    <div>
                                                        {(this.state.a.filter(a => a === 'postalcode_id').length > 0) ?
                                                            <span style={span}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]['location']}</span>
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                            </div> : null
                                            }
                                    <div className="col-md-6">
                                        <div className="form-group focus">
                                        {
                                        accountType === 4 ? <></>
                                        :
                                            <><select className= {(this.state.marital_status === '' || this.state.marital_status === null) ? "custom-select yellow-border" : "custom-select"} name="marital_status" onChange={this.handleChange} value={this.state.marital_status} >
                                                <option value=''>{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['dropdown_default_value']}</option>
                                                {maritalstatusData}
                                            </select>
                                            <label htmlFor="marital_status">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['marital_status']}</label>
                                            <div>
                                            </div> </>
                                        }
                                        </div>
                                    </div>
                                </div>
                                {accountType === 4 ? <></>
                                    :
                                    <><div className="row mt-3 mt-md-4 mt-lg-5 chart-main-block-freetext mb-4 pb-2">
                                        <div className="col-12">
                                            <h3 className="mb-0 text-dark font-weight-bold text-break">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['status_and_income']}</h3>
                                        </div>
                                        <div className="col-12 bottom-border-blue px-3" name="hidden-tag-chart">
                                        </div>
                                    </div>
                                    <div className="row mt-3 mt-md-4">
                                        <div className="col-md-6">
                                            <div className="form-group focus">
                                                <select className={(this.state.employment === '' || this.state.employment === null) ? "custom-select yellow-border" : "custom-select"} name="employment" onChange={this.handleChange} value={this.state.employment} >
                                                    <option value=''>{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['dropdown_default_value']}</option>
                                                    {employmentData}
                                                </select>
                                                <label htmlFor="arbeitsverhaltnis">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['employment']}</label>
                                                <div>
                                                    {(this.state.a.filter(a => a === 'employment').length > 0) ?
                                                        <span style={span}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]['employment']}</span>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group focus">
                                                <select className={(this.state.income === '' || this.state.income === null) ? "custom-select yellow-border" : "custom-select"} name="income" onChange={this.handleChange} value={this.state.income} >
                                                    <option value=''>{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['dropdown_default_value']}</option>
                                                    {incomeData}
                                                </select>
                                                <label htmlFor="income">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['income']}</label>
                                                <div>
                                                    {(this.state.a.filter(a => a === 'income').length > 0) ?
                                                        <span style={span}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]['income']}</span>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div></>
                                }
                                <div className="row mt-3 mt-md-4 mt-lg-5 chart-main-block-freetext mb-4 pb-2">
                                    <div className="col-12">
                                        <h3 className="mb-0 text-dark font-weight-bold text-break">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['current_studies']}</h3>
                                    </div>
                                    <div className="col-12 bottom-border-blue px-3" name="hidden-tag-chart">
                                    </div>
                                </div>
                                <div className="row mt-3 mt-md-4">
                                    <div className="col-md-6">
                                        <div className="form-group hs-form-field  autoComplete-custom focus">
                                            {
                                                accountType === 1 || accountType === 2 ?
                                               <> <label htmlFor="university">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['university']}</label>
                                                <Autocomplete
                                                    value={universityName}
                                                    inputProps={{ id: 'states-autocomplete2', className: (this.state.a.filter(a => a === 'university').length > 0 || this.state.a.filter(a => a === 'university_empty').length > 0) ? " form-control hs-input field-highlight" : universityName === '' ? "form-control hs-input yellow-border" : "form-control hs-input", name: "university" }}
                                                    items={universityData}
                                                    getItemValue={item => item.university_name}
                                                    shouldItemRender={(universityName) ? (item) => item.university_name.toLowerCase().indexOf(universityName.toLowerCase()) !== -1 : null}
                                                    // eslint-disable-next-line
                                                    onChange={this.universityChanged}
                                                    // eslint-disable-next-line
                                                    onSelect={this.universityChanged}
                                                    onBlur={this.handleError}
                                                    renderMenu={children => (
                                                        <div className={"form-autocomplete-suggestion"}>
                                                            {children}
                                                        </div>
                                                    )}
                                                    renderItem={(item, isHighlighted) => (
                                                        <div
                                                            key={item.id} id={item.id} className={(isHighlighted || item.university_name === universityName) ? "form-autocomplete-suggestion-field custom-autocomplete-background" : "form-autocomplete-suggestion-field"}>
                                                            {item.university_name}
                                                        </div>
                                                    )}
                                                />
                                                <div>
                                                    {(this.state.a.filter(a => a === 'university_empty').length > 0) ?
                                                        <span style={span}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]['university_empty']}</span>
                                                        : null
                                                    }
                                                </div> </>
                                                :  accountType === 3 ?
                                                <> <input type="text" name="current_school" className={(this.state.a.filter(a => a === 'current_school').length > 0) ? " form-control hs-input field-highlight" : this.state.current_school === '' ? "form-control hs-input yellow-border" : "form-control hs-input"} onChange={this.handleChange} value={this.state.current_school} maxLength={100} />
                                                <label htmlFor="current_school">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['current_school']}</label> 
                                                <div>
                                                    {(this.state.a.filter(a => a === 'current_school').length > 0) ?
                                                        <span style={span}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]['current_school']}</span>
                                                        : null
                                                    }
                                                </div></>
                                                :  accountType === 4 ?
                                                <> <input type="text" name="organization" className={(this.state.a.filter(a => a === 'organization').length > 0) ? " form-control hs-input field-highlight" : this.state.organization === '' ? "form-control hs-input yellow-border" : "form-control hs-input"} onChange={this.handleChange} value={this.state.organization}  maxLength={50} />
                                                <label htmlFor="major">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['name_of_the_organization']}</label> 
                                                <div>
                                                    {(this.state.a.filter(a => a === 'organization').length > 0) ?
                                                        <span style={span}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]['name_of_the_organization']}</span>
                                                        : null
                                                    }
                                                </div></> : null
                                            }
                                        </div>
                                    </div>
                                    {/**Change from dropdown to text field major textfield */}
                                    {accountType !== 3?
                                        <div className="col-md-6">
                                            <div className="form-group focus">
                                                {
                                                    accountType === 1? <>
                                                    <input type="text" name="major" className={(this.state.a.filter(a => a === 'major').length > 0) ? " form-control hs-input field-highlight" : this.state.major === '' ? "form-control hs-input yellow-border" : "form-control hs-input"} onChange={this.handleChange} value={this.state.major}  maxLength={100}/>
                                                    <label htmlFor="major">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['course_of_studies']}</label>
                                                    <div>
                                                        {(this.state.a.filter(a => a === 'major').length > 0) ?
                                                            <span style={span}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]['major']}</span>
                                                            : null
                                                        }
                                                    </div> </>
                                                    : accountType === 4 ? <>
                                                    <input type="text" name="position_in_the_organization" className={(this.state.a.filter(a => a === 'position_in_the_organization').length > 0) ? " form-control hs-input field-highlight" : this.state.position_in_the_organization === '' ? "form-control hs-input yellow-border" : "form-control hs-input"} onChange={this.handleChange} value={this.state.position_in_the_organization}  maxLength={50} />
                                                    <label htmlFor="position_in_the_organization">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['your_position']}</label>
                                                    </> : accountType === 2 ? <> <input type="text" name="subject_area" className={(this.state.a.filter(a => a === 'subject_area').length > 0) ? " form-control hs-input field-highlight" : this.state.subject_area === '' ? "form-control hs-input yellow-border" : "form-control hs-input"} onChange={this.handleChange} value={this.state.subject_area}  maxLength={100} />
                                                    <label htmlFor="subject_area">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['subject_area']}</label>
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div> : null
                                    }
                                    {accountType < 4 ||  accountType == 5?
                                        <div className="col-md-6">
                                        <div className="form-group focus">
                                            <select className={(this.state.education === '') ? "custom-select yellow-border" : "custom-select"} name="education" onChange={this.handleChange} value={this.state.education} >
                                                <option value=''>{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['dropdown_default_value']}</option>
                                                {educationData}
                                            </select>
                                            <label htmlFor="bildungsgrad">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['highest_level_of_education']}</label>
                                            <div>
                                                {(this.state.a.filter(a => a === 'education').length > 0) ?
                                                    <span style={span}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]['education']}</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div> :  null
                                    }
                                    {/** Added new dropdown field which is Angestrebter Bildungsabschluss */}
                                    { accountType == 1 ? <>
                                    <div className="col-md-6">
                                        <div className="form-group focus">
                                            <select className={(this.state.targeteducation_id === '') ? "custom-select yellow-border" : "custom-select"} name="targeteducation_id" onChange={this.handleChange} value={this.state.targeteducation_id} >
                                                <option value=''>{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['dropdown_default_value']}</option>
                                                {targeteducationdata}
                                            </select>
                                            <label htmlFor="bildungsgrad">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['targeted_education']}</label>
                                        </div>
                                    </div> </>
                                    : accountType === 4 ? <>
                                    <div className="col-md-6">
                                     <div className="form-group focus">
                                         <input type="text" name="street" className={(this.state.a.filter(a => a === 'street').length > 0) ? " form-control hs-input field-highlight" : this.state.street === '' ? "form-control hs-input yellow-border" : "form-control hs-input"} onChange={this.handleChange} value={this.state.street} maxLength={44}/>
                                         <label htmlFor="street">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['street']}</label>
                                     </div>
                                    </div> </>
                                    : null
                                    }
                                    {/**added dropdwon field which is Angestrebtes Abschlussjahr */}
                                    { accountType === 1 ?
                                        <div className="col-md-6">
                                        <div className="form-group focus">
                                        <select className={(this.state.final_year === '') ? "custom-select yellow-border" : "custom-select"} name="final_year" onChange={this.handleChange} value={this.state.final_year} >
                                                <option value=''>{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['dropdown_default_value']}</option>
                                                {finalyearData}
                                            </select>
                                            <label htmlFor="bildungsgrad">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['desired_final_year']}</label>
                                        </div>
                                    </div>
                                    : accountType === 4?
                                        <div className="col-md-6">
                                            <div className="form-group focus">
                                                <input type="text" name="house_number" className={(this.state.a.filter(a => a === 'house_number').length > 0) ? " form-control hs-input field-highlight" : this.state.house_number === '' ? "form-control hs-input yellow-border" : "form-control hs-input"} onChange={this.handleChange} value={this.state.house_number} maxLength={5}/>
                                                <label htmlFor="house_number">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['house_number']}</label>
                                            </div>
                                        </div>
                                        : null
                                    }
                                    { accountType === 4 ? <>
                                    <div className="col-md-6">
                                        <div className="form-group hs-form-field autoComplete-custom focus">
                                            <label htmlFor="postalcode_id">{accountType === 4 ? MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['postal_code'] : MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['residence']}</label>
                                            <Autocomplete
                                                value={locationName}
                                                inputProps={{ id: 'states-autocomplete1', className: (this.state.a.filter(a => a === 'postalcode_id').length > 0) ? " form-control hs-input field-highlight" : locationName === '' ? "form-control hs-input yellow-border" : "form-control hs-input", name: "postalcode" }}//removed to allow german charactors onKeyPress: this.OnlyDigitsAllow,
                                                items={locationData}
                                                getItemValue={item => item.postalcode_city}
                                                shouldItemRender={(locationName) ? (item) => item.postalcode_city : null}
                                                // eslint-disable-next-line
                                                onChange={this.getPostalCode}
                                                // eslint-disable-next-line
                                                onSelect={this.getPostalCode}
                                                renderMenu={children => (
                                                    (children.length) ?
                                                        <div className="form-autocomplete-suggestion">
                                                            {children}
                                                        </div>
                                                        : <div /> 
                                                )}
                                                renderItem={(item, isHighlighted) => (
                                                    <div key={item.id} id={item.id} className={(isHighlighted || item.postalcode_city === locationName) ? "form-autocomplete-suggestion-field custom-autocomplete-background" : "form-autocomplete-suggestion-field"}>
                                                        {item.postalcode_city}
                                           </div>
                                                )}
                                            />
                                            <div>
                                                {(this.state.a.filter(a => a === 'postalcode_id').length > 0) ?
                                                    <span style={span}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]['location']}</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                        <div className="col-md-6">
                                            <div className="form-group focus">
                                                <input type="tel" name="telephone" maxLength={20} className={(this.state.a.filter(a => a === 'telephone').length > 0) ? " form-control hs-input field-highlight" : this.state.telephone === null ? "form-control hs-input yellow-border" : "form-control hs-input"} onChange={this.handleChange} value={this.state.telephone} />
                                                <label htmlFor="telephone">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['phone_number']}</label>
                                            </div>
                                        </div> </> : null
                                    }
                                </div>
                                <div className="pt-3 pt-md-5 text-center">
                                    <button type="button" onClick={this.handleSubmit} className="btn btn-clr text-white text-uppercase font-18" title={MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['save_changes_button']}> {MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE]['save_changes_button']}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainProfile;

