import React from "react";
import { SURVEY_PARTICIPATION, KARMA_POINT } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, IMAGE_BASEURL, BASEURL } from '../../../../constants/DotEnv';
import ParticipationEndingPage from './ParticipationEndingPage';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
class SurveyPreviewEndPage extends React.Component {
    render() {
        let survey_data =  JSON.parse(localStorage.getItem('surveydetail_' + this.props.surveyId))
        let englishbuttonlabels = survey_data?.englishbuttonlabels == "1" ? "EN" : "DE"
        let survey_logo;
        let survey_optional_logo;
            survey_logo = survey_data.survey_logo;
            survey_optional_logo =  survey_data.survey_optional_logo
        return (
            <>
                <div>
                    <div className="modal_header border-bottom-0">
                        <div className="container container-large">
                            <div className="row align-items-center">
                                <div className="col-8 col-md-9 text-left px-0 px-lg-2">
                                {survey_data.account_type_category_id == 4 || survey_data.account_type_category_id == 2?
                                survey_data.isUserHaveActivePlan == 1?
                                survey_optional_logo ?
                                    <div className="modal-logo">
                                    <a href={BASEURL} className="navbar-brand">
                                        <img className="light-logo custom-logo-size" src={survey_optional_logo} alt="empirio-logo" />
                                    </a>
                                </div>
                                : null : <div className="modal-logo">
                                    <a href={BASEURL} className="navbar-brand">
                                        <img className="light-logo custom-logo-size" src={survey_data.logo_image1} alt="empirio-logo"  />
                                    </a>
                                </div> : <div className="modal-logo">
                                        <a href={BASEURL} className="navbar-brand">
                                            <img className="light-logo custom-logo-size" src={survey_data.logo_image1} alt="empirio-logo"  />
                                        </a>
                                    </div>
                                }
                                </div>
                                <div className="col-4 col-md-3 text-right closetab pb-2">
                                    <Link className="text-white close font-weight-bold" to='#' onClick={this.props.clearLocalstorageForQuestion} > 
                                        <i className="ti-close ti-close-participation font-weight-bold"></i> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="banner_text d-flex align-items-center survey-detal-bg-img survey-ending-page survey-participate-page">
                        <div className="container-fluid px-0 h-350 background-image-css" style={{ "backgroundImage": "url(" + this.props.backgroundImageUrl + ")" }} >
                            <div className="page-title survey-end-background-content">
                                <div className="round-check text-center text-white">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path-new circle-new" fill="none" stroke="#FFFFFF" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="60" />
                                        <polyline className="path-new check-new" fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                    </svg>
                                    <h1 className="text-white px-sm-0 px-5 font-weight-bold text-break">{SURVEY_PARTICIPATION[englishbuttonlabels]['endingpage']}</h1>
                                    <h3 className="text-white px-sm-0 px-5 font-weight-normal text-break">
                                        +<CountUp delay={2} end={this.props.totalEarnKarmaPoints} />  {KARMA_POINT[LANGUAGE_CODE]['earnkarmapoint']}
                                    </h3>
                                        <div className="survey-participate-detail">
                                            <div className="survey-title-box">
                                                <h2 className="text-dark font-weight-bold mb-4">
                                                    {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_title']}
                                                </h2>
                                            </div>
                                            <div class="text-gray text-survey d-flex text-left">
                                                <span class="fa fa-check survey-result-unlock-popup"></span>
                                                <div class="mb-0">
                                                    {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_first_subtitle']}
                                                </div>
                                            </div>
                                            <div class="text-gray text-survey d-flex text-left">
                                                <span class="fa fa-check survey-result-unlock-popup"></span>
                                                <div class="mb-0">
                                                    {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_second_subtitle']}
                                                </div>
                                            </div>
                                            <div class="text-gray text-survey d-flex text-left">
                                                <span class="fa fa-check survey-result-unlock-popup"></span>
                                                <div class="mb-0">
                                                    {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_third_subtitle']}
                                                </div>
                                            </div>
                                            <div class="text-gray text-survey d-flex text-left">
                                                <span class="fa fa-check survey-result-unlock-popup"></span>
                                                <div class="mb-0">
                                                    {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_fourth_subtitle']}
                                                </div>
                                            </div>
                                            <div className="survey-participate-btn">
                                                <Link to='#' onClick={this.props.clearLocalstorageForQuestion} className="btn btn-clr text-white w-100" >
                                                    {SURVEY_PARTICIPATION[LANGUAGE_CODE]['end_page_registration_pop_up_link_text']}
                                                </Link>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className='row wizard survey-participation-modal wizard__container justify-content-center align-items-center no-gutters pb-0 participation-end-content custom-height-endpage'>
                    <div classname='tab-content text-left max-width-content'>
                        <div classname='content clearfix'>
                            <ParticipationEndingPage surveyId={this.props.surveyId} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default SurveyPreviewEndPage;