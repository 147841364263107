import React from 'react';
import { DEMOGRAPHIC_PARTICIPATION_CONSTANT } from '../../../../constants/FrontEndConstant';
import Button from '@material-ui/core/Button';
class DemographicSingleSelection extends React.Component {
    
    labelOnClick = (e) => {
        if (e.currentTarget.name) {
            document.getElementById(e.currentTarget.name).click();
            document.getElementById(e.currentTarget.name).checked = !(document.getElementById(e.currentTarget.name).checked);
        }
    }

    render() {
        let data = this.props.demographicdata;
        let surveyDetail = JSON.parse(localStorage.getItem('surveydetail_' + this.props.survey_id))
        let dropdownoption = this.props.demographicdropdown;
        let options = '';
        let optionlist = [];
        var name;
        var value = "";
        var lengthCount = "";
        if (data) {
            var attribute_id = data.id;
            DEMOGRAPHIC_PARTICIPATION_CONSTANT['EN'].map((title, index) => {
                if (parseInt(attribute_id) === parseInt(title.id)) {
                    name = title.title
                    Object.keys(this.props.questionanswer).map((ans, index) => {
                        this.props.questionanswer.map((key, index) => {
                            Object.keys(key).map((finalkey) => {
                                if (finalkey === name) {
                                    value = key[finalkey]
                                }
                                return 0;
                            })
                            return 0;
                        })
                        return 0;
                    })
                }
                return 0;
            })
            if (attribute_id === 2) {
                options = dropdownoption.gender;
            }
            if(attribute_id === 4) {
                options = dropdownoption.income;
            }
            if(attribute_id === 5) {
                options = dropdownoption.employment;
            }
            if(attribute_id === 6) {
                options = dropdownoption.education;
            }
            if(attribute_id === 7) {
                options = dropdownoption.MaritalStatus;
            }
        }       
        if (options) {
            let dataTotalLength = Object.keys(options).length;
            let j = 0;
            lengthCount = dataTotalLength / 2;
            for (var i = 0; i <= lengthCount; i++) {
                optionlist.push(
                    <div className="row pb-0 pb-md-3" key={j}>
                        {(j < dataTotalLength) ?
                            <div className="col-12 col-md-6 pb-4 pb-sm-3 pb-md-0">
                                <div className="custom-radio-btn w-100 demo-single MuiButton-effect">
                                    <div className="custom-control custom-radio custom-control-inline pl-0 w-100">
                                        <label htmlFor={"customRadioInline" + j} className="btn-radio demographicsingle p-0">
                                        <Button variant="contained" className="py-3 px-4" color="#00b3f4" name={"customRadioInline" + j} onClick={(e)=>this.labelOnClick(e)}>
                                            <input type="radio" id={"customRadioInline" + j} name={name} value={options[j]['id']} className="demographicsingle" onChange={this.props.onChange} onClick={this.props.onChange} checked={(value !== undefined || value !== "") ? (parseInt(value) === parseInt(options[j]['id'])) : false} />
                                            <svg width="20px" height="20px" viewBox="0 0 20 20">
                                                <circle cx="10" cy="10" r="9"></circle>
                                                <path  style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }} d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" class="inner"></path>
                                                <path style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }} d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" class="outer"></path>
                                            </svg>
                                            <span className="ml-3 font-weight-normal">{attribute_id === 2 ? options[j]['title'] : attribute_id === 4 ? options[j]['income_range'] : attribute_id === 5 ? options[j]['employment_name'] : attribute_id === 6 ? options[j]['education_name'] : attribute_id === 7 ? options[j]['title'] :null}</span>
                                        </Button>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                        {(parseInt(j + 1) < dataTotalLength) ?
                            <div className="col-12 col-md-6 pb-4 pb-sm-3 pb-md-0">
                                <div className="custom-radio-btn w-100 demo-single MuiButton-effect">
                                    <div className="custom-control custom-radio custom-control-inline pl-0 w-100">
                                        <label htmlFor={"customRadioInline" + (j + 1)} className="btn-radio demographicsingle p-0">
                                        <Button variant="contained" className="py-3 px-4" color="#00b3f4" name={"customRadioInline" + (j + 1)} onClick={(e)=>this.labelOnClick(e)}>
                                            <input type="radio" id={"customRadioInline" + (j + 1)} name={name} value={options[(j + 1)]['id']} className="demographicsingle" onChange={this.props.onChange} onClick={this.props.onChange} checked={(value !== undefined || value !== "") ? (parseInt(value) === parseInt(options[(j +1 )]['id'])) : false} />
                                            <svg width="20px" height="20px" viewBox="0 0 20 20">
                                                <circle cx="10" cy="10" r="9"></circle>
                                                <path style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }} d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" class="inner"></path>
                                                <path style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }} d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" class="outer"></path>
                                            </svg>
                                            <span className="ml-3 font-weight-normal">{attribute_id === 2 ? options[(j + 1)]['title'] : attribute_id === 4 ? options[(j + 1)]['income_range'] : attribute_id === 5 ? options[(j + 1)]['employment_name'] : attribute_id === 6 ? options[(j + 1)]['education_name'] : attribute_id === 7 ? options[(j + 1)]['title'] :null}</span>
                                        </Button>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                )
                j = j + 2;
            }
        }
        return (
            <div key={this.props.demographicdata.id} className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
                <div className="question-heading">
                    <h3 className="text-dark font-weight-bold survey-title">{data.question}</h3>
                    {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
                </div>
                {optionlist}
            </div>
        )
    }
}
export default DemographicSingleSelection;