import React from 'react';
import SurveyListContent from '../surveyListComponents/SurveyListContent';
import { META_TAGS_CONSTANT, META_TITLE_CONSTANT, META_FACEBOOK, META_OG_DESCRIPTION, BREADCRUMB_SCHEMA } from '../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, LIMIT, OFFSET, META_TITLE_APPEND } from '../../../constants/DotEnv';
import SurveyListHeader from '../../subPageHeader/SubPageHeader';
import axios from "axios";
class SurveyList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataLength: '',
            title: '',
            sub_title: '',
            loading: 'false',
            surveycount: '',
        }
    }
    componentWillUnmount() {
        setTimeout(() => {
            window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
            window.removeSchema();
        }, 100)
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.surveylistcontent();
        setTimeout(() => {
            var schema2 = window.positionCreate(BREADCRUMB_SCHEMA['umfragen']['schema2']['position'], BREADCRUMB_SCHEMA['umfragen']['schema2']['url'], BREADCRUMB_SCHEMA['umfragen']['schema2']['name']);
            var schemaObject = window.schemaObj(BREADCRUMB_SCHEMA['schema1'], schema2, BREADCRUMB_SCHEMA['umfragen']['schema3']);
            window.setSchema(schemaObject);
        }, 100)
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (document.querySelector('.navbar-toggler')) {
            let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
            if (count === -1) {
                document.querySelector('.navbar-collapse').classList.remove('show');
                document.querySelector('header').classList.remove("header_collapse");
                document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
            }
        }
    }
    surveylistcontent = async () => {
        this.setState({ loading: 'true' });
        let filteritem = JSON.parse(localStorage.getItem('filter'));
        let duration = '';
        let university = '';
        let status = "";
        let category = "";
        let reward = "";
        if (filteritem) {
            duration = filteritem[0]['duration'];
            university = filteritem[0]['university'];
            reward = filteritem[0]['reward'];
            status = filteritem[0]['status'];
            category = filteritem[0]['category'];
        }
        let data = '';
        // eslint-disable-next-line
        let surveycount = '';
        let dataLength = '';
        let title = '';
        let sub_title = '';
        let meta_title = '';
        let meta_description = '';
        let meta_keywords = '';
        let og_title = '';
        let og_description = META_OG_DESCRIPTION[LANGUAGE_CODE]['surveylist'];
        let og_image = META_FACEBOOK['og_image'];
        let self = this;
        let userId = '';;
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        if(storeitem !== null) {
            userId = storeitem.id;
        }
        await axios.get(API_URL + '/commonApi/getSurveyData/' + LANGUAGE_CODE + '?limit=' + LIMIT + '&offset=' + OFFSET + '&category=' + category + '&university=' + university + '&duration=' + duration + '&reward=' + reward + '&status=' + status + '&userId=' + userId)
            .then((response) => {
                data = response.data.success.surveydata;
                title = response.data.success.title;
                sub_title = response.data.success.sub_title;
                dataLength = Object.keys(data).length;
                surveycount = response.data.success.surveycount;
                meta_title = META_TITLE_CONSTANT[LANGUAGE_CODE]['surveylisting'] + META_TITLE_APPEND;
                meta_description = META_OG_DESCRIPTION[LANGUAGE_CODE]['surveylist'];
                meta_keywords = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords'];
                og_title = response.data.success.title + META_TITLE_APPEND;
                window.setMeta({ "description": meta_description, "keywords": meta_keywords });
                window.setOgTag(og_title, og_description, og_image, window.location.href)
                window.setDocumentTitle(meta_title)
                self.setState({ loading: 'false', data, dataLength, title, sub_title, surveycount })
            })
            .catch((error) => {
                self.setState({ loading: 'false' })
            })
    }
    render() {
        let data = this.state.data;
        let surveycount = this.state.surveycount;
        return (
            // <!-- Main -->
            <main>
                <SurveyListHeader data={data} title={this.state.title} sub_title={this.state.sub_title} />
                <SurveyListContent data={data} loading={this.state.loading} surveycount={surveycount} />
            </main>
            //   <!-- / -->
        );
    }
}
export default SurveyList;