import 'react-app-polyfill/ie9';
import React from "react";
import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import App from "./components/App";
import { BrowserRouter as Router } from "react-router-dom";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(
    <Provider store={store}>
      <Router basename="/">
        <App />
      </Router>
    </Provider>,
    document.getElementById("root")
  );
}