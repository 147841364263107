import React, { Fragment } from 'react';
import axios from "axios";
import Redirect from 'react-router-dom/Redirect';
import { SURVEY_CREATION_CONSTANT } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL } from '../../../../constants/DotEnv';
import SurveyElementContent from '../surveyElement/SurveyElementContent'
import SurveyContentPage from '../../surveyCreationComponents/surveyContentPage/SurveyContentPage';
import SurveyElementFreetext from "../surveyElement/SurveyElementFreetext";
import SurveyElementRatingSingle from "../surveyElement/SurveyElementRatingSingle";
import SurveyElementRatingMore from "../surveyElement/SurveyElementRatingMore"
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
class SurveyCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            questionType: [],
            loading: 'false',
            redirect: 'false'
        }
    }
    componentDidMount() {
        this.getQuestionType();
    }
    getQuestionType = () => {
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let SurveyQuestionTypeDropdownList = JSON.parse(localStorage.getItem("surveyquestiontypedropdownlist"));
        let apitoken = '';
        let userId = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
            userId = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        let questionType = '';
        let self = this;
        if (SurveyQuestionTypeDropdownList === null) {
            axios.get(API_URL + '/questiontype/getQuestionType/' + LANGUAGE_CODE + '?uId=' + userId, { headers: headers })
                .then((response) => {
                    let responseNew = '';
                    responseNew = response.data['success'];
                    localStorage.setItem("surveyquestiontypedropdownlist", JSON.stringify(responseNew));
                    questionType = responseNew.questiontype;
                    self.setState({ questionType, loading: 'false' })
                })
                .catch((error) => {
                    let msg = error.response.data['error']
                    if (msg === 'Expired token' || msg === 'Invalid token') {
                        localStorage.clear();
                        self.setState({ redirect: 'true' })
                    }
                    self.setState({ loading: 'false' })
                })
        }
        else {
            questionType = SurveyQuestionTypeDropdownList.questiontype;
            this.setState({ questionType, loading: 'false' });
        }
    }
    questionTypeSection = (questionTypeOption, onChangeFunction, data) => {
        let displayToggle = true;
        let displayDropDown = false;
        let totaluserparticipated = this.props.stateData.surveyData.totaluserparticipated
        if(data.id && totaluserparticipated >= 1){
            displayDropDown = true
        }
        if(data.question_type_id && parseInt(data.question_type_id) === 2){
            displayToggle = false;
        }
        return (
            <div className="continue-btn survey-form-detail mt-4 question-dropdown-material-parent">
                {displayToggle ? 
                    <div className="swich-slider d-flex align-items-center mb-4">
                        <span className="text-dark slider-text">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_question_mandatory_toggle']}</span>
                        <label className="switch mx-2">
                            <input type="checkbox" data-toggle="toggle" name="questionOptionalToggle" checked={this.props.data.is_question_optional && parseInt(this.props.data.is_question_optional) === 1 ? true : false} onClick={this.props.onChange} />
                            <span className="slider slider-change round"></span>
                        </label>
                        <span className="text-dark slider-text">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_question_optional_toggle']}</span>
                    </div>
                : null }
                <div className="input-group-prepend">
                    <label className="input-group-text sr-only" htmlFor="question-type"></label>
                </div>
                <FormControl variant="outlined" className="w-100">
                    <InputLabel id="demo-simple-select-autowidth-label" className={(parseInt(data.question_type_id)) ? "custom-option-default d-none" : "custom-option-default"}>Bitte Fragetypen auswählen</InputLabel>
                    <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-outlined question_type_id"
                    autoWidth
                    value={parseInt(data.question_type_id)}
                    name="question_type_id"
                    onChange={onChangeFunction}
                    className="custom-dropdown-style"
                    disabled={displayDropDown}
                    >
                        <MenuItem value='' className="custom-dropdown-option-style">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_question_type_default_option']}</MenuItem>
                        {questionTypeOption}
                    </Select>
                </FormControl>
                <div id="errorSurveyMessage" className="error-text mt-2">{(this.props.validationMessage && this.props.errorElementPosition === 0) ? <p>{this.props.validationMessage}</p> : null}</div>
            </div>
        );
    }
    questionDetailSection = (onSelect, data) => {
        let questionId = data.question_type_id;
        switch (parseInt(questionId)) {
            case 1:
                return <SurveyElementFreetext validationMessage={this.props.validationMessage} errorElementPosition={this.props.errorElementPosition} value={data} message={this.props.message} onChange={onSelect} />
            case 2:
                return <SurveyContentPage fieldName="question_content" stateData={this.props.stateData} validationMessage={this.props.validationMessage} design={this.props.design} data={this.props.data} onUpdate={this.props.onUpdate} noAnimation={true}/>
            case 3:
                return <SurveyElementContent optionLimit={this.props.optionLimit} validationMessage={this.props.validationMessage} errorElementPosition={this.props.errorElementPosition} optionCount={this.props.optionCount} value={data} message={this.props.message} onChange={onSelect} />
            case 4:
                return <SurveyElementContent optionLimit={this.props.optionLimit} validationMessage={this.props.validationMessage} errorElementPosition={this.props.errorElementPosition} optionCount={this.props.optionCount} value={data} message={this.props.message} onChange={onSelect} />
            case 5:
                return <SurveyElementContent optionLimit={this.props.optionLimit} validationMessage={this.props.validationMessage} errorElementPosition={this.props.errorElementPosition} optionCount={this.props.optionCount} value={data} message={this.props.message} onChange={onSelect} />
            case 6:
                return <SurveyElementRatingSingle data={data} message={this.props.message} validationMessage={this.props.validationMessage} errorElementPosition={this.props.errorElementPosition} optionLimit={this.props.optionLimit} optionCount={this.props.optionCount} onChange={onSelect} onSelect={onSelect} />
            case 7:
                return <SurveyElementRatingMore optionScaleLimit={this.props.optionScaleLimit} validationMessage={this.props.validationMessage} errorElementPosition={this.props.errorElementPosition} optionScaleCount={this.props.optionScaleCount} data={data} message={this.props.message} optionLimit={this.props.optionLimit} optionCount={this.props.optionCount} onChange={onSelect} onSelect={onSelect} />
            default:
        }
    }
    render() {
        let data = this.props.data;
        let onChangeFunction = this.props.onChange;
        let onSelect = this.props.onSelect;
        let questionTypeOption = this.state.questionType.map((question_type, index) => {
            // eslint-disable-next-line
            let id = parseInt(question_type.id);
            return (
                // eslint-disable-next-line
                (id === 3) ? <MenuItem value={question_type.id} key={question_type.id} className="custom-dropdown-option-style">&nbsp;&#128280;&nbsp;&ensp;{question_type.question_type_name}</MenuItem>
                    : (id === 4) ? <MenuItem value={question_type.id} key={question_type.id} className="custom-dropdown-option-style">&nbsp;&#9745;&ensp;&nbsp;{question_type.question_type_name}</MenuItem>
                    : (id === 1) ? <MenuItem value={question_type.id} key={question_type.id} className="custom-dropdown-option-style">&nbsp;&#9999;&ensp;&nbsp;{question_type.question_type_name}</MenuItem>
                    // eslint-disable-next-line
                    : (id === 5) ? <MenuItem value={question_type.id} key={question_type.id} className="custom-dropdown-option-style">&nbsp;&#127942;&ensp;&nbsp;{question_type.question_type_name}</MenuItem>
                    // eslint-disable-next-line
                    : (id === 6) ? <MenuItem value={question_type.id} key={question_type.id} className="custom-dropdown-option-style">&nbsp;&#128077;&ensp;&nbsp;{question_type.question_type_name}</MenuItem>
                    // eslint-disable-next-line
                    : (id === 7) ? <MenuItem value={question_type.id} key={question_type.id} className="custom-dropdown-option-style">&nbsp;&#128077;&ensp;&nbsp;{question_type.question_type_name}</MenuItem>
                    // eslint-disable-next-line
                    : (id === 2) ? <MenuItem value={question_type.id} key={question_type.id} className="custom-dropdown-option-style">&nbsp;&#8505;&ensp;&nbsp;{question_type.question_type_name}</MenuItem>
                : null)
        })
        const elements = <div> {this.questionTypeSection(questionTypeOption, onChangeFunction, data)} </div >;
        var elementTipps;
        var typeId = parseInt(data.question_type_id);
        var attribute = [];
        if (typeId) {
            elementTipps = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_tipps'][typeId]
            // eslint-disable-next-line
            Object.values(SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_tipps_default_option']).map((msg, index) => {
            })
        }
        else {
            elementTipps = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_tipps'][0];
            Object.values(SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_tipps_default_option']).map((msg, index) => {
                return attribute += "<li>" + msg + "</li>";
            })
        }
        const elementComponent = < div> {this.questionDetailSection(onSelect, data)} </div >;
        elementTipps = (!typeId) ? <h3 className="font-14 mt-3 fw-400 lh-24" dangerouslySetInnerHTML={{ __html: elementTipps }}></h3> : <h3 className="font-14 mt-3 fw-400 lh-24">{elementTipps}</h3>
        return (
            <Fragment>
                {(this.state.redirect === 'true') ?
                    <Redirect to="/anmelden"></Redirect>
                    : null
                }
                <div className={"hs-form-field custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")}>
                    <h2 className="text-dark h3 font-weight-bold survey-title">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_title']}</h2>
                    <h3 className="font-14 lh-24 fw-400 survey-subtitle">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_subtitle']}</h3>
                    {elements}
                    {(this.props.stateData.editQuestionRedirect || this.props.stateData.addQuestionIndex) ?
                        (typeId) ? elementTipps : null
                        : elementTipps
                    }
                    {elementComponent}
                </div>
            </Fragment>
        );
    }
}
export default SurveyCategory;