import React from "react";
import axios from "axios";
import {BUBBLE_LOADING,META_FACEBOOK,BREADCRUMB_SCHEMA,PAYMENT_CONSTANTS, MYPROFILE_ERROR_MESSAGE, BILLING_ADDRESS_ERROR,CHECKOUT_SUBSCRIPTION} from "../../constants/FrontEndConstant";
import { LANGUAGE_CODE, API_URL, IMAGE_BASEURL,BASEURL} from "../../constants/DotEnv";
import Header from "./Header";
import PaypalSubscription from './PaypalSubscription'
import CreditCardSubscription from "./CreditCardSubscription";
import Payment from './Payment';
import { confirmAlert } from 'react-confirm-alert';
import Autocomplete from "react-autocomplete";
import { Redirect } from "react-router";
import StripePayment from "./StripePayment";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const product_id_month = process.env.REACT_APP_PAYPAL_1_MONTH_PRODUCT_ID
const product_id_annual = process.env.REACT_APP_PAYPAL_1_YEAR_PRODUCT_ID
const homeSectionUrl = {
  768 : 'img/empirio_startseiten_grafiken_hintergrund01_content03.svg',
  375 : 'img/empirio_startseiten_grafiken_hintergrund01_content03-375.svg',
  320 : 'img/empirio_startseiten_grafiken_hintergrund01_content03-320.svg',
  'default' : 'img/empirio_startseiten_grafiken_hintergrund01_content02.svg'
}
const url = {
  1440 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-1440.svg',
  1280 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-1280.svg',
  1024 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-1024.svg',
  768 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-768.svg',
  375 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-375.svg',
  'default' : 'img/empirio_startseiten_grafiken_hintergrund01.svg'
}
class CheckoutUniversity extends React.Component {
  constructor() {
    super();
    this.state = {
      subscription: null,
      method: null,
      homecontent: {},
      faqcontent: {},
      loading: "false",
      paypal: false,
      stripe: false,
      elements: null,
      message: "",
      showPaypal: false,
      showgiropay: false,
      showCard: false,
      nsu_name: "",
      phone: "",
      street: "",
      house_number: "",
      tax_id: "",
      postalcode: "",
      country_code: "",
      postalcode_id:null,
      a: [],
      postalcodedata: [],
      postalloading: false,
      ui_id: "",
      postalCodeMatchFound: true,
      taxidMatchNotFound: false,
      organization: "",
      house: "",
      postal: "",
      first_name: "",
      last_name: "",
      plan_type: '',
      empty_field_error:false,
      price: {},
      customer_id:'',
      payment_intent_id:'',
      mobileDevice:''
    };
    this.package = React.createRef();
    this.headerRef = React.createRef();
    setTimeout(() => {
      window.setNoIndex();
    }, 100)
    this.setImageVar()
  }

  setImageVar = () => {
      if(window.innerWidth <= 375) {
        this.mainImageBg = "url(" +IMAGE_BASEURL+ url[375]+")"
      }else if(window.innerWidth <= 768) {
        this.mainImageBg = "url(" +IMAGE_BASEURL+ url[768]+")"
      }else if(window.innerWidth <= 1024) {
        this.mainImageBg = "url(" +IMAGE_BASEURL+ url[1024]+")"
      }else if(window.innerWidth <= 1440){
        this.mainImageBg = "url(" +IMAGE_BASEURL+ url[1440]+")"
      }else{
        this.mainImageBg = "url(" + IMAGE_BASEURL+ url['default'] +")"
      }
  }

  componentWillUnmount() {
    window.removeNoIndex();
    setTimeout(() => {
        window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
    }, 100)
  }

  setHomeSectionVar = () => {
  if(window.innerWidth <= 320) {
      this.homeSectionImageBg = "url(" +IMAGE_BASEURL+ homeSectionUrl[320]+")"
    }else if(window.innerWidth <= 375) {
      this.homeSectionImageBg = "url(" +IMAGE_BASEURL+ homeSectionUrl[375]+")"
    }else if(window.innerWidth < 768) {
      this.homeSectionImageBg = "url(" +IMAGE_BASEURL+ homeSectionUrl[768]+")"
    }else{
      this.homeSectionImageBg = "url(" + IMAGE_BASEURL+ homeSectionUrl['default'] +")"
    }
}
  handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value },this.handleClick);
  };

  taxIDValidation = () => {
    let state = this.state
    if (state.tax_id && state.tax_id.length > 0) {        
      if (state.country_code == "DE") {
        if (/^DE[0-9]{9}$/.test(state.tax_id)) {
          this.setState({taxidMatchNotFound: false},this.handleClick)
        }else {
          this.setState({ taxidMatchNotFound: true },this.handleClick)
          let formfield_error_list = document.getElementsByClassName('billing_info_error');
          for(let i=0; i< formfield_error_list.length; i++){
            formfield_error_list[i].classList.remove('d-none');
          }
          return false
        }
      } else if (state.country_code == "AT") {
        if (/^ATU[0-9]{8}$/.test(state.tax_id)) {
          this.setState({taxidMatchNotFound: false},this.handleClick)
        }else {
          this.setState({ taxidMatchNotFound: true },this.handleClick)
          let formfield_error_list = document.getElementsByClassName('billing_info_error');
          for(let i=0; i< formfield_error_list.length; i++){
            formfield_error_list[i].classList.remove('d-none');
          }
          return false
        }
      } else if (state.country_code == "CH") {
        if (/^CHE(-|\s|\.)?[0-9]{3}(-|\s|\.)?[0-9]{3}(-|\s|\.)?[0-9]{3}(\s?(MWST|TVA|IVA))?$/.test(state.tax_id)) {
          this.setState({taxidMatchNotFound: false},this.handleClick)
        }else {
          this.setState({ taxidMatchNotFound: true },this.handleClick)
          let formfield_error_list = document.getElementsByClassName('billing_info_error');
          for(let i=0; i< formfield_error_list.length; i++){
            formfield_error_list[i].classList.remove('d-none');
          }
          return false
        }
      } 
    } else {
      this.setState({taxidMatchNotFound: false},this.handleClick);
    }
    return true
  }



  formValidate = () => {
    let state = this.state;
    if( state.subscription && state.organization && state.first_name && state.last_name && state.street && state.house_number && state.postalcode && state.postalCodeMatchFound)
    {
      this.setState({empty_field_error:false});
      return true;
      //this.handleClick();
    }
    else
    {
      this.setState({empty_field_error:true});
      // if(this.state.subscription == null)
      //   document.getElementById('package').scrollIntoView();
      // else
      //   document.getElementById('invoice_address').scrollIntoView();
      let formfield_error_list = document.getElementsByClassName('billing_info_error');
      for(let i=0; i< formfield_error_list.length; i++){
        formfield_error_list[i].classList.remove('d-none');
      }
      if(this.state.error)
      {
        let list = document.getElementsByClassName('form-control hs-input ');
        for(let i=0; i< list.length; i++){
          list[i].classList.add('field-highlight');
        }
      }
      return false;
    }
  }

  getPostalCode = async (event, ui) => {
    if (this.postalDataRequest) {
      clearTimeout(this.postalDataRequest);
    }
    let i = this.state.a.indexOf("postalcode");
    if (i > -1) {
      this.state.a.splice(i, 1);
    }
    this.postalcodedata = "";
    this.ui_id = this.state.ui_id;
    let value = event.target !== undefined ? event.target.value : event;
    const valLength = value.length;
    let storeitem = JSON.parse(localStorage.getItem("userdata"));
    let apitoken = "";
    let userId = "";
    if (storeitem !== null) {
      apitoken = storeitem.token;
      userId = storeitem.id;
    }
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apitoken,
    };
    let self = this;
    if (valLength > 1 && event.target !== undefined) {
      this.setState({ postalloading: true });
      this.postalDataRequest = setTimeout(async () => {
        // eslint-disable-next-line
        await axios.get(API_URL +"/userprofile/getPostalcodedata/" +value +"/" +LANGUAGE_CODE +"?uId=" +userId,{ headers: headers })
          .then((response) => {
            this.postalcodedata = response.data.success.postalcodedata;
            this.postalcodedata = JSON.parse(JSON.stringify(this.postalcodedata));
            // eslint-disable-next-line
            self.setState({postalcodedata: this.postalcodedata,postalloading: false,},this.handleClick);
          })
          .catch((error) => {self.setState({ postalloading: false });});
      }, 400);
    }
    if (self.state.postalcodedata.length) {
      let postalCodeFound = false;
      // eslint-disable-next-line
      let billing_info = {}
      self.state.postalcodedata.map((postalData) => {
        if (postalData.postalcode_city === value.toString()) {
          postalCodeFound = true;
          this.setState({postalcode_id:postalData.id, country_code:postalData.country_code},this.handleClick)
          billing_info['postalcode_id'] = postalData.id
          localStorage.setItem('billing_info',JSON.stringify(billing_info))
        }
      });
      self.setState({
        postalCodeMatchFound: postalCodeFound,
      });
    } else if (valLength) {
      self.setState({
        postalCodeMatchFound: false,
      });
    }
    this.ui_id = ui.id ? ui.id : "";
    // this.setState({postalcode_id:})
    this.setState({ postalcode: value, ui_id: this.ui_id },this.handleClick);
  };
  //Start 322 Restructuring Payment Popup and Stripe Integration
  // Fetches a payment intent and captures the client secret
  initialize =  async () => {
    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_KEY,{locale:'de'});
    this.setState({stripe:stripe});
    let storeitem = JSON.parse(localStorage.getItem('userdata'));
    const userId = storeitem.id;
    let apitoken = storeitem.token;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + apitoken
    };
    let clientSecret = localStorage.getItem("clientSecret")!=null && localStorage.getItem("clientSecret")!='undefined' ? localStorage.getItem("clientSecret"):'';
    let plan_type =  localStorage.getItem("plan_type") != null && localStorage.getItem("plan_type") != 'undefined' ? localStorage.getItem("plan_type"):1;
    let billing_info = JSON.parse(localStorage.getItem('billing_info'));
    if(billing_info === null){
      billing_info = {};
      billing_info['postalcode_id'] = this.state.postalcode_id;
    }
    //let customer_id = '';

    if (this.state.postalcode && this.state.country_code !== undefined && this.state.country_code !== null && this.state.postalcode.length > 0 && this.state.country_code.length < 1) {
      setTimeout(async () => {
        // eslint-disable-next-line
        await axios.get(API_URL +"/userprofile/getPostalcodedata/" +this.state.postalcode +"/" +LANGUAGE_CODE +"?uId=" +userId,{ headers: headers })
          .then((response) => {
            this.postalcodedata = response.data.success.postalcodedata;
            this.postalcodedata = JSON.parse(JSON.stringify(this.postalcodedata));
            // eslint-disable-next-line
            this.setState({ country_code: this.postalcodedata?.[0]?.country_code }, this.handleClick);
          })
          .catch((error) => {this.setState({ postalloading: false });});
      }, 400);
    }

    if(clientSecret == ''){
        try {
        let isTrialSubscription = 0;
        if(!storeitem.IsSubscriptioncancle && !storeitem.plan_end_date) isTrialSubscription = 1;
        const res = await axios.post(API_URL + '/payment/subscriptionPaymentIntent/' + userId+ '?uId=' + userId, {first_name:this.state.first_name, last_name:this.state.last_name, postalcode_id:billing_info.postalcode_id, plan_type: plan_type, organization: billing_info.organization, telephone: storeitem.telephone, street: billing_info.street, house_number: billing_info.house_number, name: billing_info.first_name + " " + billing_info.last_name, email: storeitem.email,isSubscription:1,IsSubscriptioncancle:storeitem.IsSubscriptioncancle, end_date:storeitem.sub_plan_end_date, isTrialSubscription: isTrialSubscription},
        { headers: headers }).then((res) => {
          clientSecret = res.data.success.intent;
          this.setState({payment_intent_id:clientSecret});
          let customer_id = res.data.success.customer_id;
          localStorage.setItem("clientSecret",clientSecret);       
          localStorage.setItem("start_date",res.data.success.start_date);
          localStorage.setItem("customer_id",customer_id);
        });
        } catch (err) {
          console.log(err);
        }
   }
    let stripe_elements = stripe.elements({ clientSecret });
    const paymentElementOptions = {
      layout: "tabs",
      fields: {
        billingDetails: {
            address: {
                country: 'never',
            }
        }
      }
    };
    const paymentElement = stripe_elements.create("payment", paymentElementOptions);
    paymentElement.mount("#payment-element");
    this.setState({elements:stripe_elements});
    paymentElement.on('change', this.paymentClick);
  };
  handleSubmit = async (e) => {
      e.preventDefault();
      const validate = this.formValidate()
      const taxIDvalidate = this.taxIDValidation()
      if (validate && taxIDvalidate) {
          this.setLoading(true);
          let iban = localStorage.getItem('IBAN');
          localStorage.removeItem("clientSecret");
          let billing_info = JSON.parse(localStorage.getItem('billing_info'));
          const payment_method = localStorage.getItem('method')
          if (payment_method && billing_info && payment_method !== billing_info['payment_method']) {
            billing_info['payment_method'] = payment_method
            localStorage.setItem('billing_info',JSON.stringify(billing_info));
          }
          if(iban == 1) {
            let isTrialSubscription = 0;
            let storeitem = JSON.parse(localStorage.getItem('userdata'));
            const userId = storeitem.id;
            let apitoken = storeitem.token;
            const headers = {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + apitoken
            };
            if(!storeitem.IsSubscriptioncancle && !storeitem.plan_end_date) isTrialSubscription = 1;
            let price =  billing_info.price.replace(',','.');
            let start_date = localStorage.getItem('start_date');
            let plan_type = parseInt(localStorage.getItem("plan_type"));
            let trans_id = storeitem.transaction_id;
            let transaction_status = storeitem.transaction_status;
            let payment_method = storeitem.payment_method;
            await axios.post(API_URL + '/payment/iban/' + userId+ '?uId=' + userId, {first_name:billing_info.first_name, last_name:billing_info.last_name, postalcode_id:billing_info.postalcode_id,plan_type: plan_type, organization:billing_info.organization, telephone: storeitem.telephone,price:price, street: billing_info.street, house_number: billing_info.house_number, name: billing_info.first_name + " " + billing_info.last_name, email: storeitem.email, isSubscription: 1, IsSubscriptioncancle:storeitem.IsSubscriptioncancle, start_date:start_date,end_date:storeitem.sub_plan_end_date, isTrialSubscription: isTrialSubscription,transaction_id:trans_id,transaction_status:transaction_status,payment_method:payment_method,tax_id:billing_info.tax_id && billing_info.tax_id.length > 0 ? billing_info.tax_id: null},
              { headers: headers }).then((res)=>{
                localStorage.setItem('payment_status',1);
                storeitem['plan_type'] = plan_type.toString();
                localStorage.setItem("userdata",JSON.stringify(storeitem));
                localStorage.setItem('transaction_id',res.data.success.transaction_id);
                localStorage.setItem('IBAN',0);
                let redirectURL = localStorage.getItem('redirectURL')!=null ? localStorage.getItem('redirectURL'): BASEURL + "mein-bereich";
                window.location = redirectURL;
              })
            .catch((err)=> {
                console.log(err);
            });
          }else if(iban == 0) {
            const stripe = this.state.stripe;
            let redirectURL = BASEURL + "university-checkout";
            let elements = this.state.elements;
            let isTrialSubscription = 0;
            let result = '';
            let storeitem = JSON.parse(localStorage.getItem('userdata'));
            if(!storeitem.IsSubscriptioncancle && !storeitem.plan_end_date) {
              isTrialSubscription = 1;
            }
            if(isTrialSubscription == 1 || storeitem.IsSubscriptioncancle == 1 || storeitem.isPlanExpire == 1) {
              result  = await stripe.confirmSetup({
                elements,
                confirmParams: {
                  return_url: redirectURL,
                  payment_method_data: {
                    billing_details: {
                        address: {
                            country: 'DE'
                        }
                    }
                  }
                },
              });
            }else {
              result = await stripe.confirmPayment({
                elements,
                confirmParams: {
                  return_url: redirectURL,
                  payment_method_data: {
                    billing_details: {
                        address: {
                            country: 'DE'
                        }
                    }
                  }
                },
              });
            }            
            this.showMessage(result.error.message);         
            this.setLoading(false);
          }
      }
    };

    // Fetches the payment intent status after payment submission
      checkStatus = async () => {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }
      const stripe = this.state.stripe;
      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

      switch (paymentIntent.status) {
        case "succeeded":
          this.showMessage("Payment succeeded!");
          break;
        case "processing":
          this.showMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          this.showMessage("Your payment was not successful, please try again.");
          break;
        default:
          this.showMessage("Something went wrong.");
          break;
      }
    };

  // ------- UI helpers -------

  showMessage = (messageText) => {
    const messageContainer = document.querySelector("#payment-message");
    messageContainer.classList.remove("hidden");
    messageContainer.textContent = messageText;
    setTimeout(function () {
      messageContainer.classList.add("hidden");
      messageContainer.textContent = "";
    },6000);
  };

  // Show a spinner on payment submission
  setLoading = (isLoading) => {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector("#submit").disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
    } else {
      document.querySelector("#submit").disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
    }
  };
  paymentClick = (event) => {
    let payment_type = 'Credit Card';
    if(event.value.type == 'iban') {
      payment_type = 'IBAN';
    } else if(event.value.type == 'card' || event?.target?.type == 'stripe' || event.value.type == 'stripe') { 
      payment_type = 'Credit Card';
    }
    localStorage.setItem('method',payment_type);
  }
  handlePlanSelection = (e) => {
    this.setState({subscription: e.target.id}, function () {
      //localStorage.removeItem("clientSecret");
      const MONTHLY_AMOUNT = this.state.price.monthly_plan;
      const ANNUAL_AMOUNT = this.state.price.quarterly_plan;
      let storeitem = JSON.parse(localStorage.getItem('userdata'));
      let billing_info = JSON.parse(localStorage.getItem('billing_info'));
      if(billing_info === null){
        billing_info = {};
        billing_info['postalcode_id'] = this.state.postalcode_id!=null?this.state.postalcode_id:null;
      }
      let planType;
      billing_info['organization'] = this.state.organization;
      billing_info['telephone'] = this.state.phone;
      billing_info['street'] = this.state.street;
      billing_info['house_number'] = this.state.house_number;
      billing_info['tax_id'] = this.state.tax_id;
      billing_info['first_name'] = this.state.first_name;
      billing_info['last_name'] = this.state.last_name;
      billing_info['payment_method'] =localStorage.getItem('method');
      billing_info['postalcode'] = this.state.postalcode;
      billing_info['country_code'] =this.state.country_code;
      if(this.state.subscription === "monthly"){
        planType = 0;
        billing_info['price'] = this.state.price.sub_monthly_price_with_tax;
        billing_info['time_period'] = PAYMENT_CONSTANTS[LANGUAGE_CODE]["sub_monthly"];
        document.querySelector('.plan_annual').classList.remove('plan-border');
        document.querySelector('.plan_monthly').classList.add('plan-border');
      }else if(this.state.subscription === "annual"){
        planType = 1;
        billing_info['price'] = this.state.price.sub_yearly_price_with_tax;
        billing_info['time_period'] = PAYMENT_CONSTANTS[LANGUAGE_CODE]["subscription_yearly"];
        document.querySelector('.plan_annual').classList.add('plan-border');
        document.querySelector('.plan_monthly').classList.remove('plan-border');
      }else {
        planType = 1;
        billing_info['price'] = this.state.price.sub_yearly_price_with_tax;
        billing_info['time_period'] = PAYMENT_CONSTANTS[LANGUAGE_CODE]["subscription_yearly"];
      }
      localStorage.setItem('billing_info',JSON.stringify(billing_info));
      storeitem['telephone'] = this.state.phone;
      storeitem['street'] = this.state.street;
      storeitem['house_number'] = this.state.house_number;
      localStorage.setItem("userdata",JSON.stringify(storeitem));

      localStorage.setItem("plan_type", planType );
      localStorage.setItem('price', billing_info['price']);
      this.handlePaymentIntent();
      this.handleClick();
    });  
  }
  handleSubscription = async () => {
    let storeitem = JSON.parse(localStorage.getItem('userdata'));
    const userId = storeitem.id;
    let apitoken = storeitem.token;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + apitoken
    };
     let plan_type = parseInt(localStorage.getItem("plan_type"));
     let url = new URL(window.location);
     let params = new URLSearchParams(url.search);
    if(storeitem){       
      if(params.get('redirect_status') == "succeeded"){
            try {   
                  let setup_intent_client_secret = params.get('setup_intent_client_secret') ;
                  let method_id = '';
                  const stripe = window.Stripe(process.env.REACT_APP_STRIPE_KEY,{locale:'de'});
                  await stripe.retrieveSetupIntent(setup_intent_client_secret).then(({setupIntent}) => {      
                    method_id = setupIntent.payment_method;
                  });
                  let billing_info = JSON.parse(localStorage.getItem('billing_info'));
                  let isTrialSubscription = 0;
              if(!storeitem.IsSubscriptioncancle && !storeitem.plan_end_date && storeitem.isPlanExpire !== 1) isTrialSubscription = 1;
                  let price =  billing_info.price.replace(',','.');
                  let start_date = localStorage.getItem('start_date');
                  let customer_id = localStorage.getItem("customer_id");
                  let trans_id = storeitem.transaction_id;
                  let transaction_status = storeitem.transaction_status;
                  let payment_method = storeitem.payment_method;
                  await axios.post(API_URL + '/payment/confirmsubscription/' + userId+ '?uId=' + userId, {first_name:billing_info.first_name, last_name:billing_info.last_name, postalcode_id:billing_info.postalcode_id,plan_type: plan_type, organization:billing_info.organization, telephone: storeitem.telephone,price:price, street: billing_info.street, house_number: billing_info.house_number, name: billing_info.first_name + " " + billing_info.last_name, email: storeitem.email, isSubscription: 1, IsSubscriptioncancle:storeitem.IsSubscriptioncancle, start_date:start_date,end_date:storeitem.sub_plan_end_date, isTrialSubscription: isTrialSubscription,payment_method_id:method_id,customer_id:customer_id,transaction_id:trans_id,transaction_status:transaction_status,payment_method:payment_method,tax_id: billing_info.tax_id && billing_info.tax_id.length > 0 ? billing_info.tax_id: null},
                    { headers: headers }).then((res)=>{
                      localStorage.setItem('payment_status',1);
                      storeitem['plan_type'] = plan_type.toString();
                      localStorage.setItem("userdata",JSON.stringify(storeitem));
                      localStorage.setItem('transaction_id',res.data.success.transaction_id);
                      localStorage.removeItem("customer_id");
                      let redirectURL = localStorage.getItem('redirectURL')!=null ? localStorage.getItem('redirectURL'): BASEURL + "mein-bereich";
                      window.location = redirectURL;
                    })
                  .catch((err)=> {
                      console.log(err);
                  });
              } catch (err) {
                console.log(err);
              }
        }
        if(params.get('redirect_status') == "failed"){
          localStorage.setItem('payment_status',0);
          localStorage.removeItem('price');
          localStorage.removeItem('plan_type');
          window.location="/university-checkout";
        }
     }
  }
  handlePaymentIntent = () => {
    this.initialize();
    this.checkStatus();
    document.querySelector("#payment-form");
    document.addEventListener("submit", this.handleSubmit);
  }

handlePopup = () =>{
  localStorage.setItem('IBAN',0);
  this.handleClick();
  document.querySelector("#annual").click();
  document.querySelector("#stripe").click();
}
  handlePaymentSelection = (e) => {
    const payment_method = { value: { type: e.target.id } }
    this.paymentClick(payment_method)
    if(e.target.id == 'iban') {
      localStorage.setItem('IBAN',1);
     // this.handlePaymentIntent();
     document.querySelector("#iban-msg").classList.remove("hidden");
      document.querySelector('.iban').classList.add('plan-border');
      document.querySelector('.stripe').classList.remove('plan-border');
      document.querySelector("#payment-element").classList.add("hidden");
    } else if(e.target.id == 'stripe'){
      //this.handlePaymentIntent();
      localStorage.setItem('IBAN',0);
      document.querySelector("#iban-msg").classList.add("hidden");
      document.querySelector('.stripe').classList.add('plan-border');
      document.querySelector('.iban').classList.remove('plan-border');
      document.querySelector("#payment-element").classList.remove("hidden");
    }
}
  handleClick = () => {
    const MONTHLY_AMOUNT = this.state.price.monthly_plan;
    const ANNUAL_AMOUNT = this.state.price.quarterly_plan;
    let storeitem = JSON.parse(localStorage.getItem('userdata'));
    let billing_info = JSON.parse(localStorage.getItem('billing_info'));
    let isTrialSubscription = 0;
    if(!storeitem.IsSubscriptioncancle && !storeitem.plan_end_date) isTrialSubscription = 1;
    if(billing_info === null){
      billing_info = {};
      billing_info['postalcode_id'] = this.state.postalcode_id!=null?this.state.postalcode_id:null;
      billing_info['price'] = this.state.price.sub_yearly_price_with_tax;
      billing_info['time_period'] = PAYMENT_CONSTANTS[LANGUAGE_CODE]["subscription_yearly"];
    }
    let planType;
    billing_info['organization'] = this.state.organization;
    billing_info['telephone'] = this.state.phone;
    billing_info['street'] = this.state.street;
    billing_info['house_number'] = this.state.house_number;
    billing_info['tax_id'] = this.state.tax_id;
    billing_info['first_name'] = this.state.first_name;
    billing_info['last_name'] = this.state.last_name;
    billing_info['payment_method'] =localStorage.getItem('method');
    billing_info['postalcode'] = this.state.postalcode;
    billing_info['country_code'] =this.state.country_code;
    if(this.state.subscription === "monthly"){
      planType = 0;
      billing_info['price'] = this.state.price.sub_monthly_price_with_tax;
      billing_info['time_period'] = PAYMENT_CONSTANTS[LANGUAGE_CODE]["sub_monthly"];
    }else if(this.state.subscription === "annual"){
      planType = 1;
      billing_info['price'] = this.state.price.sub_yearly_price_with_tax;
      billing_info['time_period'] = PAYMENT_CONSTANTS[LANGUAGE_CODE]["subscription_yearly"];
    }else {
      planType = 1;
      billing_info['price'] = this.state.price.sub_yearly_price_with_tax;
      billing_info['time_period'] = PAYMENT_CONSTANTS[LANGUAGE_CODE]["subscription_yearly"];
    }
    localStorage.setItem('billing_info',JSON.stringify(billing_info));
    storeitem['telephone'] = this.state.phone;
    storeitem['street'] = this.state.street;
    storeitem['house_number'] = this.state.house_number;
    localStorage.setItem("userdata",JSON.stringify(storeitem));

    localStorage.setItem("plan_type", planType );
    localStorage.setItem('price', billing_info['price']);

    let userData = JSON.parse(localStorage.getItem('userdata'));
    if(userData == null){
      return <Redirect to="/anmelden" />
    }
    let locationName = "";
    let locationData = this.state.postalcodedata;
    locationData = locationData.map((locationDataNew, index) => {
      locationData[index].postalcode_city = locationDataNew.postalcode_city.toString();
      return locationData[index];
    });
    if (this.state.postalcode !== undefined && this.state.postalcode !== null && this.state.postalcode !== "")
    {
      locationName = this.state.postalcode;
    }

    const button = { 
      background:"#4EB4F4",
      marginTop:"10px",
      cursor: "pointer",
      transition: "all 0.2s ease",
      boxShadow: "0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)",
      width: "100%"
    };
    const mprice = (this.state.price.sub_yearly_price_without_tax_decimal/12).toFixed(2).toString().replace(".",",");
    let displayMobile = {display:'block'};
    if(this.state.mobileDevice == 0) {
      displayMobile = {display:'flex'};
    } 
    //End 322
    confirmAlert({
      overlayClassName: "payment-popup",
      customUI: ({ onClose }) => {
        return (
          <div className='container custom-ui text-left position-relative share_popup'>
            <div className="close-btn text-right">
              <button className="d-inline-block bg-white border-0 w-auto sharing-close-button" onClick={onClose}><i class="ti-close ti-close-creditcard"></i></button>
            </div>
            <div className="pl-3" style={{'word-wrap': 'break-word',width: '96%'}}>
              <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin">
              Jetzt empirio Plus Mitglied werden
              </h3>
            </div>
            {/* <p className="lead">
            {storeitem.plan_end_date? PAYMENT_CONSTANTS[LANGUAGE_CODE]["amount_payable"] : PAYMENT_CONSTANTS[LANGUAGE_CODE]["trial_amount"]}:&nbsp;
              {this.state.subscription === "monthly" ?  this.state.price.sub_monthly_price_without_tax_decimal.replace('.',',') :
                  this.state.subscription === "annual" ?  this.state.price.sub_yearly_price_without_tax_decimal.replace('.',',')
                : null}€ zzgl. 19% Ust. = {this.state.subscription === "monthly" ?  this.state.price.sub_monthly_price_with_tax :
                this.state.subscription === "annual" ?  this.state.price.sub_yearly_price_with_tax
              : null}€
              <img id="payment-logo" src={IMAGE_BASEURL+"img/paypal_logo.png"} width="465px" height="53px" />
            </p>
            */}
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-lg-7">
                      <div className="row" style={{paddingBottom:'10px'}}>
                          {/**Desktop */}
                          <div className="col-lg-12 pr-2">
                            <div className="plan_annual" style={displayMobile}>
                                  <div className="col-lg-6">
                                    <label className="radio-container d-flex radio_color pt-3" style={{cursor:"pointer"}}>
                                      <span className='checkout_radiobutton_label'>
                                          <input
                                            type="radio"
                                            name="radio"
                                            style={{display:"none"}}
                                            id="annual"
                                            className="checkou_radiobutton"
                                            onClick={this.handlePlanSelection}
                                          />
                                          <svg width="15px" height="20px" viewBox="0 0 20 20">
                                              <circle cx="10" cy="10" r="9"></circle>
                                              <path d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                              <path d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                                          </svg>
                                        </span>
                                        <span role='button' className="text-nowrap font-16 mb-0 radio-button">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["subscription_yearly"]}</span>
                                        <span className="checkmark" />
                                    </label>
                                  </div>
                                  <div className="col-lg-6">
                                  <label for="annual" style={{cursor:"pointer",width:'100%'}} className="pt-2">
                                  <p className="font-18 mb-0" style={{fontWeight:600}}>{mprice}€ / Monat</p>
                                  <p className="font-12 mb-0">{this.state.price.sub_yearly_price_without_tax_decimal.toString().replace(".",",")}€ zzgl. 19% USt. jährlich</p>
                                  </label>
                                </div>
                              </div>
                          </div>
                      </div>
                      <div className="row">
                        {/**Desktop */}
                        <div className="col-lg-12 pr-2">
                        <div className="plan_monthly" style={displayMobile}>
                            <div className="col-lg-6">
                              <label className="radio-container d-flex radio_color pt-3" style={{cursor:"pointer"}}>
                                    <span className='checkout_radiobutton_label'>
                                      <input
                                        type="radio"
                                        name="radio"
                                        style={{display:"none"}}
                                        className="checkout_radiobutton"
                                        id="monthly"
                                        onClick={this.handlePlanSelection}
                                      />
                                    <svg width="15px" height="20px" viewBox="0 0 20 20">
                                        <circle cx="10" cy="10" r="9"></circle>
                                        <path d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                        <path d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                                    </svg>
                                    </span>
                                    <span className="text-nowrap font-16 mb-0 radio-button" role='button'>{PAYMENT_CONSTANTS[LANGUAGE_CODE]["sub_monthly"]}</span>
                                    <span className="checkmark" />
                              </label>
                            </div>
                            <div className="col-lg-6">
                                <label for="monthly" style={{cursor:"pointer",width:'100%'}} className="pt-2">
                                <p className="font-18 mb-0" style={{fontWeight:600}}>{this.state.price.sub_monthly_price_without_tax_decimal.toString().replace(".",",")}€ / Monat</p>
                                <p className="font-12 mb-0">{this.state.price.sub_monthly_price_without_tax_decimal.toString().replace(".",",")}€ zzgl. 19% USt. monatlich</p>
                                </label>
                            </div>
                          </div>
                        </div>             
                      </div>

                       <div className="row pt-5 pl-2">
                          <div className="col-md-12 p-2">
                              <div className="form-group hs-form-field focus">
                                <input
                                  type="text"
                                  name="organization"
                                  maxLength={50}
                                  className={
                                    this.state.organization === null ? ( this.state.empty_field_error ? "form-control hs-input field-highlight" : "form-control hs-input") : "form-control hs-input"
                                  }
                                  onChange={this.handleChange}
                                  value={this.state.organization? this.state.organization : null}
                                />
                                <label htmlFor="organization">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["organization"]}</label>
                                <span className='billing_info_error d-none ' style={{color:"red"}}>{this.state.organization ? null : (BILLING_ADDRESS_ERROR[LANGUAGE_CODE]['organization'])}</span>
                              </div>
                            </div>
                            <div className="col-md-6 p-2">
                              <div className="form-group hs-form-field focus">
                                <input
                                  type="text"
                                  name="first_name"
                                  maxLength={20}
                                  className={
                                    this.state.first_name === "" ? ( this.state.empty_field_error ? "form-control hs-input field-highlight" : "form-control hs-input") : "form-control hs-input"
                                  }
                                  onChange={this.handleChange}
                                  value={this.state.first_name? this.state.first_name :null}
                                />
                                <label htmlFor="first_name">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["nsu_user_first_name"]}</label>
                                <span className='billing_info_error d-none ' style={{color:"red"}}>{this.state.first_name ? null : (BILLING_ADDRESS_ERROR[LANGUAGE_CODE]['first_name'])}</span>
                              </div>
                            </div>
                            <div className="col-md-6 p-2">
                              <div className="form-group hs-form-field focus">
                                <input
                                  type="text"
                                  name="last_name"
                                  maxLength={20}
                                  className={
                                    this.state.last_name === "" ? ( this.state.empty_field_error ? "form-control hs-input field-highlight" : "form-control hs-input") : "form-control hs-input"
                                  }
                                  onChange={this.handleChange}
                                  value={this.state.last_name? this.state.last_name :null}
                                />
                              <label htmlFor="last_name">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["nsu_user_last_name"]}</label>
                                <span className='billing_info_error d-none' style={{color:'red'}}>{this.state.last_name ? null : (BILLING_ADDRESS_ERROR[LANGUAGE_CODE]['last_name'])}</span>
                              </div>
                            </div>
                            <div className="col-md-6 p-2">
                              <div className="form-group hs-form-field focus">
                                <input
                                  type="text"
                                  name="street"
                                  maxLength={44}
                                  className={
                                    this.state.street === "" ? ( this.state.empty_field_error ? "form-control hs-input field-highlight" : "form-control hs-input") : "form-control hs-input"
                                  }
                                  onChange={this.handleChange}
                                  value={this.state.street? this.state.street : null}
                                />
                                <label htmlFor="street">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["street"]}</label>
                                <span className='billing_info_error d-none' style={{color:'red'}}>{this.state.street ? null : (BILLING_ADDRESS_ERROR[LANGUAGE_CODE]['street'])}</span>
                              </div>
                            </div>
                            <div className="col-md-6 p-2">
                              <div className="form-group hs-form-field focus">
                                <input
                                  type="text"
                                  name="house_number"
                                  maxLength={5}
                                  className={
                                    this.state.house_number === "" ? ( this.state.empty_field_error ? "form-control hs-input field-highlight" : "form-control hs-input") : "form-control hs-input"
                                  }
                                  onChange={this.handleChange}
                                  value={this.state.house_number? this.state.house_number :null}
                                />
                                <label htmlFor="house_number">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["house_number"]}</label>
                                <span className='billing_info_error d-none' style={{color:'red'}}>{this.state.house_number ? null : (BILLING_ADDRESS_ERROR[LANGUAGE_CODE]['house_number'])}</span>
                              </div>
                            </div>
                            <div className="col-md-6 p-2">
                              <div className="form-group hs-form-field autoComplete-custom focus">
                              <label htmlFor="postalcode">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["postal_code"]}</label>
                                <Autocomplete
                                  value={locationName}
                                  inputProps={{
                                    id: "states-autocomplete1",
                                    className:
                                    this.state.postalcode === null ? ( this.state.empty_field_error ? "form-control hs-input field-highlight" : "form-control hs-input") : "form-control hs-input",
                                    name: "postalcode",
                                  }} //removed to allow german charactors onKeyPress: this.OnlyDigitsAllow,
                                  items={locationData}
                                  getItemValue={(item) => item.postalcode_city}
                                  shouldItemRender={ locationName ? (item) => item.postalcode_city : null }
                                  eslint-disable-next-line
                                  onChange={this.getPostalCode}
                                  // eslint-disable-next-line
                                  onSelect={this.getPostalCode}
                                  renderMenu={(children) => children.length ? (<div className="form-autocomplete-suggestion">{children}</div>) : (<div />) }
                                  renderItem={(item, isHighlighted) => (
                                    <div
                                      key={item.id}
                                      id={item.id}
                                      className={
                                        isHighlighted ||
                                          item.postalcode_city === locationName
                                          ? "form-autocomplete-suggestion-field custom-autocomplete-background"
                                          : "form-autocomplete-suggestion-field"
                                      }
                                    >
                                      {item.postalcode_city}
                                    </div>
                                  )}
                                />
                                <span className='billing_info_error d-none' style={{color:'red'}}>{this.state.postalcode ? this.state.postalCodeMatchFound ? null : (BILLING_ADDRESS_ERROR[LANGUAGE_CODE]['postal_code_match']) : (BILLING_ADDRESS_ERROR[LANGUAGE_CODE]['postal_code'])}</span>
                                <div>
                                  {this.state.a.filter((a) => a === "postalcode").length > 0
                                  ? (<span style={{color: "red"}}>{MYPROFILE_ERROR_MESSAGE[LANGUAGE_CODE]["location"]}</span>)
                                  : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 p-2">
                              <div className={`form-group hs-form-field ${((!this.state.postalcode && this.state.tax_id === "") || (this.state.postalcode.length == 0 && this.state.tax_id === "")) ? '' :'focus'}`}>
                                <input
                                  type="text"
                                  name="tax_id"
                                  disabled={!this.state.postalcode || this.state.postalcode.length == 0}
                                  className={
                                    (!this.state.postalcode || this.state.postalcode.length == 0) ? "form-control hs-input disable": this.state.tax_id !== "" ? ( this.state.taxidMatchNotFound ? "form-control hs-input field-highlight" : "form-control hs-input") : "form-control hs-input" 
                                  }
                                  onChange={this.handleChange}
                                  value={this.state.tax_id? this.state.tax_id :null}
                                />
                                <label htmlFor="tax_id">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["tax_id"]}</label>
                                <span className='billing_info_error d-none' style={{color:'red'}}>{!this.state.taxidMatchNotFound ? null : BILLING_ADDRESS_ERROR[LANGUAGE_CODE]['tax_id']}</span>
                              </div>
                            </div>
                       </div>

                    </div>

                    <div className="col-lg-5" style={{paddingLeft:'4%',paddingRight:'8px'}}>
                    <PaypalSubscription total={billing_info.price} isTaxIDValid = {this.taxIDValidation} goBack={this.props.history.goBack} first_name={this.state.first_name} last_name = {this.state.last_name} postalcode_id ={billing_info.postalcode_id} product_id={planType == 0 ? product_id_month : product_id_annual} IsSubscriptioncancle={storeitem.IsSubscriptioncancle} end_date={storeitem.sub_plan_end_date} planType={planType} isTrialSubscription={isTrialSubscription} /> 
                    {/* {this.state.subscription == "monthly" ?
                    <PaypalSubscription total={this.state.price.sub_monthly_price_with_tax} goBack={this.props.history.goBack} first_name={this.state.first_name} last_name = {this.state.last_name} postalcode_id ={billing_info.postalcode_id} product_id={product_id_month} IsSubscriptioncancle={storeitem.IsSubscriptioncancle} end_date={storeitem.sub_plan_end_date} planType={0} isTrialSubscription={isTrialSubscription} onClick={this.formValidate}/> : <PaypalSubscription total={this.state.price.sub_yearly_price_with_tax} goBack={this.props.history.goBack} first_name={this.state.first_name} last_name = {this.state.last_name} postalcode_id ={billing_info.postalcode_id} product_id={product_id_annual} IsSubscriptioncancle={storeitem.IsSubscriptioncancle} end_date={storeitem.sub_plan_end_date} planType={1} isTrialSubscription={isTrialSubscription} onClick={this.formValidate}/>} */}
                    <div className="hr-lines">oder</div>
                    <div style={{display:'flex'}}> 
                    <label className="radio-container d-flex radio_color p-2 mr-2 stripe">
                        <span className='checkout_radiobutton_label'>
                            <input
                              type="radio"
                              name="checkout-select"
                              style={{display:"none"}}
                              id="stripe"
                              className="checkout_radiobutton"
                              onClick={this.handlePaymentSelection}
                            />
                            <svg width="15px" height="20px" viewBox="0 0 20 20">
                                <circle cx="10" cy="10" r="9"></circle>
                                <path d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                <path d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                            </svg>
                          </span>
                          <span role='button' className="text-nowrap font-16 mb-0 radio-button">Kreditkarte</span>
                          <span className="checkmark" />
                      </label>

                      <label className="radio-container d-flex radio_color p-2 iban">
                        <span className='checkout_radiobutton_label'>
                            <input
                              type="radio"
                              name="checkout-select"
                              style={{display:"none"}}
                              id="iban"
                              className="checkout_radiobutton"
                              onClick={this.handlePaymentSelection}
                            />
                            <svg width="15px" height="20px" viewBox="0 0 20 20">
                                <circle cx="10" cy="10" r="9"></circle>
                                <path d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                <path d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                            </svg>
                          </span>
                          <span role='button' className="text-nowrap font-16 mb-0 radio-button">Überweisung</span>
                          <span className="checkmark" />
                      </label>
                    </div>
                    <div id="iban-msg">Sie erhalten zu Beginn des Rechnungszeitraumes eine Mail mit den Zahlungsdaten.</div>
                    <br/>
                    <StripePayment amount={storeitem.plan_type? billing_info.price : ''} iban={false} btn_text={!storeitem.plan_type && storeitem.isPlanExpire !== 1 ? "Kostenpflichtig abonnieren" :"BEZAHLEN"}></StripePayment>
                    <div class="font-14 pt-2">{!storeitem.plan_type && storeitem.isPlanExpire !== 1 ? "14 Tage kostenlos testen, danach "+billing_info.price+"€ "+String(billing_info.time_period).charAt(0).toLowerCase() + String(billing_info.time_period).slice(1) + '. Jederzeit kündbar.':''} </div>
                    </div>
                  </div>
            </div>


          </div>
        );
      }
    });
  };

  componentWillUnmount() {
    setTimeout(() => {
      window.setOgTag(
        META_FACEBOOK["og_title"],
        META_FACEBOOK["og_description"],
        META_FACEBOOK["og_image"],
        META_FACEBOOK["og_url"]
      );
      window.removeFaqItemtype();
      window.removeSchema();
    }, 100);
  }
  componentDidMount() {
    this.setState({mobileDevice: 0 });
    //this.setState({desktopDevice: 0 });
    if (window.innerWidth >= 320 && window.innerWidth <= 575) {
      //mobileDevice = {display:'none'};
      this.setState({mobileDevice: 1});
    }
    // } else {
    //   //desktopDevice =  {display:'none'};
    //   this.setState({desktopDevice: 1});
    // }
    this.getUserData();
    this.scrollUpFunction();
    this.setHomeSectionVar();
    this.getPrice()

    // Added paypal script tag
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_KEY}&components=buttons&currency=EUR&disable-funding=card,sofort,giropay,sepa&vault=true`;
    document.body.appendChild(script);

    //Add stripe script tag
    // const stripeScript = document.createElement("script");
    // stripeScript.src = 'https://js.stripe.com/v3/';
    // document.body.appendChild(stripeScript);
    this.handleSubscription();

    setTimeout(() => {
      var schemaObject = window.schemaObj(
        BREADCRUMB_SCHEMA["schema1"],
        BREADCRUMB_SCHEMA["main"]["schema2"],
        BREADCRUMB_SCHEMA["main"]["schema3"]
      );
      window.setSchema(schemaObject);
    }, 100);
  }
  getPrice(){
    const storeitem = JSON.parse(localStorage.getItem('userdata'));
  if(storeitem){
    const userId = storeitem.id;
    let apitoken = storeitem.token;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + apitoken
    };
    const res = axios.get(API_URL + '/common/getPricesForPlan/' +userId+ '?uId=' + userId, {header: headers}).then((res) => {
      this.setState({price : res.data.success.priceforplan})
    })
  }
  }
  getUserData = () => {
    const userdata = JSON.parse(localStorage.getItem('userdata'));
    const university = JSON.parse(localStorage.getItem('dropdownList'))
    const university_name = university?.university.filter((item) => userdata.university == item.id)
    const billing_info = JSON.parse(localStorage.getItem('billing_info'));
    {
      if(userdata){
        this.setState({
          postalcode: null,
          postalcode_id: null,
          first_name: userdata.first_name,
          last_name: userdata.last_name,
          plan_type: userdata.plan_type,
          organization: university_name[0]?.university_name,
        });
      }
      if(billing_info){
        if(billing_info.price == this.state.price.sub_monthly_price_with_tax){
          this.setState({subscription : "monthly"})
        }else if(billing_info.price == this.state.price.sub_yearly_price_with_tax){
          this.setState({subscription : "annual"})
        }
        this.setState({
          postalcode: billing_info.postalcode,
          first_name: billing_info.first_name,
          last_name: billing_info.last_name,
          plan_type: billing_info.plan_type,
          street: billing_info.street,
          house_number: billing_info.house_number,
          tax_id: billing_info.tax_id,
          organization: billing_info.organization,
          country_code: billing_info.country_code,
        });
      }
    }
  };

  scrollUpFunction = () => {
    /**for scrolling up page */
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  render() {
    const MONTHLY_AMOUNT = this.state.price.monthly_plan;
    const ANNUAL_AMOUNT = this.state.price.quarterly_plan;
    let userData = JSON.parse(localStorage.getItem('userdata'));
    if(userData == null){
      return <Redirect to="/anmelden" />
    }
    // let locationName = "";
    // let locationData = this.state.postalcodedata;
    // locationData = locationData.map((locationDataNew, index) => {
    //   locationData[index].postalcode_city = locationDataNew.postalcode_city.toString();
    //   return locationData[index];
    // });
    // if (this.state.postalcode !== undefined && this.state.postalcode !== null && this.state.postalcode !== "")
    // {
    //   locationName = this.state.postalcode;
    // }
    return (
      <>
        {/* header  */}
        <Header goBack={this.props.history.goBack} />
        <main >
          {this.state.loading === "true" ? BUBBLE_LOADING : null}
          <div>
            {/*   Home Banner  */}
            <section
              ref={this.headerRef}
              id="home"
              className="home-section home-banner home-banner-changed nsu-home-banner-changed theme-main-changed theme-banner mb-0"
              style={{background: "#2d3e50"}}
            >
              <div className="home-effect-bg home-effect-bg-img d-md-block" style={{"--main-img" : this.mainImageBg}}/>
              <div className="container container-large">
                <div className="row p-100px-tb p-100px-tb-changed sm-p-30px-b pb-0">
                  <div
                    className={
                      window.innerWidth >= 1200
                        ? "col-12 col-md-12 col-lg-3 col-xl-3 md-m-30px-t col-lg-banner-text text-center em_left"
                        : "col-12 col-md-12 col-lg-3 col-xl-3 md-m-30px-t text-right em_left"
                    }
                  >
                    <img
                      src={IMAGE_BASEURL + "img/checkout_header.svg"}
                      title="img"
                      id="payment-img"
                    />
                  </div>
                  <div className="col-12 col-lg-6 col-xl-6 text-center em_center em-center-padding">
                    <h1 className="text-white font-weight-bold mb-0 heading-padding">
                      {PAYMENT_CONSTANTS[LANGUAGE_CODE]["nsu_heading"]}
                    </h1>
                    {(userData.plan_end_date|| userData.isPlanExpire == 1) ? 
                      <h4 className="text-white my-4">{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["university_heading"]}</h4>
                      : <h4 className="text-white my-4">{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["trial_heading"]}</h4>
                    }
                    <div className="payment-home-section col-xl-9 mb-5 mx-auto p-3 p-md-5 box-shadow bg-white">
                      <div className="border-0 w-100">
                        <h5 className="card-title text-center font-weight-bold text-dark">{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["card_heading"]}</h5>
                        <ul className="paket-list p-2">
                          <li className="text-gray text-survey d-flex text-left">
                            <span className="fa fa-check mt-1 tick" />
                            <div className="mb-0">
                              <h6 className="text-dark Bullet_point_heading">{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["card_bullet_points1"]}</h6>
                              <p className="text-dark mb-2 Bullet_point_sub_text">{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["card_bullet_points_subheading1"]}</p>
                            </div>
                          </li>
                          <li className="text-gray text-survey d-flex text-left">
                            <span className="fa fa-check mt-1 tick" />
                            <div className="mb-0">
                              <h6 className="text-dark Bullet_point_heading">{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["card_bullet_points2"]}</h6>
                              <p className="text-dark mb-2 Bullet_point_sub_text">{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["card_bullet_points_subheading2"]}</p>
                            </div>
                          </li>
                          {/* <li className="text-gray text-survey d-flex text-left">
                            <span className="fa fa-check mt-1 tick" />
                            <div className="mb-0">
                              <h6 className="text-dark Bullet_point_heading">{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["card_bullet_points3"]}</h6>
                              <p className="text-dark mb-2 Bullet_point_sub_text">{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["card_bullet_points_subheading3"]}</p>
                            </div>
                          </li> */}
                          <li className="text-gray text-survey d-flex text-left">
                            <span className="fa fa-check mt-1 tick" />
                            <div className="mb-0">
                              <h6 className="text-dark Bullet_point_heading">{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["card_bullet_points4"]}</h6>
                              <p className="text-dark mb-2 Bullet_point_sub_text">{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["card_bullet_points_subheading4"]}</p>
                            </div>
                          </li>
                          <li className="text-gray text-survey d-flex text-left">
                            <span className="fa fa-check mt-1 tick" />
                            <div className="mb-0">
                              <h6 className="text-dark Bullet_point_heading">{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["card_bullet_points5"]}</h6>
                              <p className="text-dark mb-2 Bullet_point_sub_text">{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["card_bullet_points_subheading5"]}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <button
                        // onClick={() => { this.package.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); }}
                        onClick={this.handlePopup}
                        className="btn btn-clr text-white font-18 p-"
                        style={{ whiteSpace: "normal"}}
                        title={PAYMENT_CONSTANTS[LANGUAGE_CODE]["btn_text"]}
                      >
                        {PAYMENT_CONSTANTS[LANGUAGE_CODE]["btn_text"]}
                      </button>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-md-row justify-content-between align-items-center w-100 mb-5 mt-md-5" style={{ order: '3' }}>
                    <div className="text-white font-20 font-weight-bold my-2">
                      <i className="ti-lock"></i>
                      <p className="px-2">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["secure_payment"]}</p>
                    </div>
                    <div className="text-white font-20 font-weight-bold my-2">
                      <i className="ti-lock"></i>
                      <p className="px-2">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["ssl_encrypted"]}</p>
                    </div>
                    <div className="text-white font-20 font-weight-bold my-2">
                      <i className="ti-lock"></i>
                      <p className="px-2">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["compliant"]}</p>
                    </div>
                  </div>
                </div>
                {/*   row    */}
              </div>
            </section>
            <section id="example" className="content-left-bg-img home-section business-mr">
              <div className="container">
                <div className="row align-items-center screen-height-check-business sm-p-30px-b pt-0">
                  <div className="col-12 " >
                    <div className="row">
                      <div className="col-md-4">
                        <img
                          src={IMAGE_BASEURL + "img/checkout_university.png "}
                          className="img-fluid example-img "
                          id="example-img"
                        />
                      </div>
                      <div className="col-md-8">
                        <div className="section-title">
                          <h2 className="font-weight-bold text-dark" style={{padding: "0px 15px"}}>{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["university_section1_heading"]}</h2>
                        </div>
                        <p className="text-dark" style={{padding: "0px 15px"}}>
                        {CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["university_section1_text1"]}
                        </p>
                        <p className="text-dark" style={{padding: "0px 15px"}}>
                        {CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["university_section1_text2"]}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                  <div ref={this.package}></div>
              </div>
            </section>

            <section  className="bg-white info testimonial-section" >
            <div className="container">
            <div className="section-title text-center">
                  <h2 className="font-weight-bold text-dark">{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["testimonial_heading"]}</h2>
                </div>
              <div className="row">
                <div className="col-md-12">
                      <div className="owl-stage d-flex payment-flex justify-content-center" >
                      <div class="owl-item cloned">
                        <div class="testimonial testimonial-width">
                          <div className="testimonial-content">
                            <p  dangerouslySetInnerHTML={{__html : CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]['testimonial_text']}}></p>
                          </div>
                          <div className="testimonial-info">
                            <div className="avtar"><img src={IMAGE_BASEURL + "img/referenz_pruefer.jpg"} /></div>
                            <div className="name">
                              <span>{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["testimonial_sub_text"]}</span>
                              <p dangerouslySetInnerHTML={{__html : CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]['testimonial_sub_text1']}}></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="owl-item cloned">
                        <div class="testimonial testimonial-width">
                          <div className="testimonial-content">
                            <p>{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["testimonial_text1"]}</p>
                          </div>
                          <div className="testimonial-info">
                            <div className="avtar"><img src={IMAGE_BASEURL + "img/referenz_lindschau.jpg"} /></div>
                            <div className="name">
                              <span>{CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]["testimonial_sub_text2"]}</span>
                              <p dangerouslySetInnerHTML={{__html : CHECKOUT_SUBSCRIPTION[LANGUAGE_CODE]['testimonial_sub_text3']}}></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
              </div>
            </div></div>
            </section>
            {/* packages  */}
            <section ref={this.package} className="bg-white" id='package'>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="row py-5">
                      <div className="col-12 d-flex align-items-center justify-content-center">
                        <button onClick={this.handlePopup} className="btn btn-clr text-white font-18 p-3 " title= {PAYMENT_CONSTANTS[LANGUAGE_CODE]["btn_text"]}>{PAYMENT_CONSTANTS[LANGUAGE_CODE]["btn_text"] }</button>
                      </div>
                      <p className="p-2 w-100 text-center "><i className="ti-lock pr-2"></i>{PAYMENT_CONSTANTS[LANGUAGE_CODE]["secure_booking"]}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </>
    );
  }
}

export default CheckoutUniversity;
