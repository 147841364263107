import React from 'react'
import { chartOptionTextStyle, chartAnimation, SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT, EDIT_SURVEY_CONSTANT, URL_CONSTANT, MULTIPLE_SELECTION_QUESTION_CHART_SUBTITLE } from '../../../constants/FrontEndConstant';
import * as htmlToImage from 'html-to-image';
import { LANGUAGE_CODE, API_URL, BASEURL, IMAGE_BASEURL, MESSAGE_TIME, META_TITLE_APPEND } from '../../../constants/DotEnv';
import { confirmAlert } from 'react-confirm-alert';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import {
   EmailIcon,
   EmailShareButton,
   FacebookIcon,
   FacebookShareButton,
   LinkedinShareButton,
   LinkedinIcon,
   TwitterIcon,
   TwitterShareButton,
   WhatsappShareButton,
   WhatsappIcon } from "react-share";
// import { saveEditedImage } from './CreateChartImage'
export default class App extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         data: [],
         options: {},
         chart_data: '',
         registerRedirect : false,
         copyMessage: "",
         errorMessage: false
      };
      let script_tag = document.createElement('script');
      script_tag.src = "https://www.gstatic.com/charts/loader.js";
      script_tag.id = "chart_script";
      script_tag.type = "text/javascript";
      if (!document.getElementById("chart_script")) {
         document.body.append(script_tag);
      }
      this.ImagetoSend = [];
      this.image_meta = {};
   }
   componentDidMount() {
      setTimeout(() => {
         window.google.charts.load("50", { packages: ['corechart'] });
         window.google.charts.setOnLoadCallback(this.drawChart());
         window.google.charts.setOnLoadCallback(this.drawDemographicChart());
      }, 1000);
      // setInterval(() => {
      //    this.forceUpdate();
      // }, 20000);
   }
   // Payment Reduce and getSurveyResult call Api 
   makePaymentshowGraph = async () =>{
      let storeitem = JSON.parse(localStorage.getItem('userdata'));
      let surveydata = this.props.surveydata;
      let surveyId = surveydata.id;
      let userid = '';
      let apitoken = '';
      if (storeitem !== null) {
         apitoken = storeitem.token;
         userid = storeitem.id;
      }
      var headers = {
         "Content-Type": "application/json",
         "Authorization": "Bearer " + apitoken
      } 
      // eslint-disable-next-line
      await axios.get(API_URL + '/surveyresult/MakePaymentForShowGraph/' + LANGUAGE_CODE + '/' + surveyId + '?uId=' + userid, { headers: headers })
         .then((response) => {
            if (response && response.data && response.data.success) {
               this.setState({ unlockSurvey : response.data.success })
            }
            if (response.data.error === true && response.data.message === 'Unzureichendes Karma-Punkteguthaben für die Freischaltumfrage') {
               this.setState({errorMessage : response.data.error})
            }
         })
         .catch((error) => {
            if (error && error.response && error.response.data) {
               let msg = error.response.data['error']
               if (msg === 'Expired token' || msg === 'Invalid token') {
                  localStorage.clear();
                  this.setState({ redirect: true })
               }
               if (msg === 'Invalid User') {
                  this.setState({ redirectToNotFound: true })
               }
            }
         })
      if(this.state.unlockSurvey === true) {
         await this.props.getSurveyResult();
      }
      if (this.state.errorMessage === true) {
         this.errorMessageShow();
      }
   }
   // Error message display in popup for Insufficient balance
   errorMessageShow () {
      confirmAlert({
         customUI: ({ onClose }) => {
            return (
               <div className='custom-ui'>
                  <div className="close-btn text-right">
                     <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                  </div>
                  <p>{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['error_unlock_survey_result']}</p> 
                  {/* error_unlock_survey */}
               </div>
            )
         }
      })
   }
   //  Normal Question chart title Display
   createChartDiv() {
      let chart_divs = [];
      let child_chart_divs = [];
      let changeWidth;
      let questionTitle = '';
      let isPayment = this.props.payment;
      let isOwner = 0;
      let storeitem = JSON.parse(localStorage.getItem('userdata'));
      if (storeitem && storeitem.id && this.props.surveydata.user_id === storeitem.id) {
         isOwner = 1;
      }
      var hideSnippet = false
      // eslint-disable-next-line
      this.props.chartData.questiondata.map((chart, index) => {
         child_chart_divs = [];
         changeWidth = 100 + "%";
         const backImage = (window.innerWidth < 767) ? IMAGE_BASEURL + "img/empirioDiagramMobile.svg" : IMAGE_BASEURL + "img/empirioDiagramm.svg";
         // payment is done then all chart are visible
         // eslint-disable-next-line
         if (chart.hasOwnProperty("question_type_id") && chart.question_type_id === 5 || chart.question_type_id === 7) {
            let keyArray = Object.keys(chart);
            questionTitle = chart.question_title ? chart.question_title : '';
            // eslint-disable-next-line
            keyArray.map((objKey, obj_index) => {
               // eslint-disable-next-line
               if (objKey.substring(0, 4) === "data") {
                  if (isPayment === 1 || isOwner || index < 2) {
                     child_chart_divs.push(
                        <React.Fragment>
                           <div style={{ "height": "400px", "width": changeWidth }} className="pl-3 pl-sm-0" id={"chart_div_opt_" + objKey + "_" + index}>
                              <div className="d-flex align-items-center justify-content-center" style={{"height" : "400px"}}>
                                 <i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i>
                              </div>
                           </div>
                           <div className="d-flex chart-bottom justify-content-between align-items-center">{chart.user_participated ? <span className="user-count"><i class="ti-user"></i>{chart.user_participated}</span> : <span className="user-count"><i class="ti-user"></i>0</span>}
                              <div className="text-right" id={"share_chart_div_opt_" + objKey + "_" + index}>
                                 <button className="border-0 bg-transparent font-weight-bold pointer" id={"share_chart_div_opt_normal_share_" + obj_index + "_" + index} onClick={e =>this.shareSurvey(e,hideSnippet)}><i className="ti-share text-dark pr-2"></i>{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_icon_text']}</button>
                              </div>
                           </div>
                        </React.Fragment>)
                  } else {
                     child_chart_divs.push(
                        <React.Fragment>
                           <div className={(this.props.hideChart) ? "d-none" : ""}>
                              <div className={(this.props.hideChart) ? "remove-chart-div" : ""} style={{ "height": "400px", "width": changeWidth }} id={"chart_div_opt_" + objKey + "_" + index} >
                                 <div className="d-flex align-items-center justify-content-center" style={{"height" : "400px"}}>
                                    <i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i>
                                 </div>
                              </div>
                              <div className="d-flex chart-bottom justify-content-between align-items-center">{chart.user_participated ? <span className="user-count"><i class="ti-user"></i>{chart.user_participated}</span> : <span className="user-count"><i class="ti-user"></i>0</span>}
                                 <div className="text-right" id={"share_chart_div_opt_" + objKey + "_" + index}>
                                    <button className="border-0 bg-transparent font-weight-bold pointer" id={"share_chart_div_opt_normal_share_" + obj_index + "_" + index} onClick={e =>this.shareSurvey(e,hideSnippet)}><i className="ti-share text-dark pr-2"></i>{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_icon_text']}</button>
                                 </div>
                              </div>
                              {!this.props.hideChart ?
                                 <div class="bubble-loader chart-main-block mx-0 mx-md-3 bg-white box-shadow">
                                    <i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i>
                                 </div>
                              : null}
                           </div>
                           <div className={(this.props.hideChart) ? "col-12 px-0 px-md-3" : "col-12 px-0 px-md-3 d-none"}>
                              <div className="bg-white box-shadow chart-main-block">
                                 <div className="ranking_main">
                                    <img className="ranking_dummy_img" src={ backImage } alt="img" />
                                    <div className="publish-surveys text-center">
                                       <button type="button" onClick={(e)  => this.unlockSurvey()} className="btn btn-clr text-white text-uppercase font-18 disable custom-disable shadow-result-button" title={SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['show_results'] }>{ SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['show_results'] }</button>
                                    </div>
                                 </div>
                                 <div className="d-flex chart-bottom justify-content-between align-items-center">{chart.user_participated ? <span className="user-count"><i class="ti-user"></i>{chart.user_participated}</span> : <span className="user-count"><i class="ti-user"></i>0</span>}
                                    <div class="text-right" id={"share_chart_div_opt_" + index}>
                                       <button class="border-0 bg-transparent font-weight-bold pointer" id={"share_chart_div_opt_normal_share_dummy_" + obj_index + "_" + index} onClick={e =>this.shareSurvey(e,hideSnippet = true)}><i class="ti-share text-dark pr-2"></i>{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_icon_text']}</button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </React.Fragment>)
                  }
               }
            });
            if (isPayment === 1 || isOwner || index < 2) {
               chart_divs.push(
                  <div className="col-12 px-0 px-md-3">
                     <div className="bg-white box-shadow chart-main-block">
                        <div className="table-content bg-white">
                           <h3 className="mb-0 text-dark font-weight-bold text-break">{questionTitle}</h3>
                        </div>
                        {child_chart_divs}
                     </div>
                  </div>)
            } else {
               chart_divs.push(
                  <React.Fragment>
                     <div className={(this.props.hideChart) ? "col-12 px-0 px-md-3 d-none" :"col-12 px-0 px-md-3"}>
                        <div className="bg-white box-shadow chart-main-block">
                           <div className="table-content bg-white">
                              <h3 className="mb-0 text-dark font-weight-bold text-break">{questionTitle}</h3>
                           </div>
                           {child_chart_divs}
                        </div>
                     </div>
                     <div className={(this.props.hideChart) ? "col-12 px-0 px-md-3" : "col-12 px-0 px-md-3 d-none"}>
                        <div className="bg-white box-shadow chart-main-block">
                           <div className="table-content bg-white">
                              <h3 className="mb-0 text-dark font-weight-bold text-break">{questionTitle}</h3>
                           </div>
                           <div className="ranking_main">
                              <img className="ranking_dummy_img" src={ backImage } alt="img" />
                              <div className="publish-surveys text-center">
                                 <button type="button" onClick={(e)  => this.unlockSurvey()} className="btn btn-clr text-white text-uppercase font-18 disable custom-disable shadow-result-button" title={SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['show_results'] }>{ SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['show_results'] }</button>
                              </div>
                           </div>
                           <div className="d-flex chart-bottom justify-content-between align-items-center">{chart.user_participated ? <span className="user-count"><i class="ti-user"></i>{chart.user_participated}</span> : <span className="user-count"><i class="ti-user"></i>0</span>}
                              <div class="text-right" id={"share_chart_div_opt_" + index}>
                                 <button class="border-0 bg-transparent font-weight-bold pointer" id={"share_chart_div_opt_normal_share_" + index } onClick={e =>this.shareSurvey(e,hideSnippet=true)}><i class="ti-share text-dark pr-2"></i>{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_icon_text']}</button>
                              </div>
                           </div>
                           {!this.props.hideChart ?
                              <div class="bubble-loader chart-main-block mx-0 mx-md-3 bg-white box-shadow">
                                 <i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i>
                              </div>
                           : null}
                        </div>
                     </div>
                  </React.Fragment>)
            }
         }
         else {
            questionTitle = chart.options && chart.options.title ? chart.options.title : '' ;
            if (isPayment === 1 || isOwner || index < 2) {
               return chart_divs.push(
                  <div className="col-12 px-0 px-md-3">
                     <div className="bg-white box-shadow chart-main-block">
                        <div className="table-content bg-white">
                           <h3 className="mb-0 text-dark font-weight-bold text-break">{questionTitle}</h3>
                        </div>
                        { chart.question_type_id && chart.question_type_id === 4 ?
                           <div className="freetext-subtitle pt-1" name="hidden-tag-chart">
                              <h3 className="pl-3 pb-0 font-14 lh-24 fw-400 survey-subtitle bg-display-none multiple-select-padding">{MULTIPLE_SELECTION_QUESTION_CHART_SUBTITLE[LANGUAGE_CODE]['subtitle']}</h3>
                           </div>
                           : null
                        }
                        <div className="hide-chart-title pl-3 pl-sm-0" style={{ "height": "400px", "width": changeWidth }} id={"chart_div_" + index}>
                           <div className="d-flex align-items-center justify-content-center" style={{ "height": "400px" }} >
                              <i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i>
                           </div>
                        </div>
                        <div className="d-flex chart-bottom justify-content-between align-items-center">{chart.user_participated ? <span className="user-count"><i class="ti-user"></i>{chart.user_participated}</span> : <span className="user-count"><i class="ti-user"></i>0</span>}
                           <div className="text-right" id={"share_chart_div_opt_" + index}>
                              <button className="border-0 bg-transparent font-weight-bold pointer" id={"share_chart_div_opt_normal_share_" + index} onClick={e =>this.shareSurvey(e,hideSnippet)}><i className="ti-share text-dark pr-2"></i>{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_icon_text']}</button>
                           </div>
                        </div>
                     </div>
                  </div>)
            } else {
               return chart_divs.push(
                  <React.Fragment>
                     <div className={(this.props.hideChart) ? "col-12 px-0 px-md-3 d-none" : "col-12 px-0 px-md-3"}>
                        <div className="bg-white box-shadow chart-main-block">
                           <div className="table-content bg-white">
                              <h3 className="mb-0 text-dark font-weight-bold text-break">{questionTitle}</h3>
                           </div>
                           { chart.question_type_id && chart.question_type_id === 4 ?
                              <div className="freetext-subtitle pt-1" name="hidden-tag-chart">
                                 <h3 className="pl-3 pb-0 font-14 lh-24 fw-400 survey-subtitle bg-display-none multiple-select-padding">{MULTIPLE_SELECTION_QUESTION_CHART_SUBTITLE[LANGUAGE_CODE]['subtitle']}</h3>
                              </div>
                              : null
                           }
                           <div className={(this.props.hideChart) ? "hide-chart-title pl-3 pl-sm-0 remove-chart-div" : "hide-chart-title pl-3 pl-sm-0"} style={{ "height": "400px", "width": changeWidth }} id={"chart_div_" + index}>
                              <div className="d-flex align-items-center justify-content-center" style={{ "height": "400px" }} >
                                 <i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i>
                              </div>
                           </div>
                           <div className="d-flex chart-bottom justify-content-between align-items-center">{chart.user_participated ? <span className="user-count"><i class="ti-user"></i>{chart.user_participated}</span> : <span className="user-count"><i class="ti-user"></i>0</span>}
                              <div className="text-right" id={"share_chart_div_opt_" + index}>
                                 <button className="border-0 bg-transparent font-weight-bold pointer" id={"share_chart_div_opt_normal_share_" + index} onClick={e =>this.shareSurvey(e,hideSnippet)}><i className="ti-share text-dark pr-2"></i>{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_icon_text']}</button>
                              </div>
                           </div>
                           {!this.props.hideChart ?
                              <div class="bubble-loader chart-main-block mx-0 mx-md-3 bg-white box-shadow">
                                 <i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i>
                              </div>
                           : null}
                        </div>
                     </div>
                     <div className={(this.props.hideChart) ? "col-12 px-0 px-md-3" : "col-12 px-0 px-md-3 d-none"}>
                        <div className="bg-white box-shadow chart-main-block">
                           <div className="table-content bg-white">
                              <h3 className="mb-0 text-dark font-weight-bold text-break">{questionTitle}</h3>
                           </div>
                           { chart.question_type_id && chart.question_type_id === 4 ?
                              <div className="freetext-subtitle pt-1" name="hidden-tag-chart">
                                 <h3 className="pl-3 pb-0 font-14 lh-24 fw-400 survey-subtitle bg-display-none multiple-select-padding">{MULTIPLE_SELECTION_QUESTION_CHART_SUBTITLE[LANGUAGE_CODE]['subtitle']}</h3>
                              </div>
                              : null
                           }
                           <div className="ranking_main">
                              <img className="ranking_dummy_img" src={ backImage } alt="img" />
                              <div className="publish-surveys text-center">
                                 <button type="button" onClick={(e)  => this.unlockSurvey()} className="btn btn-clr text-white text-uppercase font-18 disable custom-disable shadow-result-button" title={SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['show_results'] }>{ SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['show_results'] }</button>
                              </div>
                           </div>
                           <div className="d-flex chart-bottom justify-content-between align-items-center">{chart.user_participated ? <span className="user-count"><i class="ti-user"></i>{chart.user_participated}</span> : <span className="user-count"><i class="ti-user"></i>0</span>}
                              <div class="text-right" id={"share_chart_div_opt_" + index}>
                                 <button class="border-0 bg-transparent font-weight-bold pointer" id={"share_chart_div_opt_normal_share_dummy_" + index} onClick={e =>this.shareSurvey(e,hideSnippet=true)}><i class="ti-share text-dark pr-2"></i>{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_icon_text']}</button>
                              </div>
                           </div>
                           {!this.props.hideChart ?
                              <div class="bubble-loader chart-main-block mx-0 mx-md-3 bg-white box-shadow">
                                 <i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i>
                              </div>
                           : null}
                        </div>
                     </div>
                  </React.Fragment>)
            }
         }
      })
      return chart_divs;
   }
   // Demographic Question chart divDisplay
   createDemographicChartDiv() {
      let chart_Divs = [];
      let questionTitle = '';
      let changeWidth;
      let isPayment = this.props.payment;
      let isOwner = 0;
      let storeitem = JSON.parse(localStorage.getItem('userdata'));
      if (storeitem && storeitem.id && this.props.surveydata.user_id === storeitem.id) {
         isOwner = 1;
      }
      // demograpghic Question chart div display
      // eslint-disable-next-line
      var hideSnippet=false
      // eslint-disable-next-line
      this.props.chartData.demographic.map((chart, index) => {
         if (chart.options) {
            if (chart.options.chart_type && chart.options.chart_type.toLowerCase() === "piechart") {
               if (window.innerWidth >= 320 && window.innerWidth <= 575) {
                  let chartArea = { 'left': 15, 'right': 15, 'width': '100%', 'height': '80%' };
                  this.props.chartData.demographic[index].options['chartArea'] = chartArea;
               }
               if (window.innerWidth >= 320 && window.innerWidth <= 400) {
                  changeWidth = (window.innerWidth - 30) + "px";
               }
               else {
                  changeWidth = 100 + "%";
               }
            }
         }
         if (chart.hasOwnProperty("question_type_id") && (chart.question_type_id === 5 || chart.question_type_id === 7)) {
         }
         else {
            questionTitle = chart.options.title;
            if (isPayment === 1 || isOwner) {
               return chart_Divs.push(
                  <div className="col-12 px-0 px-md-3">
                     <div className="bg-white box-shadow chart-main-block">
                        <div className="table-content bg-white">
                           <h3 className="mb-0 text-dark font-weight-bold text-break">{questionTitle}</h3>
                        </div>
                        <div className= "hide-chart-title pl-3 pl-sm-0" style={{ "height": "400px", "width": changeWidth }} id={"chart_div_demographic_" + index}>
                           <div className="d-flex align-items-center justify-content-center" style={{"height" : "400px"}}>
                              <i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i>
                           </div>
                        </div>
                        <div className="d-flex chart-bottom justify-content-between align-items-center">{chart.user_participated ? <span className="user-count"><i class="ti-user"></i>{chart.user_participated}</span> : <span className="user-count"><i class="ti-user"></i>0</span>}
                           <div className="text-right" id={"share_chart_div_opt_" + index}>
                              <button className="border-0 bg-transparent font-weight-bold pointer" id={"share_chart_div_opt_demographic_share_" + index} onClick={e =>this.shareSurvey(e,hideSnippet)}><i className="ti-share text-dark pr-2"></i>{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_icon_text']}</button>
                           </div>
                        </div>
                     </div>
                  </div>)
            }
            else {
               // Dummy image display.
               const backImage = (window.innerWidth < 767) ? IMAGE_BASEURL + "img/empirioDiagramMobile.svg" : IMAGE_BASEURL + "img/empirioDiagramm.svg";
               chart_Divs.push(
                  <React.Fragment>
                     <div className={(this.props.hideChart) ? "col-12 px-0 px-md-3 d-none" :"col-12 px-0 px-md-3"}>
                        <div className="bg-white box-shadow chart-main-block">
                           <div className="table-content bg-white">
                              <h3 className="mb-0 text-dark font-weight-bold text-break">{questionTitle}</h3>
                           </div>
                           <div className={(this.props.hideChart) ? "hide-chart-title pl-3 pl-sm-0 remove-chart-div" : "hide-chart-title pl-3 pl-sm-0"} style={{ "height": "400px", "width": changeWidth }} id={"chart_div_demographic_" + index}>
                              <div className="d-flex align-items-center justify-content-center" style={{"height" : "400px"}}>
                                 <i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i>
                              </div>
                           </div>
                           <div className="d-flex chart-bottom justify-content-between align-items-center">{chart.user_participated ? <span className="user-count"><i class="ti-user"></i>{chart.user_participated}</span> : <span className="user-count"><i class="ti-user"></i>0</span>}
                              <div className="text-right" id={"share_chart_div_opt_" + index}>
                                 <button className="border-0 bg-transparent font-weight-bold pointer" id={"share_chart_div_opt_demographic_share_dummy_" + index} onClick={e =>this.shareSurvey(e,hideSnippet)}><i className="ti-share text-dark pr-2"></i>{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_icon_text']}</button>
                              </div>
                           </div>
                           {!this.props.hideChart ?
                              <div class="bubble-loader chart-main-block mx-0 mx-md-3 bg-white box-shadow">
                                 <i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i>
                              </div>
                           : null}
                        </div>
                     </div>
                     <div className={(this.props.hideChart) ? "col-12 px-0 px-md-3" : "col-12 px-0 px-md-3 d-none"}>
                        <div className="bg-white box-shadow chart-main-block">
                           <div className="table-content bg-white">
                              <h3 className="mb-0 text-dark font-weight-bold text-break">{questionTitle}</h3>
                           </div>
                           <div className="ranking_main">
                              <img className="ranking_dummy_img" src={ backImage } alt="img" />
                              <div className="publish-surveys text-center">
                                 <button type="button" onClick={(e)  => this.unlockSurvey()} className="btn btn-clr text-white text-uppercase font-18 disable custom-disable shadow-result-button" title={SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['show_results'] }>{ SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['show_results'] }</button>
                              </div>
                           </div>
                           <div className="d-flex chart-bottom justify-content-between align-items-center">{chart.user_participated ? <span className="user-count"><i class="ti-user"></i>{chart.user_participated}</span> : <span className="user-count"><i class="ti-user"></i>0</span>}
                              <div class="text-right" id={"share_chart_div_opt_" + index}>
                                 <button class="border-0 bg-transparent font-weight-bold pointer" id={"share_chart_div_opt_demographic_share_" + index} onClick={e =>this.shareSurvey(e,hideSnippet=true)}><i class="ti-share text-dark pr-2"></i>{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_icon_text']}</button>
                              </div>
                           </div>
                           {!this.props.hideChart ?
                              <div class="bubble-loader chart-main-block mx-0 mx-md-3 bg-white box-shadow">
                                 <i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i>
                              </div>
                           : null}
                        </div>
                     </div>
                  </React.Fragment>)
            }
         }
      })
      return chart_Divs;
   }
   // Popup message for Unlock Survey 
   unlockSurvey () {
      let storeitem = JSON.parse(localStorage.getItem('userdata'));
      let surveydata = this.props.surveydata;
      let price = 5;
      if (storeitem && storeitem.id) {
         // User logged in then this popup box display
         confirmAlert({
            customUI: ({ onClose }) => {
               return (
                  <div className='custom-ui custome-unlock-survey position-relative'>
                     <div className="close-btn text-right">
                        <button className="d-inline-block bg-white border-0 w-auto sharing-close-button py-1 text-rgb" onClick={(event) => {
                           onClose()
                        }}><i className="ti-close pointer"></i></button>
                     </div>
                     <React.Fragment>
                        <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin">{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['unlock_result_heading']}</h3>
                        <p className="mb-2 text-dark text-left">{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['unlock_result_popup_subtext'] + ' '}{<a href="/umfragen" className="text-lowercase">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['survey_mypoll_link']}</a>}{" " + SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['unlock_result_popup_subtext1']}</p>
                        <p className="text-dark mb-2 text-left">{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['require_karma_point'] + ":"}</p>
                        <p className="text-dark font-weight-bold text-left"> {price} {SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['require_karma_point2']} </p>
                     </React.Fragment>
                     <div className="button-group survey-unlock-button">
                        <button className='w-100 ml-0' name="unlockSurvey" onClick={(event) => {
                           this.handleClickUnlockSurvey()
                           onClose()
                        }}>{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['unlock_now']}</button>
                     </div>
                  </div>
               )
            }
         })
      }
      else {
         // User not logged in then this popup box display
         confirmAlert({
            customUI: ({ onClose }) => {
               return (
                  <div className='custom-ui custome-unlock-survey position-relative'>
                     <div className="close-btn text-right">
                        <button className="d-inline-block bg-white border-0 w-auto sharing-close-button py-1 text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                     </div>
                     <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin">{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['unlock_result_heading']}</h3>
                     <p className="mb-0">{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['unlock_result_popup_subtext2']}</p>
                     <div class="mt-2 text-dark text-survey d-flex font-16 lh-20 text-left">
                        <span class="fa fa-check survey-result-unlogged-popup"></span>
                        <div class="mb-0">
                           {SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_notlogged_user_first_subtext']}
                        </div>
                     </div>
                     <div class="text-dark text-survey d-flex font-16 lh-20 text-left">
                        <span class="fa fa-check survey-result-unlogged-popup"></span>
                        <div class="mb-0">
                           {SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_notlogged_user_second_subtext']}
                        </div>
                     </div>
                     <div className="survey-unlock-popup-text text-dark text-survey d-flex font-16 lh-20 text-left">
                        <span class="fa fa-check survey-result-unlogged-popup"></span>
                        <div class="mb-0">
                           {SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_notlogged_user_third_subtext']}
                        </div>
                     </div>
                     <div className="button-group survey-unlock-button">
                        <button className="w-100 ml-0 p-0" name="registration" onClick={(event) => {
                           this.registrationRedirect(event)
                           onClose()
                        }}>{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['register_button']}</button>
                     </div>
                  </div>
               )
            }
         })
      }
   }
   // Karrma Point Reduce in Survey unlock
   handleClickUnlockSurvey = async () => {
      // Payment reduce then display all charts in this function.
      await this.makePaymentshowGraph();
      // Draw chart in all questions
      setTimeout(() => {
         window.google.charts.load("50", { packages: ['corechart'] });
         window.google.charts.setOnLoadCallback(this.drawChart());
         window.google.charts.setOnLoadCallback(this.drawDemographicChart());
      }, 1000);
   }
   // Redirect To Registration page
   registrationRedirect (event) {
      if (event.target.name === "registration") {
         // registerRedirect is true to redirect registration page
         this.setState({ registerRedirect : true })
      }
   }
   // Save all chart image
   // saveEditedChartImage = (image_string, image_name, isLast, chartData) => {
   //    this.image_meta = {};
   //    this.image_meta['image_string'] = image_string ? image_string.replace("data:image/png;base64,", "") : '';
   //    this.image_meta['image_name'] = image_name;
   //    this.ImagetoSend.push(this.image_meta);
   //    if(isLast){
   //      this.props.sendImages(this.ImagetoSend, chartData);
   //      this.ImagetoSend = [];
   //      this.image_meta = {};
   //    }
   // }
   // Demographic chart display
   drawDemographicChart() {
      setTimeout(() => {
         // let QuestionLength = 0;
         // if (this.props.questionLength) {
         //    QuestionLength = this.props.questionLength;
         // }
         // eslint-disable-next-line
         this.props.chartData.demographic.map((chart, index) => {
            // let modifyImageFlag = 1 // 0 means no modification, 1 means remove information, 2 means pie chart
            // if (QuestionLength < 2 && index < 2) {
            //    modifyImageFlag = 0;
            // }
            // else if (chart.options.chart_type === "PieChart") {
            //    modifyImageFlag = 2;
            // }
            // eslint-disable-next-line
            if (chart.hasOwnProperty("question_type_id") && (chart.question_type_id === 5 || chart.question_type_id === 7)) {
            }
            else {
               var data = window.google.visualization.arrayToDataTable(chart.data);
               var view = new window.google.visualization.DataView(data);
               let chart_type_data = chart.options.chart_type;
               let img_name = chart.demographic_id + "_" + (chart.options && chart.options.title
                  ? (chart.options.title.length > 200)
                        ? chart.options.title.substring(0,200).replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_')
                        : chart.options.title.replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_')
                  : "demographic_" + chart.demographic_id);
               if (window.innerWidth < 767) {
                  img_name += ".png";
               }
               else {
                  img_name += ".png";
               }
               if (document.getElementById("share_chart_div_opt_demographic_share_dummy_" + index)) {
                  document.getElementById("share_chart_div_opt_demographic_share_dummy_" + index).name = img_name;
               }
               if (document.getElementById("share_chart_div_opt_demographic_share_" + index)) {
                  document.getElementById("share_chart_div_opt_demographic_share_" + index).name = img_name;
               }
               var chart_div = new window.google.visualization[chart_type_data](document.getElementById("chart_div_demographic_" + index));
               window.google.visualization.events.addListener(chart_div, 'ready', () => {
                  // let img_string = chart_div.getImageURI();
                  // saveEditedImage(img_string, chart, img_name, this.saveEditedChartImage, true, false, modifyImageFlag);
               });
               chart_div.draw(view, {...chart.options,...chartAnimation,...chartOptionTextStyle});
            }
         })
         }, 1000)
      }
   filterHtmlImage(node){
      if(node.tagName === "DIV"){
         if(node.getAttribute("name") !== "hidden-tag-chart"){
            return node;
         }
      }
      else {
         return node;
      }
   }
   // Draw A normal Question chart
   drawChart = () => {
      setTimeout(() => {
      // eslint-disable-next-line
      this.props.chartData.questiondata.map((chart, index) => {
         // let modifyImageFlag = 0 // 0 means no modification, 1 means remove information, 2 means pie chart
         // if (index > 1) {
         //    modifyImageFlag = 1;
         // }
         this.ImagetoSend = [];
         this.image_meta = {};
         if (chart.hasOwnProperty("question_type_id") && (chart.question_type_id === 5 || chart.question_type_id === 7)) {
            let keyArray = Object.keys(chart);
            // eslint-disable-next-line
            keyArray.map((value, array_index) => {
               if (value.substring(0, 4) === "data") {
                  this.image_meta = {};
                  var last_char = value.substring(4);
                  var data = window.google.visualization.arrayToDataTable(chart[value]);
                  var view = new window.google.visualization.DataView(data);
                  let chart_type_data;
                  let chart_options;
                  if (chart.question_type_id === 7 || chart.question_type_id === 5) {
                     chart_type_data = chart["option" + last_char].chart_type;
                     chart_options = chart["option" + last_char]
                  } else {
                     chart_type_data = chart.options.chart_type;
                     chart_options = chart.options;
                  }
                  var chart_div = new window.google.visualization[chart_type_data](document.getElementById("chart_div_opt_" + value + "_" + index));
                  window.google.visualization.events.addListener(chart_div, 'ready', () => {
                     // let img_string = chart_div.getImageURI();
                     // let isLast = keyArray.length -1 === array_index;
                     let img_name = chart.question_id + "_" + chart_options.option_id + "_" + ((chart_options && chart_options.title)
                        ? (chart_options.title.length > 200)
                           ? chart_options.title.substring(0,200).replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_')
                           : chart_options.title.replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_')
                        : chart.question_id + "_opt_" + value);
                     if (window.innerWidth < 767) {
                        img_name += ".png";
                     }
                     else {
                        img_name += ".png";
                     }
                     if (document.getElementById("share_chart_div_opt_normal_share_" + array_index + "_" + index)) {
                        document.getElementById("share_chart_div_opt_normal_share_" + array_index + "_" + index).name = img_name; 
                     }
                     if (document.getElementById("share_chart_div_opt_normal_share_dummy_" + array_index + "_" + index)) {
                        document.getElementById("share_chart_div_opt_normal_share_dummy_" + array_index + "_" + index).name = img_name;
                     }
                     if (document.getElementById("share_chart_div_opt_normal_share_" + index)) {
                        document.getElementById("share_chart_div_opt_normal_share_" + index).name = img_name; 
                     }
                     // saveEditedImage(img_string, chart, img_name, this.saveEditedChartImage, isLast, false, modifyImageFlag);
                  });
                  chart_div.draw(view, {...chart_options,...chartAnimation});
               }
            })
         }
         else if(chart.hasOwnProperty("question_type_id") && chart.question_type_id === 1) {
            // let self = this;
            // let img_name = index+1 + "_" + ((chart && chart.question_title && chart.question_title.title)
            //    ? (chart.question_title.title.length > 200)
            //       ? chart.question_title.title.substring(0,200).replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_')
            //       : chart.question_title.title.replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_')
            //    : chart.question_id);
            // if (window.innerWidth < 767) {
            //    img_name += ".png";
            // }
            // else {
            //    img_name += ".png";
            // }
            if(chart.tagcloud.length){
               htmlToImage.toCanvas(document.getElementById("chart_div_" + index), {filter: this.filterHtmlImage, style: {'font-family':'sans-serif'}})
               .then(function (dataUrl) {
                  // saveEditedImage(dataUrl.toDataURL(), chart, img_name, self.saveEditedChartImage, true, true);
               });
            }
         }
         else {
            this.image_meta = {};
            var data = window.google.visualization.arrayToDataTable(chart.data);
            var view = new window.google.visualization.DataView(data);
            let chart_type_data = chart.options.chart_type;
            var chart_div = new window.google.visualization[chart_type_data](document.getElementById("chart_div_" + index));
            window.google.visualization.events.addListener(chart_div, 'ready', () => {
               // let img_string = chart_div.getImageURI();
               let img_name = chart.question_id + "_" + ((chart && chart.options && chart.options.title)
                  ? (chart.options.title.length > 200)
                     ? chart.options.title.substring(0,200).replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_')
                     : chart.options.title.replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_')
                  : chart.question_id);
               if (window.innerWidth < 767) {
                  img_name += ".png";
               }
               else {
                  img_name += ".png";
               }
               if (document.getElementById("share_chart_div_opt_normal_share_" + index)) {
                  document.getElementById("share_chart_div_opt_normal_share_" + index).name = img_name;
               }
               if (document.getElementById("share_chart_div_opt_normal_share_dummy_" + index)) {
                  document.getElementById("share_chart_div_opt_normal_share_dummy_" + index).name = img_name;
               }
               if (document.getElementById("share_chart_div_opt_normal_share_" + index + "_" + index)) {
                  document.getElementById("share_chart_div_opt_normal_share_" + index + "_" + index).name = img_name; 
               }
               // saveEditedImage(img_string, chart, img_name, this.saveEditedChartImage, true, false, modifyImageFlag);
            });
            chart_div.draw(view, {...chart.options,...chartAnimation,...chartOptionTextStyle});
         }
      })
   }, 1000)
   }
     // copy to Url share
     copyToClipBoard = (e) => {
      var input = document.getElementById("copyurl");
      var isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);
      if (isiOSDevice) {
         var editable = input.contentEditable;
         var readOnly = input.readOnly;
         input.contentEditable = true;
         input.readOnly = false;
         var range = document.createRange();
         range.selectNodeContents(input);
         var selection = window.getSelection();
         selection.removeAllRanges();
         selection.addRange(range);
         input.setSelectionRange(0, 999999);
         input.contentEditable = editable;
         input.readOnly = readOnly;
      } else {
         this.text.select();
      }
      document.execCommand('copy');
      e.target.focus();
      this.setState({ copyMessage: "true" })
      window.setTimeout(() => {
            this.setState({ copyMessage: "" })
      }, MESSAGE_TIME);
   }
   // Share Survey Questions 
   shareSurvey = (e,hideSnippet) => {
      let surveydata = this.props.surveydata;
      let survey_slug = surveydata.survey_title_slug;
      let survey_title = surveydata.survey_title;
      var urlstring = "";
      if (e.currentTarget.name) { 
         let question_slug = e.currentTarget.name;
         question_slug = question_slug.replaceAll("ä","ae");
         question_slug = question_slug.replaceAll("ü","ue");
         question_slug = question_slug.replaceAll("ö","oe");
         question_slug =  question_slug.replaceAll("Ü","Ue");
         question_slug =  question_slug.replaceAll("Ä","Ae");
         question_slug =  question_slug.replaceAll("Ö","Oe");
         question_slug = question_slug.replaceAll("ß","ss");
         // eslint-disable-next-line
         urlstring = "<a href='"+BASEURL + URL_CONSTANT["survey_result"] + "/" + survey_slug +"' " + "rel='nofollow'><img src='"+ IMAGE_BASEURL + URL_CONSTANT['survey_result'] + '/' + survey_slug + '/' + question_slug + "' alt='Statistik:" + surveydata.survey_title + META_TITLE_APPEND + "'" + " style='width: 100%; height: auto !important; max-width:1000px;-ms-interpolation-mode: bicubic;'/></a><br />Mehr Statistiken findest du bei <a href='" + BASEURL + URL_CONSTANT['survey_result'] + "' rel='nofollow'>empirio</a>";
      }
      var success = {
         color: "green"
      }
      confirmAlert({
         customUI: ({ onClose }) => {
            return (
               <div className='custom-ui text-left position-relative share_popup'>
                  <div className="close-btn text-right">
                     <button className="d-inline-block bg-white border-0 w-auto sharing-close-button text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                  </div>
                  <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin">{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_survey_headig']}</h3>
                  <p className="mb-4">{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_survey_subtitle']}</p>
                  <div className="text-center">
                     <FacebookShareButton
                        className="mr-2 mr-lg-4"
                        url={BASEURL + URL_CONSTANT['survey_result'] + "/" + survey_slug}
                        quote={SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_survey_icon_title'] + ' "' + survey_title + '".'}>
                        <FacebookIcon size={40} round={true} bgStyle={{"fill": "#00b3f4"}} />
                     </FacebookShareButton>
                     <WhatsappShareButton
                        className="mr-2 mr-lg-4"
                        url={BASEURL + URL_CONSTANT['survey_result'] + "/" + survey_slug}
                        title={SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_survey_icon_title'] + ' "' + survey_title + '".'}
                        hashtag="#survey result detail page">
                        <WhatsappIcon size={40} round={true} bgStyle={{"fill": "#00b3f4"}} />
                     </WhatsappShareButton>
                     <LinkedinShareButton
                        className="mr-2 mr-lg-4"
                        url={BASEURL + URL_CONSTANT['survey_result'] + "/" + survey_slug}
                        title={SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_survey_icon_title'] + ' "' + survey_title + '".'}
                        hashtag="#survey result detail page">
                        <LinkedinIcon size={40} round={true} bgStyle={{"fill": "#00b3f4"}} />
                     </LinkedinShareButton>
                     <TwitterShareButton
                        className="mr-2 mr-lg-4"
                        url={BASEURL + URL_CONSTANT['survey_result'] + "/" + survey_slug}
                        title={SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_survey_icon_title'] + ' "' + survey_title + '".'}
                        hashtag="#survey result detail page">
                        <TwitterIcon size={40} round={true} bgStyle={{"fill": "#00b3f4"}} />
                     </TwitterShareButton>
                     <EmailShareButton
                        className="mr-2 mr-lg-4"
                        subject = {SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_survey_icon_title'] + ' "' + survey_title + '".'}
                        url={BASEURL + URL_CONSTANT['survey_result'] + "/" + survey_slug}
                        hashtag="#survey result detail page">
                        <EmailIcon size={40} round={true} bgStyle={{"fill": "#00b3f4"}} />
                     </EmailShareButton>
                  </div>
                  {!hideSnippet ? 
                  <div className="freetext-subtitle pt-4 pb-0" name="hidden-tag-chart">
                     <h3 className="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-unlock-popup-text">{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['share_survey_heading2']}</h3>
                     <div className="form-group hs-form-field focus survey-unlock-button">
                        <input type="textarea" name="enter the link here" className="form-control hs-input" id="copyurl" readOnly value={urlstring} ref={(text) => this.text = text} />
                        <div className="input-group-append">
                           <div className="icon-for-copy">
                              <button type="button" className="btn-copy" onClick={this.copyToClipBoard} title={EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['copy_to_clipboard']}><i className="far fa-copy"></i></button>
                           </div>
                           <div className="validation-message" id="copymsg" style={success}>{(this.state.copyMessage === "true") ? EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['copied_message'] : null}</div>
                        </div>
                     </div>
                  </div>
                   :null}
                  {/* <div dangerouslySetInnerHTML={{ __html: urlstring }} /> */}
               </div>
            )
         }
      })
   }
   render() {
      let chart_divs = (this.props.chartData) ? this.createChartDiv(this.state.chart_data) : <div className="d-flex align-items-center justify-content-center" style={{"height" : "400px"}}><i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i></div>;
      let chart_Divs = (this.props.chartData) ? this.createDemographicChartDiv(this.state.chart_data) : <div className="d-flex align-items-center justify-content-center" style={{"height" : "400px"}}><i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i></div>;
      return (
         <React.Fragment>
            {(this.state.registerRedirect === true) ?
               <Redirect to="/registrieren"></Redirect>
               :null
            }
            <div id="columnchart_values" className="row mx-3 mx-lg-0 resule-detail-chart">
               {chart_divs} {(chart_Divs) ? chart_Divs : null }
            </div>
         </React.Fragment>
      )
   }
}