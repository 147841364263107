import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { SURVEY_PUBLISH_RESULT_STATUS_DROPDOWN, SCIENTIFIC_WORK_TYPE_DROPDOWN, SURVEY_DETAIL_CONSTANT, SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT, SURVEY_LIST_CONSTANT, BUBBLE_LOADING, NO_OF_PARTICIPANTS_DROPDOWN } from '../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, LIMIT, OFFSET, IMAGE_BASEURL } from '../../../constants/DotEnv';
import Autocomplete from 'react-autocomplete';
class SurveyPublishResultListContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownoption: '',
            university: '',
            filterdata: [],
            count: 0,
            loading: false,
            offset: '',
            checkLoader: false,
            surveycount: '',
            spinner: false
        }
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.getDropdownData();
        let autosuggestionData = document.getElementsByClassName("form-autocomplete-suggestion-field");
        if (autosuggestionData.length) {
            for (let i = 0; i < autosuggestionData.length; i++) {
                document.getElementsByClassName("form-autocomplete-suggestion-field")[i].childNodes[1].remove();
            }
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let autosuggestionData = document.getElementsByClassName("form-autocomplete-suggestion-field");
        if (autosuggestionData.length) {
            for (let i = 0; i < autosuggestionData.length; i++) {
                if (document.getElementsByClassName("form-autocomplete-suggestion-field")[i].childNodes[1]) {
                    document.getElementsByClassName("form-autocomplete-suggestion-field")[i].childNodes[1].remove();
                }
            }
        }
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (document.querySelector('.navbar-toggler')) {
            let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
            if (count === -1) {
                document.querySelector('.navbar-collapse').classList.remove('show');
                document.querySelector('header').classList.remove("header_collapse");
                document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
            }
        }
    }
    /**API call for getting dropdown list data to filter survey */
    getDropdownData = async () => {
        let dropdownoption = null;
        let self = this;
        // dropdownoption = JSON.parse(localStorage.getItem("surveylistdropdown"));
        if (dropdownoption === null) {
            await axios.get(API_URL + '/commonApi/getAllSurveydp/' + LANGUAGE_CODE)
                .then((response) => {
                    dropdownoption = response.data.success;
                    localStorage.setItem("surveylistdropdown", JSON.stringify(dropdownoption));
                    self.setState({ dropdownoption })
                })
                .catch((error) => {
                })
        }
        else {
            dropdownoption = JSON.parse(localStorage.getItem("surveylistdropdown"))
            self.setState({ dropdownoption })
        }
        let filteritem = JSON.parse(localStorage.getItem('filter_survey_result'));
        if (filteritem) {
            let id = ""
            var universityoption = dropdownoption.university;
            // eslint-disable-next-line
            universityoption.map((data) => {
                if (data.id === filteritem[0]['university']) {
                    id = data.university_name
                }
            })
            this.setState({ category: filteritem[0]['category'], participants: filteritem[0]['participants'], status: filteritem[0]['status'], university: id, scientific_work_type: filteritem[0]['scientific_work_type'] });
        }
    }
    loadMore = () => {
        this.setState({ loading: true, spinner: true, surveycount: "" })
        var participants = document.getElementById('participants').value;
        var status = document.getElementById('status').value;
        var category = document.getElementById('category').value;
        var university = document.getElementById('university').value
        var scientificType = document.getElementById('scientific').value
        var universityoption = this.state.dropdownoption.university;
        let id = 0;
        universityoption.map((data) => {
            if (data.university_name === university) {
                id = data.id
            }
            return 0;
        })
        let offset = (this.state.offset) ? this.state.offset : OFFSET;
        offset = parseInt(offset) + parseInt(12);
        this.setState({ offset })
        let newdata = '';
        let olddata = [];
        let surveycount = '';
        if (this.state.filterdata.length > 0) {
            olddata = this.state.filterdata;
        }
        else {
            olddata = this.props.data;
        }
        let filterdata = '';
        let self = this;
        let userId = '';
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        if(storeitem !== null) {
            userId = storeitem.id;
        }
        setTimeout(async () => {
            await axios.get(API_URL + '/commonApi/getDataOfPublishedResults/' + LANGUAGE_CODE + '?limit=' + LIMIT + '&offset=' + offset + '&category=' + category + '&university=' + id + '&participants=' + participants + '&status=' + status + "&scientific_work_type=" + scientificType + '&userId=' + userId)
                .then((response) => {
                    newdata = response.data.success.surveydata;
                    filterdata = olddata.concat(newdata)
                    surveycount = response.data.success.surveycount
                    /**removed checkloader for not showing loading */
                    self.setState({ loading: false, spinner: false, filterdata, surveycount })
                })
                .catch((error) => {
                    self.setState({ loading: false, spinner: false })
                })
        }, 500)
    }
    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }
    changeFilter = async (event) => {
        this.setState({ count: 0, loading: true, surveycount: '' })
        let filterdata = [];
        var universityoption = this.state.dropdownoption.university;
        let id = 0;
        let length = '';
        if (event.type) {
            if (event.type === 'click') {
                document.getElementById(event.target.id).value = "";
                if (event.target.id === 'university') {
                    this.setState({ university: "" })
                }
            }
        }
        var participants = document.getElementById('participants').value;
        var status = document.getElementById('status').value;
        var category = document.getElementById('category').value;
        var university = document.getElementById('university').value;
        var scientificType = document.getElementById('scientific').value;
        let checkLoader = '';
        let surveycount = '';
        if (event.target !== undefined) {
            var name = event.target.name;
            var value = event.target.value;
            if (name === 'university') {
                checkLoader = false;
                if (value === '') {
                    checkLoader = true;
                }
            }
            else {
                checkLoader = true;
            }
            this.setState({ [name]: value, checkLoader })
        }
        else {
            checkLoader = true;
            if (event.type) {
                if (event.type === 'click') {
                    if (event.target.id === 'university') {
                        this.setState({ university: "" })
                        university = ""
                    }
                }
            }
            else {
                this.setState({ university: event, checkLoader })
                university = event
            }
        }
        universityoption.map((data) => {
            if (data.university_name === university) {
                id = data.id
                checkLoader = true;
                this.setState({ checkLoader: true })
            }
            return 0;
        })
        let filter = [];
        filter.push({ "participants": participants, "status": status, "category": category, "university": id, "scientific_work_type": scientificType})
        this.setState({ "participants": participants, "status": status, "category": category, "scientific_work_type": scientificType })
        localStorage.setItem("filter_survey_result", JSON.stringify(filter));
        let self = this;
        let userId = '';
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        if(storeitem !== null) {
            userId = storeitem.id;
        }
        if (checkLoader === true) {
            await axios.get(API_URL + '/commonApi/getDataOfPublishedResults/' + LANGUAGE_CODE + '?limit=' + LIMIT + '&offset=' + OFFSET + '&category=' + category + '&university=' + id + '&participants=' + participants + '&status=' + status  + '&scientific_work_type=' + scientificType + '&userId=' + userId)
                .then((response) => {
                    filterdata = response.data.success.surveydata
                    surveycount = response.data.success.surveycount
                    length = filterdata.length;
                    if (length === 0) {
                        self.setState({ count: 1 })
                    }
                    self.setState({ filterdata, surveycount, loading: false, checkLoader: false })
                })
                .catch((error) => {
                    self.setState({ loading: false, checkLoader: false })
                })
            this.setState({ offset: 0 })
        }
    }
    _handleClick = (e) => {
        var id = e.target.id
        var element = document.getElementById(id);
        element.classList.toggle("active");
        var panel = document.getElementById(id).nextSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
        e.preventDefault();
    }
    render() {
        var filterElements = JSON.parse(localStorage.getItem("filter_survey_result"));
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        /**for identifying chrome and safari browser */
        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        var issafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        var participants, status, category, university, scientifics;
        if (document.getElementById('participants')) {
            participants = document.getElementById('participants').value;
        }
        if (document.getElementById('status')) {
            status = document.getElementById('status').value;
        }
        if (document.getElementById('category')) {
            category = document.getElementById('category').value;
        }
        if (document.getElementById('university')) {
            if (filterElements) {
                university = filterElements[0]['university'];
            } else {
                university = "";
            }
        }
        if (document.getElementById('scientific')) {
            scientifics = document.getElementById('scientific').value;
        }
        let autosuggestionData = document.getElementsByClassName("form-autocomplete-suggestion-field");
        if (autosuggestionData.length) {
            for (let i = 0; i < autosuggestionData.length; i++) {
                if (document.getElementsByClassName("form-autocomplete-suggestion-field")[i].childNodes[1]) {
                    document.getElementsByClassName("form-autocomplete-suggestion-field")[i].childNodes[1].remove();
                }
            }
        }
        let filterdatalength = this.state.filterdata.length;
        let length = this.state.dropdownoption.length;
        let optiondata = '';
        let universityOption = '';
        let categoryOption = '';
        let statusoption = '';
        let participantsoption = '';
        let scientificoption = "";
        let surveycount = '';
        let count = this.state.count;
        if (length !== 0) {
            optiondata = this.state.dropdownoption;
        }
        if (optiondata !== '') {
            universityOption = optiondata.university;
            const category = optiondata.surveycategory;
            categoryOption = category.map((category, index) => {
                return (<option value={category.id} key={index}>{category.category_name}</option>)
            })
            const participants = NO_OF_PARTICIPANTS_DROPDOWN[LANGUAGE_CODE];
            const status = SURVEY_PUBLISH_RESULT_STATUS_DROPDOWN[LANGUAGE_CODE];
            const scientific_work_type = SCIENTIFIC_WORK_TYPE_DROPDOWN[LANGUAGE_CODE];
            statusoption = status.map((status, index) => {
                return (<option value={status.id} key={index}>{status.status_name}</option>)
            })
            participantsoption = participants.map((participant, index) => {
                return (<option value={participant.id} key={index}>{participant.participants_name}</option>)
            })
            scientificoption = scientific_work_type.map((type, index) => {
                return (<option value={type.id} key={index}>{type.scientific_type}</option>)
            })
        }
        let component = [];
        let data = '';
        if (filterdatalength > 0) {
            data = this.state.filterdata;
            surveycount = this.state.surveycount
        }
        else {
            data = this.props.data;
            if (data.length > 0) {
                count = 1
            }
            surveycount = (this.state.surveycount !== "") ? this.state.surveycount : this.props.surveycount;
        }
        if ((status || category || participants || university || scientifics) && filterdatalength === 0 && count !== 1) {
            component.push(<div className="col-md-12 col-lg-8 col-xl-4"><p style={{ "fontSize": "24px" }}>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['norecordsfoundmsg']}</p></div>)
        }
        if (this.state.count === 1) {
            component.push(<div className="col-md-12 col-lg-8 col-xl-4"><p style={{ "fontSize": "24px" }}>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['norecordsfoundmsg']}</p></div>)
        }
        var elements = data.map((data, index) => {
            let title = data['survey_title_slug'];
            let titleNew;
            if (data['survey_title']) {
                if (window.innerWidth >= 1200 && window.innerWidth < 1500) {
                    if (data['survey_title'].length > 57) {
                        titleNew = data['survey_title'].substring(0, 57).trim() + "...";
                    } else {
                        titleNew = data['survey_title']
                    }
                }
                else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
                    if (data['survey_title'].length > 70) {
                        titleNew = data['survey_title'].substring(0, 70).trim() + "...";
                    } else {
                        titleNew = data['survey_title']
                    }
                }
                else if (window.innerWidth >= 768 && window.innerWidth < 992) {
                    if (data['survey_title'].length > 76) {
                        titleNew = data['survey_title'].substring(0, 76).trim() + "...";
                    } else {
                        titleNew = data['survey_title']
                    }
                }
                else if (window.innerWidth >= 576 && window.innerWidth < 768) {
                    if (data['survey_title'].length > 152) {
                        titleNew = data['survey_title'].substring(0, 152).trim() + "...";
                    } else {
                        titleNew = data['survey_title']
                    }
                }
                else if (window.innerWidth >= 414 && window.innerWidth < 576) {
                    if (data['survey_title'].length > 105) {
                        titleNew = data['survey_title'].substring(0, 105).trim() + "...";
                    } else {
                        titleNew = data['survey_title']
                    }
                }
                else if (window.innerWidth >= 320 && window.innerWidth < 414) {
                    if (data['survey_title'].length > 70) {
                        titleNew = data['survey_title'].substring(0, 70).trim() + "...";
                    } else {
                        titleNew = data['survey_title']
                    }
                }
                else if (window.innerWidth < 320) {
                    if (data['survey_title'].length > 65) {
                        titleNew = data['survey_title'].substring(0, 65).trim() + "...";
                    } else {
                        titleNew = data['survey_title']
                    }
                }
                else {
                    if (data['survey_title'].length > 78) {
                        titleNew = data['survey_title'].substring(0, 78).trim() + "...";
                    } else {
                        titleNew = data['survey_title'];
                    }
                }
            }
            let backImage = IMAGE_BASEURL + "img/100x100.jpg"
            if (data['thumb_image']) {
                backImage = data['thumb_image'];
            }
            return (<div className="col-md-6 col-lg-4 col-xl-3" key={index}>
                <div className="blog-item">
                    <Link to={"/statistiken/" + title}>
                        <div className="blog-img position-relative h-220 background-image-css" style={{ "backgroundImage": "url(" + backImage + ")" }}></div>
                        <div className="blog-content">
                            <h6 className={(isChrome || issafari) ? "text-dark font-16 custom-test-text" : "text-dark font-16 text-hyphens"}>{(titleNew) ? (issafari || isChrome) ? data['survey_title'] : titleNew : null}</h6>
                            {(data['unlockSurveyResult'] === 1) ?
                                <div className="progress custom-main-progress">
                                    <span className="progress-value custom-progress">{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['unlock_survey_message']}</span>
                                </div>
                                :
                                <div className="progress custom-main-progress">
                                    <span className={"text-gray progress-value custom-progress fw-400 text-left"}>{SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['from_subtitle'] + " " + data['user_name']}</span>
                                </div>
                            }
                        </div>
                        {(data['userimage']) ? 
                            <div className="user_profile_photo">
                                <div className="login-profile profile_pic">
                                    <img src={data['userimage']} className="rounded-circle center" alt="person-img" width="70" height="70" />
                                </div>
                            </div>
                            : null
                        }
                        <div className="blog-content pt-1">
                            <div className="d-flex">
                                <ul className="list-unstyled mb-2 mr-3 custom-icon-margin-right">
                                    <li className="text-gray d-flex">
                                        <i className="ti-bar-chart mr-3 custom-icon-margin-right font-18"></i>
                                        <div className="blog__card-title d-block">
                                            <span className="d-block">{data['totalquestion_published'] ? data['totalquestion_published'] : 0}</span>
                                            {SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT[LANGUAGE_CODE]['question_published_title']}
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-unstyled mb-2 mr-3 ml-2 ml-xl-4 custom-icon-mx">
                                    <li className="text-gray">
                                        <i className="ti-user mr-3 custom-icon-margin-right font-18"></i>
                                        <div className="blog__card-title d-block">
                                            <span className="d-block">{data['participation_count'] ? data['participation_count'] : 0}</span>
                                            {SURVEY_DETAIL_CONSTANT[LANGUAGE_CODE]['totalparticipants']}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="tags w-100 d-inline-block px-0">
                                <span className="text-gray d-flex w-100 align-items-center font-14 py-0 px-0">
                                    <i className="ti-location-pin mr-3 custom-icon-margin-right font-18"></i>
                                    <div className="blog__card-title custom-university-name-line-clamp">
                                       {data['university_name']}
                                    </div>
                               </span>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>)
        })
        return (
            // <!-- custom-select -->
            <div>
                {(this.props.loading === true) ?
                    BUBBLE_LOADING
                    : null
                }
                {(this.state.loading === true && this.state.checkLoader === true) ?
                    BUBBLE_LOADING
                    : null
                }
                { (window.innerWidth >= 320 && window.innerWidth < 768) ?
                    <div className="container container-large mt-4">
                        <div className="accordion-02">
                            <div className="acco-group mb-0" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                                {/* eslint-disable-next-line*/ }
                                <a href="#" className="acco-heading text-dark font-weight-bold font-18 font_16 acco-heading-plus" itemProp="name" id="filter" onClick={this._handleClick}>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['filter']}</a>
                                <div className="panel" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                    <section className="custom-select-survey custom-xs-survey bg-white pt-4">
                                        <div className="container container-large">
                                            <div className="row">
                                                <div className="col-12 col-md">
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <label className="input-group-text sr-only" htmlFor="inputGroupSelect01"></label>
                                                        </div>
                                                        <select className="custom-select" id="category" name="category" onChange={this.changeFilter} value={this.state.category}>
                                                            <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['category']}</option>
                                                            {categoryOption}
                                                        </select>
                                                        <div className={(category) ? "input-group-append input_close_btn" : "d-none"}>
                                                            <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="category" value=""></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md">
                                                    <div className="input-group custom-autocomplete-filter mb-3">
                                                        <div className="input-group-prepend">
                                                            <label className="input-group-text sr-only" htmlFor="inputGroupSelect02"></label>
                                                        </div>
                                                        <Autocomplete
                                                            value={this.state.university}
                                                            inputProps={{ id: 'university', name: "university", className: 'form-control hs-input filter-autocomplete text-truncate', placeholder: SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['university'] }}
                                                            items={universityOption}
                                                            getItemValue={item => item.university_name}
                                                            shouldItemRender={(this.state.university) ? (item) => item.university_name.toLowerCase().indexOf(this.state.university.toLowerCase()) !== -1 : null}
                                                            // eslint-disable-next-line
                                                            onChange={this.changeFilter}
                                                            // eslint-disable-next-line
                                                            onSelect={this.changeFilter}
                                                            renderMenu={children => (
                                                                <div className="form-autocomplete-suggestion">
                                                                    {children}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) => (
                                                                (item.university_name) ?
                                                                    <div key={item.id} id={item.id} className={(isHighlighted || this.state.university === item.university_name) ? "form-autocomplete-suggestion-field custom-autocomplete-background" : "form-autocomplete-suggestion-field"}>
                                                                        {item.university_name.trim()}
                                                                    </div>
                                                                    : null
                                                            )}
                                                        />
                                                        <div className={(university) ? "input-group-append input_close_btn" : 'd-none'}>
                                                            <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="university" value=""></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md">
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <label className="input-group-text sr-only" htmlFor="inputGroupSelect04"></label>
                                                        </div>
                                                        <select className="custom-select" id="participants" name="participants" onChange={this.changeFilter} value={this.state.participants}>
                                                            <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['participants']}</option>
                                                            {participantsoption}
                                                        </select>
                                                        <div className={(participants) ? "input-group-append input_close_btn" : "d-none"}>
                                                            <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="participants" value=""></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md">
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <label className="input-group-text sr-only" htmlFor="inputGroupSelect05"></label>
                                                        </div>
                                                        <select className="custom-select" id="status" name="status" onChange={this.changeFilter} value={this.state.status}>
                                                            <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['sorting']}</option>
                                                            {statusoption}
                                                        </select>
                                                        <div className={(status) ? "input-group-append input_close_btn" : "d-none"}>
                                                            <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="status" value=""></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md">
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <label className="input-group-text sr-only" htmlFor="inputGroupSelect05"></label>
                                                        </div>
                                                        <select className="custom-select" id="scientific" name="scientific" onChange={this.changeFilter} value={this.state.scientific_work_type}>
                                                            <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['scientific_type']}</option>
                                                            {scientificoption}
                                                        </select>
                                                        <div className={(scientifics) ? "input-group-append input_close_btn" : "d-none"}>
                                                            <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="scientific" value=""></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                <section className="custom-select-survey custom-xs-survey bg-white">
                    <div className="container container-large">
                        <div className="row">
                            <div className="col-12 col-md">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text sr-only" htmlFor="inputGroupSelect01"></label>
                                    </div>
                                    <select className="custom-select" id="category" name="category" onChange={this.changeFilter} value={this.state.category}>
                                        <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['category']}</option>
                                        {categoryOption}
                                    </select>
                                    <div className={(category) ? "input-group-append input_close_btn" : "d-none"}>
                                        <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="category" value=""></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md">
                                <div className="input-group custom-autocomplete-filter mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text sr-only" htmlFor="inputGroupSelect02"></label>
                                    </div>
                                    <Autocomplete
                                        value={this.state.university}
                                        inputProps={{ id: 'university', name: "university", className: 'form-control hs-input filter-autocomplete text-truncate', placeholder: SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['university'] }}
                                        items={universityOption}
                                        getItemValue={item => item.university_name}
                                        shouldItemRender={(this.state.university) ? (item) => item.university_name.toLowerCase().indexOf(this.state.university.toLowerCase()) !== -1 : null}
                                        // eslint-disable-next-line
                                        onChange={this.changeFilter}
                                        // eslint-disable-next-line
                                        onSelect={this.changeFilter}
                                        renderMenu={children => (
                                            <div className="form-autocomplete-suggestion">
                                                {children}
                                            </div>
                                        )}
                                        renderItem={(item, isHighlighted) => (
                                            (item.university_name) ?
                                                <div key={item.id} id={item.id} className={(isHighlighted || this.state.university === item.university_name) ? "form-autocomplete-suggestion-field custom-autocomplete-background" : "form-autocomplete-suggestion-field"}>
                                                    {item.university_name.trim()}
                                                </div>
                                                : null
                                        )}
                                    />
                                    <div className={(university) ? "input-group-append input_close_btn" : 'd-none'}>
                                        <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="university" value=""></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text sr-only" htmlFor="inputGroupSelect04"></label>
                                    </div>
                                    <select className="custom-select" id="participants" name="participants" onChange={this.changeFilter} value={this.state.participants}>
                                        <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['participants']}</option>
                                        {participantsoption}
                                    </select>
                                    <div className={(participants) ? "input-group-append input_close_btn" : "d-none"}>
                                        <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="participants" value=""></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text sr-only" htmlFor="inputGroupSelect05"></label>
                                    </div>
                                    <select className="custom-select" id="status" name="status" onChange={this.changeFilter} value={this.state.status}>
                                        <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['sorting']}</option>
                                        {statusoption}
                                    </select>
                                    <div className={(status) ? "input-group-append input_close_btn" : "d-none"}>
                                        <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="status" value=""></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text sr-only" htmlFor="inputGroupSelect05"></label>
                                    </div>
                                    <select className="custom-select" id="scientific" name="scientific" onChange={this.changeFilter} value={this.state.scientific_work_type}>
                                        <option value="">{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['scientific_type']}</option>
                                        {scientificoption}
                                    </select>
                                    <div className={(scientifics) ? "input-group-append input_close_btn" : "d-none"}>
                                        <i className="fa fa-times input-group-text" onClick={this.changeFilter} id="scientific" value=""></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                }
                {/* <!-- custom-select-end --> */}
                {/* <!-- main survey --> */}
                <section className="section-blog bg-white">
                    <div className="container container-large">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="blog-lising">
                                    <div className="row">
                                        {/* surveylist data start */}
                                        {(component.length !== 0) ?
                                            component
                                            : elements
                                        }
                                    </div> {/*<!-- row -->*/}
                                    {(surveycount === 0) ? null : (elements.length === surveycount) ? null
                                        : <div className="btnlink load-more text-center mt-4">
                                            {(this.state.spinner === true) ? <div><i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i></div> : <button type="button" onClick={this.loadMore} className="btn btn-clr text-uppercase text-white font-weight-bold font-18" title={SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['loadmore_button']}>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['loadmore_button']}</button>}
                                        </div>
                                    }
                                </div> {/* <!-- blog lisgin -->*/}
                            </div> {/* <!-- col -->*/}
                        </div> {/* <!-- row -->*/}
                    </div>  {/*<!-- container -->*/}
                </section>
                {/* <!--main-survey-end --> */}
            </div>
        );
    }
}
export default SurveyPublishResultListContent;