import React, { Component } from "react";
import axios from 'axios';
import {SURVEY_CREATION_CONSTANT, BUBBLE_LOADING} from '../../../../../js/constants/FrontEndConstant';
import { LANGUAGE_CODE,API_URL} from '../../../../constants/DotEnv';

export default class SurveyDefineClosingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headingLengthLimit: 40,
      freetextLengthLimit: 400,
      headingValue: "",
      freetextValue: "",
      loading:false
    };
  }

  handleChange = (e) => {
    let name =  e.target.name
    let value = e.target.value
    this.setState({[name]:value})
  }

  getSurveyEndPageInfo = async () =>{
    let storeitem = JSON.parse(localStorage.getItem('userdata'));
    this.setState({ loading: 'true' })
    await axios.get(API_URL + "/commonApi/getSurveyEndPageInfo/" + LANGUAGE_CODE + '/'+ this.props.SurveyId +'?uId=' + storeitem.id)
    .then((response) => {
        this.setState({headingValue:response.data.success.survey_end_page_title})
        this.setState({freetextValue:response.data.success.survey_end_page_description})
        this.setState({ loading: 'false' })
    })
    .catch((error) => {
        console.log(error);
        this.setState({ loading: 'false' });
    })
}

  componentDidMount() {
    this.getSurveyEndPageInfo();
  }

  render() {
    return (
      <div className={"tab-pane sectionstep body current custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")}>
      {(this.state.loading === 'true') ? BUBBLE_LOADING : null }
        <div className="mt-4 form-group survey-form-detail">
          <label htmlFor="headingValue" className="sr-only">sdf</label>
          <input
            type="text"
            name="headingValue"
            id="headingValue"
            className="get-header form-control"
            value={this.state.headingValue}
            placeholder= {SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_content_page_header_placeholder']}
            maxlength={this.state.headingLengthLimit}
            onChange={this.handleChange}
          />
          <span className="title-counter mt-1 d-block">{this.state.headingLengthLimit - (this.state.headingValue ? this.state.headingValue.length : 0)}/{this.state.headingLengthLimit}</span>
        </div>
        <div className="align-items-center mt-4">
          <label htmlFor="freetextValue" className="sr-only">sdf</label>
          <textarea
            rows="4"
            cols="35"
            name="freetextValue"
            id="freetextValue"
            className="w-100 text-msg text-area-design get-text"
            value={this.state.freetextValue}
            placeholder= {SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_content_page_text_placeholder']}
            maxlength={this.state.freetextLengthLimit}
            onChange={this.handleChange}
          />
          <span className="title-counter d-block">{this.state.freetextLengthLimit - (this.state.freetextValue ? this.state.freetextValue.length : 0)}/{this.state.freetextLengthLimit}</span>
        </div>
        <div id="errorSurveyMessage" className="error-text mt-2">
        {(this.props.validationMessage) ?
          (!((this.state.headingValue && this.state.freetextValue) || (this.state.headingValue == '' && this.state.freetextValue ==''))) &&
            <p>{this.props.validationMessage}</p> :null 
        }</div>
      </div>
    );
  }
}
