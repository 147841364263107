import React from 'react';
import { Link } from "react-router-dom";
import { LINK_TREE_CONSTANT, FOOTER_LINKS, URL_CONSTANT } from "../../constants/FrontEndConstant";
import { LANGUAGE_CODE } from '../../constants/DotEnv';
class LinkTree extends React.Component {
    constructor(props) {
        super(props);
        setTimeout(() => {
            window.setNoIndex();
        }, 3000)
    }
    componentWillUnmount() {
        window.removeNoIndex();
    }
    componentDidMount() {
        this.scrollUpFunction();
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
        if (count === -1) {
            document.querySelector('.navbar-collapse').classList.remove('show');
            document.querySelector('header').classList.remove("header_collapse");
            document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
        }
    }
    render() {
        return (
            <main>
                <section className="page-title-section theme-bg">
                    <div className="container-fluid">
                        <div className="row align-items-center justify-content-center no-gutters text-center min-height-form">
                            <div className="login-form col-12 col-sm-10 col-md-7 col-lg-5 bg-link-tree">
                                <div className="padding-login-for hs-form-field">
                                    <div className="form-group font-size-btn">
                                        <Link className="nav-link-btn btn btn-link-tree link-tree-box-shadow text-dark bg-white d-block mx-0" to="/" title={LINK_TREE_CONSTANT[LANGUAGE_CODE]['link_tree_home']}>{LINK_TREE_CONSTANT[LANGUAGE_CODE]['link_tree_home']}</Link>
                                    </div>
                                    <div className="form-group font-size-btn">
                                        <Link className="nav-link-btn btn btn-link-tree link-tree-box-shadow text-dark bg-white d-block mx-0" to={'/' + URL_CONSTANT['survey_list']} title={LINK_TREE_CONSTANT[LANGUAGE_CODE]['link_tree_umfragen']}>{LINK_TREE_CONSTANT[LANGUAGE_CODE]['link_tree_umfragen']}</Link>
                                    </div> 
                                    <div className="form-group font-size-btn">
                                        <Link className="nav-link-btn btn btn-link-tree link-tree-box-shadow text-dark bg-white d-block mx-0" to={"/" + URL_CONSTANT['survey_result']} title={LINK_TREE_CONSTANT[LANGUAGE_CODE]['link_tree_statisken']}>{LINK_TREE_CONSTANT[LANGUAGE_CODE]['link_tree_statisken']}</Link>
                                    </div>
                                    <div className="form-group font-size-btn">
                                        <Link className="nav-link-btn btn btn-link-tree link-tree-box-shadow text-dark bg-white d-block mx-0" to={'/' + URL_CONSTANT['empiriowissen']} title={FOOTER_LINKS[LANGUAGE_CODE]['empirio_knowledge']}>{FOOTER_LINKS[LANGUAGE_CODE]['empirio_knowledge']}</Link>
                                    </div>
                                    <div className="form-group font-size-btn">
                                        <Link className="nav-link-btn btn btn-link-tree link-tree-box-shadow text-dark bg-white d-block mx-0" to='/datenschutz' title={FOOTER_LINKS[LANGUAGE_CODE]['data_protection']}>{FOOTER_LINKS[LANGUAGE_CODE]['data_protection']}</Link>
                                    </div>
                                    <div className="form-group font-size-btn">
                                        <Link className="nav-link-btn btn btn-link-tree link-tree-box-shadow text-dark bg-white d-block mx-0" to='/impressum' title={FOOTER_LINKS[LANGUAGE_CODE]['imprint']}>{FOOTER_LINKS[LANGUAGE_CODE]['imprint']}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
export default LinkTree;