import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { HOMEPAGE_CONSTANT, BUBBLE_LOADING, META_FACEBOOK, BREADCRUMB_SCHEMA } from '../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, META_TITLE_HOME_PREPEND, IMAGE_BASEURL } from '../../constants/DotEnv';

const url = {
  1440 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-1440.svg',
  1280 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-1280.svg',
  1024 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-1024.svg',
  768 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-768.svg',
  375 : 'img/empirio_startseiten_grafiken_hintergrund01-gradient-01-375.svg',
  'default' : 'img/empirio_startseiten_grafiken_hintergrund01.svg'
}
const homeSectionUrl = {
  768 : 'img/empirio_startseiten_grafiken_hintergrund01_content03.svg',
  375 : 'img/empirio_startseiten_grafiken_hintergrund01_content03-375.svg',
  320 : 'img/empirio_startseiten_grafiken_hintergrund01_content03-320.svg',
  'default' : 'img/empirio_startseiten_grafiken_hintergrund01_content02.svg'
}
class Main extends React.Component {
  constructor() {
    super();
    this.setImageVar()
    this.setHomeSectionVar()
    this.state = {
      homecontent: {},
      faqcontent: {},
      loading: 'false',
    }
  }
  setHomeSectionVar = () => {
    
    if(window.innerWidth <= 320) {
      this.homeSectionImageBg = "url(" +IMAGE_BASEURL+ homeSectionUrl[320]+")"
    }else if(window.innerWidth <= 375) {
      this.homeSectionImageBg = "url(" +IMAGE_BASEURL+ homeSectionUrl[375]+")"
    }else if(window.innerWidth < 768) {
      this.homeSectionImageBg = "url(" +IMAGE_BASEURL+ homeSectionUrl[768]+")"
    }else{
      this.homeSectionImageBg = "url(" + IMAGE_BASEURL+ homeSectionUrl['default'] +")"
    }
  }
  setImageVar = () => {
    if(window.innerWidth <= 375) {
      this.mainImageBg = "url(" +IMAGE_BASEURL+ url[375]+")"
    }else if(window.innerWidth <= 768) {
      this.mainImageBg = "url(" +IMAGE_BASEURL+ url[768]+")"
    }else if(window.innerWidth <= 1024) {
      this.mainImageBg = "url(" +IMAGE_BASEURL+ url[1024]+")"
    }else if(window.innerWidth <= 1440){
      this.mainImageBg = "url(" +IMAGE_BASEURL+ url[1440]+")"
    }else{
      this.mainImageBg = "url(" + IMAGE_BASEURL+ url['default'] +")"
    }
  }
  componentWillUnmount() {
    setTimeout(() => {
      window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
      window.removeFaqItemtype();
      window.removeSchema();
    }, 100)
  }
  componentDidMount() {
    this.scrollUpFunction();
    this.homecmscontent();
    this.faqcontent();
   setTimeout(() => {
     //var schema2 = window.positionCreate(BREADCRUMB_SCHEMA['main']['schema2']['position'],BREADCRUMB_SCHEMA['main']['schema2']['url'],BREADCRUMB_SCHEMA['main']['schema2']['name']);
     var schemaObject = window.schemaObj(BREADCRUMB_SCHEMA['schema1'],BREADCRUMB_SCHEMA['main']['schema2'],BREADCRUMB_SCHEMA['main']['schema3']);
     window.setSchema(schemaObject);
   },100)
  }
  scrollUpFunction = () => {
    /**for scrolling up page */
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
    if (count === -1) {
      document.querySelector('.navbar-collapse').classList.remove('show');
      document.querySelector('header').classList.remove("header_collapse");
      document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
    }
  }
  faqcontent = () => {
    let self = this;
    axios.get(API_URL + '/faq/getHomepageFaq/' + LANGUAGE_CODE)
      .then((response) => {
        self.setState({ faqcontent: response.data.success.faq })
      })
      .catch((error) => {
      })
  }
  homecmscontent = () => {
    this.setState({ loading: 'true' })
    let self = this;
    let meta_title = "";
    let meta_description = "";
    let meta_keywords = "";
    let og_title = '';
    let og_description = '';
    let og_image = '';
    axios.get(API_URL + '/cms/getCmsByID/1/' + LANGUAGE_CODE)
      .then((response) => {
        meta_title = META_TITLE_HOME_PREPEND + response.data.success.cms.homecms[LANGUAGE_CODE]['title']
        meta_description = response.data.success.cms.homecms[LANGUAGE_CODE]['meta_description']
        meta_keywords = response.data.success.cms.homecms[LANGUAGE_CODE]['meta_keyword']
        og_title = META_TITLE_HOME_PREPEND + response.data.success.cms.homecms[LANGUAGE_CODE]['title'];
        og_description = response.data.success.cms.homecms[LANGUAGE_CODE]['meta_description'];
        og_image = META_FACEBOOK['og_image'];
        window.setMeta({ "description": meta_description, "keywords": meta_keywords });
        window.setOgTag(og_title, og_description, og_image, window.location.href)
        window.setDocumentTitle(meta_title)
        self.setState({ loading: 'false', homecontent: response.data.success.cms })
      })
      .catch((error) => {
        self.setState({ loading: 'false' })
      })
  }
  render() {
    let length = Object.keys(this.state.homecontent).length;
    let faqlength = Object.keys(this.state.faqcontent).length;
    let data = '';
    let faqdata = '';
    let faq = [];
    let blockcontent = [];
    let cardContent = [];
    if (faqlength !== 0) {
      faqdata = this.state.faqcontent;
      for (let i = 0; i < faqdata.length; i++) {
        faq.push(<div className="row" key={i}><div className="col-12 col-md-12 my-0 m-15px-tb">
          <div className="row mt-4 mt-md-5" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <div className="col-12 col-md-5 col-lg-4">
              <p className="text-dark font-weight-bold font-18 mb-0" itemProp="name">{faqdata[i]['faq_question']}</p>
            </div>
            <div className="col-12 col-md-7 col-lg-8" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <p className="font-weight-noraml text-dark font-18 mb-0" itemProp="text">{faqdata[i]['faq_answer']}</p>
            </div>
          </div>
        </div></div>)
      }
    }
    if (length !== 0) {
      data = this.state.homecontent;
      let dataNew = data.homecms[LANGUAGE_CODE]['sub_title'].split(".");
      var subTitle = [];
      for (var i = 0; i < (dataNew.length - 1); i++) {
        subTitle.push(
          <span className="d-inline-block">{dataNew[i].trim() + "."}&nbsp;</span>
        )
      }
    }
    if (data.column && data.column !== '' && data.column !== null) {
      let columnlength = data.column.length;
      let columnData = data.column;
      if (columnlength && columnlength !== 0) {
        for (let i = 0; i < columnlength; i++) {
          let count = i + 1;
          let alt_text = columnData[i]['column_block_culture'][LANGUAGE_CODE]['Image_alt_text'] ? columnData[i]['column_block_culture'][LANGUAGE_CODE]['Image_alt_text'] : 'mian-banner';
          if ((count % 2) !== 0) {
            blockcontent.push(
              <section className="content-right bg-white home-content-right" key={i}>
                <div className="container container-large">
                  <div className="row align-items-center">
                    <div className="col-md-6 text-center mxh-img">
                      <img src={columnData[i]['column_block']['Image']}  alt={alt_text} width="400" height="400" />
                    </div>
                    <div className="col-md-6 mt-2 mt-md-0">
                      <h2 dangerouslySetInnerHTML={{ __html: columnData[i]['column_block_culture'][LANGUAGE_CODE]['title'] }}></h2>
                      <p dangerouslySetInnerHTML={{ __html: columnData[i]['column_block_culture'][LANGUAGE_CODE]['content'] }}></p>
                    </div>
                  </div>
                </div>
              </section>
            )
          }
          else {
            blockcontent.push(
              <section className="content-left content-left-bg-img home-section home-content-left" key={i} style={{"--home-section" : this.homeSectionImageBg}}>
                <div className="container container-large">
                  <div className="row align-items-center full-screen p-100px-tb sm-p-30px-b pt-0">
                    <div className="col-md-6 mt-2 mt-md-0">
                      <h2 dangerouslySetInnerHTML={{ __html: columnData[i]['column_block_culture'][LANGUAGE_CODE]['title'] }}></h2>
                      <p dangerouslySetInnerHTML={{ __html: columnData[i]['column_block_culture'][LANGUAGE_CODE]['content'] }}></p>
                    </div>
                    <div className="col-md-6 text-center mxh-img order-class-sm">
                      <img src={columnData[i]['column_block']['Image']}  alt={alt_text} width="400" height="400" />
                    </div>
                  </div>
                </div>
              </section>
            )
          }
        }
      }
    }
    if (data['card'] && data['card'].length !== 0) {
      let cardLength = Object.keys(data['card']).length;
      for (let i = 1; i <= cardLength; i++) {
        cardContent.push(
          <div className="col-lg-4 col-md-6 col-sm-6 m-15px-tb">
            <div className="feature-box text-center">
              <div className="icon">
                <i className={data['card']['card'+ i]['card'+ i +'_icon']}></i>
              </div>
              <div className="feature-content mt-4">
                <h3 className="mb-4 text-dark font-weight-bold">{data['card']['card'+ i]['card'+ i +'_title']}</h3>
                <p dangerouslySetInnerHTML={{ __html: data['card']['card'+ i]['card'+ i +'_description'] }}></p>
              </div>
            </div>
          </div>
        )
      }
    }
    return (
      <main>
        {(this.state.loading === 'true') ?
          BUBBLE_LOADING
          : null
        }
        {(length !== 0) ?
          //  Main  
          <div>
            {/*   Home Banner  */}
            <section id="home" className="theme-main-homepage home-section theme-main home-banner home-banner-changed theme-main-changed">
            <div className="home-effect-bg home-effect-bg-img d-md-block" style={{"--main-img" : this.mainImageBg}}>
            </div>
              <div className="container container-large">
                <div className="row p-100px-tb p-100px-tb-changed sm-p-30px-b pb-0">
                  <div className={(window.innerWidth >= 1500)?"col-12 col-md-12 col-lg-3 col-xl-3 md-m-30px-t col-lg-banner-text text-center em_left":"col-12 col-md-12 col-lg-3 col-xl-3 md-m-30px-t text-right em_left"}>
                    <img src={(data['header_image']) ? data['header_image'] : ""}  alt={data['header_image_alt_text'] ? data['header_image_alt_text'] : 'mian-banner'} width="400" height="400" />
                  </div>
                  <div className="col-12 col-lg-6 col-xl-6 text-center em_center em-center-padding">
                    <h1 className="text-white font-weight-bold mb-0 heading-padding">{data.homecms[LANGUAGE_CODE]['title']}</h1>
                    <h4 className="text-white mt-4">{subTitle}</h4>
                    <div className="btn-bar">
                      <Link className="btn btn-clr text-white text-uppercase font-18" to={data.homecms['read_more_btn_link']} title={data.homecms[LANGUAGE_CODE]['read_more_btn_text']}>{data.homecms[LANGUAGE_CODE]['read_more_btn_text']}</Link>
                    </div>
                  </div>
                  <div className={(window.innerWidth >= 1500)?"col-12 col-lg-3 col-xl-3 md-m-30px-t col-lg-banner-text text-center em_right":"col-12 col-lg-3 col-xl-3 md-m-30px-t text-right em_right"}>
                    <img src={(data['header_image1']) ? data['header_image1'] : ""}  alt={data['header_image1_alt_text'] ? data['header_image1_alt_text'] : 'mian-banner'} width="400" height="400" />
                  </div>
                </div>{/*   row    */}
              </div>
            </section>
            {/* Block content start*/}
            { blockcontent }
            {/* Block content end */}
            {/*   section-community    */}
            <section id="community" className="section-community card-bg-gray mt-0 mt-md-5 mt-lg-5">
              <div className="container">
                <div className="row justify-content-center mb-0 m-45px-b md-m-25px-b">
                  <div className="col-12 text-center">
                    <div className="section-title text-center">
                      <h2 className="text-dark font-weight-bold">{data.homecms[LANGUAGE_CODE]['cards_main_title']}</h2>
                    </div>
                  </div>{/*   col    */}
                </div>{/*   row    */}
                <div className="row justify-content-center text-center">
                  { cardContent }
                </div>{/*   row    */}
              </div>{/*   container    */}
            </section>
            {/*   faq section    */}
            <section id="" className="section faq-question bg-white">
              <div className="container">
                <div className="row justify-content-center mb-0 mb-md-0 m-45px-b md-m-25px-b">
                  <div className="col-12 text-center">
                    <div className="section-title text-center">
                      <h2 className="text-dark font-weight-bold">{data.homecms[LANGUAGE_CODE]['faq_section_title']}</h2>
                    </div>
                  </div>{/*   col    */}
                </div>{/*   row    */}
                {(faqlength !== 0) ?
                  faq
                  : <div className="row justify-content-center"><h1>{HOMEPAGE_CONSTANT[LANGUAGE_CODE]['data_not_found']}</h1></div>
                }
              </div>{/*   container    */}
            </section>
            {/* //   sign-up-now-section    */}
            <section className="analysiere theme-main">
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-10 col-lg-8">
                    <div className="section-title text-center">
                      <h2 className="pb-2 text-white font-weight-bold">{data.homecms[LANGUAGE_CODE]['blue_text']}</h2>
                      <Link to={data.homecms['required_btn_link']} className="btn btn-clr text-uppercase text-white font-18" title={data.homecms[LANGUAGE_CODE]['required_btn_text']}>{data.homecms[LANGUAGE_CODE]['required_btn_text']}</Link>
                    </div>
                  </div>{/*   col    */}
                </div>
              </div>
            </section>
          </div>
          : <section className="page-title-section theme-main d-flex align-items-center">
            <div className="container">
              <div className="page-title">
                <h1 className="text-white font-weight-bold mb-0">{HOMEPAGE_CONSTANT[LANGUAGE_CODE]['data_not_found']}</h1>
              </div>
            </div>
          </section>
        }
      </main>
    );
  }
}

export default Main;

