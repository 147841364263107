const initialState = {
  surveyData: []
};
function survey(state = initialState, action) {
  if (action.type === "SURVEY_DATA_LOADED") {
    return Object.assign({}, state, {
      surveyData: state.surveyData.concat(action.payload)
    });
  }
  return state;
}
export default survey;