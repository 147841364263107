import React from "react";
import ReactDOM from "react-dom";
import {API_URL, LANGUAGE_CODE, BASEURL} from '../../constants/DotEnv'
import axios from 'axios'
import {PAYMENT_CONSTANTS} from "../../constants/FrontEndConstant";

//const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';


class PaypalSubscription extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      message: ""
    };
    this.paypalRef = React.createRef();
  }

  /* handlePaypal = () => {
    const plan_id = this.props.product_id
    const IsSubscriptioncancle = this.props.IsSubscriptioncancle;
    let start_date = null;
    let isSchedule = null;
    let isTrialSubscription = 0;
    const plan_end_date = this.props.end_date;
    if(IsSubscriptioncancle == 1){
      start_date = `${plan_end_date.slice(0,10)}T${plan_end_date.slice(11)}Z`;
      isSchedule = 1;
    }
    if(document.getElementById("paypal-render")!=null) {
      document.getElementById("paypal-render").innerHTML = '';
   }
    if(!IsSubscriptioncancle && !plan_end_date){
      const trialDate = new Date();
      const futureDay = trialDate.getDate() + 14;
      trialDate.setDate(futureDay);
      start_date = trialDate.toISOString();
      isTrialSubscription = 1;
      isSchedule = 1;
    }
       window.paypal.Buttons({
        style:{
          color:  'silver',
          label:  'paypal'
        },
        createSubscription: function(data, actions) {
          if(start_date){
            return actions.subscription.create({
              intent:"subscription",
              plan_id: plan_id,
              "start_time": start_date
            });
          }else{
            return actions.subscription.create({
              intent:"subscription",
              plan_id: plan_id,
            });
          }
        },
        onApprove: async (data, actions) => {
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        var billing_info = JSON.parse(localStorage.getItem('billing_info'));
        let apitoken = storeitem.token;
        let userId = storeitem.id;
        const headers = {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + apitoken
        }
        const planType = this.props.planType;
        let finalDate = new Date().toLocaleString().replaceAll('/','-').replace(',','');
        await axios.post(API_URL + '/payment/paypal/' + userId+ '?uId=' + userId ,
        { first_name:this.props.first_name, last_name:this.props.last_name, price: billing_info.price.replace(',','.'), postalcode_id:this.props.postalcode_id, plan_type: planType, start_date : finalDate, transaction_id :data.subscriptionID, organization: billing_info.organization, telephone: storeitem.telephone, street: billing_info.street, house_number: billing_info.house_number, isSubscription: 1, isSchedule: isSchedule, isTrialSubscription: isTrialSubscription},
        { headers: headers })
        .then((res)=>{ localStorage.setItem('payment_status',1);
        localStorage.setItem('plan_type', planType);
        localStorage.setItem('transaction_id',data.subscriptionID);
        // let redirectURL = localStorage.getItem('redirectURL')!=null ? localStorage.getItem('redirectURL'):BASEURL + "mein-bereich";
        // window.location = redirectURL;
      })
          .catch(err => console.log(err))
        },
      })
      .render(this.paypalRef.current);
  }*/

  async onApprove(data,actions) {

    const plan_id = this.props.product_id;
    const IsSubscriptioncancle = this.props.IsSubscriptioncancle;
    let start_date = null;
    let isSchedule = null;
    let isTrialSubscription = 0;
    const plan_end_date = this.props.end_date;
    if(IsSubscriptioncancle == 1){
      start_date = `${plan_end_date.slice(0,10)}T${plan_end_date.slice(11)}Z`;
      isSchedule = 1;
    }
    if(document.getElementById("paypal-render")!=null) {
      document.getElementById("paypal-render").innerHTML = '';
    }
    if(!IsSubscriptioncancle && !plan_end_date){
      const trialDate = new Date();
      const futureDay = trialDate.getDate() + 14;
      trialDate.setDate(futureDay);
      start_date = trialDate.toISOString();
      isTrialSubscription = 1;
      isSchedule = 1;
    }

    let storeitem = JSON.parse(localStorage.getItem('userdata'));
    let billing_info = JSON.parse(localStorage.getItem('billing_info'));
    
    let apitoken = storeitem.token;
    let userId = storeitem.id;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + apitoken
    }
    const planType = this.props.planType;
    let finalDate = new Date().toLocaleString().replaceAll('/','-').replace(',','');
    localStorage.removeItem("clientSecret")
    await axios.post(API_URL + '/payment/paypal/' + userId+ '?uId=' + userId ,
    { first_name:this.props.first_name, last_name:this.props.last_name, price: billing_info.price.replace(',','.'), postalcode_id:this.props.postalcode_id, plan_type: planType, start_date : finalDate, transaction_id :data.subscriptionID, organization: billing_info.organization, telephone: storeitem.telephone, street: billing_info.street, house_number: billing_info.house_number, isSubscription: 1, isSchedule: isSchedule, isTrialSubscription: isTrialSubscription,tax_id: billing_info.tax_id && billing_info.tax_id.length > 0 ? billing_info.tax_id: null},
    { headers: headers })
    .then((res)=>{ localStorage.setItem('payment_status',1);
    storeitem['plan_type'] = planType.toString();
    localStorage.setItem("userdata",JSON.stringify(storeitem));
    localStorage.setItem('plan_type', planType);
    localStorage.setItem('transaction_id',data.subscriptionID);
    let redirectURL = localStorage.getItem('redirectURL')!=null ? localStorage.getItem('redirectURL'):BASEURL + "mein-bereich";
    window.location = redirectURL;
  })
      .catch(err => console.log(err));
  }
  createSubscription(data,actions) {
  let billing_info = JSON.parse(localStorage.getItem('billing_info'));
  localStorage.setItem('method', 'PayPal');
  if (billing_info['payment_method'] !== 'PayPal') {
    billing_info['payment_method'] = 'PayPal'
    localStorage.setItem('billing_info',JSON.stringify(billing_info));
  }

  //this.setState({empty_field_error:true});
  // if(this.state.subscription == null)
  //   document.getElementById('package').scrollIntoView();
  // else
    //   document.getElementById('invoice_address').scrollIntoView();
  const isTaxidValid = this.props.isTaxIDValid()

  if(billing_info.first_name == '' || billing_info.last_name == '' || billing_info.organization == null || billing_info.street == null|| billing_info.house_number == null|| billing_info.postalcode_id == null || !isTaxidValid) {
      let formfield_error_list = document.getElementsByClassName('billing_info_error');
      for(let i=0; i< formfield_error_list.length; i++){
        formfield_error_list[i].classList.remove('d-none');
      }
      if(this.state.error)
      {
        let list = document.getElementsByClassName('form-control hs-input ');
        for(let i=0; i< list.length; i++){
          list[i].classList.add('field-highlight');
        }
      }
      return false;
  }else {
      const plan_id = this.props.product_id;
      const IsSubscriptioncancle = this.props.IsSubscriptioncancle;
      let start_date = null;
      let isSchedule = null;
      let isTrialSubscription = 0;
      const plan_end_date = this.props.end_date;
      if(IsSubscriptioncancle == 1){
        start_date = `${plan_end_date.slice(0,10)}T${plan_end_date.slice(11)}Z`;
        isSchedule = 1;
      }
      if(document.getElementById("paypal-render")!=null) {
        document.getElementById("paypal-render").innerHTML = '';
      }
      if(!IsSubscriptioncancle && !plan_end_date){
        const trialDate = new Date();
        const futureDay = trialDate.getDate() + 14;
        trialDate.setDate(futureDay);
        start_date = trialDate.toISOString();
        isTrialSubscription = 1;
        isSchedule = 1;
      }

      if(start_date){
        return actions.subscription.create({
          /* Creates the schedule subscription  */
          intent:"subscription",
          plan_id: plan_id,
          "start_time": start_date
        });
      }else{
        return actions.subscription.create({
          /* Creates the subscription */
          intent:"subscription",
          plan_id: plan_id,
        });
      }
   }
  }
    render() {
  //  this.handlePaypal();
    if (this.state.error) {
      return <div className="text-danger">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["payment_error_msg"]}</div>
    }
    return (
      <PayPalScriptProvider options={{'client-id': process.env.REACT_APP_PAYPAL_KEY,components: "buttons",vault: "true"}}>
      <PayPalButtons style={{  color:  'silver', label:  'paypal',tagline: false,layout: 'horizontal'}}
      createSubscription={(data,actions)=>this.createSubscription(data,actions)}
    // createOrder={(data, actions) => this.createOrder(data,actions)}
      onApprove={(data, actions) => this.onApprove(data,actions)}/>
      </PayPalScriptProvider>

  );
    //return <div className="w-100" ref={this.paypalRef} id="paypal-render" />
  }
}

export default PaypalSubscription;