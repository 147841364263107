import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BUBBLE_LOADING, META_FACEBOOK, BREADCRUMB_SCHEMA } from '../../constants/FrontEndConstant';
import { API_URL, LANGUAGE_CODE, META_TITLE_APPEND } from '../../constants/DotEnv';
import FunctionHeader from '../subPageHeader/SubPageHeader';
class Functions extends React.Component {
    constructor() {
        super();
        this.state = {
            functioncontent: {},
            loading: 'false',
            datalength: '',
            title: '',
            sub_title: ''
        }
    }
    componentWillUnmount() {
        setTimeout(() => {
            window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
            window.removeSchema();
        }, 100)
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.functioncmscontent();
        setTimeout(() => {
            var schema2 = window.positionCreate(BREADCRUMB_SCHEMA['function']['schema2']['position'], BREADCRUMB_SCHEMA['function']['schema2']['url'], BREADCRUMB_SCHEMA['function']['schema2']['name']);
            var schemaObject = window.schemaObj(BREADCRUMB_SCHEMA['schema1'], schema2, BREADCRUMB_SCHEMA['function']['schema3']);
            window.setSchema(schemaObject);
        }, 100)
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
        if (count === -1) {
            document.querySelector('.navbar-collapse').classList.remove('show');
            document.querySelector('header').classList.remove("header_collapse");
            document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
        }
    }
    functioncmscontent = async () => {
        this.setState({ loading: 'true' })
        let self = this;
        let functioncontent = '';
        let datalength = '';
        let title = '';
        let sub_title = '';
        let meta_title = '';
        let meta_description = '';
        let meta_keywords = '';
        let og_title = '';
        let og_description = '';
        let og_image = '';
        await axios.get(API_URL + '/cms/getCmsByID/3/' + LANGUAGE_CODE)
            .then((response) => {
                functioncontent = response.data.success.cms;
                datalength = Object.keys(functioncontent).length;
                title = functioncontent['functioncms'][LANGUAGE_CODE]['title'];
                sub_title = functioncontent['functioncms'][LANGUAGE_CODE]['sub_title'];
                meta_title = functioncontent['functioncms'][LANGUAGE_CODE]['title'] + META_TITLE_APPEND;
                meta_description = functioncontent['functioncms'][LANGUAGE_CODE]['meta_description']
                meta_keywords = functioncontent['functioncms'][LANGUAGE_CODE]['meta_keyword']
                og_title = functioncontent['functioncms'][LANGUAGE_CODE]['title'] + META_TITLE_APPEND;
                og_description = functioncontent['functioncms'][LANGUAGE_CODE]['meta_description'];
                og_image = META_FACEBOOK['og_image'];
                window.setMeta({ "description": meta_description, "keywords": meta_keywords });
                window.setOgTag(og_title, og_description, og_image, window.location.href)
                window.setDocumentTitle(meta_title)
                self.setState({ loading: 'false', functioncontent, datalength, title, sub_title })
            })
            .catch((error) => {
                self.setState({ loading: 'false' })
            })
    }
    render() {
        let functiondata = '';
        let blockcontentlength = '';
        let blockcontent = [];
        if (this.state.datalength !== '') {
            functiondata = this.state.functioncontent;
            blockcontentlength = this.state.functioncontent.column.length;
            for (let i = 0; i < blockcontentlength; i++) {
                let count = i + 1;
                let alt_text = functiondata.column[i]['column_block_culture'][LANGUAGE_CODE]['Image_alt_text'] ? functiondata.column[i]['column_block_culture'][LANGUAGE_CODE]['Image_alt_text']  : "wenigen-img";
                if ((count % 2) === 0) {
                    blockcontent.push(
                        <section className="content-left bg-gray" key={i}>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-6 mt-5 mt-md-0">
                                        <h2 dangerouslySetInnerHTML={{ __html: functiondata.column[i]['column_block_culture'][LANGUAGE_CODE]['title'] }}></h2>
                                        <p dangerouslySetInnerHTML={{ __html: functiondata.column[i]['column_block_culture'][LANGUAGE_CODE]['content'] }}></p>
                                    </div>
                                    <div className="col-md-6 text-center mxh-img order-class-sm">
                                        <img src={functiondata.column[i]['column_block']['Image']} alt={alt_text} width="400" height="400" />
                                    </div>

                                </div>
                            </div>
                        </section>
                    )
                }
                else {
                    blockcontent.push(
                        <section className="content-right bg-white" key={i}>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-6 text-center mxh-img ">
                                        <img src={functiondata.column[i]['column_block']['Image']} alt={alt_text} width="400" height="400" />
                                    </div>
                                    <div className="col-md-6 mt-5 mt-md-0">
                                        <h2 dangerouslySetInnerHTML={{ __html: functiondata.column[i]['column_block_culture'][LANGUAGE_CODE]['title'] }}></h2>
                                        <p dangerouslySetInnerHTML={{ __html: functiondata.column[i]['column_block_culture'][LANGUAGE_CODE]['content'] }}></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                }
            }
        }
        return (
            // {/*<!-- Main -->*/}
            <main>
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {/*<!-- banner text --->*/}
                <FunctionHeader data={functiondata} title={this.state.title} sub_title={this.state.sub_title} dataLength={this.state.datalength} />
                {/*<!--banner next -->*/}
                {(this.state.datalength > 0 && this.state.loading === 'false') ?
                    <div>
                        <section className="umfrage-in-erstllen footer-dark">
                            <div className="container">
                                <div className="row text-center justify-content-center align-items-center">
                                    <div className="col-md-4">
                                        <p dangerouslySetInnerHTML={{ __html: functiondata['functioncms'][LANGUAGE_CODE]['dark_blue_title1'] }}></p>
                                    </div>
                                    <div className="col-md-4">
                                        <p dangerouslySetInnerHTML={{ __html: functiondata['functioncms'][LANGUAGE_CODE]['dark_blue_title2'] }}></p>
                                    </div>
                                    <div className="col-md-4">
                                        <p dangerouslySetInnerHTML={{ __html: functiondata['functioncms'][LANGUAGE_CODE]['dark_blue_title3'] }}></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* Block content start*/}
                        {blockcontent}
                        {/* Block content end */}
                        {/*<!--- start now -->*/}
                        <section className="analysiere theme-main">
                            <div className="container">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-md-10 col-lg-8">
                                        <div className="section-title text-center">
                                            <h2>{functiondata['functioncms'][LANGUAGE_CODE]['blue_text']}</h2>
                                            <Link to={functiondata['functioncms']['required_btn_link']} className="btn btn-clr text-uppercase text-white font-18" title={functiondata['functioncms'][LANGUAGE_CODE]['required_btn_text']}>{functiondata['functioncms'][LANGUAGE_CODE]['required_btn_text']}</Link>
                                        </div>
                                    </div> {/*<!-- col -->*/}
                                </div>
                            </div>
                        </section>
                        {/*<!--- start now end -->*/}
                    </div>
                    : null
                }
            </main>
            // <!--main end --->
        );
    }
}

export default Functions;

