import React from 'react';
import { Link } from "react-router-dom";
import { FOOTER_LINKS, URL_CONSTANT, HEADER_LINKS } from '../../constants/FrontEndConstant';
import { LANGUAGE_CODE, INSTAGEAM_URL, FACEBOOK_URL, UMFRAG_RATGEBER_URL,TIKTOK_URL} from '../../constants/DotEnv';
class Footer extends React.Component {
  componentDidMount() {
    this.scrollUpFunction();
  }
  scrollUpFunction = () => {
    /**for scrolling up page */
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
    if (count === -1) {
      document.querySelector('.navbar-collapse').classList.remove('show');
      document.querySelector('header').classList.remove("header_collapse");
      document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
    }
  }
  changeActiveTab = (activeTab) => {
    localStorage.setItem("myprofileactivetab", activeTab);
    this.scrollUpFunction();
  }
  render() {
    var currentYear = new Date().getFullYear();
    return (
      // <!-- Footer -->
      <footer className="footer footer-dark">
        <section className="footer-section text-center text-md-left">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-3 col-lg-3 sm-m-15px-tb footer-in-xs">
                <h4 className="font-alt text-white h6">{FOOTER_LINKS[LANGUAGE_CODE]['empirio']}</h4>
                <ul className="fot-link">
                  <li><Link to="/" className="text-white" onClick={this.scrollUpFunction} title={FOOTER_LINKS[LANGUAGE_CODE]['Umfrage_Tool']}>{FOOTER_LINKS[LANGUAGE_CODE]['Umfrage_Tool']}</Link></li>
                  <li><Link to="/funktionen" className="text-white" onClick={this.scrollUpFunction} title={FOOTER_LINKS[LANGUAGE_CODE]['features']}>{FOOTER_LINKS[LANGUAGE_CODE]['features']}</Link></li>                  
                  <li><Link to="/umfragen" className="text-white" onClick={this.scrollUpFunction} title={FOOTER_LINKS[LANGUAGE_CODE]['survey']}>{FOOTER_LINKS[LANGUAGE_CODE]['survey']}</Link></li>
                  <li><Link to="/statistiken" className="text-white" onClick={this.scrollUpFunction} title={FOOTER_LINKS[LANGUAGE_CODE]['Statistiken']}>{FOOTER_LINKS[LANGUAGE_CODE]['Statistiken']}</Link></li>
                </ul>
              </div>{/*<!-- col -->*/}
              <div className="col-12 col-sm-6 col-md-3 col-lg-3 sm-m-15px-tb footer-in-xs">
                <h4 className="font-alt text-white h6">{HEADER_LINKS[LANGUAGE_CODE]['solution']}</h4>
                <ul className="fot-link">
                  <li><Link to="/umfrage-tool" onClick={this.scrollUpFunction} className="text-white" title={FOOTER_LINKS[LANGUAGE_CODE]['for_student']}>{FOOTER_LINKS[LANGUAGE_CODE]['for_student']}</Link></li>
                  <li><a href="/umfrage-unternehmen" className="text-white" title={FOOTER_LINKS[LANGUAGE_CODE]['for_companies']}>{FOOTER_LINKS[LANGUAGE_CODE]['for_companies']}</a></li>
                  <li><a href="/umfrage-hochschulen" className="text-white" title={FOOTER_LINKS[LANGUAGE_CODE]['for_colleges']}>{FOOTER_LINKS[LANGUAGE_CODE]['for_colleges']}</a></li>
                  <li><a href="/umfrage-schulen" className="text-white" title={FOOTER_LINKS[LANGUAGE_CODE]['for_schools']}>{FOOTER_LINKS[LANGUAGE_CODE]['for_schools']}</a></li>
                </ul>
              </div> {/*<!-- col -->*/}
              <div className="col-12 col-sm-6 col-md-3 col-lg-3 sm-m-15px-tb footer-in-xs">
                <h4 className="font-alt text-white h6">{FOOTER_LINKS[LANGUAGE_CODE]['info_help']}</h4>
                <ul className="fot-link">
                  <li><Link to="/ueber-Uns" onClick={this.scrollUpFunction} className="text-white" title={FOOTER_LINKS[LANGUAGE_CODE]['about_us']}>{FOOTER_LINKS[LANGUAGE_CODE]['about_us']}</Link></li>
                  <li><Link to="/faq" onClick={this.scrollUpFunction} className="text-white" title={FOOTER_LINKS[LANGUAGE_CODE]['faq']}>{FOOTER_LINKS[LANGUAGE_CODE]['faq']}</Link></li>
                  <li><Link to="/empiriowissen" className="text-white" onClick={this.scrollUpFunction} title={FOOTER_LINKS[LANGUAGE_CODE]['empirio_knowledge']}>{FOOTER_LINKS[LANGUAGE_CODE]['empirio_knowledge']}</Link></li>
                  <li><Link to="/lexikon" className="text-white" onClick={this.scrollUpFunction} title={FOOTER_LINKS[LANGUAGE_CODE]['lexikon']}>{FOOTER_LINKS[LANGUAGE_CODE]['lexikon']}</Link></li>
                </ul>
              </div>
              <div className="col-12 col-sm-6 col-md-3 col-lg-3 sm-m-15px-tb footer-in-xs">
                <h4 className="font-alt text-white h6">{FOOTER_LINKS[LANGUAGE_CODE]['social']}</h4>
                <ul className="fot-link">
                  {// eslint-disable-next-line
                  }<li><a href={UMFRAG_RATGEBER_URL} target="_blank" onClick={this.scrollUpFunction} className="text-white" title={FOOTER_LINKS[LANGUAGE_CODE]['Umfrag_Ratgeber']} rel="noopener">{FOOTER_LINKS[LANGUAGE_CODE]['Umfrag_Ratgeber']}</a></li>              
                  <li><a href={TIKTOK_URL} target="_blank" onClick={this.scrollUpFunction} className="text-white" title={FOOTER_LINKS[LANGUAGE_CODE]['TikTok']} rel="noopener">{FOOTER_LINKS[LANGUAGE_CODE]['TikTok']}</a></li>
                  {// eslint-disable-next-line
                  }<li><a href={FACEBOOK_URL} target="_blank" onClick={this.scrollUpFunction} className="text-white" title={FOOTER_LINKS[LANGUAGE_CODE]['facebook']} rel="noopener">{FOOTER_LINKS[LANGUAGE_CODE]['facebook']}</a></li>
                  <li><a href={INSTAGEAM_URL} target="_blank" onClick={this.scrollUpFunction} className="text-white" title={FOOTER_LINKS[LANGUAGE_CODE]['instagram']} rel="noopener">{FOOTER_LINKS[LANGUAGE_CODE]['instagram']}</a></li>
                </ul>
              </div> {/*<!-- col -->*/}
            </div>
            <div className="footer-copy">
              <div className="row text-center text-md-left">
                <div className="col-12 col-sm-4 footer-left-content">
                  {/* ©  */}
                  <p className="text-white"><i class="far fa-copyright"></i>{" " + currentYear + " " + FOOTER_LINKS[LANGUAGE_CODE]['copyrights']}</p>
                </div>{/*<!-- col -->*/}
                <div className="col-12 col-sm-8 footer-right-content">
                  <ul className="bottom-footer-link list-unstyled d-inline-block mb-0 mt-3 mt-sm-0">
                    <li className="d-inline-block"><Link to="/agb" onClick={this.scrollUpFunction} className="text-white" title={FOOTER_LINKS[LANGUAGE_CODE]['conditions']}>{FOOTER_LINKS[LANGUAGE_CODE]['conditions']}</Link></li>
                    <li className="d-inline-block"><Link to="/impressum" onClick={this.scrollUpFunction} className="text-white" title={FOOTER_LINKS[LANGUAGE_CODE]['imprint']}>{FOOTER_LINKS[LANGUAGE_CODE]['imprint']}</Link></li>
                    <li className="d-inline-block"><Link to="/datenschutz" onClick={this.scrollUpFunction} className="text-white" title={FOOTER_LINKS[LANGUAGE_CODE]['data_protection']}>{FOOTER_LINKS[LANGUAGE_CODE]['data_protection']}</Link></li>
                  </ul>
                </div>
              </div> {/*<!-- row -->*/}
            </div> {/*<!-- footer-copy -->*/}
          </div> {/*<!-- container -->*/}
        </section>
      </footer>
    );
  }
}
export default Footer;