import React, { Fragment } from 'react';
import Redirect from 'react-router-dom/Redirect';
import { SURVEY_CREATION_CONSTANT, BUBBLE_LOADING } from "../../../../constants/FrontEndConstant";
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
class SurveyWelcomeText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 'false',
            redirect: 'false'
        }
    }
    render() {
        var s = SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_welcome_text_content'];
        var htmlObject = document.createElement('div');
        htmlObject.innerHTML = s;
        return (
            <Fragment>
                {(this.state.redirect === 'true') ?
                    <Redirect to="/anmelden"></Redirect>
                    : null
                }
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {(this.props.message) ?
                    <p>{this.props.message}</p>
                    : null
                }
                <h2 className={"text-dark h3 custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} dangerouslySetInnerHTML={{ __html: SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_welcome_text_content'] }} />
            </Fragment>
        );
    }
}
export default SurveyWelcomeText;