import React, { Fragment } from 'react';
import { SURVEY_CREATION_CONSTANT } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
class SurveyElementRatingMoreContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            optionCount: 2,
            optionLimit: 10
        };
    }
    section = (allOption, onChangeFunction, value) => {
        return (
            <div>
                {allOption}
                {(this.props.optionScaleCount < this.props.optionScaleLimit) ?
                    <div className="add-answer mt-3 mb-4 text-center">
                        {// eslint-disable-next-line
                        }<a href="#" onClick={this.props.onChange} name="sclaeaddoption" title="Add-answer"><i className="ti-plus icon-m border-radius create-plus"></i></a>
                    </div>
                    : null}
            </div>
        );
    }
    render() {
        let value = this.props.value;
        let onChangeFunction = this.props.onChange;
        let allOption = [];
        if (value.scale_option_text) {
            if (value.scale_option_text.length) {
                // eslint-disable-next-line
                value.scale_option_text.map((optiondata, index) => {
                    let checkFocus;
                    if(optiondata.scale_text){
                        if(optiondata.scale_text !== "free_text_condition"){
                            checkFocus = true;
                        }
                    }
                    allOption.push(
                        <div className={(index === 0) ?(checkFocus)? "form-group custom-label-size hs-form-field d-flex align-items-center space-between mt-4 mt-md-4 del-btn focus":"form-group custom-label-size hs-form-field d-flex align-items-center space-between mt-3 mt-md-3 del-btn " : (checkFocus)?"form-group custom-label-size hs-form-field d-flex align-items-center space-between del-btn focus":"form-group custom-label-size hs-form-field d-flex align-items-center space-between del-btn "} id={"scale" + index} key={index}>
                            <input type="hidden" name="id" value={(optiondata.id) ? optiondata.id : ""} onChange={onChangeFunction} className="element-second" />
                            <input type="text" name={"scale_text"} value={optiondata.scale_text} onChange={onChangeFunction} className="form-control hs-input minwi element-second" />
                            <label htmlFor={SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_rating_scale_option_lable']}>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_rating_scale_option_lable']}</label>
                            {value.scale_option_text.length > 1 ?
                                <button className="btn p-0 icon-for-image" type="button" name="scaleremoveoption" id={optiondata.id} onClick={onChangeFunction} data-value={index}><i className="fas fa-trash"></i></button>
                                : <div className="icon-for-image"></div>}
                        </div>);
                })
            }
        }
        const elements = < div>  {this.section(allOption, onChangeFunction, value)} </div >;
        return (
            <Fragment>
                {(value.scale_type !== 5) ?
                    <p>{this.props.message}</p>
                    : null
                }
                {elements}
            </Fragment>
        );
    }
}
export default SurveyElementRatingMoreContent;