import React from 'react';
import { DEMOGRAPHIC_PARTICIPATION_CONSTANT, SURVEY_PARTICIPATION } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
class DemographicDropdownSelection extends React.Component {
    render() {
        let data = this.props.demographicdata;
        let dropdownoption = this.props.demographicdropdown;
        let options = '';
        let finaloption = '';
        var name
        var value = "";
        if (data) {
            var attribute_id = data.id;
            // eslint-disable-next-line
            DEMOGRAPHIC_PARTICIPATION_CONSTANT['EN'].map((title, index) => {
                if (parseInt(attribute_id) === parseInt(title.id)) {
                    name = title.title
                    // eslint-disable-next-line
                    Object.keys(this.props.questionanswer).map((ans, index) => {
                        // eslint-disable-next-line
                        this.props.questionanswer.map((key, index) => {
                            // eslint-disable-next-line
                            Object.keys(key).map((finalkey) => {
                                if (finalkey === name) {
                                    value = key[finalkey]
                                }
                            })
                        })
                    })
                }
            })
            if (dropdownoption) {
                if (attribute_id === 4) {
                    options = dropdownoption.income;
                    finaloption = options.map((option, index) => {
                        return (<option value={option.id} key={index}>{option.income_range}</option>)
                    })
                }
                else if (attribute_id === 5) {
                    options = dropdownoption.employment;
                    finaloption = options.map((option, index) => {
                        return (<option value={option.id} key={index}>{option.employment_name}</option>)
                    })
                }
                else if (attribute_id === 6) {
                    options = dropdownoption.education;
                    finaloption = options.map((option, index) => {
                        return (<option value={option.id} key={index}>{option.education_name}</option>)
                    })
                }
                else if (attribute_id === 7) {
                    options = dropdownoption.MaritalStatus;
                    finaloption = options.map((option, index) => {
                        return (<option value={option.id} key={index}>{option.title}</option>)
                    })
                }
                else {
                    options = dropdownoption.nationality;
                    finaloption = options.map((option, index) => {
                        return (<option value={option.id} key={index}>{option.nationality}</option>)
                    })
                }
            }
        }
        return (<div key={this.props.demographicdata.id} className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
            <div className="question-heading">
                <h3 className="text-dark font-weight-bold survey-title">{data.question}</h3>
                {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
            </div>
            <div className="continue-btn survey-form-detail">
                <div className="input-group-prepend">
                    <label className="input-group-text sr-only" htmlFor="categories"></label>
                </div>
                <select className="custom-select dropdown-type-demo" name={name} id="categories" onChange={this.props.onChange} value={(value) ? value : ""}>
                    <option value="">{SURVEY_PARTICIPATION[LANGUAGE_CODE]['demogrphic_dropdown_selection_default_option']}</option>
                    {finaloption}
                </select>
            </div>
        </div>)
    }
}
export default DemographicDropdownSelection;