import React from "react";
import Slider from '@material-ui/core/Slider';

class SurveyRatingRangeQuestion extends React.Component {

  handleChange = (event, newValue,id) => {
    var surveydetail = JSON.parse(localStorage.getItem("surveydetail_" + this.props.questiondata.survey_id))
    this.props.onLoad(event,newValue,id);
    var survey_color;
    if(surveydetail.survey_color) survey_color = surveydetail.survey_color
    else survey_color = "#00b3f4"
    document.getElementsByClassName("MuiSlider-root")[0].style.color = survey_color;
  };

  render() {
    let data = this.props.questiondata;
    let option = [];

    if (data.scale_type === 4) {
      option.push(
        <div key={1} className="text-center  mt-5" >
        <div className="box-shadow box-shadow-hover pl-3 pr-3 pt-5 pb-4">
        <div className="MuiSlider-main">
            <Slider
              className="selected-probability"
              key = {data.question_id}
              value={
                this.props.questionanswer.free_text_answer === undefined
                    ? this.props.questionanswer[data.question_id] || this.props.questionanswer[data.question_id] === 0
                      ? this.props.questionanswer[data.question_id]
                      : 50
                    : this.props.questionanswer.free_text_answer !== undefined
                      ? parseInt(this.props.questionanswer.free_text_answer)
                      : 50
              }
              valueLabelDisplay="auto"
              step={1}
              onChange={(event,newValue)=>this.handleChange(event,newValue,data.question_id)}
            />
            <div className="MuiSlider-per">  <span className="d-inline-block font-weight-bold">0%</span> <span className="d-inline-block font-weight-bold">100%</span></div>

         </div> </div></div>
      );
    }
    return (
      // range-slider
      <div key={this.props.questiondata.question_id} className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
        <div className="text-center">
          <div className="question-heading text-left">
            <h3 className="text-dark font-weight-bold text-break survey-title">
              {data.question_text}
            </h3>
            {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
          </div>
          {option}
        </div>
      </div>
    );
  }
}
export default SurveyRatingRangeQuestion;
