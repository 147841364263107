import React from "react";
import Header from './header/MainHeader'
import Footer from './footer/Footer'
import Home from './home/Main'
import Login from './login/Login'
import PageNotFound from './error/pageNotFound'
import NsuRegistration from './registration/NsuRegistration'
import ForgotPassword from './forgotPassword/ForgotPassword'
import ResetPassword from './resetPassword/ResetPassword'
import { UrlConstant, API_URL, LANGUAGE_CODE, IMAGE_BASEURL, MONTHLY_AMOUNT, ANNUAL_AMOUNT } from '../constants/DotEnv'
import MyProfile from './myProfile/MyProfile'
import Faq from './faq/Faq'
import AboutUs from './aboutUs/AboutUs'
import Functions from './function/Functions'
import Impressum from './impressum/Impressum'
import Agb from './agb/Agb'
import Datenschutz from './datenschutz/Datenschutz'
import SurveyList from './survey/surveyList/SurveyList'
import SurveyDetail from './survey/surveyDetail/SurveyDetail'
import SurveyPublishResultList from './survey/surveyPublishResultList/SurveyPublishResultList'
import SurveyPublishResultDetail from './survey/surveyPublishResultDetail/SurveyPublishResultDetail'
import Empiriowissen from './empiriowissen/Empiriowissen'
import EmpiriowissenGuide from './empiriowissen/EmpiriowissenGuide'
import EmpiriowissenWörterbuch from './empiriowissen/EmpiriowissenWörterbuch'
import SurveyCreation from './survey/surveyCreation/SurveyCreation';
import Unsubscribed from './unsubscribed/Unsubscribed';
import Unsubscribenewsletter from './unsubscribenewsletter/Unsubscribenewsletter';
import axios from 'axios';
import { URL_CONSTANT, AllUrlConstant, META_TAGS_CONSTANT, META_FACEBOOK,PAYMENT_CONSTANTS, AFFILIATION, TIME_PERIOD} from '../constants/FrontEndConstant';// eslint-disable-next-line
import { BrowserRouter as Router, Route, Switch, withRouter, Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import SurveyParticipation from "./survey/surveyParticipation/SurveyParticipation";
import EditSurvey from "./survey/surveyEdit/EditSurvey";
import HilfeCenter from './hilfeCenter/HilfeCenter';
import Empirio from './empirio/Empirio';
import Lexikon from './empiriowissen/Lexikon';
import Avv from './avv/Avv';
import CmsContentPage from './commonCmsContent/CmsContentPage';
import { Helmet } from "react-helmet";
import LinkTree from './linkTree/LinkTree';
import NsuLandingPage from './nsu/LandingPage'
import Payment from "./payment/PaymentPage";
import Paypal from "./payment/paypal";
import Sepa from "./payment/giropay";
import Card from "./payment/Payment";
import CheckoutBusiness from './payment/CheckoutBusiness'
import CheckoutUniversity from "./payment/checkoutUniversity";
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: 'false',
      scrollPostion : 0
    }
    this.externalParticipation = false;
    this.cmsPage = false;
    this.element = [];
    this.elementOgTag = [];
    this.canonical = "";
  }

  async componentDidMount() {
    this.checkForTokenValidity();
    setInterval(() => {
      this.checkForTokenValidity();
    }, 5000 * 60)
    /**---this function is for aapending google strucured breadcrumb data--- */
    window.setSchema = (schemaobj) => {
      let schemaobject = schemaobj;
      var scriptelement = document.createElement("script");
      scriptelement.type = "application/ld+json";
      scriptelement.innerText = JSON.stringify(schemaobject);
      scriptelement.id = "schemajson"
      document.querySelector("head").append(scriptelement);
    }
    /**---This function is to remove schema script--- */
    window.removeSchema = () => {
      if (document.getElementById('schemajson')) {
        document.getElementById('schemajson').remove();
      }
    }
    /**---This function include postion wise schema structure for google breadcrumb--- */
    window.schemaObj = (position1, position2, position3) => {
      var itemListElement = [];
      if (position1 && Object.keys(position1).length) {
        itemListElement.push(position1);
      }
      if (position2 && Object.keys(position2).length) {
        itemListElement.push(position2);
      }
      if (position3 && Object.keys(position3).length) {
        itemListElement.push(position3);
      }
      var schemaobj = {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": itemListElement
      }
      return schemaobj;
    }
    /***---This function is for dynamic position,id and name for a particular page--- */
    window.positionCreate = (position, id, name) => {
      var item = {}
      if (position && id && name) {
        item = {
          "@type": "ListItem",
          "position": position,
          "item": {
            "@id": id,
            "name": name
          }
        }
      }
      return item;
    }
    /*----This is to set itemtype and itemscope for the html page for structured data faq page---*/
    window.setFaqItemtype = () => {
      var htmlelement = document.querySelector('html');
      if (htmlelement) {
        htmlelement.setAttribute('itemType', "https://schema.org/FAQPage");
        htmlelement.setAttribute('itemScope', "");
      }
    }
    /**---This function is to remove html itemtype and itemscope property--- */
    window.removeFaqItemtype = () => {
      if (document.querySelector('html')) {
        document.querySelector('html').removeAttribute('itemType');
        document.querySelector('html').removeAttribute('itemScope');
      }
    }
    /* --- This is to Set Meta and title for Document from Any Component --- */
    window.setDocumentTitle = (documentTitle) => {
      document.title = documentTitle;
    }
    window.setMeta = (metaObj) => {
      let metaName = Object.keys(metaObj);
      this.element = [];
      // eslint-disable-next-line
      metaName.map((metaTag) => {
        // if (document.querySelector("meta[name='" + metaTag + "']"))
        //   document.querySelector("meta[name='" + metaTag + "']").content = metaObj[metaTag]
        this.element.push(<meta name={metaTag} content={metaObj[metaTag]} />);
      })
      this.setState({ loading: 'false' });
    }
    /**this function is for setting no index in meta */
    window.setNoIndex = () => {
      var metaelement = document.createElement("meta");
      metaelement.name = "robots";
      metaelement.content = "noindex, nofollow"
      document.querySelector("head").append(metaelement);
    }
    /**this function is for removing no index in meta */
    window.removeNoIndex = () => {
      if (document.querySelector("meta[name='robots']"))
        document.querySelector("meta[name='robots']").remove();
    }
    /**this function is for setting facebook meta */
    window.setMetaForFacebook = (metaObj) => {
      let metaName = Object.keys(metaObj);
      this.elementOgTag = [];
      // eslint-disable-next-line
      metaName.map((metaTag) => {
        // if (document.querySelector("meta[property='" + metaTag + "']"))
        //   document.querySelector("meta[property='" + metaTag + "']").content = metaObj[metaTag]
        this.elementOgTag.push(<meta property={metaTag} content={metaObj[metaTag]} />);
      })
      this.setState({ loading: 'false' });
    }
    /** function for setting og tag in each and every component */
    window.setOgTag = (og_title, og_description, og_image, og_url) => {
      let og_tag_title = og_title;
      let og_tag_description = og_description;
      let og_tag_image = og_image;
      let og_tag_url = og_url;
      window.setMetaForFacebook({ "og:title": og_tag_title, "og:description": og_tag_description, 'og:image': og_tag_image, 'og:url': og_tag_url, "og:updated_time": Math.floor(Date.now() / 1000) })
    }
    window.getQueryStringValue = (key) => {
      // eslint-disable-next-line
      return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }
    await this.checkCmsParctipation(this.windowLoaction)
  }
  componentWillUnmount () {
    window.removeEventListener("scroll", this.calculateScrollDistance,false);
  }
  async componentDidUpdate(prevProps, prevState, snapshot) {
    //Safari for  Survey Detail Page 
    var getPopupelElement = document.getElementById('show_popup')
    if(getPopupelElement){
      setTimeout(() => {
            localStorage.removeItem('payment_status')
          },5000)
    }
    window.scrollTo(0, 0);
    if (prevProps.location.pathname !== this.props.location.pathname) {
      await this.checkCmsParctipation(this.windowLoaction)
    }
  }
  checkForTokenValidity = () => {
    let loggedInUserData = JSON.parse(localStorage.getItem('userdata'));
    if (loggedInUserData) {
      let fd = new FormData();
      fd.append("token", loggedInUserData.token);
      axios.post(API_URL + '/frontuser/checktoken', fd).then((res) => {
        if (res.hasOwnProperty("data") && res.data.hasOwnProperty("error")) {
          window.location.pathname = "/anmelden"; /* --- If Token Expired redirect to Login Page --- */
        }
      }).catch((err) => {
        if (err.response) {
          if (err.response.data.hasOwnProperty('error')) {
            localStorage.clear();
            window.location.pathname = "/anmelden";
          }
        }
      })
    }
  }
  checkCmsParctipation = async (url) => {
    this.cmsPage = false;
    if (url) {
      if (url !== "umfragen" && url !== URL_CONSTANT['survey_result']) {
        await axios.get(API_URL + '/commonApi/checkSlug/' + LANGUAGE_CODE + '/' + url)
          .then((response) => {
            if (response.data['success']) {
              if (!response.data['success'].is_survey) {
                this.cmsPage = true;
              }
              else {
                this.cmsPage = false;
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data) {
                let msg = error.response.data['error']
                if (msg) {
                  if (Object.keys(msg).length) {
                  }
                  if (msg.length) {
                    if (msg === 'Expired token' || msg === 'Invalid token') {
                      localStorage.clear();
                    }
                  }
                }
              }
            }
          })
      }
    }
    this.setState({ loading: "false" });
    return;
  }
  removeLocalStorage = () =>{
    localStorage.removeItem('payment_status')
    localStorage.removeItem('show_popup')
    localStorage.removeItem('transaction_id')
  }
   // For bind a purchase event in gtm
   gtmEvntBind = () => {
    const event = window.dataLayer.find((item) => item.event === 'eec.purchase')
    if (localStorage.getItem('payment_status') == 1 && !event) {
      const billing_info = JSON.parse(localStorage.getItem('billing_info'))
      const user_ID = JSON.parse(localStorage.getItem('userdata')).account_type_category_id
      const affiliation = AFFILIATION['EN'][user_ID]
      const price = parseFloat(billing_info.price.replace(',', '.'))
      const time_period = TIME_PERIOD['EN'][billing_info.time_period]
      const payment_method = billing_info.payment_method
      if (window.dataLayer) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          transaction_id: localStorage.getItem('transaction_id'),
          event: 'eec.purchase',
          value: price,
          currency: 'EUR',
          payment_method: payment_method ?? null,
          time_period: time_period,
          items: [{
              item_name:  user_ID !== 1? `empirio Business Plus (${billing_info.time_period})` : `empirio Plus (${billing_info.time_period})`,
              affiliation: affiliation,
              price: price,
              quantity: 1,
            },
          ],
        })
      }
    }
  }
  render() {
    var url = new URL(window.location);
    var params = new URLSearchParams(url.search);
    var userData = JSON.parse(localStorage.getItem("userdata"));
    let windowLoactionIndex = 1;
    let windowLoaction = '';
    if (window.location.pathname.split('/')['1'] === 'front') {
      windowLoactionIndex = 2;
    }
    if (!this.element.length) {
      this.element.push(<meta name="description" content={META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_description']} />);
      this.element.push(<meta name="keywords" content={META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords']} />);
    }

    this.canonical = "";
    this.canonical = <link rel="canonical" href={window.location.href} />;

    if (!this.elementOgTag.length) {
      this.elementOgTag.push(<meta property="og:title" content={META_FACEBOOK['og_title']} />);
      this.elementOgTag.push(<meta property="og:description" content={META_FACEBOOK['og_description']} />);
      this.elementOgTag.push(<meta property="og:image" content={META_FACEBOOK['og_image']} />);
      this.elementOgTag.push(<meta property="og:url" content={META_FACEBOOK['og_url']} />);
      this.elementOgTag.push(<meta property="og:image:width" content="600" />);
      this.elementOgTag.push(<meta property="og:image:height" content="600" />);
    }
    windowLoaction = window.location.pathname.split('/')[windowLoactionIndex];
    this.windowLoaction = windowLoaction;
    if (windowLoaction) {
      windowLoaction = windowLoaction.toLowerCase()
      if (AllUrlConstant['all'].indexOf(windowLoaction) === -1) {
        if (!this.cmsPage) {
          if (windowLoaction === "umfragen") {
            if (windowLoactionIndex === 2 && window.location.pathname.split('/')[4]) {
              if (UrlConstant['header'].indexOf(windowLoaction) === -1) {
                UrlConstant['header'].push(windowLoaction)
              }
              if (UrlConstant['footer'].indexOf(windowLoaction) === -1) {
                UrlConstant['footer'].push(windowLoaction)
              }
            }
            else if (windowLoactionIndex === 1 && window.location.pathname.split('/')[3]) {
              if (UrlConstant['header'].indexOf(windowLoaction) === -1) {
                UrlConstant['header'].push(windowLoaction)
              }
              if (UrlConstant['footer'].indexOf(windowLoaction) === -1) {
                UrlConstant['footer'].push(windowLoaction)
              }
            }
            else {
              if (UrlConstant['header'].indexOf(windowLoaction) && UrlConstant['header'].indexOf(windowLoaction) !== -1) {
                UrlConstant['header'].splice(UrlConstant['header'].indexOf(windowLoaction), 1)
              }
              if (UrlConstant['footer'].indexOf(windowLoaction) && UrlConstant['footer'].indexOf(windowLoaction) !== -1) {
                UrlConstant['footer'].splice(UrlConstant['footer'].indexOf(windowLoaction), 1)
              }
            }
          }
          else {
            if (UrlConstant['header'].indexOf(windowLoaction) === -1) {
              UrlConstant['header'].push(windowLoaction)
            }
            if (UrlConstant['footer'].indexOf(windowLoaction) === -1) {
              UrlConstant['footer'].push(windowLoaction)
            }
          }
        }
      }
      if (windowLoaction === URL_CONSTANT['survey_result']) {
        if (window.location.pathname.split('/').length === 4) {
          if (UrlConstant['footer'].indexOf(URL_CONSTANT['survey_result']) === -1) {
            UrlConstant['footer'].push(URL_CONSTANT['survey_result'])
          }
          if (UrlConstant['header'].indexOf(URL_CONSTANT['survey_result']) === -1) {
            UrlConstant['header'].push(URL_CONSTANT['survey_result'])
          }
        }
        else {
          if (UrlConstant['footer'].indexOf(URL_CONSTANT['survey_result']) !== -1) {
            UrlConstant['footer'].splice(UrlConstant['footer'].indexOf(URL_CONSTANT['survey_result']),1);
          }
          if (UrlConstant['header'].indexOf(URL_CONSTANT['survey_result']) !== -1) {
            UrlConstant['header'].splice(UrlConstant['header'].indexOf(URL_CONSTANT['survey_result']),1);
          }
        }
      }
    }
    return (
      <div style={{"--select-icon" : "url("+IMAGE_BASEURL+"img/down-arrow.png)", "--effect" : "url(" + IMAGE_BASEURL + "img/home-about-texture.svg)"}}>
        {
            localStorage.getItem('payment_status') == 1 &&
            confirmAlert({
                  customUI: ({ onClose }) => {
                    this.gtmEvntBind()
                      return (
                          <div className='custom-ui'  id= 'show_popup'>
                          <div className='hello'>
                            <div className="close-btn text-right">
                              <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={()=>{onClose();this.removeLocalStorage()}}><i className="ti-close"></i></button>
                            </div>
                            <h3 className="font-20 text-dark m-2 p-2 text-center mt-sm-0 font-weight-bold text-left">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["payment_suceess_msg"]}</h3>
                            <button className='btn btn-clr text-white text-uppercase' onClick={()=>{onClose();this.removeLocalStorage()}} >{PAYMENT_CONSTANTS[LANGUAGE_CODE]["close_button"]}</button>
                          </div>
                          </div>
                      )
                  },
                  onClickOutside: () => {this.removeLocalStorage()},
              })
        }
        {
            localStorage.getItem('payment_status') == 0 &&
            confirmAlert({
                  customUI: ({ onClose }) => {
                      return (
                          <div class="custom-ui text-left position-relative share_popup">
                            <div class="close-btn text-right">
                              <button class="d-inline-block bg-white border-0 w-auto sharing-close-button" onClick={()=>{onClose();this.removeLocalStorage()}}>
                                <i class="ti-close ti-close-creditcard"></i>
                              </button>
                            </div>
                            <h3 class="font-24 text-dark mt-2 mt-sm-0 font-weight-bold text-left survey-sharing-bottom-margin">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["giropay_payment"]}</h3>
                            <p class="lead"> {PAYMENT_CONSTANTS[LANGUAGE_CODE]["amount_payable"]}: &nbsp;
                              {userData.plan_type == 0 ?  MONTHLY_AMOUNT :
                                 userData.plan_type == 1 ?  ANNUAL_AMOUNT
                                : null}€</p>
                            <div class="text-danger">{PAYMENT_CONSTANTS[LANGUAGE_CODE]["payment_error_msg"]}</div>
                          </div>
                      )
                  },
                  onClickOutside: () => {this.removeLocalStorage()},
              })
        }
        <Helmet>
          {this.element.length ? this.element : null}
          {this.elementOgTag.length ? this.elementOgTag : null}
          {this.canonical ? this.canonical : null}
        </Helmet>
        <div>
          {(UrlConstant['header'].indexOf(windowLoaction) === -1) ? <Header /> : null}
          <Switch>
            <Route path="/" exact={true} component={Home} />

            {/* NSU concept */}
            { userData?
              userData.account_type_category_id == 4 && userData.IsSubscriptioncancle !== 0?
              <Route path="/business-checkout" exact={true} component={CheckoutBusiness} />
              :  userData.account_type_category_id == 2 && userData.IsSubscriptioncancle !== 0?
              <Route path="/university-checkout" exact={true} component={CheckoutUniversity} />
              : <Route path="/checkout" exact={true} component={Payment} />  : null
            }
            <Route path="/checkout/paypal" exact={true} component={Paypal} />
            <Route path="/checkout/giropay" exact={true} component={Sepa} />
            <Route path="/checkout/card" exact={true} component={Card} />
            {/* EndNSU concept */}
            <Route path="/anmelden" exact={true} component={Login} />
            <Route path="/anmelden/:id" exact={true} component={Login} />
            <Route path="/unsubscribe/:id" exact={true} component={Unsubscribed} />
            <Route path="/unsubscribenewsletter/:id" exact={true} component={Unsubscribenewsletter} />
            <Route path="/registrieren" exact={true} component={NsuRegistration} />
            <Route path={"/" + URL_CONSTANT['forgotpassword']} exact={true} component={ForgotPassword} />
            <Route path={"/" + URL_CONSTANT['resetpassword'] + "/:id"} exact={true} component={ResetPassword} />
            <Route path="/mein-bereich" exact={true} render={(props) => <MyProfile {...props} />} />
            <Route path="/faq" exact={true} component={Faq} />
            <Route path='/ueber-Uns' exact={true} component={AboutUs} />
            <Route path='/funktionen' exact={true} component={Functions} />
            <Route path='/impressum' exact={true} component={Impressum} />
            <Route path='/agb' exact={true} component={Agb} />
            <Route path='/datenschutz' exact={true} component={Datenschutz} />
            <Route path={'/' + URL_CONSTANT['survey_list']} exact={true} component={SurveyList} />
            <Route path={'/' + URL_CONSTANT['empiriowissen']} exact={true} component={Empiriowissen} />
            <Route path={"/" + URL_CONSTANT['findparticipation'] + "/:surveyid"} exact={true} component={EditSurvey} />
            {// eslint-disable-next-line
            }<Route path={'/' + URL_CONSTANT['empiriowissen'] + '/wörterbuch/:slug'} exact={true} render={(props) => <EmpiriowissenWörterbuch {...props} />} />
            <Route path={'/' + URL_CONSTANT['empiriowissen'] + '/:slug'} exact={true} render={(props) => <EmpiriowissenGuide {...props} />} />
            <Route path={'/' + URL_CONSTANT['surveycreation']} exact={true} render={(props) => <SurveyCreation {...props} />} />
            <Route path={'/' + URL_CONSTANT['surveycreation'] + '/:surveyid'} exact={true} render={(props) => <SurveyCreation {...props} />} />
            <Route path='/404' component={PageNotFound} />
            <Route path="/hilfe-center" exact={true} component={HilfeCenter} />
            <Route path="/empirio" exact={true} component={Empirio} />
            <Route path="/lexikon" exact={true} component={Lexikon} />
            <Route path="/avv" exact={true} component={Avv} />
            {/* For a Links in survey participation, result list, data protection, empirioWissen, impressum and home page */}
            <Route path={"/" + URL_CONSTANT['link_tree']} exact={true} component={LinkTree} />

            {/* For publish surveys list */}
            <Route path={"/" + URL_CONSTANT['survey_result']} exact={true} component={SurveyPublishResultList} />

            {/* For publish survey result details */}
            <Route path={'/' + URL_CONSTANT['survey_result'] + '/:title'} exact={true} component={SurveyPublishResultDetail} />

            {/* For preview of participation */}
            <Route path={"/" + URL_CONSTANT['surveycreation'] + "/:title/:preview"} exact={true} component={SurveyParticipation} />

            {/* For survey detail page */}
            <Route path={"/" + URL_CONSTANT['survey_list'] + "/:title"} exact={true} component={SurveyDetail} />

            {/* For extenal participation */}
            {/* (this.cmsPage) ? CmsContentPage : SurveyParticipation */}
            <Route path="/s/:title" exact={true} component={SurveyParticipation} />
            {/* registration for using referral code  */}
            <Route path="/referral/:referralcode" exact={true} component={NsuRegistration} />
            {/* For cms page*/}
            <Route path="/:accType" exact={true}  render={(props) => <NsuLandingPage {...props} />}/>
            <Route path="/:title" exact={true}  component={ CmsContentPage}  />
            {/* For internal participation */}
            <Route path={"/" + URL_CONSTANT['survey_list'] + "/:title/:start"} exact={true} component={SurveyParticipation} />

            <Route path='/*' component={PageNotFound} />
          </Switch>

          {(UrlConstant['footer'].indexOf(windowLoaction) === -1) ? <Footer /> : null}

        </div>
      </div>
    );
  }
}
export default withRouter(App);
