import React, { Fragment } from 'react';
import axios from "axios";
import Redirect from 'react-router-dom/Redirect';
import { SURVEY_CREATION_CONSTANT, BUBBLE_LOADING } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL } from '../../../../constants/DotEnv';
import SurveyElementContent from '../surveyElement/SurveyElementContent'
import Button from '@material-ui/core/Button';
class SurveyElementRatingSingle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            questionScaleType: [],
            loading: 'false',
            redirect: 'false'
        }
    }
    componentDidMount() {
        this.getQuestionScaleType();
    }
    getQuestionScaleType = () => {
        this.setState({ loading: 'true' })
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let SurveyQuestionScaleTypeDropdownList = JSON.parse(localStorage.getItem("surveyquestionscaletypedropdownlist"));
        // eslint-disable-next-line
        let apitoken = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
        }
        let questionScaleType = '';
        let self = this;
        if (SurveyQuestionScaleTypeDropdownList === null) {
            axios.get(API_URL + '/commonApi/getScale/' + LANGUAGE_CODE)
                .then((response) => {
                    let responseNew = '';
                    responseNew = response.data['success'];
                    localStorage.setItem("surveyquestionscaletypedropdownlist", JSON.stringify(responseNew));
                    questionScaleType = responseNew.scaledata;
                    self.setState({ questionScaleType, loading: 'false' })
                })
                .catch((error) => {
                    if (error) {
                        if (error.response) {
                            if (error.response.data) {
                                let msg = error.response.data['error']
                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                    localStorage.clear();
                                    self.setState({ redirect: 'true' })
                                }
                            }
                        }
                    }
                    self.setState({ loading: 'false' })
                })
        }
        else {
            questionScaleType = SurveyQuestionScaleTypeDropdownList.scaledata;
            this.setState({ questionScaleType, loading: 'false' });
        }
    }
    questionScaleTypeSection = (questionScaleTypeOption, onChangeFunction, data) => {
        return (
            <div className="row">
                {questionScaleTypeOption}
            </div>
        );
    }
    questionDetailSectionNew = (onChange, data) => {
        let questionId = data.scale_type;
        switch (parseInt(questionId)) {
            case 5:
                return <div>
                    <h3 className="mt-3 py-2 font-14 fw-400">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_rating_single_above_option_title']}</h3>
                    <SurveyElementContent ratingSingleContent={true} optionLimit={this.props.optionLimit} optionCount={this.props.optionCount} value={this.props.data} message={this.props.message} onChange={this.props.onChange} />
                </div>
            default:
        }
    }
    clickInputRadio = (e) => {
        if(e.currentTarget.name)
            document.getElementById(e.currentTarget.name).click();
    }
    render() {
        let data = this.props.data;
        let onChangeFunction = this.props.onChange;
        let onChange = this.props.onChange;
        let question_scale_type = this.state.questionScaleType;
        let Max = this.state.questionScaleType.length;
        let questionScaleTypeOption = [];
        for (var index = 0; index < Max; index++) {
            questionScaleTypeOption.push(
                <div className="col-md-4 participation-survey pb-3 survey-radio-effect" key={index}>
                    <label htmlFor={"scale_type" + index} className="MuiButton-effect w-100 mb-0 pointer btn-radio survey-pic-shadow" key={index}>
                        <Button variant="contained" color="#00b3f4" className="w-100" name={"scale_type" + index} onClick={(e)=>this.clickInputRadio(e)}>
                            <div className="custom-radio-btn btn w-100">
                                <div className="custom-control custom-radio custom-control-inline pl-0 px-4 py-3 w-100">
                                    <input type="radio" id={"scale_type" + index} name="scale_type" className="custom-control-input" defaultChecked={(parseInt(data.scale_type) === parseInt(question_scale_type[index].id))} value={question_scale_type[index].id} onChange={onChange} />
                                    <svg width="20px" height="20px" viewBox="0 0 20 20">
                                        <circle cx="10" cy="10" r="9"></circle>
                                        <path style={{"--colorpicker": "#00b3f4" }} d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" class="inner"></path><path d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" class="outer" style={{"--colorpicker": "#00b3f4" }}></path>
                                    </svg>
                                    <span class="ml-3 ml-md-2 ml-lg-4 p-1 word-break-all d-inline-block w-100 text-initial font-16">{question_scale_type[index].scale_type}</span>
                                </div>
                            </div>
                        </Button>
                    </label>
                </div>);
        }
        // the below one line logic is for showing the zustimmung field at first in list (according to #291 comments)
        questionScaleTypeOption.unshift(questionScaleTypeOption.pop());
        const elements = <div> {this.questionScaleTypeSection(questionScaleTypeOption, onChangeFunction, data)} </div>;
        const elementComponent = <div> {this.questionDetailSectionNew(onChange, data)} </div>;
        return (
            <Fragment>
                {(this.state.redirect === 'true') ?
                    <Redirect to="/anmelden"></Redirect>
                    : null
                }
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                <div className="form-group survey-form-detail mt-4 mt-md-5">
                    <label htmlFor="question_text" className="sr-only">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_question_IPH']}</label>
                    <input type="text" id="question_text" name="question_text" value={data.question_text} onChange={onChangeFunction} className={(this.props.errorElementPosition === 1 && this.props.validationMessage) ? "form-control border-bottom-red" : "form-control"} placeholder={SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_element_page_question_IPH']} />
                </div>
                <div id="errorSurveyMessage" className="error-text mt-0">{(this.props.validationMessage && this.props.errorElementPosition === 1) ? <p>{this.props.validationMessage}</p> : null}</div>
                <div id="errorSurveyMessage" className="error-text mt-0">{(this.props.validationMessage && this.props.errorElementPosition === 3) ? <p>{this.props.validationMessage}</p> : null}</div>
                {(!this.props.validationMessage || this.props.errorElementPosition === 2) ? <div className="error-text mt-0"><p>{this.props.message}</p></div> : null}
                {elements}
                {elementComponent}
            </Fragment>
        );
    }
}
export default SurveyElementRatingSingle;