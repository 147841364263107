import React from "react";
import { SCHOOL_GRADE_SCALE_CONSTANT } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from "../../../../constants/DotEnv";
import Button from '@material-ui/core/Button';
class SurveyRatingWithMoreSchoolGradeQuestion extends React.Component {
    
    labelOnClick = (e) => {
        if (e.currentTarget.name) {
            document.getElementById(e.currentTarget.name).click();
            document.getElementById(e.currentTarget.name).checked = !(document.getElementById(e.currentTarget.name).checked);
        }
    }

    render() {
        let data = this.props.questiondata;
        let surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + data.survey_id));
        let englishbuttonlabels;
        englishbuttonlabels = surveydetail.englishbuttonlabels == "1" ? "EN" : "DE"
        let option = data.option.map((scale, index) => {
            return (<div key={index} className="d-flex align-items-center justify-content-center row mb-5"><div className="col-12 text-center mb-3  md-5"><span className="font-size font-weight-bold">{scale.option_text}</span></div><div className="d-flex align-items-center justify-content-center flex-wrap col-12">{SCHOOL_GRADE_SCALE_CONSTANT[LANGUAGE_CODE].map((option, index) => {
                return (
                        <button
                            type='button'
                            id={option.id}
                            className={"MuiCustom-school-grade px-0 "+((this.props.questionanswer.option === undefined)
                                ? (this.props.questionanswer[scale.id] === undefined) ? "btn-grade box-shadow  school-grade-font rating-gray" : (this.props.questionanswer[scale.id] === option.title) ? 'btn-grade-clr school-grade box-shadow   school-grade-font' : 'btn-grade box-shadow  school-grade-font rating-gray'
                                : (this.props.questionanswer.option === undefined) ? "btn-grade box-shadow  school-grade-font rating-gray" : (this.props.questionanswer.option.findIndex(ans => ans.answer === option.title && parseInt(ans.option_id) === parseInt(scale.id)) > -1) ? 'btn-grade-clr box-shadow school-grade  school-grade-font ' : 'btn-grade box-shadow  school-grade-font rating-gray')
                            }
                            name={scale.id}
                            key={index}
                            value={option.title}
                            onClick={this.props.onSchoolGrade}
                            style={{ "--school-gradian": surveydetail.survey_color? surveydetail.survey_color : "#00b3f4" }}
                        >
                            <Button variant="contained" color="#00b3f4" className="" onClick={(e) => this.labelOnClick(e)}>
                                <span class="font-weight-bold h6 school-number rating-element-size">{option.id}</span><br />{SCHOOL_GRADE_SCALE_CONSTANT[englishbuttonlabels][index]["title"]}
                            </Button>
                        </button>)
            })}</div></div>)
        })
        return (<div key={this.props.questiondata.question_id} className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
            <div className="question-heading">
                <h3 className="text-dark font-weight-bold text-break survey-title">{data.question_text}</h3>
                {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
            </div>
            <div className="ratingwithmore scool-grade">
                {option}
            </div>
        </div>)
    }
}
export default SurveyRatingWithMoreSchoolGradeQuestion;