import React from 'react';
import axios from "axios";
import { connect } from "react-redux";
import { removeUser } from "../../actions";
import { addUserdata } from '../../actions';
import { Redirect } from "react-router-dom";
import EditProfile from "./EditProfile";
import ProfileTab from "./ProfileTab";
import { LOGIN_CONSTANT, MYPROFILE_EDITPROFILE_CONSTANT, META_TAGS_CONSTANT, META_TITLE_CONSTANT, BUBBLE_LOADING } from "../../constants/FrontEndConstant";
import { LANGUAGE_CODE, MESSAGE_TIME, API_URL, META_TITLE_APPEND } from '../../constants/DotEnv';
import { confirmAlert } from 'react-confirm-alert';
function mapDispatchToProps(dispatch) {
    return {
        removeUser: userdata => dispatch(removeUser(userdata)),
        addUserdata: userdata => dispatch(addUserdata(userdata)),
    }
}
class MyProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            loading: 'false',
            showImageUploadLoader: false,
            userKarmaInfo: "",
            user_account_id: null
        };
    }

    componentDidMount() {
        let meta_title = META_TITLE_CONSTANT[LANGUAGE_CODE]['mein-bereich'] + META_TITLE_APPEND;
        let meta_description = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_description'];
        let meta_keywords = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords'];
        setTimeout(() => {
            window.setMeta({ "description": meta_description, "keywords": meta_keywords })
            window.setDocumentTitle(meta_title)
        }, 1000)
        this.scrollUpFunction();
        this.myprofileDataFunction();
        window.setTimeout(() => {
            localStorage.removeItem('unsubscribe');
            localStorage.removeItem('unsubscribenewslatter');
            localStorage.removeItem('unsubscribenewslattererror');
            this.unsubscribenewslatter = "";
            this.unsubscribe = "";
            this.unsubscribenewslattererror = "";
            this.setState({ unsubscribe: "", unsubscribenewslatter: "", unsubscribenewslattererror: "" })
        }, MESSAGE_TIME);
    }

    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (document.querySelector('.navbar-toggler')) {
            let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
            if (count === -1) {
                document.querySelector('.navbar-collapse').classList.remove('show');
                document.querySelector('header').classList.remove("header_collapse");
                document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
            }
        }
    }

    uploadProfilePicture = (event) => {
        let userdata = JSON.parse(localStorage.getItem('userdata'));
        let self = this;
        let userId = '';
        var fd = new FormData();
        fd.append("userimage", event.target.files[0]);
        fd.append("id", userdata.id);
        if (userdata !== null) {
            userId = userdata.id;
        }
        this.setState({
            showImageUploadLoader: true
        }, async () => {
            await axios({
                method: "POST",
                // eslint-disable-next-line
                url: API_URL + '/userprofile/updateprofileimage' + '?uId=' + userId,
                data: fd,
                headers: {
                    'content-type': 'multipart/form-data',
                    "Authorization": "Bearer " + userdata.token
                },
            }).then(res => {
                let currentData = this.state.data;
                currentData['userimage'] = res.data.success.profileimage;
                userdata['userimage'] = res.data.success.profileimage;
                localStorage.setItem(('userdata'), JSON.stringify(userdata));
                this.props.addUserdata(userdata);
                self.setState({
                    data: currentData,
                    showImageUploadLoader: false
                })
            }).catch(err => {
                if (err.response) {
                    let all_errors = err.response.data.error;
                    self.showErrorPopup(Object.values(all_errors));
                    self.setState({
                        showImageUploadLoader: false
                    })
                }
            })
        })
    }

    showErrorPopup = (error_messages) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <div className="close-btn text-right">
                            <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close"></i></button>
                        </div>
                        {
                            error_messages.map((error_message, index) => (<p key={index}>{error_message}</p>))
                        }
                        <button onClick={onClose} className="btn">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['popup_ok_button_text']}</button>
                    </div>
                )
            }
        })
    }

    /**this function if for getting information regarding user's karmapoints,user's level and related bonus */
    getUserKarmapointsInfo = async () => {
        this.setState({ loading: 'true' })
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let token = '';
        let userid = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
            token = storeitem.token;
            userid = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        let userKarmaInfo = '';
        let self = this;
        await axios.get(API_URL + "/userssurvey/getKarmapoint/" + LANGUAGE_CODE + "/" + userid + '?uId=' + userid, { headers: headers })
            .then((response) => {
                userKarmaInfo = response.data.success.userkarmapoints;
                self.setState({ userKarmaInfo, loading: 'false' })
            })
            .catch((error) => {
                if (error) {
                    if (error.response) {
                        if (error.response.data) {
                            let msg = error.response.data['error']
                            if (msg === 'Expired token' || msg === 'Invalid token') {
                                this.props.removeUser({ token });
                                localStorage.clear();
                            }
                        }
                    }
                }
                self.setState({ loading: 'false' })
            })
    }
    async myprofileDataFunction() {
        this.setState({ loading: 'true' })
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let token = '';
        let userid = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
            token = storeitem.token;
            userid = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        let data = '';
        let self = this;
        await axios.get(API_URL + "/userprofile/getUserdata/" + userid + "/" + LANGUAGE_CODE + '?uId=' + userid, { headers: headers })
            .then((response) => {
                data = response.data['success'];
                let userData = Object.assign({}, storeitem);    //creating copy of object of userdata of localStorage
                /**Update data of local Storage if exist into respone of axios call */
                // eslint-disable-next-line
                Object.keys(data).map((data1, index) => {
                    userData[data1] = data[data1];
                })
                localStorage.setItem(('userdata'), JSON.stringify(userData));
                self.setState({ data })
                let changeUserData = []
                changeUserData['userimage'] = data.userimage;
                this.props.addUserdata(changeUserData);
            })
            .catch((error) => {
                if (error) {
                    if (error.response) {
                        if (error.response.data) {
                            let msg = error.response.data['error']
                            if (msg === 'Expired token' || msg === 'Invalid token') {
                                this.props.removeUser({ token });
                                localStorage.clear();
                            }
                        }
                    }
                }
            })
        let FinalDropdownList = JSON.parse(localStorage.getItem("dropdownList"));
        if (FinalDropdownList === null) {
            await axios.get(API_URL + "/common/getAll/" + LANGUAGE_CODE + '?uId=' + userid, { headers: headers })
                .then((response) => {
                    let dropDownData = response.data['success'];
                    localStorage.setItem("dropdownList", JSON.stringify(dropDownData));
                })
                .catch((error) => {
                    //console.log("error", error.response)
                })
        }
        this.getUserKarmapointsInfo();
        self.setState({ loading: 'false' })
    }
    getUserAccountID =(user_account_id) => {
        this.setState({user_account_id: user_account_id})
    }
    render() {
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let token = '';
        this.unsubscribe = localStorage.getItem('unsubscribe')
        this.unsubscribenewslatter = localStorage.getItem('unsubscribenewslatter');
        this.unsubscribenewslattererror = localStorage.getItem('unsubscribenewslattererror')
        if (storeitem !== null) {
            token = storeitem.token;
        }
        return (
            <main>
                {(token === '') ?
                    <Redirect to="/anmelden" />
                    : null
                }
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {(this.state.data) ?
                    <div>
                        <EditProfile data={this.state.data} showImageUploadLoader={this.state.showImageUploadLoader} uploadProfilePicture={this.uploadProfilePicture} karmainfo={this.state.userKarmaInfo}  user_account_id={this.state.user_account_id} />
                        <section className="myprofile-section mt-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">                                                               
                                        <ProfileTab  user_account_id={this.state.user_account_id} data={this.state.data} getUserAccountID={this.getUserAccountID}/>
                                        {(this.unsubscribe) ? <span className="error_message output_message error float-none">
                                            {LOGIN_CONSTANT[LANGUAGE_CODE]['unsubscribe_mail']}
                                        </span>
                                            : null
                                        }
                                        {(this.unsubscribenewslatter) ? <span className="error_message_success output_message success float-none">
                                            {LOGIN_CONSTANT[LANGUAGE_CODE]['unsubscribe_newsletter']}
                                        </span>
                                            : null
                                        }
                                        {(this.unsubscribenewslattererror) ? <span className="error_message output_message error float-none">
                                            {LOGIN_CONSTANT[LANGUAGE_CODE]['unsubscribe_newsletter_error']}
                                        </span>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    : null
                }
            </main>
        );
    }
}
export default connect(null, mapDispatchToProps)(MyProfile);