import React, { Fragment } from 'react';
import { SURVEY_CREATION_CONSTANT } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
import Button from '@material-ui/core/Button';
class SurveyWelcomePage extends React.Component {
	
	labelOnClick = (e) => {
        if (e.currentTarget.name) {
            document.getElementById(e.currentTarget.name).click();
            document.getElementById(e.currentTarget.name).checked = !(document.getElementById(e.currentTarget.name).checked);
        }
    }

	render() {
		let data = this.props.data;
		let onClickFunction = this.props.onClick;
		return (
			<Fragment>
				<div className={"hs-form-field custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")}>
					<h2 className="text-dark h3 font-weight-bold survey-title">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_welcome_page_title1']}</h2>
					<h3 className="font-14 lh-24 fw-400 survey-subtitle">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_welcome_page_subtitle']}</h3>
					<div className="survey-form-detail row">
						<div className="col-12 col-md-6">
							<div className="custom-radio-btn w-100 MuiButton-effect">
								<div className="custom-control custom-radio custom-control-inline pl-0 w-100">
									 <label className="btn-radio p-0">
									<Button variant="contained" className="py-3 px-4" color="#00b3f4" name="customRadioInline12" onClick={(e)=>this.labelOnClick(e)}>
										<input type="radio" className="custom-control-input" onClick={onClickFunction} defaultChecked={(data.is_welcome_page === true || data.is_welcome_page === 1 || data.is_welcome_page ==="true" ||data.is_welcome_page === "1" ) ? true : false} name="is_welcome_page" id="customRadioInline12" value={true} />
										<svg width="20px" height="20px" viewBox="0 0 20 20">
                                            <circle cx="10" cy="10" r="9"></circle>
                                            <path  style={{"--colorpicker": "#00b3f4" }} d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                            <path  style={{"--colorpicker": "#00b3f4" }} d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                                        </svg>
										<span className="ml-3">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_welcome_page_yes_button']}</span>
									</Button>
									</label>
								</div>
								
							</div>
						</div>
						<div className="col-12 col-md-6 pt-3 pt-md-0">
							<div className="custom-radio-btn  w-100 MuiButton-effect">
								<div className="custom-control custom-radio custom-control-inline pl-0 w-100">
									<label className="btn-radio p-0">
									<Button variant="contained" className="py-3 px-4" color="#00b3f4" name="customRadioInline11" onClick={(e)=>this.labelOnClick(e)}>
									<input type="radio" onClick={onClickFunction} className="custom-control-input" id="customRadioInline11" defaultChecked={(data.is_welcome_page === false || data.is_welcome_page === 2 ||data.is_welcome_page === "false" || data.is_welcome_page === "2" ) ? true : false} name="is_welcome_page"  value={false} />
									<svg width="20px" height="20px" viewBox="0 0 20 20">
                                            <circle cx="10" cy="10" r="9"></circle>
                                            <path  style={{"--colorpicker": "#00b3f4" }} d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                            <path style={{"--colorpicker": "#00b3f4" }} d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                                        </svg>
									<span className="ml-3">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_welcome_page_no_button']}</span>
									</Button>
									</label>
								</div>
							
							</div>
						</div>
					</div>
					<div id="errorSurveyMessage" className="error-text mt-3">{(this.props.validationMessage)?<p>{this.props.validationMessage}</p> : null}</div>
				</div>
			</Fragment>
		);
	}
}
export default SurveyWelcomePage;