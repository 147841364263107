import React from 'react';
import { DEMOGRAPHIC_PARTICIPATION_CONSTANT, SURVEY_PARTICIPATION } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
import Autocomplete from 'react-autocomplete';
class DemographicSingleTextField extends React.Component {
    /**this function is for prevent form data submit when press enter key */
    stopSubmitting = (e) => {
        var x = e.which || e.keycode;
        if ((x < 48 || x > 57)) {
            e.preventDefault();
        }
        if (e.key === 'Enter') {
            e.preventDefault();
            if (this.props.lastIndex === 1) {
                this.props.finish(e)
            } else {
                this.props.continue(e)
            }
        }
    }
    stopToMove = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (this.props.lastIndex === 1) {
                this.props.finish(e)
            } else {
                this.props.continue(e)
            }
        }
    }
    render() {
        let data = this.props.demographicdata
        let attribute_id = data.id;
        var name;
        var value = ""
        DEMOGRAPHIC_PARTICIPATION_CONSTANT['EN'].map((title, index) => {
            if (parseInt(attribute_id) === parseInt(title.id)) {
                name = title.title
                Object.keys(this.props.questionanswer).map((ans, index) => {
                    this.props.questionanswer.map((key, index) => {
                        Object.keys(key).map((finalkey) => {
                            if (finalkey === name) {
                                if (finalkey === 'postalcode_id') {
                                    value = key['postalcode']
                                } else {
                                    value = key[finalkey]
                                }
                            }
                            return 0;
                        })
                        return 0;
                    })
                    return 0;
                })
            }
            return 0;
        })
        return (
            <div key={this.props.demographicdata.id} className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
                <div className="question-heading">
                    <h3 className="text-dark font-weight-bold survey-title">{data.question}</h3>
                    {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
                </div>
                <div className="form-row survey-form-question">
                    <div className="form-group col-12 fill-colour width100div">
                        <label htmlFor="yourcolour" className="sr-only">{SURVEY_PARTICIPATION[LANGUAGE_CODE]['demographic_textfield_IPH']}</label>
                        {(name === "age") ? <input type="text" className="form-control textfield-type-demo" name={name} id="yourcolour" maxLength="2" onKeyPress={this.stopSubmitting} onChange={this.props.onChange} value={value} placeholder={SURVEY_PARTICIPATION[LANGUAGE_CODE]['demographic_textfield_IPH']} />
                            : <Autocomplete
                                value={value}
                                inputProps={{ id: 'states-autocomplete1', className: 'form-control hs-input textfield-type-demo w-100', placeholder: SURVEY_PARTICIPATION[LANGUAGE_CODE]['demographic_location_IPH'], onKeyPress: this.stopToMove }}//onKeyPress: this.props.onKeyPress removed to allow special characters
                                items={this.props.locationData}
                                getItemValue={item => item.postalcode_city}
                                shouldItemRender={(value) ? (item) => item.postalcode_city : null}
                                // eslint-disable-next-line
                                onChange={this.props.onSelect}
                                // eslint-disable-next-line
                                onSelect={this.props.onSelect}
                                renderMenu={children => (
                                    (children.length) ? <div className="form-autocomplete-suggestion">
                                        {children}
                                    </div>
                                        : <div />
                                )}
                                renderItem={(item, isHighleghted) => (
                                    <div key={item.id} id={item.id} className={(isHighleghted) ? "form-autocomplete-suggestion-field custom-autocomplete-background" : "form-autocomplete-suggestion-field"}>
                                        {item.postalcode_city}
                                    </div>
                                )}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default DemographicSingleTextField;

