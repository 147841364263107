import React from "react";
import { Redirect } from "react-router-dom";
import { PAGE_NOT_FOUND_CONSTANT, META_TITLE_CONSTANT, META_TAGS_CONSTANT } from '../../constants/FrontEndConstant';
import { LANGUAGE_CODE, META_TITLE_APPEND} from '../../constants/DotEnv';
class Static extends React.Component {
	constructor() {
		super();
		let meta_title = META_TITLE_CONSTANT[LANGUAGE_CODE]['pagenotfound'] + META_TITLE_APPEND;
		let meta_description = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_description'];
		let meta_keywords = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords'];
		setTimeout(() => {
			window.setMeta({ "description": meta_description, "keywords": meta_keywords })
			window.setDocumentTitle(meta_title)
		}, 100)
	}
	render() {
		let redirectToNotFound = false;
		if (window.location.pathname !== "/404") {
			redirectToNotFound = true;
		}
		return (
			<main>
				{(redirectToNotFound === true) ? <Redirect to="/404" /> : null
				}
				<section class="page-title-section theme-bg d-flex justify-content-center align-items-center text-center">
					<div class="container text-white error">
						<h2 class="text-uppercase">{PAGE_NOT_FOUND_CONSTANT[LANGUAGE_CODE]['title_text']}</h2>
						<h3 class="text-white h2">{PAGE_NOT_FOUND_CONSTANT[LANGUAGE_CODE]['sub_title_text']}</h3>
					</div>
				</section>

			</main>
		);
	}
}
export default Static;