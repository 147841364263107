import React, { Fragment } from 'react';
import { SURVEY_CREATION_CONSTANT } from "../../../../constants/FrontEndConstant";
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
class SurveyCategory extends React.Component {
    section = (categoryOption, method, value) => {
        return (
            <div className="hs-form-field">
                <h2 className="text-dark h3 font-weight-bold survey-title">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_category_page_question']}</h2>
                <h3 className="font-14 lh-24 fw-400 survey-subtitle">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_category_page_subtitle']}</h3>
                <div className="continue-btn survey-form-detail">
                    <div className="input-group-prepend">
                        <label className="input-group-text sr-only" htmlFor="categories"></label>
                    </div>
                    <div id="errorSurveyMessage"></div>
                    <select className={(this.props.validationMessage) ? "custom-select border-red" : "custom-select"} value={value.category_id} name="category_id" id='category_id' onChange={method} >
                        <option value=''>{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_category_page_default_option']}</option>
                        {categoryOption}
                    </select>
                    <div id="errorSurveyMessage" className="error-text mt-2">{(this.props.validationMessage) ? <p>{this.props.validationMessage}</p> : null}</div>
                </div>
            </div>
        );
    }
    render() {
        let value = this.props.value;
        let method = this.props.onChange;
        let elements = '';
        let dropDownData = this.props.dropDownData.surveycategory;
        if (dropDownData) {
            let categoryOption = dropDownData.map((surveycategory, index) => {
                return <option value={parseInt(surveycategory.id)} key={surveycategory.id}>{surveycategory.category_name}</option>
            })
            elements = <div className={"tab-pane sectionstep custom-form-animated animated "+ ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-2" > {this.section(categoryOption, method, value)} </div >;
        }
        return (
            <Fragment>
                {elements}
            </Fragment>
        );
    }
}
export default SurveyCategory;