import React from 'react';
import Button from '@material-ui/core/Button';
class SurveyRatingWithPointsQuestion extends React.Component {
	labelOnClick = (e) => {
        if (e.currentTarget.name) {
            document.getElementById(e.currentTarget.name).click();
            document.getElementById(e.currentTarget.name).checked = !(document.getElementById(e.currentTarget.name).checked);
        }
    }
    render() {
        let data = this.props.questiondata;
        let survey_data = JSON.parse(localStorage.getItem("surveydetail_" + data.survey_id))
        let option = [];
        let length = 11;
        for (let i = 1; i < length; i++) {
            option.push(
			<span className="MuiButton-effect MuiButton-effect-rating MuiButton-point">
			<Button variant="contained" color="#00b3f4" className="box-shadow" onClick={(e) => this.labelOnClick(e)}>
			<button
                type='button'
                className={(this.props.questionanswer.free_text_answer === undefined) ?
                    (Object.keys(this.props.questionanswer).length === 0) ? "default box-shadow rating-gray points-rank-button" : (parseInt(this.props.questionanswer[data.question_id]) === parseInt(i)) ? "btn-rank box-shadow points-rank-button selected-points" : (parseInt(this.props.questionanswer[data.question_id]) >= parseInt(i)) ? "btn-rank box-shadow points-rank-button" : "default box-shadow rating-gray points-rank-button"
                    : (this.props.questionanswer.free_text_answer === undefined) ? "default box-shadow rating-gray points-rank-button" : (parseInt(this.props.questionanswer.free_text_answer) === parseInt(i)) ? "btn-rank box-shadow points-rank-button selected-points" : (parseInt(this.props.questionanswer.free_text_answer) >= parseInt(i)) ? 'btn-rank box-shadow points-rank-button' : 'default box-shadow rating-gray points-rank-button'}
                name={data.question_id}
                key={i}
                value={i}
                onClick={this.props.onButtonRating}
                style={survey_data.survey_color? {"--school-gradian": survey_data.survey_color} : {"--school-gradian": "#00b3f4"}}
            ><span className="font-weight-bold h6 mb-0 text-nowrap " style={{ display: "flex", justifyContent: "center"}}>{i}</span></button></Button></span>)
        }
        return (<div key={this.props.questiondata.question_id} className={"tab-pane sectionstep one-ten custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
            <div className="text-center">
                <div className="question-heading text-left">
                    <h3 className="text-dark font-weight-bold text-break survey-title">{data.question_text}</h3>
                    {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
                </div>
                {option}
            </div>
        </div>)
    }
}
export default SurveyRatingWithPointsQuestion;