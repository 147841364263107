import React, { Component } from "react";
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import { Button } from "react-bootstrap";
import {API_URL,MONTHLY_AMOUNT,ANNUAL_AMOUNT, BASEURL} from '../../constants/DotEnv'
import axios from "axios";
import { BUBBLE_LOADING } from "../../constants/FrontEndConstant"
class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaymentLoading: false,
      Redirrect: false,
      error:'',
      loading:false,
      error_msg: ''
    };
  }
  handleSubmit = async (event) => {
    this.setState({loading:true})
    event.preventDefault();
    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      this.setState({error_msg: result.error.message})
      this.setState({loading: false})
    } else {
      // pass the token to your backend API
      let storeitem = JSON.parse(localStorage.getItem('userdata'));
      var billing_info = JSON.parse(localStorage.getItem('billing_info'));
      let apitoken = storeitem.token;
      let userId = storeitem.id;
      const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + apitoken
      };
      var amount =  this.props.total
      var final_amount = amount.replace(",",'.')
      const planType = this.props.total === MONTHLY_AMOUNT ? 0 : this.props.total === ANNUAL_AMOUNT ? 1 : 0;
      await axios.post(API_URL + '/payment/stripe/' + userId+ '?uId=' + userId, {first_name:this.props.first_name, last_name:this.props.last_name, price:final_amount, postalcode_id:this.props.postalcode_id, token: result.token.id,plan_type: planType, organization: storeitem.organization, telephone: storeitem.telephone, street: billing_info.street, house_number: billing_info.house_number, name: this.props.first_name + " " + this.props.last_name, email: storeitem.email},
        { headers: headers }).then((res)=>{
          this.setState({loading:false})
          localStorage.setItem('payment_status',1);
          localStorage.setItem('plan_type', planType);
          localStorage.setItem('transaction_id',res.data.success.transaction_id);
          window.location = localStorage.getItem('redirectURL');
          if(!localStorage.getItem('redirectURL')){
            window.location = BASEURL + "mein-bereich"
          }
        })
       .catch((err)=> {this.setState({error:err.response.data.error, loading:false});
       localStorage.removeItem('plan_type')})
    }
  };
  render() {
    if(this.state.error)
    {
    return (
      <>
        {this.state.loading == true? BUBBLE_LOADING : null}
        <div className='text-danger'>{this.state.error}</div>
      </>)
    }
  else{
      return (
        <>
        {this.state.loading == true? BUBBLE_LOADING : null}
        <div
        className="p-3"
        >
          <div
            className="mx-auto"
          >
            <form
              onSubmit={this.handleSubmit}
              className="w-100 d-block"
            >
              <div
              className="d-flex flex-column align-items-center"
              >
                <CardElement
                  className="card-body mb-2"
                  // onChange={(event) => console.log(event)}
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                />
                <p style={{color: 'red'}}>{this.state.error_msg}</p>
                <Button
                  className="pay-button btn-dark btn-block"
                  disabled={this.state.isPaymentLoading}
                  onClick={this.handleSubmit}
                >
                  {/* {this.state.isPaymentLoading ? "Loading..." : "Bezahlen"} &#8364;{this.props.total} */}
                  {this.state.isPaymentLoading ? "Loading..." : "Bezahlen"}
                </Button>
              </div>
            </form>
          </div>
        </div>
        </>
      );
    }
  }
}
function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} {...props} />
      )}
    </ElementsConsumer>
  );
}
export default InjectedCheckoutForm;