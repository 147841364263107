import React, { Component } from 'react';
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import { FORGOTPASSWORD_ERROR, FORGOTPASSWORD_CONSTANT, META_TITLE_CONSTANT, META_TAGS_CONSTANT, BUBBLE_LOADING, META_FACEBOOK, BREADCRUMB_SCHEMA } from "../../constants/FrontEndConstant";
import { API_URL, LANGUAGE_CODE, FRONT_USER, MESSAGE_TIME, META_TITLE_APPEND } from '../../constants/DotEnv';
const ListItem = ({ value }) => (<span> {value}<br /></span>);
class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            message: [],
            count: '',
            loading: 'false',
            fieldErrorMessage: [],
            error: 0
        };
        let meta_title = META_TITLE_CONSTANT[LANGUAGE_CODE]['forgotpassword'] + META_TITLE_APPEND;
        let meta_description = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_description'];
        let meta_keywords = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords'];
        let og_title = META_TITLE_CONSTANT[LANGUAGE_CODE]['forgotpassword'] + META_TITLE_APPEND;
        let og_description = META_FACEBOOK['og_description'];
        let og_image = META_FACEBOOK['og_image'];
        setTimeout(() => {
            window.setMeta({ "description": meta_description, "keywords": meta_keywords })
            window.setOgTag(og_title, og_description, og_image, window.location.href)
            window.setDocumentTitle(meta_title)
        }, 200)
    }
    componentWillUnmount() {
        setTimeout(() => {
            window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
            window.removeSchema();
        }, 100)
    }
    componentDidMount() {
        this.scrollUpFunction();
        setTimeout(() => {
            var schema2 = window.positionCreate(BREADCRUMB_SCHEMA['forgotpassword']['schema2']['position'], BREADCRUMB_SCHEMA['forgotpassword']['schema2']['url'], BREADCRUMB_SCHEMA['forgotpassword']['schema2']['name']);
            var schemaObject = window.schemaObj(BREADCRUMB_SCHEMA['schema1'], schema2, BREADCRUMB_SCHEMA['forgotpassword']['schema3']);
            window.setSchema(schemaObject);
        }, 100)
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
        if (count === -1) {
            document.querySelector('.navbar-collapse').classList.remove('show');
            document.querySelector('header').classList.remove("header_collapse");
            document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
        }
    }
    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
        var index = this.state.fieldErrorMessage.indexOf(name);
        var indexRE = this.state.fieldErrorMessage.indexOf(name + "validation");
        if (index > -1) {
            this.state.fieldErrorMessage.splice(index, 1);
        }
        if (indexRE > -1) {
            this.state.fieldErrorMessage.splice(indexRE, 1);
        }
    }
    handleSubmit = (e) => {
        var forgotfield = {
            email: this.state.email,
        }
        var a = [];
        this.setState({ fieldErrorMessage: a })
        var test = 0;
        var error = ''
        for (var key in forgotfield) {
            if (forgotfield.hasOwnProperty(key)) {
                var errorkey = key;
                const value = forgotfield[errorkey];
                if (value === '' || value === null || value === undefined) {
                    a.push(errorkey);
                    error = test + 1;
                    test = error;
                }
                else {
                    if (errorkey === 'email') {
                        // eslint-disable-next-line 
                        var emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        var checkEmail = emailRE.test(value);
                        if (checkEmail === false) {
                            a.push(errorkey + "validation");
                            error = test + 1;
                            test = error;
                        }
                    }
                }
            }
        }
        this.setState({ error: test, fieldErrorMessage: a });
        if (test === 0) {
            this.setState({ message: [], loading: 'true' })
            let apiresponse = "";
            let regError = [];
            let self = this;
            axios.post(API_URL + FRONT_USER + "/forgotpassword", {
                email: this.state.email,
                languagecode: LANGUAGE_CODE
            })
                .then((response) => {
                    this.scrollUpFunction();
                    apiresponse = response.data;
                    self.setState({ loading: 'false' })
                    apiresponse = response.data['success'];
                    if (apiresponse !== null) {
                        self.setState({ count: 1, email: '' })
                    }
                    self.setState({ message: apiresponse })
                    window.setTimeout(() => {
                        self.setState({ message: [], count: null })
                    }, MESSAGE_TIME);
                })
                .catch((error) => {
                    this.scrollUpFunction();
                    self.setState({ loading: 'false' })
                    apiresponse = error.response.data['error'];
                    if (apiresponse !== null) {
                        self.setState({ count: 0 })
                    }
                    Object.keys(apiresponse).map(function (key, index) {
                        regError.push(apiresponse[key]);
                        return 0;
                    });
                    self.setState({ message: regError })
                    window.setTimeout(() => {
                        self.setState({ message: [], count: null })
                    }, MESSAGE_TIME);
                });
        }
        e.preventDefault();
    }
    render() {
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let token = '';
        if (storeitem !== null) {
            token = storeitem.token;
        }
        var span = {
            color: 'red'
        }
        return (
            //<!-- Main -->
            <main>
                {(token !== '') ?
                    <Redirect to="/mein-bereich" />
                    : null
                }
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                <section className="page-title-section theme-bg">
                    <div className="container-fluid">
                        <div className="row align-items-center justify-content-center no-gutters text-center min-height-form">
                            <div className="login-form col-12 col-sm-10 col-md-7 col-lg-5">
                                <div className="padding-login-form hs-form-field">
                                    {(this.state.count === 0) ?
                                        <span className="error_message output_message error message-padding">
                                            {this.state.message.map((msg, index) => <ListItem key={index} value={msg} />)}
                                        </span>
                                        : null
                                    }
                                    {(this.state.count === 1) ?
                                        <span className="error_message_success output_message success message-padding">
                                            {this.state.message}
                                        </span>
                                        : null
                                    }
                                    <h2 className="text-dark font-weight-bold mb-4">{FORGOTPASSWORD_CONSTANT[LANGUAGE_CODE]['forgot_password_title']}</h2>
                                    <h3 className="font-14 lh-24 fw-400 forgot-subtitle">{FORGOTPASSWORD_CONSTANT[LANGUAGE_CODE]['subtitle']}</h3>
                                    <form method="post" className="text-left" onSubmit={this.handleSubmit}>
                                        <div className={(this.state.email) ? "form-group hs-form-field focus" : "form-group hs-form-field"}>
                                            <input type="text" name="email" autoCapitalize="none" className={(this.state.fieldErrorMessage.filter(a => a === 'email' || a === 'emailvalidation').length > 0) ? " form-control hs-input field-highlight" : "form-control hs-input"} onChange={this.handleChange} value={this.state.email} />
                                            <label htmlFor="email adress">{FORGOTPASSWORD_CONSTANT[LANGUAGE_CODE]['email_address_label']}</label>
                                            <div>
                                                {(this.state.fieldErrorMessage.filter(a => a === 'email' || a === 'emailvalidation').length > 0) ?
                                                    <span style={span}>{FORGOTPASSWORD_ERROR[LANGUAGE_CODE][this.state.fieldErrorMessage.filter(a => a === 'email' || a === 'emailvalidation')]}</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group mt-4 mt-sm-5 font-size-btn">
                                            <input type="submit" className="btn btn-clr w-100 d-block login-btn text-uppercase text-white forgot-btn font-16" title={FORGOTPASSWORD_CONSTANT[LANGUAGE_CODE]['button_text']} value={FORGOTPASSWORD_CONSTANT[LANGUAGE_CODE]['button_text']} />
                                        </div>
                                    </form>
                                    <div className="btn-link-for-registrer mt-4 mt-sm-5 font-weight-bold">
                                        <span className="text-dark d-block d-sm-inline-block">{FORGOTPASSWORD_CONSTANT[LANGUAGE_CODE]['found_password']}</span>
                                        <Link to="/anmelden" title={FORGOTPASSWORD_CONSTANT[LANGUAGE_CODE]['sign_up_here']} className="text-blue d-block d-sm-inline-block ml-0 ml-sm-3">{FORGOTPASSWORD_CONSTANT[LANGUAGE_CODE]['sign_up_here']}</Link>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- col --> */}
                        </div>
                    </div>
                </section>
                {/* <!--login section end --> */}
            </main>
        );
    }
}
export default ForgotPassword;