import React from "react";
import SurveyRatingWithPointsQuestion from "./SurveyRatingWithPointsQuestion";
import SurveyRatingWithSchoolGradeQuestion from "./SurveyRatingWithSchoolGradeQuestion";
import SurveyRatingWithThumbQuestion from "./SurveyRatingWithThumbQuestion";
import SurveyRatingWithFreeTextQuestion from "./SurveyRatingWithFreeTextQuestion";
import SurveyRatingRangeQuestion from "./SurveyRatingRangeQuestion";
import SurveyRatingWithStaticQueation from "./SurveyRatingWithStaticQueation";
class SurveyRatingWithOneElementQuestion extends React.Component {
    section = (questiontype, data) => {
        var questioncomponent = questiontype;
        var questiondata = data
        switch (questioncomponent) {
            case 1:
                return < SurveyRatingWithPointsQuestion questiondata={questiondata} onChange={this.props.onChange} counter={this.props.counter} onButtonRating={this.props.onButtonRating} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} />
            case 2:
                return < SurveyRatingWithSchoolGradeQuestion questiondata={questiondata} onChange={this.props.onChange} onSchoolGrade={this.props.onSchoolGrade} counter={this.props.counter} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} />
            case 3:
                return < SurveyRatingWithThumbQuestion questiondata={questiondata} onChange={this.props.onChange} onButtonRating={this.props.onButtonRating} counter={this.props.counter} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} />
            case 4:
                return < SurveyRatingRangeQuestion questiondata={questiondata} onChange={this.props.onChange} onLoad={this.props.onLoad} counter={this.props.counter} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} />
            case 5:
                return < SurveyRatingWithFreeTextQuestion questiondata={questiondata} onChange={this.props.onChange} onSchoolGrade={this.props.onSchoolGrade} counter={this.props.counter} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} />
            case 6:
                return < SurveyRatingWithStaticQueation questiondata={questiondata} onChange={this.props.onChange} onSchoolGrade={this.props.onSchoolGrade} counter={this.props.counter} finalanswer={this.props.finalanswer} errormessage={this.props.errormessage} questionanswer={this.props.questionanswer} backButtonClick={this.props.backButtonClick} />
            default:
        }
    }
    render() {
        let data = this.props.questiondata;
        let questiontype = '';
        if (data) {
            questiontype = data.scale_type;
        }
        const element = this.section(questiontype, data)
        return (
            <div className="">{element}</div>
        )
    }
}
export default SurveyRatingWithOneElementQuestion;