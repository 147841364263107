import React from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import EmpiriowissenWörterbuchHeader from '../subPageHeader/SubPageHeader';
import { EMPIRIOWISSEN_CONSTANT, URL_CONSTANT, BUBBLE_LOADING } from '../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL } from '../../constants/DotEnv';
class EmpiriowissenWörterbuch extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
            loading: 'false',
            title: '',
            sub_title: '',
            dataLength: ''
        }
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.empiriowissenWörterbuchContent();
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
        if (count === -1) {
            document.querySelector('.navbar-collapse').classList.remove('show');
            document.querySelector('header').classList.remove("header_collapse");
            document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params['slug'] !== prevProps.match.params['slug']) {
            this.empiriowissenWörterbuchContent();
        }
    }
    empiriowissenWörterbuchContent() {
        this.setState({ loading: 'true' })
        var headers = {
            "Content-Type": "application/json"
        }
        let data = '';
        let dataLength = '';
        let title = '';
        let sub_title = '';
        let self = this;
        let guideId = this.props.match.params.slug;
        axios.get(API_URL + "/emperiowissen/" + guideId + "/" + LANGUAGE_CODE, { headers: headers })
            .then((response) => {
                data = response.data['success']['empiriowissen'];
                title = response.data['success']['title'];
                sub_title = response.data['success']['sub_title'];
                dataLength = Object.keys(data).length;
                self.setState({ data, loading: 'false', dataLength, title, sub_title })
            })
            .catch((error) => {
                self.setState({ loading: 'false', data, dataLength, title, sub_title });
            })
    }
    render() {
        let data = this.state.data;
        return (
            <main>
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {/* <!-- banner text ---> */}
                {// eslint-disable-next-line
                }<EmpiriowissenWörterbuchHeader data={this.state.data} title={this.state.title} sub_title={this.state.sub_title} dataLength={this.state.dataLength} />
                {/* <!-- banner-text-end --> */}
                {/* <!-- --> */}
                {(this.state.dataLength > 0 && this.state.loading === 'false') ?
                    <section className="empirical-research ">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-12">
                                    <h2 className="text-dark font-weight-bold">{data.title}</h2>
                                    <p className="text-dark font-weight-normal font-18"> <div dangerouslySetInnerHTML={{ __html: data.description }}></div></p>
                                    <div className="start-chapter text-center">
                                        <Link to={"/" + URL_CONSTANT['empiriowissen'] + "/"} className="btn btn-clr text-uppercase text-white font-18" title={EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['back']}>{EMPIRIOWISSEN_CONSTANT[LANGUAGE_CODE]['back']}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    : null
                }
                {/* <!-- end--> */}
            </main>
        );
    }
}
export default EmpiriowissenWörterbuch;