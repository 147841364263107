import React, { Fragment } from 'react';
import { SURVEY_CREATION_CONSTANT } from "../../../../constants/FrontEndConstant";
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
import { BlockPicker } from 'react-color'
class SurveyColorpicker extends React.Component {
    section = () => {
        var color = ["#ff9ff3","#feca57","#ff6b6b","#48dbfb","#1dd1a1","#f368e0","#ff9f43","#ee5253","#0abde3","#10ac84","#00d2d3","#54a0ff","#5f27cd","#c8d6e5","#576574","#01a3a4","#2e86de","#341f97","#8395a7","#222f3e"]
        return (
            <div className="hs-form-field">
                <h2 className="text-dark h3 font-weight-bold survey-title">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_colorpicker_heading']}</h2>
                {/* <h3 className="font-14 lh-24 fw-400 survey-subtitle">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_colorpicker_heading']}</h3> */}
                <div className="continue-btn survey-form-detail">
                <BlockPicker
                triangle="hide"
                colors={color}
                    color={ this.props.color }
                    onChangeComplete={  this.props.functioncolor }/>
                </div>
            </div>
        );
    }
    render() {
        let elements = '';
        let NSUuser = true;
        if (NSUuser) {
            elements = <div className={"tab-pane sectionstep"} id="survey-form-2" > {this.section()} </div >;
        }
        return (
            <Fragment>
                {elements}
            </Fragment>
        );
    }
}
export default SurveyColorpicker;