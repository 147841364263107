import React from 'react';
import { SURVEY_CREATION_CONSTANT } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
import * as loadImage from 'blueimp-load-image';
class SurveyContentPageImage extends React.Component {
    render() {
        let fieldName = this.props.fieldName;
        let surveyContent;
        if (fieldName) {
            surveyContent = this.props.data.surveyContent;
        } else {
            surveyContent = this.props.AllData.surveyData.surveyContent;
        }
        let index = this.props.index;
        let optionId;
        let optionImage;
        if (surveyContent) {
            if (surveyContent[index]) {
                optionId = surveyContent[index].id;
                optionImage = surveyContent[index].content_image;
                if (optionImage) {
                    if (optionImage.length === undefined) {
                        // eslint-disable-next-line
                        let self = this;
                        let ext = surveyContent[index].content_image.type.split('/').pop().toLowerCase();
                        if (ext === 'png' || ext === 'jpeg' || ext === 'jpg') {
                            loadImage.parseMetaData(surveyContent[index].content_image, function (data) {
                                //default image orientation
                                var orientation = 0;
                                //if exif data available, update orientation
                                if (data.exif) {
                                    orientation = data.exif.get('Orientation');
                                }
                                // eslint-disable-next-line
                                var loadingImage = loadImage(
                                    surveyContent[index].content_image,
                                    function (canvas) {
                                        //here's the base64 data result
                                        var base64data = canvas.toDataURL(surveyContent[index].content_image.type);
                                        //here's example to show it as on imae preview
                                        // eslint-disable-next-line
                                        var img_src = base64data.replace(/^data\:image\/\w+\;base64\,/, '');
                                        document.getElementById("file-upload-" + index).src = base64data;
                                    }, {
                                        canvas: true,
                                        orientation: orientation,
                                        maxHeight: 50,
                                        maxWidth: 50,

                                    }
                                );
                            });
                        }
                        else {
                            optionImage = window.URL.createObjectURL(surveyContent[index].content_image);
                        }
                    }
                }
            }
        }
        return (
            <div id={"d-flex align-items-center mt-4 removeImage-" + index} className={"d-flex align-items-center mt-4 removeImage-" + index} key={index}>
                <div id="errorSurveyMessage" className="error-text"></div>
                <label className="file">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_content_page_choose_image_button']}
                    <input type="file" className={"file-upload get-file fileUploadEvent" + index} data-value={index} title={index} data-id={optionId} name={"survey_welcome_image"} onChange={this.props.onUpdate} id="file1" style={{ "display": "none" }} />
                </label>
                <button className="btn p-0 icon-del mr-3" onClick={this.props.onUpdate} type="button" name="removeImage" id={optionId} value={index}><i className="fas fa-trash"></i></button>
                {// eslint-disable-next-line
                }<img width="50" height="50" className={(optionImage) ? "element-content image-tag element-content-img" : "element-content image-tag element-content-img d-none"} id={"file-upload-" + index} src={(optionImage) ? optionImage : null}></img>
            </div>
        )
    }
}
export default SurveyContentPageImage;