import React from "react";
import { SURVEY_LIST_CONSTANT, SURVEY_PARTICIPATION, BUBBLE_LOADING } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, IMAGE_BASEURL } from '../../../../constants/DotEnv';
import axios from 'axios';
import { Link } from 'react-router-dom';
class ParticipationEndingPage extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: "false",
            relatedsurvey: [],
        }
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.getRelatedSurvey();
    }
    /** this function is for scrolling up page */
    scrollUpFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    /** API call for the related survey */
    getRelatedSurvey = () => {
        this.setState({ loading: "true" })
        let self = this;
        let relatedsurvey = '';
        let category_id = "";
        let survey_id = "";
        let surveydetail;
        if (this.props.surveyId) {
            surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + this.props.surveyId))
        }
        if (surveydetail !== null) {
            survey_id = surveydetail.id
            category_id = surveydetail.category_id
        }
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let userid = '';
        if (storeitem !== null) {
            userid = storeitem.id;
        }
        axios.get(API_URL + '/commonApi/relatedsurvey/' + LANGUAGE_CODE + '/' + category_id + '/' + survey_id + '?userid=' + userid)
            .then((response) => {
                relatedsurvey = response.data.success.surveydata;
                self.setState({ relatedsurvey, loading: "false" })
            })
            .catch((error) => {
                self.setState({ loading: 'false' })
            })
    }
    render() {
        /**for identifying chrome and safari browser */
        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        var issafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        let title = '';
        let length = this.state.relatedsurvey.length;
        let relatedsurvey = ""
        let relatedsurveydata = '';
        if (length !== '' || this.state.length > 0) {
            relatedsurvey = this.state.relatedsurvey;
            /*related survey start*/
            relatedsurveydata = relatedsurvey.map((relateddata, index) => {
                let startdate = relateddata['start_date'].replace(/-/g, '/');
                let enddate = relateddata['end_date'].replace(/-/g, '/');
                let currentdate = new Date();
                enddate = new Date(enddate);
                startdate = new Date(startdate);
                var timeDiff = enddate.getTime() - currentdate.getTime();
                var daysleft = Math.ceil(timeDiff / (1000 * 3600 * 24));
                daysleft = daysleft + 1;
                let newDate = new Date();
                var changeCurrentDate = new Date(newDate.setHours(0, 0, 0, 0));
                if (Date.parse(startdate) <= Date.parse(changeCurrentDate) && Date.parse(changeCurrentDate) <= Date.parse(enddate)) {
                    let timediff = Math.abs(enddate.getTime() - startdate.getTime());
                    let diffday2 = Math.ceil(timediff / (1000 * 3600 * 24));
                    let totaldate = Math.abs(changeCurrentDate.getTime() - startdate.getTime());
                    let diffday1 = Math.ceil(totaldate / (1000 * 3600 * 24));
                    let cal = diffday1 / (diffday2 + 1) * 100;
                    var currentwidth = cal.toFixed('2');
                }
                else if (startdate > currentdate) {
                    currentwidth = '0';
                }
                else {
                    currentwidth = '100';
                }
                currentwidth = parseInt(currentwidth);
                title = relateddata['survey_title_slug'];
                let isparticipated = relateddata['isparticipate'];
                let participatemessage = ''
                if (isparticipated === '1') {
                    participatemessage = SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['alredy_participate'];
                }
                let titleNew;
                if (relateddata['survey_title']) {
                    if (window.innerWidth >= 1200 && window.innerWidth < 1500) {
                        if (relateddata['survey_title'].length > 48) {
                            titleNew = relateddata['survey_title'].substring(0, 48).trim() + "...";
                        } else {
                            titleNew = relateddata['survey_title']
                        }
                    }
                    else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
                        if (relateddata['survey_title'].length > 59) {
                            titleNew = relateddata['survey_title'].substring(0, 59).trim() + "...";
                        } else {
                            titleNew = relateddata['survey_title']
                        }
                    }
                    else if (window.innerWidth >= 768 && window.innerWidth < 992) {
                        if (relateddata['survey_title'].length > 80) {
                            titleNew = relateddata['survey_title'].substring(0, 80).trim() + "...";
                        } else {
                            titleNew = relateddata['survey_title']
                        }
                    }
                    else if (window.innerWidth >= 576 && window.innerWidth < 768) {
                        if (relateddata['survey_title'].length > 152) {
                            titleNew = relateddata['survey_title'].substring(0, 152).trim() + "...";
                        } else {
                            titleNew = relateddata['survey_title']
                        }
                    }
                    else if (window.innerWidth >= 414 && window.innerWidth < 576) {
                        if (relateddata['survey_title'].length > 105) {
                            titleNew = relateddata['survey_title'].substring(0, 105).trim() + "...";
                        } else {
                            titleNew = relateddata['survey_title']
                        }
                    }
                    else if (window.innerWidth >= 320 && window.innerWidth < 414) {
                        if (relateddata['survey_title'].length > 82) {
                            titleNew = relateddata['survey_title'].substring(0, 82).trim() + "...";
                        } else {
                            titleNew = relateddata['survey_title']
                        }
                    }
                    else if (window.innerWidth < 320) {
                        if (relateddata['survey_title'].length > 75) {
                            titleNew = relateddata['survey_title'].substring(0, 75).trim() + "...";
                        } else {
                            titleNew = relateddata['survey_title']
                        }
                    }
                    else {
                        if (relateddata['survey_title'].length > 77) {
                            titleNew = relateddata['survey_title'].substring(0, 77).trim() + "...";
                        } else {
                            titleNew = relateddata['survey_title'];
                        }
                    }
                }
                let backImage = IMAGE_BASEURL + "img/100x100.jpg"
                if (relateddata['thumb_image']) {
                    backImage = relateddata['thumb_image'];
                }
                return (<div className="col-md-6 col-lg-4 col-xl-3 blog-lising" key={index}>
                    <div className="blog-item">
                        <Link to={"/umfragen/" + title}>
                            <div className="blog-img position-relative h-220 background-image-css" style={{ "backgroundImage": "url(" + backImage + ")" }}>
                            </div>
                            <div className="blog-content">
                                <h6 className={(issafari || isChrome) ? "text-dark font-16 custom-test-text text-left" : "text-dark font-16 text-hyphens text-left"}>{(issafari || isChrome) ? relateddata['survey_title'] : titleNew}</h6>
                            </div>
                            {(relateddata['userimage']) ? <div className="user_profile_photo"><div className="login-profile profile_pic">
                                <img src={relateddata['userimage']} className="rounded-circle center" alt="person-img" width="70" height="70" />
                            </div></div>
                                : null}
                            {(parseInt(isparticipated) === 1) ?
                                <div className="progress custom-main-progress">
                                    <span className="progress-value custom-progress">{participatemessage}</span>
                                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ "ariaValuenow": "10", "ariaValuemin": "0", "ariaValuemax": "100" }}></div>
                                </div>
                                : <div className="progress">
                                    <span className="progress-value">{participatemessage}</span>
                                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ "width": currentwidth + "%", "ariaValuenow": "10", "ariaValuemin": "0", "ariaValuemax": "100" }}></div>
                                </div>
                            }
                            <div className="blog-content d-flex justify-content-around">
                                <ul className="list-unstyled mb-2 mr-4">
                                    <li className="text-gray mb-2 d-flex"><i className="ti-star mr-0 mr-3 font-18"></i><div className="blog__card-title d-block text-left"><span className="d-block">{relateddata['karmapoint']}</span> {SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['karmapoint']}</div></li>
                                    <li className="text-gray d-flex"><i className="ti-user mr-3 font-18"></i><div className="blog__card-title d-block text-left"><span className="d-block">{relateddata['totaluser']}</span> {SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['totaluser']}</div></li>
                                </ul>
                                <ul className="list-unstyled mb-2">
                                    <li className="text-gray mb-2 d-flex"><i className="ti-time mr-3 font-18"></i><div className="blog__card-title d-block text-left"><span className="d-block">{relateddata['duration']}</span> {SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['duration']}</div></li>
                                    <li className="text-gray d-flex"><i className="ti-tag mr-3 font-18"></i><div className="blog__card-title d-block text-left"><span className="d-block">{daysleft}</span>{SURVEY_LIST_CONSTANT[LANGUAGE_CODE]['daysleft']}</div></li>
                                </ul>
                            </div>{/* /blog-content */}
                        </Link>
                    </div>
                </div>)
            })
        }
        return (
        <div>
            <div className="container survey-form custom_survey_card survey-form-cust">
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                <h2 className="text-dark font-weight-bold text-center">{SURVEY_PARTICIPATION[LANGUAGE_CODE]['participationmessage']}</h2>
                {(relatedsurveydata.length > 0) ?
                    <div className="text-center">
                        <div className="row my-4 my-xl-5">
                            {relatedsurveydata}
                        </div>
                    </div>
                    : null}
            </div>
        </div>
        );
    }
}
export default ParticipationEndingPage;