import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { removeUser } from "../../actions";
import { RESETPASSWORD_ERROR, RESETPASSWORD_CONSTANT, META_TITLE_CONSTANT, META_TAGS_CONSTANT, BUBBLE_LOADING, META_FACEBOOK } from "../../constants/FrontEndConstant";
import { LANGUAGE_CODE, API_URL, MESSAGE_TIME, FRONT_USER, META_TITLE_APPEND } from '../../constants/DotEnv';
const ListItem = ({ value }) => (<span> {value}<br /></span>);
const mapStateToProps = state => {
    return { newuserdata: state.LogInUserData };
};
function mapDispatchToProps(dispatch) {
    return {
      removeUser: userdata => dispatch(removeUser(userdata))
    };
}
class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            newPassword: '',
            confirmPassword: '',
            message: [],
            count: '',
            loading: 'false',
            fieldErrorMessage: [],
            error: 0
        };
        this.validPassword = true;
        let meta_title = META_TITLE_CONSTANT[LANGUAGE_CODE]['resetpassword'] + META_TITLE_APPEND;
        let meta_description = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_description'];
        let meta_keywords = META_TAGS_CONSTANT[LANGUAGE_CODE]['meta_keywords'];
        let og_title = META_TITLE_CONSTANT[LANGUAGE_CODE]['resetpassword'] + META_TITLE_APPEND;
        let og_description = META_FACEBOOK['og_description'];
        let og_image = META_FACEBOOK['og_image'];
        setTimeout(() => {
            window.setMeta({ "description": meta_description, "keywords": meta_keywords })
            window.setOgTag(og_title, og_description, og_image, window.location.href)
            window.setDocumentTitle(meta_title)
        }, 200);
    }
    componentWillUnmount() {
        setTimeout(() => {
            window.setOgTag(META_FACEBOOK['og_title'], META_FACEBOOK['og_description'], META_FACEBOOK['og_image'], META_FACEBOOK['og_url'])
        }, 100)
    }
    componentDidMount() {
        if(JSON.parse(localStorage.getItem('userdata')) !== null){
            this.logoutFunction(JSON.parse(localStorage.getItem('userdata')));
        }
        this.scrollUpFunction()
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
        if (count === -1) {
            document.querySelector('.navbar-collapse').classList.remove('show');
            document.querySelector('header').classList.remove("header_collapse");
            document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
        }
    }
    componentWillMount() {
        this.setState({ id: this.props.match.params.id });
    }
    handleChange = (e) => {
        if(this.validPassword) {
            const name = e.target.name;
            let value = e.target.value;
            value = value.replace(/\s/g,'');
            this.setState({ [name]: value });
            var index = this.state.fieldErrorMessage.indexOf(name);
            var indexREPwd = this.state.fieldErrorMessage.indexOf(name + "LengthValidation");
            if (indexREPwd > -1) {
                this.state.fieldErrorMessage.splice(indexREPwd, 1);
            }
            if (index > -1) {
                this.state.fieldErrorMessage.splice(index, 1);
            }
        }
        else {
            this.validPassword = true;
        }
    }
    onKeyDownPassword = (event) =>{
        var key = event ? event.which : window.event.keyCode;
        if (key === 32) {
            this.validPassword = false;
            return false;
        }
    }
    logoutFunction = async (storeitem) => {
        this.setState({ loading: 'true' })
        let apitoken = storeitem.token;
        let token = storeitem.token;
        let userid = storeitem.id;
        var headers = {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + apitoken
        }
        var data = {
          userid: userid,
          languagecode: LANGUAGE_CODE
        }
        this.props.removeUser({ token });
        localStorage.clear();
        let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
        if (count === -1) {
          document.querySelector('.navbar-collapse').classList.remove('show');
          document.querySelector('header').classList.remove("header_collapse");
          document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
        }
        // eslint-disable-next-line
        await axios.post(API_URL + "/logout" + '?uId=' + userid, data, { headers: headers })
          .then()
          .catch((error) => {
            console.log(error);
          })
        this.setState({ loading: 'false' })
        return this.props.removeUser({ token });
    }
    handleSubmit = (e) => {
        var resetpasswordfield = {
            newPassword: this.state.newPassword,
            confirmPassword: this.state.confirmPassword
        }
        var a = [];
        this.setState({ fieldErrorMessage: a })
        var errorCount = 0;
        var error = ''
        for (var key in resetpasswordfield) {
            if (resetpasswordfield.hasOwnProperty(key)) {
                var errorkey = key;
                const value = resetpasswordfield[errorkey];
                if (value === '' || value === null || value === undefined) {
                    a.push(errorkey);
                    error = errorCount + 1;
                    errorCount = error;
                }
            }
        }
        if(errorCount === 0 && this.state.newPassword.length < 8 ){
            errorCount = 1;
            this.setState({ count: 0, message:[RESETPASSWORD_ERROR[LANGUAGE_CODE]['passwordLength']] })
            window.setTimeout(() => {
                this.setState({ message: [], count: null })
            }, MESSAGE_TIME);
        }
        this.setState({ error: errorCount, fieldErrorMessage: a });
        if (errorCount === 0) {
            this.setState({ message: [], loading: 'true' })
            let apiresponse = "";
            let regError = [];
            let self = this;
            axios.post(API_URL + FRONT_USER + "/resetpassword", {
                userid: self.state.id,
                password: self.state.newPassword,
                languagecode: LANGUAGE_CODE,
                confirm_password: self.state.confirmPassword
            })
                .then((response) => {
                    this.scrollUpFunction();
                    apiresponse = response.data;
                    self.setState({ loading: 'false' })
                    apiresponse = response.data['success'];
                    if (apiresponse !== null) {
                        self.setState({ count: 1, newPassword: '', confirmPassword: '' })
                    }
                    self.setState({ message: apiresponse })
                    window.setTimeout(() => {
                        self.setState({ redirectToLogin: true })
                    }, MESSAGE_TIME / 2);
                    window.setTimeout(() => {
                        self.setState({ message: [], count: null, redirectToLogin: true })
                    }, MESSAGE_TIME);
                })
                .catch((error) => {
                    this.scrollUpFunction();
                    self.setState({ loading: 'false' })
                    apiresponse = error.response.data['error'];
                    if (apiresponse !== null) {
                        self.setState({ count: 0 })
                    }
                    Object.keys(apiresponse).map(function (key, index) {
                        regError.push(apiresponse[key]);
                        return 0;
                    });
                    self.setState({ message: regError })
                    window.setTimeout(() => {
                        self.setState({ message: [], count: null })
                    }, MESSAGE_TIME);
                });
        }
        e.preventDefault();
    }
    render() {
        var span = {
            color: 'red'
        }
        return (
            //<!-- Main -->
            <main>
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {(this.state.redirectToLogin === true) ?
                    <Redirect to='/anmelden' />
                    : null
                }
                <section className="page-title-section theme-bg">
                    <div className="container-fluid">
                        <div className="row align-items-center justify-content-center no-gutters text-center min-height-form">
                            <div className="login-form col-12 col-sm-10 col-md-7 col-lg-5">
                                <div className="padding-login-form hs-form-field">
                                    {(this.state.count === 0) ?
                                        <span className="error_message output_message error message-padding">
                                            {this.state.message.map((msg, index) => <ListItem key={index} value={msg} />)}
                                        </span>
                                        : null
                                    }
                                    {(this.state.count === 1) ?
                                        <span className="error_message_success output_message success message-padding">
                                            {this.state.message}
                                        </span>
                                        : null
                                    }
                                    <h2 className="text-dark font-weight-bold mb-4">{RESETPASSWORD_CONSTANT[LANGUAGE_CODE]['reset_password_form_title']}</h2>
                                    <form method="post" className="text-left" onSubmit={this.handleSubmit}>
                                        <div className={(this.state.newPassword) ? "form-group hs-form-field focus" : "form-group hs-form-field"}>
                                            {// eslint-disable-next-line
                                            }<input type="password" name="newPassword" className={(this.state.fieldErrorMessage.filter(a => a === 'newPassword').length > 0) ? " form-control hs-input field-highlight" : "form-control hs-input"} onChange={this.handleChange} value={this.state.newPassword} onKeyDown={(event) => this.onKeyDownPassword(event)} />
                                            <label htmlFor="newPassword">{RESETPASSWORD_CONSTANT[LANGUAGE_CODE]['new_password']}</label>
                                            <div>
                                                {(this.state.fieldErrorMessage.filter(a => a === 'newPassword').length > 0) ?
                                                    <span style={span}>{RESETPASSWORD_ERROR[LANGUAGE_CODE]['newPassword']}</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className={(this.state.confirmPassword) ? "form-group hs-form-field focus" : "form-group hs-form-field"}>
                                            {// eslint-disable-next-line
                                            }<input type="password" name="confirmPassword" className={(this.state.fieldErrorMessage.filter(a => a === 'confirmPassword').length > 0) ? " form-control hs-input field-highlight" : "form-control hs-input"} onChange={this.handleChange} value={this.state.confirmPassword} onKeyDown={(event) => this.onKeyDownPassword(event)} />
                                            <label htmlFor="confirmPassword">{RESETPASSWORD_CONSTANT[LANGUAGE_CODE]['confirm_password']}</label>
                                            <div>
                                                {(this.state.fieldErrorMessage.filter(a => a === 'confirmPassword').length > 0) ?
                                                    <span style={span}>{RESETPASSWORD_ERROR[LANGUAGE_CODE]['confirmPassword']}</span>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group mt-4 mt-sm-5 font-size-btn">
                                            <input type="submit" className="btn btn-clr w-100 d-block login-btn text-uppercase text-white forgot-btn font-16" title={RESETPASSWORD_CONSTANT[LANGUAGE_CODE]['reset_password_button']} value={RESETPASSWORD_CONSTANT[LANGUAGE_CODE]['reset_password_button']} />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* <!-- col --> */}
                        </div>
                    </div>
                </section>
                {/* <!--login section end --> */}
            </main>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);