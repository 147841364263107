import React, { Fragment } from 'react';
import axios from "axios";
import {Link} from "react-router-dom";
import { SURVEY_CREATION_CONSTANT, BUBBLE_LOADING } from '../../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL } from '../../../../constants/DotEnv';
import Redirect from 'react-router-dom/Redirect';
import Button from '@material-ui/core/Button';
class SurveyPicture extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: 'false',
            redirect: 'false',
            isPremiumUser:false,
            imgUploaded:false,
            imgpoolLength:0,
            img_error: '',
        }
    }
    componentWillMount() {
        if (this.props.data.category_id) {
            this.getSurveyCategoryPictures(this.props.data.category_id);
        }
    }
    componentDidMount(){
        let userData = JSON.parse(localStorage.getItem('userdata'))
        this.setState({isPremiumUser : userData.plan_type})
        this.setState({account_type_category_id : userData.account_type_category_id})
    }
    getSurveyCategoryPictures = async (surveyCategory) => {
        this.setState({ loading: 'true' })
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let userId = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
            userId = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        let data = '';
        let self = this;
        await axios.get(API_URL + '/imagepool/getImageByCategoryId/' + LANGUAGE_CODE + '/' + surveyCategory + '?uId=' + userId +'&survey_id='+this.props.data.id, { headers: headers })
            .then((response) => {
                data = response.data['success'].imagepool;
                self.setState({imgpoolLength:data.length - 1})
                self.setState({ data, loading: 'false' })
            })
            .catch((error) => {
                if (error) {
                    if (error.response) {
                        if (error.response.data) {
                            let msg = error.response.data['error']
                            if (msg === 'Expired token' || msg === 'Invalid token') {
                                localStorage.clear();
                                self.setState({ redirect: 'true' })
                            }
                        }
                    }
                }
                self.setState({ loading: 'false' })
            })
    }

    labelOnClick = (e) => {
        if (e.currentTarget.name) {
            document.getElementById(e.currentTarget.name).click();
            // document.getElementById(e.currentTarget.name).checked = !(document.getElementById(e.currentTarget.name).checked);
        }
    }

    customImgChange = (e) =>
    {
        const fileInputId = document.getElementById('fileInput');
        document.getElementById('fileInput').onchange = () =>
        {
            if(fileInputId.files.length)
            {
                this.setState({imgUploaded : true});
            }
            else
            {
                this.setState({imgUploaded :false})
            }
        }
    }

    makeDefaultCheckTrue = () =>
    {
        if(this.state.isPremiumUser)
        {
            if(this.state.imgUploaded)
            {
                document.getElementById(`customRadioInline${this.state.imgpoolLength}`).checked = true;
            }
        }
    }

    /** this function is for scrolling up page */
    scrollUpFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    /**for uploading custom background image for plus package users*/
    handleUpload = async (event) =>
    {
        const fileInputId = document.getElementById('fileInput');
        if(fileInputId.files.length)
        {
            this.setState({ loading: 'true' })
            let formData = new FormData();
            formData.append("uploadedImageDetails[]",event.target.files[0] ); 
            let storeitem = JSON.parse(localStorage.getItem('userdata'));
            let apitoken = '';
            let userId = '';
            if (storeitem !== null) {
                apitoken = storeitem.token;
                userId = storeitem.id;
            }
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + apitoken
            }
            await axios.post(API_URL + '/imagepool/setImageForPlusPlanuser/' + LANGUAGE_CODE + '/' + this.props.data.category_id + '?uId=' + userId + '&survey_id=' + this.props.data.id,formData,{ headers: headers })
                .then(async(response) => {
                    await this.getSurveyCategoryPictures(this.props.data.category_id);
                    if(response.data.error){
                        this.setState({img_error : response.data.error });
                        this.scrollUpFunction();
                    }else{
                        this.makeDefaultCheckTrue();
                        this.setState({img_error : null})
                    }
                })
                .catch((error) => {
                    if (error) {
                        if (error.response) {
                            if (error.response.data) {
                                let msg = error.response.data['error']
                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                    localStorage.clear();
                                    this.setState({ redirect: 'true' })
                                }
                            }
                        }
                    }
                    this.setState({ loading: 'false' })
                })
                this.setState({ loading: 'false' })
        }
    }

    /**data is props data and dataNew is state data*/
    SurveyPic = (data, dataNew) => {
        let rows = [];
        let dataTotalLength = Object.keys(dataNew).length;
        for (var j = 0; j < dataTotalLength; j++) {
            rows.push(
                <div className="col-12 col-md-4 col-lg-3 pb-3 pt-lg-0" key={j}>
                    <label htmlFor={"customRadioInline" + j} className="MuiButton-effect w-100 mb-0 pointer survey-pic-shadow" key={j}>
                        <Button variant="contained"  name={"customRadioInline" + j} onClick={(e)=>this.labelOnClick(e)}>
                            <div className="fill-by-img" style={{ "backgroundImage": "url(" + dataNew[j].image_path + ")" }}>
                            </div>
                            <div className="custom-radio-btn btn w-100">
                                <div className="custom-control custom-radio custom-control-inline pl-0 w-100">
                                  
                                    <div className="custom-control-label btn-radio mb-0 btn-radio-spacing" >
									  <input type="radio" id={"customRadioInline" + j} name="survey_picture" className="custom-control-input" defaultChecked={data === dataNew[j].id} value={dataNew[j].id} onClick={this.props.onClick} onChange={this.props.onClick} />
                                    <svg width="20px" height="20px" viewBox="0 0 20 20">
                                        <circle cx="10" cy="10" r="9"></circle>
                                        <path  style={{"--colorpicker": "#00b3f4" }} d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                        <path style={{"--colorpicker": "#00b3f4" }} d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                                    </svg>
                                    </div>
                                </div>
                            </div>
                        </Button>
                    </label>
                </div>
            )
        }
        return (
        <div className="row no-gutters survey-form-detail" >
            <div className="col-12">
                <div className="row survey-picture">
                    {rows}
                    {this.state.isPremiumUser ?
                        <div className="col-12 col-md-4 col-lg-3 pb-3 pt-lg-0" onClick={()=>{document.getElementById('fileInput').click();this.customImgChange();}} >
                            <label htmlFor='customRadioInline'  className="MuiButton-effect w-100 mb-0 pointer survey-pic-shadow" >
                                <Button variant="contained" className='MuiButtonBase-root MuiButton-root MuiButton-contained' tabIndex='0' type='button'  >
                                    <span className='MuiButton-label'>
                                        <div className="fill-by-img icon-center" >
                                            <div className="icon card-img icon-center" style={{alignItems: "center",height:'201px'}}>
                                                <input type="file" id="fileInput" accept="image/*" onChange={this.handleUpload} />
                                                    <i className="ti-plus icon-m border-radius btn-clr create-plus" ></i>
                                            </div>
                                        </div>
                                    </span>
                                </Button>
                            </label>
                        </div>
                        :
                        <Link className="col-12 col-md-4 col-lg-3 pb-3 pt-lg-0" to={this.state.account_type_category_id == 4 ? '/business-checkout' : this.state.account_type_category_id == 2 ?  "/university-checkout" :'/checkout'}>
                            <label htmlFor='customRadioInline'  className="MuiButton-effect w-100 mb-0 pointer survey-pic-shadow" >
                                <Button variant="contained" className='MuiButtonBase-root MuiButton-root MuiButton-contained' tabIndex='0' type='button' color="#00b3f4" name='customRadioInline' onClick={() => localStorage.setItem('redirectURL',window.location.href)}>
                                    <span className='MuiButton-label'>
                                        <div className="fill-by-img icon-center" >
                                            <div className="icon card-img icon-center" style={{alignItems: "center"}}>
                                                <input type="file" id="fileInput" />
                                                    <i className="ti-plus icon-m border-radius btn-clr create-plus" ></i>
                                            </div>
                                        </div>
                                        <div className="custom-radio-btn btn w-100">
                                            <div className="custom-control custom-radio custom-control-inline pl-0 w-100">
                                                <div className="custom-control-label btn-radio mb-0 btn-radio-spacing text-dark font-20" ><i className='ti-lock'></i></div>
                                            </div>
                                        </div>
                                    </span>
                                </Button>
                            </label>
                        </Link>
                    }
                </div>
            </div>
        </div>);
    }
    render() {
        let data = this.props.data;
        let dataNew = this.state.data;
        let getAllImage = this.SurveyPic(parseInt(data.survey_picture), dataNew);
        return (
            <Fragment>
                    {(this.state.redirect === 'true') ?
                        <Redirect to="/anmelden"></Redirect>
                        : null
                    }
                    {(this.state.loading === 'true') ?
                        BUBBLE_LOADING
                        : null
                    }
                <div className={"hs-form-field custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")}>
                    <h2 className="text-dark h3 font-weight-bold survey-title">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_picture_page_title1']}</h2>
                    <h3 className="font-14 lh-24 fw-400 survey-subtitle">{SURVEY_CREATION_CONSTANT[LANGUAGE_CODE]['survey_picture_page_subtitle']}</h3>
                    <div id="errorSurveyMessage" className="error-text mt-2">{(this.props.validationMessage) ? <p>{this.props.validationMessage}</p> : null}</div>
                    <div id="errorSurveyMessage" className="error-text mt-2">{(this.state.img_error) ? <p>{this.state.img_error}</p> : null}</div>
                    {getAllImage}
                </div>
            </Fragment>
        );
    }
}
export default SurveyPicture;