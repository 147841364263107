import React from 'react';
import Slider from '@material-ui/core/Slider';

class SurveyRatingWithMoreProbabiltyQuestion extends React.Component {
    handleChange = (event, newValue,id) => {
        this.props.onLoad(event,newValue,id);
        for(let i=0; i<this.props.questiondata.option.length; i++)
        {
            var surveydetail = JSON.parse(localStorage.getItem("surveydetail_" + this.props.questiondata.survey_id))
            var survey_color;
            if(surveydetail.survey_color) survey_color = surveydetail.survey_color
            else survey_color = "#00b3f4"
            if(this.props.questiondata.option[i].id == id)
                document.getElementsByClassName("MuiSlider-root")[i].style.color = survey_color;
        }
    };
    render() {
        let data = this.props.questiondata;
        let option = data.option.map((scale, index) => {
            return (
                <div key={index} className={(index) ? "text-center  mt-5 " : "text-center"}>
                    <div className="col-12 text-center mb-4">
                        <span class="font-size font-weight-bold">{scale.option_text}</span>
                    </div>
                    <div className="box-shadow box-shadow-hover pl-3 pr-3 pt-5 pb-4">
                        <div className="MuiSlider-main">
                            <Slider
                                className="selected-probability"
                                id={data.question_id}
                                name={scale.id}
                                key = {data.question_id}
                                value={
                                (this.props.questionanswer.option === undefined) ?
                                    (this.props.questionanswer[scale.id] === undefined)
                                        ? 50
                                        : (this.props.questionanswer[scale.id])
                                    : (this.props.questionanswer.option === undefined)
                                        ? 50
                                        : (this.props.questionanswer.option.findIndex(ans => ans.option_id === scale.id) > -1)
                                            ? this.props.questionanswer.option[this.props.questionanswer.option.findIndex(ans => ans.option_id === scale.id)]['answer']
                                            : 50
                                }
                                valueLabelDisplay="auto"
                                step={1}
                                onChange={(event,newValue)=>this.handleChange(event,newValue,scale.id)}
                            />
                            <div className="MuiSlider-per">
                                <span className="d-inline-block font-weight-bold">0%</span>
                                <span className="d-inline-block font-weight-bold">100%</span>
                            </div>
                        </div>
                    </div>
                </div>)
        })
        return (
            <div key={this.props.questiondata.question_id} className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
                <div className="text-center">
                    <div className="question-heading text-left">
                        <h3 className="text-dark font-weight-bold text-break survey-title">{data.question_text}</h3>
                        {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
                    </div>
                    {option}
                </div>
            </div>
        )
    }
}
export default SurveyRatingWithMoreProbabiltyQuestion;