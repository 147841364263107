import React from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import { MYPROFILE_POLLS_CONSTANT, URL_CONSTANT, BUBBLE_LOADING, MYPROFILE_MAINPROFILE_CONSTANT } from '../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, BASEURL, MESSAGE_TIME, IMAGE_BASEURL } from '../../constants/DotEnv';
const ListItem = ({ value }) => (<span> {value}</span>);
class Polls extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: 'false',
            mypollscontent: {},
            redirectToEdit: "false",
            redirectToFindParticipate: "false",
            redirectToLiveTest: "false",
            survey_id: "",
            checkStatus: '',
            active_package: '',
            redirectToCheckout: false,
            copyData: false,
            isPlanExpired:false
        }
        this.redirectToPreview = false;
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.getMyPolls();
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (document.querySelector('.navbar-toggler')) {
            let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
            if (count === -1) {
                document.querySelector('.navbar-collapse').classList.remove('show');
                document.querySelector('header').classList.remove("header_collapse");
                document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
            }
        }
    }
    /** function for redirect to find participation tab on click of survey image or title */
    addSurveyId = async (pollsdata, event, tab) => {
        window.history.pushState(null, document.title, window.location.href);
        event.preventDefault();
        this.setState({ redirectToFindParticipate: "false" })
        let data = pollsdata
        let st = "";
        if (data) {
            if (data.id) {
                this.surveyMainId = data.id;
            }
            if (data.survey_title_slug) {
                this.titleSlug = data.survey_title_slug;
            }
            if (data.status) {
                st = data.status;
            }
            // eslint-disable-next-line
            var survey_id = data.id
            localStorage.setItem('editsurveyactivetab', tab);
            this.setState({ redirectToFindParticipate: "true", checkStatus: st });
        }
    }
    /**onclick of survey creation check university and remove surveycreationeditid, surveycreationdata, surveycreationallquestiondata from the localstorage*/
    removeId = (e) => {
        let href = window.location.href;
        let titleDoc = document.title
        window.history.pushState(null, titleDoc, href);
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let accountType = storeitem.account_type_category_id;
        let showpopup = false
        if(accountType){
            if(accountType <= 2){
                if(!storeitem.university) showpopup = true
            }
            else if(accountType === 3){
                if(!storeitem.current_school) showpopup = true
            }
            else if(accountType === 4){
                if(!storeitem.organization) showpopup = true
            }
        }
        if(showpopup){
            e.preventDefault();
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <div className="close-btn text-right">
                                <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                            </div>
                            <p className="mt-3 mb-4">{accountType <= 2 ? MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['user_university_not_exist'] : accountType === 3 ?  MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['user_current_school_not_exist'] : accountType === 4 ?  MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['user_organization_not_exist'] : null  }</p>
                            <button onClick={onClose} >{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['university_popup_close_button']}</button>
                        </div>
                    )
                }
            })
        }
        localStorage.removeItem('surveyparticipationquestion');
        localStorage.removeItem('surveydetail');
        localStorage.removeItem("surveycreationeditid")
        localStorage.removeItem("surveycreationdata")
        localStorage.removeItem("surveycreationallquestiondata")
    }
    /** API call for geting user's own created survey */
    getMyPolls = async () => {
        this.setState({ loading: 'true' })
        let self = this;
        let mypollscontent = '';
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let token = '';
        let userid = '';
        if (storeitem !== null) {
            token = storeitem.token;
            userid = storeitem.id;
            this.setState({isPlanExpired:storeitem.isPlanExpire})
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
        await axios.get(API_URL + '/userssurvey/mySurvey/' + LANGUAGE_CODE + "/" + userid + '?uId=' + userid, { headers: headers })
            .then((response) => {
                mypollscontent = response.data.success.Mysurvey;
                self.setState({ loading: 'false', mypollscontent })
                localStorage.setItem("mySurveyList", JSON.stringify(mypollscontent))
            })
            .catch((error) => {
                self.setState({ loading: 'false' })
            })
    }
    // To edit survey click to display popup message
    editSurveyMessageShow = (data, event) => {
        let isPublished = '';
        if (data.ispublish !== '') {
            isPublished = data.ispublish;
        }
        // Survey result is publish then not edit survey question
        if (isPublished === 1) {
            confirmAlert({
                customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <div className="close-btn text-right">
                            <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                        </div>
                        <h3 className="mt-3 mb-4">{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['toedit_popup_heading']}</h3>
                        <p className="mt-3 mb-4">{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['toedit_popup_subtext']}</p>
                        <button className="ml-0" onClick={(event) => {
                            onClose()
                        }} >{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['no_question_found_ok_button']}</button>
                    </div>
                )
                }
            })
        }
        // If survey result not published then to edit survey question
        if (isPublished === 0) {
            this.addEditSurveyId(data, event);
        }
    }
    /**this function is for edit survey 
     * when survey status is published 
     * then it can not edit it and display popup with message
    */
    addEditSurveyId = (data, event) => {
        window.history.pushState(null, document.title, window.location.href);
        this.setState({ loading: "true" })
        // eslint-disable-next-line
        let editid = { "id": data.id }
        let surveyStatus = data.status;
        event.preventDefault();
        localStorage.removeItem("surveycreationeditid")
        if (data) {
            if (data.id) {
                localStorage.removeItem("surveycreationdata_" + data.id)
                localStorage.removeItem("surveycreationallquestiondata_" + data.id)
                this.surveyMainId = data.id;
            }
            if (data.survey_title_slug) {
                this.titleSlug = data.survey_title_slug;
            }
        }
        if (surveyStatus) {
            this.setState({ redirectToEdit: "true", loading: "false" })
        }
    }
    /**this function is for live test which first check if survey has question 
     * then only can preview it otherwise display popup with message that 
     * there is no question in survey */
    liveTestStatusCheck = async (data, event) => {
        event.preventDefault();
        this.setState({ loading: 'true' })
        var isPreview = false;
        if (data.is_question) {
            this.redirectToPreview = true;
        }
        else {
            isPreview = true;
        }
        let survey_data = data
        if (survey_data) {
            var survey_id = survey_data.id
            this.surveyMainId = parseInt(survey_id);
            if (survey_data.survey_title_slug) {
                this.titleSlug = data.survey_title_slug;
            }
            // eslint-disable-next-line
            let self = this;
            // eslint-disable-next-line
            let responseQuestion = ""
            let survey_detail = "";
            if (isPreview) {
                this.redirectToPreview = false;
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <div className="close-btn text-right">
                                    <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                                </div>
                                <h2 className="mt-3 mb-4">{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['preview_no_question_popup_title']}</h2>
                                <p className="mt-3 mb-4">{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['preview_no_question_popup_text']}</p>
                                <button onClick={onClose}>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['preview_no_question_popup_ok_button']}</button>
                            </div>
                        )
                    }
                })
            }
            else {
                localStorage.removeItem("surveydetail_" + this.surveyMainId)
                localStorage.removeItem("surveyparticipationquestion_" + this.surveyMainId)
                if (survey_detail) {
                    let surveydetail = "";
                    let title = survey_detail.survey_title_slug;
                    let surveydata = "";
                    axios.get(API_URL + '/commonApi/getSurveyBySlug/' + LANGUAGE_CODE + '/' + title + '/live')
                        .then((response) => {
                            surveydetail = response.data.success.surveydata;
                            if (surveydetail !== '') {
                                surveydata = response.data.success.surveydata;
                                localStorage.setItem("surveydetail_" + this.surveyMainId, JSON.stringify(surveydata));
                            }
                        })
                        .catch((error) => {
                            //self.setState({ loading: 'false' })
                        })
                }
                this.setState({ survey_id })
            }
            this.setState({ loading: "false" })
        }
    }
    /** this function is for asking to disable survey and also show popup message when survey is in draft mode and try to disable it */
    disableSurvey = (data, index, event) => {
        //window.history.pushState(null, document.title, window.location.href);
        event.preventDefault();
        let survey_data = data;
        if (survey_data) {
            /**confirmation popup message when user want to disable survey */
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <div className="close-btn text-right">
                                <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close"></i></button>
                            </div>
                            <p>{(survey_data.status === 1 || survey_data.status === 4 || survey_data.status === 5) ? MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['status_disable_popup_text'] : MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['status_activate_popup_text']}</p>
                            <button name={survey_data.id} onClick={(event) => {
                                this.handleClickDisable(survey_data, index)
                                onClose()
                            }}>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['status_disable_popup_yes_button']}</button>
                            <button onClick={onClose}>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['status_disable_popup_no_button']}</button>
                        </div>
                    )
                }
            })
        }
    }
    /**this function is to disable survey and after that survey is no longer available in participation exchange system*/
    handleClickDisable = async (disable_survey_data, index) => {
        let survey_data = disable_survey_data
        let mypollscontent = this.state.mypollscontent;
        this.setState({ loading: "true" })
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let userId = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
            userId = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken,
            "languagecode": LANGUAGE_CODE
        }
        var data = {
            id: parseInt(survey_data.id),
            status: (survey_data.status === 1 || survey_data.status === 4 || survey_data.status === 5) ? parseInt(3) : parseInt(5)
        }
        let self = this;
        // eslint-disable-next-line
        await axios.post(API_URL + "/survey/updateSurveyStatusById" + '?uId=' + userId, data, { headers: headers })
            .then((response) => {
                survey_data.status = (survey_data.status === 1 || survey_data.status === 4 || survey_data.status === 5) ? 3 : 5;
                let SurveyIntId = parseInt(survey_data.id);
                if (localStorage.getItem('surveydetail_' + SurveyIntId)) {
                    let surveyDetailData = JSON.parse(localStorage.getItem('surveydetail_' + SurveyIntId));
                    surveyDetailData['status'] = survey_data.status;
                    localStorage.setItem("surveydetail_" + SurveyIntId, JSON.stringify(surveyDetailData));
                }
                if (localStorage.getItem("surveycreationdata_" + SurveyIntId)) {
                    localStorage.removeItem("surveycreationdata_" + SurveyIntId)
                }
                mypollscontent[index] = survey_data;
                self.setState({ loading: 'false', mypollscontent, message: 'successfull', responseMessage: response.data['success']['message'] });
                window.setTimeout(() => {
                    self.setState({ message: '', error: 0 })
                }, MESSAGE_TIME);
            })
            .catch((error) => {
                self.setState({ loading: 'false' });
                let regError = [];
                let apiresponse = ""
                if (error) {
                    if (error.response) {
                        if (error.response.data) {
                            apiresponse = error.response.data['error'];
                            if (apiresponse) {
                                if (Object.keys(apiresponse).length) {
                                    regError.push(apiresponse)
                                }
                                if (apiresponse.length) {
                                    if (apiresponse === 'Expired token' || apiresponse === 'Invalid token') {
                                        localStorage.clear();
                                        self.setState({ redirect: 'true' })
                                    }
                                }
                                self.setState({ message: "error", errorMessage: regError, loading: 'false' })
                            }
                        }
                    }
                }
                window.setTimeout(() => {
                    self.setState({ message: '', errorMessage: [], error: 0 })
                }, MESSAGE_TIME);
            })
        /**for scrolling up page after submiting data to show message */
        document.body.scrollTop = 0;
    }
    /** this function is for confirming user by popup message before delete survey from the system*/
    deleteSurvey = async (data, index, event) => {
        event.preventDefault()
        let delete_survey_data = data
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <div className="close-btn text-right">
                            <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                        </div>
                        <p>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['status_delete_popup_text']}</p>
                        <button name={delete_survey_data.id} onClick={(event) => {
                            this.handleClickDelete(delete_survey_data, index, event)
                            onClose()
                        }}>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['status_disable_popup_yes_button']}</button>
                        <button onClick={onClose}>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['status_disable_popup_no_button']}</button>
                    </div>
                )
            }
        })
    }
    /** this function is for confirming user by popup message before copy survey from the system*/
    copySurvey = async (data, index, event) => {
        event.preventDefault()
        let copy_survey_data = data
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <div className="close-btn text-right">
                                <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                            </div>
                            <p class="font-weight-bold">{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['copy_survey_text']}</p>
                            <button  onClick={(event) => { this.handleClickCopy(copy_survey_data,event)
                                onClose()
                            }}>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['status_disable_popup_yes_button']}</button>
                            <button onClick={onClose}>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['status_disable_popup_no_button']}</button>
                        </div>
                    )
                }
            })
    }
    handleClickCopy = async (copy_survey_data, event) => {
        let self = this;
        let copy_survey = copy_survey_data
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
            let apitoken = '';
            let userId = '';
            let mypollscontent= ''
            if (storeitem !== null) {
                apitoken = storeitem.token;
                userId = storeitem.id;
            }
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + apitoken
            }
        axios.get(API_URL + '/survey/copysurvey/' + LANGUAGE_CODE + "/" + copy_survey.id + "/" + userId + '?uId=' + userId, { headers: headers })
        .then((response) => {
            mypollscontent = response.data.success;
            this.getMyPolls()
        })
            .catch((error) => {
                self.setState({ loading: 'false' })
            })
    }
    /** this function is for deleting survey from the database it delete all records of particular survey from the database */
    handleClickDelete = async (delete_survey_data, index, event) => {
        let delete_survey = delete_survey_data
        let mypollscontent = this.state.mypollscontent;
        if (delete_survey) {
            this.setState({ loading: "true" })
            let storeitem = JSON.parse(localStorage.getItem('userdata'));
            let apitoken = '';
            let userId = '';
            if (storeitem !== null) {
                apitoken = storeitem.token;
                userId = storeitem.id;
            }
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + apitoken
            }
            let self = this;
            await axios.delete(API_URL + "/survey/deleteSurveyById/" + LANGUAGE_CODE + "/" + delete_survey.id + '?uId=' + userId, { headers: headers })
                .then((response) => {
                    let removeindex = mypollscontent.map(function (item) { return item.id; }).indexOf(mypollscontent[index]['id']);
                    mypollscontent.splice(removeindex, 1);
                    self.setState({ loading: 'false', message: 'successfull', mypollscontent, responseMessage: response.data['success']['message'] });
                    window.setTimeout(() => {
                        self.setState({ message: '', error: 0 })
                    }, MESSAGE_TIME);
                })
                .catch((error) => {
                    self.setState({ loading: 'false' });
                    let regError = [];
                    let apiresponse = ""
                    if (error) {
                        if (error.response) {
                            if (error.response.data) {
                                apiresponse = error.response.data['error'];
                                if (apiresponse) {
                                    if (Object.keys(apiresponse).length) {
                                        // eslint-disable-next-line
                                        Object.keys(apiresponse).map((apiresponseData, indexNew) => {
                                            if (apiresponse[apiresponseData] === "Umfrage nicht gefunden" || apiresponse[apiresponseData] === "Survey not found") {
                                                let removeindex = mypollscontent.map(function (item) { return item.id; }).indexOf(mypollscontent[index]['id']);
                                                mypollscontent.splice(removeindex, 1);
                                                regError.push(apiresponse[apiresponseData])
                                            }
                                        })
                                    }
                                    if (apiresponse.length) {
                                        if (apiresponse === 'Expired token' || apiresponse === 'Invalid token') {
                                            localStorage.clear();
                                            self.setState({ redirect: 'true' })
                                        }
                                    }
                                    self.setState({ message: "error", errorMessage: regError, loading: 'false', mypollscontent })
                                }
                            }
                        }
                    }
                    window.setTimeout(() => {
                        self.setState({ message: '', errorMessage: [], error: 0 })
                    }, MESSAGE_TIME);
                })
        }
        /**for scrolling up page after submiting data to show message */
        document.body.scrollTop = 0;
    }
    render() {
        /**for identifying chrome and safari browser */
        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        var issafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (this.redirectToPreview) {
            window.open(BASEURL + URL_CONSTANT['surveycreation'] + "/" + this.titleSlug + "/" + URL_CONSTANT['preview'], '_blank')
            this.redirectToPreview = false;
        }
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let token = '';
        if (storeitem !== null) {
            token = storeitem.token;
        }
        let pollsdata = this.state.mypollscontent;
        var pollsdetails = [];
        let length = Object.keys(pollsdata).length
        let status = "";
        let title = "";
        if (length !== 0) {
            for (let i = 0; i < length; i++) {
                if (pollsdata[i].status === 1 || pollsdata[i].status === 4 || pollsdata[i].status === 5) {
                    status = MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['status_published']
                }
                else if (pollsdata[i].status === 2) {
                    status = MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['status_draft']
                }
                else {
                    status = MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['status_disable']
                }
                if (pollsdata[i].survey_title) {
                    if (window.innerWidth >= 992 && window.innerWidth < 1200) {
                        if (pollsdata[i].survey_title.length > 45) {
                            title = pollsdata[i].survey_title.substring(0, 45).trim() + "...";
                        } else {
                            title = pollsdata[i].survey_title
                        }
                    }
                    else if (window.innerWidth >= 768 && window.innerWidth < 992) {
                        if (pollsdata[i].survey_title.length > 47) {
                            title = pollsdata[i].survey_title.substring(0, 47).trim() + "...";
                        } else {
                            title = pollsdata[i].survey_title
                        }
                    }
                    else if (window.innerWidth >= 576 && window.innerWidth < 768) {
                        if (pollsdata[i].survey_title.length > 125) {
                            title = pollsdata[i].survey_title.substring(0, 125).trim() + "...";
                        } else {
                            title = pollsdata[i].survey_title
                        }
                    }
                    else if (window.innerWidth >= 500 && window.innerWidth < 576) {
                        if (pollsdata[i].survey_title.length > 125) {
                            title = pollsdata[i].survey_title.substring(0, 125).trim() + "...";
                        } else {
                            title = pollsdata[i].survey_title
                        }
                    }
                    else if (window.innerWidth >= 400 && window.innerWidth < 500) {
                        if (pollsdata[i].survey_title.length > 90) {
                            title = pollsdata[i].survey_title.substring(0, 90).trim() + "...";
                        } else {
                            title = pollsdata[i].survey_title
                        }
                    }
                    else if (window.innerWidth >= 320 && window.innerWidth < 400) {
                        if (pollsdata[i].survey_title.length > 63) {
                            title = pollsdata[i].survey_title.substring(0, 63).trim() + "...";
                        } else {
                            title = pollsdata[i].survey_title
                        }
                    }
                    else if (window.innerWidth < 320) {
                        if (pollsdata[i].survey_title.length > 73) {
                            title = pollsdata[i].survey_title.substring(0, 73).trim() + "...";
                        } else {
                            title = pollsdata[i].survey_title
                        }
                    }
                    else {
                        if (pollsdata[i].survey_title.length > 60) {
                            title = pollsdata[i].survey_title.substring(0, 60).trim() + "...";
                        } else {
                            title = pollsdata[i].survey_title;
                        }
                    }
                }
                let backImage = IMAGE_BASEURL + "img/100x100.jpg"
                if (pollsdata[i].thumb_image) {
                    backImage = pollsdata[i].thumb_image;
                }
                pollsdetails.push(<div className="col-md-4 col-lg-3 mb-3" key={i}>
                    <div className="card bg-white boxshadow h-100">
                        <Link to={(parseInt(pollsdata[i].status) === 2) ? ("/" + URL_CONSTANT['surveycreation'] + "/" + window.btoa(pollsdata[i].id)) : ("/" + URL_CONSTANT['findparticipation'] + "/" + window.btoa(pollsdata[i].id))} className="d-inline-block" rel="noopener noreferrer" onClick={(event) => this.addSurveyId(pollsdata[i], event)}>
                        {
                            pollsdata[i].image_surveyid ?
                            (
                                this.state.isPlanExpired === 1
                                ? <div className="blog-img position-relative h-170 background-image-css" style={{ "backgroundImage": "none" }}></div>
                                : <div className="blog-img position-relative h-170 background-image-css" style={{ "backgroundImage": "url(" + backImage + ")" }}></div> 
                            )
                            : <div className="blog-img position-relative h-170 background-image-css" style={{ "backgroundImage": "url(" + backImage + ")" }}></div>
                        }
                        </Link>
                        <div className="card-content d-block w-100">
                            <h6><Link to={(parseInt(pollsdata[i].status) === 2) ? ("/" + URL_CONSTANT['surveycreation'] + "/" + window.btoa(pollsdata[i].id)) : ("/" + URL_CONSTANT['findparticipation'] + "/" + window.btoa(pollsdata[i].id))} rel="noopener noreferrer" className={(isChrome || issafari) ? "text-dark font-16 custom-test-text" : "text-dark font-16 text-hyphens"} onClick={(event) => this.addSurveyId(pollsdata[i], event)}>{(isChrome || issafari) ? pollsdata[i].survey_title : title}</Link></h6>
                            <div className="fixed_droupdown"><div className="float-left"><p className={(pollsdata[i].status === 1 || pollsdata[i].status === 4 || pollsdata[i].status === 5) ? "text-center font-16 text-success font-weight-bold" : (pollsdata[i].status === 2) ? "text-center font-16 text-warning font-weight-bold" : "text-center font-16 text-danger font-weight-bold"}>{status}</p></div>
                                <div className="dropdown share float-right">
                                    <li  className="dropdown-toggle list-unstyled" rel="noopener noreferrer" role="button" id="dropdownLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="ti-more text-dark"></i>
                                    </li>
                                    <div className="dropdown-menu dropdown-links" aria-labelledby="dropdownLink">
                                        <li className="dropdown-item text-dark font-18" title={MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['preview']} to={"/" + URL_CONSTANT['surveycreation'] + "/" + pollsdata[i]['survey_title_slug'] + "/" + URL_CONSTANT['preview']} role="button" onClick={(event) => this.liveTestStatusCheck(pollsdata[i], event)}>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['preview']}</li>
                                        <li className="dropdown-item text-dark font-18" title={MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['edit']}  role="button" onClick={(event) => this.editSurveyMessageShow(pollsdata[i], event)}>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['edit']}</li>
                                        {(pollsdata[i].status !== 2)
                                            ? <li className="dropdown-item text-dark font-18" role="button" title={(status === MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['status_disable']) ? MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['start_again'] : MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['disable']}
                                            to={"/" + URL_CONSTANT['findparticipation'] + "/" + window.btoa(this.surveyMainId)} onClick={(event) => this.disableSurvey(pollsdata[i], i, event)}>{(status === MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['status_disable']) ? MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['start_again'] : MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['disable']}</li>
                                            : null
                                        }
                                        <li className="dropdown-item text-dark font-18" title={MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['delete']} role="button" onClick={(event) => this.deleteSurvey(pollsdata[i], i, event)}>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['delete']}</li>
                                        {(pollsdata[i].status !== 2)
                                            ? <li to={"/" + URL_CONSTANT['findparticipation'] + "/" + window.btoa(pollsdata[i].id)} className="dropdown-item text-dark font-18" title="Teilnehmer finden" role="button" onClick={(event) => this.addSurveyId(pollsdata[i], event, 1)}>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['find_participant']}</li>
                                            : null
                                        }
                                        {(pollsdata[i].status !== 2)
                                            ? <li to={"/" + URL_CONSTANT['findparticipation'] + "/" + window.btoa(pollsdata[i].id)} className="dropdown-item text-dark font-18" title="Auswerten" role="button" onClick={(event) => this.addSurveyId(pollsdata[i], event, 2)}>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['analyze']}</li>
                                            : null
                                        }
                                        {(pollsdata[i].status !== 2)
                                            ? <li to={"/" + URL_CONSTANT['findparticipation'] + "/" + window.btoa(pollsdata[i].id)} className="dropdown-item text-dark font-18" title="Veröffentlichen" role="button" onClick={(event) => this.addSurveyId(pollsdata[i], event, 3)}>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['publish']}</li>
                                            : null
                                        }
                                        {/* NSU copy survey */}
                                        {
                                            storeitem.plan_type?
                                            <li className="dropdown-item text-dark font-18" title={MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['copy_survey']} onClick={(event) => this.copySurvey(pollsdata[i], i, event)} role="button">{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['copy_survey']}</li>
                                            :
                                            <li className="dropdown-item text-dark font-18" title={MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['copy_survey']} role="button" onClick={(event) => {this.setState({redirectToCheckout: true}); localStorage.setItem('redirectURL',window.location.href)}}><i class="ti-lock pr-2"></i>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['copy_survey']}
                                            <Link to={storeitem.account_type_category_id == 4 ? "/business-checkout" :storeitem.account_type_category_id == 2 ? "/university-checkout" : "/checkout"} style={{padding: "0px 10px"}}></Link> </li>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )
            }
        }
        let myprofileactivetab = localStorage.getItem('myprofileactivetab');
        if (myprofileactivetab) {
            myprofileactivetab = parseInt(myprofileactivetab);
        }
        let accountType = this.props.useraccountid
        return (
            <div className={(this.props.myprofileactivetab === 2) ? "tab-pane fade show active" : "tab-pane fade"} id="umfragen" role="tabpanel" aria-labelledby="umfragen-tab">
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {(token === "") ?
                    <Redirect to="/anmelden"></Redirect>
                    : null
                }
                {
                    this.state.redirectToCheckout?
                    <Redirect to={storeitem.account_type_category_id == 4 ? "/business-checkout" :storeitem.account_type_category_id == 2 ? "/university-checkout" : "/checkout"}></Redirect>
                    : null
                }
                {(this.state.redirectToFindParticipate === "true" && this.surveyMainId) ?
                    (parseInt(this.state.checkStatus) === 2) ?
                        <Redirect to={"/" + URL_CONSTANT['surveycreation'] + "/" + window.btoa(this.surveyMainId)}></Redirect>
                        :
                        <Redirect to={"/" + URL_CONSTANT['findparticipation'] + "/" + window.btoa(this.surveyMainId)}></Redirect>
                    : null
                }
                {(this.state.redirectToEdit === "true" && this.surveyMainId) ?
                    <Redirect to={"/" + URL_CONSTANT['surveycreation'] + "/" + window.btoa(this.surveyMainId)}></Redirect>
                    : null
                }
                { [1,2,4].includes(accountType) ?
                    <div className="md-m-30px-b pb-0">
                        <div className="row mx-0 box-shadow survey-title-box">
                             <div className="col-12 col-lg-1 text-center add-answer py-lg-5 py-1 px-0 pl-3 pl-lg-0 mt-2">
                                 <div className="round-information">
                                     <i className="fas fa-info icon-m border-radius"></i>
                                 </div>
                             </div>
                             <div className="col-12 col-lg-11 pt-2 px-lg-0 px-3 py-lg-4 d-flex flex-column justify-content-center pb-3">
                                 <p className="font-20 text-dark font-weight-bold mb-1 text-left">{MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE][[1,2].includes(accountType) ? 'polls_heading_ab' : 'polls_heading_d']}</p>
                                <p className="mr-lg-4 mr-0 font-16 text-dark mb-1 text-left" dangerouslySetInnerHTML={{ __html: MYPROFILE_MAINPROFILE_CONSTANT[LANGUAGE_CODE][[1,2].includes(accountType) ? 'polls_text_ab' : 'polls_text_d'] }}></p>
                             </div>
                        </div>
                    </div>
                    : null
                } 
                <div className="card-umfragen">
                    <div className="row">
                        <div className="col-12">
                            <div className={`main-card md-m-30px-b ${[1,2,4].includes(accountType) ? 'mb-2 pt-4':''}`}>
                                {(this.state.message === 'successfull') ?
                                    <div className='alert alert-success'>
                                        {this.state.responseMessage}
                                    </div>
                                    : null
                                }
                                {(this.state.message === 'error') ?
                                    <div className='alert alert-danger'>
                                        {this.state.errorMessage.map((msg, index) => <ListItem key={index} value={msg} />)}
                                    </div>
                                    : null
                                }
                                <div className="row">
                                    {/** here display polls which is created by user*/}
                                    {(length !== 0) ?
                                        pollsdetails
                                        : null
                                    }
                                    {/** here user can create it's own polls */}
                                    <div className="col-md-4 col-lg-3">
                                        <Link to={"/" + URL_CONSTANT['surveycreation']} rel="noopener noreferrer" className="d-inline-block w-100" title="Add Survey" onClick={this.removeId}>
                                            <div className="card bg-white boxshadow text-center add-card">
                                                <div className="icon card-img">
                                                    <i className="ti-plus icon-m border-radius btn-clr create-plus"></i>
                                                </div>
                                                <div className="card-content d-block w-100">
                                                    <h5 className="text-dark font-20">{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['create_poll']}</h5>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Polls;