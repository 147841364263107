import { ADD_USERDATA } from "../constants/ActionTypes";
import { REMOVE_USER } from "../constants/ActionTypes";
import axios from "axios";
export function addUserdata(payload) {
  return { type: ADD_USERDATA, payload };
}
export function removeUser(payload) {
  return { type: REMOVE_USER, payload };
}
export function getSurveyData(payload) {
  return function (dispatch) {
    axios.get('https://cors-anywhere.herokuapp.com/http://jsonplaceholder.typicode.com/todos', {
      params: {
        id: payload.id
      }
    })
      .then(function (response) {
        dispatch({ type: "SURVEY_DATA_LOADED", payload: response.data });
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
}