import React from "react";
import Button from '@material-ui/core/Button';
class SurveyRatingWithMoreThumbQuestion extends React.Component {
    getScaleOption = (data) => {
        var scalebutton = [];
        let length = 6;
        let surveydetail = JSON.parse(localStorage.getItem('surveydetail_' + this.props.questiondata.survey_id));
        for (let i = 1; i < length; i++) {
            scalebutton.push(
			<button
                type='button'
                className={"MuiCustom-thumbs-up px-0 " + ((this.props.questionanswer.option === undefined) ?
                    (this.props.questionanswer[data] === undefined) ? "default box-shadow thumbs-button-cusom" : (parseInt(this.props.questionanswer[data]) === parseInt(i)) ? "btn-rank selected-thumbs box-shadow thumbs-button-cusom font-weight-normal" : (parseInt(this.props.questionanswer[data]) >= parseInt(i)) ? "btn-rank box-shadow thumbs-button-cusom font-weight-normal" : "default box-shadow thumbs-button-cusom"
                    : (this.props.questionanswer.option === undefined) ? "default box-shadow thumbs-button-cusom" : (this.props.questionanswer.option.findIndex(ans => ans.option_id === data && parseInt(ans.answer) === parseInt(i)) > -1) ? "btn-rank selected-thumbs box-shadow thumbs-button-cusom font-weight-normal" : (this.props.questionanswer.option.findIndex(ans => ans.option_id === data && parseInt(ans.answer) >= parseInt(i)) > -1) ? "btn-rank box-shadow thumbs-button-cusom font-weight-normal" : "default box-shadow thumbs-button-cusom")
                }
                name={data}
                key={i}
                value={i}
                onClick={this.props.onButtonRating}
                style={{ "--school-gradian": surveydetail.survey_color? surveydetail.survey_color : "#00b3f4" }}
            >
                <Button variant="contained" color="#00b3f4" className="box-shadow" onClick={(e) => this.labelOnClick(e)}>
                    {(this.props.questionanswer.option === undefined) ?
                    (this.props.questionanswer[data] === undefined) ? <i className="far fa-thumbs-up rating-gray"></i> : (parseInt(this.props.questionanswer[data]) === parseInt(i)) ? <i className="far fa-thumbs-up rating-white"></i> : (parseInt(this.props.questionanswer[data]) >= parseInt(i)) ? <i className="far fa-thumbs-up rating-white"></i> : <i className="far fa-thumbs-up rating-gray"></i>
                    : (this.props.questionanswer.option === undefined) ? <i className="far fa-thumbs-up rating-gray"></i> : (this.props.questionanswer.option.findIndex(ans => ans.option_id === data && parseInt(ans.answer) === parseInt(i)) > -1) ? <i className="far fa-thumbs-up rating-white"></i> : (this.props.questionanswer.option.findIndex(ans => ans.option_id === data && parseInt(ans.answer) >= parseInt(i)) > -1) ? <i className="far fa-thumbs-up rating-white"></i> : <i className="far fa-thumbs-up rating-gray"></i>}
                </Button>
            </button>)
        }
        return scalebutton;
    }
	labelOnClick = (e) => {
        if (e.currentTarget.name) {
            document.getElementById(e.currentTarget.name).click();
            document.getElementById(e.currentTarget.name).checked = !(document.getElementById(e.currentTarget.name).checked);
        }
    }
    render() {
        let data = this.props.questiondata;
        // eslint-disable-next-line
        let scalebutton;
        let option = data.option.map((scale, index) => {
            return (<div key={index} className="d-flex align-items-center row mb-5"><div className="col-12 text-center mb-3 md-5"><span className="font-size font-weight-bold">{scale.option_text}</span></div><div className="d-flex align-items-center justify-content-center flex-wrap col-12">{scalebutton = this.getScaleOption(scale.id)}</div></div>)
        })
        return (<div key={this.props.questiondata.question_id} className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
            <div className="">
                <div className="question-heading">
                    <h3 className="text-dark font-weight-bold text-break survey-title">{data.question_text}</h3>
                    {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
                </div>
                {option}
            </div>
        </div>)
    }
}
export default SurveyRatingWithMoreThumbQuestion;