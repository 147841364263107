import React from 'react';
import { IMAGE_BASEURL } from './DotEnv';
export const SURVEY_CREATION_POSITION_CONSTANT = { // related to section of surveyCreation.js
    "survey_title_position": 0,
    "survey_category_position": 1,
    "survey_picture_position": 2,
    "survey_welcome_page_position": 3,
    "survey_welcome_content_position": 4,
    // "survey_welcome_text_position": -- this page is used for display static data
    "survey_element_position": 5,
    "survey_demographic_position": 6,
    "survey_creating_conditions_position": 7,
    "survey_end_position": 8,                         /** more than last component position component */
    "define_closing_page": 9,
    "survey_custom_logo": 10,
    "survey_data_protection": 11
}
export const LOGIN_ERROR = {
    EN: {
        "email": "Please enter your e-mail address",
        "emailvalidation": "Please enter a valid email address",
        "password": "Please enter password"
    },
    DE: {
        "email": "Bitte E-Mail Adresse eingeben",
        "emailvalidation": "Bitte eine gültige E-Mail Adresse eingeben",
        "password": "Bitte Passwort eingeben"
    }
}
export const VERIFY_ERROR = {
    EN: {
        "sucess": "Your registration was successful. You can sign up now!",
        "error": "user does not exist",
    },
    DE: {
        "success": "Deine Registrierung war erfolgreich. Du kannst dich jetzt anmelden!",
        "error": "Benutzer existiert nicht"
    }
}
export const FORGOTPASSWORD_ERROR = {
    EN: {
        "email": "Please enter your e-mail address",
        "emailvalidation": "Please enter a valid email address",
    },
    DE: {
        "email": "Bitte E-Mail Adresse eingeben",
        "emailvalidation": "Bitte eine gültige E-Mail Adresse eingeben",
    }
}
export const RESETPASSWORD_ERROR = {
    EN: {
        "newPassword": "Please enter new password",
        "confirmPassword": "Please enter confirm password",
        "passwordLength": "Password must be 8 characters or above"
    },
    DE: {
        "newPassword": "Bitte Passwort eingeben",
        "confirmPassword": "Bitte gib dein Passwort ein",
        "passwordLength": "Das Passwort muss aus 8 oder mehr Zeichen bestehen"
    }
}
export const REGISTRATION_ERROR_MESSAGE = {
    EN: {
        "firstname": "Please enter firstname",
        "lastname": "Please enter lastname",
        "email": "Please enter your e-mail address",
        "emailvalidation": "Please enter a valid email address",
        "password": "Please enter password",
        "passwordLength": "Password must be 8 characters or above",
        "terms_condition": "Please agree to the privacy policy and the terms and conditions",
    },
    DE: {
        "firstname": "Bitte gib deinen Vornamen ein",
        "lastname": "Bitte gib deinen Nachnamen ein",
        "email": "Bitte E-Mail Adresse eingeben",
        "emailvalidation": "Bitte eine gültige E-Mail Adresse eingeben",
        "password": "Bitte Passwort eingeben",
        "passwordLength": "Das Passwort muss aus 8 oder mehr Zeichen bestehen",
        "terms_condition": "Bitte der Datenschutzerklärung und den AGB zustimmen",
    }
}
export const MYPROFILE_ERROR_MESSAGE = {
    EN: {
        "first_name": "Please enter first name",
        "last_name": "Please enter last name",
        "birthday": "please enter valid birthdate",
        "gender": "Please select your gender",
        "education": "Please select your education",
        "nationality": "Please select your nationality",
        "marital_status": "Please select your marital status",
        "semester": "Please select your semester",
        "employment": "Please select your employment",
        "income": "Please select your income",
        "university": "Please enter valid university name",
        "location": "Please enter valid postalcode",
        "major": "Please enter your major",
        "university_empty" : "Please enter the university name",
        "invalid" : "invalid date",
        "all_field_required" : "Please fill in all required fields",
        "name_of_the_organization" : "Please fill name of your organization.",
        "current_school": "Please fill your current school name."
    },
    DE: {
        "first_name": "Bitte gib deinen Vornamen ein",
        "last_name": "Bitte gib deinen Nachnamen ein",
        "birthday": "Bitte gib ein gültiges Geburtsdatum ein",
        "gender": "Bitte wähle dein Geschlecht",
        "education": "Bitte wähle deine Ausbildung aus",
        "nationality": "Bitte wähle deine Nationalität aus",
        "marital_status": "Bitte wähle deinen Familienstand aus",
        "semester": "Bitte wähle dein Semester aus",
        "employment": "Bitte wähle deinen Erwerbsstatus aus",
        "income": "Bitte wähle dein Einkommen aus",
        "university": "Bitte gib einen gültigen Hochschulnamen ein",
        "location": "Bitte gültigen Wohnort auswählen",
        "major": "Bitte gib deinen Studiengang ein",
        "university_empty" : "Bitte gib einen Hochschulnamen ein",
        "invalid" : "ungültiges Datum",
        "all_field_required" : "Bitte alle Pflichtfelder ausfüllen",
        "name_of_the_organization" : "Bitte geben Sie den Namen Ihrer Organisation ein.",
        "current_school": "Bitte tragen Sie Ihren aktuellen Schulnamen ein."

    }
}
export const STATUS_DROPDOWN = {
    EN: [{ "id": "true", "status_name": "Newest" }, { "id": "false", "status_name": "Ending soon" }],
    DE: [{ "id": "true", "status_name": "Neuste" }, { "id": "false", "status_name": "Bald endende" }]
}
export const NO_OF_PARTICIPANTS_DROPDOWN = {
    EN: [{ "id": "4", "participants_name": "100-499 Attendees" }, { "id": "6", "participants_name": "500-999 Attendees" }, { "id": "10", "participants_name": "1000+ Attendees" }],
    DE: [{ "id": "4", "participants_name": "100-499 Teilnehmer" }, { "id": "6", "participants_name": "500-999 Teilnehmer" }, { "id": "10", "participants_name": "1000+ Teilnehmer" }]
}
export const SCIENTIFIC_WORK_TYPE_DROPDOWN = {
    EN: [{ "id": "Seminar paper", "scientific_type": "Seminar paper" }, { "id": "Bachelor thesis", "scientific_type": "Bachelor thesis" }, { "id": "Master thesis", "scientific_type": "Master thesis" }, { "id": "Dissertation", "scientific_type": "Dissertation" }, { "id": "House work", "scientific_type": "House work" }, { "id": "Project work", "scientific_type": "Project work" }, { "id": "Other", "scientific_type": "Other" }],
    DE: [{ "id": "Seminararbeit", "scientific_type": "Seminararbeit" }, { "id": "Bachelorarbeit", "scientific_type": "Bachelorarbeit" }, { "id": "Masterarbeit", "scientific_type": "Masterarbeit" }, { "id": "Dissertation", "scientific_type": "Dissertation" }, { "id": "Hausarbeit", "scientific_type": "Hausarbeit" }, { "id": "Projektarbeit", "scientific_type": "Projektarbeit" }, { "id": "Sonstiges", "scientific_type": "Sonstiges" }]
}
export const DURATION_DROPDOWN = {
    EN: [{ "id": "5", "duration": "1-5 Minutes" }, { "id": "10", "duration": "6-10 Minutes" }, { "id": "15", "duration": ">10 Minutes" }],
    DE: [{ "id": "5", "duration": "1-5 Minuten" }, { "id": "10", "duration": "6-10 Minuten" }, { "id": "15", "duration": ">10 Minuten" }]
}
export const SURVEY_DETAIL_CONSTANT = {
    EN: {
        "description": "Description",
        "targetgroup_requirements": "Target group and requirements",
        "similar_surveys": "Similar polls",
        "queried_features": "Queried features",
        "survey_start_button": "Start a survey",
        "alert_login_title": "Log in issue",
        "alert_login_message": "Please log in to take part in surveys.",
        "alert_login_button": "ok",
        "alert_attribute_title": "Demographic characteristics are missing",
        "alert_attribute_message": "To participate in this survey, you must have the following attributes in your profile:",
        "alert_attribute_button": "ok",
        "alert_participated_title": "Already participated",
        "alert_participated_message": "Already participated",
        "alert_participated_button": "ok",
        "alert_his_own_survey_popup_text" : "You can not participate in your own survey.",
        "alert_his_own_survey_popup_ok_button" : "Ok",
        "minute" : "minutes",
        "totalparticipants" : "Member",
        "daysleft" : "Days left"
    },
    DE: {
        "description": "Beschreibung",
        "targetgroup_requirements": "Zielgruppe und Anforderungen",
        "similar_surveys": "Ähnliche Umfragen",
        "queried_features": "Abgefragte Merkmale",
        "survey_start_button": "Umfrage Starten",
        "alert_login_title": "Log in Ausgabe",
        "alert_login_message": "Bitte melde dich an, um an Umfragen teilnehmen zu können.",
        "alert_login_button": "OK",
        "alert_attribute_title": "Demografische Merkmale fehlen",
        "alert_attribute_message": "Um an dieser Umfrage teilnehmen zu können, musst du folgende Attribute in deinem Profil hinterlegen:",
        "alert_attribute_button": "OK",
        "alert_participated_title": "Bereits teilgenommen",
        "alert_participated_message": "Bereits teilgenommen",
        "alert_participated_button": "OK",
        "alert_his_own_survey_popup_text" : "Du kannst nicht an deiner eigenen Umfrage teilnehmen.",
        "alert_his_own_survey_popup_ok_button" : "OK",
        "minute" : "Minuten",
        "totalparticipants" : "Teilnehmer",
        "daysleft" : "Tage noch"
    }
}
export const MYPROFILE_SETTING_ERROR_MESSAGE = {
    EN: {
        "email" : "Please enter your e-mail address",
        "emailvalidation" : "Please enter a valid email address",
        "password": "Please enter password",
        "passwordLength": "Password must be 8 characters or above",
        "confirm_password": "Please enter confirm password"
    },
    DE: {
        "email" : "Bitte E-Mail Adresse eingeben",
        "emailvalidation" : "Bitte eine gültige E-Mail Adresse eingeben",
        "password": "Bitte Passwort eingeben",
        "passwordLength": "Das Passwort muss aus 8 Zeichen oder mehr bestehen",
        "confirm_password": "Bitte gib dein Passwort ein"
    }
}
export const SURVEY_LIST_CONSTANT = {
    EN: {
        "karmapoint": "Karma",
        "totaluser": "Member",
        "duration": "Minutes",
        "daysleft": "days",
        "norecordsfoundmsg": "No poll found.",
        "loadmore_button": "load More",
        "alredy_participate": "Already participated",
        "should_participate": "Participated with you",
        "category": "Category",
        "durationfilter": "Duration",
        "reward": "Reward",
        "status": "Status",
        "university": "University",
        "filter" : "Filter",
        "participants" : "Number of participants",
        "sorting" : "Sort by",
        "scientific_type" : "Type of scientific work",
        "toggle_button_heading" : "Participated with you",
        "sliderbar_heading" : "Top 20 members of the month",
        "table-name-heading" : "Name",
        "table-karmapoint-heading" : "Karma",
        "data_not_found_msg" : "No participations yet."
    },
    DE: {
        "karmapoint": "Karma",
        "totaluser": "Teilnehmer",
        "duration": "Minuten",
        "daysleft": "Tage",
        "norecordsfoundmsg": "Keine Umfrage gefunden.",
        "loadmore_button": "Mehr laden",
        "alredy_participate": "Bereits teilgenommen",
        "should_participate": "Hat bei dir teilgenommen",
        "category": "Kategorie",
        "durationfilter": "Dauer",
        "reward": "Belohnung",
        "status": "Status",
        "university": "Hochschule",
        "filter" : "Filter",
        "participants" : "Anzahl Teilnehmer",
        "sorting" : "Sortieren",
        "scientific_type" : "Art der wissenschaftlichen Arbeit",
        "toggle_button_heading" : "Hat bei dir teilgenommen",
        "sliderbar_heading" : "Top 20 Mitglieder des Monats",
        "table-name-heading" : "Name",
        "table-karmapoint-heading" : "Karma",
        "data_not_found_msg" : "Noch keine Teilnahmen."
    }
}
export const EMPIRIOWISSEN_CONSTANT = {
    EN: {
        "research_title": "Learn step by step all the important information that you should know about empirical research.",
        "research_description": "On empirioWissen we have summarized clearly and step by step the field of empirical research. From the start of the research process to the evaluation of the data and the writing of your scientific work. But you can also jump right into various topics if you have questions about specific aspects.",
        "start_chapter_button": "1. Start the chapter",
        "overview_title": "Topics in the overview",
        "overview_detail_button": "To the lexicon",
        "research_footer_title": "Now, step by step, understand empirical research",
        "footer_start_chapter_button": "1. Start the chapter",
        "back": "Back",
        "chapter": "chapter",
        "published_on": "Updated on",
        "back_to_the_start": "To overview",
        "next_chapter": "next chapter",
        "previous_chapter": "privious chapter",
        "find_participants": "Create a poll",
        "empiriowisse_description" : "Based on empirical knowledge, we have summarized the field of empirical research clearly and step by step. From the beginning of the research process to the evaluation of the data and writing of your scientific work. You can also jump directly to different topics if you have questions about specific aspects.",
        "empiriowissen_shortdescription" : "Learn step by step all the important information that you should know about empirical research.",
        "empiriowissen_card_title" : "You might be interested in that too"
    },
    DE: {
        "research_title": "Erfahre Schritt für Schritt alle wichtigen Informationen, die du zur empirischen Forschung wissen solltest.",
        "research_description": "Auf empirioWissen haben wir dir übersichtlich und Schritt für Schritt das Themenfeld der empirischen Forschung zusammengefasst. Von dem Start des Forschungsprozesses bis zur Auswertung der Daten und dem Verfassen deiner wissenschaftlichen Arbeit. Du kannst aber auch direkt in verschiedenen Themen einsteigen, wenn du Fragen zu bestimmten Aspekten hast.",
        "start_chapter_button": "1. Kapitel starten",
        "overview_title": "Themen in der Übersicht",
        "overview_detail_button": "Zum Lexikon",
        "research_footer_title": "Jetzt Schritt für Schritt empirische Forschung verstehen",
        "footer_start_chapter_button": "1. Kapitel starten",
        "back": "Zurück",
        "chapter": "Kapitel",
        "published_on": "Aktualisiert am",
        "back_to_the_start": "Zur Übersicht",
        "next_chapter": "Weiter",
        "previous_chapter": "Zurück",
        "find_participants": "Umfrage erstellen",
        "empiriowisse_description" : "Auf empirioWissen haben wir das Feld der empirischen Forschung klar und Schritt für Schritt zusammengefasst. Vom Beginn des Forschungsprozesses bis zur Auswertung der Daten und Schreiben deiner wissenschaftlichen Arbeit. Du kannst aber auch direkt zu verschiedenen Themen springen, wenn du Fragen zu bestimmten Aspekten hast.",
        "empiriowissen_shortdescription" : "Erfahre Schritt für Schritt alle wichtigen Informationen, die du zur empirischen Forschung wissen solltest.",
        "empiriowissen_card_title" : "Das könnte dich auch interessieren"
    }
}
/**constant for survey participation components */
export const SURVEY_PARTICIPATION = {
    EN: {
        "minute": "Minutes",
        "participation_button": "Start survey",
        "by_author": "From",
        "welcome": "welcome",
        "demographic_attribute": "Queried features",
        "question": "Question",
        "from": "from",
        "back": "BACK",
        "continue": "NEXT",
        "finish": "FINISH",
        "close_survey": "Close poll",
        "endingpage": "Thank you",
        "participationmessage": "More polls from the community",
        "errormessage": "Please answer question",
        "rating_page_default_option": "Please select rank",
        "information": "Information",
        "demogrphic_dropdown_selection_default_option": "Please choose",
        "demographic_textfield_IPH": "Fill The Answer",
        "common_textfield_IPH": "Type here",
        "errormessage_for_ranking_order" : "Each rank may only be assigned once",
        'live_demo' : "Live Demo",
        "cover_page_survey_inactive_alert_msg" : "Right now survey is inactive",
        "cover_page_survey_already_participate_alert_msg":"You have already participated",
        "cover_page_survey_inactive_alert_ok_btn" : "ok",
        "demographic_location_IPH" :"Enter residence",
        "correctlocation" : "Please select valid place of residence",
        "end_page_registration_pop_up_title":"Register for free and get results",
        "end_page_registration_pop_up_first_subtitle":"Notification when the results of the survey are published",
        "end_page_registration_pop_up_second_subtitle" : "Secure empirioKarma points from this survey",
        "end_page_registration_pop_up_third_subtitle" : "Create your own academic survey",
        "end_page_registration_pop_up_fourth_subtitle":"Find participants in the community",
        "end_page_registration_pop_up_link_text":"Join Now",
        "canvasDownloadButtonLabel": "download",
        "multipal_selection_question": "Multiple answers allowed",
        "data_protection_error_msg" : "Please accept the data protection declaration (at the end of the page the corresponding check mark must be set)",
        "rank" : "Rank"
    },
    DE: {
        "minute": "Minuten",
        "participation_button": "An Umfrage Teilnehmen",
        "by_author": "Von",
        "welcome": "herzlich willkommen",
        "demographic_attribute": "Abgefragte Merkmale",
        "question": "Frage",
        "from": "von",
        "back": "ZURÜCK",
        "continue": "WEITER",
        "finish": "FERTIG",
        "close_survey": "Umfrage schließen",
        "endingpage": "Vielen Dank für die Teilnahme",
        "participationmessage": "Weitere Umfragen aus der Community",
        "errormessage": "Bitte Frage beantworten",
        "rating_page_default_option": "Bitte Rang auswählen",
        "information": "Information",
        "demogrphic_dropdown_selection_default_option": "Bitte wählen",
        "demographic_textfield_IPH": "Antwort ausfüllen",
        "common_textfield_IPH": "Antwort eingeben",
        "errormessage_for_ranking_order" : "Jeder Rang darf nur einmal vergeben werden",
        'live_demo' : "Live Demo",
        "cover_page_survey_inactive_alert_msg" : "Die Umfrage ist derzeit inaktiv",
        "cover_page_survey_already_participate_alert_msg":"Du hast bereits teilgenommen",
        "cover_page_survey_inactive_alert_ok_btn" : "ok",
        "demographic_location_IPH" :"Wohnort eingeben",
        "correctlocation" : "Bitte gültigen Wohnort auswählen",
        "end_page_registration_pop_up_title":"Kostenlos registrieren und Ergebnisse erhalten",
        "end_page_registration_pop_up_first_subtitle":"Benachrichtigung, wenn die Ergebnisse der Umfrage veröffentlicht werden",
        "end_page_registration_pop_up_second_subtitle" : "empirioKarma Punkte dieser Umfrage sichern",
        "end_page_registration_pop_up_third_subtitle" : "Eigene akademische Umfrage erstellen",
        "end_page_registration_pop_up_fourth_subtitle":"Teilnehmer in der Community finden",
        "end_page_registration_pop_up_link_text":"Jetzt  Registrieren",
        "canvasDownloadButtonLabel": "Speichern",
        "multipal_selection_question": "Mehrfach-Auswahl möglich",
        "data_protection_error_msg" : "Bitte die Datenschutzerklärung akzeptieren (am Ende der Seite muss der entsprechende Haken gesetzt werden)",
        "rank" : "Rang"
    }
}
/** Language constant for footer links */
export const FOOTER_LINKS = {
    EN: {
        "empirio": "empirio",
        "about_us": "about us",
        "features": "Features",
        "empirio_knowledge": "empirioWissen",
        "survey": "Community",
        "current_polls": "Current polls",
        "create_surveys": "Own polls",
        "info_help": "Info & Help",
        "faq": "FAQ",
        "help_center": "Help Center",
        "social": "Services & Links",
        "facebook": "Facebook",
        "instagram": "Instagram",
        "conditions": "Conditions",
        "imprint": "Imprint",
        "data_protection": "Data protection",
        "copyrights": "Empirio UG",
        "Umfrage_Tool": "Umfrage-Tool",
        "Statistiken" : "Results",
        "for_student" : "For students",
        "for_companies" : "For companies",
        "for_colleges" : "For colleges",
        "for_schools" : "For schools",
        "lexikon" : "Dictionary",
        "Umfrag_Ratgeber" : "Umfrage-Ratgeber",
        "TikTok" : "TikTok"

    },
    DE: {
        "empirio": "empirio",
        "about_us": "Über uns",
        "features": "Funktionen",
        "empirio_knowledge": "empirioWissen",
        "survey": "Community",
        "current_polls": "Aktuelle Umfragen",
        "create_surveys": "Eigene Umfragen",
        "info_help": "Infos & Hilfe",
        "faq": "FAQ",
        "help_center": "Hilfe Center",
        "social": "Services & Links",
        "facebook": "Mitarbeiterbefragungen",
        "instagram": "Instagram",
        "conditions": "AGB",
        "imprint": "Impressum",
        "data_protection": "Datenschutz",
        "copyrights": "Empirio UG",
        "Umfrage_Tool": "Umfrage-Tool",
        "community" : "Community",
        "Statistiken" : "Ergebnisse",
        "for_student" : "Für Studierende",
        "for_companies" : "Für Unternehmen",
        "for_colleges" : "Für Hochschulen",
        "for_schools" : "Für Schulen",
        "lexikon" : "Lexikon",
        "Umfrag_Ratgeber" : "Umfrage-Ratgeber",
        "TikTok" : "Themen Abschlussarbeit"
    }
}
/** Language constant for header parts links,login and logout button */
export const HEADER_LINKS = {
    EN: {
        "login": "Log In",
        "logout": "Sign out",
        "features": "Features",
        "survey": "Community",
        "empirio_knowledge": "empirio knowledge",
        "myprofile_title_label" : "Own area",
        "survey_result" : "Results",
        "packages" : "Packages",
        "advantages" : "Advantages",
        "solution" : "Solutions",
        "landingPage_header_button" : "Create poll",
        "for_companies" : "For companies"
    },
    DE: {
        "login": "Anmelden",
        "logout": "Abmelden",
        "features": "Funktionen",
        "survey": "Community",
        "empirio_knowledge": "empirioWissen",
        "myprofile_title_label" : "Eigener Bereich",
        "survey_result" : "Ergebnisse",
        "packages" : "Pakete",
        "advantages" : "Vorteile",
        "solution" : "Lösungen",
        "landingPage_header_button" : "Umfrage erstellen",
        "for_companies" : "Für Unternehmen"
    }
}
/** Language constant for page not found page */
export const PAGE_NOT_FOUND_CONSTANT = {
    EN: {
        "title_text": "Whoops!",
        "sub_title_text": "Page not found"
    },
    DE: {
        "title_text": "Hoppala!",
        "sub_title_text": "Seite nicht gefunden"
    }
}
/** Forgot password form constant  */
export const FORGOTPASSWORD_CONSTANT = {
    EN: {
        "forgot_password_title": "Forgot Password?",
        "email_address_label": "E-mail address",
        "found_password": "Found password?",
        "sign_up_here": "Sign up here",
        "button_text": "To confirm",
        "subtitle":"Please enter your email address so that we can send you a link to reset your password.",
    },
    DE: {
        "forgot_password_title": "Passwort vergessen?",
        "email_address_label": "E-Mail Adresse",
        "found_password": "Passwort gefunden?",
        "sign_up_here": "Hier anmelden",
        "button_text": "Bestätigen",
        "subtitle":"Bitte gib deine E-Mail Adresse ein, damit wir dir einen Link zum Zurücksetzen deines Passworts zuschicken können."
    }
}
/** Login form constant */
export const LOGIN_CONSTANT = {
    EN: {
        "login_title": "Log In",
        "email_address_label": "E-mail address",
        "password_label": "password",
        "to_forget": "To forget?",
        "button_text": "Log In",
        "not_a_member_yet": "Not a member yet?",
        "sign_up_here": "Sign up here",
        "unsubscribe_mail":"You will not receive notifications about your activities on empirio in the future.",
        "unsubscribe_newsletter" : "In the future, you will no longer receive the newsletter from empirio.",
        "unsubscribe_newsletter_error" : "user does not exist"
    },
    DE: {
        "login_title": "Anmelden",
        "email_address_label": "E-Mail Adresse",
        "password_label": "Passwort",
        "to_forget": "Vergessen?",
        "button_text": "Anmelden",
        "not_a_member_yet": "Noch kein Mitglied?",
        "sign_up_here": "Registriere dich hier",
        "unsubscribe_mail":"Du wirst in Zukunft keine Benachrichtigungen zu deinen Aktivitäten auf empirio mehr erhalten.",
        "unsubscribe_newsletter" : "Du erhältst zukünftig nicht mehr den Newsletter von empirio.",
        "unsubscribe_newsletter_error" : "Benutzer existiert nicht"
    }
}
/** constant for the survey participation ranking with one element school grade type question options */
export const SCHOOL_GRADE_SCALE_CONSTANT = {
    EN: [{ "id": 1, "title": "Excellent" }, { "id": 2, "title": "Good" }, { "id": 3, "title": "Satisfactory" }, { "id": 4, "title": "Sufficient" }, { "id": 5, "title": "Poor" }, { "id": 6, "title": "Insufficient" }],
    DE: [{ "id": 1, "title": "sehr gut" }, { "id": 2, "title": "gut" }, { "id": 3, "title": "befriedigend" }, { "id": 4, "title": "ausreichend" }, { "id": 5, "title": "mangelhaft" }, { "id": 6, "title": "ungenügend" }]
}
/** constant for the survey participation ranking with survey approval type question options */
export const SURVEY_APPROVAL_QUESTION_ANSWER = {
    EN: [{ "id": 1, "title": "Strongly agree" }, { "id": 2, "title": "Agree" }, { "id": 3, "title": "Neither agree nor disagree" }, { "id": 4, "title": "Disagree" }, { "id": 5, "title": "Strongly disagree" }],
    DE: [{ "id": 1, "title": "Stimme voll und ganz zu" }, { "id": 2, "title": "Stimme zu" }, { "id": 3, "title": "Weder noch" }, { "id": 4, "title": "Stimme nicht zu" }, { "id": 5, "title": "Stimme überhaupt nicht zu" }]
}
export const DEMOGRAPHIC_PARTICIPATION_CONSTANT = { EN: [{ "id": 1, "title": "age" }, { "id": 2, "title": "gender" }, { "id": 3, "title": "postalcode_id" }, { "id": 4, "title": "income" }, { "id": 5, "title": "employment" }, { "id": 6, "title": "education" }, { "id": 7, "title": "marital_status" }, { "id": 8, "title": "nationality" }] }
/**constant for polls in my profile */
export const MYPROFILE_POLLS_CONSTANT = {
    EN: {
        "preview": "Preview",
        "disable": "Deactivate",
        "edit" : "To edit",
        "delete" : "Delete",
        "start_again" : "Activate",
        "create_poll" : "Create a poll",
        "already_published_popup_title" : "Already published",
        "already_published_popup_text" : "Sorry you can not edit this survey as it already published",
        "already_published_popup_ok_button" : "Ok",
        "no_question_found_title" : "No question found",
        "no_question_found_description" : "Please add at least one question to publish survey",
        "no_question_found_ok_button" : "Ok",
        "status_published" : "Active",
        "status_draft" : "Draft",
        "status_disable" : "Inactive",
        "status_delete_popup_text" : "Do you really want to delete the survey?",
        "status_disable_popup_yes_button" : "Yes",
        "status_disable_popup_no_button" : "No",
        "preview_no_question_popup_title" : "Question not found",
        "preview_no_question_popup_text" : "Question not found in survey, can not preview it",
        "preview_no_question_popup_ok_button" : "Ok",
        "draft_disable_popup_text" : "Survey is in draft mode you can not disable it",
        "draft_disable_popup_ok_button" : "Ok",
        "status_activate_popup_text" : "Do you really want to activate the survey? Participation is then enabled.",
        "user_university_not_exist":'Please select your" Current College "in the" My Profile "tab to start creating.',
        "university_popup_close_button":"Close",
        "status_disable_popup_text" : "Do you really want to deactivate the survey? Participation is not possible afterwards.",
        "find_participant" : "Find participants",
        "analyze" : "Evaluate",
        "publish" : "Publish",
        "toedit_heading" : "Cannot edit",
        "toedit_subtext" : "Since you have published the results of your survey on empirio, you cannot edit your survey at the moment. Please stop the publication first so that you can edit your survey again afterwards.",
        "copy_survey" : "Copy",
        "copy_survey_text" : "Do you really want to copy this survey?",
        "copy_survey_heading" : "Umfrage copy",
        "user_current_school_not_exist":'Please enter the name of your school in the "My Profile" tab to start creating.',
        "user_organization_not_exist":'Please enter your organization name in the "My Profile" tab to start creating.',
        "conform_mail_text" : "You are now on the waiting list!"
    },
    DE: {
        "preview": "Vorschau",
        "disable": "Deaktivieren",
        "edit" : "Bearbeiten",
        "delete" : "Löschen",
        "start_again" : "Aktivieren",
        "create_poll" : "Umfrage erstellen",
        "already_published_popup_title" : "Bereits veröffentlicht",
        "already_published_popup_text" : "Leider kannst du diese Umfrage nicht bearbeiten, da sie bereits veröffentlicht wurde",
        "already_published_popup_ok_button" : "Ok",
        "no_question_found_title" : "Keine Frage gefunden",
        "no_question_found_description" : "Bitte füge mindestens eine Frage hinzu, um die Umfrage zu veröffentlichen",
        "no_question_found_ok_button" : "Ok",
        "status_published" : "Aktiv",
        "status_draft" : "Entwurf",
        "status_disable" : "Inaktiv",
        "status_delete_popup_text" : "Willst du die Umfrage wirklich löschen?",
        "status_disable_popup_yes_button" : "Ja",
        "status_disable_popup_no_button" : "Nein",
        "preview_no_question_popup_title" : "Frage nicht gefunden",
        "preview_no_question_popup_text" : "Frage nicht in Umfrage gefunden, kann nicht in der Vorschau angezeigt werden",
        "preview_no_question_popup_ok_button" : "Ok",
        "draft_disable_popup_text" : "Die Umfrage befindet sich im Entwurfsmodus. Du kannst sie nicht deaktivieren",
        "draft_disable_popup_ok_button" : "Ok",
        "status_activate_popup_text" : "Möchtest du die Umfrage wirklich aktivieren? Die Teilnahme ist dann aktiviert.",
        "user_university_not_exist":'Bitte wähle deine "Aktuelle Hochschule" im "Mein Profil" Tab aus, um mit der Erstellung zu beginnen.',
        "university_popup_close_button":"Schließen",
        "status_disable_popup_text" : "Willst du die Umfrage wirklich deaktivieren? Die Teilnahme ist danach nicht mehr möglich.",
        "find_participant" : "Teilnehmer finden",
        "analyze" : "Auswerten",
        "publish" : "Veröffentlichen",
        "toedit_popup_heading" : "Bearbeiten nicht möglich",
        "toedit_popup_subtext" : "Da du die Ergebnisse deiner Umfrage bei empirio veröffentlicht hast, kannst du deine Umfrage im Moment nicht bearbeiten. Bitte beende zunächst die Veröffentlichung, um deine Umfrage anschließend wieder bearbeiten zu können.",
        "copy_survey" : "Kopieren",
        "copy_survey_text" : "Möchtest du die Umfrage wirklich kopieren?",
        "copy_survey_heading" : "Umfrage kopieren",
        "user_current_school_not_exist":'Bitte trage den Namen deiner Schule im "Mein Profil" Tab ein, um mit der Erstellung zu beginnen.',
        "user_organization_not_exist":'Bitte trage den Namen deiner Organisation im "Mein Profil" Tab ein, um mit der Erstellung zu beginnen.',
        "conform_mail_text" : "Du bist jetzt auf der Warteliste!"
    }
}
/**constant for survey creation constant */
export const SURVEY_CREATION_CONSTANT = {
    EN: {
        "common_survey_title": "Create a poll",
        "question_survey_title":"Question",
        "continue_button": "CONTINUE",
        "continue_button_to_finish_button":"FINISHED",
        "continue_button_edit_question":"SAVE",
        "back_button_edit_question":"Cancel",
        "back_button": "BACK",
        "close_button":"SHUT DOWN",
        "find_participants_button":"FIND PARTICIPANTS",
        "survey_title_page_question": "What is the title of your survey?",
        "survey_title_page_subtitle": "Please select a descriptive title for your survey and note that you will not be able to change it later.",
        "survey_title_page_IPH": "Title of your survey",
        "survey_category_page_question": "Which category would you like to assign your survey to?",
        "survey_category_page_subtitle": "The category is used to sort your survey correctly, if you later seek participants in the empirio community.",
        "survey_category_page_default_option": "Choose a category",
        "survey_picture_page_title1": "Which image do you want to use for your survey?",
        "survey_picture_page_subtitle": "This image will be displayed in several places related to your survey (e.g., your poll's homepage, etc.).",
        "survey_welcome_page_title1": "Would you like to create a welcome page for your survey?",
        "survey_welcome_page_subtitle": "Here you have the opportunity to introduce your research as an introduction (for example, the purpose of the survey, etc.)",
        "survey_welcome_page_yes_button": "Yes",
        "survey_welcome_page_no_button": "No",
        "survey_welcome_page_content_page_title": "How should your welcome page be structured?",
        "survey_welcome_page_content_page_subtitle": "With the help of the corresponding buttons, you can successively add either a text or an image element to your welcome page.",
        "survey_welcome_text_content": "<p class='font-weight-bold font-24 survey-title'>Congratulations, the beginning has been made!</p></br><p class='font-16 lh-26 fw-400'>You have now already added the first question to your survey. You can create the other questions with our survey editor.</p><b class='font-16 lh-26'>So it goes on</b><ul class='common-list'><li class='font-16 lh-26 fw-400'>Select demographics that you want to know from the participant. We will automatically generate the related questions for you.</li><li class='font-16 lh-26 fw-400'>Add, change and delete more questions</li><li class='font-16 lh-26 fw-400'>Insert information pages (e.g. for the welcome)</li><li class='font-16 lh-26 fw-400'>Change the order of the questions</li><li class='font-16 lh-26 fw-400'>Define response logics</li><li class='font-16 lh-26 fw-400'>Preview your survey</li></ul></br><b class='font-16 lh-26'>Please note</b><p class='font-16 lh-26 fw-400'>The following data may not be requested: Personal data from which a person's racial or ethnic origin, political opinions, religious or ideological beliefs emerge; Union membership; genetic data, biometric data that are processed exclusively for the unique identification of a natural person; Health data; Data about a person's sex life or sexual orientation.</p>",
        "survey_content_page_text_button": "Text",
        "survey_content_page_image_button": "Image",
        "survey_content_page_header_button": "Heading",
        "survey_content_page_text_placeholder": "Text enter here...",
        "survey_content_page_header_placeholder": "Header enters here...",
        "survey_content_page_choose_image_button": "File Upload",
        "survey_element_page_title": "Which question types would you like to add to your survey?",
        "survey_element_page_subtitle": "Depending on what you want to know from the participant, you can select one of several possible question types. Create your survey step by step.",
        "survey_element_page_tipps":{
            0:"Use the selection menu to add new questions to your survey. When you've done that, just click Next to continue.",
            1:"With this question type the participant has the possibility to freely answer your question (eg justification for a given answer).",
            2:"Here you can use text and image elements to give your participants additional information.",
            3:"Leave an answer unfilled to allow the participant to enter a response himself.",
            4:"Leave an answer unfilled to allow the participant to enter a response himself.",
            5:"Here, the participant can bring individual answer options in the order of his choice.",
            6:"Here the participant can rate your question with one of the selectable methods.",
            7:"Here the participant can rate several statements on a rating scale of your choice.",
        },
        "survey_element_page_tipps_default_option":{
            0:'Selection of demographic characteristics that you want to know from the participant. The corresponding questions are automatically generated for you',
            1:'Definition of response logics',
            2:'Edit the entire survey'
        },
        "survey_element_page_question_type_default_option":"Please select question types",
        "survey_element_page_question_IPH":"What is your question?",
        "survey_element_page_rating_single_above_option_title":"Your own rating scale (e.g. applies, partially applies, ...)",
        "survey_element_page_option_lable":"Answer",
        "survey_element_page_rating_option_lable":"Criteria",
        "survey_element_page_rating_scale_option_lable":"expression",
        "survey_element_page_rating_more_above_option_title":"Your own rating scale (e.g. applies, partially applies, ...)",
        "survey_element_page_rating_more_above_scale_title":"The statements to be evaluated (e.g. I am... self-confident, team player, extrovert)",
        "survey_demographic_page_title":"Which demographic characteristics do you want to query from your participants?",
        "survey_demographic_page_subtitle":"We automatically generate all questions for the selected demographic characteristics. If you need more features, just create your own questions in the previous section.",
        "survey_creating_condition_page_information_title":"You have now finished creating the questions.",
        "survey_creating_condition_page_information_subtitle":"In the following section, you can change the order of the questions, delete individual questions and set logics for the survey participants. You also have the opportunity to see a preview of the survey participation.",
        "survey_creating_condition_page_title":"Survey editor",
        "survey_creating_condition_page_subtitle":'For each answer option you can define a question to which the participant should jump in this case. Just open the question with the "+".',
        "survey_creating_condition_page_confirm_alert":"Do you really want to delete the question?",
        "survey_creating_condition_page_confirm_alert_not_delete":"Your survey needs at least one question.",
        "survey_creating_condition_page_confirm_alert_not_delete_close_button":"Close",
        "survey_creating_condition_page_confirm_alert_yes_button":"Yes",
        "survey_creating_condition_page_confirm_alert_no_button":"No",
        "survey_creating_condition_page_default_dropdown_1":"Jump to ...",
        "survey_creating_condition_page_default_dropdown_2":"Demographic attributes",
        "survey_creating_condition_page_delete_question_backend_error":"You can not delete this question because this question has condition",
        "survey_end_page_title": "Congratulation! Your survey is ready",
        "survey_end_page_subtitle":"You can now send your survey link and optionally find participants in the empirio community.",
        "survey_question_delete_error_1" : "The question can not be deleted because it is referenced by an answer in question",
        "survey_question_delete_error_2" : ". Please remove this link and try again.",
        "popup_button_ok_text" : "OK",
        "demographic_characteristic_inquiry" : "Inquire about demographic characteristics",
        "Define_closing_page" : "Define closing page",
        "survey_demographic_toggle_sie" : "formal",
        "survey_demographic_toggle_du" : "informal",
        "survey_question_mandatory_toggle" : "Mandatory",
        "survey_question_optional_toggle" : "Non-mandatory",
        "survey_creating_condition_comment_heading" : 'Give feedback',
        "survey_creating_condition_comment_subtext" : 'Help us to make empirio even better by telling us your suggestions for improvement and suggestions:',
        "survey_creating_condition_comment_error_msg" : "Please enter your feedback",
        "survey_creating_condition_comment_send_button" : 'Send',
        "survey_creating_condition_page_default_dropdown_3":"End of the survey",
        "copy_survey_question" : "Do you really want to copy the question?",
        "survey_customLogo_heading" : "Would you like to use your own logo?",
        "survey_customLogo_subheading" : "Use the \"Logo 1\" tile to select the logo that is to be displayed at the top left during participation. In addition, with the \"Logo 2\" tile, you have the option of specifying an extra logo for the start page and the end page of your survey - a white logo with a transparent background is particularly suitable here.",
        "Corporate_Identity_Color_Picker" : "Would you like to define your own color?",
        "Corporate_Identity_Color_Picker_subheading" : "You have the option of using your own color for the elements that are displayed in \"empirio blue\" in your survey (\"Next\" button, etc.). Simply select one of the colors shown below or design the survey in your company's color by entering a hex value.",
        "data_protection" : "Data protection",
        "data_protection_toggle": "Active",
        "survey_data_protection_heading": "Consent Statement",
        "Survey_data_protection_checkbox_text" : "I agree and would like to take part in the survey",
        "survey_data_protection_text0": "Example:",
        "survey_data_protection_text1": "This poll is about the topic",
        "survey_data_protection_text2": "Participation in the survey is voluntary and does not require registration. In addition, no contact details or names are recorded.",
        "survey_data_protection_text3": "Within the survey, personal data (age, gender, level of education, etc.) are requested. These are used for further evaluation and interpretation of the results. Your information will of course be treated confidentially.",
        "survey_data_protection_text4": "To exercise your rights (information, correction, deletion, revocation, etc.) or questions about the survey, you can contact me at",
        "survey_data_protection_text5": "to contact.",
    },
    DE: {
        "common_survey_title": "Umfrage erstellen",
        "question_survey_title":"Frage",
        "continue_button": "WEITER",
        "continue_button_to_finish_button":"FERTIG",
        "continue_button_edit_question":"SPEICHERN",
        "back_button_edit_question":"ABBRECHEN",
        "back_button": "ZURÜCK",
        "close_button":"SCHLIEßEN",
        "find_participants_button":"TEILNEHMER FINDEN",
        "survey_title_page_question": "Wie lautet der Titel deiner Umfrage?",
        "survey_title_page_subtitle": "Bitte wähle einen aussagekräftigen Titel für deine Umfrage und beachte, dass du diesen später nicht mehr ändern kannst.",
        "survey_title_page_IPH": "Titel deiner Umfrage",                 /**IPH -input placeholder */
        "survey_category_page_question": "Welcher Kategorie würdest du deine Umfrage zuordnen?",
        "survey_category_page_subtitle": "Die Kategorie dient dazu deine Umfrage richtig einzusortieren, wenn du später Teilnehmer in der empirio-Community suchst.",
        "survey_category_page_default_option": "Wähle eine Kategorie",
        "survey_picture_page_title1": "Welches Bild möchtest du für deine Umfrage verwenden?",
        "survey_picture_page_subtitle": "Dieses Bild wird an diversen Stellen in Zusammenhang mit deiner Umfrage angezeigt (z.B. Startseite deiner Umfrage etc.).",
        "survey_welcome_page_title1": "Möchtest du eine Begrüßungsseite für deine Umfrage erstellen?",
        "survey_welcome_page_subtitle": "Hier hast du die Möglichkeit deine Forschung einleitend vorzustellen (z.B. Ziel der Umfrage etc.)",
        "survey_welcome_page_yes_button": "Ja",
        "survey_welcome_page_no_button": "Nein",
        "survey_welcome_page_content_page_title": "Wie soll deine Begrüßungsseite aufgebaut sein?",
        "survey_welcome_page_content_page_subtitle": "Mit Hilfe der entsprechenden Buttons, kannst du nacheinander entweder ein Text- oder ein Bild-Element zu deiner Begrüßungsseite hinzufügen.",
        "survey_welcome_text_content": "<p class='font-weight-bold font-24 survey-title'>Glückwunsch, der Anfang ist gemacht!</p></br><p class='font-16 lh-26 fw-400'>Du hast nun schon die erste Frage zu deiner Umfrage hinzugefügt. Die weiteren Fragen kannst du mit unserem Umfrage-Editor erstellen.</p><b class='font-16 lh-26'>So geht's weiter</b><ul class='common-list'><li class='font-16 lh-26 fw-400'>Demografischen Merkmale auswählen, die du vom Teilnehmer wissen möchtest. Die dazugehörigen Fragen generieren wir ganz automatisch für dich.</li><li class='font-16 lh-26 fw-400'>Weitere Fragen hinzufügen, ändern und löschen</li><li class='font-16 lh-26 fw-400'>Informantionsseiten (z.B. zur Begrüßung) einfügen</li><li class='font-16 lh-26 fw-400'>Reihenfolge der Fragen verändern</li><li class='font-16 lh-26 fw-400'>Antwort-Logiken festlegen</li><li class='font-16 lh-26 fw-400'>Vorschau deiner Umfrage anzeigen</li></ul></br><b class='font-16 lh-26'>Bitte beachte</b><p class='font-16 lh-26 fw-400'>Folgende Daten dürfen nicht abgefragt werden: Personenbezogene Daten, aus denen rassische oder ethnische Herkunft, politische Meinungen, religiöse oder weltanschauliche Überzeugungen einer Person hervorgehen; Gewerkschaftszugehörigkeit; genetische Daten, biometrische Daten, die ausschließlich zur eindeutigen Identifizierung einer natürlichen Person verarbeitet werden; Gesundheitsdaten; Daten zum Sexualleben oder zur sexuellen Orientierung einer Person.</p>",
        "survey_content_page_text_button": "Text",
        "survey_content_page_image_button": "Bild",
        "survey_content_page_header_button": "Überschrift",
        "survey_content_page_text_placeholder": "Text hier eingeben ...",
        "survey_content_page_header_placeholder": "Überschrift hier eingeben ...",
        "survey_content_page_choose_image_button": "Datei Upload",
        "survey_element_page_title": "Welchen Fragetypen möchtest du deiner Umfrage hinzufügen?",
        "survey_element_page_subtitle": "Je nachdem was du vom Teilnehmer wissen möchtest, kannst du einen von mehreren möglichen Fragetypen auswählen. Erstelle so Schritt für Schritt deine Umfrage.",
        "survey_element_page_tipps":{
            0:'Mit dem Auswahlmenü kannst du deiner Umfrage neue Fragen hinzufügen. Wenn du das erledigt hast, klicke einfach auf "Weiter", um fortzufahren.',
            1:"Mit diesem Fragetyp hat der Teilnehmer die Möglichkeit frei auf deine Frage zu antworten (z.B. Begründung für eine gegebene Antwort).",
            2:"Hier kannst du mit Text- und Bild-Elementen deinen Teilnehmern zusätzliche Informationen geben.",
            3:"Lass eine Antwort ungefüllt, um dem Teilnehmer die Möglichkeit zu geben selber eine Antwort einzugeben.",
            4:"Lass eine Antwort ungefüllt, um dem Teilnehmer die Möglichkeit zu geben selber eine Antwort einzugeben.",
            5:"Hier kann der Teilnehmer einzelne Antwortmöglichkeiten in die Reihenfolge seiner Wahl bringen.",
            6:"Hier kann der Teilnehmer deine Frage mit einer der auswählbaren Methoden bewerten.",
            7:"Hier kann der Teilnehmer auf einer Bewertungsskala deiner Wahl mehrere Aussagen bewerten.",
        },
        "survey_element_page_tipps_default_option":{
            0:'Auswahl der demografischen Merkmalen, die du vom Teilnehmer wissen möchtest',
            1:'Festlegung von Antwort-Logiken',
            2:'Bearbeiten der gesamten Umfrage'
        },
        "survey_element_page_question_type_default_option":"Bitte Fragetypen auswählen",
        "survey_element_page_question_IPH":"Wie lautet deine Frage ?",
        "survey_element_page_rating_single_above_option_title":"Deine eigene Bewertungsskala (z.B. trifft zu, trifft teilweise zu, ...)",
        "survey_element_page_option_lable":"Antwort",
        "survey_element_page_rating_option_lable":"Kriterium",
        "survey_element_page_rating_scale_option_lable":"Aussage",
        "survey_element_page_rating_more_above_option_title":"Deine eigene Bewertungsskala (z.B. trifft zu, trifft teilweise zu, ...)",
        "survey_element_page_rating_more_above_scale_title":"Die zu bewertenden Aussagen (z.B. Ich bin… selbstbewusst, teamfähig, extrovertiert)",
        "survey_demographic_page_title":"Welche demografischen Merkmale möchtest du von deinen Teilnehmern abfragen?",
        "survey_demographic_page_subtitle":"Wir generieren automatisch alle Fragen für die ausgewählten demografischen Merkmale. Solltest du noch weitere Merkmale benötigen, erstelle dafür einfach eigene Fragen im vorherigen Teil.",
        "survey_creating_condition_page_information_title":"Du hast die Erstellung der Fragen nun abgeschlossen.",
        "survey_creating_condition_page_information_subtitle":"Im folgenden Teil kannst du die Reihenfolge der Fragen verändern, einzelne Fragen löschen und Logiken für die Umfrage-Teilnehme festlegen. Außerdem hast du die Möglichkeit dir eine Vorschau der Umfrage-Teilnahme anzeigen zu lassen.",
        "survey_creating_condition_page_title":"Umfrage-Editor",
        "survey_creating_condition_page_subtitle":'Du kannst für jede Antwortmöglichkeit eine Frage definieren, zu welcher der Teilnehmer in diesem Fall springen soll. Klappe dazu einfach die entsprechende Frage mit dem "+" auf.',
        "survey_creating_condition_page_confirm_alert":"Willst du die Frage wirklich löschen?",
        "survey_creating_condition_page_confirm_alert_not_delete":"Deine Umfrage benötigt mindestens eine Frage.",
        "survey_creating_condition_page_confirm_alert_not_delete_close_button":"Schließen",
        "survey_creating_condition_page_confirm_alert_yes_button":"Ja",
        "survey_creating_condition_page_confirm_alert_no_button":"Nein",
        "survey_creating_condition_page_default_dropdown_1":"Springe zu...",
        "survey_creating_condition_page_default_dropdown_2":"Demografische Merkmale",
        "survey_creating_condition_page_delete_question_backend_error":"Du kannst diese Frage nicht löschen, da diese Frage eine Logik hat",
        "survey_end_page_title": "Glückwunsch! Deine Umfrage ist fertig",
        "survey_end_page_subtitle":"Du kannst nun gleich deinen Umfrage-Link verschicken und optional Teilnehmer in der empirio-Community finden.",
        "survey_question_delete_error_1" : "Die Frage kann nicht gelöscht werden, da sie durch eine Antwort in Frage",
        "survey_question_delete_error_2" : " referenziert wird. Bitte entferne diese Verlinkung und versuche es dann erneut.",
        "popup_button_ok_text" : "OK",
        "demographic_characteristic_inquiry" : "Demografische Merkmale abfragen",
        "Define_closing_page" : "Abschlussseite definieren",
        "survey_demographic_toggle_sie" : "Sie",
        "survey_demographic_toggle_du" : "Du",
        "survey_question_mandatory_toggle" : "Pflicht",
        "survey_question_optional_toggle" : "Optional",
        "survey_creating_condition_comment_heading" : 'Feedback geben',
        "survey_creating_condition_comment_subtext" : 'Hilf uns empirio noch besser zu machen, indem du uns hier deine Verbesserungsvorschläge und Anregungen mitteilst:',
        "survey_creating_condition_comment_error_msg" : "Bitte gib dein Feedback ein",
        "survey_creating_condition_comment_send_button" : 'Abschicken',
        "survey_creating_condition_page_default_dropdown_3" : "Ende der Umfrage",
        "copy_survey_question" : "Willst du die Frage wirklich kopieren?",
        "survey_customLogo_heading" : "Möchtest du ein eigenes Logo verwenden?",
        "survey_customLogo_subheading" : "Wähle mit der Kachel \"Logo 1\" das Logo aus, was während der Teilnahme oben links angezeigt werden soll. Zusätzlich hast du optional mit der Kachel \"Logo 2\" die Möglichkeit ein extra Logo für die Startseite sowie die Abschlusseite deiner Umfrage festzulegen - ein weißes Logo mit transparentem Hintergrund eignet sich hier besonders gut.",
        "Corporate_Identity_Color_Picker" : "Möchtest du eine eigene Farbe definieren?",
        "Corporate_Identity_Color_Picker_subheading" : "Du hast die Möglichkeit, für die Elemente, die in deiner Umfrage im \"empirio Blau\" dargestellt werden (\"Weiter\"-Button etc.), eine eigene Farbe zu verwenden. Wähle dazu einfach eine der unten gezeigten Farben aus oder gestalte die Umfrage in der Farbe deines Unternehmens, indem du einen Hex-Wert angibst.",
        "data_protection" : "Datenschutzerklärung",
        "survey_data_protection_text0": "Beispiel:",
        "survey_data_protection_text1": "In dieser Umfrage geht es um das Thema",
        "survey_data_protection_text2": "Die Teilnahme an der Umfrage ist freiwillig und benötigt keine Registrierung. Außerdem werden keine Kontaktdaten oder Namen erfasst.",
        "survey_data_protection_text3": "Innerhalb der Umfrage werden personenbezogene Daten (Alter, Geschlecht, Bildungsstand etc.) abgefragt. Diese dienen zur weiterführenden Auswertung und Interpretation der Ergebnisse. Deine Angaben werden selbstverständlich vertraulich behandelt.",
        "survey_data_protection_text4": "Zur Ausübung deiner Rechte (Auskunft, Korrektur, Löschung, Widerruf, etc.) oder Fragen zur Umfrage kannst du mich unter",
        "survey_data_protection_text5": "kontaktieren.",
        "data_protection_toggle": "Aktiv",
        "survey_data_protection_heading": "Einwilligungserklärung",
        "Survey_data_protection_checkbox_text" : "Ich willige ein und möchte an der Umfrage teilnehmen"
    }
}
export const FREE_TEXT_OPTIOPNS = {
    EN: [{ "id": "0", "free_text_name": "Short Answer" }, { "id": "1", "free_text_name": "Long Answer" }],
    DE: [{ "id": "0", "free_text_name": "Kurze Antwort" }, { "id": "1", "free_text_name": "Lange Antwort" }]
}
export const SURVEY_CREATION_ERROR_MESSAGE = {
    EN: {
        "survey_title_page": "Please enter the title of your poll.",
        "survey_category_page": "Please choose a category.",
        "survey_picture_page": "Please select a picture.",
        "survey_welcome_page": "Please choose if you want to create a welcome page.",
        "survey_welcome_content_page": "Please add at least one text or picture element to your welcome page.",
        "survey_welcome_content_page_text": "Please fill up all the content text.",
        "survey_welcome_content_page_image": "please add all the content image.",
        "survey_welcome_content_page_image_ext": "Please only select pictures",
        "survey_welcome_content_page_image_size": "Your pictures must not be bigger than 5 MB",
        "survey_welcome_content_page_max_limit":"You can not add more than 10 items to your welcome page.",
        "survey_element_page":"Please add at least one question to your survey.",
        "survey_element_page_type":"please select the question type.",
        "survey_element_page_question":"Please enter question title.",
        "survey_element_page_option_default":"Please fill up all option of the question.",
        "survey_element_page_option_single_multiple_selection":"A maximum of one answer can be left blank.",
        "survey_element_page_option_image_default":"Please upload an image for each answer option.",
        "survey_element_page_scale":"Please select at least one scale.",
        "survey_element_page_scale_option":"Please add all the scale option for question.",
        "survey_element_page_option":"Add at least one option in this question.",
        "survey_element_page_content_image_ext": "Please only select pictures",
        "survey_element_page_content_image_size": "Your pictures must not be bigger than 5 MB",
        "survey_element_page_content_max_limit":"You can not add more than 10 items to your content question.",
        "survey_title_limit": "Survey Title can be of 100 characters maximum.",
        "survey_define_closing_page":"Please fill in heading and text or empty both fields",
    },
    DE: {
        "survey_title_page": "Bitte gib den Titel deiner Umfrage ein.",
        "survey_category_page": "Bitte wähle eine Kategorie.",
        "survey_picture_page": "Bitte wähle ein Bild.",
        "survey_welcome_page": "Bitte wähle, ob du eine Begrüßungsseite erstellen möchtest.",
        "survey_welcome_content_page": "Bitte füge mindestens ein Text- oder Bild-Element zu deiner Begrüßungsseite hinzu.",
        "survey_welcome_content_page_text": "Bitte gib den Text ein",
        "survey_welcome_content_page_image": "Bitte lade das Bild hoch",
        "survey_welcome_content_page_image_ext": "Bitte nur Bilder auswählen",
        "survey_welcome_content_page_image_size": "Deine Bilder dürfen jeweils nicht größer als 5 MB sein",
        "survey_welcome_content_page_max_limit":"Du kannst nicht mehr als 10 Elemente zu deiner Begrüßungsseite hinzufügen.",
        "survey_element_page":"Bitte füge deiner Umfrage mindestens eine Frage hinzu.",
        "survey_element_page_type":"Bitte wählen Sie den Fragetyp.",
        "survey_element_page_question":"Bitte gib den Titel der Frage an",
        "survey_element_page_scale":"Bitte wähle eine Bewertungsskala aus",
        "survey_element_page_option_default":"Bitte alle Antworten ausfüllen.",
        "survey_element_page_option_single_multiple_selection":"Maximal eine Antwort darf leer gelassen werden.",
        "survey_element_page_option_image_default":"Bitte lade für jede Antwortmöglichkeit ein Bild hoch.",
        "survey_element_page_scale_option":"Bitte füge die Bewertungsskala für die Frage hinzu.",
        "survey_element_page_option":"Fügen Sie dieser Frage mindestens eine Option hinzu.",
        "survey_element_page_content_image_ext": "Bitte nur Bilder auswählen",
        "survey_element_page_content_image_size": "Deine Bilder dürfen jeweils nicht größer als 5 MB sein",
        "survey_element_page_content_max_limit":"Du kannst nicht mehr als 10 Elemente hinzufügen.",
        "survey_title_limit": "Der Umfrage-Titel darf maximal 100 Zeichen haben.",
        "survey_define_closing_page":"Bitte Überschrift und Text füllen oder beide Felder leeren",
    }
}
export const SURVEY_TERMS_CONDITION = {
    EN: {
        "terms_condition1": "With the start of the poll you agree with the",
        "terms_condition2": "Conditions",
        "terms_condition3": "and the",
        "terms_condition4": "Privacy Policy",
        "terms_condition5": "to Empirio UG"
    },
    DE: {
        "terms_condition1": "Mit dem Start der Umfrage stimmst du den",
        "terms_condition2": "AGB",
        "terms_condition3": "und den",
        "terms_condition4": "Datenschutzbestimmungen",
        "terms_condition5": "der Empirio UG zu"
    }
}
/** constant for terms and condition which display in registration page */
export const REGISTRATION_TERMS_CONDITION = {
    EN: {
        "terms_condition1": "I agree to the",
        "terms_condition2": "Privacy Policy",
        "terms_condition3": "and the",
        "terms_condition4": "Terms",
        "terms_condition5": "too."
    },
    DE: {
        "terms_condition1": "Ich stimme der",
        "terms_condition2": "Datenschutzerklärung",
        "terms_condition3": "und den",
        "terms_condition4": "AGB",
        "terms_condition5": "zu."
    }
}
/** constant for EditSurvey component */
export const EDIT_SURVEY_CONSTANT = {
    EN: {
        "tab1": "To edit",
        "tab2": "Find participants",
        "tab3": "Evaluate",
        "tab4": "Publish",
        "send_survey_by_link": "The link to your survey",
        "send_survey_by_link_subtitle" : "Send the link of your survey to the desired participants and post it on social networks. To protect you from multiple participations, the link can only be used once on a device.",
        "Enter_link_label": "Enter the link here",
        "survey_karmapoint_link" : "Step 1: Take part in surveys in the community and get karma points for your survey",
        "survey_karmapoint_link_subtitle" : "For every minute you take part in other surveys, you will receive an empirioKarma Point. The more points you have, the higher your survey will later be placed in the empirioKarma ranking and the higher visibility you will receive more participants. ",
        "find_out_participants": "Step 2: Find participants in the empirio community",
        "find_out_participants_subtitle" : "Share your survey in our community and get more participants for your survey.",
        "find_participants_subtitle1" : "Congratulation! Your survey is now published in the empirioKarma ranking.",
        "find_participants_subtitle2" : "you get to the introduction page of your survey.",
        "survey_mypoll_link" : "Here",
        "description_of_survey": "Presentation of the survey",
        "target_group_of_survey": "Audience of the survey",
        "end_date_of_survey": "End date",
        "reward_category": "Reward",
        "reward_description": "Details of the reward",
        "publish_button_text": "Share in community",
        "copied_message" : "Text copied",
        "copy_to_clipboard" : "Copy to clipboard",
        "disable_button_text" : "Edit data",
        "disable_popup_text" : "Do you really want to end the survey? Participation it is disabled afterwards.",
        "disable_popup_yes_button":"Yes",
        "disable_popup_no_button" : "No",
        "default_reward_option" : "Please choose",
        "invalid" : "invalid date",
        "survey_karmapoint_link_subtitle2" : "you get to the polls in our community.",
        "current_rank" : "Current rank",
        "karma_of_point" : "Required karma up to 1st rank",
        "number_of_days" : "Remaining days",
        "find_participation_heading" : "Optional: find participants in the empirio community",
        "external_link_subject" : "Link to the survey",
        "request_community_heading" : "Find participants in the empirio community now",
        "request_community_text" : "In our community - the empirioKarma Ranking - students support each other every day by taking part in their surveys. We offer selected partners access to this community in order to gain participants for your survey (e.g. market research). If you are interested, you can be put on the waiting list - we will contact you as soon as places are available.",
        "request_community_button" : "On the waiting list",
        "toggle_text" : "Allow multiple participations",
        "english_lable_button" : "English poll",
    },
    DE: {
        "tab1": "Bearbeiten",
        "tab2": "Teilnehmer finden",
        "tab3": "Auswerten",
        "tab4": "Veröffentlichen",
        "send_survey_by_link": "Der Link zu deiner Umfrage",
        "send_survey_by_link_subtitle" : "Verschicke den Link deiner Umfrage an die gewünschten Teilnehmer und poste ihn in sozialen Netzwerken. Damit du vor mehrfachen Teilnahmen einer Person geschützt bist, kann der Link auf einem Gerät jeweils nur einmal verwendet werden.",
        "Enter_link_label": "Geben Sie hier den Link ein",
        "survey_karmapoint_link" : "Schritt 1: Nimm an Umfragen in der Community teil und erhalte Karma Punkte für deine Umfrage",
        "survey_karmapoint_link_subtitle" : "Für jede Minute, die du an anderen Umfragen teilnimmst, erhältst du einen empirioKarma Punkt. Je mehr Punkte du hast, desto höher wird deine Umfrage später im empirioKarma Ranking platziert und durch die höhere Sichtbarkeit erhältst du mehr Teilnehmer. ",
        "find_out_participants": "Schritt 2: In der empirio-Community Teilnehmer finden",
        "find_out_participants_subtitle" : "Teile deine Umfrage in unserer Community und erhalte so weitere Teilnehmer für deine Umfrage.",
        "find_participants_subtitle1" : "Glückwunsch! Deine Umfrage ist nun im empirioKarma Ranking veröffentlicht.",
        "find_participants_subtitle2" : "gelangst du zur Vorstellungsseite deiner Umfrage.",
        "survey_mypoll_link" : "Hier",
        "description_of_survey": "Vorstellung der Umfrage",
        "target_group_of_survey": "Zielgruppe der Umfrage",
        "end_date_of_survey": "Enddatum",
        "reward_category": "Belohnung",
        "reward_description": "Details zur Belohnung",
        "publish_button_text": "In Community teilen",
        "copied_message" : "Text kopiert",
        "copy_to_clipboard" : "In Zwischenablage kopieren",
        "disable_button_text" : "Daten bearbeiten",
        "disable_popup_text" : "Willst du die Umfrage wirklich beenden? Die Teilnahme daran ist danach deaktiviert.",
        "disable_popup_yes_button":"Ja",
        "disable_popup_no_button" : "Nein",
        "default_reward_option" : "Bitte wählen",
        "invalid" : "ungültiges Datum",
        "survey_karmapoint_link_subtitle2" : "gelangst du zu den Umfragen in unserer Community.",
        "current_rank" : "Aktueller Rang",
        "karma_of_point" : "Benötigtes Karma bis zum 1. Rang",
        "number_of_days" : "Verbleibende Tage",
        "find_participation_heading" : "Optional: Teilnehmer finden in der empirio Community",
        "external_link_subject" : "Link zur Umfrage",
        "request_community_heading" : "Jetzt  Teilnehmer in der empirio Community finden",
        "request_community_text" : "In unserer Community - dem empirioKarma Ranking - unterstützen sich täglich Studierende gegenseitig mit Teilnahmen an ihren Umfragen. Wir bieten ausgewählten Partnern Zugang zu dieser Community, um Teilnehmende für Ihre Umfrage (z.B. Marktforschung) zu gewinnen. Bei Interesse kannst du dich auf die Warteliste setzen lassen - wir kontaktieren dich dann, sobald Plätze verfügbar sind.",
        "request_community_button" : "Auf die Warteliste",
        "toggle_text" : "Mehrfach-Teilnahme erlauben",
        "english_lable_button" : "Englische Umfrage",

    }
}
/**constant for error messages in find participants component */
export const FIND_PARTICIPANTS_ERROR_MESSAGE = {
    EN: {
        "about_survey_text": "Please fill in",
        "about_target_group_text": "Please fill in",
        "end_date": "Please fill in",
        "reward_type": "Please select",
        "reward_description": "Maximum 100 characters allowed",
        "notvaliddate" : "Please select a date in the future",
        "biggerDate" : "Survey may take a maximum of 2 months"
    },
    DE: {
        "about_survey_text": "Bitte ausfüllen",
        "about_target_group_text": "Bitte ausfüllen",
        "end_date": "Bitte ausfüllen",
        "reward_type": "Bitte auswählen",
        "reward_description": "Maximal 100 Zeichen erlaubt",
        "notvaliddate" : "Bitte ein Datum in der Zukunft auswählen",
        "biggerDate" : "Umfrage darf maximal 2 Monate dauern"
    }
}
/**constant for final year dropdown in my profile */
export const FINAL_YEAR_DROPDOWN = {
    EN: [{ "id": "2019", "final_year": "2019" }, { "id": "2020", "final_year": "2020" },{ "id": "2021", "final_year": "2021" },{ "id": "2022", "final_year": "2022" },{ "id": "2023", "final_year": "2023" },{ "id": "2024", "final_year": "2024" },{ "id": "2025", "final_year": "2025" },{ "id": "2026", "final_year": "2026" },{ "id": "2027", "final_year": "2027" },{ "id": "2028", "final_year": "2028" },{ "id": "2029", "final_year": "2029" }],
    DE: [{ "id": "2019", "final_year": "2019" }, { "id": "2020", "final_year": "2020" },{ "id": "2021", "final_year": "2021" },{ "id": "2022", "final_year": "2022" },{ "id": "2023", "final_year": "2023" },{ "id": "2024", "final_year": "2024" },{ "id": "2025", "final_year": "2025" },{ "id": "2026", "final_year": "2026" },{ "id": "2027", "final_year": "2027" },{ "id": "2028", "final_year": "2028" },{ "id": "2029", "final_year": "2029" }],
}
/**my profile's main profile form constant */
export const MYPROFILE_MAINPROFILE_CONSTANT = {
    EN : {
        "general_information" : "General information",
        "first_name" : "First name",
        "surname" : "Surname",
        "date_of_birth" : "Date of birth",
        "gender" : "gender",
        "nationality" : "Nationality",
        "residence" : "place of residence",
        "marital_status" : "Marital status",
        "status_and_income" : "Status and income",
        "employment" : "Employment",
        "income" : "Net income",
        "current_studies" : "Current studies",
        "university" : "Current university",
        "course_of_studies" : "course of studies",
        "highest_level_of_education" : "Highest level of education",
        "targeted_education" : "Targeted education",
        "desired_final_year" : "Desired final year",
        "dropdown_default_value" : "Please choose",
        "save_changes_button" : "Save Changes",
        "quick_click_heading": "empirio quick click",
        "quick_click_text" : "The more profile fields you fill out, the more time you save when taking part in surveys in the community. Because with the empirio Quick-Click you don't have to enter your demographic characteristics (age, gender, etc.) in any survey - they are then automatically transmitted.",
        "polls_heading_ab": "Do you need support with your scientific work?",
        "polls_text_ab" : "At our partner GoThesis you will find many AI-based tools that will support you in your academic work - creation of a thesis guide, personal Chat-based thesis assistant, proofreading, plagiarism checking and much more.<br/><a href='https://www.gothesis.de/ki' target='_blank'>Now to the AI ​​tools</a>",
        "polls_heading_d": "Do you need support?",
        "polls_text_d" : "With our full-service offer, we support you with surveys of employees, customers, etc.starting from €999 - from planning to implementation to evaluation. <br/>Simply write your request now to <a href='mailto:hallo@empirio.de'>hallo@empirio.de</a>.",
        "current_school": "Name of the school",
        "name_of_the_organization": "Name of the organization",
        "postal_code" : "Postal code",
        "house_number" : "House number",
        "phone_number" : "Phone number",
        "your_position" : "Your position",
        "subject_area" : "Subject area",
        "street" : "street",
        "quick_click_NSU": "Account type"
    },
    DE : {
        "general_information" : "Allgemeine Informationen",
        "first_name" : "Vorname",
        "surname" : "Nachname",
        "date_of_birth" : "Geburtsdatum",
        "gender" : "Geschlecht",
        "nationality" : "Staatsangehörigkeit",
        "residence" : "Wohnort",
        "marital_status" : "Familienstand",
        "status_and_income" : "Status und Einkommen",
        "employment" : "Erwerbsstatus",
        "income" : "Nettoeinkommen",
        "current_studies" : "Weitere Informationen",
        "university" : "Aktuelle Hochschule",
        "course_of_studies" : "Studiengang",
        "highest_level_of_education" : "Höchster Bildungsabschluss",
        "targeted_education" : "Angestrebter Bildungsabschluss",
        "desired_final_year" : "Angestrebtes Abschlussjahr",
        "dropdown_default_value" : "Bitte wählen",
        "save_changes_button" : "Änderungen speichern",
        "quick_click_heading": "empirio Quick-Klick",
        "polls_heading_ab": "Du benötigst Unterstützung bei deiner wissenschaftlichen Arbeit?",
        "polls_text_ab" : "Bei unserem Partner GoThesis findest du viele KI-basierte Tools, die dich bei deiner wissenschaftlichen Arbeit unterstützen - Erstellung eines Thesis-Leitfadens, Chat-basierter Thesis-Assistent, Lektorat, Plagiatsprüfung uvm.<br/><a href='https://www.gothesis.de/ki' target='_blank'>> Jetzt zu den KI-Tools</a>",
        "polls_heading_d": "Du benötigst Unterstützung?",
        "polls_text_d" : "Mit unserem Full-Service Angebot unterstützen wir schon ab 999€ bei der Befragung von Mitarbeitern, Kunden etc. - von der Planung, über die Durchführung bis zur Auswertung.<br>Schreibe jetzt einfach deine Anfrage an <a href='mailto:hallo@empirio.de'>hallo@empirio.de</a>.",
        "quick_click_text" : "Je mehr Profil-Felder du ausfüllst, desto mehr Zeit sparst du beim Teilnehmen von Umfragen in der Community. Denn mit dem empirio Quick-Klick musst du bei keiner Umfrage deine demografischen Merkmale (Alter, Geschlecht usw.) angeben - sie werden dann automatisch übermittelt.",
        "current_school": "Name der Schule",
        "name_of_the_organization": "Name der Organisation",
        "postal_code" : "Postleitzahl",
        "house_number" : "Hausnummer",
        "phone_number" : "Telefonnummer",
        "your_position" : "Deine Position",
        "subject_area" : "Fachrichtung",
        "street" : "Straße",
        "quick_click_NSU": "Account Typ"
    } 
}

/** myprofile's profiletab constant */
export const MYPROFILE_PROFILETAB_CONSTANT = {
    EN : {
        "my_profile" : "My profile",
        "my_polls" : "My polls",
        "settings" : "Settings",
        "statistic" : "Statistics"
    },
    DE : {
        "my_profile" : "Mein Profil",
        "my_polls" : "Meine Umfragen",
        "settings" : "Einstellungen",
        "statistic" : "Statistiken"
    }
}
/** myprofile's settings constant */
export const MYPROFILE_SETTINGS_CONSTANT = {
    EN : {
        "credentials" : "credentials",
        "email_address" : "e-mail address",
        "new_password" : "New Password",
        "repeat_new_password" : "Repeat new password",
        "notification" : "Notifications",
        "notification_about_activities" : "I would like to receive notification about my activities on empirio",
        "notification_about_newsletter" : "I would like to receive the newsletter from empirio",
        "miscellaneous" : "Miscellaneous",
        "notification_about_quickclick" : "Enable empirio quick-click (we simply use the data from your profile to automatically answer and skip demographic questions)",
        "You_mochcount_yours" : "You want yours",
        "delete_account_link" : "Delete account?",
        "save_changes_button" : "Save Changes",
        "delete_account_popup_message" : "Are you really sure that you want to permanently delete your account?",
        "delete_account_popup_yes_button" : "Yes, delete it!",
        "delete_account_popup_cancel_button" : "Cancel",
        "delete_survey_participant_popup_message" : "Are you sure you want to delete this participant ?",
        "confirm_alert_yes_button_text" : "Yes",
        "confirm_alert_no_button_text" : "No",
        "delete_account_heading_message" : "It's a shame you want to go",
        "delete_account_subtext_message" : "Please tell us why you want to delete your account:",
        "reason_error_delete_account" : 'Please provide the reason for deleting your account',
        "plus_membership_active_msg_category_D": "The Plus membership will expire on",
        "plus_membership_active_msg_category2_D": "automatically extended.",
        "plus_membership_active_link_category_D": "Cancel here now.",
        "cancle_subscripton_heading" : "Do you really want to cancel the empirio Plus subscription?",
        "cancle_subscripton_text" : "Please let us know why you want to end your subscription so we can keep improving:",
        "reason_error_cancle_subscripton" : 'Please provide the reason for cancle your subscripton',
        "plus_membership_cancel_msg_category_D": "The Plus membership runs on",
        "plus_membership_cancel_msg_category2_D": "away.",
        "plus_membership_cancel_link_category_D": "Extend here now.",
        "UserCanNotDelteAccount" : "Before the account can be deleted, the active empirio Plus subscription must first be terminated.",
        "reason_error_sub_cancel" : 'Please provide the reason for canceling the subscription',
        "cancel_sub_success_msg": "Cancellation successful"
    },
    DE : {
        "credentials" : "Anmeldedaten",
        "email_address" : "E-Mail Adresse",
        "new_password" : "Neues Passwort",
        "repeat_new_password" : "Neues Passwort wiederholen",
        "notification" : "Benachrichtigungen",
        "notification_about_activities" : "Ich möchte Benachrichtigungen zu meinen Aktivitäten auf empirio erhalten",
        "notification_about_newsletter" : "Ich möchte den Newsletter von empirio erhalten",
        "miscellaneous" : "sonstiges",
        "notification_about_quickclick" : "empirio Quick-Klick aktivieren (wir nutzen einfach die Daten aus deinem Profil, um Fragen zu demografischen Merkmalen automatisch zu beantworten und zu überspringen)",
        "You_mochcount_yours" : "Du möchtest deinen",
        "delete_account_link" : "Account löschen?",
        "save_changes_button" : "Änderungen speichern",
        "delete_account_popup_message" : "Bist du dir wirklich sicher, dass du deinen Account unwiderruflich löschen möchtest?",
        "delete_account_popup_yes_button" : "Ja",
        "delete_account_popup_cancel_button" : "ABBRECHEN",
        "delete_survey_participant_popup_message" : "Möchtest du diesen Teilnehmer wirklich löschen?",
        "confirm_alert_yes_button_text" : "Ja",
        "confirm_alert_no_button_text" : "Nein",
        "delete_account_heading_message" : "Schade, dass du gehen möchtest",
        "delete_account_subtext_message" : "Bitte teile uns doch mit, warum du deinen Account löschen möchtest:",
        "reason_error_delete_account" : 'Bitte gib den Grund für die Löschung deines Accounts an',
        "plus_membership_active_msg_category_D": "Die Plus Mitgliedschaft wird am",
        "plus_membership_active_msg_category2_D": "automatisch verlängert.",
        "plus_membership_active_link_category_D": "Jetzt hier kündigen.",
        "cancle_subscripton_heading" : "Möchtest du das empirio Plus Abo wirklich kündigen?",
        "cancle_subscripton_text" : "Bitte teile uns doch mit, warum du dein Abo beenden möchtest, damit wir uns stetig verbessern können:",
        "reason_error_cancle_subscripton" : 'Bitte gib den Grund für die Löschung deines Accounts an',
        "plus_membership_cancel_msg_category_D": "Die Plus Mitgliedschaft läuft am",
        "plus_membership_cancel_msg_category2_D": "ab.",
        "plus_membership_cancel_link_category_D": "Jetzt hier verlängern..",
        "UserCanNotDelteAccount" : "Bevor der Account gelöscht werden kann, muss zunächst das aktive empirio Plus Abo beendet werden.",
        "reason_error_sub_cancel" : 'Bitte gib den Grund für die Kündigung des Abos an',
        "cancel_sub_success_msg": "Kündigung erfolgreich"
    }
}
/** myprofile's editprofile constant */
export const MYPROFILE_EDITPROFILE_CONSTANT = {
    EN : {
        "hey" : "Hey",
        "welcome_message" : "welcome in your own area",
        "empirio_karma_points_l1" : "empiriokarma",
        "empirio_karma_points_l2" : "Points",
        "level_l1" : "Current",
        "level_l2" : "Level",
        "ponts_to_next_level_l1" : "Points to the",
        "ponts_to_next_level_l2" : "next level",
        "next_level_bonus_l1" : "NEXT",
        "next_level_bonus_l2" : "Level bonus",
        "popup_ok_button_text" : "Ok",
        "referral_button" : "Refer friends",
        "referral_popup_subtext_1" : 'Do you like our initiative to support academic research?',
        "referral_popup_subtext_2" : '<p>Then invite your friends to empirio and receive a "karma multiplier" for our community. </p><p class="mt-4"><b>With every successful registration</b> Via your personal link you will receive the for every 10 participations <b>three times the amount of empirioKarma points</b>.</p>',
        "referral_friends_registered" : "Referred friends",
        "referral_karma_multiplier" : 'Participations with 3x karma multiplier remaining',
        "statistic_heading" : 'No statistics activated yet',
        "statistic_subheading1" : "What is shown here?",
        "statistic_subtext1" : 'Help other students with their empirical research by taking part in surveys in our community and receive empirioKarma points as a reward. You can then use these to activate survey results in the empirio "Statistics" area. You can then find the unlocked statistics here.',
        "statistic_subheading2" : "Did you register after participating?",
        "statistic_subtext2": 'If you have just taken part in a survey and are interested in the results, we will simply notify you by email as soon as they have been published.',
        "voucher_heading": 'Redeem points and receive a voucher',
        "voucher_subtext1": 'Together with our partner',
        "voucher_subtext2": "<p>we can offer you an exclusive voucher code for printing and binding your thesis.</p><p>For only <b>5 empirioKarma points</b> you get <b>10% discount</b> with your next order on BachelorPrint.</p>",
        "voucher_subtext3": 'Your personal one <b>Voucher code is now ready</b>. Use it when printing and binding your thesis on BachelorPrint and <b>save 10%</b> with your next order.',
        "voucher_point1" : "24h express shipping for FREE",
        "voucher_point2" : "3D preview of your binding",
        "voucher_point3" : "Look into the book function",
        "voucher_link_text": "Bachelor Print",
        "voucher_button1": 'Unlock voucher',
        "voucher_button2": 'Now to bachelor print',
        "voucher_profile_button": 'Redeem points',
        "plus_membership" : "Plus Mitgliedschaft",
        "plus_membership_active_msg": "Your Plus membership expires on",
        "plus_membership_active_msg2": "",
        "plus_membership_active_link_text": "Jetzt hier verlängern",
        "new_patner_voucher_heading" : "Create a Premium CV now for free?",
        "new_patner_voucher_text" : "Create your CV simply and easily with our partner Lebenslauf.de and secure the premium package worth over €15 free of charge for 30 days. Simply follow the link below and then register using the icon at the top right of the page - you will then automatically receive the premium package.",
        "click_here": "Click here",
        "patner_voucher_subtext": "<p>Create your CV simply and easily with our partner Lebenslauf.de and get the premium package for <b>30 days free of charge.</b></p>",
        "patner_voucher_subtext2": "Simply follow the link below and register using the icon at the top right of the page - you will then automatically receive the premium package.",
        "patner_voucher_button": "Create a Curriculum Vitae",
        "for_free_trial": "14 days free trial"
    },
    DE : {
        "hey" : "Hey",
        "welcome_message" : "willkommen im eigenen Bereich",
        "empirio_karma_points_l1" : "empirioKarma",
        "empirio_karma_points_l2" : "Punkte",
        "level_l1" : "Aktuelles",
        "level_l2" : "Level",
        "ponts_to_next_level_l1" : "Bis zum",
        "ponts_to_next_level_l2" : "nächsten Level",
        "next_level_bonus_l1" : "Nächster",
        "next_level_bonus_l2" : "Level-Bonus",
        "popup_ok_button_text" : "Ok",
        "referral_button" : "Freunde werben",
        "referral_popup_subtext_1" : 'Gefällt dir unsere Initiative zur Unterstützung der akademischen Forschung?',
        "referral_popup_subtext_2" : '<p>Dann lade deine Freunde zu empirio ein und erhalte einen "Karma-Multiplikator" für unsere Community. </p><p class="mt-4"><b>Mit jeder erfolgreichen Registrierung</b> über deinen persönlichen Link erhältst du für jeweils 10 Teilnahmen die <b>dreifache Menge an empirioKarma Punkten</b>.</p>',
        "referral_friends_registered" : "Freunde geworben",
        "referral_karma_multiplier" : 'Teilnahmen mit 3x Karma-Multiplikator verbleibend',
        "statistic_heading" : 'Noch keine Statistiken freigeschaltet',
        "statistic_subheading1" : "Was wird hier angezeigt?",
        "statistic_subtext1" : 'Hilf anderen Studierenden bei ihrer empirischen Forschung, indem du in unserer Community an Umfragen teilnimmst und erhalte als Belohnung empirioKarma Punkte. Diese kannst du anschließend dazu verwenden, Umfrage-Ergebnisse im empirio "Statistiken" Bereich freizuschalten. Die freigeschalteten Statistiken findest du anschließend hier.',
        "statistic_subheading2" : "Du hast dich nach einer Teilnahme registriert?",
        "statistic_subtext2": 'Wenn du soeben an einer Umfrage teilgenommen hast und dich für die Ergebnisse interessierst, benachrichtigen wir dich einfach per Mail, sobald diese veröffentlicht wurden.',
        "voucher_heading": 'Punkte einlösen und Gutschein erhalten',
        "voucher_subtext1": "Zusammen mit unserem Partner",
        "voucher_subtext2": "<p>können wir dir einen exklusiven Gutscheincode für das Drucken und Binden deiner Abschlussarbeit anbieten.</p><p>Für nur <b>5 empirioKarma Punkte</b> erhältst du <b>10% Rabatt</b> bei deiner nächsten Bestellung auf BachelorPrint.</p>",
        "voucher_subtext3": "Dein persönlicher <b>Gutscheincode liegt nun bereit</b>. Verwende ihn beim Drucken und Binden deiner Abschlussarbeit auf BachelorPrint und <b>spare 10%</b> bei deiner nächsten Bestellung.",
        "voucher_point_1" : "24h Express-Versand GRATIS",
        "voucher_point_2" : "3D-Vorschau deiner Bindung",
        "voucher_point_3" : "Blick-ins-Buch Funktion",
        "voucher_link_text": "BachelorPrint",
        "voucher_button1": 'Gutschein Freischalten',
        "voucher_button2": 'Jetzt Zu Bachelorprint',
        "voucher_profile_button": "Punkte einlösen",
        "plus_membership" : "Plus Mitgliedschaft",
        "plus_membership_active_msg": "Deine Plus Mitgliedschaft läuft am",
        "plus_membership_active_msg2": "ab.",
        "plus_membership_active_link_text": "Jetzt hier verlängern",
        "new_patner_voucher_heading" : "Jetzt kostenlos einen Premium-Lebenslauf erstellen?",
        "new_patner_voucher_text" : "Erstelle einfach und unkompliziert deinen Lebenslauf bei unserem Partner Lebenslauf.de und sichere dir jetzt 30 Tage lang kostenfrei das Premium-Paket im Wert von über 15€. Dafür folge einfach folgendem Link und registriere dich dann über das Icon rechts oben auf der Seite - du erhältst dann automatisch das Premium-Paket.",
        "click_here": "Hier klicken",
        "patner_voucher_subtext": "<p>Erstelle einfach und unkompliziert deinen Lebenslauf bei unserem Partner Lebenslauf.de und sichere dir jetzt <b>30 Tage kostenfrei das Premium-Paket.</b></p>",
        "patner_voucher_subtext2": "Dafür folge einfach folgendem Link und registriere dich über das Icon rechts oben auf der Seite - du erhältst dann automatisch das Premium-Paket.",
        "patner_voucher_button": "Lebenslauf erstellen",
        "for_free_trial": "14 Tage kostenlos testen"
    }
}
/** home page contant */
export const HOMEPAGE_CONSTANT = {
    EN : {
        "data_not_found" : "Data not found"
    },
    DE : {
        "data_not_found" : "Daten nicht gefunden"
    }
}
/** registration form constant */
export const REGISTRATION_CONSTANT = {
    EN : {
        "registartion_form_title":"To register",
        "first_name" : "First name",
        "last_name" : "Surname",
        "email_address" : "E-mail address",
        "password" : "password",
        "newsletter_checkbox_content_1" : "I would like to receive the newsletter from empirio",
        "newsletter_checkbox_content_2" : "(revocable at any time in the settings)",
        "do_you_have_account" : "You have an account?",
        "login_link" : "Log In",
        "registartion_button": "To register",
        "additional_information_2": "Create free academic survey",
        "additional_information_3": "Optional: Find participants in the community",
        "additional_information_4": "Access to all empirio statistics",
        "user_type" : "Account type",
        "additional_information_nsu1": "Create free online survey",
        "additional_information_nsu2": "Unlimited questions & entries",
        "additional_information_nsu3": "Optional: Own logo, colors and more",
    },
    DE : {
        "registartion_form_title" : "Registrieren",
        "first_name" : "Vorname",
        "last_name" : "Nachname",
        "email_address" : "E-Mail Adresse",
        "password" : "Passwort",
        "newsletter_checkbox_content_1" : "Ich möchte den Newsletter von empirio erhalten",
        "newsletter_checkbox_content_2" : "(jederzeit in den Einstellungen widerrufbar)",
        "do_you_have_account" : "Du hast einen Account?",
        "login_link" : "Anmelden",
        "registartion_button": "Registrieren",
        "additional_information_2": "Kostenlos akademische Umfrage erstellen",
        "additional_information_3": "Optional: Teilnehmer in der Community finden",
        "additional_information_4": "Zugang zu allen empirio Statistiken",
        "user_type" : "Konto Typ",
        "additional_information_nsu1": "Kostenlos Online-Umfrage erstellen",
        "additional_information_nsu2": "Unbegrenzte Anzahl an Fragen & Teilnahmen",
        "additional_information_nsu3": "Optional: Eigenes Logo, Farben und mehr",
    }
}
/**reset-password form constant */
export const RESETPASSWORD_CONSTANT = {
    EN : {
        "reset_password_form_title" : "reset Password",
        "new_password" : "New Password",
        "confirm_password" : "Confirm Password",
        "reset_password_button" : "Reset Password" 
    },
    DE : {
        "reset_password_form_title" : "Passwort zurücksetzen",
        "new_password" : "Neues Passwort",
        "confirm_password" : "Passwort bestätigen",
        "reset_password_button" : "Passwort zurücksetzen"
    }
}
/* --- Table Column header for survey Analysis --- */
export const SURVEY_ANALYSIS = {
    EN : {
        "table_column_member_name" : "Member",
        "table_column_date" : "Date",
        "table_column_time" : "Time",
        "table_column_duration" : "Duration",
        "table_column_action" : "Action",
        "no_participant_message" : "No Participants Yet",
        "delete_message" : "Do you really want to delete the participant?",
        "member" : "Member",
        "member_from_empirio" : "Of it from empirio",
        "views" : "Views",
        "duration" : "Avg. Duration",
        "dropout_rate" : "dropout rate",
        "download_result" : "Export of results",
        "png" : "Diagrams as picture",
        "excel" : "Raw data as Excel",
        "premium_excel":"Finished evaluation as Excel",
        "csv" : "Raw data as CSV",
        "powerpoint" : "Diagrams as Powerpoint",
        "word_diagramme" : "Diagrams as Word",
        "word_fragbook" : "Questionnaire as Word",
        "detail_of_participants" : "Detail view of the participants",
        "reset" : "Reset",
        "reset_heading" : "Confirm reset",
        "reset_title" : "Are you sure you want to reset the views to 0?",
        "reset_left_button" : "Abort",
        "reset_right_button" : "Confirm",
        "premium_export_text":"finished evaluations for the presentation",
        "premium_export_text_sub_heading":"Deutsches Ipsum Dolor id latine HugoClub Mate complectitur pri, mea meliore denique Audi id. Elitr expetenda nam an, Vorsprung durch Technik ei reque euismod assentior. Odio Kreuzberg iracundia ex pri. Ut vel Anwendungsprogrammierschnittstelle mandamus, quas natum adversarium ei Knappwurst diam "
    },
    DE : {
        "table_column_member_name" : "Teilnehmer",
        "table_column_date" : "Datum",
        "table_column_time" : "Uhrzeit",
        "table_column_duration" : "Dauer",
        "table_column_action" : "Aktion",
        "no_participant_message" : "Noch keine Teilnehmer",
        "delete_message" : "Möchtest du den Teilnehmer wirklich löschen?",
        "member" : "Teilnehmer",
        "member_from_empirio" : "Davon von empirio",
        "views" : "Aufrufe",
        "duration" : "Durchschn. Dauer",
        "dropout_rate" : "Abbruchquote",
        "download_result" : "Export der Ergebnisse",
        "png" : "Diagramme als Bild",
        "excel" : "Rohdaten als Excel",
        "premium_excel":"Fertige Auswertung als Excel",
        "csv" : "Rohdaten als CSV",
        "powerpoint" : "Diagramme als Powerpoint",
        "word_diagramme" : "Diagramme als Word",
        "word_fragbook" : "Fragebogen als Word",
        "detail_of_participants" : "Detailansicht der Teilnehmer",
        "reset" : "Reset",
        "reset_heading" : "Reset bestätigen",
        "reset_title" : "Möchtest du die Aufrufe wirklich auf 0 zurücksetzen?",
        "reset_left_button" : "ABBRECHEN",
        "reset_right_button" : "Bestätigen",
        "premium_export_text":"fertige auswertungen zur prasentation",
        "premium_export_text_sub_heading":"Deutsches Ipsum Dolor id latine HugoClub Mate complectitur pri, mea meliore denique Audi id. Elitr expetenda nam an, Vorsprung durch Technik ei reque euismod assentior. Odio Kreuzberg iracundia ex pri. Ut vel Anwendungsprogrammierschnittstelle mandamus, quas natum adversarium ei Knappwurst diam"
    }
}
/** --- For default meta keywords ---  */
export const META_TAGS_CONSTANT = {
    EN : {
        "meta_keywords" : "empirio, create survey, survey software, find participants, create own survey, student community for online surveys, create surveys, survey tool, survey software",
        "meta_description" : "One community - many possibilities: create survey. Find participants. Export results. And all that easy, fast and free."
    },
    DE : {
        "meta_keywords" : "empirio, Umfrage erstellen, Umfrage Software, Teilnehmer finden, Eigene Umfrage erstellen, Studierenden-Community für Online-Umfragen, Umfragen erstellen, Umfrage-Tool, Umfrage-Software",
        "meta_description" : "Eine Community - viele Möglichkeiten: Umfrage erstellen. Teilnehmer finden. Ergebnisse exportieren. Und das alles einfach, schnell und kostenlos."
    }
}
/** --- For default meta title --- */
export const META_TITLE_CONSTANT = {
    EN : {
        "mein-bereich" : "My Space",
        "login" : "Login",
        "registration" : "Registartion",
        "forgotpassword" : "Forgot Password",
        "resetpassword" : "Reset Password",
        "pagenotfound" : "Woops",
        "findparticipation" : "My survey",
        "surveycreation" : "Create a poll",
        "empiriowissen" : "Empirical research simply explained",
        "publishResult": "Statistics from scientific surveys",
        "surveylisting" : "Support student surveys"
    },
    DE : {
        "mein-bereich" : "Mein Bereich",
        "login" : "Anmelden",
        "registration" : "Registrieren",
        "forgotpassword" : "Passwort vergessen",
        "resetpassword" : "Passwort zurücksetzen",
        "pagenotfound" : "Hoppala",
        "findparticipation" : "Meine Umfrage",
        "surveycreation" : "Umfrage erstellen",
        "empiriowissen": "Empirische Forschung einfach erklärt",
        "publishResult" : "Statistiken von wissenschaftlichen Umfragen",
        "surveylisting" : "Unterstütze Umfragen von Studierenden"
    }
}
/** --- For url constant --- */
export const URL_CONSTANT = {
        "surveycreation" : "umfrage-erstellen",
        "findparticipation" : "meine-umfrage",
        "empiriowissen" : "empiriowissen",
        "forgotpassword" : "passwort-vergessen",
        "resetpassword" : "passwort-zuruecksetzen",
        "start":"start",
        "preview":"vorschau",
        "survey_list": "umfragen",
        "survey_result": "statistiken",
        "link_tree" : 'linktree'
}
export const META_FACEBOOK = {
        "og_title" : "Empirio",
        "og_description" : "Empirio",
        "og_url" : "https://www.empirio.de",
        "og_image" : IMAGE_BASEURL.concat("dist/og_img/empirio_startseite_1.png")
}
export const META_SHARE_FACEBOOK = {
        "og_description" : "Siehe dir diese und viele weitere spannende Statistiken auf empirio an."
}
export const AllUrlConstant = {
    all : ['nsu','survey','anmelden','unsubscribe','unsubscribenewsletter','empiriowissen','vorlagen','registrieren', 'referral', URL_CONSTANT['forgotpassword'],URL_CONSTANT['resetpassword'],'mein-bereich','faq','ueber-Uns','ueber-uns','funktionen','impressum','agb','avv','datenschutz',URL_CONSTANT['empiriowissen'],URL_CONSTANT['vorlagen'],URL_CONSTANT['findparticipation'],URL_CONSTANT['surveycreation'],'404','hilfe-center','empirio','lexikon',URL_CONSTANT['survey_result'], URL_CONSTANT['link_tree']]
}
export const BUBBLE_LOADING = <div id="loading"><div className="load-circle">{//eslint-disable-next-line
}<img src={IMAGE_BASEURL + "img/empirio_loading.svg"}></img></div></div>
export const OG_PARTICIPATION = {
    EN : {
        'help' : "Help",
        'male_description' : "with his empirical research by answering this survey.",
        "female_description" : "in your empirical research by answering this survey.",
        "common_description" : ", by answering this survey."
    },
    DE : {
        'help' : "Hilf",
        'male_description' : "bei seiner empirischen Forschung, indem du diese Umfrage beantwortest.",
        "female_description" : "bei ihrer empirischen Forschung, indem du diese Umfrage beantwortest.",
        "common_description" : ", indem du diese Umfrage beantwortest."
    }
}
export const META_OG_DESCRIPTION = {
    EN : {
        'surveylist' : "Supported research projects of students in the empirioKarma ranking"
    },
    DE : {
        'surveylist' : "Unterstütze Forschungsprojekte von Studierenden im empirioKarma-Ranking"
    }
}
export const BREADCRUMB_SCHEMA = {
    "schema1":{
        "@type": "ListItem",
        "position": 1,
        "item":
        {
         "@id": "https://www.empirio.de/",
         "name": "empirio - Kostenlose Umfragen für Studierende"
        }
       },
        "main":{
            "schema2":{
            },
            "schema3":{
            }
        },
        "umfragen" : {
            "schema2":{
                "position":2,
                "url":"https://www.empirio.de/umfragen",
                "name":"Umfragen"
            },
            "schema3":{
            }
        },
        "agb" : {
            "schema2":{
                "position":2,
                "url":"https://www.empirio.de/agb",
                "name":"AGB"
            },
            "schema3":{
            }
        },
        "impressum" : {
            "schema2":{
                "position":2,
                "url":"https://www.empirio.de/impressum",
                "name":"Impressum"
            },
            "schema3":{
            }
        },
        "datenschutz" : {
            "schema2":{
                "position":2,
                "url":"https://www.empirio.de/datenschutz",
                "name":"Datenschutz"
            },
            "schema3":{
            }
        },
        "login" : {
            "schema2":{
                "position":2,
                "url":"https://www.empirio.de/anmelden",
                "name":"Anmelden"
            },
            "schema3":{
            }
        },
        "registration" : {
            "schema2":{
                "position":2,
                "url":"https://www.empirio.de/registrieren",
                "name":"Registrieren"
            },
            "schema3":{
            }
        },
        "forgotpassword" : {
            "schema2":{
                "position":2,
                "url":"https://www.empirio.de/passwort-vergessen",
                "name":"Passwort vergessen"
            },
            "schema3":{
            }
        },
        "function" : {
            "schema2":{
                "position":2,
                "url":"https://www.empirio.de/funktionen",
                "name":"Funktionen"
            },
            "schema3":{
            }
        },
        "aboutus" : {
            "schema2":{
                "position":2,
                "url":"https://www.empirio.de/ueber-Uns",
                "name":"Über uns"
            },
            "schema3":{
            }
        },
        "faq" : {
            "schema2":{
                "position":2,
                "url":"https://www.empirio.de/faq",
                "name":"FAQ"
            },
            "schema3":{
            }
        },
        "lexikon" : {
            "schema2":{
                "position":2,
                "url":"https://www.empirio.de/lexikon",
                "name":"Lexikon"
            },
            "schema3":{
            }
        },
        "empiriowissen" : {
            "schema2":{
                "position":2,
                "url":"https://www.empirio.de/empiriowissen",
                "name":"empirioWissen"
            },
            "schema3":{
            }
        },
        "empiriowissenChapter" : {
            "schema2":{
                "position":2,
                "url":"https://www.empirio.de/empiriowissen",
                "name":"empirioWissen"
            },
            "schema3":{
                "position":3,
                "url":"https://www.empirio.de/empiriowissen/",
                "name":"empirioWissen"
            }
        }
}
/** --- For participation last page karmapoint --- */
export const KARMA_POINT = {
    EN : {
        'earnkarmapoint' : "empirioKarma Point"
    },
    DE : {
        'earnkarmapoint' : "empirioKarma Punkte"
    }
}

/* --- Export chart title style options ---- */

/* --- Note: Currently we have default fontName and colour if enabling from here also include it at the implemented places as well --- */
export const chartOptionTextStyle = {
    titleTextStyle:{
        fontSize: 22, //don't specify px
        bold: true,   // true or false
        italic: false,  // true of false
        fontName: 'Poppins',
        color: "#4a4a4a" //valid color-name (red,blue etc),valid rgb,valid hashcode
    }
}

export const chartAnimation = {
    animation:{
        duration: 2500,
        easing: 'out',
        startup: true
      }
}
export const MULTIPLE_SELECTION_QUESTION_CHART_SUBTITLE = {
    EN : {
        'subtitle' : "Multiple choices were possible for this question."
    },
    DE : {
        'subtitle' : "Bei dieser Frage war eine Mehrfach-Auswahl möglich."
    }
}
export const FREETEXT_TAG_COULD = {
    EN : {
        'subtitle' : "You can find all the answers to this question above under \"Export the Results\" under \"Raw data as Excel\"."
    },
    DE : {
        'subtitle' : "Alle Antworten auf diese Frage findest du oben beim \"Export der Ergebnisse\" unter \"Rohdaten als Excel\".",
    }
}
/** constant for EditSurveyResult component */
export const EDIT_SURVEY_RESULT_CONSTANT = {
    EN : {
        "publish_result_message_text" : "Hello",
        "publish_result_message_subtext1" : "As you may have already discovered, you have the option of publishing the results of your survey on empirio later. In this case the results of your questions will be presented in our “Statistics” section.",
        "publish_result_message_subtext2" : "In order to guarantee the highest possible quality of the results, each survey needs at least 100 participants for publication.",
        "publish_result_message_participants" : "Your current number of participants:",
        "publish_result_message_question" : "Why should you publish your results?",
        "publish_result_message_content" : "<h3><ul class='px-3 px-lg-5'><li class='font-14 lh-24 fw-400'>Instant credit of 100 karma points</li><li class='font-14 lh-24 fw-400'>10 karma points per month in the statistics area</li><li class='font-14 lh-24 fw-400'>Automatically make the results available to the participants of your survey and thus motivate other participants</li><li class='font-14 lh-24 fw-400'>Your online reputation - your name will be mentioned in connection with the results</li><li class='font-14 lh-24 fw-400'>The results of your bachelor thesis will be made available to many people instead of disappearing into the “moth box”</li><li class='font-14 lh-24 fw-400'>Full control: Decide for yourself which questions are published</li></ul></h3>",
        "publish_result_section1_content" : "<h3><ul class='px-3 px-lg-4 checkmark'><li class='font-14 lh-24 fw-400 tie-check'>Instant credit of 100 karma points</li><li class='font-14 lh-24 fw-400 tie-check'>10 karma points per month in the statistics area</li><li class='font-14 lh-24 fw-400 tie-check'>Full control: Decide for yourself which questions are published</li></ul></h3>",
        "publish_result_heading_section3" : "Presentation of the survey",
        "publish_result_heading_section4" : "Facts and odds",
        "publish_result_heading_section4_subtext" : "Here you can provide further information about your survey. These include e.g. Data such as the survey period, the research method used, etc. We have already filled in a few data that you can simply take over.",
        "publish_result_button" : "Publish Results",
        "publish_update_result_button" : "End publication",
        "type_of_scientific_work_lable" : "Type of scientific work",
        "seminar_paper" : "Seminar paper",
        "bachelor_thesis" : "Bachelor thesis",
        "master_thesis" : "Master thesis",
        "dissertation" : "Dissertation",
        "house_work" : "House work",
        "project_work" : 'Project work',
        "other_option" : "other",
        "demograpghic_attribute" : "Demographic characteristic",
        "question_type" : 'question',
        "survey_period" : "Survey period",
        "region_surveyed" : "Region surveyed",
        "age_group" : "Age group",
        "heading_result_publish" : "Congratulations, your survey is published!",
        "subheading_result_publish" : "For each additional month you will receive 10 karma points with which you can unlock the results of other surveys.",
        "subheading_result_publish2" : "you get to your published results.",
        "calls" : "Calls",
        "since_published" : "Published since",
        "karma_point_received" : "Karma points received",
        "note" : "Optional: Note",
        "years" : 'Years',
        "publish_question" : "Publish?",
        "publish_result_question_yes_button":"Yes",
        "publish_result_question_no_button" : "No",
        "send_survey_link_heading": "The link to your survey results",
        "send_survey_link_subtitle": "Send the link of your survey results to friends and post it on social networks to share your exciting findings with others.",
        "default_text_external_link": "Here is the link to the results of my survey",
        "survey_participants_weiting_heading": "Your survey participants are waiting for the publication!",
        "survey_participants_weiting_subtext1": "It’s already waiting",
        "survey_participants_weiting_subtext2": "Participants on the publication of your survey results! If you publish your results on empirio, all interested parties will be automatically notified that they can now view your exciting findings.",
    },
    DE : {
        "publish_result_message_text" : "Hallo",
        "publish_result_message_subtext1" : "wie du vielleicht schon entdeckt hast, hast du später die Möglichkeit die Ergebnisse deiner Umfrage auf empirio zu veröffentlichen. In diesem Fall werden die Ergebnisse deiner Fragen in unserem “Statistiken” Bereich präsentiert.",
        "publish_result_message_subtext2" : "Um eine möglichst hohe Qualität der Ergebnisse zu garantieren, braucht jede Umfrage für eine Veröffentlichung mindestens 100 Teilnehmer.",
        "publish_result_message_participants" : "Deine aktuelle Teilnehmerzahl:",
        "publish_result_message_question" : "Warum solltest du deine Ergebnisse veröffentlichen?",
        "publish_result_message_content" : "<h3><ul class='px-3 px-lg-5'><li class='font-14 lh-24 fw-400'>Sofort-Gutschrift von 100 Karmapunkten</li><li class='font-14 lh-24 fw-400'>10 Karmapunkte pro Monat im Statistiken-Bereich</li><li class='font-14 lh-24 fw-400'>Stelle automatisch den Teilnehmern deiner Umfrage die Ergebnisse zur Verfügung und motiviere damit weitere Teilnehmer</li><li class='font-14 lh-24 fw-400'>Deine Reputation im Netz - dein Name wird im Zusammenhang mit den Ergebnissen erwähnt</li><li class='font-14 lh-24 fw-400'>Die Ergebnisse deiner Bachelorarbeit werden vielen Leuten zugänglich gemacht, anstatt in der “Mottenkiste” zu verschwinden</li><li class='font-14 lh-24 fw-400'>Volle Kontrolle: Entscheide selber welche Fragen veröffentlicht werden</li></ul></h3>",
        "publish_result_section1_content" : "<h3><ul class='px-3 px-lg-4 checkmark'><li class='font-14 lh-24 fw-400 tie-check'>Sofort-Gutschrift von 100 Karmapunkten</li><li class='font-14 lh-24 fw-400 tie-check'>10 Karmapunkte pro Monat im Statistiken-Bereich</li><li class='font-14 lh-24 fw-400 tie-check'>Volle Kontrolle: Entscheide selber welche Fragen veröffentlicht werden</li></ul></h3>",
        "publish_result_heading_section3" : "Vorstellung der Umfrage",
        "publish_result_heading_section4" : "Fakten und Quoten",
        "publish_result_heading_section4_subtext" : "Hier kannst du weitere Informationen rund um deine Befragung angeben. Dazu gehören z.B. Daten wie der Erhebungszeitraum, die verwendete Forschungsmethode etc. Wir haben schon einmal ein paar Daten vorausgefüllt, die du einfach übernehmen kannst.",
        "publish_result_button" : "Ergebnisse veröffentlichen",
        "publish_update_result_button" : "Veröffentlichung beenden",
        "type_of_scientific_work_lable" : "Art der wissenschaftlichen Arbeit",
        "seminar_paper" : "Seminararbeit",
        "bachelor_thesis" : "Bachelorarbeit",
        "master_thesis" : "Masterarbeit",
        "dissertation" : "Dissertation",
        "house_work" : "Hausarbeit",
        "project_work" : 'Projektarbeit',
        "other_option" : 'Sonstiges',
        "demograpghic_attribute" : "Demografisches Merkmal",
        "question_type" : 'Frage',
        "survey_period" : "Erhebungszeitraum",
        "region_surveyed" : "Befragte Region",
        "age_group" : "Altersgruppe",
        "heading_result_publish" : "Glückwunsch, deine Umfrage ist veröffentlicht!",
        "subheading_result_publish" : "Für jeden weiteren Monat erhältst du 10 Karma Punkte, mit denen du Ergebnisse anderer Umfragen freischalten kannst.",
        "subheading_result_publish2" : "gelangst du zu deinen veröffentlichten Ergebnissen.",
        "calls" : "Aufrufe",
        "since_published"  : "Veröffentlicht seit",
        "karma_point_received" : "Erhaltene Karma Punkte",
        "note" : "Optional: Note",
        "years" : 'Jahre',
        "publish_question" : "Veröffentlichen?",
        "publish_result_question_yes_button":"Ja",
        "publish_result_question_no_button" : "Nein",
        "send_survey_link_heading": "Der Link zu deinen Umfrage-Ergebnissen",
        "send_survey_link_subtitle": "Verschicke den Link deiner Umfrage-Ergebnisse an Freunde und poste ihn in sozialen Netzwerken, um deine spannenden Erkenntnisse mit anderen zu teilen.",
        "default_text_external_link": "Hier ist der Link zu den Ergebnissen meiner Umfrage",
        "survey_participants_weiting_heading": "Deine Umfrage-Teilnehmer warten auf die Veröffentlichung!",
        "survey_participants_weiting_subtext1": "Es warten nun schon",
        "survey_participants_weiting_subtext2": "Teilnehmer auf die Veröffentlichung deiner Umfrage-Ergebnisse! Wenn du deine Ergebnisse auf empirio veröffentlichst, werden alle Interessenten automatisch darüber benachrichtigt, dass sie sich nun deine spannenden Erkenntnisse ansehen können.",
    }
}
//constant for publish result error.
export const EDIT_SURVEY_RESULT_ERROR_CONSTANT = {
    EN : {
        "error_message" : "Please select at least 5 questions - excluding demographic characteristics - for publication",
        "error_scientific_work"  : "Please select the type of your scientific work",
        "error_note_message" : "Please enter a valid grade (example: 2.3)",
        "error_facts_odds" : "Please provide some data and facts about your survey",
        "error_present_survey" : "Please present your survey",
    },
    DE : {
        "error_message" : "Bitte wähle mindestens 5 Fragen - ausgenommen demographischer Merkmale - zur Veröffentlichung aus",
        "error_scientific_work"  : "Bitte wähle die Art deiner wissenschaftlichen Arbeit aus",
        "error_note_message" : "Bitte eine gültige Note eingeben (Beispiel: 2,3)",
        "error_facts_odds" : "Bitte gib ein paar Daten und Fakten zu deiner Umfrage an",
        "error_present_survey" : "Bitte stelle deine Umfrage vor",
    }
}
//constant for Allowing user to publish result greater then "count" of participants
export const NO_OF_PARTICIPANTS_ALLOW = { "count": 3 }
// Voucher Code in profile page user unlock Voucher.
export const VOUCHER_CODE = {
    "voucher_code": 'hDm8P29a',
    "voucher_ExternalURL" : "https://www.bachelorprint.de/24h-online-shop/?sPartner=epo",
    "new_patner_voucher_URL" : "https://www.lebenslauf.de/?utm_source=dirPartner_empirio&utm_medium=affiliate&utm_campaign=karma2022&utm_content=&pid=empirio"
}
//constant for Allowing system login users participated to graterthen equalto "login_users" to message show
export const NO_OF_SYSTEM_LOGIN_USERS_ALLOW = {"login_users" : 3}
// Constant for survey publish result details
export const SURVEY_PUBLISH_RESULT_DETAIL_CONSTANT = {
    EN : {
        "question" : "Diagrams",
        "presentation_of_study" : "Presentation of the study",
        "survey_facts_odds" : "Survey facts and odds",
        "results" : "Results",
        "demographic_characteristic" : "Demographic characteristics",
        "from_subtitle" : "from",
        "question_published_title" : "Diagrams",
        "show_results" : "Unlock diagrams",
        "unlock_result_heading" : "Unlock diagrams",
        "unlock_result_popup_subtext" : "In order to be able to see statistics completely, you have to activate them with your empirioKarma points. You will get this if you",
        "unlock_result_popup_subtext1" : 'help other community members with your participation in their surveys. You can find a more detailed explanation on our "Functions" page.',
        "unlock_result_popup_subtext2": "In order to be able to see all the diagrams of this survey, you have to register with empirio.",
        "unlock_button" : "Unlock",
        "unlock_now" : "Unlock now",
        "register_button" : "Register for free",
        "share_survey_headig" : "Share diagram",
        "share_survey_subtitle" : "Share the diagram with your friends on social networks",
        "share_survey_heading2" : "Include diagram in website",
        "share_icon_text" : "Share",
        "require_karma_point" : "To be able to see all the diagrams for this survey, you need",
        "require_karma_point2" : "empirioKarma points",
        "more_statistics_text" : "You can find more statistics at",
        "error_unlock_survey" : "You do not have enough empirioKarma points to activate the voucher. Go to our community now, take part in surveys and earn the points you need (1 minute = 1 empirioKarma point).",
        "error_unlock_survey_result" : "Insufficient karma point",
        "share_survey_icon_title" : "Here are exciting survey results on the topic",
        "share_notlogged_user_first_subtext" : "Free access to all empirio statistics",
        "share_notlogged_user_second_subtext" : "Create your own academic survey",
        "share_notlogged_user_third_subtext" : "Support scientific research",
        "unlock_survey_message" : "Already activated",
    },
    DE : {
        "question" : "Diagramme",
        "presentation_of_study" : "Vorstellung der Studie",
        "survey_facts_odds" : "Fakten und Quoten zur Umfrage",
        "results" : "Ergebnisse",
        "demographic_characteristic" : "Demographische Merkmale",
        "from_subtitle" : "von",
        "question_published_title" : "Diagramme",
        "show_results" : "Diagramme freischalten",
        "unlock_result_heading" : "Diagramme freischalten",
        "unlock_result_popup_subtext" : "Um Statistiken vollständig einsehen zu können, musst du diese mit deinen empirioKarma Punkten freischalten. Diese erhältst du, wenn du",
        "unlock_result_popup_subtext1" : 'anderen Community-Mitgliedern mit deiner Teilnahme an ihren Umfragen hilfst. Eine genauere Erklärung dazu findest du auf unserer "Funktionen" Seite.',
        "unlock_result_popup_subtext2": "Um alle Diagramme dieser Umfrage einsehen zu können, musst du dich bei empirio registrieren.",
        "unlock_button" : "Freischalten",
        "unlock_now" : "Jetzt freischalten",
        "register_button" : "Kostenlos registrieren",
        "share_survey_headig" : "Diagramm teilen",
        "share_survey_subtitle" : "Teile das Diagramm in sozialen Netzwerken mit deinen Freunden",
        "share_survey_heading2" : "Diagramm in Webseite einbinden",
        "share_icon_text" : "Teilen",
        "require_karma_point" : "Um alle Diagramme dieser Umfrage einsehen zu können, benötigst du",
        "require_karma_point2" : "empirioKarma Punkte",
        "more_statistics_text" : "Mehr Statistiken findest du bei",
        "error_unlock_survey" : "Du hast noch nicht genug empirioKarma Punkte, um den Gutschein freizuschalten. Gehe jetzt in unsere Community, nimm an Umfragen teil und verdiene dir die benötigten Punkte (1 Minute = 1 empirioKarma Punkt).",
        "error_unlock_survey_result" : "Unzureichender Karma Punkt",
        "share_survey_icon_title" : "Hier sind spannende Umfrage-Ergebnisse zum Thema",
        "share_notlogged_user_first_subtext" : "Kostenloser Zugang zu allen empirio Statistiken",
        "share_notlogged_user_second_subtext" : "Eigene akademische Umfrage erstellen",
        "share_notlogged_user_third_subtext" : "Wissenschaftliche Forschung unterstützen",
        "unlock_survey_message" : "Bereits freigeschaltet"
    }
}
// Constant for survey publish result status dropdown
export const SURVEY_PUBLISH_RESULT_STATUS_DROPDOWN = {
    EN: [{ "id": "true", "status_name": "Newest" }, { "id": "false", "status_name": "Oldest" }],
    DE: [{ "id": "true", "status_name": "Neuste" }, { "id": "false", "status_name": "Älteste" }]
}
export const LINK_TREE_CONSTANT = {
    EN: {
        "link_tree_home" : "That is empirio",
        "link_tree_umfragen" : "Participate in surveys",
        "link_tree_statisken" : "Discover survey results",
    },
    DE: {
        "link_tree_home" : "Das ist empirio",
        "link_tree_umfragen" : "An Umfragen teilnehmen",
        "link_tree_statisken" : "Umfrage-Ergebnisse entdecken",
    }
}
export const CHART_HEIGHT = 600
export const CHART_WIDTH = 1200

// Constat for popup alert in wission artiles.

export const ARTICLE_POPUP_CONSTANT = {
    EN: {
        "heading_text" : "Create survey for free",
        "sub_text" : "You have informed yourself well and now want to easily create an online survey?",
        "message_text" : "Register now for free with empirio so that you can get started right away.",
        "button_text" : "Create poll now",
        "point1_text" : "Unlimited questions & entries",
        "point2_text" : "More than 25 different question types",
        "point3_text" : "Numerous evaluation formats",
        "button_link" : "/registration"
    },
    DE: {
        "heading_text" : "Umfrage kostenlos erstellen",
        "sub_text" : "Du hast dich gut informiert und möchtest nun unkompliziert eine Online-Umfrage erstellen?",
        "message_text" : "Registriere dich jetzt kostenlos bei empirio, damit du direkt loslegen kannst.",
        "button_text" : "Jetzt Umfrage erstellen",
        "point1_text" : "Unbegrenzte Anzahl an Fragen & Teilnahmen",
        "point2_text" : "Mehr als 25 verschiedene Fragetypen",
        "point3_text" : "Zahlreiche Auswertungsformate",
        "button_link" : "/registrieren"
    }
}
export const PAYMENT_CONSTANTS = {
    EN: {
      creditcard_payment: "Creditcard Payment",
      sepa_payment: "SEPA payment",
      paypal_payment: "Paypal Payment",
      amount_payable: "Payable",
      nsu_heading: "empirio Plus",
      nsu_subheading: "The super boost. for your poll.",
      secure_payment: "Secure payment",
      ssl_encrypted: "SSL encrypted",
      compliant: "privacy",
      btn_text: "Secure benefits",
      payment_btn: "charge with cost",
      secure_booking: "Secure booking",
      nsu_name: "Company Name",
      phone: "Phone",
      street: "Street",
      house_number: "House Number",
      tax_id: "Tax ID",
      postal_code: "Postal Code",
      price_tag: "Unique",
      book_package_header:"Book empirio Plus now",
      book_package_text: "When you book the 90-day membership, you get ",
      book_package_text1 : "Tipp:",
      book_package_text2 : "one month free",
      invoice_address:'Invoice Address',
      payment_method:'Payment Method',
      paypal:'PayPal',
      credit_card:'Credit Card',
      pay_with_giropay:'Giropay',
      nsu_user_first_name : "First name",
      nsu_user_last_name: "Surname",
      Extend_user_package:"Extend for a fee",
      payment_suceess_msg : "payment successful",
      payment_error_msg : "There was an error processing the payment.",
      popup_main_heading : "Get the empirio Plus benefits now",
      popup_text1 : "Export Questionnaire",
      popup_text2 : "Finished evaluation",
      popup_text3 : "Customize charts",
      popup_text4 : "Personalized survey",
      popup_text5 : "Copy content",
      popup_description1 : "Download questions and answers as a Word file (e.g. for the attachment of your thesis)",
      popup_description2 : "Export first analysis of your results in Excel format",
      popup_description3 : "Export or edit diagrams as Word and Powerpoint",
      popup_description4 : "Define your own background image and individual final page",
      popup_description5 : "Copy individual questions or an entire survey",
      section1_heading : "That is empirio Plus. Your time-saving package.",
      section1_description1 : "With empirio Plus you make an empirio survey for",
      section1_description2: "survey. Upload your own cover photo and define an individual end page (e.g. for a thank you etc.). Above all, empirio Plus makes your life even easier. You get everything with just a few clicks , what else you need for the perfect thesis. Your questionnaire as a Word file to copy it to the appendix of your thesis. The results as diagrams directly in Word or Powerpoint format so that you can insert them in no time at all and edit them if necessary . to edit according to your wishes (e.g. colors, font, size, etc.). Or even a first Excel evaluation of your raw data, in which we summarize your results. In addition, you even have the option of simply using your survey as a basis for further surveys to copy.",
      section1_description3: "your",
      section2_heading : "full control No subscription.",
      section2_description : "If you want to use all the advantages of empirio Plus, you can book them once for 30 or 90 days. Membership is not automatically renewed. If you need empirio Plus for a longer period of time, you can flexibly extend your membership at any time.",
      package_days_30 : "30 days Membership",
      package_days_90 : "90 days Membership",
      enter_IBN_number : "The IBAN is incomplete",
      valid_INB_number : "Please enter valid IBN number.",
      giropay_payment : "Giropay payment",
      payment_suceess_msg_button : "Conclude",
      close_button : "Conclude",
      subscription_yearly : "Annual payment",
      organization : "Name der Organisation",
      sub_monthly : "Monthly payment",
      sub_price_card_text_monthly : "Per month",
      sub_price_card_text1 : "net plus 19% VAT",
      sub_price_card_text_yealy : "Yearly",
      trial_button: 'Test for free',
      trial_amount: 'Price after 14 days',
      tax_id_info: 'This feature operates upon selecting a postcode'
    },
    DE: {
      creditcard_payment: "Kreditkarten Zahlung",
      sepa_payment: "SEPA Zahlung",
      paypal_payment: "Paypal Zahlung",
      amount_payable: "Betrag",
      nsu_heading: "empirio Plus",
     // nsu_subheading: "Das Plus für deine Umfrage.",
      nsu_subheading: "Volle Kontrolle. Kein Abo.",
      secure_payment: "Sichere Zahlung",
      ssl_encrypted: "SSL-verschlüsselt",
      btn_text: "Vorteile sichern",
      compliant: "Datenschutz",
      payment_btn: "Kostenpflichtig buchen",
      secure_booking: "Sichere Buchung",
      nsu_name: "Name des Unternehmens",
      phone: "Telefonnummer",
      street: "Straße",
      house_number: "Nr.",
      tax_id: "Umsatzsteuer-ID",
      postal_code: "Postleitzahl",
      price_tag: "Einmalig",
      book_package_header:"Jetzt empirio Plus buchen",
      book_package_text: "Bei der Buchung der 90 Tage Mitgliedschaft erhältst du ",
      book_package_text1 : "Tipp:",
      book_package_text2 : "einen Monat gratis",
      invoice_address:'Rechnungsadresse',
      payment_method:'Zahlungsart',
      paypal:'PayPal',
      credit_card:'Kreditkarte',
      pay_with_giropay:'Giropay',
      nsu_user_first_name : "Vorname",
      nsu_user_last_name: "Nachname",
      Extend_user_package:"Kostenpflichtig verlängern",
      payment_suceess_msg : "Buchung erfolgreich",
      payment_error_msg : "Bei der Verarbeitung der Zahlung ist ein Fehler aufgetreten.",
      popup_main_heading : "Sichere dir jetzt die empirio Plus Vorteile",
      popup_text1 : "Fragebogen exportieren",
      popup_text2 : "Fertige Auswertung",
      popup_text3 : "Diagramme anpassen",
      popup_text4 : "Personalisierte Umfrage",
      popup_text5 : "Inhalte kopieren",
      popup_description1 : "Fragen und Antworten als Word-Datei herunterladen (z.B. für den Anhang deiner Abschlussarbeit)",
      popup_description2 : "Erste Analyse deiner Ergebnisse im Excel-Format exportieren",
      popup_description3 : "Diagramme als Word und Powerpoint exportieren bzw. bearbeiten",
      popup_description4 : "Eigenes Hintergrundbild und individuelle Abschlussseite definieren",
      popup_description5 : "Einzelne Fragen oder eine komplette Umfrage kopieren",
      section1_heading : "Das ist empirio Plus. Dein Zeitspar-Paket.",
      section1_description1 : "Mit empirio Plus machst du eine empirio Umfrage zu",
      section1_description2 : "Umfrage. Lade ein eigenes Titelbild hoch und definiere eine individuelle Abschlussseite (z.B. für eine Danksagung etc.). Vor allem aber macht dir empirio Plus dein Leben noch einfacher. Mit wenigen Klicks erhältst du alles, was du noch für die perfekte Abschlussarbeit benötigst. Deinen Fragebogen als Word-Datei, um ihn in den Anhang deiner Abschlussarbeit zu kopieren. Die Ergebnisse als Diagramme direkt im Word- oder Powerpoint-Format, um diese im Handumdrehen bei dir zu einzufügen und ggf. nach deinen Wünschen zu bearbeiten (z.B. Farben, Schriftart, Größe etc.). Oder auch eine erste Excel-Auswertung deiner Rohdaten, in welcher wir dir deine Ergebnisse zusammenfassen. Zusätzlich hast du für eine noch schnellere Erstellung deines Fragebogens sogar die Möglichkeit einzelne Fragen oder auch eine komplette Umfrage zu kopieren.",
      section1_description3 : "deiner" ,
      section2_heading : "Volle Kontrolle. Kein Abo.",
      section2_description : "Wenn du alle Vorteile von empirio Plus nutzen möchtest, kannst du diese einmalig für 30 bzw. 90 Tage buchen. Die Mitgliedschaft wird nicht automatisch verlängert. Solltest du empirio Plus doch für einen längeren Zeitraum benötigen, kannst du ganz flexibel jederzeit deine Mitgliedschaft verlängern.",
      package_days_30 : "30 Tage Mitgliedschaft",
      package_days_90 : "90 Tage Mitgliedschaft",
      enter_IBN_number : "Die IBAN ist unvollständig",
      valid_INB_number : "Bitte geben Sie eine gültige IBN-Nummer ein.",
      giropay_payment : "Giropay Zahlung",
      close_button : "Schließen",
      subscription_yearly : "Jährliche Zahlung",
      organization : "Name der Organisation",
      sub_monthly : "Monatliche Zahlung",
      sub_price_card_text_monthly : "Monatlich",
      sub_price_card_text1 : "zzgl. 19% USt.",
      sub_price_card_text_yealy : "Jährlich",
      trial_button: 'Kostenlos testen',
      trial_amount: 'Betrag nach 14 Tagen',
      tax_id_info: 'Diese Funktion wird bei Auswahl einer Postleitzahl aktiviert'
    },
  };
export const BILLING_ADDRESS_ERROR = {
    EN: {
        "first_name": "Please enter your first name",
        "last_name": "Please enter your last name",
        "street": "Please enter your street",
        "house_number": "Please enter your house number",
        "postal_code": "Please enter your postal code",
        "postal_code_match": "please enter valid postal code",
        "no_payment_option_selected": "Please enter a payment method",
        "no_time_period_selected" : "Please enter a period",
        "organization": "Please enter a organisation",
        "tax_id":"Please enter valid vat id"
    },
    DE: {
        "first_name": "Bitte gib deinen Vornamen ein",
        "last_name": "Bitte gib deinen Nachnamen ein",
        "street": "Bitte gib eine Straße ein",
        "house_number": "Bitte gib eine Hausnummer ein",
        "postal_code": "Bitte gib eine Postleitzahl ein",
        "postal_code_match": "Bitte gültige Postleitzahl eingeben",
        "no_payment_option_selected": "Bitte gib eine Zahlungsart an",
        "no_time_period_selected" : "Bitte gib einen Zeitraum an",
        "organization" : "Bitte gib einen Name der Organisation",
        "tax_id":"Bitte geben Sie eine gültige Umsatzsteuer-ID ein"
    }
}

export const CHECKOUT_SUBSCRIPTION = {
    EN: {
        "sub_heading":"Your brand in focus.",
        "card_heading":"Secure empirio Plus benefits now",
        "card_bullet_points1":"Ad-free",
        "card_bullet_points2":"Individual design",
        "card_bullet_points3":"Helpful evaluations",
        "card_bullet_points4":"Copy content",
        "card_bullet_points5":"Premium Support",
        "card_bullet_points_subheading1":"The empirio logo and advertising at the end of the survey will be removed",
        "card_bullet_points_subheading2":"Define your own logo, background image and color",
        "card_bullet_points_subheading3":"Export the results to additional formats at the touch of a button",
        "card_bullet_points_subheading4":"Copy individual questions or an entire survey to save time",
        "card_bullet_points_subheading5":"Priority support for questions and problems at hallo@empirio.de",
        "card_bullet_points_subheading6":"The empirio Plus subscription. Cancellable at any time",
        "section_heading":"Your brand in focus",
        "section_text":"Create your own individual survey experience with the empirio Plus subscription and present your brand to customers and employees in the familiar image.",
        "section_text2":"Simply upload your own logo and background image and choose your desired color. This strengthens the brand’s appearance with your target group and creates even more trust in your company.",
        "packages_heading":"Book the empirio Plus subscription now",
        "packages_subheading":"Tip: When you book an annual membership, you get two months free.",
        "testimonial_text": "With empirio we carried out a customer survey quickly and easily. From this we were able to derive valuable insights for the optimization of <span> <a href='https://www.reifendirekt.de'>Reifendirekt.de </a></span>.",
        "testimonial_sub_text": "dr Andrew examiner",
        "testimonial_sub_text1": "CEO, Reifendirekt.de",
        "testimonial_sub_text2": "Jannis Lindschau",
        "testimonial_sub_text3": "CEO, <a href='https://www.lebenslauf.de'>Lebenslauf.de </a>",
        "testimonial_text1": "I create employee and customer surveys in a targeted manner and without much effort. What particularly convinces me is that I can design the surveys with the Plus membership in our corporate design.",
        "testimonial_sub_text2": "Jannis Lindschau",
        "testimonial_heading": "Thats what our customers say",
        "university_heading" : "The university in focus.",
        "university_section1_heading" : "The university in focus.",
        "university_section1_text1" : "Create a completely individual survey experience with the empirio Plus subscription and present the university to participants in the familiar image.",
        "university_section1_text2" : "Simply upload your own logo and background image and choose a color of your choice. In this way you strengthen the appearance of the university brand among your target group.",
        "trial_heading": "14 days free test",
        "packages_heading_business":"The empirio Plus subscription. Cancellable at any time.",
    },
    DE: {
        "sub_heading":"Deine Marke im Mittelpunkt.",
        "card_heading":"Jetzt empirio Plus Vorteile sichern",
        "card_bullet_points1":"Werbefrei",
        "card_bullet_points2":"Individuelles Design",
        "card_bullet_points3":"Hilfreiche Auswertungen",
        "card_bullet_points4":"Inhalte kopieren",
        "card_bullet_points5":"Premium-Support",
        "card_bullet_points_subheading1":"Das empirio Logo sowie Werbung am Ende der Umfrage werden entfernt",
        "card_bullet_points_subheading2":"Eigenes Logo, Hintergrundbild und Farbe definieren",
        "card_bullet_points_subheading3":"Auf Knopfdruck die Ergebnisse in zusätzliche Formate exportieren",
        "card_bullet_points_subheading4":"Einzelne Fragen oder eine komplette Umfrage kopieren und so Zeit sparen",
        "card_bullet_points_subheading5":"Priorisierter Support bei Fragen und Problemen unter hallo@empirio.de",
        "card_bullet_points_subheading6":"Das empirio Plus Abo. Jederzeit kündbar",
        "section_heading":"Deine Marke im Mittelpunkt",
        "section_text":"Erstelle jetzt dein ganz individuelles Umfrage-Erlebnis im empirio Plus Abo und präsentiere deine Marke vor Kunden und Mitarbeitenden im gewohnten Bild.",
        "section_text2":"Lade dazu einfach ein eigenes Logo sowie Hintergrundbild hoch und wähle deine Wunsch-Farbe aus. Damit stärkst du den Auftritt der Marke bei deiner Zielgruppe und schaffst noch mehr Vertrauen in dein Unternehmen.",
        "packages_heading":"Jetzt das empirio Plus Abo buchen",
        "packages_heading_business":"Das empirio Plus Abo. Jederzeit kündbar.",
        "packages_subheading":"<b>Tipp:</b> Bei der Buchung der Jahres-Mitgliedschaft erhältst du <u>zwei Monate gratis</u>.",
        "testimonial_text": "Mit empirio haben wir schnell und unkompliziert eine Kundenbefragung durchgeführt. Aus dieser konnten wir wertvolle Erkenntnisse für die Optimierung von Reifendirekt.de ableiten.",
        "testimonial_sub_text": "Dr. Andreas Prüfer",
        "testimonial_sub_text1": "CEO, <a href='https://www.reifendirekt.de' target='_blank'>Reifendirekt.de </a>",
        "testimonial_text1": "Mitarbeiter- und Kundenumfragen erstelle ich zielgerichtet und ohne viel Aufwand. Besonders überzeugt mich, dass ich die Umfragen mit empirio Plus in unserem Corporate Design gestalten kann.",
        "testimonial_sub_text2": "Jannis Lindschau",
        "testimonial_sub_text3": "CEO, <a href='https://www.lebenslauf.de' target='_blank'>Lebenslauf.de </a>",
        "testimonial_heading": "Das sagen unsere Kunden",
        "university_heading" : "Die Hochschule im Mittelpunkt.",
        "university_section1_heading" : "Die Hochschule im Mittelpunkt",
        "university_section1_text1" : "Erstelle jetzt ein ganz individuelles Umfrage-Erlebnis im empirio Plus Abo und präsentiere die Hochschule vor Teilnehmenden im gewohnten Bild.",
        "university_section1_text2" : "Lade dazu einfach ein eigenes Logo sowie Hintergrundbild hoch und wähle eine Wunsch-Farbe aus. Damit stärkst du den Auftritt der Hochschule-Marke bei deiner Zielgruppe.",
        "trial_heading": "Jetzt 14 Tage kostenlos testen."
    }
}

export const AFFILIATION ={
    EN : {
        1: "Student",
        2: "University",
        3: "School",
        4: "Business",
        5: "Other"
    },
    DE : {
        1: "Studentin",
        2: "Universität",
        3: "Schule",
        4: "Geschäft",
        5: "Andere"
    }
}

export const TIME_PERIOD ={
    EN : {
        "30 Tage Mitgliedschaft" : "30 Days",
        "90 Tage Mitgliedschaft" : "90 Days",
        "Monatliche Zahlung" : "1 Month",
        "Jährliche Zahlung" : "1 Year",
    },
    DE : {
        "30 Tage Mitgliedschaft" : "30 Tage",
        "90 Tage Mitgliedschaft" : "90 Tage",
        "Monatliche Zahlung" : "1 Monat",
        "Jährliche Zahlung" : "1 Jahr",
    }
}