import React from "react"
import { SURVEY_PARTICIPATION } from "../../../../constants/FrontEndConstant";
import { LANGUAGE_CODE } from '../../../../constants/DotEnv';
import Button from '@material-ui/core/Button';
class SurveySingleSelectionQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.setOptionWidth()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setOptionWidth();
    }
    setOptionWidth = () => {
        if (window.innerWidth > 767 && this.props.questiondata['option'].some(option => option.option_text.length > 28)) {
            this.option_width = "col-12 pb-4 pb-sm-3 pb-md-3 pb-lg-0 full_width_option";
            this.freeText_width = "col-12";
        } else {
            this.option_width = "col-12 col-md-6 pb-4 pb-sm-3 pb-md-3 pb-lg-0 without_full_width";
            this.freeText_width = "col-12 col-md-6 without_full_width";
        }
    }

    labelOnClick = (e) => {
        if (e.currentTarget.name) {
            document.getElementById(e.currentTarget.name).click();
            document.getElementById(e.currentTarget.name).checked = !(document.getElementById(e.currentTarget.name).checked);
        }
    }

    /**this function is for prevent form data submit when press enter key */
    stopSubmitting = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }
    changeSelected = (id) => {
        if (id) {
            document.getElementById(id).checked = true;
        }
    }
    render() {
        var data = this.props.questiondata;
        var surveyDetail = JSON.parse(localStorage.getItem('surveydetail_' + data.survey_id))
        let englishbuttonlabels;
        englishbuttonlabels = surveyDetail.englishbuttonlabels == "1" ? "EN" : "DE"
        let optionlist = [];
        let freeTextOption = [];
        let freetextWithoutRow = false;
        let is_free = data.is_free_text;
        if (data.option !== undefined || data.option !== null) {
            let dataTotalLength = Object.keys(data['option']).length;
            let dataLength =
                (data.option[0]['option_image']) ?
                    Object.keys(data['option']).length
                    : (parseInt(is_free) === 1) ? dataTotalLength - 1 : dataTotalLength;
            var j = 0;
            let lengthCount;
            if (dataLength % 2 === 0) {
                lengthCount = (dataLength / 2) - 1;
            }
            else {
                lengthCount = (dataLength / 2);
                freetextWithoutRow = true;
            }
            let checkFreeText;
            if (document.getElementById(data.question_id)) {
                checkFreeText = document.getElementById(data.question_id).checked
            }
            if (is_free === 1) {
                if (freetextWithoutRow) {
                    freeTextOption.push(
                        <div className={this.freeText_width}>
                            <div className="input-group custom_text_field">
                                <div className="input-group-prepend w-auto"></div>
                                <div className="custom-radio-btn radio-align W-100">
                                    <div className="custom-control custom-radio custom-control-inline W-1000 w-100 free-text-shadow free-single-text-shadow-hover pl-0">
                                        <label htmlFor={data.question_id} className="btn-radio radio-survey custom-btn-radio box-shadow shadow-none w-auto mb-0 hover-none btn-radio-freeText">
                                            <input type="radio" id={data.question_id} name={data.question_id} className="custom-control-input" onClick={()=>this.props.dummyStateChange()} checked={(this.props.questionanswer['free_text_answer'] === undefined) ?
                                                this.props.questionanswer['single_selection_free'] !== undefined ? (this.props.questionanswer['single_selection_free'] !== "" && this.props.questionanswer['question_id'] === this.props.questiondata['question_id']) ? true : (checkFreeText) ? true : false : (checkFreeText) ? true : false
                                                : (this.props.questionanswer['free_text_answer']) ? true : (checkFreeText) ? true : false
                                            } />
                                            <svg width="18px" height="20px" viewBox="0 0 20 20">
                                                <circle cx="10" cy="10" r="9"></circle>
                                                <path style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }} d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                                <path style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }} d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                                            </svg>
                                        </label>
                                        <input type="text" className="form-control free-text-ans free-text-height pl-2" name="single_selection_free" onClick={() => {this.changeSelected(data.question_id);this.props.dummyStateChange()}} onKeyPress={this.stopSubmitting} id="yourcolour" onChange={this.props.onChange} placeholder={SURVEY_PARTICIPATION[englishbuttonlabels]['common_textfield_IPH']} value={this.props.questionanswer["single_selection_free"] !== undefined ? (this.props.questionanswer['single_selection_free']) : (this.props.questionanswer['free_text_answer'] !== undefined) ? (this.props.questionanswer['free_text_answer']) : ""} />
                                    </div>
                                </div>
                            </div>
                        </div>)
                }
                else {
                    freeTextOption.push(
                        <div className="row">
                            <div className={this.freeText_width}>
                                <div className="input-group custom_text_field">
                                    <div className="input-group-prepend w-auto"></div>
                                    <div className="custom-radio-btn radio-align W-100">
                                        <div className="custom-control custom-radio custom-control-inline W-1000 w-100 free-text-shadow free-single-text-shadow-hover pl-0">
                                            <label htmlFor={data.question_id} className="btn-radio radio-survey shadow-none w-auto mb-0 hover-none btn-radio-freeText">
                                                <input type="radio" id={data.question_id} name={data.question_id} className="custom-control-input" onClick={()=>this.props.dummyStateChange()} checked={(this.props.questionanswer['free_text_answer'] === undefined) ?
                                                    this.props.questionanswer['single_selection_free'] !== undefined ? (this.props.questionanswer['single_selection_free'] !== "" && this.props.questionanswer['question_id'] === this.props.questiondata['question_id']) ? true : (checkFreeText) ? true : false : (checkFreeText) ? true : false
                                                    : (this.props.questionanswer['free_text_answer']) ? true : (checkFreeText) ? true : false
                                                } />
                                                <svg width="18px" height="20px" viewBox="0 0 20 20">
                                                    <circle cx="10" cy="10" r="9"></circle>
                                                    <path style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }} d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                                    <path style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }} d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                                                </svg>
                                            </label>
                                            <input type="text" className="form-control free-text-ans free-text-height pl-2" name="single_selection_free" onClick={() => {this.changeSelected(data.question_id);this.props.dummyStateChange()}} onKeyPress={this.stopSubmitting} id="yourcolour" onChange={this.props.onChange} placeholder={SURVEY_PARTICIPATION[englishbuttonlabels]['common_textfield_IPH']} value={this.props.questionanswer["single_selection_free"] !== undefined ? (this.props.questionanswer['single_selection_free']) : (this.props.questionanswer['free_text_answer'] !== undefined) ? (this.props.questionanswer['free_text_answer']) : ""} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                }
            }
            for (var i = 0; i <= lengthCount; i++) {
                optionlist.push(
                    (data.option[i]['option_image']) ? (
                        <div className="row justify-content-center" key={j}>
                            {(j < dataTotalLength) ?
                                (data.option[j]['option_text'] !== "free_text_condition") ?
                                    <div className="col-md-5 pb-5 pb-sm-3">
                                        {/* this.labelOnClick(data.option[j]['id']) */}
                                        <label htmlFor={data.option[j]['id']} className="MuiButton-effect w-100 mb-0 pointer box-shadow btn-radio custom-btn-radio box-shadow p-0 radio-survey">
                                            <Button variant="contained" color="#00b3f4" name={data.option[j]['id']} onClick={(e)=>this.labelOnClick(e)}>
                                                <div className="fill-by-img" style={{ "backgroundImage": "url(" + data.option[j]['option_image'] + ")" }}>
                                                </div>
                                                <div className="custom-radio-btn radio-align-img  w-100 min-height-for-img py-3 px-4">
                                                    <input type="radio" id={data.option[j]['id']} name={data.question_id} className="custom-control-input singleselection"
                                                        value={data.option[j]['id']}
                                                        checked={(this.props.questionanswer.option === undefined) ?
                                                            (parseInt(this.props.questionanswer[data.question_id]) === data.option[j]['id']) ? true : false
                                                            : // eslint-disable-next-line
                                                            (this.props.questionanswer.option.findIndex(ans => ans.option_id === data.option[j]['id']) > -1) ? true : false
                                                        }
                                                        onClick = {this.props.onChange}
                                                        onChange={this.props.onChange} />
                                                    <svg width="20px" height="20px" viewBox="0 0 20 20">
                                                        <circle cx="10" cy="10" r="9"></circle>
                                                        <path style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }} d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                                        <path style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }} d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                                                    </svg>
                                                    <span className="ml-3 font-weight-normal">{data.option[j]['option_text']}</span>
                                                </div>
                                            </Button>
                                        </label>
                                    </div>
                                    : null
                                : null
                            }
                            {(parseInt(j + 1) < dataTotalLength) ?
                                (data.option[parseInt(j + 1)]['option_text'] !== "free_text_condition") ?
                                    <div className="col-md-5 pb-5 pb-sm-3">
                                        <label htmlFor={data.option[parseInt(j + 1)]['id']} className="MuiButton-effect w-100 mb-0 pointer box-shadow btn-radio custom-btn-radio box-shadow p-0 radio-survey">
                                            <Button variant="contained" color="#00b3f4" name={data.option[parseInt(j + 1)]['id']} onClick={(e)=>this.labelOnClick(e)}>
                                                <div className="fill-by-img" style={{ "backgroundImage": "url(" + data.option[parseInt(j + 1)]['option_image'] + ")" }}>
                                                </div>
                                                <div className="custom-radio-btn radio-align-img w-100 min-height-for-img py-3 px-4">
                                                    <input type="radio" id={data.option[parseInt(j + 1)]['id']} name={data.question_id} className="custom-control-input singleselection" value={data.option[parseInt(j + 1)]['id']}
                                                        checked={(this.props.questionanswer.option === undefined) ?
                                                            (parseInt(this.props.questionanswer[data.question_id]) === data.option[j + 1]['id']) ? true : false
                                                            : // eslint-disable-next-line
                                                            (this.props.questionanswer.option.findIndex(ans => ans.option_id === data.option[parseInt(j + 1)]['id']) > -1) ? true : false
                                                        }
                                                        onClick = {this.props.onChange}
                                                        onChange={this.props.onChange} />
                                                    <svg width="20px" height="20px" viewBox="0 0 20 20">
                                                        <circle cx="10" cy="10" r="9"></circle>
                                                        <path style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }} d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                                        <path style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }}s d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                                                    </svg>
                                                    <span className="ml-3 font-weight-normal">{data.option[parseInt(j + 1)]['option_text']}</span>
                                                </div>
                                            </Button>
                                        </label>
                                    </div>
                                    : null
                                : null
                            }
                        </div>)
                        :
                        ((j !== dataTotalLength) ?
                            <div className={(this.option_width.indexOf("full_width_option") !== -1) ? "row pb-0" : "row pb-0 pb-md-0 pb-lg-3 "} key={j}>
                                {(j < dataTotalLength) ?
                                    (data.option[j]['option_text'] !== "free_text_condition") ?
                                        <div className={this.option_width}>
                                            <div className="custom-radio-btn w-100 MuiButton-effect">
                                                <div className="custom-control custom-radio radio-align custom-control-inline pl-0 w-100">
                                                    <label htmlFor={data.option[j]['id']} className="btn-radio radio-survey custom-btn-radio box-shadow p-0">
                                                        <Button variant="contained" className="py-3 px-4" color="#00b3f4" name={data.option[j]['id']} onClick={(e)=>this.labelOnClick(e)}>
                                                            <input type="radio" id={data.option[j]['id']} name={data.question_id} value={data.option[j]['id']}
                                                                checked={(this.props.questionanswer.option === undefined) ?
                                                                    (parseInt(this.props.questionanswer[data.question_id]) === data.option[j]['id']) ? true : false
                                                                    :// eslint-disable-next-line
                                                                    (this.props.questionanswer.option.findIndex(ans => ans.option_id === data.option[j]['id']) > -1) ? true : false}
                                                                className="singleselection" onChange={this.props.onChange} onClick={this.props.onChange} />
                                                            <svg width="20px" height="20px" viewBox="0 0 20 20">
                                                                <circle cx="10" cy="10" r="9"></circle>
                                                                <path style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }} d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                                                <path style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }} d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                                                            </svg>
                                                            <span className="ml-3 font-weight-normal">{data.option[j]['option_text']}</span>
                                                        </Button>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    : null
                                }
                                {(parseInt(j + 1) < dataTotalLength) ?
                                    (data.option[parseInt(j + 1)]['option_text'] !== "free_text_condition") ?
                                        <div className={this.option_width}>
                                            <div className="custom-radio-btn w-100 MuiButton-effect">
                                                <div className="custom-control radio-align custom-radio custom-control-inline pl-0 w-100">
                                                    <label htmlFor={data.option[parseInt(j + 1)]['id']} className="btn-radio radio-survey custom-btn-radio box-shadow p-0">
                                                        <Button variant="contained" className="py-3 px-4" color="#00b3f4" name={data.option[j + 1]['id']} onClick={(e)=>this.labelOnClick(e)}>
                                                            <input type="radio" id={data.option[parseInt(j + 1)]['id']} name={data.question_id} value={data.option[parseInt(j + 1)]['id']}
                                                                checked={(this.props.questionanswer.option === undefined) ?
                                                                    (parseInt(this.props.questionanswer[data.question_id]) === data.option[parseInt(j + 1)]['id']) ? true : false
                                                                    :// eslint-disable-next-line
                                                                    (this.props.questionanswer.option.findIndex(ans => ans.option_id === data.option[parseInt(j + 1)]['id']) > -1) ? true : false}
                                                                className="singleselection" onChange={this.props.onChange} onClick={this.props.onChange} />
                                                            <svg width="20px" height="20px" viewBox="0 0 20 20">
                                                                <circle cx="10" cy="10" r="9"></circle>
                                                                <path style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }} d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                                                <path style={{"--colorpicker": surveyDetail.survey_color ? surveyDetail.survey_color : "#00b3f4" }} d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                                                            </svg>
                                                            <span className="ml-3 font-weight-normal">{data.option[parseInt(j + 1)]['option_text']}</span>
                                                        </Button>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    : null
                                }
                                {(parseInt(lengthCount) === i) ? (is_free === 1 && freetextWithoutRow) ? freeTextOption : null : null}
                            </div>
                            : null
                        )
                )
                j = (data.option[i]['option_image']) ? parseInt(j + 2) : parseInt(j + 2)
            }
        }
        return (
            <div key={this.props.questiondata.question_id} className={"tab-pane sectionstep custom-form-animated animated " + ((this.props.backButtonClick) ? "fadeInLeft" : "fadeInRight")} id="survey-form-1" data-step="0">
                <div className="question-heading">
                    <h3 className="text-dark font-weight-bold text-break survey-title">{data.question_text}</h3>
                    {(this.props.errormessage !== undefined) ? <div id="errorSurveyMessage" className="error-text"><p>{this.props.errormessage}</p></div> : null}
                </div>
                {(data.option[0]['option_image'] === null || data.option[0]['option_image'] === "") ?
                    optionlist
                    : <div className="row no-gutters survey-form-question">
                        <div className="col-12">
                            {optionlist}
                        </div>
                    </div>
                }
                {(is_free === 1 && !freetextWithoutRow) ?
                    freeTextOption
                    : null
                }
            </div>
        )
    }
}
export default SurveySingleSelectionQuestion;