import React from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { BUBBLE_LOADING, URL_CONSTANT, MYPROFILE_EDITPROFILE_CONSTANT } from '../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, IMAGE_BASEURL } from '../../constants/DotEnv';
class Statistic extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: 'false',
            redirect: "false",
            mystatisticcontent: {},
        }
    }
    componentDidMount() {
        this.scrollUpFunction();
        this.getMystatistic();
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (document.querySelector('.navbar-toggler')) {
            let count = document.querySelector('.navbar-toggler').className.indexOf("collapsed");
            if (count === -1) {
                document.querySelector('.navbar-collapse').classList.remove('show');
                document.querySelector('header').classList.remove("header_collapse");
                document.querySelector('.navbar-toggler').setAttribute("aria-expanded", false);
            }
        }
    }
    /** API call for geting user's unlocked survey list */
    getMystatistic = async () => {
        this.setState({ loading: 'true' })
        let self = this;
        let mystatisticContent = '';
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let token = '';
        let userid = '';
        if (storeitem !== null) {
            token = storeitem.token;
            userid = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
        await axios.get(API_URL + '/userssurvey/unlockSurveyResult/' + LANGUAGE_CODE + "/" + userid + '?uId=' + userid, { headers: headers })
            .then((response) => {
                mystatisticContent = response.data.success.UnlocksurveyResult;
                self.setState({ loading: 'false', mystatisticContent })
            })
            .catch((error) => {
                self.setState({ loading: 'false' })
                let apiresponse = "";
                if (error) {
                    if (error.response) {
                        if (error.response.data) {
                            apiresponse = error.response.data['error'];
                            if (apiresponse.length) {
                                if (apiresponse === 'Expired token' || apiresponse === 'Invalid token') {
                                    localStorage.clear();
                                    self.setState({ redirect: 'true' })
                                }
                            }
                        }
                    }
                }
            })
    }
    render() {
        /**for identifying chrome and safari browser */
        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        var issafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let myprofileactivetab = localStorage.getItem('myprofileactivetab');
        if (myprofileactivetab) {
            myprofileactivetab = parseInt(myprofileactivetab);
        }
        let statisticData = this.state.mystatisticContent;
        var length = '';
        for( let key in statisticData ) {
            if(statisticData.hasOwnProperty(key)) {
                ++length;
            }
        }
        var statisticdetails = [];
        let title = "";
        if (length !== 0) {
            for (let i = 0; i < length; i++) {
                if (statisticData[i].survey_title) {
                    if (window.innerWidth >= 992 && window.innerWidth < 1200) {
                        if (statisticData[i].survey_title.length > 45) {
                            title = statisticData[i].survey_title.substring(0, 45).trim() + "...";
                        } else {
                            title = statisticData[i].survey_title
                        }
                    }
                    else if (window.innerWidth >= 768 && window.innerWidth < 992) {
                        if (statisticData[i].survey_title.length > 47) {
                            title = statisticData[i].survey_title.substring(0, 47).trim() + "...";
                        } else {
                            title = statisticData[i].survey_title
                        }
                    }
                    else if (window.innerWidth >= 576 && window.innerWidth < 768) {
                        if (statisticData[i].survey_title.length > 125) {
                            title = statisticData[i].survey_title.substring(0, 125).trim() + "...";
                        } else {
                            title = statisticData[i].survey_title
                        }
                    }
                    else if (window.innerWidth >= 500 && window.innerWidth < 576) {
                        if (statisticData[i].survey_title.length > 125) {
                            title = statisticData[i].survey_title.substring(0, 125).trim() + "...";
                        } else {
                            title = statisticData[i].survey_title
                        }
                    }
                    else if (window.innerWidth >= 400 && window.innerWidth < 500) {
                        if (statisticData[i].survey_title.length > 90) {
                            title = statisticData[i].survey_title.substring(0, 90).trim() + "...";
                        } else {
                            title = statisticData[i].survey_title
                        }
                    }
                    else if (window.innerWidth >= 320 && window.innerWidth < 400) {
                        if (statisticData[i].survey_title.length > 63) {
                            title = statisticData[i].survey_title.substring(0, 63).trim() + "...";
                        } else {
                            title = statisticData[i].survey_title
                        }
                    }
                    else if (window.innerWidth < 320) {
                        if (statisticData[i].survey_title.length > 73) {
                            title = statisticData[i].survey_title.substring(0, 73).trim() + "...";
                        } else {
                            title = statisticData[i].survey_title
                        }
                    }
                    else {
                        if (statisticData[i].survey_title.length > 60) {
                            title = statisticData[i].survey_title.substring(0, 60).trim() + "...";
                        } else {
                            title = statisticData[i].survey_title;
                        }
                    }
                }
                let backImage = IMAGE_BASEURL + "img/100x100.jpg"
                if (statisticData[i].thumb_image) {
                    backImage = statisticData[i].thumb_image;
                }
                statisticdetails.push(<div className="col-md-4 col-lg-3 mb-3" key={i}>
                    <div className="card bg-white boxshadow h-100">
                        <Link to={"/" + URL_CONSTANT['survey_result'] + "/" + statisticData[i].survey_title_slug} className="d-inline-block" rel="noopener noreferrer">
                             <div className="blog-img position-relative h-170 background-image-css" style={{ "backgroundImage": "url(" + backImage + ")" }}>
                            </div>
                            <div className="card-content d-block w-100">
                                <h6 className={(isChrome || issafari) ? "text-dark font-16 custom-test-text" : "text-dark font-16 text-hyphens"}>{(isChrome || issafari) ? statisticData[i].survey_title : title}</h6>
                            </div>
                        </Link>
                    </div>
                </div>
                )
            }
        }
        return(
            <div className={(this.props.myprofileactivetab === 3) ? "tab-pane fade show active" : "tab-pane fade"} id="statistiken" role="tabpanel" aria-labelledby="statistiken-tab">
                {(this.state.loading === 'true') ?
                    BUBBLE_LOADING
                    : null
                }
                {(this.state.redirect === 'true') ?
                    <Redirect to="/anmelden"></Redirect>
                    : null
                }
                <div className="card-umfragen">
                    <div className="row">
                        <div className="col-12">
                            <div className="main-card md-m-30px-b">
                                <div className="row">
                                    {/** here display statistic details which is unlocked survey by user*/}
                                    {(length !== 0) ?
                                        statisticdetails
                                        : null
                                    }
                                    { (length === '' || length === 0 || length === null || length === undefined) ?
                                        <div className="col">
                                            <div className="row mx-0 box-shadow survey-title-box">
                                                <div className="col-12 col-lg-1 text-center add-answer py-lg-5 py-1 px-0 pl-3 pl-lg-0 mt-2">
                                                    <div className="round-information">
                                                        <i className="fas fa-info icon-m border-radius"></i>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-11 pt-2 px-lg-0 px-3 py-lg-4 pb-3">
                                                    <p className="font-20 text-dark font-weight-bold mb-4 text-left">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['statistic_heading']}</p>
                                                    <p className="mr-lg-4 mr-0 font-16 font-weight-bold text-dark mb-1 text-left">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['statistic_subheading1']}</p>
                                                    <p className="mr-lg-4 mr-0 font-16 text-dark mb-3 text-left">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['statistic_subtext1']}</p>
                                                    <p className="mr-lg-4 mr-0 font-16 font-weight-bold text-dark mb-1 text-left">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['statistic_subheading2']}</p>
                                                    <p className="mr-lg-4 mr-0 font-16 text-dark mb-1 text-left">{MYPROFILE_EDITPROFILE_CONSTANT[LANGUAGE_CODE]['statistic_subtext2']}</p>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Statistic;