import React from 'react'
import createChartDownloadLink,{getActualImageString} from "./createChartDownloadLink" //saveEditedImage
import { chartOptionTextStyle,chartAnimation,SURVEY_PARTICIPATION } from '../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../constants/DotEnv';
export default class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            options: {},
            chart_data: ''
        };
        let script_tag = document.createElement('script');
        script_tag.src = "https://www.gstatic.com/charts/loader.js";
        script_tag.id = "chart_script";
        script_tag.type = "text/javascript";
        if (!document.getElementById("chart_script")) {
            document.body.append(script_tag);
        }
        if(document.getElementById("chart_script")){
            if(window.google && window.google.charts){
                this.callGoogleAPI()
            }
            document.getElementById('chart_script').addEventListener('load', () => {
                if (window.google && window.google.charts) {
                    this.callGoogleAPI()
                }
              })
        }
    }
    callGoogleAPI = () => {
        window.google.charts.load("50", { packages: ['corechart'] });
        window.google.charts.setOnLoadCallback(this.drawChart());
    }
    componentWillUnmount () {
        document.getElementById('chart_script').removeEventListener('load',() => {
            if (window.google && window.google.charts) {
                window.google.charts.load("50", { packages: ['corechart'] });
                window.google.charts.setOnLoadCallback(this.drawChart());
              }
        })
    }
    createChartDiv() {
        let chart_divs = [];
        let questionTitle = '';
        let changeWidth;
        // eslint-disable-next-line
        this.props.chartData.map((chart, index) => {
            if (chart.options) {
                if (chart.options.chart_type && chart.options.chart_type.toLowerCase() === "piechart") {
                    if (window.innerWidth >= 320 && window.innerWidth <= 575) {
                        let chartArea = { 'left': 15, 'right': 15, 'width': '100%', 'height': '80%' };
                        this.props.chartData[index].options['chartArea'] = chartArea;
                    }
                    if (window.innerWidth >= 320 && window.innerWidth <= 400) {
                        changeWidth = (window.innerWidth - 30) + "px";
                    }
                    else {
                        changeWidth = 100 + "%";
                    }
                }
            } 
            // eslint-disable-next-line
            if (chart.hasOwnProperty("question_type_id") && chart.question_type_id === 5 || chart.question_type_id === 7) {
            }
            else {
                questionTitle = chart.options.title;
                return chart_divs.push(<React.Fragment><div className="bg-white box-shadow chart-main-block"><div className="table-content bg-white"><h3 className="mb-0 text-dark font-weight-bold text-break">{questionTitle}</h3></div><div className= "hide-chart-title pl-3 pl-sm-0" style={{ "height": "400px", "width": changeWidth }} id={"chart_div_demographic_" + index}> <div className="d-flex align-items-center justify-content-center" style={{"height" : "400px"}}><i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i></div></div><div className="d-flex chart-bottom justify-content-between align-items-center">{chart.user_participated ? <span className="user-count"><i class="ti-user"></i>{chart.user_participated}</span> : <span className="user-count"><i class="ti-user"></i>0</span>}
                {/* <div className="text-right"><div><button disabled="true" id={"download_chart_div_demographic_" + index}class="border-0 bg-transparent font-weight-bold pointer chart-img"><i className="fa fa-spinner fa-spin mr-2" style={{ "fontSize": "20px", "color": "#128df5" }}></i><span>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</span></button></div></div> */}
                </div></div></React.Fragment>)
            }
        })
        return chart_divs;
    }
    drawChart() {
        if(this.props.chartData){
        setTimeout(async() => {
            // eslint-disable-next-line
                await this.props.chartData.reduce(async(prev, chart, index) => {
                    const res = await prev
                // eslint-disable-next-line
                if (chart.hasOwnProperty("question_type_id") && (chart.question_type_id === 5 || chart.question_type_id === 7)) {
                }
                else {
                    var data = window.google.visualization.arrayToDataTable(chart.data);
                    var view = new window.google.visualization.DataView(data);
                    let chart_type_data = chart.options.chart_type;
                    let img_name = this.props.questionLength + index+1 + "_" + (chart.options && chart.options.title
                        ? (chart.options.title.length > 200)
                            ? chart.options.title.substring(0,200).replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
                            : chart.options.title.replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
                        : "demographic_" + chart.demographic_id + ".png");
                    var chart_div = new window.google.visualization[chart_type_data](document.getElementById("chart_div_demographic_" + index));
                    chart_div.draw(view, {...chart.options,...chartAnimation,...chartOptionTextStyle});
                }
            return res
        }, [])
        }, 3000)
        }
    }
    render() {
        let chart_divs = (this.props.chartData) ? this.createChartDiv(this.state.chart_data) : <div className="d-flex align-items-center justify-content-center" style={{ "height": "400px" }}><i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i></div>;
        return (<div id="columnchart_values">{chart_divs}</div>)
    }
}