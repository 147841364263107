import React from 'react'
import createChartDownloadLink,{getActualImageString} from "./createChartDownloadLink"; //saveEditedImage
import { chartOptionTextStyle, chartAnimation, FREETEXT_TAG_COULD, MULTIPLE_SELECTION_QUESTION_CHART_SUBTITLE,SURVEY_PARTICIPATION } from '../../constants/FrontEndConstant';
import { LANGUAGE_CODE } from '../../constants/DotEnv';
import TagCloud from 'react-tag-cloud';
import randomColor from 'randomcolor';

export default class App extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      options: {},
      chart_data: '',
      data_div: [],
    };
    let script_tag = document.createElement('script');
    script_tag.src = "https://www.gstatic.com/charts/loader.js";
    script_tag.id = "chart_script";
    script_tag.type = "text/javascript";
    if (!document.getElementById("chart_script")) {
      document.body.append(script_tag);
    }
    if(document.getElementById("chart_script")){
      if(window.google && window.google.charts){
        this.callGoogleApi()
      }
      document.getElementById('chart_script').addEventListener('load', () => {
        if (window.google && window.google.charts) {
         this.callGoogleApi()
        }
      })
    }
    this.ImagetoSend = [];
    this.image_meta = {};
  }
  callGoogleApi = () => {
    setTimeout(() => {
      window.google.charts.load("50", { packages: ['corechart'] });
      window.google.charts.setOnLoadCallback(this.drawChart);
    },500)
  }
  componentDidMount= () => {
    this.createChartDiv(this.state.chart_data)
    // setInterval(() => {
    //   this.forceUpdate();
    // }, 20000);
  }
  componentWillUnmount = () => {
    document.getElementById('chart_script').removeEventListener('load',() => {
        if (window.google && window.google.charts) {
            window.google.charts.load("50", { packages: ['corechart'] });
            window.google.charts.setOnLoadCallback(this.drawChart);
          }
    })
}
  createChartDiv =() => {
    let chart_divs = [];
    let child_chart_divs = [];
    let changeWidth;
    let questionTitle = '';
    // eslint-disable-next-line
    this.props.chartData.map((chart, index) => {
      child_chart_divs = [];
      changeWidth = 100 + "%";
      // eslint-disable-next-line
      if (chart.hasOwnProperty("question_type_id") && chart.question_type_id === 5 || chart.question_type_id === 7) {
        let keyArray = Object.keys(chart);
        questionTitle = chart.question_title;
        // eslint-disable-next-line
        keyArray.map((objKey, obj_index) => {
          if (objKey.substring(0, 4) === "data") {
            child_chart_divs.push(<React.Fragment><div style={{ "height": "400px", "width": changeWidth }} id={"chart_div_opt_" + objKey + "_" + index} className="pl-3 pl-sm-0"><div className="d-flex align-items-center justify-content-center" style={{"height" : "400px"}}><i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i></div></div><div className="d-flex chart-bottom justify-content-between align-items-center">{chart.user_participated ? <span className="user-count"><i class="ti-user"></i>{chart.user_participated}</span> : <span className="user-count"><i class="ti-user"></i>0</span>}
            {/* <div className="text-right"><div><div><button id={"download_chart_div_opt_"+objKey+ "_" + index} class="border-0 bg-transparent font-weight-bold pointer chart-img" disabled="true">
            <i className="fa fa-spinner fa-spin mr-2" style={{ "fontSize": "20px", "color": "#128df5" }}></i><span>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</span></button></div></div></div> */}
            </div></React.Fragment>)
          }
        });
        chart_divs.push(<div className="bg-white box-shadow chart-main-block"><div className="table-content bg-white"><h3 className="mb-0 text-dark font-weight-bold text-break">{questionTitle}</h3></div>{child_chart_divs}</div>)
      } else {
        if(chart.question_type_id && chart.question_type_id === 1) {
          let maxFontsizeTagCloud = 60;
          let minFontsizeTagCloud = 14;
          let differenceBetweenTwoTag = 15;
          let currentTagCloudFontSize = maxFontsizeTagCloud;
          chart_divs.push(
            chart.tagcloud.length ?
              <React.Fragment>
                <div className="bg-white box-shadow custom-tag-parent chart-main-block-freetext">
                  <div className="table-content bg-white">
                    <h3 className="mb-0 text-dark font-weight-bold text-break">{chart.question_title.title}</h3>
                  </div>
                  <div className="bottom-border-blue" name="hidden-tag-chart">
                  </div>
                  <div className="freetext-subtitle pt-1" name="hidden-tag-chart">
                    <h3 className="font-14 lh-24 fw-400 survey-subtitle">{FREETEXT_TAG_COULD[LANGUAGE_CODE]['subtitle']}</h3>
                  </div>
                  <div className="bg-white">
                    <div className='app-outer bg-white' id={"chart_div_" + index + "_child"}>
                      <div className='app-inner'>
                        <TagCloud
                          className='tag-cloud'
                          style={{ fontFamily: 'sans-serif', chartAnimation: null,
                            color: () => randomColor({
                              hue: this.props.chartColor
                          }),
                          padding: 5,
                        }}>
                          {(chart.tagcloud.map((tagArray, index)=>{
                            if(minFontsizeTagCloud <= currentTagCloudFontSize && index !== 0 && chart.tagcloud[index].tag_count !== chart.tagcloud[index-1].tag_count){
                              currentTagCloudFontSize -= differenceBetweenTwoTag;
                            }
                            return (<div style={{fontWeight: "bold", fontSize: currentTagCloudFontSize, animation: 'none'}}>{tagArray.tag}</div>)
                          }))}
                        </TagCloud>
                      </div>
                    </div>
                    <div className="d-flex chart-bottom justify-content-between align-items-center" name="hidden-tag-chart">
                        {chart.user_participated && <span className="user-count"><i class="ti-user"></i>{chart.user_participated}</span>}
                        <div id={"download_chart_div_" + index}></div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            : null
          )
        }
        else {
          questionTitle = chart.options.title;
          return chart_divs.push(<React.Fragment><div className="bg-white box-shadow chart-main-block"><div className="table-content bg-white"><h3 className="mb-0 text-dark font-weight-bold text-break">{questionTitle}</h3></div>{ chart.question_type_id && chart.question_type_id === 4 ? <div className="freetext-subtitle pt-1" name="hidden-tag-chart"><h3 className="pl-3 pb-0 font-14 lh-24 fw-400 survey-subtitle bg-display-none multiple-select-padding">{MULTIPLE_SELECTION_QUESTION_CHART_SUBTITLE[LANGUAGE_CODE]['subtitle']}</h3>
          </div> : null }<div className="hide-chart-title pl-3 pl-sm-0" style={{ "height": "400px", "width": changeWidth }} id={"chart_div_" + index}> <div className="d-flex align-items-center justify-content-center" style={{ "height": "400px" }} ><i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i></div></div><div className="d-flex chart-bottom justify-content-between align-items-center">{chart.user_participated ? <span className="user-count"><i class="ti-user"></i>{chart.user_participated}</span> : <span className="user-count"><i class="ti-user"></i>0</span>}
         {/* <div className="text-right"> <div><button class="border-0 bg-transparent font-weight-bold pointer chart-img" id={"download_chart_div_" + index} disabled="true"><i className="fa fa-spinner fa-spin mr-2" style={{ "fontSize": "20px", "color": "#128df5" }}></i><span>{SURVEY_PARTICIPATION[LANGUAGE_CODE]['canvasDownloadButtonLabel']}</span></button></div></div> */}
         </div></div></React.Fragment>)
        }
      }
    })
    // this.setState({data : chart_divs})
    return chart_divs;
  }

  // getDataUrl(img) {
  //     // Create canvas
  //     const canvas = document.createElement('canvas');
  //     const ctx = canvas.getContext('2d');
  //     // Set width and height
  //     canvas.width = img.width;
  //     canvas.height = img.height;
  //     // Draw the image
  //     ctx.drawImage(img, 0, 0);
  //     return canvas.toDataURL();
  // }

  // filterHtmlImage(node){
  //   if(node.tagName === "DIV"){
  //       if(node.getAttribute("name") !== "hidden-tag-chart"){
  //         return node;
  //       }
  //   }
  //   else {
  //     return node;
  //   }
  // }

  drawFreeTextChart = () => {
    this.setState({displayFreeText: true})
  }
  drawChart = () => {
    if(this.props.chartData){
    setTimeout(async() => {
      // eslint-disable-next-line
        await this.props.chartData.reduce(async(prev, chart, index) => {
          const res = await prev
        // this.ImagetoSend = [];
        this.image_meta = {};
        if (chart.hasOwnProperty("question_type_id") && (chart.question_type_id === 5 || chart.question_type_id === 7)) {
          let keyArray = Object.keys(chart);
          // eslint-disable-next-line
            await keyArray.reduce(async(prevVal, value, array_index) => {
              const newRes = await prevVal
              value = keyArray[array_index]
            if (value.substring(0, 4) === "data") {
              this.image_meta = {};
              var last_char = value.substring(4);
              var data = window.google.visualization.arrayToDataTable(chart[value]);
              var view = new window.google.visualization.DataView(data);
              let chart_type_data;
              let chart_options;
              if (chart.question_type_id === 7 || chart.question_type_id === 5) {
                chart_type_data = chart["option" + last_char].chart_type;
                chart_options = chart["option" + last_char]
                chart_options = {...chart_options,colors:['red']}
              } else {
                chart_type_data = chart.options.chart_type;
                chart_options = chart.options;
                chart_options = {...chart_options,colors:['red']}
              }
              var chart_div = new window.google.visualization[chart_type_data](document.getElementById("chart_div_opt_" + value + "_" + index));
              window.google.visualization.events.addListener(chart_div, 'ready', () => {
                let img_string = chart_div.getImageURI();
                let isLast = keyArray.length -1 === array_index;
                let img_name = (index+1) + "_" + (parseInt(last_char) + 1) + "_" + ((chart_options && chart_options.title)
                    ? (chart_options.title.length > 200)
                      ? chart_options.title.substring(0,200).replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
                      : chart_options.title.replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
                    : chart.question_id + "_opt_" + value + ".png");
              });
              chart_div.draw(view, {...chart_options,...chartAnimation});
            }
          return newRes
        }, [])
        }
        else if(chart.hasOwnProperty("question_type_id") && chart.question_type_id === 1) {
          let self = this;
          let img_name = index+1 + "_" + ((chart && chart.question_title && chart.question_title.title)
              ? (chart.question_title.title.length > 200)
                ? chart.question_title.title.substring(0,200).replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
                : chart.question_title.title.replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
              : chart.question_id + ".png");
              img_name =  img_name.replaceAll("ä","ae");
              img_name =  img_name.replaceAll("ü","ue");
              img_name =  img_name.replaceAll("ö","oe");
              img_name =  img_name.replaceAll("Ü","Ue");
              img_name =  img_name.replaceAll("Ä","Ae");
              img_name =  img_name.replaceAll("Ö","Oe");
              img_name =  img_name.replaceAll("ß","ss");
          // if(chart.tagcloud.length){
          //   htmlToImage.toCanvas(document.getElementById("chart_div_" + index), {filter: this.filterHtmlImage, style: {'font-family':'sans-serif'}})
          //   .then(function (dataUrl) {
          //   });
          // }
        }
        else {
          this.image_meta = {};
          var data = window.google.visualization.arrayToDataTable(chart.data);
          var view = new window.google.visualization.DataView(data);
          let chart_type_data = chart.options.chart_type;
          var chart_div = new window.google.visualization[chart_type_data](document.getElementById("chart_div_" + index));
          window.google.visualization.events.addListener(chart_div, 'ready', () => {
          let img_string = chart_div.getImageURI();
          let img_name = index+1 + "_" + ((chart && chart.options && chart.options.title)
              ? (chart.options.title.length > 200)
                ? chart.options.title.substring(0,200).replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
                : chart.options.title.replace(/[?]/g, "").replace(/[!@#$%^&*"€()?',.|=]/g, "_").replace(/\s/g, '_').replace(/\//g, '_').replace(/[&\/\\#, +()$~%.'"*?<>{}`-]/g, '_').replace(/[\[\]']/g,'_') + ".png"
              : chart.question_id + ".png");
          });
          chart_div.draw(view, {...chart.options,...chartAnimation,...chartOptionTextStyle});
        }
      return res
    }, [])
    }, 3000)
    }
  }
  render() {
    let chart_divs = (this.props.chartData) ? this.createChartDiv() : <div className="d-flex align-items-center justify-content-center" style={{"height" : "400px"}}><i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i></div>;
    return (<div id="columnchart_values">{chart_divs}</div>)

    // return (<div id="columnchart_values">{this.state.data.length < this.props.chartData.length ? <div className="d-flex align-items-center justify-content-center" style={{"height" : "400px"}}><i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i></div> : this.state.data}</div>)
  }
}
