import React from 'react';
import axios from 'axios';
import { Redirect, Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import { EDIT_SURVEY_CONSTANT, FIND_PARTICIPANTS_ERROR_MESSAGE, MYPROFILE_POLLS_CONSTANT, BUBBLE_LOADING, URL_CONSTANT } from '../../../constants/FrontEndConstant';
import { LANGUAGE_CODE, API_URL, MESSAGE_TIME, BASEURL } from '../../../constants/DotEnv';
import MaskedInput from 'react-text-mask';
import CountUp from 'react-countup';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterIcon,
    TwitterShareButton,
    WhatsappShareButton,
    WhatsappIcon } from "react-share";
const ListItem = ({ value }) => (<span> {value}<br /></span>);
class FindParticipants extends React.Component {
    constructor() {
        super();
        this.state = {
            categoryOption: '',
            loading: "false",
            about_survey_text: "",
            about_target_group_text: "",
            end_date: "",
            reward_type: "",
            reward_description: "",
            fieldErrorMessage: [],
            error: 0,
            message: "",
            responseMessage: "",
            errorMessage: [],
            redirect: "false",
            copyMessage: "",
            poll_data: "",
            status: "",
            responseSurvey: "",
            changebutton: false,
            bubbleLoading : 'false',
            toggle: false,
            englishLable: false
        }
    }
    /**get category dropdown data from the localstorage if it not exist in localstorage then call api for getting category dropdown data */
    componentDidMount() {
        this.scrollUpFunction();
        this.getallowmultipleparticipations();
        this.getEnglishLabelStatus();
    }
    getallowmultipleparticipations = async () => {
        let survey_id = parseInt(this.state.poll_data.id);
        await axios.get(API_URL + '/commonApi/getallowmultipleparticipations/' + LANGUAGE_CODE + '/' + survey_id)
                    .then((response) => {
                        if (response.data.success) {
                            let value = response.data.success.allowmultipleparticipations
                            if(value == 1) this.setState({toggle: true})
                            else this.setState({toggle: false})
                        }
                    })
                    .catch((error) => {
                        console.log("error", error)
                    })
    }
    getEnglishLabelStatus = async () => {
        let survey_id = parseInt(this.state.poll_data.id);
        await axios.get(API_URL + '/commonApi/getenglishbuttonlabels/' + LANGUAGE_CODE + '/' + survey_id)
            .then((response) => {
                if (response.data.success) {
                    let value = response.data.success.englishbuttonlabels
                    if(value == 1) this.setState({englishLable: true})
                    else this.setState({englishLable: false})
                }
            })
            .catch((error) => {
                console.log("error", error)
            })
    }
    /**
    * Warning: This lifecycle is currently deprecated, and will be removed in React version 17+
    More details here: https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
    */
    componentWillMount() {
        this.checkData();
    }
    checkData = async () => {
        this.setState({ loading: "true" })
        let poll_data = this.props.poll_data;
        var responseSurvey = '';
        if (localStorage.getItem("surveycreationdata_" + poll_data.id)) {
            responseSurvey = JSON.parse(localStorage.getItem("surveycreationdata_" + poll_data.id))
        } else {
            responseSurvey = this.props.poll_data;
        }
        let status = ""
        if (poll_data.status) {
            status = poll_data.status
        }
        this.setState({ poll_data, status})
        let survey_id = poll_data.id;
        if (survey_id) {
            this.surveyMainId = poll_data.id;
        }
        let categoryOption = "";
        let self = this;
        // eslint-disable-next-line
        if (this.props.callForData) {
            this.setfieldValue(responseSurvey);
            this.setrankposition(this.surveyMainId);
            this.setState({ responseSurvey});
        }
        else {
            let self = this;
            let responseQuestion = '';
            let storeitem = JSON.parse(localStorage.getItem('userdata'));
            let apitoken = '';
            let userid = '';
            if (storeitem !== null) {
                apitoken = storeitem.token;
                userid = storeitem.id;
            }
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + apitoken
            }
            await axios.get(API_URL + '/survey/getQuestionBySurveyId/' + LANGUAGE_CODE + '/' + poll_data.id + '?uId=' + userid, { headers: headers })
                .then((response) => {
                    responseSurvey = response.data['success'].surveydata;
                    this.setfieldValue(responseSurvey);
                    responseQuestion = response.data['success'].questiondata;
                    if (this.surveyMainId) {
                        localStorage.setItem("surveycreationdata_" + poll_data.id, JSON.stringify(responseSurvey));
                        localStorage.setItem("surveycreationallquestiondata_" + poll_data.id, JSON.stringify(responseQuestion));
                    }
                    self.setState({ responseSurvey})
                })
                .catch((error) => {
                    if (error) {
                        if (error.response) {
                            if (error.response.data) {
                                let msg = error.response.data['error']
                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                    localStorage.clear();
                                    self.setState({ redirect: 'true' })
                                }
                                if (msg === 'Invalid User') {
                                    self.setState({ redirectToNotFound: true })
                                }
                            }
                        }
                    }
                })
        }
        categoryOption = JSON.parse(localStorage.getItem("surveylistdropdown"));
        if (categoryOption === null) {
            await axios.get(API_URL + '/commonApi/getAllSurveydp/' + LANGUAGE_CODE)
                .then((response) => {
                    categoryOption = response.data.success;
                    localStorage.setItem("surveylistdropdown", JSON.stringify(categoryOption));
                    categoryOption = categoryOption.reward;
                    self.setState({ categoryOption })
                })
                .catch((error) => {
                })
        }
        else {
            categoryOption = JSON.parse(localStorage.getItem("surveylistdropdown"))
            categoryOption = categoryOption.reward;
            self.setState({ categoryOption })
        }
        self.setState({ loading: "false" })
    }
    scrollUpFunction = () => {
        /**for scrolling up page */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    setrankposition = async (survey_id) => {
        this.setState({ bubbleLoading : 'true' })
        let rank_positions = '';
        let self = this;
        let survey_Id = survey_id;
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let userid = '';
        if (storeitem !== null) {
            apitoken = storeitem.token;
            userid = storeitem.id;
        }
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        await axios.get(API_URL + '/survey/getRankPosition/' + LANGUAGE_CODE + '/' + survey_Id + '?uId=' + userid, { headers: headers })
            .then((response) => {
                rank_positions = response.data['success'].rank_position;
                self.setState({rank_positions})
            })
            .catch((error) => {
                if (error) {
                    if (error.response) {
                        if (error.response.data) {
                            let msg = error.response.data['error']
                            if (msg === 'Expired token' || msg === 'Invalid token') {
                                localStorage.clear();
                                self.setState({ redirect: 'true' })
                            }
                            if (msg === 'Invalid User') {
                                self.setState({ redirectToNotFound: true })
                            }
                        }
                    }
                }
            })
        this.setState({ bubbleLoading : 'false' })
    }
    /** to set all state value which is already field by user */
    setfieldValue = (responseSurvey) => {
        let filledupdata = responseSurvey;
        let enddate = "";
        let currentdate = "";
        let changebutton = false;
        var CurrentDateForMonth = new Date();
        let day = CurrentDateForMonth.getDate();
        let month = CurrentDateForMonth.getMonth() + 3;
        let year = CurrentDateForMonth.getFullYear();
        if (month > 12) {
            year = year + 1;
            month = month - 12;
        }
        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }
        let nextAllowDate = day + "." + month + "." + year;
        if (filledupdata) {
            // eslint-disable-next-line
            Object.keys(filledupdata).map((key, index) => {
                const keyNew = key;
                var value = filledupdata[key];
                if (key === 'end_date') {
                    if (value) {
                        enddate = value.split(".")
                        enddate = new Date(+enddate[2], enddate[1] - 1, +enddate[0]);
                        this.convertDate = (dateattribute) => {
                            var dd = dateattribute.getDate();
                            var mm = dateattribute.getMonth() + 1; //January is 0!
                            var yyyy = dateattribute.getFullYear();
                            if (dd < 10) {
                                dd = '0' + dd;
                            }
                            if (mm < 10) {
                                mm = '0' + mm;
                            }
                            return dd + '.' + mm + '.' + yyyy
                        }
                        currentdate = new Date()
                    }
                    nextAllowDate = nextAllowDate.split(".")
                    nextAllowDate = new Date(+nextAllowDate[2], nextAllowDate[1] - 1, +nextAllowDate[0])
                    if (currentdate) {
                        currentdate = new Date(currentdate.setHours(0, 0, 0, 0))
                    }
                    if (Date.parse(enddate) < Date.parse(currentdate)) {
                        changebutton = true
                    }
                    if (Date.parse(enddate) > Date.parse(nextAllowDate)) {
                        changebutton = true
                    }
                }
                this.setState({ [keyNew]: value, changebutton })
            });
        }
    }
    /** function for submiting data using API call */
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (name === "about_survey_text" || name === "about_target_group_text") {
            let values = '';
            if (value.replace(/\s/g, '').length || value.length === 0) {
                values = value;
            }
            this.setState({ [name]: values })
        }
        else {
            this.setState({ [name]: value })
        }
        var index = this.state.fieldErrorMessage.indexOf(name);
        var indexDate = this.state.fieldErrorMessage.indexOf("notvalid")
        var indexBiggerDate = this.state.fieldErrorMessage.indexOf("bigger")
        var indexInvalid = this.state.fieldErrorMessage.indexOf('invalid')
        if (index > -1) {
            this.state.fieldErrorMessage.splice(index, 1);
        }
        if (indexDate > -1) {
            this.state.fieldErrorMessage.splice(indexDate, 1);
        }
        if (indexBiggerDate > -1) {
            this.state.fieldErrorMessage.splice(indexBiggerDate, 1);
        }
        if (indexInvalid > -1) {
            this.state.fieldErrorMessage.splice(indexInvalid, 1);
        }
    }
    /** function for submiting data and before submiting check whether all detail is fill up or not,if not then display error message */
    handleSubmit = (event) => {
        let enddate = "";
        var checkDateFormate = "";
        let currentdate = "";
        var formfield = {
            about_survey_text: this.state.about_survey_text,
            about_target_group_text: this.state.about_target_group_text,
            end_date: this.state.end_date,
            reward_type: this.state.reward_type,
            reward_description: this.state.reward_description
        }
        var CurrentDate = new Date();
        let day = CurrentDate.getDate();
        let month = CurrentDate.getMonth() + 3;
        let year = CurrentDate.getFullYear();
        if (month > 12) {
            year = year + 1;
            month = month - 12;
        }
        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }
        let nextAllowDate = day + "." + month + "." + year;
        var a = [];
        this.setState({ fieldErrorMessage: a })
        var test = 0;
        var error = ''
        for (var key in formfield) {
            if (formfield.hasOwnProperty(key)) {
                var errorkey = key;
                const value = formfield[errorkey];
                if (value === '' || value === null || value === undefined) {
                    if (key !== 'reward_description') {
                        a.push(errorkey);
                        error = test + 1;
                        test = error;
                    }
                }
                else if (key === 'reward_description') {
                    if (value) {
                        if (value.length > 100) {
                            a.push(errorkey);
                            error = test + 1;
                            test = error;
                        }
                    }
                } else {
                    if (key === 'end_date') {
                        enddate = value.split(".")
                        enddate = new Date(+enddate[2], enddate[1] - 1, +enddate[0]);
                        currentdate = new Date();
                        nextAllowDate = nextAllowDate.split(".")
                        nextAllowDate = new Date(+nextAllowDate[2], nextAllowDate[1] - 1, +nextAllowDate[0])
                        this.checkDate = (field) => {
                            let regs;
                            var errorMsg = "";
                            // regular expression to match required date format
                            let re = /^(\d{1,2})\.(\d{1,2})\.(\d{4})$/;
                            if (field !== '') {
                                // eslint-disable-next-line
                                if (regs = field.match(re)) {
                                    if (regs[1] < 1 || regs[1] > 31) {
                                        errorMsg = "Invalid date"
                                    } else if (regs[2] < 1 || regs[2] > 12) {
                                        errorMsg = "Invalid date"
                                    }
                                } else {
                                    errorMsg = "Invalid date"
                                }
                            }
                            return errorMsg;
                        }
                        checkDateFormate = this.checkDate(value)
                        if (this.state.status !== 1) {
                            if (checkDateFormate) {
                                a.push("invalid");
                                error = test + 1;
                                test = error;
                            }
                            else if (enddate < currentdate) {
                                a.push("notvalid");
                                error = test + 1;
                                test = error;
                            } else if (enddate > nextAllowDate) {
                                a.push("bigger");
                                error = test + 1;
                                test = error;
                            }
                        }
                        /** if status is active (1) and date expires then it show error message */
                        else if (this.state.status === 1) {
                            if (checkDateFormate) {
                                a.push("invalid");
                                error = test + 1;
                                test = error;
                            }
                            else if (enddate < currentdate) {
                                a.push("notvalid");
                                error = test + 1;
                                test = error;
                            } else if (enddate > nextAllowDate) {
                                a.push("bigger");
                                error = test + 1;
                                test = error;
                            } else {
                                this.setState({ changebutton: false })
                            }
                        }
                        else {
                            this.setState({ changebutton: false })
                        }
                    }
                }
            }
        }
        if (test === 0 || test === undefined) {
            /** added for the disable survey first confirm with user by popup message */
            if (this.state.status) {
                this.submitData(formfield)
            }
            else {
                this.submitData(formfield)
            }
        }
        this.setState({ error: test, fieldErrorMessage: a });
        event.preventDefault();
    }
    submitData = async (formfield) => {
        this.setState({ loading: "true" })
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let apitoken = '';
        let user_id = "";
        let status = "";
        if (storeitem !== null) {
            apitoken = storeitem.token;
            user_id = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        if (this.state.status === 1 && !this.state.changebutton) {
            var data = {
                id: parseInt(this.state.poll_data.id),
                status: parseInt(4)
            }
            let self = this;
            // eslint-disable-next-line
            await axios.post(API_URL + "/survey/updateSurveyStatusById" + '?uId=' + user_id, data, { headers: headers })
                .then((response) => {
                    status = (this.state.status === 1) ? 4 : this.state.status;
                    this.props.changeData(status);
                    if (this.surveyMainId) {
                        var existing = localStorage.getItem("surveycreationdata_" + this.surveyMainId);
                        existing = existing ? JSON.parse(existing) : {};
                        existing['status'] = status;
                        localStorage.setItem("surveycreationdata_" + this.surveyMainId, JSON.stringify(existing));
                    }
                    self.setState({ loading: 'false', message: 'successfull', responseMessage: response.data['success']['message'], status });
                    window.setTimeout(() => {
                        self.setState({ message: '', error: 0 })
                    }, MESSAGE_TIME);
                })
                .catch((error) => {
                    self.setState({ loading: 'false' });
                    let regError = [];
                    let apiresponse = ""
                    if (error) {
                        if (error.response) {
                            if (error.response.data) {
                                apiresponse = error.response.data['error'];
                                if (apiresponse) {
                                    if (Object.keys(apiresponse).length) {
                                        regError.push(apiresponse)
                                    }
                                    if (apiresponse.length) {
                                        if (apiresponse === 'Expired token' || apiresponse === 'Invalid token') {
                                            localStorage.clear();
                                            self.setState({ redirect: 'true' })
                                        }
                                    }
                                    self.setState({ message: "error", errorMessage: regError, loading: 'false' })
                                }
                            }
                        }
                    }
                    window.setTimeout(() => {
                        self.setState({ message: '', errorMessage: [], error: 0 })
                    }, MESSAGE_TIME);
                })
        } else {
            let data = {
                id: parseInt(this.state.poll_data.id),
                user_id: parseInt(user_id),
                about_survey_text: formfield.about_survey_text.replace(/<br\s*\/?>/mg, ""),
                about_target_group_text: formfield.about_target_group_text.replace(/<br\s*\/?>/mg, ""),
                end_date: formfield.end_date,
                reward_type: formfield.reward_type,
                reward_description: formfield.reward_description
            }
            let self = this;
            let responseQuestion = "";
            let rank_positions = '';
            let isresponse;
            let survey_id = parseInt(this.state.poll_data.id);
            /* thia api call is for getting questiondata of survey ,if question not found then can not publish survey and display error popup */
            await axios.get(API_URL + '/survey/getQuestionBySurveyId/' + LANGUAGE_CODE + '/' + survey_id+ '?uId=' + user_id, { headers: headers })
                .then((response) => {
                    isresponse = true;
                    responseQuestion = response.data['success'].questiondata;
                })
                .catch((error) => {
                    isresponse = false
                    if (error && error.response && error.response.data) {
                        let msg = error.response.data['error']
                        if (msg === 'Invalid User') {
                            self.setState({ redirectToNotFound: true })
                        }
                    }
                })
            if (isresponse) {
                if (responseQuestion.length === 0) {
                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <div className='custom-ui'>
                                    <div className="close-btn text-right">
                                        <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                                    </div>
                                    <h2 className="mt-3 mb-4">{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['no_question_found_title']}</h2>
                                    <p className="mt-3 mb-4">{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['no_question_found_description']}</p>
                                    <button onClick={onClose}>{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['no_question_found_ok_button']}</button>
                                </div>
                            )
                        }
                    })
                } else {
                    // eslint-disable-next-line
                    await axios.post(API_URL + "/survey/findparticipants" + '?uId=' + user_id, data, { headers: headers })
                        .then((response) => {
                            status = 1;
                            rank_positions = response.data['success']['rank_position'];
                            if (this.surveyMainId) {
                                var existing = localStorage.getItem("surveycreationdata_" + this.surveyMainId);
                                existing = existing ? JSON.parse(existing) : {};
                                this.props.changeData(status);
                                existing['status'] = status;
                                existing['about_survey_text'] = data.about_survey_text.replace(/<br\s*\/?>/mg, "");
                                existing['about_target_group_text'] = data.about_target_group_text.replace(/<br\s*\/?>/mg, "");
                                existing['end_date'] = data.end_date;
                                existing['reward_type'] = data.reward_type;
                                existing['reward_description'] = data.reward_description;
                                localStorage.setItem("surveycreationdata_" + this.surveyMainId, JSON.stringify(existing));
                            }
                            self.setState({ message: 'successfull', rank_positions, responseMessage: response.data['success']['message'], status });
                            window.setTimeout(() => {
                                self.setState({ message: '', error: 0 })
                            }, MESSAGE_TIME);
                        })
                        .catch((error) => {
                            let regError = [];
                            let apiresponse = ""
                            if (error) {
                                if (error.response) {
                                    if (error.response.data) {
                                        apiresponse = error.response.data['error'];
                                        if (apiresponse) {
                                            if (Object.keys(apiresponse).length) {
                                                Object.keys(apiresponse).map(function (key, index) {
                                                    regError.push(apiresponse[key]);
                                                    return 0;
                                                });
                                            }
                                            if (apiresponse.length) {
                                                if (apiresponse === 'Expired token' || apiresponse === 'Invalid token') {
                                                    localStorage.clear();
                                                    self.setState({ redirect: 'true' })
                                                }
                                            }
                                            self.setState({ message: "error", errorMessage: regError })
                                        }
                                    }
                                }
                            }
                            window.setTimeout(() => {
                                self.setState({ message: '', errorMessage: [], error: 0 })
                            }, MESSAGE_TIME);
                        })
                }
            }
            this.setState({ loading: "false" })
        }
        /**for scrolling up page after submiting data to show message */
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    /*** for copy text which is url for external survey participants which is in readonly mode in text field */
    copyToClipBoard = (e) => {
        var input = document.getElementById("copyurl");
        var isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);
        if (isiOSDevice) {
            var editable = input.contentEditable;
            var readOnly = input.readOnly;
            input.contentEditable = true;
            input.readOnly = false;
            var range = document.createRange();
            range.selectNodeContents(input);
            var selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            input.setSelectionRange(0, 999999);
            input.contentEditable = editable;
            input.readOnly = readOnly;
        } else {
            this.text.select();
        }
        document.execCommand('copy');
        e.target.focus();
        this.setState({ copyMessage: "true" })
        window.setTimeout(() => {
            this.setState({ copyMessage: "" })
        }, MESSAGE_TIME);
    }
    /* --- Create Anchor Tag with Link remove it once File is downloaded --- */
    createAnchorforDownload(file_path) {
        if (file_path) {
            var ua = window.navigator.userAgent;
            var msie = ua.indexOf("MSIE ");
            var url = file_path;
            var fileName = file_path.substring(file_path.lastIndexOf('/') + 1);
            // eslint-disable-next-line
            if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
                var _window = window.open(url, '_blank');
                _window.document.close();
                _window.document.execCommand('SaveAs', true, fileName)
                _window.close();
            }
            else {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", url, true);
                xhr.responseType = "blob";
                xhr.onload = function () {
                    var urlCreator = window.URL || window.webkitURL;
                    var imageUrl = urlCreator.createObjectURL(this.response);
                    var tag = document.createElement('a');
                    tag.href = imageUrl;
                    tag.download = fileName;
                    document.body.appendChild(tag);
                    tag.click();
                    document.body.removeChild(tag);
                }
                xhr.send();
            }
        }
    }
    /* --- Download Survey Coverpage --- */
    downloadSurveyCover = async (e) => {
        this.setState({ loading: 'true' })
        let apitoken;
        // eslint-disable-next-line
        let userId;
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        if (storeitem !== null) {
            apitoken = storeitem.token;
            userId = storeitem.id;
        }
        var headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + apitoken
        }
        let self = this;
        if (this.state.id) {
            await axios.get(API_URL + '/surveyresult/shareCoverImage/' + LANGUAGE_CODE + '/' + this.state.id + '?uId=' + userId, { headers: headers })
                .then((response) => {
                    if (response.data.success) {
                        if (response.data.success.image_path) {
                            this.createAnchorforDownload(response.data.success.image_path)
                        }
                    }
                })
                .catch((error) => {
                    if (error) {
                        if (error.response) {
                            if (error.response.data) {
                                let msg = error.response.data['error']
                                if (msg === 'Expired token' || msg === 'Invalid token') {
                                    localStorage.clear();
                                    self.setState({ redirect: 'true' })
                                }
                                if (msg === 'Umfrage nicht gefunden' || msg === 'Survey not found') {
                                    self.setState({ redirectPagenotfound: true })
                                }
                            }
                        }
                    }
                })
        }
        this.setState({ loading: 'false' })
    }
    sendMail = async (e) => {
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
            let apitoken = '';
            let userid = '';
            if (storeitem !== null) {
                apitoken = storeitem.token;
                userid = storeitem.id;
            }
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + apitoken
            }
            await axios.get(API_URL + '/communitymail/sendcommunitymail/' + userid + '?uId=' + userid , { headers: headers })
                .then((response) => {
                    if (response) {
                            confirmAlert({
                                customUI: ({ onClose }) => {
                                    return (
                                        <div className='custom-ui'>
                                            <div className="close-btn text-right">
                                                <button className="d-inline-block bg-white border-0 w-auto text-rgb" onClick={onClose}><i className="ti-close pointer"></i></button>
                                            </div>
                                            <p className="mt-3 mb-4">{MYPROFILE_POLLS_CONSTANT[LANGUAGE_CODE]['conform_mail_text']}</p>
                                        </div>
                                    )
                                }
                            })
                }})
                .catch((error) => {
                })
    }
    handleChangeToggle = async (event) => {
        const name = event.target.name;
        const value = event.target.checked;
        this.setState({ [name]: value });
        let self = this;
            let storeitem = JSON.parse(localStorage.getItem('userdata'));
            let apitoken = '';
            let userid = '';
            if (storeitem !== null) {
                apitoken = storeitem.token;
                userid = storeitem.id;
            }
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + apitoken
            }
            let survey_id = parseInt(this.state.poll_data.id);
            let allowmultipleparticipations;
            if(value === true) allowmultipleparticipations =  1;
            else allowmultipleparticipations = 0
            let data = {
                id : survey_id,
                allowmultipleparticipations:  allowmultipleparticipations,
                user_id : userid }
            await axios.post(API_URL + '/survey/setallowmultipleparticipations/' + LANGUAGE_CODE + '/' + survey_id + '?uId=' + userid, data, { headers: headers })
                .then((response) => {
                })
                .catch((error) => {
                })
    }

    handleChangeEnglishLabel = async (event) => {
        const name = event.target.name;
        const value = event.target.checked;
        this.setState({ [name]: value });
            let storeitem = JSON.parse(localStorage.getItem('userdata'));
            let apitoken = '';
            let userid = '';
            if (storeitem !== null) {
                apitoken = storeitem.token;
                userid = storeitem.id;
            }
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + apitoken
            }
            let survey_id = parseInt(this.state.poll_data.id);
            let englishbuttonlabels;
            if(value === true) englishbuttonlabels =  1;
            else englishbuttonlabels = 0
            let data = {
                id : survey_id,
                englishbuttonlabels:  englishbuttonlabels,
                user_id : userid }
            await axios.post(API_URL + '/survey/setenglishbuttonlabels/' + LANGUAGE_CODE + '/' + survey_id + '?uId=' + userid, data, { headers: headers })
                .then((response) => {
                })
                .catch((error) => {
                })
    }
    render() {
        let storeitem = JSON.parse(localStorage.getItem('userdata'));
        let categoroption = this.state.categoryOption;
        let url = "";
        let rankSurvey = '';
        let karma_Point = '';
        let no_of_days = '';
        let title = '';
        var user_ID= null;
        if(storeitem){
            user_ID = storeitem.account_type_category_id
        }
        if (this.state.responseSurvey) {
            url = BASEURL + 's/' + this.state.responseSurvey.survey_identifier;
        }
        else {

        }
        let survey_title = '';
        if (this.state.responseSurvey.survey_title && this.state.responseSurvey.survey_title.length !== 0) {
            survey_title = this.state.responseSurvey.survey_title;
        }
        var option = "";
        var span = {
            color: 'red'
        }
        var success = {
            color: "green"
        }
        if(this.state.rank_positions){
            rankSurvey = this.state.rank_positions.rank_position;
            karma_Point = this.state.rank_positions.required_karma;
            no_of_days = this.state.rank_positions.no_of_days;
        }
        /**map for the dropdown option and return it in option variable */
        if (categoroption) {
            option = categoroption.map((option, index) => {
                return (<option value={option.id} key={index}>{option.reward}</option>)
            })
        }
        if (localStorage.getItem("surveycreationdata_" + this.surveyMainId)) {
            let data = JSON.parse(localStorage.getItem("surveycreationdata_" + this.surveyMainId));
            title = data['survey_title_slug'];
        } 
        return (
            <div className={"tab-pane fade active show"} id="teilnehmer-finden" role="tabpanel" aria-labelledby="teilnehmer-finden-tab">
                {(this.state.loading === "true") ?
                    BUBBLE_LOADING
                    : null
                }
                {(this.state.message === 'successfull') ?
                    <div className='alert alert-success'>
                        {this.state.responseMessage}
                    </div>
                    : null
                }
                {(this.state.message === 'error') ?
                    <div className='alert alert-danger'>
                        {this.state.errorMessage.map((msg, index) => <ListItem key={index} value={msg} />)}
                    </div>
                    : null
                }
                {(this.state.redirect === 'true') ?
                    <Redirect to='/anmelden'></Redirect>
                    : null
                }
                {(this.state.redirectToNotFound) ?
                    <Redirect to='/404'></Redirect>
                    :null
                }
                <div className="participant-tab">
                    <div className="container-fluid">
                        <div className="send-survey-by-link container-md px-0 mb-4">
                            <div className="bg-white box-shadow custom-tag-parent chart-main-block-freetext">
                                <div className="table-content bg-white px-3 px-lg-5">
                                    <h3 className="mb-0 text-dark font-weight-bold text-break">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['send_survey_by_link']}</h3>
                                </div>
                                <div className="bottom-border-blue px-3 px-lg-5" name="hidden-tag-chart">
                                </div>
                                <div className="freetext-subtitle py-4 px-3 px-lg-5" name="hidden-tag-chart">
                                    <h3 className="font-14 lh-24 fw-400">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['send_survey_by_link_subtitle']}</h3>
                                    <div className="form-group hs-form-field focus mb-3 mt-4">
                                        <input type="text" name="enter the link here" className="form-control hs-input" id="copyurl" readOnly value={url} ref={(text) => this.text = text} />
                                        <div className="input-group-append">
                                            <div className="icon-for-copy">
                                                <button type="button" className="btn-copy" onClick={this.copyToClipBoard} title={EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['copy_to_clipboard']}><i className="far fa-copy"></i></button>
                                            </div>
                                        </div>
                                        <div className="validation-message" style={success}>{(this.state.copyMessage === "true") ? EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['copied_message'] : null}</div>
                                    </div>
                                    <div className="row survey-form-detail mt-4">
                                        <div className="col-12 col-md-12 mb-4 d-flex flex-wrap">
                                            <div className="swich-slider d-flex align-items-center col-12 col-md-6">
                                                <label className="switch mr-2 flex-shrink-0">
                                                    <input type="checkbox" data-toggle="toggle" name="toggle" checked={this.state.toggle} onClick={this.handleChangeToggle} />
                                                    <span className="slider round"></span>
                                                </label>
                                                <span className="slider-text-findparticipation" style={{"color": "#202124"}}>{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['toggle_text']}</span>
                                            </div>
                                            <div className="swich-slider d-flex align-items-center col-12 col-md-6 justify-content-md-end">
                                                 {storeitem.plan_type ?
                                                    <>
                                                    <label className="switch mr-2">
                                                        <input type="checkbox" data-toggle="toggle" name="englishLable" checked={this.state.englishLable} onClick={this.handleChangeEnglishLabel} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                        <span className="slider-text-findparticipation" style={{"color": "#202124"}}>{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['english_lable_button']}</span>
                                                    </>
                                                    :
                                                    <Link className='text-colors' to={user_ID == 4 ? '/business-checkout' : user_ID == 2 ? "/university-checkout" : '/checkout'}><i onClick={() => localStorage.setItem('redirectURL',window.location.href)} class="fas fa-lock mr-2"></i>
                                                    <span className="slider-text-findparticipation" style={{"color": "#202124"}} onClick={() => localStorage.setItem('redirectURL',window.location.href)}>{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['english_lable_button']}</span></Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <FacebookShareButton
                                            className="mr-1 mr-sm-3"
                                            url={url}
                                            quote={EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['external_link_subject'] + ' "' + survey_title + '".'} >
                                            <FacebookIcon size={40} round={true} bgStyle={{"fill": "#00b3f4"}} />
                                        </FacebookShareButton>
                                        <WhatsappShareButton
                                            className="mr-1 mr-sm-3"
                                            url={url}
                                            title={EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['external_link_subject'] + ' "' + survey_title + '".'}
                                            hashtag="#External Survey Participation Link">
                                            <WhatsappIcon size={40} round={true} bgStyle={{"fill": "#00b3f4"}} />
                                        </WhatsappShareButton>
                                        <LinkedinShareButton
                                            className="mr-1 mr-sm-3"
                                            url={url}
                                            title={EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['external_link_subject'] + ' "' + survey_title + '".'}
                                            hashtag="#External Survey Participation Link">
                                            <LinkedinIcon size={40} round={true} bgStyle={{"fill": "#00b3f4"}} />
                                        </LinkedinShareButton>
                                        <TwitterShareButton
                                            className="mr-1 mr-sm-3"
                                            url={url}
                                            title={EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['external_link_subject'] + ' "' + survey_title + '".'}
                                            hashtag="#External Survey Participation Link">
                                            <TwitterIcon size={40} round={true} bgStyle={{"fill": "#00b3f4"}} />
                                        </TwitterShareButton>
                                        <EmailShareButton
                                            subject = {EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['external_link_subject'] + ' "' + survey_title + '".'}
                                            url={url}
                                            hashtag="#External Survey Participation Link">
                                            <EmailIcon size={40} round={true} bgStyle={{"fill": "#00b3f4"}} />
                                        </EmailShareButton>
                                    </div>
                                </div>
                            </div>
                            {storeitem.account_type_category_id <= 2?
                                <>
                                <div className="row text-center mt-4 mt-sm-5">
                                    <div className="col-12">
                                        <h3 className="mb-0 text-dark font-weight-bold text-break">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]["find_participation_heading"]}</h3>
                                    </div>
                                </div>
                                <div className="bg-white box-shadow custom-tag-parent chart-main-block-freetext mt-4 mt-lg-5">
                                    <div className="table-content bg-white px-3 px-lg-5">
                                        <h3 className="mb-0 text-dark font-weight-bold text-break">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['survey_karmapoint_link']}</h3>
                                    </div>
                                    <div className="bottom-border-blue px-3 px-lg-5" name="hidden-tag-chart">
                                    </div>
                                    <div className="freetext-subtitle py-4 px-3 px-lg-5" name="hidden-tag-chart">
                                        <h3 className="font-14 lh-24 fw-400 mb-3">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['survey_karmapoint_link_subtitle'] + " "}{<span style={{ "color": "#00b3f4", "text-decoration": "underline", "cursor": "pointer" }} name="download_coverpage"><Link to={'/' + URL_CONSTANT['survey_list']}>{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['survey_mypoll_link']}</Link></span>}{" " + EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['survey_karmapoint_link_subtitle2']}</h3>
                                    </div>
                                </div>
                                <div className="bg-white box-shadow custom-tag-parent chart-main-block-freetext">
                                    <div className="table-content bg-white px-3 px-lg-5">
                                        <h3 className="mb-0 text-dark font-weight-bold text-break">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['find_out_participants']}</h3>
                                    </div>
                                    <div className="bottom-border-blue px-3 px-lg-5" name="hidden-tag-chart">
                                    </div>
                                    <div className="freetext-subtitle py-4 px-3 px-lg-5" name="hidden-tag-chart">
                                        { (this.state.status !== 1 || (this.state.status === 1 && this.state.changebutton)) ?
                                            <>
                                                <h3 className="font-14 lh-24 fw-400">{ EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['find_out_participants_subtitle']}</h3>
                                                <div className="find-out about-emporio-participants mt-3 mt-md-5 container-md px-0">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className={(this.state.about_survey_text) ? "form-group hs-form-field focus" : "form-group hs-form-field"}>
                                                                <textarea className={(this.state.fieldErrorMessage.filter(a => a === 'about_survey_text').length > 0) ? " form-control hs-input field-highlight custom-padding-findparticipation textarea-height" : (this.state.status === 1 && (!this.state.changebutton)) ? "form-control hs-input disable field-disable-gray custom-padding-findparticipation textarea-height" : (this.state.status === 3) ? "form-control hs-input disable field-disable-gray custom-padding-findparticipation textarea-height" : "form-control hs-input custom-padding-findparticipation textarea-height"} id="exampleFormControlTextarea1" rows="5" name="about_survey_text" onChange={this.handleChange} value={(this.state.about_survey_text) ? this.state.about_survey_text.replace(/<br\s*\/?>/mg, "") : ""} readOnly={(this.state.status === 1 && !this.state.changebutton) ? true : (this.state.status === 3) ? true : false}></textarea>
                                                                {(this.state.status === 1 && (!this.state.changebutton)) ? null : (this.state.status === 3) ? null : <label htmlFor="exampleFormControlTextarea1">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['description_of_survey']}</label>}
                                                                <div>
                                                                    {(this.state.fieldErrorMessage.filter(a => a === 'about_survey_text').length > 0) ?
                                                                        <span style={span}>{FIND_PARTICIPANTS_ERROR_MESSAGE[LANGUAGE_CODE]['about_survey_text']}</span>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className={(this.state.about_target_group_text) ? "form-group hs-form-field focus" : "form-group hs-form-field"}>
                                                                <textarea className={(this.state.fieldErrorMessage.filter(a => a === 'about_target_group_text').length > 0) ? " form-control hs-input field-highlight custom-padding-findparticipation textarea-height" : (this.state.status === 1 && !this.state.changebutton) ? "form-control hs-input disable field-disable-gray custom-padding-findparticipation textarea-height" : (this.state.status === 3) ? "form-control hs-input disable field-disable-gray custom-padding-findparticipation textarea-height" : "form-control hs-input custom-padding-findparticipation textarea-height"} id="exampleFormControlTextarea2" rows="5" name="about_target_group_text" onChange={this.handleChange} value={(this.state.about_target_group_text) ? this.state.about_target_group_text.replace(/<br\s*\/?>/mg, "") : ""} readOnly={(this.state.status === 1 && !this.state.changebutton) ? true : (this.state.status === 3) ? true : false}></textarea>
                                                                {(this.state.status === 1 && (!this.state.changebutton)) ? null : (this.state.status === 3) ? null : <label htmlFor="exampleFormControlTextarea2">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['target_group_of_survey']}</label>}
                                                                <div>
                                                                    {(this.state.fieldErrorMessage.filter(a => a === 'about_target_group_text').length > 0) ?
                                                                        <span style={span}>{FIND_PARTICIPANTS_ERROR_MESSAGE[LANGUAGE_CODE]['about_target_group_text']}</span>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className={"form-group hs-form-field focus-sustain"}>
                                                                <MaskedInput
                                                                    mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                    className={((this.state.fieldErrorMessage.filter(a => a === 'end_date').length > 0) || (this.state.fieldErrorMessage.filter(a => a === 'notvalid').length > 0) || (this.state.fieldErrorMessage.filter(a => a === "bigger").length > 0) || (this.state.fieldErrorMessage.filter(a => a === 'invalid').length > 0)) ? " form-control hs-input field-highlight" : (this.state.status === 1 && !this.state.changebutton) ? "form-control hs-input field-disable-gray" : (this.state.status === 3) ? "form-control hs-input field-disable-gray" : "form-control hs-input"}
                                                                    id='1'
                                                                    type='text'
                                                                    placeholder="tt.mm.jjjj"
                                                                    name="end_date"
                                                                    onChange={this.handleChange}
                                                                    value={(this.state.end_date) ? this.state.end_date.split(' ')[0] : null}
                                                                    readOnly={(this.state.status === 1 && !this.state.changebutton) ? true : (this.state.status === 3) ? true : false}
                                                                />
                                                                {(this.state.status === 1 && (!this.state.changebutton)) ? null : (this.state.status === 3) ? null : <label htmlFor="teilnehmer">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['end_date_of_survey']}</label>}
                                                                <div>
                                                                    {(this.state.fieldErrorMessage.filter(a => a === 'end_date').length > 0) ?
                                                                        <span style={span}>{FIND_PARTICIPANTS_ERROR_MESSAGE[LANGUAGE_CODE]['end_date']}</span>
                                                                        : (this.state.fieldErrorMessage.filter(a => a === 'notvalid').length > 0) ? <span style={span}>{FIND_PARTICIPANTS_ERROR_MESSAGE[LANGUAGE_CODE]['notvaliddate']}</span>
                                                                            : (this.state.fieldErrorMessage.filter(a => a === 'bigger').length > 0) ? <span style={span}>{FIND_PARTICIPANTS_ERROR_MESSAGE[LANGUAGE_CODE]['biggerDate']}</span> : (this.state.fieldErrorMessage.filter(a => a === 'invalid').length > 0) ? <span style={span}>{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['invalid']}</span> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group focus">
                                                                <select className={(this.state.fieldErrorMessage.filter(a => a === 'reward_type').length > 0) ? "custom-select font-weight-noraml field-highlight" : (this.state.status === 1 && !this.state.changebutton) ? "custom-select font-weight-noraml disable" : (this.state.status === 3 || this.state.status === 4) ? "custom-select font-weight-noraml disable" : "custom-select font-weight-noraml"} id="belohnung" name="reward_type" onChange={this.handleChange} value={this.state.reward_type} disabled={(this.state.status === 1 && !this.state.changebutton) ? true : (this.state.status === 3) ? true : false} >
                                                                    <option value="" >{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['default_reward_option']}</option>
                                                                    {option}
                                                                </select>
                                                                {(this.state.status === 1 && (!this.state.changebutton)) ? null : (this.state.status === 3) ? null : <label htmlFor="belohnung">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['reward_category']}</label>}
                                                                <div>
                                                                    {(this.state.fieldErrorMessage.filter(a => a === 'reward_type').length > 0) ?
                                                                        <span style={span}>{FIND_PARTICIPANTS_ERROR_MESSAGE[LANGUAGE_CODE]['reward_type']}</span>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className={(this.state.reward_description) ? "form-group hs-form-field focus" : "form-group hs-form-field focus"}>
                                                                <input type="text" className={(this.state.fieldErrorMessage.filter(a => a === 'reward_description').length > 0) ? "form-control hs-input field-highlight custom-padding-findparticipation" : (this.state.status === 1 && !this.state.changebutton) ? "form-control hs-input field-disable-gray" : (this.state.status === 3) ? "form-control hs-input field-disable-gray custom-padding-findparticipation" : "form-control hs-input custom-padding-findparticipation"} id="exampleFormControlTextarea3" name="reward_description" onChange={this.handleChange} value={this.state.reward_description} readOnly={(this.state.status === 1 && !this.state.changebutton) ? true : (this.state.status === 3) ? true : false} />
                                                                {(this.state.status === 1 && (!this.state.changebutton)) ? null : (this.state.status === 3) ? null : <label htmlFor="exampleFormControlTextarea3">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['reward_description']}</label>}
                                                                <div>
                                                                    {(this.state.fieldErrorMessage.filter(a => a === 'reward_description').length > 0) ?
                                                                        <span style={span}>{FIND_PARTICIPANTS_ERROR_MESSAGE[LANGUAGE_CODE]['reward_description']}</span>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                {(this.state.bubbleLoading === "true") ?
                                                    <div className="d-flex align-items-center justify-content-center" style={{"height" : "300px"}}><i className="fa fa-spinner fa-spin" style={{ "fontSize": "48px", "color": "#128df5" }}></i></div>
                                                    :
                                                    <React.Fragment>
                                                        <h3 className="font-14 lh-24 fw-400">{ EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['find_participants_subtitle1'] + " "}{<span style={{ "color": "#00b3f4", "text-decoration": "underline", "cursor": "pointer" }} ><Link to={"/umfragen/" + title }>{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['survey_mypoll_link']}</Link></span>}{" " + EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['find_participants_subtitle2']}</h3>
                                                        <div className="row py-4 py-lg-0 my-lg-5">
                                                            <div className="col-12">
                                                                <div className="count-box counter auto-height bg-white box-border">
                                                                    <div className="row no-gutters">
                                                                        <div className="col-12 col-md pt-3 pt-md-0">
                                                                            <div className="counter-col">
                                                                                <div className="counter-data custom-static-data">
                                                                                    <div className="count"  data-to="245" data-speed={rankSurvey}>{rankSurvey ?
                                                                                    <CountUp end={rankSurvey} />
                                                                                    : 0 }
                                                                                    </div>
                                                                                    <h6 className="font-18 text-truncate">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['current_rank']}</h6>
                                                                                </div>
                                                                            </div> {/*<!-- count col -->*/}
                                                                        </div>
                                                                        <div className="col-12 col-md pt-3 pt-md-0">
                                                                            <div className="counter-col">
                                                                                <div className="counter-data custom-static-data">
                                                                                    <div className="count" data-to="189" data-speed={karma_Point}>{karma_Point ?
                                                                                    <CountUp end={karma_Point} />
                                                                                    : 0 }
                                                                                    </div>
                                                                                    <h6 className="font-18 text-truncate">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['karma_of_point']}</h6>
                                                                                </div>
                                                                            </div> {/*<!-- count col -->*/}
                                                                        </div>
                                                                        <div className="col-12 col-md pt-3 pt-md-0">
                                                                            <div className="counter-col br-none">
                                                                                <div className="counter-data custom-static-data">
                                                                                    <div className="count" data-to="445" data-speed={no_of_days}>{ no_of_days ?
                                                                                    <CountUp end={no_of_days} />
                                                                                    : 0 }
                                                                                    </div>
                                                                                    <h6 className="font-18 text-truncate">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['number_of_days']}</h6>
                                                                                </div>
                                                                            </div> {/*<!-- count col -->*/}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </>
                                        }
                                        <div className="publish-survey text-center my-3 mt-md-4 mb-md-4">
                                            <button type="button" disabled={(this.state.status === 3) ? true : false} onClick={this.handleSubmit} className={(this.state.status === 3) ? "btn btn-clr text-white text-uppercase font-18 disable custom-disable" : "btn btn-clr text-white text-uppercase font-18 custom-disable"} title={(this.state.status !== 1) ? EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['publish_button_text'] : (this.state.changebutton) ? EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['publish_button_text'] : EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['disable_button_text']}>{(this.state.status !== 1) ? EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['publish_button_text'] : (this.state.changebutton) ? EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['publish_button_text'] : EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['disable_button_text']}</button>
                                        </div>
                                    </div>
                                </div>
                                </>
                                : null
                            }
                                {storeitem.account_type_category_id == 4 ?
                                <>
                                <div className="bg-white box-shadow custom-tag-parent chart-main-block-freetext mt-4 mt-lg-5">
                                    <div className="table-content bg-white px-3 px-lg-5">
                                        <h3 className="mb-0 text-dark font-weight-bold text-break">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['request_community_heading']}</h3>
                                    </div>
                                    <div className="bottom-border-blue px-3 px-lg-5" name="hidden-tag-chart">
                                    </div>
                                    <div className="freetext-subtitle py-4 px-3 px-lg-5" name="hidden-tag-chart">
                                        <h3 className="font-14 lh-24 fw-400">{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['request_community_text'] + " "}</h3>
                                    <div className="publish-survey text-center my-3 mt-md-4 mb-md-4">
                                        <button className='btn btn-clr text-white text-uppercase font-18 custom-disable' onClick={() => this.sendMail()}>{EDIT_SURVEY_CONSTANT[LANGUAGE_CODE]['request_community_button']}</button>
                                    </div>
                                    </div>
                                </div>
                                </> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default FindParticipants;